import { UserInfoViewModel } from './../../common/viewmodels/userinfoviewmodel';
import { GoogleAnalyticsViewModel } from './../../common/viewmodels/googleanalyticsviewmodel';
import { GoogleAnalyticsService } from './../../common/shared/services/google-analytics.service';
import { UserProfileService } from './../../common/shared/services/user-profile.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-authenticated-user',
  templateUrl: './authenticated-user.component.html',
  styleUrls: ['./authenticated-user.component.css']
})
export class AuthenticatedUserComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  constructor(private userProfileService: UserProfileService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    // If user redirected from Payment Gateway and the local storage was cleared then
    // call getUserProfile() method to reload user profile data
    if (sessionStorage.getItem('wasLocalStorageCleared') && sessionStorage.getItem('wasLocalStorageCleared') === 'true') {
      this.userProfileService.getUserProfile();
      sessionStorage.removeItem('wasLocalStorageCleared');
      const subscription = this.userProfileService.getData().subscribe(isUserProfileLoaded => {
        if (isUserProfileLoaded) {
          this.RegisterGoogleAnalyticsService();
        }
      });
      this.subscriptions.push(subscription);
    } else {
      this.RegisterGoogleAnalyticsService();
    }
  }

  RegisterGoogleAnalyticsService() {
    const agentId = this.userProfileService.getBasicUserInfo().id;
    const agentName = this.userProfileService.getBasicUserInfo().firstName + ' ' + this.userProfileService.getBasicUserInfo().lastName;
    const agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    const agencyCode = this.userProfileService.getBasicUserInfo().agencyCode;
    const googleAnalyticsViewModel: GoogleAnalyticsViewModel = {
      eventCategory: null,
      eventAction: 'User',
      eventLabel: null,
      eventValue: null
    };
    this.googleAnalyticsService.customUserDetailsEventEmitter(agentId, agentName, agencyId, agencyCode, googleAnalyticsViewModel);
    if (agencyCode !== undefined) {
      this.googleAnalyticsService.customUserInfoEventEmitter((agentId + ' | ' + agentName + ' | ' + agencyCode), googleAnalyticsViewModel);
    } else {
      this.googleAnalyticsService.customUserInfoEventEmitter((agentId + ' | ' + agentName), googleAnalyticsViewModel);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
