import { Component, OnInit, ViewChild, OnDestroy, Input, AfterContentChecked } from '@angular/core';
import { DialogsService } from '../../dialogs/dialogs.service';
import { JarvisError } from 'src/app/common/jarviserror';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatSort, PageEvent } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { BookingSummaryService } from '../../services/booking-summary.service';
import { BookingSummaryViewModel } from '../../../../viewmodels/booking-summary/bookingsummaryviewmodel';
import { BookingsCountRequestViewModel } from 'src/app/b2b/viewmodels/booking-summary/bookingscountrequestviewmodel';
import { BookingTimePipe } from '../../pipes/booking-time.pipe';
import { interval } from 'rxjs';
import { TooltipPosition } from '@angular/material';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';
import { CONSTANTS } from '../../../../../common/constants';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { ReservationDataService } from '../../services/reservation-data.service';
import { ScreenService } from '../../../../../framework/fw/services/screen.service';

@Component({
    selector: 'app-booking-summary',
    templateUrl: './booking-summary.component.html',
    styleUrls: ['./booking-summary.component.css']
})
export class BookingSummaryComponent implements OnInit, OnDestroy {
    myBookings: BookingSummaryViewModel[] = [];
    reqObj: BookingsCountRequestViewModel = <BookingsCountRequestViewModel>{};
    subscriptions: any[] = [];
    spinnerDialogId: string;
    spinnerDialogTimeout: number;
    searchBookingsForm: FormGroup;
    bookingsCount: {};

    displayedColumns = [
        'bookingId', 'bookingStatus', 'agencyReferenceNo',
        'checkInDate', 'guestName', 'totalRooms',
        'bookingDate', 'totalPrice'
    ];
    displayedColumnsMobile = [
        'bookingId', 'bookingStatus','bookingDate'
    ];
    // displayedColumns = [
    //     'bookingId', 'bookingStatus', 'confirmBy', 'timeLeft', 'agencyReferenceNo',
    //     'checkInDate', 'guestName', 'totalRooms',
    //     'bookingDate', 'totalPrice'
    // ];
    dataSource: MatTableDataSource<BookingSummaryViewModel>;
    pageEvent: PageEvent;
    noRecordsFound: boolean;
    orderBy = 'lastModifiedDate';  // Default Order;
    sortOrder = 'desc';       // Default Sort Orde
    countReqObj: any;
    bookingReqObj: any;
    isSuperUser: boolean;
    branchId: any;

    bookingSummary = [];

    SECOND = 1000;
    MINUTE: any = 60 * this.SECOND;
    HOUR: any = 60 * this.MINUTE;
    DAY: any = 24 * this.HOUR;
    reservationDetailsStatus = CONSTANTS.reservationDetailsStatus;
    bookingStatus = CONSTANTS.bookingStatus;


    @Input() agencyCode: string;
    @Input() applicationId: string;
    @Input() currencyCode: string;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private bookingSummaryService: BookingSummaryService,
        private dialogsService: DialogsService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private bookingTimePipe: BookingTimePipe,
        private topBarService: TopBarService,
        private userProfileService: UserProfileService,
        private reservationDataService: ReservationDataService,
        public screenService: ScreenService
    ) {
        this.topBarService.currencyChange$.subscribe(curr => {
            this.currencyCode = curr;
            this.getCountSummary();
        });
    }

    ngOnInit() {
        this.reqObj.agencyCode = this.agencyCode;
        this.reqObj.applicationId = this.applicationId;

        // added to provide delay for time left count delay
        // Subscribe to begin publishing values
        const secondsCounter = interval(1000);
        secondsCounter.subscribe(n => {
            // console.log(`It's been ${n} seconds since subscribing!`);
        });

        this.searchBookingsForm = new FormGroup({
            bookingID: new FormControl()
        });

        this.getCountSummary();
        this.getBranchId();

    }

    getCountSummary() {
        this.countReqObj = this.prepareBookingReqObj(this.agencyCode, this.applicationId);

        this.getBookingsCount(this.countReqObj);

        this.countReqObj.currencyCode = this.currencyCode;

        // todo add keys
        this.countReqObj.agentId = this.userProfileService.userDetailsDto['id']
        this.countReqObj.branchId = this.branchId;



        this.bookingReqObj = this.countReqObj;

        this.getRecentBookings(this.bookingReqObj);
    }

    getBranchId() {
        this.isSuperUser = this.userProfileService.getBasicUserInfo()['isSuperUser'] || false;
        
        if(!this.isSuperUser) {
            this.reservationDataService.getBranchByUserId(this.userProfileService.userDetailsDto['id']).subscribe(result => {
                this.branchId =  result['branchId'];
            })
        } else {
            this.branchId = null;
        }
    }

    getTransDate(myDate) {
        return this.bookingTimePipe.transform(myDate);
    }

    prepareBookingReqObj(agencyCode: string, applicationId: string) {
        const reqObj: BookingsCountRequestViewModel = <BookingsCountRequestViewModel>{};
        reqObj.agencyCode = agencyCode;
        reqObj.applicationId = applicationId;

        const now = new Date();
        const prevDate = new Date();
        // prevDate.setDate(now.getDate() - 30);
        prevDate.setDate(now.getDate());

        const isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
        // tslint:disable-next-line:max-line-length
        // const todDate = new Date((prevDate.getTime() - prevDate.getTimezoneOffset() * 60000) - (30 * 60000)).toISOString();
        const todDate = new Date((prevDate.getTime() - prevDate.getTimezoneOffset() * 60000) - (60000)).toISOString();

        reqObj.endDate = now;
        reqObj.startDate = prevDate;

        return reqObj;
    }

    getRecentBookings(reqObj) {
        const subscription = this.bookingSummaryService.getBookingByAgencyCode(reqObj).subscribe(data => {
            this.myBookings = data ? data.result : [];
            this.dataSource = new MatTableDataSource<BookingSummaryViewModel>(this.myBookings);
            this.sortTable(this.dataSource);
        }, (error: JarvisError) => {
            /* this.hideSpinner(); */
            console.error(`Error in Getting Bookings. ${error.friendlyMessage}`);
        }
        );
        this.subscriptions.push(subscription);
    }

    getBookingsCount(reqObj: BookingsCountRequestViewModel) {
        const subscription = this.bookingSummaryService.getBookingsCountByAgencyCode(reqObj).subscribe(data => {
            this.bookingsCount = data ? data.result : [];
        }, (error: JarvisError) => {
            /* this.hideSpinner(); */
            console.error(`Error in Getting Bookings. ${error.friendlyMessage}`);
        }
        );
        this.subscriptions.push(subscription);
    }

    searchBookings() {
        this.hideSpinner();
        this.router.navigate(['/authenticated/bookingmgmt/reservationList'],
            {
                queryParams:
                    { BId: this.searchBookingsForm.get('bookingID').value }
            });
    }

    goUserView(value, operation) {
        this.router.navigate(['../../bookingmgmt/reservations/reservationdetails',
            value.bookingId, value.bookingStatus, value.agencyReferenceNo,
            value.agencyId, value.checkInDate, operation.trim().toLowerCase(), value.bookingSource],
            {
                relativeTo: this.activatedRoute,
                queryParams: {
                    AC: value.agencyCode,
                    DT: value.datetype,
                    From: value.fromDate,
                    To: value.toDate
                }
            }
        );
    }

    navigate(url, id) {
        const tdayDate = new Date();
        // const toDate = tdayDate.setDate((new Date()).getDate() - 30);
        const toDate = tdayDate.setDate((new Date()).getDate());

        this.router.navigate([url],
            {
                relativeTo: this.activatedRoute,
                queryParams: {
                    BStatus: id,
                    From: new Date(toDate)
                }
            }
        );
    }

    showSpinner() {
        this.spinnerDialogTimeout = setTimeout(() => {
            this.spinnerDialogId = this.dialogsService.showSpinner();
        });
    }

    hideSpinner() {
        if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
            this.dialogsService.hideSpinner(this.spinnerDialogId);
            this.spinnerDialogId = undefined;
        }
        clearTimeout(this.spinnerDialogTimeout);
        this.spinnerDialogTimeout = undefined;
    }

    sortTable(datasource) {
        datasource.sort = this.sort;
        datasource.sortingDataAccessor = (item, property) => {
            if (typeof item[property] === 'string') {
                return item[property].toLowerCase();
            } else {
                return item[property];
            }
        };
    }
    dispose() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    ngOnDestroy() {
        this.dispose();
    }

}
