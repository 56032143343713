import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-room-info-dialog',
  templateUrl: './room-info-dialog.component.html',
  styleUrls: ['./room-info-dialog.component.css']
})
export class RoomInfoDialogComponent implements OnInit {

  imagelist: any[];
  title: string;
  roomSize: string;
  beds: string;
  guest: string;
  facilities: any[];
  description: string;
  smoking: string;

  constructor(public dialogRef: MatDialogRef<RoomInfoDialogComponent>) { }

  ngOnInit() {
  }

}
