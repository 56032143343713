import { NgModule } from '@angular/core';
import { MaterialModule } from '../../common/material/material.module';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-bootstrap/carousel';

// import { DealInfoComponent } from './deal-info/deal-info.component';
// import { MgBedbankAboutComponent } from './mg-bedbank-about/mg-bedbank-about.component';
// import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
// import { ContactComponent, ContactUsSubmittedDialog, ContactUsPreviewDialog } from './contact/contact.component';
// import {
//   AgentRegistrationComponent, AgentRegistrationConfirmationDialog,
//   AgentRegistrationPreviewDialog
// } from './registration-forms/agent-registration/agent-registration.component';
// import {
//   SupplierRegistrationComponent,
//   SupplierRegistrationPreviewDialog,
//   SupplierRegistrationConfirmationDialog
// } from './registration-forms/supplier-registration/supplier-registration.component';
import { RouterModule } from '@angular/router';

// import { RegistrationNavComponent } from './registration-nav/registration-nav.component';
// import { RegistrationComponent } from './registration/registration.component';

import { CaptchaModule } from 'primeng/captcha';
import { ComponentsModule } from '../common/b2b-shared/components/components.module';

// import { GroupBookingComponent, GroupBookingSubmittedDialog, GroupBookingPreviewDialog } from './group-booking/group-booking.component';

import { CalendarModule } from 'primeng/components/calendar/calendar';
import { FaqComponent } from './faq/faq.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { BoHomeComponent } from './bo-home/bo-home.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';


@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    CaptchaModule,
    ComponentsModule,
    CalendarModule,
    MultiSelectModule,
    NgxUsefulSwiperModule
  ],
  declarations: [
    HomeComponent,
    BoHomeComponent,
    // DealInfoComponent,
    // MgBedbankAboutComponent,
    // TermsAndConditionsComponent,
    // ContactComponent,
    // ContactUsSubmittedDialog,
    // AgentRegistrationComponent,
    // SupplierRegistrationComponent,
    // SupplierRegistrationPreviewDialog,
    // SupplierRegistrationConfirmationDialog,
    // RegistrationNavComponent,
    // RegistrationComponent,
    // AgentRegistrationPreviewDialog,
    // AgentRegistrationConfirmationDialog,
    // GroupBookingComponent,
    // GroupBookingSubmittedDialog,
    FaqComponent,
    // GroupBookingPreviewDialog,
    // ContactUsPreviewDialog
  ],
  // entryComponents: [
  //   ContactUsSubmittedDialog,
  //   SupplierRegistrationPreviewDialog,
  //   SupplierRegistrationConfirmationDialog,
  //   AgentRegistrationPreviewDialog,
  //   AgentRegistrationConfirmationDialog,
  //   GroupBookingSubmittedDialog,
  //   GroupBookingPreviewDialog,
  //   ContactUsPreviewDialog]
})
export class HomeMgmtModule { }
