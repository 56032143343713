import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FrameworkConfigService {

  ShowStatusBar = true;
  ShowTopBar = true;
  ShowTitleBar = true;
  ShowBanner = true;
  ShowAgencyDetails = false;
  ShowMenu = true;
  AgencyNameCode = '';
  IsCallCenterUser = false;
  constructor() { }

  //  get ShowStatusBar() {
  //   return this._showStatusBar;
  //  }

  //  set ShowStatusBar(showStatusBar: boolean) {
  //     this._showStatusBar = showStatusBar;
  //  }

  //  get ShowTopBar() {
  //   return this._showStatusBar;
  //  }

  //  set ShowStatusBar(showStatusBar: boolean) {
  //     this._showStatusBar = showStatusBar;
  //  }

  //  get ShowStatusBar() {
  //   return this._showStatusBar;
  //  }

  //  set ShowStatusBar(showStatusBar: boolean) {
  //     this._showStatusBar = showStatusBar;
  //  }

  //  get ShowAgencyDetail() {
  //   return this._showAgencyDetails;
  //  }

  //  set ShowAgencyDetail(showAgencyDetail: boolean) {
  //     this._showAgencyDetails = showAgencyDetail;
  //  }

  //  get AgencyNameCode() {
  //    return this._agencyNameCode;
  //  }

  //  set AgencyNameCode(value: string) {
  //   this._agencyNameCode = value;
  //  }
}
