import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { Utilities } from 'src/app/common/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class CancelpopupService {

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, message: string, buttonText?: string, cancelButtonName?: string): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    if (Utilities.isNullOrEmpty(buttonText)) {
      dialogRef.componentInstance.buttonText = 'OK';
    } else {
      dialogRef.componentInstance.buttonText = buttonText;
    }
    if (Utilities.isNullOrEmpty(cancelButtonName)) {
      dialogRef.componentInstance.cancelButtonName = 'Cancel';
    } else {
      dialogRef.componentInstance.cancelButtonName = cancelButtonName;
    }

    return dialogRef.afterClosed();
  }
}
