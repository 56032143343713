import { PaymentDataService } from './../../common/b2b-shared/services/payment-data.service';
import { HotelSearchService } from './../../common/b2b-shared/services/hotel-search.service';
import { Component, OnInit, Input, NgZone, OnDestroy, Inject } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { HotelViewModel, HotelImagesViewModel, RoomDetailViewModel, RoomCategoryInfoViewModel } from '../../viewmodels/hotel-mgmt/hotelviewmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { RoomInfoDataService } from '../../common/b2b-shared/services/room-info-data.service';
import { Subscription } from 'rxjs';
import { JarvisError } from '../../../common/jarviserror';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { MapsdialogComponent } from '../../common/b2b-shared/dialogs/mapsdialog/mapsdialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CurrencyApi } from '../../../framework/fw/currency/currency-api';
import { HotelSummaryMapViewModel } from '../../viewmodels/hotel-mgmt/hotelsummarymapviewmodel';

// tslint:disable-next-line:max-line-length
import { HotelRecheckService } from '../../common/b2b-shared/services/hotel-recheck.service';
import { HotelRecheckViewModel } from '../../viewmodels/hotel-mgmt/hotelrecheckviewmodel';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { style, animate, transition, trigger } from '@angular/animations';
import { CONSTANTS } from 'src/app/common/constants';
import { MessageDialogComponent } from '../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { ErrorCodes } from 'src/app/common/errorcodes';
import { GoogleAnalyticsViewModel } from 'src/app/common/viewmodels/googleanalyticsviewmodel';
import { GoogleAnalyticsService } from 'src/app/common/shared/services/google-analytics.service';
import { HotelBookService } from '../../common/b2b-shared/services/hotel-book.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';

import { SupplierCode } from '../../../common/enum';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
import { Utilities } from 'src/app/common/utilities/utilities';
import { CallcenterService } from '../../common/b2b-shared/services/callcenter.service';
import { errorConstants } from 'src/app/common/friendlymessage';
import { ScreenService } from 'src/app/framework/fw/services/screen.service';
import { AgencyProfileDataService } from '../../common/b2b-shared/services/agency-profile-data.service';
import { MGFPointsConverter } from 'src/app/common/viewmodels/appsettingsviewmodel';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';

@Component({
  selector: 'app-hotel-list-view',
  templateUrl: './hotel-list-view.component.html',
  styleUrls: ['./hotel-list-view.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
    ]),
  ]
})
export class HotelListViewComponent implements OnInit, OnDestroy {
  _hotels: HotelViewModel[];

  @Input() set hotels(value: HotelViewModel[]) {
    this._hotels = value;
    this._hotels.forEach(hotel => {
      hotel.currentImageIndex = 0;
      if (this.frameworkConfigService.IsCallCenterUser) {
        hotel.roomCategoryInfo.forEach(rateCatInfo => {
          rateCatInfo.roomDetails.forEach(roomDetail => {
            if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('S')) {
              const supplierId = roomDetail.mgno.substring(1);
              if (!Utilities.isNullOrEmpty(supplierId) && !Utilities.isNullOrEmpty(this.callCenterDataService.supplierListData)) {
                this.callCenterDataService.supplierListData.subscribe(supplierdata => {
                  roomDetail.supplierName = supplierdata.filter(x => x.id === +supplierId).map(s => s.name)[0];
                });
              }
            } else if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('C')) {
              const channelManagerId = roomDetail.mgno.substring(1);
              // tslint:disable-next-line: max-line-length
              if (!Utilities.isNullOrEmpty(channelManagerId) && !Utilities.isNullOrEmpty(this.callCenterDataService.channelManagerListData)) {
                this.callCenterDataService.channelManagerListData.subscribe(cmdata => {
                  roomDetail.supplierName = 'MGDynamicCM - ' + cmdata.filter(x => x.id === +channelManagerId).map(s => s.name)[0];
                });
              }
            } else if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('T')) {
              const channelManagerId = roomDetail.mgno.substring(1);
              // tslint:disable-next-line: max-line-length
              if (!Utilities.isNullOrEmpty(channelManagerId) && !Utilities.isNullOrEmpty(this.callCenterDataService.channelManagerListData)) {
                this.callCenterDataService.channelManagerListData.subscribe(cmdata => {
                  roomDetail.supplierName = 'CMStatic - ' + cmdata.filter(x => x.id === +channelManagerId).map(s => s.name)[0];
                });
              }
            } else if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('D')) {
              const supplierId = roomDetail.mgno.substring(1);
              if (!Utilities.isNullOrEmpty(supplierId) && !Utilities.isNullOrEmpty(this.callCenterDataService.supplierListData)) {
                this.callCenterDataService.supplierListData.subscribe(supplierdata => {
                  roomDetail.supplierName = 'SupplierDirect - ' + supplierdata.filter(x => x.id === +supplierId).map(s => s.name)[0];
                });
              }
            }
          });
        });
      }
      hotel.roomCategoryInfo.forEach(rateCatInfo => {
        rateCatInfo.roomDetails.forEach(roomDetail => {
          if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('S')) {
            const supplierId = roomDetail.mgno.substring(1);
            if (!Utilities.isNullOrEmpty(supplierId)) {
              this.callCenterDataService.supplierListData.subscribe(supplierdata => {
                const supplierCode = supplierdata.filter(x => x.id === +supplierId).map(s => s.code)[0];
                // tslint:disable-next-line:max-line-length
                roomDetail.isMGPreferredSupplier = this.configurationService.config.listOfMGPreferredSupplier.includes(supplierCode) ? true : false;
              });
            }
          }
        });
      });
      // if (hotel.images.length > 0) {
      //   const interval = setInterval(() => {
      //     hotel.currentImageIndex = ++hotel.currentImageIndex % hotel.images.length;
      //   }, 5000);
      // }
    });
    /* this._hotels.forEach(hotel => {
      hotel.roomCategoryInfo.forEach(roomCategory => {
        roomCategory.roomDetails.forEach(room => {
          room.messages.forEach((message) => {
            room.hotelMessage = (room.hotelMessage ? room.hotelMessage : '') + message + '\n';
            room.hotelMessage.trim();
          });
        });
      });
    }); */
  }
  get hotels(): HotelViewModel[] {
    return this._hotels;
  }
  roomId: number;
  title: string;
  roomSize: string;
  beds: string;
  guest: string;
  facilities: any;
  description: string;
  smoking: string;
  subscriptions: Subscription[] = [];
  // imagelist: any[] = [];
  hotelImages: any[];
  showHotelImages: boolean;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  starRating: any;
  nightlyRate: any;
  noOfRooms: any;
  showNightlyRate: boolean;
  hotelRecheckData: HotelRecheckViewModel = <HotelRecheckViewModel>{};
  readonly maxRoomsToBeDisplayed = 1;
  contentUrl = this.configurationService.config.baseUrls.contentUrl;
  currentImageIndex: number;
  promotionTypes = CONSTANTS.promotionTypes;
  hotelName: string;
  mgPreferred: boolean;
  imageFolderPath = CONSTANTS.imageFolderPath.hotelList;
  supplierCode = SupplierCode;
  xmlError = errorConstants.recheckErrorMessage;
  errorCode: string;
  errorMessage: string;
  HotelsBookPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.HotelsBookProperties);
  disableBookForCallCenter: boolean;
  openDateHotelVoucher = this.configurationService.config.openDateHotelVoucher;
  safeStayPolicy = CONSTANTS.SafeStayPolicy;
  isVaccinatedClicked = false;
  isMGPointApplicable: any;
  mgPointForHotel: number;
  mGFPointsConverter:MGFPointsConverter;
  exchangeRate: number;
  
constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private hotelDataService: HotelDataService,
    private roomInfoDataService: RoomInfoDataService,
    private dialogsService: DialogsService,
    private zone: NgZone,
    public dialog: MatDialog,
    public currencyApi: CurrencyApi,
    private hotelRecheckService: HotelRecheckService,
    public configurationService: ConfigurationService,
    public googleAnalyticsService: GoogleAnalyticsService, private userProfileService: UserProfileService,
    public hotelBookService: HotelBookService,
    public hotelSearchService: HotelSearchService, public frameworkConfigService: FrameworkConfigService,
    private callCenterDataService: CallcenterService, public paymentDataService: PaymentDataService, private agencyProfileDataService: AgencyProfileDataService, public screenService: ScreenService,
    private configservice: ConfigurationService, private topBarService: TopBarService) { }

  ngOnInit() {
    this.isMGPointApplicable = this.agencyProfileDataService.isMGPointApplicable;
    this.exchangeRate = this.topBarService._exchangeRate;
    window.scrollTo(0, 0);
    console.log('Get Payment API call at: ' + new Date());
    const subscription =  this.paymentDataService.getPaymentTypeDetailsByAgencyId(
      this.currencyApi.selectedCurrency, this.userProfileService.getBasicUserInfo().agencyId).subscribe( data => {
        if (!data.isCreditBalance && !data.isWireTransfer && this.frameworkConfigService.IsCallCenterUser) {
          this.disableBookForCallCenter = true;
        } else {
          this.disableBookForCallCenter = false;
        }
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(data));
      },
      (error: JarvisError) => {
       // this.hideSpinner();
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
       // this.hideSpinner()
      );
    this.subscriptions.push(subscription);
    this.mGFPointsConverter = this.configservice.config.mgfPointsConverter;
  }
  onBookHotelClick(roomDetailViewModel: RoomDetailViewModel,
    roomCategoryInfoViewModel: RoomCategoryInfoViewModel, hotelId: string, selectedHotel: HotelViewModel) {
    this.hotelDataService.selectedHotel = selectedHotel; // , selectedHotel: HotelViewModel
    // Set selected room details .
    this.hotelBookService.bookFromUnbeatableDeals = false;
    this.hotelBookService.bookFromChainDeals = false;
    sessionStorage.setItem('bookFromBanners', JSON.stringify(false));
    // localStorage.setItem('bookFromPromotion', JSON.stringify(false));
    sessionStorage.setItem('bookFromPromotion', JSON.stringify(false));
    this.hotelRecheckService.mapSelectedRoomDetails(roomDetailViewModel,
      roomCategoryInfoViewModel.roomCode, hotelId);
    this.showSpinner();
    const subscription = this.hotelDataService.getRecheckAvailability(this.hotelRecheckService.recheckSearchDetails).
      // subscribe(data => console.log('Recheck Data' + JSON.stringify(data)));
      subscribe((data) => {
        if (data === null) {
          // tslint:disable-next-line:no-unused-expression
          // console.error(`Error in Recheck. ${error.friendlyMessage}`);
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          // tslint:disable-next-line: max-line-length
          dialogRef.componentInstance.message = `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.`;
          dialogRef.componentInstance.buttonText = 'OK';
          this.hideSpinner();
        } else {
          // tslint:disable-next-line: max-line-length
          if (!Utilities.isNullOrUndefined(data.errorModel) && data.errorModel.xmlErrorCode !== null && data.errorModel.errorCode === 200) {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Message';
            this.errorMessage = data.errorModel.message;
            let filterData = this.xmlError.filter(error => {
              if (data.errorModel.xmlErrorCode === error.code) {
                this.errorMessage = error.message;
              }
            });
            filterData = {}[''];
            dialogRef.componentInstance.message = this.errorMessage;
            dialogRef.componentInstance.buttonText = 'OK';
            this.hideSpinner();
          } else {
            this.hotelRecheckService.hotelRecheckDetails = data;
            this.hotelRecheckService.recheckSearchDetails.cancellationPolicyType = data.hotel.roomDetails.cancellationPolicyType;
            this.hotelRecheckService.recheckSearchDetails.packageRate = data.hotel.roomDetails.packageRate;
          }
        }
        if (data.hotel !== undefined) {
          if ((roomDetailViewModel.grossPrice !== data.hotel.roomDetails.grossPrice) &&
            (data.hotel.roomDetails.grossPrice - roomDetailViewModel.grossPrice) > 0 ? true : false) {

            let dialogRef: MatDialogRef<RecheckRateDialogComponent>;
            dialogRef = this.dialog.open(RecheckRateDialogComponent, {
              data: {
                updatedGrossPrice: data.hotel.roomDetails.grossPrice,
                oldGrossPrice: roomDetailViewModel.grossPrice,
                grossPriceDiff: (data.hotel.roomDetails.grossPrice
                  - roomDetailViewModel.grossPrice),
                isAvailable: data.hotel.roomDetails.isAvail,
                currency: data.currency,
                isCreditCard: false,
                updatedNetPrice: data.hotel.roomDetails.netPrice,
                oldNetPrice: data.hotel.roomDetails.netPrice,
                netPriceDiff: (data.hotel.roomDetails.netPrice
                  - data.hotel.roomDetails.netPrice),
                isSecondRecheck: false,
                isQRISDialog: false
              }
            });
            return dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
                this.router.navigate(['../guestdetails'], { relativeTo: this.activatedRoute });
              } else { return false; }
            });

          } else { this.router.navigate(['../guestdetails'], { relativeTo: this.activatedRoute }); }
        }
        window.scrollTo(0, 0);
        window.scroll(0, 0);
        // this.router.navigate(['../guestdetails'], { relativeTo: this.activatedRoute });
      },
        (error: JarvisError) => {
          this.hideSpinner();
          // console.error(`Error in Recheck. ${error.friendlyMessage}`);
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          // tslint:disable-next-line: max-line-length
          dialogRef.componentInstance.message = `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.`;
          dialogRef.componentInstance.buttonText = 'OK';
        },
        () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }
  // this.grossPrice = this.hotels.
  getRoomBedsDetails(bedsObj) {
    let bedsDetail = '';
    for (let i = 0; i < bedsObj.length; i++) {
      bedsDetail += bedsObj[i].noOfBeds + ' ' + bedsObj[i].bedType + ', ';
    }
    return bedsDetail.replace(/,\s*$/, ''); // Removes last comma and trims space
  }

  getRoomGuestDetails(maxOccupancy, maxChildren) {
    let guestDetails = maxOccupancy ? 'Room sleeps ' + maxOccupancy + ' guests ' : '';
    guestDetails += maxChildren ? '(up to ' + maxChildren + ' children)' : '';
    return guestDetails.trim();
  }

  getRoomAmenitiesDetails(amenitiesObj) {
    const amenitiesDetail = [];
    for (let i = 0; i < amenitiesObj.length; i++) {
      const facilities = amenitiesObj[i].facilities;
      if (facilities.length > 0) {
        for (let j = 0; j < facilities.length; j++) {
          amenitiesDetail.push(facilities[j].name);
        }
      }
    }
    return amenitiesDetail;
  }

  getRoomPhotosDetails(photosObj) {
    const photoArr = [];
    // primeNG galleria accepts url link in source index
    for (let i = 0; i < photosObj.length; i++) {
      photoArr.push({
        source: photosObj[i].url,
        title: '',
        alt: ''
      });
    }
    return photoArr;
  }

  openRoomInfoDialog(hotelCode, roomCode) {
    this.showSpinner();
    const subscription = this.roomInfoDataService.getRoomInfoDetails({
      hotelCode: hotelCode,
      roomCode: roomCode
    })
      .subscribe(response => {
        if (response.result) {
          const result = response.result;
          this.title = result.roomName;
          this.roomSize = result.size;
          this.description = result.roomDescription;
          this.smoking = result.isSmoking ? 'Allowed' : 'Non Smoking';
          this.hotelImages = result.photos.length > 0 ? this.getRoomPhotosDetails(result.photos) : [];
          this.beds = result.beds.length > 0 ? this.getRoomBedsDetails(result.beds) : '';
          this.guest = (result.maxOccupancy || result.maxChildren) ? this.getRoomGuestDetails(result.maxOccupancy, result.maxChildren) : '';
          this.facilities = result.amenities.length > 0 ? this.getRoomAmenitiesDetails(result.amenities) : '';

          const subscriptionDialogsService = this.dialogsService
            .openRoomInfoModal(this.title, this.hotelImages, this.roomSize, this.beds, this.guest, this.facilities,
              this.description, this.smoking)
            .subscribe(res => { },
              (error: JarvisError) => { this.hideSpinner(); console.error(`Error in getting room details. ${error.friendlyMessage}`); },
              () => this.hideSpinner());
          this.subscriptions.push(subscriptionDialogsService);
        }
      },
        (error: JarvisError) => { this.hideSpinner(); console.error(`Error in getting room details. ${error.friendlyMessage}`); },
        () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  goToHotelDetails(selectedHotel: HotelViewModel) {
    // ------------- Hotel Browse -------------------------
    const googleAnalyticsHotelBrowse: GoogleAnalyticsViewModel = {
      eventCategory: 'Hotel',
      eventAction: 'Browse',
      eventLabel: null,
      eventValue: null
    };
    this.googleAnalyticsService.customHotelBookBrowseEventEmitter(selectedHotel.hotelName, googleAnalyticsHotelBrowse);
    // ------------- Hotel Browse Ends -------------------------
    if (this.frameworkConfigService.IsCallCenterUser) {
      localStorage.setItem('exchangeRate', ''+this.topBarService._exchangeRate);
      this.hotelDataService.selectedHotel = selectedHotel;
      this.router.navigate(['../hoteldetails'], { relativeTo: this.activatedRoute });
    } else {
      localStorage.setItem('exchangeRate', ''+this.topBarService._exchangeRate);
      this.hotelBookService.bookFromUnbeatableDeals = false;
      this.hotelBookService.bookFromChainDeals = false;
      sessionStorage.setItem('bookFromBanners', JSON.stringify(false));
      // localStorage.setItem('bookFromPromotion', JSON.stringify(false));
      sessionStorage.setItem('bookFromPromotion', JSON.stringify(false));
      this.hotelDataService.selectedHotel = selectedHotel;
      localStorage.setItem('selectedHotel', JSON.stringify(selectedHotel));
      localStorage.setItem('guestNationalityName', this.hotelSearchService.guestNationalityName);
      this.router.navigate([], { relativeTo: this.activatedRoute }).then(res => {
        window.open('authenticated/hotelmgmt/hoteldetails', '_blank');
      });
    }
  }

  getNightlyRate(hotelId, roomCode, roomDetailIndex) {
    const nightlyRate = {
      currency: '',
      roomCount: 0,
      dates: [],
      rooms: [],
      totals: []
    };
    // Set default value
    this.nightlyRate = {};
    this.showNightlyRate = false;
    this.noOfRooms = 0;
    // Get filtered hotel obj
    const filteredHotelObj = this.getFilteredHotelForNightlyRate(hotelId, roomCode);
    const roomCategoryInfoObj = this.getObjByKey(filteredHotelObj, 'roomCategoryInfo');
    for (let j = 0; j < roomCategoryInfoObj.length; j++) {
      const roomDetailsObj = this.getObjByKey(roomCategoryInfoObj[j], 'roomDetails');
      const priceDetailsObj = this.getObjByKey(roomDetailsObj[roomDetailIndex], 'priceDetails');
      // Get room count
      if (nightlyRate.roomCount === 0 && priceDetailsObj.length !== 0) {
        nightlyRate.roomCount = priceDetailsObj.length;
      }
      for (let l = 0; l < priceDetailsObj.length; l++) {
        for (const pdKey in priceDetailsObj[l]) {
          if (priceDetailsObj[l].hasOwnProperty(pdKey)) {
            // Get currency code
            if (pdKey === 'currencyCode' && !nightlyRate.currency) {
              nightlyRate.currency = priceDetailsObj[l]['currencyCode'];
            }

            if (pdKey === 'nightlyRate') {
              const nightlyRateObj = priceDetailsObj[l][pdKey];
              for (let m = 0; m < nightlyRateObj.length; m++) {
                for (const nrKey in nightlyRateObj[m]) {
                  if (nightlyRateObj[m].hasOwnProperty(nrKey)) {
                    // Get dates
                    if (nrKey === 'date') {
                      if (!nightlyRate.dates.includes(nightlyRateObj[m]['date'])) {
                        nightlyRate.dates.push(nightlyRateObj[m]['date']);
                      }
                    }
                    // Get gross price
                    if (nrKey === 'grossPrice') {
                      if (!nightlyRate.rooms.includes(nightlyRate.rooms[m])) {
                        nightlyRate.rooms[m] = [];
                      }
                      nightlyRate.rooms[m].push(nightlyRateObj[m]['grossPrice']);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // Get total for each room(s) respectively
    nightlyRate.totals = nightlyRate.rooms.reduce((r, a) => a.map((b, index) => (r[index] || 0) + b), []);
    // Get no of rooms for making table heading
    this.noOfRooms = Array(nightlyRate.roomCount).fill(0).map((x, i) => i);
    this.nightlyRate = nightlyRate;
    this.showNightlyRate = true;
  }

  getObjByKey(obj, key): any {
    let returnObj = {};
    for (const objKey in obj) {
      if (obj.hasOwnProperty(objKey)) {
        if (objKey === key) {
          returnObj = obj[key];
        }
      }
    }
    return returnObj;
  }

  getFilteredHotelForNightlyRate(hotelId, roomCode) {
    let hotels = JSON.parse(JSON.stringify(this.hotels));
    hotels = hotels.filter(h => hotelId === h.hotelId);
    hotels = hotels.filter(h => {
      let hotelFound = false;
      h.roomCategoryInfo = h.roomCategoryInfo.filter(rc => {
        let roomFound = false;
        roomFound = roomCode === rc.roomCode ? true : false;
        return roomFound;
      });
      hotelFound = h.roomCategoryInfo.length > 0 ? true : false;
      return hotelFound;
    });
    return hotels[0];
  }

  /* openHotelImages(hotelimages) {
    if (hotelimages && hotelimages.length > 0) {
      hotelimages.forEach((element) => {
        this.imagelist.push({ source: element.imagePath });
      });
    }
    this.showHotelImages = true;
  } */

  openHotelImagesDialog(hotelCode: string, hotel) {
    this.showSpinner();
    this.hotelImages = [];
    this.hotelDataService.getHotelPhotos(hotelCode).subscribe((images) => {
      for (let i = 0; i < images.length; i++) {
        this.hotelImages.push({
          source: images[i].filePath,
          title: '',
          alt: ''
        });
      }
      this.showHotelImages = true;
      this.hotelName = hotel.hotelName;
      this.mgPreferred = hotel.isMgPreferred;
      this.starRating = hotel.starRating;
    },
      (error: JarvisError) => { this.hideSpinner(); console.error(`Error in getting hotel photos. ${error.friendlyMessage}`); },
      () => this.hideSpinner());
  }

  closeHotelImages() {
    this.showHotelImages = false;
    // this.imagelist = [];
    this.hotelImages = [];
  }

  openHotelMap(hotel: HotelViewModel) {
    const hotelSummary = new HotelSummaryMapViewModel();
    hotelSummary.hotelId = hotel.hotelId;
    hotelSummary.hotelName = hotel.hotelName;
    hotelSummary.address = hotel.address;
    hotelSummary.grossPrice = hotel.grossPrice;
    hotelSummary.starRating = hotel.starRating;
    hotelSummary.currency = this.currencyApi.selectedCurrency;
    hotelSummary.latitude = hotel.latitude;
    hotelSummary.longitude = hotel.longitude;
  }

  // openMap(mapGLat, mapGLng, selectedHotel: HotelViewModel, hotel: HotelViewModel): void {
  openMap(hotel: HotelViewModel): void {
    const hotelSummary = new HotelSummaryMapViewModel();
    hotelSummary.hotelId = hotel.hotelId;
    hotelSummary.hotelName = hotel.hotelName;
    hotelSummary.address = hotel.address;
    hotelSummary.grossPrice = hotel.grossPrice;
    hotelSummary.starRating = hotel.starRating;
    hotelSummary.currency = this.currencyApi.selectedCurrency;
    hotelSummary.latitude = hotel.latitude;
    hotelSummary.longitude = hotel.longitude;
    hotelSummary.facilities = hotel.facilities;
    hotelSummary.isMgPreferred = hotel.isMgPreferred;
    hotelSummary.image = (hotel.images.length > 0) ?
      hotel.images[0] : null;
    this.hotelDataService.selectedHotel = hotel;
    this.zone.run(() => {
      const subscription = this.dialog.open(MapsdialogComponent, {
        width: '700px',
        height: '560px',
        // data: { latitude: +mapGLat, longitude: +mapGLng }
        data: { hotel: hotelSummary }
      }).afterClosed().subscribe(result => {
        const retResult = `${result}`;
        if (retResult !== 'undefined' && retResult !== 'unselected') {
          const parseresult = JSON.parse(result);
          // this.hotelDetailsGroup.get('latLong').setValue(parseresult.Lat + '/' + parseresult.Lng);
        }
      });
      this.subscriptions.push(subscription);
    });
  }

  selectDisplayImage(hotel, image) {
    hotel.currentImageIndex = hotel.images.indexOf(image);
  }


  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  expandRooms(hotel) {
    hotel.isExpanded = !hotel.isExpanded;
  }

  openSafeStayPolicy() {
    localStorage.setItem('isVaccinatedClicked', JSON.stringify(this.isVaccinatedClicked));
    this.router.navigate([]).then(result => { window.open('siteinfomgmt/safestaypolicies', '_blank'); });
  }

  displayExtraBedIcon(priceDetailsArray) {
    let isExtrabed = false;
    for (let i = 0; i < priceDetailsArray.length; i++) {
      if (priceDetailsArray[i].isExtrabed === true) {
        isExtrabed = true;
        break;
      }
    }
    return isExtrabed;
  }

  getTotalRoomCategoryCount(roomCategoryInfo: any): number {
    let total = 0;
    roomCategoryInfo.forEach(element => {
      total = total + element.roomDetails.length;
    });
    return total; 
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.hideSpinner();
    this.dispose();
  }

}

@Component({
  selector: 'app-recheck-rate-dialog',
  templateUrl: 'recheck-rate-dialog.html'
})
export class RecheckRateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RecheckRateDialogComponent>,
    private hotelRecheckService: HotelRecheckService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }
}
