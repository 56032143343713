import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {
  title: string;
  message: string;
  messages: any[];
  buttonText: string;
  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>) {}

  ngOnInit() {
  }

}
