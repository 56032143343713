import { FrameworkConfigService } from './../../../../../framework/fw/services/framework-config.service';
import { UserProfileService } from './../../../../../common/shared/services/user-profile.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullBookingResponseDetailsViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-response-viewmodel';
import { NationalityViewModel } from 'src/app/b2b/viewmodels/group-booking/nationalityviewmodel';
import { DatePipe } from '@angular/common';
import { HotelDataService } from '../../services/hotel-data.service';
import { CancelBookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-request-viewmodel';
import { CancelBookingResponseViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';
import { DialogsService } from '../../dialogs/dialogs.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { JarvisError } from 'src/app/common/jarviserror';
import { Subscription } from 'rxjs';
import { checkNoChangesInRootView } from '@angular/core/src/render3/instructions';
import { ReservationType, Document, DocumentTypes, ActionStatus, PaymentAction } from '../../../../../common/enum';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { ErrorCodes } from 'src/app/common/errorcodes';
import { ReservationBookingCancelPreviewDialog } from './ReservationBookingCancelPreviewDialog.component';
import { HotelBookService } from '../../services/hotel-book.service';
import { HoldBookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/hold-booking-request-viewmodel';
import { ReservationDataService } from '../../services/reservation-data.service';
import { CONSTANTS } from '../../../../../common/constants';
import { EmailPdfRequestViewModel } from 'src/app/b2b/viewmodels/reservation/invoice-voucher/emailpdfrequestviewmodel';
import { InvoiceAndVouchers } from 'src/app/b2b/viewmodels/reservation/invoice-voucher/invoiceandvouchers';
import { Utilities } from 'src/app/common/utilities/utilities';
import { errorConstants } from 'src/app/common/friendlymessage';
import { ScreenService } from '../../../../../framework/fw/services/screen.service';
import { PaymentDataService } from '../../services/payment-data.service';
import { PaymentTypeDetailsViewModel } from '../../../../viewmodels/payment/paymenttypedetailsviewmodel';
import { HotelRecheckService } from '../../services/hotel-recheck.service';
import { BookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-request-viewmodel';
import { MGFPointsConverter } from 'src/app/common/viewmodels/appsettingsviewmodel';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';

@Component({
  selector: 'app-reservation-details',
  templateUrl: './reservation-details.component.html',
  styleUrls: ['./reservation-details.component.css']
})
export class ReservationDetailsComponent implements OnInit {

  @Input() reservationDetails: FullBookingResponseDetailsViewmodel;
  holdBookingData: boolean;
  totalPrice: number;
  totalNoOfAdults: number;
  totalNoOfChildren: number;
  nights: number;
  nationalityList: NationalityViewModel[];
  nationality: string;
  todaysDate: string;
  agencyId: string;
  agencyReferenceNo: string;
  bookingId: string;
  cancelBookingDataReservation: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
  bookingAmount: number;
  checkinDate: string;
  checkoutDate: string;
  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  bookingStatus: string;
  currency: string;
  buttonStatus: any = {};
  nightlyRatesDateList: Date[] = [];
  promotions: string;
  isOnHold: boolean;
  documentId: string;
  fileType: string;
  documentTypes = DocumentTypes;
  invoiceAndVouchersList: InvoiceAndVouchers[];
  emailPdfRequest: EmailPdfRequestViewModel;
  agencyVoucher: InvoiceAndVouchers;
  agencyInvoice: InvoiceAndVouchers;
  customerVoucher: InvoiceAndVouchers;
  customerInvoice: InvoiceAndVouchers;
  bookingItenerary: InvoiceAndVouchers;
  reservationDetailsStatus = CONSTANTS.reservationDetailsStatus;
  isInvoiceVouchers = false;
  paramObj: any;
  ViewBookingsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewBookings);
  HotelsBookPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.HotelsBookProperties);
  cancelReservationXmlError = errorConstants.cancelReservationErrorMessage;
  errorCode: string;
  errorMessage: string;
  promotionTypes = CONSTANTS.promotionTypes;
  paymentBy = CONSTANTS.paymentBy;
  bookingSource: string;
  specialRequest: string;
  StatusFlagForMg: boolean;
  // Payment Gateway
  paymentGatewayRequest: any;
  paymentTypeDetails: PaymentTypeDetailsViewModel;
  paymentTypeCodes = CONSTANTS.paymentTypeCodes;
  paymentGatways = CONSTANTS.PaymentGateway;
  paymentAction = PaymentAction;
  actionStatus = ActionStatus;
  mGFPointsConverter:MGFPointsConverter;
  exchangeRate: number;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute, private datePipe: DatePipe,
    private hotelDataService: HotelDataService, public dialog: MatDialog,
    private dialogsService: DialogsService, private hotelBookService: HotelBookService,
    private reservationDataService: ReservationDataService,
    private userProfileService: UserProfileService,
    public frameWorkConfigService: FrameworkConfigService,
    public paymentDataService: PaymentDataService,
    public screenService: ScreenService,
    private configservice: ConfigurationService, private topBarService: TopBarService) { }

  ngOnInit() {
    this.StatusFlagForMg = false;
    if (!Utilities.isNullOrEmpty(this.reservationDetails.bookingDetails.status)
      // tslint:disable-next-line:max-line-length
      && this.reservationDetails.bookingDetails.status === 'CONF' && !Utilities.isNullOrEmpty(this.reservationDetails.bookingDetails.mgPoints) && this.reservationDetails.bookingDetails.mgPoints > 0) {
      this.StatusFlagForMg = true;
    }
    if (!Utilities.isNullOrEmpty(this.reservationDetails.bookingDetails.specialRequest)) {
      if (this.reservationDetails.bookingDetails.specialRequest.includes('Hotel Product Code:')) {
        const specialRequestVal = this.reservationDetails.bookingDetails.specialRequest.split('Hotel Product Code:');
        Utilities.isNullOrEmpty(specialRequestVal[0]) ? this.specialRequest = 'N/A' : this.specialRequest = specialRequestVal[0];
      } else if (this.reservationDetails.bookingDetails.specialRequest.includes('Hotel Promo Code:')) {
        const specialRequestValue = this.reservationDetails.bookingDetails.specialRequest.split('Hotel Promo Code:');
        Utilities.isNullOrEmpty(specialRequestValue[0]) ? this.specialRequest = 'N/A' : this.specialRequest = specialRequestValue[0];
      } else {
        this.specialRequest = this.reservationDetails.bookingDetails.specialRequest;
      }
    } else {
      this.specialRequest = 'N/A';
    }
    this.bookingSource = this.activatedRoute.snapshot.params['bookingSource'];
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.paramObj = { ...params.keys, ...params };
    });
    this.isInvoiceVouchers = this.paramObj.params['isInvoiceVouchers'] || false;
    this.todaysDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd').toString();
    this.checkinDate = this.datePipe.transform(this.reservationDetails.bookingDetails.checkIn);
    this.checkoutDate = this.datePipe.transform(this.reservationDetails.bookingDetails.checkOut);
    this.nationalityList = this.activatedRoute.snapshot.parent.data['nationalityList'];
    this.bookingStatus = this.activatedRoute.snapshot.params['bookingstatus'];
    this.nationality = this.nationalityList
      .find(nationality => nationality.code === this.reservationDetails.bookingDetails.nationality).name;
    this.totalPrice = 0;
    this.bookingAmount = this.reservationDetails.bookingDetails.hotels.roomDetails.grossPrice;
    this.totalNoOfAdults = 0;
    this.totalNoOfChildren = 0;
    this.documentId = this.reservationDataService.documentId;
    this.reservationDetails.bookingDetails.hotels.roomDetails.rooms.forEach(room => {
      this.totalPrice = this.totalPrice + room.grossPrice;
      this.totalNoOfAdults = this.totalNoOfAdults + room.noOfAdults;
      this.totalNoOfChildren = this.totalNoOfChildren + room.noOfChild;
      this.agencyId = this.activatedRoute.snapshot.params['agencyid'];
      this.agencyReferenceNo = this.activatedRoute.snapshot.params['agencyreferenceno'];
      this.bookingId = this.activatedRoute.snapshot.params['id'];
      for (const d = new Date(this.checkinDate); d <= new Date(this.checkoutDate); d.setDate(d.getDate() + 1)) {
        this.nightlyRatesDateList.push(new Date(d));
      }
      this.promotions = room.discountRates
        .map(promo => (promo.name.toLowerCase() === this.promotionTypes.Opaque.toLowerCase() ? 'Package Rate' :
          promo.name.toLowerCase() === this.promotionTypes.FreeNights.toLowerCase() ? 'Bonus Nights' : promo.name)).join(', ');
    });
    this.setButtonStatus();
    const checkIn = new Date(this.reservationDetails.bookingDetails.checkIn);
    const checkOut = new Date(this.reservationDetails.bookingDetails.checkOut);
    this.nights = Math.round(Math.abs(checkOut.getTime() - checkIn.getTime()) / (24 * 60 * 60 * 1000));
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.bookingStatus = params ['Status'];
    // });
    this.currency = this.reservationDetails.bookingDetails.currency;
    this.topBarService.getCurrencyExchangeRateForMGF(this.currency);
    this.exchangeRate = this.topBarService._exchangeRate;
    this.getInvoiceAndVouchersList(this.bookingId, this.documentId);
    const subscription = this.paymentDataService.getPaymentTypeDetailsByAgencyId(
      this.currency, this.userProfileService.getBasicUserInfo().agencyId).subscribe(data => {
        this.paymentTypeDetails = data;
        /* if (this.paymentTypeDetails.isDisableCreditLimit && (this.router.url.search('hotelinfo') > -1 ||
          this.router.url.search('agencysearchinfo') > -1) && !this.isTriggered) {
          this.showIsDisableCreditOption = true;
        } */
      },
        (error: JarvisError) => {
          console.error(`Error in Getting Agency data. ${error.friendlyMessage}`);
        },
      );
    this.subscriptions.push(subscription);
    this.mGFPointsConverter = this.configservice.config.mgfPointsConverter;
  }

  editBooking() {
    this.router.navigate(['/authenticated/bookingmgmt/editbooking',
      this.bookingId, this.agencyReferenceNo]);
  }

  showBookingStatus(status) {
    switch (status) {
      case 'CONF': return 'CONFIRMED';
      case 'NOTCONF': return 'NOT CONFIRMED';
      case 'CANCEL': return 'CancelRequest';
      case 'CANCELCONF': return 'Cancelled Free';
      case 'CANCELCHARGE': return 'Cancelled Charged';
      case 'OnHold': return 'ON HOLD';
      case 'RESERVED': return 'RESERVED';
    }
  }

  setButtonStatus() {
    this.calculateDaysCount();
    if (this.cancelBookingDataReservation.daysBefore < 0) {
      this.buttonStatus.showConfirm = false;
      this.buttonStatus.showEdit = false;
      this.buttonStatus.showCancel = false;
    } else if (this.bookingStatus === 'CONF' || this.bookingStatus === 'Confirmed') {
      this.buttonStatus.showCancel = true;
      this.buttonStatus.showEdit = true;
      this.buttonStatus.showConfirm = false;
    } else if (this.bookingStatus === 'OnHold' || this.bookingStatus === 'On Hold') {
      this.buttonStatus.showCancel = true;
      this.buttonStatus.showEdit = true;
      this.buttonStatus.showConfirm = true;
      this.isOnHold = true;
    } else if (this.bookingStatus === 'CANCELCONF' || this.bookingStatus === 'CANCELCHARGE'
      || this.bookingStatus === 'Cancelled') {
      this.buttonStatus.showCancel = false;
      this.buttonStatus.showEdit = false;
      this.buttonStatus.showConfirm = false;
    } else if (this.bookingStatus.toLowerCase() === this.reservationDetailsStatus.RESERVED.toLowerCase()) {
      this.buttonStatus.showCancel = true;
      this.buttonStatus.showEdit = true;
    }
  }
  goToReservationList() {
    // window.history.back();
    this.router.navigate(['/authenticated/bookingmgmt/reservationList'],
      { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
  }
  calculateDaysCount() {
    const dt1 = new Date(this.todaysDate);
    const dt2 = new Date(this.checkinDate);
    this.cancelBookingDataReservation.daysBefore = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }
  calculateRefundAmount() {
    this.cancelBookingDataReservation.refundAmount = isNaN(this.bookingAmount - this.cancelBookingDataReservation.chargedAmount) ? 0 :
      this.bookingAmount - this.cancelBookingDataReservation.chargedAmount;
  }
  cancelConfirmBooking() {
    const cancelBookingRequestViewmodel = new CancelBookingRequestViewmodel();
    cancelBookingRequestViewmodel.AgencyBookingId = this.agencyReferenceNo;
    cancelBookingRequestViewmodel.CancelDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    cancelBookingRequestViewmodel.MGBookingId = this.bookingId;
    cancelBookingRequestViewmodel.SimulationFlag = true;
    const subscription = this.hotelDataService.cancelBookingReservation(cancelBookingRequestViewmodel).subscribe((response) => {
      if (response === null) {
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        // tslint:disable-next-line: max-line-length
        dialogRef.componentInstance.message = `Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.`;
        dialogRef.componentInstance.buttonText = 'OK';
        this.hideSpinner();
      } else {
        // tslint:disable-next-line: max-line-length
        if (!Utilities.isNullOrUndefined(response.errorModel) && response.errorModel.xmlErrorCode !== null && response.errorModel.errorCode === 200) {
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Message';
          this.errorMessage = response.errorModel.message;
          let filterData = this.cancelReservationXmlError.filter(error => {
            if (response.errorModel.xmlErrorCode === error.code) {
              this.errorMessage = error.message;
            }
          });
          filterData = {}[''];
          dialogRef.componentInstance.message = this.errorMessage;
          dialogRef.componentInstance.buttonText = 'OK';
          this.hideSpinner();
        } else {
          this.cancelBookingDataReservation = response;
          this.cancelBookingDataReservation.bookingAmount = this.bookingAmount;
          this.cancelBookingDataReservation.currency = this.currency;
          this.cancelBookingDataReservation.nationality = this.nationality;
          this.cancelBookingDataReservation.paymentBy = this.reservationDetails.bookingDetails.paymentBy;
          this.calculateDaysCount();
          this.calculateRefundAmount();
          this.hotelBookService.cancelBookingDetailsReservation = this.cancelBookingDataReservation;
          this.dialog.open(ReservationBookingCancelPreviewDialog,
            {
              data: this.cancelBookingDataReservation,
              panelClass: "reservation-cancel-preview-dialog"
            });
        }
      }
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Cancel Booking. ${error.friendlyMessage}`);
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  holdBookingPreview() {
    if (this.reservationDetails.bookingDetails.paymentBy === this.paymentBy.creditCard || this.reservationDetails.bookingDetails.paymentBy === this.paymentBy.qris || this.reservationDetails.bookingDetails.paymentBy === this.paymentBy.bankTransfer) {
      sessionStorage.setItem('isOnHold', 'TRUE');
      if (this.reservationDetails.bookingDetails.paymentBy === this.paymentBy.creditCard) {
        this.paymentTypeDetails.mGBookingID = this.reservationDetails.bookingDetails.mgBookingID;
        this.paymentTypeDetails.docId = this.reservationDetails.bookingDetails.mgBookingVersionID;
        this.paymentTypeDetails.netPrice = this.reservationDetails.bookingDetails.hotels.roomDetails.netPrice;
        this.paymentTypeDetails.grossPrice = this.reservationDetails.bookingDetails.hotels.roomDetails.grossPrice;
        this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.creditCard;
        /* const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
        const checkindate = q[0].toLocaleDateString('en-US');
        const checkoutdate = q[1].toLocaleDateString('en-US');
        const checkInCheckoutDate = [];
        checkInCheckoutDate[0] = checkindate;
        checkInCheckoutDate[1] = checkoutdate;
        this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate = checkInCheckoutDate; */
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        sessionStorage.setItem('reservationDetails', JSON.stringify(this.reservationDetails));
        // sessionStorage.setItem('bookingDate', JSON.stringify(this.bookingDate));
        // sessionStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
        // sessionStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
        // sessionStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
        // this.paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails'));
        this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
        // tslint:disable-next-line:max-line-length

        if (this.paymentTypeDetails.ccPaymentGatewayCode !== null &&
          this.paymentTypeDetails.ccPaymentGatewayCode.toUpperCase() === this.paymentGatways.asiaPay) {
          const subscription3 = this.paymentDataService.getSecureHash(this.reservationDetails.bookingDetails.mgBookingID).subscribe(data => {
            const authorizationRequest = {
              merchantId: this.paymentTypeDetails.ccMerchantId,
              // tslint:disable-next-line:max-line-length
              amount: this.currency === CONSTANTS.idrCurrencyCode || this.currency === CONSTANTS.vndCurrencyCode ?
                Math.ceil(this.paymentTypeDetails.netPrice) : this.paymentTypeDetails.netPrice,
              orderRef: this.paymentTypeDetails.mGBookingID,
              currCode: this.paymentTypeDetails.currencyCode,
              mpsMode: 'NIL',
              successUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentsuccess`,
              failUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentfailed`,
              cancelUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentcancelled`,
              payType: 'H',
              lang: 'E',
              payMethod: 'CC',
              secureHash: data
            };
            this.paymentGatewayRequest = {
              mGBookingId: this.paymentTypeDetails.mGBookingID,
              creditCardConfigurationId: this.paymentTypeDetails.ccPaymentGatewayId,
              amount: this.paymentTypeDetails.netPrice,
              status: this.actionStatus[this.actionStatus.Pending],
              action: this.paymentAction[this.paymentAction.Authorization],
              authorizationRequest: JSON.stringify(authorizationRequest),
              merchantId: this.paymentTypeDetails.ccMerchantId
            };
            const subscription1 = this.paymentDataService.updatePaymentDetails(this.paymentGatewayRequest).subscribe(data1 => {
              this.router.navigate(['/authenticated/payment/paymentgateway']);
            },
              (error: JarvisError) => console.error(`Error in Update Payment Details. ${error.friendlyMessage}`));
            this.subscriptions.push(subscription1);
          },
            (error: JarvisError) => console.error(`Error in Getting Secure Hash Key. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription3);
        } else if (this.paymentTypeDetails.ccPaymentGatewayCode !== null &&
          this.paymentTypeDetails.ccPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
          this.paymentGatewayRequest = {
            mGBookingId: this.paymentTypeDetails.mGBookingID,
            creditCardConfigurationId: this.paymentTypeDetails.ccPaymentGatewayId,
            amount: this.paymentTypeDetails.netPrice,
            status: this.actionStatus[this.actionStatus.Pending],
            action: this.paymentAction[this.paymentAction.Authorization],
            merchantId: this.paymentTypeDetails.ccMerchantId,
            actualAmount: this.paymentTypeDetails.netPrice
          };
          const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
            this.router.navigate(['/authenticated/payment/paymentgateway']);
          },
            (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription1);
        }
      } else if (this.reservationDetails.bookingDetails.paymentBy === this.paymentBy.qris) {
        this.paymentTypeDetails.mGBookingID = this.reservationDetails.bookingDetails.mgBookingID;
        this.paymentTypeDetails.docId = this.reservationDetails.bookingDetails.mgBookingVersionID;
        this.paymentTypeDetails.netPrice = this.reservationDetails.bookingDetails.hotels.roomDetails.netPrice;
        this.paymentTypeDetails.grossPrice = this.reservationDetails.bookingDetails.hotels.roomDetails.grossPrice;
        this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.qris;
        // this.paymentDataService.paymentTypeDetails = this.paymentTypeDetails;
        // localStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        // localStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
        // localStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
        // localStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
        /* const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
        const checkindate = q[0].toLocaleDateString('en-US');
        const checkoutdate = q[1].toLocaleDateString('en-US');
        const checkInCheckoutDate = [];
        checkInCheckoutDate[0] = checkindate;
        checkInCheckoutDate[1] = checkoutdate;
        this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate = checkInCheckoutDate; */
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        sessionStorage.setItem('reservationDetails', JSON.stringify(this.reservationDetails));
        // sessionStorage.setItem('bookingDate', JSON.stringify(this.bookingDate));
        /* sessionStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
        sessionStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
        sessionStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
        this.paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails')); */
        this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
        // tslint:disable-next-line:max-line-length

        // Note: QRIS is only implemented for XENDIT
        if (this.paymentTypeDetails.qrisPaymentGatewayCode !== null &&
          this.paymentTypeDetails.qrisPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
          this.paymentGatewayRequest = {
            mGBookingId: this.paymentTypeDetails.mGBookingID,
            creditCardConfigurationId: this.paymentTypeDetails.qrisPaymentGatewayId,
            amount: this.paymentTypeDetails.netPrice,
            status: this.actionStatus[this.actionStatus.Pending],
            action: this.paymentAction[this.paymentAction.Authorization],
            merchantId: this.paymentTypeDetails.qrisMerchantId,
            actualAmount: this.paymentTypeDetails.netPrice
          };
          const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
            this.router.navigate(['/authenticated/payment/paymentgateway']);
          },
            (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription1);
        }
      } else if (this.reservationDetails.bookingDetails.paymentBy === this.paymentBy.bankTransfer) {
        this.paymentTypeDetails.mGBookingID = this.reservationDetails.bookingDetails.mgBookingID;
        this.paymentTypeDetails.docId = this.reservationDetails.bookingDetails.mgBookingVersionID;
        this.paymentTypeDetails.netPrice = this.reservationDetails.bookingDetails.hotels.roomDetails.netPrice;
        this.paymentTypeDetails.grossPrice = this.reservationDetails.bookingDetails.hotels.roomDetails.grossPrice;
        this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.bankTransfer;
        // this.paymentDataService.paymentTypeDetails = this.paymentTypeDetails;
        // localStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        // localStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
        // localStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
        // localStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
        /* const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
        const checkindate = q[0].toLocaleDateString('en-US');
        const checkoutdate = q[1].toLocaleDateString('en-US');
        const checkInCheckoutDate = [];
        checkInCheckoutDate[0] = checkindate;
        checkInCheckoutDate[1] = checkoutdate;
        this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate = checkInCheckoutDate; */
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        sessionStorage.setItem('reservationDetails', JSON.stringify(this.reservationDetails));
        // sessionStorage.setItem('bookingDate', JSON.stringify(this.bookingDate));
        /* sessionStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
        sessionStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
        sessionStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals)); */
        // this.paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails'));
        this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
        // tslint:disable-next-line:max-line-length

        // Note: Bank Transfer is only implemented for XENDIT
        if (this.paymentTypeDetails.btPaymentGatewayCode !== null &&
          this.paymentTypeDetails.btPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
          this.paymentGatewayRequest = {
            mGBookingId: this.paymentTypeDetails.mGBookingID,
            creditCardConfigurationId: this.paymentTypeDetails.btPaymentGatewayId,
            amount: this.paymentTypeDetails.netPrice,
            status: this.actionStatus[this.actionStatus.Pending],
            action: this.paymentAction[this.paymentAction.Authorization],
            merchantId: this.paymentTypeDetails.btMerchantId,
            actualAmount: this.paymentTypeDetails.netPrice
          };
          const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
            this.router.navigate(['/authenticated/payment/paymentgateway']);
          },
            (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription1);
        }
      }
    } else {
      const dialogRef = this.dialog.open(ReservationHoldConfirmDialog, {
        data: { id: this.bookingId, refNo: this.agencyReferenceNo, agencyGUID: this.agencyId, checkinDate: this.checkinDate }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isOnHold = result.data.onHold;
          this.bookingStatus = result.data.bookingStatus;
          this.bookingId = result.data.bookingId;
          this.documentId = result.data.documentId;
          this.isInvoiceVouchers = result.data.isInvoiceVouchers;
          this.getInvoiceAndVouchersList(this.bookingId, this.documentId);
          this.goUserView(result.data);
          window.scrollTo(0, 0);
          window.scroll(0, 0);
        }
      });
    }
  }
  goUserView(value) {
    this.router.navigate(['authenticated/bookingmgmt/reservations/reservationdetails',
      value.bookingId, value.bookingStatus, value.refNo, value.agencyId, value.checkinDate, value.operation.trim().toLowerCase(),
      value.bookingSource],
      {
        // relativeTo: this.activatedRoute,
        queryParams: {
          'isInvoiceVouchers': this.isInvoiceVouchers,
        }
      }
    );
  }

  getInvoiceAndVouchersList(bookingId: string, documentId: string) {
    this.showSpinner();
    const subscription = this.reservationDataService.getInvoiceAndVouchers(bookingId, documentId).subscribe((invoiceAndVouchersList) => {
      this.invoiceAndVouchersList = invoiceAndVouchersList;
      this.invoiceAndVouchersList.forEach(element => {
        if (element.documentType.toLowerCase() === CONSTANTS.documentType.agency.toLowerCase()) {
          if (element.fileType.toLowerCase().toLowerCase() === CONSTANTS.document.Voucher.toLowerCase()) {
            this.agencyVoucher = element;
          } else if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
            this.agencyInvoice = element;
          }
        } else if (element.documentType.toLowerCase() === CONSTANTS.documentType.customer.toLowerCase()) {
          if (element.fileType.toLowerCase() === CONSTANTS.document.Voucher.toLowerCase()) {
            this.customerVoucher = element;
          } else if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
            this.customerInvoice = element;
          } else if (element.fileType.toLowerCase() === CONSTANTS.document.Itinerary.toLowerCase()) {
            this.bookingItenerary = element;
          }
        }
      });
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Getting Reservation- getInvoiceAndVouchersList. ${error.friendlyMessage}`);
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  buildDownloadOrSendEmailRequest(reservationType, doc, docType) {
    this.emailPdfRequest = new EmailPdfRequestViewModel();
    this.emailPdfRequest.agencyId = Number(this.agencyId);
    this.emailPdfRequest.MGReservationNumber = this.bookingId;
    this.emailPdfRequest.reservationDocId = this.documentId;

    if (reservationType.toUpperCase() === CONSTANTS.reservationType.New) {
      this.emailPdfRequest.reservationType = ReservationType.New;
    } else {
      this.emailPdfRequest.reservationType = ReservationType.Cancel;
    }

    if (doc.toUpperCase() === CONSTANTS.document.All) {
      this.emailPdfRequest.document = Document.All;
    } else if (doc.toUpperCase() === CONSTANTS.document.Invoice) {
      this.emailPdfRequest.document = Document.Invoice;
    } else if (doc.toUpperCase() === CONSTANTS.document.Voucher) {
      this.emailPdfRequest.document = Document.Voucher;
    } else {
      this.emailPdfRequest.document = Document.Itinerary;
    }

    if (docType.toUpperCase() === CONSTANTS.documentType.agency) {
      this.emailPdfRequest.documentType = DocumentTypes.Agency;
    } else if (docType.toUpperCase() === CONSTANTS.documentType.customer) {
      this.emailPdfRequest.documentType = DocumentTypes.Customer;
    } else {
      this.emailPdfRequest.documentType = DocumentTypes.Hotel;
    }
    this.emailPdfRequest.isB2B = true;
  }

  downloadPDF(reservationType, doc, docType) {
    this.showSpinner();
    this.buildDownloadOrSendEmailRequest(reservationType, doc, docType);

    const subscription = this.reservationDataService.generatePDF(this.emailPdfRequest).subscribe((data) => {
      const blob = data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(blob);
      } else {  // for other browsers
        const fileURL = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = fileURL;
        a.download = this.bookingId + '_' + docType + '_' + doc;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      }
    },
      (error: JarvisError) => {
        this.hideSpinner();
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);

  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }
  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }
  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

@Component({
  selector: 'app-reservation-hold-confirm-dialog',
  templateUrl: 'reservation-hold-confirm-dialog.html',
  styleUrls: ['./reservation-details.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class ReservationHoldConfirmDialog implements OnInit {
  bookingResponseDetail: FullBookingResponseDetailsViewmodel;
  holdBookingData: boolean;
  isOnHold: boolean;
  bookingId: string;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  bookingStatus: string;
  subscriptions: Subscription[] = [];
  documentId: string;
  checkinDate: string;
  isInvoiceVouchers: boolean;
  bookReservationXmlError = errorConstants.bookErrorMessage;
  errorCode: string;
  errorMessage: string;

  constructor(public dialogRef: MatDialogRef<ReservationHoldConfirmDialog>,
    private router: Router, public activatedRoute: ActivatedRoute,
    private hotelBookService: HotelBookService, private userProfileService: UserProfileService,
    private hotelDataService: HotelDataService, @Inject(MAT_DIALOG_DATA) public data,
    private dialogsService: DialogsService, public dialog: MatDialog,
    private reservationDataService: ReservationDataService) {
  }
  ngOnInit() {
    this.documentId = this.reservationDataService.documentId;
  }

  customClose(dialogRef) {
    this.showSpinner();
    const holdBookingRequestViewmodel = new HoldBookingRequestViewmodel();
    holdBookingRequestViewmodel.mgReservationId = this.data.id;
    holdBookingRequestViewmodel.lastUpdatedUser = this.userProfileService.getBasicUserInfo().id;
    holdBookingRequestViewmodel.lastUpdatedSource = 'B2B';
    const subscription = this.hotelDataService.confirmBookingHold(holdBookingRequestViewmodel).subscribe((response) => {
      // tslint:disable-next-line: max-line-length
      if (!Utilities.isNullOrUndefined(response.errorModel) && response.errorModel.xmlErrorCode !== null && response.errorModel.errorCode === 200) {
        dialogRef.close();
        let dialogRef1: MatDialogRef<MessageDialogComponent>;
        dialogRef1 = this.dialog.open(MessageDialogComponent);
        dialogRef1.componentInstance.title = 'Message';
        this.errorMessage = response.errorModel.message;
        let filterData = this.bookReservationXmlError.filter(error => {
          if (response.errorModel.xmlErrorCode === error.code) {
            this.errorMessage = error.message;
          }
        });
        filterData = {}[''];
        dialogRef1.componentInstance.message = this.errorMessage;
        dialogRef1.componentInstance.buttonText = 'OK';
      } else {
        this.holdBookingData = response;
        this.isOnHold = !this.holdBookingData;
        dialogRef.close({
          data: {
            onHold: this.isOnHold, bookingStatus: 'CONF',
            bookingId: this.data.id, refNo: this.data.refNo, agencyId: this.data.agencyGUID, checkinDate: this.data.checkinDate,
            documentId: this.documentId, operation: 'read', isInvoiceVouchers: true, bookingSource: 'B2B'
          }
        });
      }
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Cancel Booking. ${error.friendlyMessage}`);
        // tslint:disable-next-line:no-shadowed-variable
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }
  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
  }
}
