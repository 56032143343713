import { ErrorCodes } from './../../../common/errorcodes';
import { MessageDialogComponent } from './../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Subscription, of, Observable } from 'rxjs';
import { JarvisError } from 'src/app/common/jarviserror';

import { CONSTANTS } from 'src/app/common/constants';
import { DateAdapter, MAT_DATE_FORMATS, MatDialogRef, MatDialog } from '@angular/material';
import { DatePipe, DecimalPipe, formatNumber } from '@angular/common';
import { DatePickerDateAdapterService } from '../../common/b2b-shared/services/date-picker-date-adapter.service';

import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';

import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { ChargeTypeViewModel } from '../../viewmodels/markup-mgmt/common/chargetypeviewmodel';
import { AgencyMarkupService } from '../../common/b2b-shared/services/agency-markup.service';
import { MarkupInfoViewModel } from '../../viewmodels/markup-mgmt/common/markupInfoviewmodel';
import { CurrencyApiService } from '../../../common/shared/services/currency-api.service';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { GetMarkupByIdViewModel } from '../../viewmodels/markup-mgmt/common/getmarkupbyidviewmodel';
import { MarkupInfoResponseViewModel } from '../../viewmodels/markup-mgmt/common/markupinforesponseviewmodel';
import { ErrorMessages } from '../../../common/errormessage';
import { MatSnackBar } from '@angular/material';
import { Utilities } from '../../../common/utilities/utilities';
import { CancelpopupService } from '../../common/b2b-shared/dialogs/cancelpopup.service';
import { CountryViewModel } from 'src/app/common/viewmodels/countryviewmodel';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { MarkupUtilities } from './../../viewmodels/markup-mgmt/common/markupUtilities';
import { UserInputTransformService } from '../../../common/shared/services/user-input-transform.service';
import { NavigatorService } from '../../../framework/fw/services/navigator.service';
import { MarkFormDirtyService } from '../../common/b2b-shared/services/mark-form-dirty.service';

@Component({
    selector: 'app-country-markup-info',
    templateUrl: './country-markup-info.component.html',
    styleUrls: ['./country-markup-info.component.css'],
    providers: [
        DatePipe,
        { provide: DateAdapter, useClass: DatePickerDateAdapterService },
        { provide: MAT_DATE_FORMATS, useValue: CONSTANTS.DATE_FORMATS },
        DecimalPipe
    ]
})

export class CountryMarkupInfoComponent implements OnInit {

    chargeTypeList$: Observable<ChargeTypeViewModel[]>;

    defaultCurrancy: string;
    isConflicts: boolean;
    uiSequenceError: boolean;

    subscriptions: Subscription[] = [];
    spinnerDialogId: string;
    spinnerDialogTimeout: number;

    paramObj: any;
    isChecked: boolean;
    confirmCancel: boolean;
    isRead: boolean;
    isEdit: boolean;
    counter: number;


    updateMarkupForm: FormGroup;
    markupBranch: any;
    saveMarkupInfo: MarkupInfoViewModel = <MarkupInfoViewModel>{};
    getMarkupById: GetMarkupByIdViewModel = <GetMarkupByIdViewModel>{};

    // TODO: asign model
    markupInfo: any;
    direction = 1;

    chargeTypeArr: ChargeTypeViewModel[] = [];
    updateMarkupInfo: MarkupInfoResponseViewModel = <MarkupInfoResponseViewModel>{};

    uiSequenceList: CountryViewModel[] = [];
    orderedUISequenceList: CountryViewModel[] = [];

    sourceSelectedCount = 0;
    targetSelectedCount = 0;
    isDisacardDisabled: boolean;

    conflictsCountryID = [];
    conflictsCountryObj: any = [];
    isSequesnceChanged: boolean;
    currencyList: any;
    destinationErrorMessage = ErrorMessages.invalidDestinationCountry;
    isSubmitted = false;
    isDiscardConflictAndSave: boolean;

    constructor(
        private router: Router,
        private dialogsService: DialogsService,
        private activatedRoute: ActivatedRoute,
        private agencyMarkupService: AgencyMarkupService,
        private currencyApiService: CurrencyApiService,
        private userProfileService: UserProfileService,
        private snackBar: MatSnackBar,
        public cancelPopupService: CancelpopupService,
        public decimalPipe: DecimalPipe,
        private dialog: MatDialog,
        public userInputTransformService: UserInputTransformService,
        private navigatorService: NavigatorService,
        private markFormDirtyService: MarkFormDirtyService
    ) { }

    ngOnInit() {
        this.isChecked = true;
        this.counter = 0;
        this.isDisacardDisabled = true;

        this.activatedRoute.params.subscribe(params => {
            this.paramObj = { ...params.keys, ...params };
        });

        this.markupBranch = this.agencyMarkupService.selectedMarkupBranch;
        this.defaultCurrancy = this.currencyApiService.getDefaultCurrancy();
        this.isRead = (this.paramObj['operation'] === 'read');
        this.isEdit = (this.paramObj['operation'] === 'edit');
        this.uiSequenceList = this.activatedRoute.snapshot.data['countryList'];
        this.sortSourceSeq(this.uiSequenceList, 'countryName');

        this.getChargeType();

        this.updateMarkupForm = new FormGroup({
            fromDate: new FormControl(),
            toDate: new FormControl(),
            chargeTypeId: new FormControl('', Validators.required),
            markup: new FormControl('', [Validators.required]),
            isActive: new FormControl(this.isChecked),
        });

        if (this.paramObj['operation'] === 'edit' || this.paramObj['operation'] === 'read') {
            this.getMarkupToEdit();
        }

    }

    getMarkupToEdit() {
        this.showSpinner();
        this.buildSearchByIdModel();
        const subscription = this.agencyMarkupService.getMarkupToEdit(this.getMarkupById).subscribe((markupInfo) => {
            if (markupInfo.result) {
                this.updateMarkupInfo = markupInfo.result;
                this.updateExistingForm(this.updateMarkupInfo);
                this.addValidations();
            } else {
                this.updateMarkupInfo = new MarkupInfoResponseViewModel();
            }
        }, (error: JarvisError) => {
            this.hideSpinner(); console.error(`Error in Getting Branch Markup List. ${error.friendlyMessage}`);
        },
            () => this.hideSpinner()
        );
        this.subscriptions.push(subscription);
    }

    removeAmount() {
        this.updateMarkupForm.get('markup').setValue('');
      }

    updateExistingForm(formData) {
        const othEle = formData[0];
        this.updateMarkupForm.get('fromDate').setValue(othEle['fromDate']);
        this.updateMarkupForm.get('toDate').setValue(othEle['toDate']);
        this.updateMarkupForm.get('chargeTypeId').setValue(othEle['chargeTypeId']);
        this.updateMarkupForm.get('markup').setValue(othEle['markup']
            .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 6 }));
        this.updateMarkupForm.get('isActive').setValue(othEle['isActive']);

        formData.forEach( (confCountry) => {
            let filterData = this.uiSequenceList.filter((countryObj, i) => {
                if ((countryObj['countryId'] === confCountry['countryId'])) {
                    this.uiSequenceList.splice(i, 1);
                    this.orderedUISequenceList.push(countryObj);
                    return (countryObj['countryId'] === confCountry['countryId']);
                }
            });
            filterData = {}[''];
        });

    }

    buildSearchByIdModel() {
        this.getMarkupById.agencyId = this.paramObj['agencyId'];
        this.getMarkupById.branchId = this.paramObj['branchId'];
        this.getMarkupById.tabId = this.paramObj['tabId'];
        this.getMarkupById.groupId = this.paramObj['groupId'];
    }

    getChargeType() {
        this.chargeTypeList$ = this.agencyMarkupService.getChargeType(0);
        this.chargeTypeList$.subscribe((ele) => {
            ele.forEach(element => {
                this.chargeTypeArr.push(element);
            });
        });
    }

    buildModel() {
        this.saveMarkupInfo.fromDateKey = null;
        this.saveMarkupInfo.toDateKey = null;
        this.saveMarkupInfo.agencyId = this.userProfileService.getBasicUserInfo().agencyId;  // headers madhli
        this.saveMarkupInfo.branchId = (this.agencyMarkupService.selectedMarkupBranch.isAgency) ? 0 :
            this.agencyMarkupService.selectedMarkupBranch.id; // selected all 0
        this.saveMarkupInfo.currencyId = this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId; // 14;
        this.saveMarkupInfo.countryList = this.orderedUISequenceList.map(e => e.countryId);
        this.saveMarkupInfo.cityList = null;
        this.saveMarkupInfo.hotelList = null;
        this.saveMarkupInfo.countryId = null;
        this.saveMarkupInfo.cityId = null;
        this.saveMarkupInfo.tabId = CONSTANTS.markup.tab.countrymarkup.id;
        this.saveMarkupInfo.tabName = CONSTANTS.markup.tab.countrymarkup.label;
        this.saveMarkupInfo.isUpdate = false;  // create = ;
        const mValueFrom = this.updateMarkupForm.get('markup').value ?
          +MarkupUtilities.replaceComma(this.updateMarkupForm.get('markup').value) : null;
            this.saveMarkupInfo.markup = mValueFrom;


        if (this.paramObj['operation'] === 'edit') {
            this.saveMarkupInfo.isUpdate = true;
            this.saveMarkupInfo.groupId = this.paramObj['groupId'];
        }
    }

    saveMarkup() {
        this.isSubmitted = true;
        this.markFormDirtyService.markGroupDirty(this.updateMarkupForm);
        if(this.updateMarkupForm.valid && (this.updateMarkupForm.dirty || this.isSequesnceChanged) &&
         !this.isValid()) {
            this.saveMarkupInfo = this.updateMarkupForm.value;
            this.buildModel();
            this.saveMarkupInfo.isSaveValidRecordsOnly = this.isDiscardConflictAndSave;
            this.updateMarkup();
        }
    }

    updateMarkup() {
        this.showSpinner();
        const subscription = this.agencyMarkupService.saveMarkup(this.saveMarkupInfo).subscribe((markupInfo) => {
            this.markupInfo = [];
            this.markupInfo = markupInfo.result;
            if (this.markupInfo.length > 0) {
                this.conflicts();
            } else {
                const message = (this.paramObj['operation'] === 'edit') ?
                    'Markup has been updated successfully' :
                    'Markup has been added successfully';
                this.snackBar.open(message, '', {
                    duration: CONSTANTS.toasterTimeOut.duration,
                    verticalPosition: 'top', panelClass: 'showSnackBar'
                });
                this.navigate();
            }
        }, (error: JarvisError) => {
            this.hideSpinner();
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Error';
            dialogRef.componentInstance.message = `Error Code ${ErrorCodes.ValidateMarkupRequest}. ${error.friendlyMessage}`;
            dialogRef.componentInstance.buttonText = 'OK';
          },
          () => this.hideSpinner());
        this.subscriptions.push(subscription);
    }

    conflicts() {

        this.conflictsCountryID = [];
        this.conflictsCountryID = this.markupInfo.map(element => {
            return element['countryId'];
        });

        if (this.conflictsCountryID.length < this.orderedUISequenceList.length) {
            this.isDisacardDisabled = false;
        } else {
            this.isDisacardDisabled = true;
        }


        let dialogRef: MatDialogRef<ErrorMessageComponent>;
        dialogRef = this.dialog.open(ErrorMessageComponent);
        dialogRef.componentInstance.title = CONSTANTS.markup.errorMessage.title;
        dialogRef.componentInstance.message = CONSTANTS.markup.errorMessage.countryMsg;
        dialogRef.componentInstance.viewButtonText = 'View';
        dialogRef.componentInstance.cancelButtonText = 'Cancel';
        dialogRef.componentInstance.discardButtonText = 'Discard Conflicts & Save';
        dialogRef.componentInstance.isDisacardDisabled = this.isDisacardDisabled;

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'viewConflicts') {
                this.viewConflicts();                
            } else if (result === 'Discard Conflicts & Save') {
                this.isDiscardConflictAndSave = true;
                this.saveMarkup();
            }
            let firstElementWithError = document.querySelector('.isError');
                if (firstElementWithError) {
                    firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }
          });
    }

    viewConflicts() {
        this.isConflicts = true;
    }

    closeCustomTooltip() {
        this.isConflicts = false;
    }

    navigate() {
        this.router.navigate(['/authenticated/markup/markupmgmt/countrymarkup'], { relativeTo: this.activatedRoute });
    }

    getMarkupTypeByID(id) {
        let chargetype1;
        this.chargeTypeArr.forEach(element => {
            if (element['id'] === id) {
                return chargetype1 = element['chargeType'];
            }
        });

        if (chargetype1) {
            return chargetype1;
        }
    }

    addValidations() {
        if (this.getMarkupTypeByID(this.updateMarkupForm.get('chargeTypeId').value) === 'Amount') {
            this.updateMarkupForm.get('markup')
                .setValidators([
                    Validators.required,
                    Validators.max(CONSTANTS.markup.markupMaxAmount),
                    MarkupUtilities.markupAmountValidatior
                ]);
        } else {
            this.updateMarkupForm.get('markup')
                .setValidators([
                    Validators.required,
                    Validators.max(CONSTANTS.markup.markupMaxPercent),
                    MarkupUtilities.markupPercentValidatior
                ]);
        }
        this.updateMarkupForm.get('markup').updateValueAndValidity();
    }

    hasErrors(controlName: string) {
        if (this.updateMarkupForm.get(controlName)) {
            return (this.updateMarkupForm.get(controlName).dirty || this.updateMarkupForm.get(controlName).touched)
                && (this.updateMarkupForm.get(controlName).errors !== null);
        }
    }

    getValidationMessage(controlName: string) {

        if (this.updateMarkupForm.get(controlName).hasError('required')) {
            return ErrorMessages.requiredFieldMessage;
        } else if (this.updateMarkupForm.get(controlName).hasError('max')) {
            const markupLimitMessage =
                (this.getMarkupTypeByID(this.updateMarkupForm.get('chargeTypeId').value) === 'Amount')
                    ? ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxAmount)
                    : ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxPercent);
            // `${CONSTANTS.markup.markupMaxAmount | number }`
            return markupLimitMessage;
        } else if (this.updateMarkupForm.get(controlName).hasError('min')) {
            const markupLimitMessage =
                (this.getMarkupTypeByID(this.updateMarkupForm.get('chargeTypeId').value) === 'Amount')
                    ? ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinAmount)
                    : ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinPercent);

            return markupLimitMessage;
        } else {
            return '';
        }
    }

    transformAmount(amount, control) {
        if (amount && amount !== '') {
        amount = Number(amount.replace(/[,]/g, ''));
       let isNumber = false;
       isNumber = isNaN(amount);
       if (isNumber === false) {
         amount = Number(amount);
         this.updateMarkupForm.get(control)
         .setValue(formatNumber(amount, this.navigatorService.getCurrentLocale(), CONSTANTS.digitsInfo));
       }
     }
    }

    numberFormatInCurrentLocale(value) { // If index not present, then remove the parameter
        if (value !== '') {
       this.updateMarkupForm.get('markup').setValue(this.userInputTransformService.userInputNumberTransform(value));
          }
        }


    removeCurrencyPipeFormat($event) {
        $event.target.value = MarkupUtilities.replaceComma($event.target.value);
      }

    getCountryName(id) {
        const index = this.orderedUISequenceList.findIndex(item => item.countryId === id);
        return (index > -1) ? this.orderedUISequenceList[index]['countryName'] : '';
    }


    addClass(event, eleCounter) {
        const index = this.conflictsCountryID.indexOf(eleCounter);
        this.conflictsCountryObj = [];
        this.conflictsCountryObj = this.markupInfo.filter(e => {
            return e['countryId'] === eleCounter;
        } );

        event.target.classList.add('ps-relative');
        const popupID = eleCounter;
        document.getElementById(popupID).classList.remove('display-none');
        document.getElementById(popupID).classList.add('display-popup');
    }

    removeClass(event, eleCounter) {
        event.target.classList.remove('ps-relative');
        const popupID = eleCounter;
        document.getElementById(popupID).classList.add('display-none');
        document.getElementById(popupID).classList.remove('display-popup');
    }

    cancelCRUDMarkup() {
        let confirmCancelSubscription = null;
        confirmCancelSubscription = Utilities.confirmCancel(this.updateMarkupForm.dirty, this.cancelPopupService, this.router,
            // tslint:disable-next-line:max-line-length
            ['/authenticated/markup/markupmgmt/countrymarkup'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0));
        if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
            this.subscriptions.push(confirmCancelSubscription);
            this.confirmCancel = true;
        }
    }

    isValid() {
        return this.paramObj['operation'] === 'edit'
        ? !(this.isSequesnceChanged ? (this.orderedUISequenceList.length > 0 && this.updateMarkupForm.valid)
                                    : (this.updateMarkupForm.dirty && this.updateMarkupForm.valid))
        : ((!this.updateMarkupForm.touched)
        || !this.updateMarkupForm.valid)
        || !(this.orderedUISequenceList.length > 0);
    }

    sortSourceSeq(arr, objKey) {
        arr.sort(function(compObj1, compObj2) {
          return compObj1[objKey].localeCompare(compObj2[objKey].toLowerCase());
       });
      }

    canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.updateMarkupForm.dirty && this.confirmCancel) {
            return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
        } else {
            return of(true);
        }
    }

    showSpinner() {
        this.spinnerDialogTimeout = setTimeout(() => {
            this.spinnerDialogId = this.dialogsService.showSpinner();
        });
    }

    hideSpinner() {
        if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
            this.dialogsService.hideSpinner(this.spinnerDialogId);
            this.spinnerDialogId = undefined;
        }
        clearTimeout(this.spinnerDialogTimeout);
        this.spinnerDialogTimeout = undefined;
    }

}
