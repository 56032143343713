import { CONSTANTS } from '../constants';

export class PasswordValidators {
    static matchPasswordValidator = function (control) {
        const password = control.root.get('password');
        const confirmPassword = control.root.get('confirmPassword');
        if (password !== null && confirmPassword !== null) {
            if (confirmPassword.value === '' && (password.value === '' || password.value === CONSTANTS.dummyPassword)) {
                return null;
            } else {
                if (password.value === confirmPassword.value) {
                    return null;
                } else {
                    // confirmPassword.markAsTouched();
                    // confirmPassword.markAsDirty();
                    return { 'passwordMismatch': true };
                }
            }
        }
    };

    static passwordLengthValidator = function (control) {
        if (control.value !== null && control.value.length > 0 && control.value.length < 8) {
            return { 'passwordLengthError': true };
        } else {
            return null;
        }
    };

    static passwordNumberValidator = function (control) {
        const regExpForNumber = new RegExp('(?=.*[0-9])');
        if (control.value !== null && control.value.length > 0 && !regExpForNumber.test(control.value)) {
            return { 'passwordNumberError': true };
        } else {
            return null;
        }
    };

    static passwordCaseValidator = function (control) {
        const regExpForCase = new RegExp('(?=.*[A-Z])');
        if (control.value !== null && control.value.length > 0 && !regExpForCase.test(control.value)) {
            return { 'passwordCaseError': true };
        } else {
            return null;
        }
    };

    static passwordNonAlphanumValidator = function (control) {
        const regExForNonAlphanum = new RegExp('[^A-Za-z0-9]');
        if (control.value !== null && control.value.length > 0 && !regExForNonAlphanum.test(control.value)) {
            return { 'passwordNonAlphanumError': true };
        } else {
            return null;
        }
    };
}
