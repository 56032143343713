import { CONSTANTS } from 'src/app/common/constants';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HotelBookService } from '../../services/hotel-book.service';
import { FullBookingResponseDetailsViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-response-viewmodel';
import { BookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-request-viewmodel';
import { CancelBookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-request-viewmodel';
import { HotelDataService } from '../../services/hotel-data.service';
import { DatePipe } from '@angular/common';
import { CancelBookingResponseViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';
import { MessageDialogComponent } from '../../../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { Utilities } from 'src/app/common/utilities/utilities';
import { errorConstants } from 'src/app/common/friendlymessage';
import { JarvisError } from '../../../../../common/jarviserror';
import { Subscription } from 'rxjs';
import { ScreenService } from '../../../../../framework/fw/services/screen.service';
@Component({
  selector: 'app-reservation-cancel-preview-dialog',
  templateUrl: 'reservation-cancel-dialog-preview.html',
  styleUrls: ['./reservation-details.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class ReservationBookingCancelPreviewDialog implements OnInit, OnDestroy {
  bookingResponseDetail: FullBookingResponseDetailsViewmodel;
  bookingRequestDetails: BookingRequestViewmodel;
  isPreview: boolean;
  cancelBookingDataReservation: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
  paymentBy = CONSTANTS.paymentBy;
  subscriptions: Subscription[] = [];
  xmlError = errorConstants.cancelReservationErrorMessage;
  errorCode: string;
  errorMessage: string;
  isDataLoaded: boolean;
  constructor(public dialogRef: MatDialogRef<ReservationBookingCancelPreviewDialog>,
    private hotelBookService: HotelBookService, private hotelDataService: HotelDataService,
    @Inject(MAT_DIALOG_DATA)
    public data, public dialog: MatDialog, private router: Router, public activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,public screenService: ScreenService) { }

  ngOnInit() {
      this.isDataLoaded = true;
      this.cancelBookingDataReservation = this.hotelBookService.cancelBookingDetailsReservation;
  }
  cancelConfirmBooking() {
    this.isDataLoaded = false;
    const cancelBookingRequestViewmodel = new CancelBookingRequestViewmodel();
    cancelBookingRequestViewmodel.AgencyBookingId = this.cancelBookingDataReservation.agencyReferenceNumber;
    cancelBookingRequestViewmodel.CancelDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    cancelBookingRequestViewmodel.MGBookingId = this.cancelBookingDataReservation.bookingId;
    cancelBookingRequestViewmodel.SimulationFlag = false;
    const subscription = this.hotelDataService.cancelBookingReservation(cancelBookingRequestViewmodel).subscribe((response) => {
      this.dialogRef.close(true)
      if (response === null) {
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `Please try again .If the problem persists ,Kindly contact support MG Team`;
        dialogRef.componentInstance.buttonText = 'OK';
      } else {
        // tslint:disable-next-line: max-line-length
        if (!Utilities.isNullOrUndefined(response.errorModel) && response.errorModel.xmlErrorCode !== null && response.errorModel.errorCode === 200) {
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Message';
          this.errorMessage = response.errorModel.message;
          let filterData = this.xmlError.filter(error => {
            if (response.errorModel.xmlErrorCode === error.code) {
              this.errorMessage = error.message;
            }
          });
          filterData = {}[''];
          dialogRef.componentInstance.message = this.errorMessage;
          dialogRef.componentInstance.buttonText = 'OK';
        } else {
          this.cancelBookingDataReservation = response;
          this.hotelBookService.cancelBookingDetailsReservation.documentID = this.cancelBookingDataReservation.documentID;
          this.openDialog();
        }
      }
    },
    (error: JarvisError) => {
      this.isDataLoaded = true;
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    },
    () => this.isDataLoaded = true);
    this.subscriptions.push(subscription);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ReservationBookingCancelConfirmDialog, {});
  }
  customClose(dialogRef) {
    dialogRef.close();
  }
  comapareAmount(chargedAmount) {
    // tslint:disable-next-line:radix
    return parseInt(chargedAmount) > 0;
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }
}
@Component({
  selector: 'app-reservation-cancel-confirm-dialog',
  templateUrl: 'reservation-cancel-confirm-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class ReservationBookingCancelConfirmDialog {
  constructor(public dialogRef: MatDialogRef<ReservationBookingCancelConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router, public activatedRoute: ActivatedRoute) {
  }

  customClose(dialogRef) {
    dialogRef.close();
    this.router.navigate(['authenticated/bookingmgmt/cancelSummary'], { relativeTo: this.activatedRoute });
  }
}



