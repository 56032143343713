// import { RoomDetailViewModel } from "./hotelviewmodel";

export class BookingRequestViewmodel {
  sessionId: string;
  agencyBookingId: string;
  nationality: string;
  checkIn: string;
  checkOut: string;
  hotelCode: string;
  currency: string;
  isAvailable: boolean;
  isOnHold: boolean;
  isOnRequest: boolean;
  specialRequest: string;
  isCopyGuestDetail: boolean;
  roomDetail: RoomDetailsViewModel;
  paymentType: string;
  mGReservationNumber: string;
  docId: string;
  saveFailedBooking: boolean;
  clientIPAddress: string;
  supplierPaymentTypeCode: string;
}

export class RoomDetailsViewModel {
  cancellationPolicyType: string;
  code: string;
  mealCode: string;
  room: BookRoom[];
  packageRate: any;
}


export class BookRoom {
  roomNumber: number;
  noOfAdults: number;
  noOfChildren: number;
  childAges: ChildAges[];
  ratekey: string;
  isExtrabed: boolean;
  guestDetail: GuestDetails[];
}

export class ChildAges {
  childAge: number;
}

export class GuestDetails {
  salutation: string;
  firstName: string;
  lastName: string;
  age: number;
}
