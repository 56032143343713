import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routedAgencyComponents, MarkupMgmtRoutingModule} from './markup-mgmt-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../common/material/material.module';
import { MatInputModule, MatButtonModule, MatSelectModule, MatRadioModule, MatCardModule } from '@angular/material';
import { DialogsModule } from '../common/b2b-shared/dialogs/dialogs.module';

import { ComponentsModule } from '../common/b2b-shared/components/components.module';

import { PickListModule } from 'primeng/picklist';
import { PipesModule } from './../common/b2b-shared/pipes/pipes.module';

import { BranchMarkupInfoComponent } from './branch-markup-info/branch-markup-info.component';
import { CountryMarkupInfoComponent } from './country-markup-info/country-markup-info.component';
import { PaginatorModule } from 'primeng/paginator';
import { CityMarkupInfoComponent } from './city-markup-info/city-markup-info.component';
import { HotelMarkupComponent } from './hotel-markup/hotel-markup.component';
import { HotelMarkupInfoComponent } from './hotel-markup-info/hotel-markup-info.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { MarkupConflictsListPopupComponent } from './markup-conflicts-list-popup/markup-conflicts-list-popup.component';
import { DirectivesModule } from '../common/b2b-shared/directive/allow-decimal-number/directives.module';
import { B2bSharedModule } from '../common/b2b-shared/b2b-shared.module';

@NgModule({
  declarations: [
    routedAgencyComponents,
    BranchMarkupInfoComponent,
    CountryMarkupInfoComponent,
    CityMarkupInfoComponent,
    HotelMarkupComponent,
    HotelMarkupInfoComponent,
    ErrorMessageComponent,
    MarkupConflictsListPopupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MarkupMgmtRoutingModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    DialogsModule,
    ComponentsModule,
    PickListModule,
    PaginatorModule,
    PipesModule,
    DirectivesModule,
    B2bSharedModule
  ],
  entryComponents: [
    ErrorMessageComponent,
    MarkupConflictsListPopupComponent
  ]
})
export class MarkupMgmtModule { }
