import { FormGroupDirective } from '@angular/forms';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInvalidControlScroll]'
})
export class InvalidControlScrollDirective {

  constructor(private el: ElementRef,
    private formGroupDir: FormGroupDirective) { }

  @HostListener('ngSubmit')

  scrollToFirstInvalidControl(): void {
    let firstElementWithError: any = null;
    if (this.formGroupDir.control.invalid) {
      firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    } else if (document.querySelector('.mat-error')) {
      firstElementWithError = document.querySelector('.mat-error');
    }
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // firstElementWithError.focus();
    }
  }

}
