import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit, DoCheck, AfterViewChecked } from '@angular/core';
import { SwiperComponent } from 'ngx-useful-swiper';
import { ScreenService } from 'src/app/framework/fw/services/screen.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-image-galleria',
  templateUrl: './image-galleria.component.html',
  styleUrls: ['./image-galleria.component.css']
})
export class ImageGalleriaComponent implements OnInit, OnChanges, AfterViewInit, DoCheck, AfterViewChecked {
  images: any[];
  activeIndex: number;
  @ViewChild('swiper') swiper: SwiperComponent;

  @Input() imagesArray: any[];
  @Input() frameWidth: number;
  @Input() frameHeight: number;
  @Input() panelWidth: number;
  @Input() panelHeight: number;
  @Input() pageType: string;

  smallConfig: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 20,
  };

  constructor(public screenService: ScreenService) { }

  ngDoCheck(): void {
    /* this.activeIndex = 1;
    if (this.screenService.isBelowLarge()) {
      this.updateSlideIndex();
      if (this.swiper && this.swiper.swiper) {
        this.swiper.swiper.on('slideChange', () => this.updateSlideIndex());
      }
    } */
  }
  ngAfterViewChecked(): void {
    if (this.screenService.isBelowLarge()) {
      this.updateSlideIndex();
      if (this.swiper && this.swiper.swiper) {
        this.swiper.swiper.on('slideChange', () => this.updateSlideIndex());
      }
    }
  }

  ngOnInit() {
    this.activeIndex = 1;
    if (this.imagesArray) {
      this.images = this.imagesArray;
    }
  }

  ngOnChanges(): void {
    this.activeIndex = 1;
    if (this.imagesArray) {
      this.images = this.imagesArray;
    }
  }

  ngAfterViewInit() {
    if (this.screenService.isBelowLarge()) {
      this.updateSlideIndex();
      if (this.swiper && this.swiper.swiper) {
        this.swiper.swiper.on('slideChange', () => this.updateSlideIndex());
      }
    }
  }

  updateSlideIndex() {
    if (this.swiper && this.swiper.swiper) {
      this.activeIndex = this.swiper.swiper.realIndex + 1;
    }
  }

  getIndex(event) {
    this.activeIndex = event.index + 1;
  }

}
