import { Router } from '@angular/router';
import { UserProfileService } from './../../../common/shared/services/user-profile.service';
import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from 'src/app/common/constants';

@Component({
  selector: 'app-agency-mgmt-nav',
  templateUrl: './agency-mgmt-nav.component.html',
  styleUrls: ['./agency-mgmt-nav.component.css']
})
export class AgencyMgmtNavComponent implements OnInit {

  routeLinks: any[];
  AgencyProfileAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.AgencyProfile);
  BranchAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.Branch);
  AgentUserAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.AgentUser);
  AgencyBankDetailsAccess = 
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.AgencyBankDetails);

  constructor(private userProfileService: UserProfileService, private router: Router) { }

  ngOnInit() {
    if (!this.AgencyProfileAccess.hasViewAccess) {
      if (this.BranchAccess.hasViewAccess) {
        this.router.navigate(['/authenticated/agency/agencymgmt/branch']);
      } else if (!this.BranchAccess.hasViewAccess) {
        if (this.AgentUserAccess.hasViewAccess) {
          this.router.navigate(['/authenticated/agency/agencymgmt/agent']);
        }
        else if (!this.AgentUserAccess.hasViewAccess) {
          if (this.AgencyBankDetailsAccess.hasViewAccess){
            this.router.navigate(['/authenticated/agency/agencymgmt/agencybankdetails']);
          }
          else {
          this.router.navigate(['/authenticated/agency/agencymgmt/rolesandpermissions']);
          }
        }
      } else {
        this.router.navigate(['/authenticated/agency/agencymgmt/rolesandpermissions']);
      }
    }
    this.routeLinks = [
      {
        label: 'Agency Profile',
        path: 'agencyprofile',
        isView: this.AgencyProfileAccess.hasViewAccess
      },
      {
        label: 'Branch',
        path: 'branch',
        isView: this.BranchAccess.hasViewAccess
      },
      {
        label: 'Agent User',
        path: 'agent',
        isView: this.AgentUserAccess.hasViewAccess
      },
      {
        label: 'Bank Details',
        path: 'agencybankdetails',
        isView: this.AgencyBankDetailsAccess.hasViewAccess
      },
      {
        label: 'Roles & Permissions',
        path: 'rolesandpermissions',
        isView: true
      }
    ];
  }

}
