import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CONSTANTS } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class ScreenService {

    private resizeSource = new Subject<null>();
    resize$ = this.resizeSource.asObservable();

    // The below are pixel values of the screen.
    largeBreakpoint = CONSTANTS.screen.largeBreakpoint; // 839.98;
    mediumBreakpoint = CONSTANTS.screen.mediumBreakpoint; // 500 to 839.98;
    screenWidth = CONSTANTS.screen.screenWidth; // 1000;
    screenHeight = CONSTANTS.screen.screenHeight; // 800;
    mediumLargeBreakpoint = CONSTANTS.screen.mediumLargeBreakpoint;//600
    mobileLandscapeBreakpoint = CONSTANTS.screen.mobileLandscapeBreakpoint; //1280

    constructor() {

        try {
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
            window.addEventListener('resize', (event) => this.onResize(event));
        } catch (e) {
            // we're going with default screen dimensions of screenwidth and screenheight as
            // defined above
        }
    }
    isLarge(): boolean {
        return this.screenWidth >= this.largeBreakpoint;
    }
    isBelowLarge(): boolean {
        return this.screenWidth < this.largeBreakpoint;
    }
    isSmall(): boolean {
        return this.screenWidth <= this.mediumBreakpoint;
    }
    isMedium(): boolean {
        return this.mediumBreakpoint < this.screenWidth && this.screenWidth < this.largeBreakpoint;
    }
    isMediumLarge(): boolean {
        return this.screenWidth <= this.mediumLargeBreakpoint;
    }
    isLandscape(): boolean {
        return this.screenWidth < this.mobileLandscapeBreakpoint;
    }
    isGreaterthanLandscape(): boolean {
        return this.screenWidth >= this.mobileLandscapeBreakpoint;
    }
    isBelowLargeHeight(): boolean {
        return this.screenHeight < this.largeBreakpoint;
    }

    onResize($event): void {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
        // Notify any subscribers that the screen has been resized so act accordingly.
        this.resizeSource.next();
    }
}
