import { Injectable } from '@angular/core';
import { UserManager, User, WebStorageStateStore } from 'oidc-client';
import { ConfigurationService } from './configuration.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userManager: UserManager;
  private _user: User;
  private _isLoggedOut = false;
  constructor(private configurationService: ConfigurationService, private router: Router) {
    if (window.navigator.cookieEnabled) {
      const config = {
        authority: this.configurationService.config.baseUrls.auth,
        client_id: this.configurationService.config.identityClient.b2bClientId,
        redirect_uri: `${this.configurationService.config.baseUrls.web}assets/oidc-login-redirect.html`,
        // TODO: Update the Scope as required
        scope: 'openid mg.jarvis.api.gateway mg.jarvis.api.backoffice mg.jarvis.api.extranet mg.jarvis.api.usermanagement profile',
        response_type: 'id_token token',
        post_logout_redirect_uri: `${this.configurationService.config.baseUrls.web}?postLogout=true`,
        // post_logout_redirect_uri: `${this.configurationService.config.baseUrls.web}`,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: true,
        silent_redirect_uri: `${this.configurationService.config.baseUrls.web}assets/silent-redirect.html`
      };
      this._userManager = new UserManager(config);

      this._userManager.getUser().then(user => {
        if (user && !user.expired) {
          this._user = user;

          //  console.log('User is ' + JSON.stringify(this._user.profile.sub));
        }
      });
      this._userManager.events.addUserLoaded(args => {
        this._userManager.getUser().then(user => {
          this._user = user;
          // Todo: Call User Profile to get user details again?. May be not
        });
      });

      this._userManager.events.addSilentRenewError(() => {
  //      if (this.isBoUser()) {
            // alert('Your session has expired.Logging Off');
            // window.close();
    //    } else {
    //      this.logout();
      //  }
      });
    }
  }

  login(): Promise<any> {
    return this._userManager.signinRedirect();
  }

  logout(): Promise<any> {
    localStorage.removeItem('hotelSearchPanelArray');
    this._isLoggedOut = true;
    return this._userManager.signoutRedirect();
  }
  removeUser(): Promise<any> {
    return this._userManager.removeUser();
  }

   isLoggedOut(): boolean {
    return this._isLoggedOut;
  }

  isLoggedIn(): boolean {
    return this._user && this._user.access_token && !this._user.expired;
  }

  getAccessToken(): string {
    return this._user ? this._user.access_token : '';
  }

  signoutRedirectCallback(): Promise<any> {
    return this._userManager.signoutRedirectCallback();
  }

  // Todo: Actually we shouldn't be needing this since when we make call to API
  // this is already available in [sub] claim.BUt to keep it consistent addded this here
  getSubjectId(): string {
    return this._user ? this._user.profile.sub : '';
  }

  isBoUser(): boolean {
    return this._user && this._user.profile && this._user.profile.isbo ;
  }

  // Hack: For Firefox private browser, local storage is getting cleared during redirection from Payment Gateway.
  // Hence, storing oidc.user details into session storage from local storage before redirection to Payment Gateway.
  setCCUserFromLocalToSessionStorage() {
    if (Object.keys(localStorage).find(a => a.includes('oidc.user:')) !== undefined &&
        Object.keys(sessionStorage).find(a => a.includes('oidc.user:')) === undefined) {
      sessionStorage.setItem(Object.keys(localStorage).find(a => a.includes('oidc.user:')),
                            localStorage[Object.keys(localStorage).find(a => a.includes('oidc.user:'))]);
    }
  }

  // Hack: For Firefox private browser, local storage is getting cleared during redirection from Payment Gateway.
  // Hence, storing oidc.user details from session storage back into local storage after redirection from Payment Gateway.
  setCCUserFromSessionToLocalStorage() {
    if (Object.keys(sessionStorage).find(a => a.includes('oidc.user:')) !== undefined &&
        Object.keys(localStorage).find(a => a.includes('oidc.user:')) === undefined) {
        localStorage.setItem(Object.keys(sessionStorage).find(a => a.includes('oidc.user:')),
                                sessionStorage[Object.keys(sessionStorage).find(a => a.includes('oidc.user:'))]);
        this._user = JSON.parse(sessionStorage.getItem(Object.keys(sessionStorage).find(a => a.includes('oidc.user:'))));
        // Setting 'wasLocalStorageCleared' in session storage to be used on authenticated-user component to be redirected before
        // either PaymentSuccess or PaymentFailed or PaymentCancel page.
        sessionStorage.setItem('wasLocalStorageCleared', 'true');
    }
  }

  // Remove the oidc.user details from session storage after storing it into local storage in setCCUserFromSessionToLocalStorage().
  removeOidcUserSessionStorage() {
    if (Object.keys(sessionStorage).find(a => a.includes('oidc.user:')) !== undefined) {
      sessionStorage.removeItem(Object.keys(sessionStorage).find(a => a.includes('oidc.user:')));
    }
  }
}
