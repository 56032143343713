import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HotelBookService } from '../../common/b2b-shared/services/hotel-book.service';
import { FullBookingResponseDetailsViewmodel } from '../../viewmodels/hotel-mgmt/booking-response-viewmodel';
import { BookingRequestViewmodel } from '../../viewmodels/hotel-mgmt/booking-request-viewmodel';
import { HotelRecheckService } from '../../common/b2b-shared/services/hotel-recheck.service';
import { HotelRecheckViewModel } from '../../viewmodels/hotel-mgmt/hotelrecheckviewmodel';
import { HotelSearchService } from '../../common/b2b-shared/services/hotel-search.service';
import { CancelBookingRequestViewmodel } from '../../viewmodels/hotel-mgmt/cancel-booking-request-viewmodel';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { CancelBookingResponseViewmodel } from '../../viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';
import { JarvisError } from 'src/app/common/jarviserror';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { MessageDialogComponent } from '../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { ErrorCodes } from 'src/app/common/errorcodes';
import { BookingCancelPreviewDialog } from './BookingCancelPreviewDialog';
import { Utilities } from '../../../common/utilities/utilities';
import { InvoiceAndVouchers } from '../../viewmodels/reservation/invoice-voucher/invoiceandvouchers';
import { ReservationDataService } from '../../common/b2b-shared/services/reservation-data.service';
import { EmailPdfRequestViewModel } from '../../viewmodels/reservation/invoice-voucher/emailpdfrequestviewmodel';
import { CONSTANTS } from '../../../common/constants';
import { ReservationType, Document, DocumentTypes, PaymentAction, ActionStatus } from '../../../common/enum';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { NationalityViewModel } from '../../viewmodels/group-booking/nationalityviewmodel';
import { HoldBookingRequestViewmodel } from '../../viewmodels/hotel-mgmt/hold-booking-request-viewmodel';
import { BreadCrumbService } from '../../common/b2b-shared/services/bread-crumb.service';
import { BookingTimePipe } from '../../common/b2b-shared/pipes/booking-time.pipe';
import { OnHoldBookingTimerRequestViewModel } from '../../viewmodels/reservation/reservation-details/onholdbookingtimerrequestviewmodel';
import { HoldBookingConfirmationResponse } from '../../viewmodels/reservation/reservation-details/holdbookingconfirmationresponse';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
import { errorConstants } from 'src/app/common/friendlymessage';
import { AgencyProfileDataService } from '../../common/b2b-shared/services/agency-profile-data.service';
import { ScreenService } from './../../../framework/fw/services/screen.service';
import { PaymentTypeDetailsViewModel } from '../../viewmodels/payment/paymenttypedetailsviewmodel';
import { PaymentDataService } from '../../common/b2b-shared/services/payment-data.service';
import { MGFPointsConverter } from 'src/app/common/viewmodels/appsettingsviewmodel';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';

@Component({
  selector: 'app-booking-complete-information',
  templateUrl: './booking-complete-information.component.html',
  styleUrls: ['./booking-complete-information.component.css']
})
export class BookingCompleteInformationComponent implements OnInit, OnDestroy {
  utilities = Utilities;
  hotelRecheckData: HotelRecheckViewModel = <HotelRecheckViewModel>{};
  bookingResponseDetail: FullBookingResponseDetailsViewmodel;
  bookingRequestDetails: BookingRequestViewmodel;
  guestNationality: string;
  cancelBookingData: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
  subscriptions: Subscription[] = [];
  refundAmount: number;
  bookingAmount: number;
  cancelDate: Date;
  checkinDate: string;
  daysBefore: number;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  todaysDate: string;
  isCharged: boolean;
  currency: string;
  bookingSatus: string;
  bookingId: string;
  documentId: string;
  invoiceAndVouchersList: InvoiceAndVouchers[];
  emailPdfRequest: EmailPdfRequestViewModel;
  agencyId: number;
  ReservationType = ReservationType;
  bookingState: string;
  fileType: string;
  documentTypes = DocumentTypes;
  agencyVoucher: InvoiceAndVouchers;
  agencyInvoice: InvoiceAndVouchers;
  customerVoucher: InvoiceAndVouchers;
  customerInvoice: InvoiceAndVouchers;
  bookingItenerary: InvoiceAndVouchers;
  // agencyId: string;
  nightlyRatesDateList: Date[] = [];
  promotions: string;
  checkoutDate: string;
  nationalityList: NationalityViewModel[];
  isOnHold: boolean;
  isConfirm: boolean;
  isOnRequest: boolean;
  reservationDetailsStatus = CONSTANTS.reservationDetailsStatus;
  onHoldBookingRequest: OnHoldBookingTimerRequestViewModel = <OnHoldBookingTimerRequestViewModel>{};
  timerData: HoldBookingConfirmationResponse;
  hotelCode: string;
  ViewBookingsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewBookings);
  HotelsBookPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.HotelsBookProperties);
  promotionTypes = CONSTANTS.promotionTypes;
  paymentBy = CONSTANTS.paymentBy;
  xmlError = errorConstants.cancelReservationErrorMessage;
  errorCode: string;
  errorMessage: string;
  specialRequest: string;
  isMGPointApplicable: any;
  mgPointForDetails: number;
  // Payment Gateway
  paymentGatewayRequest: any;
  paymentTypeDetails: PaymentTypeDetailsViewModel;
  paymentTypeCodes = CONSTANTS.paymentTypeCodes;
  paymentGatways = CONSTANTS.PaymentGateway;
  paymentAction = PaymentAction;
  actionStatus = ActionStatus;
  mGFPointsConverter:MGFPointsConverter;
  exchangeRate: number;

  constructor(private hotelBookService: HotelBookService,
    private activatedRoute: ActivatedRoute,
    private hotelRecheckService: HotelRecheckService,
    private hotelSearchService: HotelSearchService,
    private hotelDataService: HotelDataService,
    public dialog: MatDialog, private router: Router, private datePipe: DatePipe,
    private dialogsService: DialogsService,
    private reservationDataService: ReservationDataService,
    private userProfileService: UserProfileService,
    private breadCrumbService: BreadCrumbService, private bookingTimePipe: BookingTimePipe,
    private agencyProfileDataService: AgencyProfileDataService, public screenService: ScreenService,
    public paymentDataService: PaymentDataService,
    private configservice: ConfigurationService, private topBarService: TopBarService
  ) { }

  ngOnInit() {
    this.isMGPointApplicable = this.agencyProfileDataService.isMGPointApplicable;
    this.exchangeRate = this.topBarService._exchangeRate;
    window.scrollTo(0, 0);
    this.breadCrumbService.GuestDetailsTransition(false);
    this.nationalityList = this.activatedRoute.snapshot.data['nationalityList'];
    this.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    this.bookingResponseDetail = this.hotelBookService.fullBookingResponseDetails;
    this.bookingRequestDetails = this.hotelBookService.bookingRequestDetails;
    this.bookingId = this.bookingResponseDetail.bookingDetails.mgBookingID;
    this.documentId = this.bookingResponseDetail.bookingDetails.mgBookingVersionID;
    this.hotelRecheckData = this.hotelRecheckService.hotelRecheckDetails;
    this.mgPointForDetails = this.hotelRecheckData.hotel.mgPoints;
    this.isOnHold = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.isOnHold;
    if (this.isOnHold) {
     this.isMGPointApplicable = false;
    }
    // tslint:disable-next-line:max-line-length
    if (this.bookingResponseDetail.bookingDetails.status.toLocaleLowerCase() === this.reservationDetailsStatus.RESERVED.toLocaleLowerCase()) {
      this.isOnRequest = true;
    } else {
      this.isOnRequest = false;
    }
    if (!Utilities.isNullOrEmpty(this.bookingResponseDetail.bookingDetails.specialRequest)) {
      if (this.bookingResponseDetail.bookingDetails.specialRequest.includes('Hotel Product Code:')) {
        const specialRequestVal = this.bookingResponseDetail.bookingDetails.specialRequest.split('Hotel Product Code:');
        Utilities.isNullOrEmpty(specialRequestVal[0]) ? this.specialRequest = 'N/A' : this.specialRequest = specialRequestVal[0];
      } else if (this.bookingResponseDetail.bookingDetails.specialRequest.includes('Hotel Promo Code:')) {
        const specialRequestValue = this.bookingResponseDetail.bookingDetails.specialRequest.split('Hotel Promo Code:');
        Utilities.isNullOrEmpty(specialRequestValue[0]) ? this.specialRequest = 'N/A' : this.specialRequest = specialRequestValue[0];
      } else {
        this.specialRequest = this.bookingResponseDetail.bookingDetails.specialRequest;
      }
    } else {
      this.specialRequest = 'N/A';
    }
    this.hotelCode = this.hotelRecheckService.hotelRecheckDetails.hotel.hotelId;
    // this.isConfirm = this.bookingResponseDetail.bookingDetails.status === 'CONF' ? true : false;
    this.guestNationality = this.nationalityList
      .find(nationality => nationality.code === this.bookingResponseDetail.bookingDetails.nationality).name;
    this.bookingAmount = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.grossPrice;
    this.todaysDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd').toString();
    this.checkinDate = this.datePipe.transform(this.hotelRecheckService.hotelRecheckDetails.checkIn);
    this.currency = this.bookingResponseDetail.bookingDetails.currency;
    this.checkoutDate = this.datePipe.transform(this.hotelRecheckService.hotelRecheckDetails.checkOut);
    this.nationalityList = this.activatedRoute.snapshot.parent.data['nationalityList'];
    this.bookingResponseDetail.bookingDetails.hotels.roomDetails.rooms.forEach(room => {
      // this.totalPrice = this.totalPrice + room.grossPrice;
      // this.totalNoOfAdults = this.totalNoOfAdults + room.noOfAdults;
      //  this.totalNoOfChildren = this.totalNoOfChildren + room.noOfChild;
      // this.agencyId = this.activatedRoute.snapshot.params['agencyid'];
      // this.agencyReferenceNo = this.activatedRoute.snapshot.params['agencyreferenceno'];
      // this.bookingId = this.activatedRoute.snapshot.params['id'];
      for (const d = new Date(this.checkinDate); d <= new Date(this.checkoutDate); d.setDate(d.getDate() + 1)) {
        this.nightlyRatesDateList.push(new Date(d));
      }
      this.promotions = room.discountRates
        .map(promo => (promo.name.toLowerCase() === this.promotionTypes.Opaque.toLowerCase() ? 'Package Rate' :
          promo.name.toLowerCase() === this.promotionTypes.FreeNights.toLowerCase() ? 'Bonus Nights' : promo.name)).join(', ');
    });
    this.getInvoiceAndVouchersList(this.bookingId, this.documentId);
    if (this.isOnHold) {
      this.getTimerForHoldBooking();
      const subscription = this.paymentDataService.getPaymentTypeDetailsByAgencyId(
        this.currency, this.userProfileService.getBasicUserInfo().agencyId).subscribe(data => {
          this.paymentTypeDetails = data;
          /* if (this.paymentTypeDetails.isDisableCreditLimit && (this.router.url.search('hotelinfo') > -1 ||
            this.router.url.search('agencysearchinfo') > -1) && !this.isTriggered) {
            this.showIsDisableCreditOption = true;
          } */
        },
          (error: JarvisError) => {
            console.error(`Error in Getting Agency data. ${error.friendlyMessage}`);
          },
        );
      this.subscriptions.push(subscription);
    }
    const currencySubscription = this.topBarService.currencyChange$.subscribe(() => {
      this.exchangeRate = this.topBarService._exchangeRate;
      console.log("exchange rate "+ this.exchangeRate);
    });
    this.subscriptions.push(currencySubscription);
    console.log("exchange rate is "+this.topBarService._exchangeRate);
    this.mGFPointsConverter = this.configservice.config.mgfPointsConverter;
  }

  getTimerForHoldBooking() {
    this.onHoldBookingRequest.checkinDate = this.checkinDate;
    this.onHoldBookingRequest.mgReservationNumber = this.bookingId;
    this.onHoldBookingRequest.hotelCode = this.hotelCode;
    // this.bookingId,this.checkInDate
    const subscription = this.reservationDataService.getTimerForHoldBooking(this.onHoldBookingRequest).subscribe(timerData => {
      this.timerData = timerData;
    }, (error: JarvisError) => {
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    });
    this.subscriptions.push(subscription);
  }

  calculateDaysCount() {
    const dt1 = new Date(this.todaysDate);
    const dt2 = new Date(this.checkinDate);
    this.cancelBookingData.daysBefore = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

  }
  calculateRefundAmount() {
    this.cancelBookingData.refundAmount = isNaN(this.bookingAmount - this.cancelBookingData.chargedAmount) ? 0 :
      this.bookingAmount - this.cancelBookingData.chargedAmount;
  }
  cancelConfirmPreview() {
    const cancelBookingRequestViewmodel = new CancelBookingRequestViewmodel();
    cancelBookingRequestViewmodel.AgencyBookingId = this.bookingRequestDetails.agencyBookingId;
    cancelBookingRequestViewmodel.CancelDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    cancelBookingRequestViewmodel.MGBookingId = this.bookingResponseDetail.bookingDetails.mgBookingID;
    cancelBookingRequestViewmodel.SimulationFlag = true;
    const subscription = this.hotelDataService.cancelBooking(cancelBookingRequestViewmodel).subscribe((response) => {
      if (response === null) {
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        // tslint:disable-next-line: max-line-length
        dialogRef.componentInstance.message = `Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.`;
        dialogRef.componentInstance.buttonText = 'OK';
        this.hideSpinner();
      } else {
        // tslint:disable-next-line: max-line-length
        if (!Utilities.isNullOrUndefined(response.errorModel) && response.errorModel.xmlErrorCode !== null && response.errorModel.errorCode === 200) {
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Message';
          this.errorMessage = response.errorModel.message;
          let filterData = this.xmlError.filter(error => {
            if (response.errorModel.xmlErrorCode === error.code) {
              this.errorMessage = error.message;
            }
          });
          filterData = {}[''];
          dialogRef.componentInstance.message = this.errorMessage;
          dialogRef.componentInstance.buttonText = 'OK';
        } else {
          this.cancelBookingData = response;
          this.cancelBookingData.bookingAmount = this.bookingAmount;
          this.cancelBookingData.currency = this.bookingResponseDetail.bookingDetails.currency;
          this.cancelBookingData.paymentBy = this.bookingResponseDetail.bookingDetails.paymentBy;
          this.calculateDaysCount();
          this.calculateRefundAmount();
          this.hotelBookService.cancelBookingDetails = this.cancelBookingData;
          this.dialog.open(BookingCancelPreviewDialog,
            {
              data: this.cancelBookingData,
              panelClass: 'booking-cancel-preview'
            });
        }
      }
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Cancel Booking. ${error.friendlyMessage}`);
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  holdBookingPreview() {
    if (this.bookingResponseDetail.bookingDetails.paymentBy === this.paymentBy.creditCard || this.bookingResponseDetail.bookingDetails.paymentBy === this.paymentBy.qris || this.bookingResponseDetail.bookingDetails.paymentBy === this.paymentBy.bankTransfer) {
      sessionStorage.setItem('isOnHold', 'TRUE');
      if (this.bookingResponseDetail.bookingDetails.paymentBy === this.paymentBy.creditCard) {
        this.paymentTypeDetails.mGBookingID = this.bookingResponseDetail.bookingDetails.mgBookingID;
        this.paymentTypeDetails.docId = this.bookingResponseDetail.bookingDetails.mgBookingVersionID;
        this.paymentTypeDetails.netPrice = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.netPrice;
        this.paymentTypeDetails.grossPrice = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.grossPrice;
        this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.creditCard;
        /* const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
        const checkindate = q[0].toLocaleDateString('en-US');
        const checkoutdate = q[1].toLocaleDateString('en-US');
        const checkInCheckoutDate = [];
        checkInCheckoutDate[0] = checkindate;
        checkInCheckoutDate[1] = checkoutdate;
        this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate = checkInCheckoutDate; */
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        sessionStorage.setItem('reservationDetails', JSON.stringify(this.bookingResponseDetail));
        // sessionStorage.setItem('bookingDate', JSON.stringify(this.bookingDate));
        // sessionStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
        // sessionStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
        // sessionStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
        // this.paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails'));
        this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
        // tslint:disable-next-line:max-line-length

        if (this.paymentTypeDetails.ccPaymentGatewayCode !== null &&
          this.paymentTypeDetails.ccPaymentGatewayCode.toUpperCase() === this.paymentGatways.asiaPay) {
          const subscription3 = this.paymentDataService.getSecureHash(this.bookingResponseDetail.bookingDetails.mgBookingID).subscribe(data => {
            const authorizationRequest = {
              merchantId: this.paymentTypeDetails.ccMerchantId,
              // tslint:disable-next-line:max-line-length
              amount: this.currency === CONSTANTS.idrCurrencyCode || this.currency === CONSTANTS.vndCurrencyCode ?
                Math.ceil(this.paymentTypeDetails.netPrice) : this.paymentTypeDetails.netPrice,
              orderRef: this.paymentTypeDetails.mGBookingID,
              currCode: this.paymentTypeDetails.currencyCode,
              mpsMode: 'NIL',
              successUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentsuccess`,
              failUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentfailed`,
              cancelUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentcancelled`,
              payType: 'H',
              lang: 'E',
              payMethod: 'CC',
              secureHash: data
            };
            this.paymentGatewayRequest = {
              mGBookingId: this.paymentTypeDetails.mGBookingID,
              creditCardConfigurationId: this.paymentTypeDetails.ccPaymentGatewayId,
              amount: this.paymentTypeDetails.netPrice,
              status: this.actionStatus[this.actionStatus.Pending],
              action: this.paymentAction[this.paymentAction.Authorization],
              authorizationRequest: JSON.stringify(authorizationRequest),
              merchantId: this.paymentTypeDetails.ccMerchantId
            };
            const subscription1 = this.paymentDataService.updatePaymentDetails(this.paymentGatewayRequest).subscribe(data1 => {
              this.router.navigate(['/authenticated/payment/paymentgateway']);
            },
              (error: JarvisError) => console.error(`Error in Update Payment Details. ${error.friendlyMessage}`));
            this.subscriptions.push(subscription1);
          },
            (error: JarvisError) => console.error(`Error in Getting Secure Hash Key. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription3);
        } else if (this.paymentTypeDetails.ccPaymentGatewayCode !== null &&
          this.paymentTypeDetails.ccPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
          this.paymentGatewayRequest = {
            mGBookingId: this.paymentTypeDetails.mGBookingID,
            creditCardConfigurationId: this.paymentTypeDetails.ccPaymentGatewayId,
            amount: this.paymentTypeDetails.netPrice,
            status: this.actionStatus[this.actionStatus.Pending],
            action: this.paymentAction[this.paymentAction.Authorization],
            merchantId: this.paymentTypeDetails.ccMerchantId,
            actualAmount: this.paymentTypeDetails.netPrice
          };
          const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
            this.router.navigate(['/authenticated/payment/paymentgateway']);
          },
            (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription1);
        }
      } else if (this.bookingResponseDetail.bookingDetails.paymentBy === this.paymentBy.qris) {
        this.paymentTypeDetails.mGBookingID = this.bookingResponseDetail.bookingDetails.mgBookingID;
        this.paymentTypeDetails.docId = this.bookingResponseDetail.bookingDetails.mgBookingVersionID;
        this.paymentTypeDetails.netPrice = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.netPrice;
        this.paymentTypeDetails.grossPrice = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.grossPrice;
        this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.qris;
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        sessionStorage.setItem('reservationDetails', JSON.stringify(this.bookingResponseDetail));
        // sessionStorage.setItem('bookingDate', JSON.stringify(this.bookingDate));
        this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
        // tslint:disable-next-line:max-line-length

        // Note: QRIS is only implemented for XENDIT
        if (this.paymentTypeDetails.qrisPaymentGatewayCode !== null &&
          this.paymentTypeDetails.qrisPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
          this.paymentGatewayRequest = {
            mGBookingId: this.paymentTypeDetails.mGBookingID,
            creditCardConfigurationId: this.paymentTypeDetails.qrisPaymentGatewayId,
            amount: this.paymentTypeDetails.netPrice,
            status: this.actionStatus[this.actionStatus.Pending],
            action: this.paymentAction[this.paymentAction.Authorization],
            merchantId: this.paymentTypeDetails.qrisMerchantId,
            actualAmount: this.paymentTypeDetails.netPrice
          };
          const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
            this.router.navigate(['/authenticated/payment/paymentgateway']);
          },
            (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription1);
        }
      } else if (this.bookingResponseDetail.bookingDetails.paymentBy === this.paymentBy.bankTransfer) {
        this.paymentTypeDetails.mGBookingID = this.bookingResponseDetail.bookingDetails.mgBookingID;
        this.paymentTypeDetails.docId = this.bookingResponseDetail.bookingDetails.mgBookingVersionID;
        this.paymentTypeDetails.netPrice = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.netPrice;
        this.paymentTypeDetails.grossPrice = this.bookingResponseDetail.bookingDetails.hotels.roomDetails.grossPrice;
        this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.bankTransfer;
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
        sessionStorage.setItem('reservationDetails', JSON.stringify(this.bookingResponseDetail));
        // sessionStorage.setItem('bookingDate', JSON.stringify(this.bookingDate));
        this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
        // tslint:disable-next-line:max-line-length
        // Note: Bank Transfer is only implemented for XENDIT
        if (this.paymentTypeDetails.btPaymentGatewayCode !== null &&
          this.paymentTypeDetails.btPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
          this.paymentGatewayRequest = {
            mGBookingId: this.paymentTypeDetails.mGBookingID,
            creditCardConfigurationId: this.paymentTypeDetails.btPaymentGatewayId,
            amount: this.paymentTypeDetails.netPrice,
            status: this.actionStatus[this.actionStatus.Pending],
            action: this.paymentAction[this.paymentAction.Authorization],
            merchantId: this.paymentTypeDetails.btMerchantId,
            actualAmount: this.paymentTypeDetails.netPrice
          };
          const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
            this.router.navigate(['/authenticated/payment/paymentgateway']);
          },
            (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
          this.subscriptions.push(subscription1);
        }
      }
    } else {
      const dialogRef = this.dialog.open(BookingHoldConfirmDialog);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isOnHold = result.data.isOnHold;
          this.bookingId = result.data.bookingId;
          this.documentId = result.data.documentId;
          this.getInvoiceAndVouchersList(this.bookingId, this.documentId);
          window.scrollTo(0, 0);
          window.scroll(0, 0);
        }
      });
    }
  }

  getTransDate(myDate) {
    return this.bookingTimePipe.transform(myDate);
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  editBooking() {
    this.router.navigate(['/authenticated/bookingmgmt/editbooking',
      this.bookingResponseDetail.bookingDetails.mgBookingID, this.bookingResponseDetail.bookingDetails.agencyBookingID]);
  }

  getInvoiceAndVouchersList(bookingId: string, documentId: string) {
    this.showSpinner();
    const subscription = this.reservationDataService.getInvoiceAndVouchers(bookingId, documentId).subscribe((invoiceAndVouchersList) => {
      this.invoiceAndVouchersList = invoiceAndVouchersList;
      this.invoiceAndVouchersList.forEach(element => {
        if (element.documentType.toLowerCase() === CONSTANTS.documentType.agency.toLowerCase()) {
          if (element.fileType.toLowerCase().toLowerCase() === CONSTANTS.document.Voucher.toLowerCase()) {
            this.agencyVoucher = element;
          } else if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
            this.agencyInvoice = element;
          }
        } else if (element.documentType.toLowerCase() === CONSTANTS.documentType.customer.toLowerCase()) {
          if (element.fileType.toLowerCase() === CONSTANTS.document.Voucher.toLowerCase()) {
            this.customerVoucher = element;
          } else if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
            this.customerInvoice = element;
          } else if (element.fileType.toLowerCase() === CONSTANTS.document.Itinerary.toLowerCase()) {
            this.bookingItenerary = element;
          }
        }
      });
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Getting Reservation- getInvoiceAndVouchersList. ${error.friendlyMessage}`);
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  buildDownloadOrSendEmailRequest(reservationType, doc, docType) {
    this.emailPdfRequest = new EmailPdfRequestViewModel();
    this.emailPdfRequest.agencyId = this.agencyId;
    this.emailPdfRequest.MGReservationNumber = this.bookingId;
    this.emailPdfRequest.reservationDocId = this.documentId;

    if (reservationType.toUpperCase() === CONSTANTS.reservationType.New) {
      this.emailPdfRequest.reservationType = ReservationType.New;
    } else {
      this.emailPdfRequest.reservationType = ReservationType.Cancel;
    }

    if (doc.toUpperCase() === CONSTANTS.document.All) {
      this.emailPdfRequest.document = Document.All;
    } else if (doc.toUpperCase() === CONSTANTS.document.Invoice) {
      this.emailPdfRequest.document = Document.Invoice;
    } else if (doc.toUpperCase() === CONSTANTS.document.Voucher) {
      this.emailPdfRequest.document = Document.Voucher;
    } else {
      this.emailPdfRequest.document = Document.Itinerary;
    }

    if (docType.toUpperCase() === CONSTANTS.documentType.agency) {
      this.emailPdfRequest.documentType = DocumentTypes.Agency;
    } else if (docType.toUpperCase() === CONSTANTS.documentType.customer) {
      this.emailPdfRequest.documentType = DocumentTypes.Customer;
    } else {
      this.emailPdfRequest.documentType = DocumentTypes.Hotel;
    }
    this.emailPdfRequest.isB2B = true;
  }

  downloadPDF(reservationType, doc, docType) {
    this.showSpinner();
    this.buildDownloadOrSendEmailRequest(reservationType, doc, docType);

    const subscription = this.reservationDataService.generatePDF(this.emailPdfRequest).subscribe((data) => {
      const blob = data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(blob);
      } else {  // for other browsers
        const fileURL = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = fileURL;
        a.download = this.bookingId + '_' + docType + '_' + doc;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      }
    },
      (error: JarvisError) => {
        this.hideSpinner();
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);

  }

  goBack() {
    this.router.navigate(['authenticated/hotelmgmt/hotelinfo']);
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  ngOnDestroy() {
    this.dispose();
  }
}

@Component({
  selector: 'app-booking-hold-confirm-dialog',
  templateUrl: 'booking-hold-confirm-dialog.html',
  styleUrls: ['./booking-complete-information.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class BookingHoldConfirmDialog implements OnInit {
  bookingResponseDetail: FullBookingResponseDetailsViewmodel;
  holdBookingData: boolean;
  isOnHold: boolean;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  subscriptions: Subscription[] = [];
  documentId: string;
  bookingId: string;

  constructor(public dialogRef: MatDialogRef<BookingHoldConfirmDialog>,
    private router: Router, public activatedRoute: ActivatedRoute,
    private hotelBookService: HotelBookService, private userProfileService: UserProfileService,
    private hotelDataService: HotelDataService, private dialogsService: DialogsService, public dialog: MatDialog,
    public frameworkConfigService: FrameworkConfigService) {
  }
  ngOnInit() {
    this.bookingResponseDetail = this.hotelBookService.fullBookingResponseDetails;
    this.isOnHold = false;
    this.documentId = this.bookingResponseDetail.bookingDetails.mgBookingVersionID;
    this.bookingId = this.bookingResponseDetail.bookingDetails.mgBookingID;
  }
  customClose(dialogRef) {
    this.showSpinner();
    const holdBookingRequestViewmodel = new HoldBookingRequestViewmodel();
    holdBookingRequestViewmodel.mgReservationId = this.bookingResponseDetail.bookingDetails.mgBookingID;
    holdBookingRequestViewmodel.lastUpdatedUser = this.userProfileService.getBasicUserInfo().id;
    if (this.frameworkConfigService.IsCallCenterUser) {
      holdBookingRequestViewmodel.lastUpdatedSource = 'BO';
    } else {
      holdBookingRequestViewmodel.lastUpdatedSource = 'B2B';
    }
    const subscription = this.hotelDataService.confirmBookingHold(holdBookingRequestViewmodel).subscribe((response) => {
      this.holdBookingData = response;
      this.isOnHold = !this.holdBookingData;
      dialogRef.close({ data: { isOnHold: this.isOnHold, bookingId: this.bookingId, documentId: this.documentId } });
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Cancel Booking. ${error.friendlyMessage}`);
        // tslint:disable-next-line:no-shadowed-variable
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }
  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
  }
}
