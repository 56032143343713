import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from 'src/app/common/utilities/utilities';

@Pipe({ name: 'orderBy' })
export class Orderbypipe implements PipeTransform {
  transform(records: Array<any>, args?: any): any {
    return Utilities.isNullOrEmpty(records) ? records : records.sort(function (a, b) {
      if (!Utilities.isNullOrEmpty(args.exclude) && args.exclude.toLowerCase() === a[args.property].toLowerCase()) {
        return -1;
      } else if (a[args.property].toLowerCase() < b[args.property].toLowerCase()) {
          return -1 * args.direction;
      } else if (a[args.property].toLowerCase() > b[args.property].toLowerCase()) {
          return 1 * args.direction;
      } else {
          return 0;
      }
    });
  }
}
