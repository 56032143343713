import { Injectable } from '@angular/core';
import { AppSettingsViewModel } from '../../viewmodels/appsettingsviewmodel';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  // Endpoint of the Server Controller for AppSettings ie AppSettingsController
  private readonly configUrlPath: string = 'AppSettings';
  private configData: AppSettingsViewModel;
  appSettings: any;
  // Todo: Later replace the HttpClient with our HttpService
  constructor(private http: HttpClient) { }

  loadConfigurationData(): Promise<AppSettingsViewModel> {
     return this.http.get(`${window.location.protocol}//${window.location.host}/${this.configUrlPath}`)
     .toPromise()
     .then((response: any) => {
       this.configData = response;
//       console.log(this.configData);
       return this.configData;
     })
     .catch(err => {
       return Promise.reject(err);
     });
 }

  // A helper property to return the config object
  get config(): AppSettingsViewModel {
    return this.configData;
  }
}
