import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DealsViewModel } from 'src/app/b2b/viewmodels/dealsviewmodel';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';
import { map } from 'rxjs/operators';
import { DestinationDeal } from 'src/app/b2b/viewmodels/destinationdealviewmodel';
import { AuthService } from 'src/app/common/shared/services/auth.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { DestinationsViewModel } from 'src/app/b2b/viewmodels/destinationviewmodel';
import { ChainDeal } from '../../../../b2b/viewmodels/chaindealviewmodel';
import { ChainsViewModel } from '../../../../b2b/viewmodels/chainviewmodel';

@Injectable({
  providedIn: 'root'
})
export class DealsDataService {
  unbeatableDeals$: Observable<DealsViewModel[]>;
  destinationDeals$: Observable<DestinationDeal[]>;
  favoriteDestinations$: Observable<DestinationsViewModel[]>;
  chainDeals$: Observable<ChainDeal[]>;
  _navigatedFromUnbeatableDeals: boolean;
  _navigatedFromChainDeals: boolean;

  private _destinationId: number;
  private _chaindealId: number;

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService,
    private topBarService: TopBarService, private authService: AuthService,
    private userProfileService: UserProfileService) {
    this.topBarService.currencyChange$.subscribe(curr => {
      // console.log('Changed Currency in Deals Data Service' + curr);
      this.getUnbeatableDeals(curr);
      if (this.authService.isLoggedIn()) {
        this.getDestinationDeals(curr, 0,
          this.userProfileService.getBasicUserInfo().agencyId);
          this.getChainDeals(curr, 0, this.userProfileService.getBasicUserInfo().agencyId);
      } else {
        this.getDestinationDeals(curr, this._destinationId);
      }
    });
  }

  get navigatedFromUnbeatableDeals(): boolean {
    return this._navigatedFromUnbeatableDeals;
  }

  set navigatedFromUnbeatableDeals(unbeatableDeals: boolean) {
    this._navigatedFromUnbeatableDeals = unbeatableDeals;
  }

  get navigatedFromChainDeals(): boolean {
    return this._navigatedFromChainDeals;
  }

  set navigatedFromChainDeals(chainDeals: boolean) {
    this._navigatedFromChainDeals = chainDeals;
  }

  getUnbeatableDeals(currency: string): Observable<DealsViewModel[]> {
    return this.unbeatableDeals$ = this.authenticatedHttpService
      .get(this.configurationService.config.baseUrls.b2BContentApi, 'api/UnbeatableDeals/GetUnbeatableDeals?currency=' + currency).pipe(
        map((data) => {
          if (data) {
            return data.result;
          } else {
            return [];
          }
        }));
  }

  getDestinationDeals(currency: string, destinationId: number, agencyId = 0): Observable<DestinationDeal[]> {
    return this.destinationDeals$ = this.authenticatedHttpService
      .get(this.configurationService.config.baseUrls.b2BContentApi,
        'api/Destinations/GetDestinationDeals?currencyCode=' + currency + '&agencyId=' + agencyId +
        '&destinationId=' + destinationId).pipe(
          map((data) => {
            if (data === null) {
              return data;
            } else {
              return data.result;
            }
          }));
  }

  getFavouriteDestinations(agencyId = 0): Observable<DestinationsViewModel[]> {
    return this.favoriteDestinations$ = this.authenticatedHttpService
      .get(this.configurationService.config.baseUrls.b2BContentApi,
        'api/Destinations/getall?AgencyId=' + agencyId).pipe(
          map((data) => {
            if (data) {
              return data.result;
            } else {
              return [];
            }
          }));
  }

  getChainDeals(currency: string, chaindealId: number, agencyId = 0): Observable<ChainDeal[]> {
    return this.chainDeals$ = this.authenticatedHttpService
      .get(this.configurationService.config.baseUrls.b2BContentApi,
        'api/FavoriteChains/GetChainDeals?currencyCode=' + currency + '&agencyId=' + agencyId +
        '&chaindealId=' + chaindealId).pipe(
          map((data) => {
            if (data === null) {
              return data;
            } else {
              return data.result;
            }
          }));
  }

  getFavouriteChains(agencyId = 0): Observable<ChainsViewModel[]> {
    return this.favoriteDestinations$ = this.authenticatedHttpService
      .get(this.configurationService.config.baseUrls.b2BContentApi,
        'api/FavoriteChains/GetAll?AgencyId=' + agencyId).pipe(
          map((data) => {
            if (data) {
              return data.result;
            } else {
              return [];
            }
          }));
  }

  get destinationId() {
    return this._destinationId;
  }

  set destinationId(value: number) {
    this._destinationId = value;
  }

  get chaindealId() {
    return this._chaindealId;
  }

  set chaindealId(value: number) {
    this._chaindealId = value;
  }
}
