import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { GetMarkupByIdViewModel } from '../../../viewmodels/markup-mgmt/common/getmarkupbyidviewmodel';
import { MarkupSearchViewModel } from '../../../viewmodels/markup-mgmt/common/markupsearchviewmodel';
import { AgencyViewModel } from '../../../viewmodels/agency-markup/agencyviewmodel';
import { CountryViewModel } from 'src/app/common/viewmodels/countryviewmodel';
import { MarkupInfoViewModel } from '../../../viewmodels/markup-mgmt/common/markupInfoviewmodel';
import { ChargeTypeViewModel } from '../../../viewmodels/markup-mgmt/common/chargetypeviewmodel';



@Injectable({
  providedIn: 'root'
})
export class AgencyMarkupService {

  public selectedbranch: any;   // TODO: assign model
  chargeTypeDTO: ChargeTypeViewModel[];

  constructor(private configurationService: ConfigurationService,
    private authenticatedHttpService: AuthenticatedHttpService) { }

  getAgencyNameAndCode(searchText, agencyId: number): Observable<AgencyViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/GetByName?searchText=' + searchText + '&agencyId=' + agencyId).pipe(
        map(data => {
          let agencyDto: AgencyViewModel[] = [];
          if (data !== null) {
            agencyDto = data.result;
          }
          return agencyDto.sort(this.compare);
        })
      );
  }

  getBranchByUserId(userId: string): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/GetById?userId=' + userId).pipe(
        map(data => {
          const branchObj = {};
          if (data !== null) {
          branchObj['name'] = data['branchName'];
          branchObj['id'] = data['branchId'];
          // branchObj['type'] = data['branchName'];
          }
          return branchObj;
        })
      );
  }

  getChargeType(languageId: number): Observable<ChargeTypeViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/ChargeType/Get?languageId=' + languageId ).pipe(map( data => {
      this.chargeTypeDTO = [];
      if (data !== null) {
      this.chargeTypeDTO = data.result.filter( x => x.isDeleted === false);
      }
      return this.chargeTypeDTO;
    }));
  }

  getMarkupList(request: MarkupSearchViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , request, 'api/AgencyMarkup/GetList').pipe(map(data => {
        return data ? data : [];
      }));
  }

  saveMarkup(request: MarkupInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , JSON.stringify(request), 'api/AgencyMarkup/ValidateMarkupRequest').pipe(map(data => {
        return data ? data : [];
      }));
  }

  getMarkupToEdit(request: GetMarkupByIdViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , JSON.stringify(request), 'api/AgencyMarkup/GetById').pipe(map(data => {
        return data ? data : [];
      }));
  }

  getCountriesList(): Observable<CountryViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Countries/GetList').pipe(map(data => {
        let countryList: CountryViewModel[] = [];
        if (data != null) {
          countryList = data.result;
        }
        return countryList;
      }));
  }

  compare(a: AgencyViewModel, b: AgencyViewModel) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  // used to store markup selected branchs

  // tslint:disable-next-line:member-ordering
  private subject = new Subject<any>();

  updateSelectedMarkupBranch(message: any) {
    this.subject.next(message);
  }

  clearSelectedBranch() {
      this.subject.next();
  }

  getSelectedBranch(): Observable<any> {
      return this.subject.asObservable();
  }

  // TODO: remove getters setters
  set selectedMarkupBranch(branch: any) {
    this.selectedbranch = branch;
  }

  get selectedMarkupBranch(): any {
    return this.selectedbranch;
  }


}
