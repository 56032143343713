import { RolesAndPermissionsComponent } from './roles-and-permissions/roles-and-permissions.component';
import { PaymentTypeDetailsResolverService } from './../common/b2b-shared/services/payment-data-resolver.service';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AgencyProfileComponent } from './agency-profile/agency-profile.component';
import { AgencyComponent } from './agency/agency.component';
import { AgencyMgmtNavComponent } from './agency-mgmt-nav/agency-mgmt-nav.component';
import { BranchListComponent } from './branch/branch-list/branch-list.component';
import { BranchInfoComponent } from './branch/branch-info/branch-info.component';
import { CountriesResolverService, CurrencyResolverService } from '../common/b2b-shared/services/lookup-resolver.service';
import { AgentListComponent } from './agent/agent-list/agent-list.component';
import { AgentInfoComponent } from './agent/agent-info/agent-info.component';
import { CanDeactivateGuardService } from '../common/b2b-shared/services/guards/can-deactivate-guard.service';
import { AgencyBankDetailsComponent } from './agency-bank-details/agency-bank-details.component';

export const agencyRoutes: Routes =
  [
    // { path: '', redirectTo: 'agencymgmt', pathMatch: 'full' },
    // { path: 'agencymgmt', component: AgencyComponent },
    // { path: 'agencyprofile', component: AgencyProfileComponent },
    {
      path: 'agencymgmt',
      component: AgencyComponent,
      children: [
        { path: '', redirectTo: 'agencyprofile', pathMatch: 'full' },
        {
          path: 'agencyprofile',
          component: AgencyProfileComponent,
          canDeactivate: [CanDeactivateGuardService],
          data: { breadcrumb: 'AgencyProfile' }
        },
        { path: 'branch', component: BranchListComponent, data: { breadcrumb: 'AgencyBranch' } },
        {
          path: 'branch/:id/:operation', component: BranchInfoComponent, canDeactivate: [CanDeactivateGuardService],
          data: { breadcrumb: 'AgencyBranchId' }
        },
        {
          path: 'agent', component: AgentListComponent,
          data: { breadcrumb: 'AgencyAgent' }
        },
        {
          path: 'agent/:id/:operation', component: AgentInfoComponent, canDeactivate: [CanDeactivateGuardService],
          data: { breadcrumb: 'AgencyAgentId' }
        },
        { 
          path: 'agencybankdetails',
          component: AgencyBankDetailsComponent,
          canDeactivate: [CanDeactivateGuardService],
          data: { breadcrumb: 'AgencyBankDetails' } 
        },
        {
          path: 'rolesandpermissions', component: RolesAndPermissionsComponent, canDeactivate: [CanDeactivateGuardService],
          data: { breadcrumb: 'RolesAndPermissions' }
        },
      ],
      resolve: {
        countryList: CountriesResolverService,
        currencyList: CurrencyResolverService,
        paymentTypeDetails: PaymentTypeDetailsResolverService
      },
    },
  ];

@NgModule({
  imports: [RouterModule.forChild(agencyRoutes)],
  exports: [RouterModule]
})
export class AgencyRoutingModule { }

export const routedAgencyComponents = [
  AgencyProfileComponent,
  AgencyComponent,
  AgencyMgmtNavComponent,
  BranchListComponent,
  BranchInfoComponent,
  AgentListComponent,
  AgentInfoComponent,
  AgencyBankDetailsComponent,
  RolesAndPermissionsComponent
];
