import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from 'src/app/common/constants';
import { MatTableDataSource, MatPaginator, MatSort, Sort, DateAdapter, MAT_DATE_FORMATS, MatDialogRef, MatDialog } from '@angular/material';
import { Subscription, Observable, Subject } from 'rxjs';
import { JarvisError } from 'src/app/common/jarviserror';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';

import { MarkupListViewModel } from '../../viewmodels/markup-mgmt/common/markupdatalistviewmodel';
import { MarkupSearchViewModel } from '../../viewmodels/markup-mgmt/common/markupsearchviewmodel';
import { CurrencyApiService } from '../../../common/shared/services/currency-api.service';
import { AgencyMarkupService } from '../../common/b2b-shared/services/agency-markup.service';
import { DatePipe, DecimalPipe, formatNumber } from '@angular/common';
import { DatePickerDateAdapterService } from '../../common/b2b-shared/services/date-picker-date-adapter.service';
import { ChargeTypeViewModel } from '../../viewmodels/markup-mgmt/common/chargetypeviewmodel';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { ErrorMessages } from '../../../common/errormessage';
import { CountryViewModel } from 'src/app/common/viewmodels/countryviewmodel';
import { CityListViewModel } from '../../common/b2b-shared/viewmodels/city/cityListViewModel';
import { CityDataService } from '../../../common/shared/services/city-data.service';
import { Utilities } from 'src/app/common/utilities/utilities';
import { HotelViewModel } from './../../../b2b/viewmodels/reservation/reservation-list/hotelviewmodel';
import { MarkupConflictsListPopupComponent } from '../markup-conflicts-list-popup/markup-conflicts-list-popup.component';
import { Paginator } from 'primeng/paginator';
import { MarkupUtilities } from './../../viewmodels/markup-mgmt/common/markupUtilities';
import { UserInputTransformService } from '../../../common/shared/services/user-input-transform.service';
import { NavigatorService } from '../../../framework/fw/services/navigator.service';

@Component({
  selector: 'app-hotel-markup',
  templateUrl: './hotel-markup.component.html',
  styleUrls: ['./hotel-markup.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: DatePickerDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: CONSTANTS.DATE_FORMATS },
    DecimalPipe
  ]
})
export class HotelMarkupComponent implements OnInit {
  actions: string;
  create = CONSTANTS.operation.create;
  edit = CONSTANTS.operation.edit;
  read = CONSTANTS.operation.read;
  editView = false;
  readView = false;
  deleteView = false;

  searchMarkupForm: FormGroup;
  searchMarkupModel: MarkupSearchViewModel = <MarkupSearchViewModel>{};
  dataSource: MatTableDataSource<MarkupListViewModel>;
  markupList: MarkupListViewModel[] = [<MarkupListViewModel>{}];

  chargeTypeList$: Observable<ChargeTypeViewModel[]>;
  chargeTypeArr: ChargeTypeViewModel[] = [];

  markupBranch: any;

  countryList: CountryViewModel[] = [];

  noDataAvailable: boolean;
  totalRecords: number;
  Math: any;
  showGrid = false;
  pageSize = 20;
  offset = 0;
  direction = 1;
  orderBy = 'ModifiedDate';  // Default Order;
  sortOrder = 'DESC';        // Default Sort Order
  pageIndex: number;
  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  showFilter: boolean;
  defaultCurrancy: string;
  operation: string;
  selectedMarkupBranch: any;
  isPageChanged: boolean;
  isFilterApplied: boolean;

  displayedColumns = [
    'createdDate', 'from', 'to', 'hotel', 'markupType', 'markupValue',
    'currency', 'updatedDate', 'updatedBy', 'isActive', 'actions'
  ];

  status = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 },
  ];
  amount = 1;
  percentage = 2;
  dateTypeList = CONSTANTS.markup.dateTypeList;
  cityList: Observable<CityListViewModel[]>;
  hotelList: Observable<HotelViewModel[]>;
  B2BMarkupAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.B2BMarkup);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('pp') paginator: Paginator;

  private resetDatepickerSubject: Subject<void> = new Subject<void>();

  subscription: Subscription;
  messages: any = {};
  todaysDate = new Date();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private agencyMarkupService: AgencyMarkupService,
    private dialogsService: DialogsService,
    private currencyApiService: CurrencyApiService,
    private userProfileService: UserProfileService,
    private cityDataService: CityDataService,
    private dialog: MatDialog,
    public userInputTransformService: UserInputTransformService,
    private navigatorService: NavigatorService,
    public decimalPipe: DecimalPipe
  ) {

    this.subscription = this.agencyMarkupService.getSelectedBranch().subscribe(message => {
      if (message) {
        this.selectedMarkupBranch = message;
        this.searchMarkup();
      } else {
        this.messages = undefined;
      }
    });

  }

  ngOnInit() {
    this.selectedMarkupBranch = this.agencyMarkupService.selectedMarkupBranch;
    this.defaultCurrancy = this.currencyApiService.getDefaultCurrancy();
    this.countryList = this.activatedRoute.parent.snapshot.data['countryList'];

    this.Math = Math;
    this.sort.disableClear = true;

    this.getChargeType();

    this.searchMarkupForm = new FormGroup({
      dateType: new FormControl(),
      fromDate: new FormControl(),
      toDate: new FormControl(),
      countryId: new FormControl(),
      cityId: new FormControl(),
      hotelId: new FormControl(),
      chargeTypeId: new FormControl(),
      markupValueFrom: new FormControl(''),
      markupValueTo: new FormControl(''),
      statusId: new FormControl(),
    });

    this.showGrid = false;
    this.noDataAvailable = true;
    this.isFilterApplied = false;

    this.searchMarkup();
    this.addValidations();
  }

  removeCurrencyPipeFormat($event) {
    $event.target.value = MarkupUtilities.replaceComma($event.target.value);
  }

  resetDatePicker() {
    this.resetDatepickerSubject.next();
  }

  buildModel() {
    this.showGrid = true;

    this.searchMarkupModel = new MarkupSearchViewModel();
    this.searchMarkupModel.offset = this.offset;
    this.searchMarkupModel.fetchNext = this.pageSize;
    this.searchMarkupModel.orderBy = this.orderBy;
    this.searchMarkupModel.sortOrder = this.sortOrder;

    this.searchMarkupModel.fromDateKey = null;
    this.searchMarkupModel.toDateKey = null;

    this.searchMarkupModel.dateType = this.isFilterApplied ? this.searchMarkupForm.get('dateType').value : null;
    this.searchMarkupModel.fromDate = this.isFilterApplied ? this.searchMarkupForm.get('fromDate').value : null;
    this.searchMarkupModel.toDate = this.isFilterApplied ? this.searchMarkupForm.get('toDate').value : null;
    this.searchMarkupModel.countryId = this.isFilterApplied ? this.searchMarkupForm.get('countryId').value : null;
    this.searchMarkupModel.cityId = this.isFilterApplied ? this.searchMarkupForm.get('cityId').value : null;
    this.searchMarkupModel.hotelId = this.isFilterApplied ? this.searchMarkupForm.get('hotelId').value : null;
    this.searchMarkupModel.markupTypeId = this.isFilterApplied ? this.searchMarkupForm.get('chargeTypeId').value : null;
    const mValueFrom = this.isFilterApplied ? (this.searchMarkupForm.get('markupValueFrom').value ?
      +MarkupUtilities.replaceComma(this.searchMarkupForm.get('markupValueFrom').value) : null) : null;
    this.searchMarkupModel.markupValueFrom = mValueFrom;
    const mValueTo = this.isFilterApplied ? (this.searchMarkupForm.get('markupValueTo').value ?
      +MarkupUtilities.replaceComma(this.searchMarkupForm.get('markupValueTo').value) : null) : null;
    this.searchMarkupModel.markupValueTo = mValueTo;

    this.searchMarkupModel.statusId = this.isFilterApplied ? this.searchMarkupForm.get('statusId').value : null;

    this.searchMarkupModel.agencyId = this.userProfileService.getBasicUserInfo().agencyId;  // headers madhli
    this.searchMarkupModel.branchId = (this.selectedMarkupBranch.isAgency) ? 0 :
      this.selectedMarkupBranch.id; // selected all 0
    this.searchMarkupModel.currencyId = this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId; // 14;

    this.searchMarkupModel.tabId = CONSTANTS.markup.tab.hotelmarkup.id;
    this.searchMarkupModel.tabName = CONSTANTS.markup.tab.hotelmarkup.label;
  }

  getChargeType() {
    this.chargeTypeList$ = this.agencyMarkupService.getChargeType(0);
    this.chargeTypeList$.subscribe((ele) => {
      ele.forEach(element => {
        this.chargeTypeArr.push(element);
      });
    });
  }

  transformAmount(amount, column) {
    if (amount && amount !== '') {
      amount = Number(amount.replace(/[,]/g, ''));
      let markup = null;
      let isNumber = false;
      isNumber = isNaN(amount);
      if (isNumber === false) {
        amount = Number(amount);
        if (column === 'From') {
          // tslint:disable-next-line:max-line-length
          this.searchMarkupForm.controls['markupValueFrom'].setValue(formatNumber(amount, this.navigatorService.getCurrentLocale(), CONSTANTS.digitsInfo));
          markup = this.searchMarkupForm.get('markupValueFrom');
          if (this.searchMarkupForm.get('markupValueTo').hasError('validRange')) {
            this.searchMarkupForm.get('markupValueTo').updateValueAndValidity();
          }
        } else {
          // tslint:disable-next-line:max-line-length
          this.searchMarkupForm.controls['markupValueTo'].setValue(formatNumber(amount, this.navigatorService.getCurrentLocale(), CONSTANTS.digitsInfo));
          markup = this.searchMarkupForm.get('markupValueTo');
          if (this.searchMarkupForm.get('markupValueFrom').hasError('validRange')) {
            this.searchMarkupForm.get('markupValueFrom').updateValueAndValidity();
          }
        }
      }
      markup.updateValueAndValidity();
    }
  }

  numberFormatInCurrentLocaleFromPrice(value) { // If index not present, then remove the parameter
    if (value && value !== '') {
      this.searchMarkupForm.get('markupValueFrom').setValue(this.userInputTransformService.userInputNumberTransform(value));
    }
  }

  numberFormatInCurrentLocaleToPrice(value) { // If index not present, then remove the parameter
    if (value && value !== '') {
      this.searchMarkupForm.get('markupValueTo').setValue(this.userInputTransformService.userInputNumberTransform(value));
    }
  }


  getMarkupTypeByID(id) {
    let chargetype1;
    this.chargeTypeArr.forEach(element => {
      if (element['id'] === id) {
        return chargetype1 = element['chargeType'];
      }
    });

    if (chargetype1) {
      return chargetype1;
    }
  }

  searchMarkup() {
    this.buildModel();
    this.showSpinner();
    const subscription = this.agencyMarkupService.getMarkupList(this.searchMarkupModel).subscribe((markupList) => {
      this.markupList = markupList.result;

      if (this.markupList && this.markupList.length > 0) {
        this.dataSource = new MatTableDataSource<MarkupListViewModel>(this.markupList);
        this.totalRecords = +this.markupList[0]['total'];
        this.noDataAvailable = false;
        this.showGrid = true;
        if (this.offset === 0 && this.isPageChanged === true) {
          this.paginator.changePage(0);
        }
      } else {
        this.dataSource = new MatTableDataSource<MarkupListViewModel>();
        this.totalRecords = 0;
        this.noDataAvailable = true;
        this.showGrid = false;
      }

      this.dataSource.sort = this.sort;
      window.scrollTo(0, 0);
    }, (error: JarvisError) => {
      this.hideSpinner(); console.error(`Error in getting branch markup. ${error.friendlyMessage}`);
    },
      () => this.hideSpinner()
    );
    this.subscriptions.push(subscription);
  }

  addDateValidation(control) {
    this.resetDatePicker();
    this.searchMarkupForm.get('fromDate').setValue('');
    this.searchMarkupForm.get('toDate').setValue('');
    if (control === 'dateType' && this.searchMarkupForm.get(control).value) {
      this.searchMarkupForm.get('fromDate').setErrors({ required: true });
      this.searchMarkupForm.get('toDate').setErrors({ required: true });
    } else {
      this.searchMarkupForm.get('fromDate').setValidators(null);
      this.searchMarkupForm.get('toDate').setValidators(null);
    }
  }

  addValidations() {
    if (this.searchMarkupForm.get('chargeTypeId').value && this.searchMarkupForm.get('chargeTypeId').value === this.amount) {
      this.searchMarkupForm.get('markupValueFrom')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxAmount),
          Validators.min(CONSTANTS.markup.markupMinAmount),
          MarkupUtilities.markupAmountValidatior,
          MarkupUtilities.rangeValidator
        ]);
      this.searchMarkupForm.get('markupValueTo')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxAmount),
          Validators.min(CONSTANTS.markup.markupMinAmount),
          MarkupUtilities.markupAmountValidatior,
          MarkupUtilities.rangeValidator
        ]);
    } else {
      this.searchMarkupForm.get('markupValueFrom')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxPercent),
          Validators.min(CONSTANTS.markup.markupMinPercent),
          MarkupUtilities.markupPercentValidatior,
          MarkupUtilities.rangeValidator
        ]);
      this.searchMarkupForm.get('markupValueTo')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxPercent),
          Validators.min(CONSTANTS.markup.markupMinPercent),
          MarkupUtilities.markupPercentValidatior,
          MarkupUtilities.rangeValidator
        ]);
    }
    this.searchMarkupForm.get('markupValueFrom').updateValueAndValidity();
    this.searchMarkupForm.get('markupValueTo').updateValueAndValidity();
  }

  getCities() {
    const countryId = this.searchMarkupForm.get('countryId').value;
    this.searchMarkupForm.get('cityId').setValidators(null);
    this.searchMarkupForm.get('cityId').setErrors(null);
    this.searchMarkupForm.get('hotelId').setValidators(null);
    this.searchMarkupForm.get('hotelId').setErrors(null);

    this.searchMarkupForm.updateValueAndValidity();

    this.cityList = countryId ?
      this.cityDataService.searchByCountryId(countryId, '') :
      new Observable<CityListViewModel[]>();
  }

  onCityDropdownClicked() {
    if (!this.searchMarkupForm.get('cityId').disabled && !this.searchMarkupForm.get('countryId').value) {
      this.setRequiredValidator('cityId');
    }
    // this.searchMarkupForm.get('hotel').setValue(null);
  }

  onHotelDropdownClicked() {
    if (!this.searchMarkupForm.get('cityId').disabled) {
      this.setRequiredValidator('countryId');
      this.setRequiredValidator('cityId');
    }
    // this.searchMarkupForm.get('hotel').setValue(null);
  }

  getHotels() {
    const cityId = this.searchMarkupForm.get('cityId').value;
    this.hotelList = cityId ?
      this.cityDataService.searchHotelsByCityId(cityId, '') :
      new Observable<CityListViewModel[]>();
  }

  setRequiredValidator(controlName: string) {
    if (Utilities.isNullOrEmpty(this.searchMarkupForm.get(controlName).value)) {
      this.searchMarkupForm.get(controlName).setValidators(Validators.required);
      this.searchMarkupForm.get(controlName).markAsDirty();
      this.searchMarkupForm.get(controlName).markAsTouched();
      this.searchMarkupForm.get(controlName).updateValueAndValidity();
    }
  }

  hasErrors(controlName: string) {
    if (this.searchMarkupForm.get(controlName)) {
      return (this.searchMarkupForm.get(controlName).dirty || this.searchMarkupForm.get(controlName).touched)
        && this.searchMarkupForm.get(controlName).errors !== null;
    }
  }

  getValidationMessage(controlName: string) {
    if (!Utilities.isNullOrEmpty(this.searchMarkupForm.get(controlName))) {
      if (this.searchMarkupForm.get(controlName).hasError('required')) {
        if (controlName === 'cityId') {
          return ErrorMessages.requireDestinationCountry;
        } else if (controlName === 'fromDate' || controlName === 'toDate') {
          return ErrorMessages.requiredFieldMessage;
        } else {
          return ErrorMessages.requiredFieldMessage;
        }
      } else if (this.searchMarkupForm.get(controlName).hasError('maxDateError')) {
        if (controlName === 'toDate') {
          return ErrorMessages.invalidDateRangeMessage;
        }
      } else if (this.searchMarkupForm.get(controlName).hasError('max')) {
        const markupLimitMessage =
          (this.searchMarkupForm.get('chargeTypeId').value) === this.amount
            ? ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxAmount)
            : ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxPercent);
        // `${CONSTANTS.markup.markupMaxAmount | number }`
        return markupLimitMessage;
      } else if (this.searchMarkupForm.get(controlName).hasError('min')) {
        const markupLimitMessage =
          (this.searchMarkupForm.get('chargeTypeId').value) === this.amount
            ? ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinAmount)
            : ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinPercent);
        return markupLimitMessage;
      } else {
        return '';
      }
    }
  }

  calculateNightCount() {
    if (this.searchMarkupForm.get('fromDate').value && this.searchMarkupForm.get('toDate').value) {
      const dt1 = new Date(this.searchMarkupForm.get('fromDate').value);
      const dt2 = new Date(this.searchMarkupForm.get('toDate').value);
      const nightCount = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
      if (nightCount > 29) {
        this.searchMarkupForm.get('toDate').setErrors({ maxDateError: true });
      } else {
        this.searchMarkupForm.get('toDate').setErrors(null);
      }
    }
  }

  createNewBranch() {
    this.router.navigate(['./hotelmarkupInfo/',
      this.userProfileService.getBasicUserInfo().agencyId,
      this.selectedMarkupBranch.id,
      CONSTANTS.markup.tab.hotelmarkup.id,
      0,
      'create'], { relativeTo: this.activatedRoute });
  }

  showFilterSection() {
    this.showFilter = !this.showFilter;
  }

  buildSearchModel() {
    this.showGrid = true;
    this.pageIndex = 0;
    this.offset = 0;
    this.searchMarkupModel = new MarkupSearchViewModel();

    this.searchMarkupModel.offset = this.offset;
    this.searchMarkupModel.fetchNext = this.pageSize;
    this.searchMarkupModel.orderBy = this.orderBy;
    this.searchMarkupModel.sortOrder = this.sortOrder;
  }

  filterMarkup() {
    this.offset = 0;
    this.pageSize = 20;
    this.isFilterApplied = true;
    this.searchMarkup();
  }

  getCountryCount(countryListStr) {
    const countryList = countryListStr.split(',');
    return { 'count': countryList.length, 'displayCountry': countryList[0] };
  }

  displayConflictingFields(conflictList) {
    const confList = conflictList.split(',');
    let dialogRef: MatDialogRef<MarkupConflictsListPopupComponent>;
    const title = {};
    title['caption'] = 'Hotels';
    title['subCaption'] = `(Displaying ${confList.length} Hotels)`;

    dialogRef = this.dialog.open(MarkupConflictsListPopupComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.conflictsList = confList;
  }

  reset() {
    this.offset = 0;
    this.pageSize = 20;

    this.searchMarkupForm.setErrors(null);
    this.searchMarkupForm.get('countryId').setValidators(null);
    this.searchMarkupForm.get('hotelId').setValidators(null);
    this.searchMarkupForm.get('cityId').setValidators(null);
    this.searchMarkupForm.get('fromDate').setValidators(null);
    this.searchMarkupForm.get('toDate').setValidators(null);
    this.searchMarkupForm.reset();
    this.searchMarkupForm.updateValueAndValidity();
    this.cityList = new Observable<CityListViewModel[]>();
    this.hotelList = new Observable<HotelViewModel[]>();
    this.searchMarkup();
    this.resetDatePicker();
    // this.isPageChanged = true;

  }

  updateMarkup(value, operation) {
    this.router.navigate(['./hotelmarkupInfo/',
      value.agencyId,
      value.branchId,
      CONSTANTS.markup.tab.hotelmarkup.id,
      value.groupId,
      operation],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  sortData(sort: Sort) {
    if (sort.direction !== '') {
      this.orderBy = sort.active;
      this.sortOrder = sort.direction;
      this.searchMarkupModel.orderBy = sort.active;
      this.searchMarkupModel.sortOrder = sort.direction;
      this.searchMarkupModel.offset = this.offset;
      this.searchMarkupModel.fetchNext = this.pageSize;
      this.searchMarkup();
    }
  }

  onPageChange(event) {
    this.pageIndex = event.pageIndex;
    this.offset = event.first;
    this.pageSize = event.rows;
    this.searchMarkupModel.offset = this.offset;
    this.searchMarkupModel.fetchNext = this.pageSize;
    this.isPageChanged = (event.page === 0) ? false : true;
    this.searchMarkup();
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1000);
  }

  showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

}


