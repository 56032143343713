import { CONSTANTS } from 'src/app/common/constants';
import { Utilities } from 'src/app/common/utilities/utilities';

export class MarkupUtilities {
  static isNullOrEmpty = function (value: any): boolean {
    return value === undefined || value === null || value.toString().trim() === '';
  };

  static markupAmountValidatior = function (control) {
    if (control.value !== null) {
      if (control.value.includes(',') === true) {
        if (control.value.includes('.') === true) {
          const amount = control.value.replace(/[,]/g , '');
          if (amount > CONSTANTS.markup.markupMaxAmount) {
            return {'max': true};
          }
          if (amount < CONSTANTS.markup.markupMinAmount) {
            return {'min': true};
          }
        }
        return null;
      } else {
        if ((control.value >= 0) || (control.value === null) || (control.value === '')) {
          return null;
        } else {
          return { 'validateMarkupValue': true };
        }
      }
    }
  };

  static markupPercentValidatior = function (control) {
    if (control.value !== null) {
      if (control.value.includes(',') === true) {
        if (control.value.includes('.') === true) {
          const amount = control.value.replace(/[,]/g , '');
          if (amount > CONSTANTS.markup.markupMaxPercent) {
            return {'max': true};
          }
          if (amount < CONSTANTS.markup.markupMinPercent) {
            return {'min': true};
          }
        }
        return null;
      } else {
        if ((control.value >= 0) || (control.value === null) || (control.value === '')) {
          return null;
        } else {
          return { 'validateMarkupValue': true };
        }
      }
    }
  };

  static rangeValidator(control) {
    let isValidRange = false;
    if (!Utilities.isNullOrEmpty(control.parent)) {
      const parentControl = control.parent;
      if (!Utilities.isNullOrEmpty(parentControl.get('markupValueFrom').value)
      && !Utilities.isNullOrEmpty(parentControl.get('markupValueTo').value)) {
        let markupValueFrom =  parentControl.get('markupValueFrom').value; // as any - ('' as any);
        markupValueFrom = (markupValueFrom != null && markupValueFrom !== undefined && markupValueFrom !== '')
        ? markupValueFrom.replace(/[,]/g, '') : null;
        let markupValueTo = parentControl.get('markupValueTo').value; // as any - ('' as any);
        markupValueTo = (markupValueTo != null && markupValueTo !== undefined && markupValueTo !== '')
        ? markupValueTo.replace(/[,]/g, '') : null;
        if (+(markupValueTo) < +(markupValueFrom)) {
          isValidRange = true;
        }
      }
    }
    return isValidRange ? { 'validRange': true } : null;
  }

  static replaceComma(value: any): string {
    let replacedString = '';
    if (value != null && value !== undefined && value !== '') {
      replacedString = value.replace(/[,]/g, '');
    }
    return replacedString;
  }

}
