import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './common/default/default/default.component';
import { UnauthorizedComponent } from './framework/fw/unauthorized/unauthorized.component';
import { HomeComponent } from './b2b/home-mgmt/home/home.component';
import { HotelInfoComponent } from './b2b/hotel-mgmt/hotel-info/hotel-info.component';
/*
import { DealInfoComponent } from './b2b/home-mgmt/deal-info/deal-info.component';
import { MgBedbankAboutComponent } from './b2b/home-mgmt/mg-bedbank-about/mg-bedbank-about.component';
import { ContactComponent } from './b2b/home-mgmt/contact/contact.component';
import { TermsAndConditionsComponent } from './b2b/home-mgmt/terms-and-conditions/terms-and-conditions.component';
import { AgentRegistrationComponent } from './b2b/home-mgmt/registration-forms/agent-registration/agent-registration.component';
import { SupplierRegistrationComponent } from './b2b/home-mgmt/registration-forms/supplier-registration/supplier-registration.component';
import { RegistrationComponent } from './b2b/home-mgmt/registration/registration.component';
import { GroupBookingComponent } from './b2b/home-mgmt/group-booking/group-booking.component';
*/
import {
  CountriesResolverService,
  SalutationsResolverService,
  StarRatingResolverService,
  NationalityResolverService
} from './b2b/common/b2b-shared/services/lookup-resolver.service';
import { AuthenticatedUserComponent } from './b2b/authenticated-user/authenticated-user.component';
import { DefaultCurrencyResolverService } from './b2b/common/b2b-shared/services/resolvers/default-currency-resolver.service';
import { FavouriteDestinationsResolverService } from './b2b/common/b2b-shared/services/resolvers/favourite-destinations-resolver.service';
import { AgencyCurrencyResolverService } from './b2b/common/b2b-shared/services/resolvers/agency-currency-resolver.service';
import { AgencyRequestCreditComponent } from './framework/fw/users/agency-request-credit/agency-request-credit.component';
import { HotelListComponent } from './b2b/hotel-mgmt/hotel-list/hotel-list.component';
import { FaqComponent } from './b2b/home-mgmt/faq/faq.component';
import { ChangePasswordComponent } from './framework/fw/users/change-password/change-password.component';
import { UserProfileComponent } from './framework/fw/users/user-profile/user-profile.component';
import { HotelSearchComponent } from './b2b/common/b2b-shared/components/hotel-search/hotel-search.component';
import { HotelListFilterResolverService } from './b2b/common/b2b-shared/services/resolvers/hotel-list-filter-resolver.service';
import { HotelDetailsComponent } from './b2b/hotel-mgmt/hotel-details/hotel-details.component';
import { GuestDetailsComponent } from './b2b/hotel-mgmt/guest-details/guest-details.component';
import { AlertListComponent } from './common/shared/alerts/alert-list/alert-list.component';
import { AlertDataResolverService } from './common/shared/services/alertdata-resolver.service';
import { CurrencyApi } from './framework/fw/currency/currency-api';
import { BookingCompleteInformationComponent } from './b2b/hotel-mgmt/booking-complete-information/booking-complete-information.component';
import { CanDeactivateGuardService } from './b2b/common/b2b-shared/services/guards/can-deactivate-guard.service';
import { ErrorComponent } from './framework/fw/error/error.component';
import { BookingCancelInfoComponent } from './b2b/hotel-mgmt/booking-cancel-info/booking-cancel-info.component';
import { BoHomeComponent } from './b2b/home-mgmt/bo-home/bo-home.component';
import { PaymentTypeDetailsResolverService } from './b2b/common/b2b-shared/services/payment-data-resolver.service';
import { PaydollarPaymentComponent } from './b2b/payment-mgmt/paydollar-payment/paydollar-payment.component';
import { SecureHashResolverService } from './b2b/common/b2b-shared/services/secure-hash-resolver.service';
import { MsgForMobileMenuComponent } from './b2b/common/b2b-shared/components/msg-for-mobile-menu/msg-for-mobile-menu/msg-for-mobile-menu.component';

export const routes: Routes = [
  { path: 'default', component: DefaultComponent },
  { path: 'bohome', component: BoHomeComponent },

  {
    path: 'home', component: HomeComponent,
    resolve: {
      defaultCurrency: DefaultCurrencyResolverService
    },
  },

  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'siteinfomgmt', loadChildren: './b2b/site-info/site-info.module#SiteInfoModule' },
  {
    path: 'authenticated', component: AuthenticatedUserComponent,
    resolve: {
      agencyCurrency: AgencyCurrencyResolverService
    },
    children: [
      // {path: 'hotelmgmt/paymentgateway', component: PaydollarPaymentComponent,
      // resolve: { secureHash: SecureHashResolverService }},
      { path: 'callcentermgmt', loadChildren: './b2b/callcenter-mgmt/callcenter-mgmt.module#CallcenterMgmtModule' },
      { path: '', redirectTo: 'hotelmgmt/hotelinfo', pathMatch: 'full' },
      {
        path: 'hotelmgmt/hotelinfo', component: HotelInfoComponent, canDeactivate: [CanDeactivateGuardService],
        resolve: { nationalityList: NationalityResolverService, agencyCurrency: AgencyCurrencyResolverService,
          paymentTypeDetails: PaymentTypeDetailsResolverService},
        /*  data: {  breadcrumb: 'home' } */
      },
      {
        path: 'hotelmgmt/hotellist', component: HotelListComponent, resolve: {
         // hotelFilterList: HotelListFilterResolverService,
          nationalityList: NationalityResolverService
        },
        data: { breadcrumb: 'Hotel List' }
      },
      {
        path: 'hotelmgmt/hoteldetails',
        component: HotelDetailsComponent,
        resolve: { nationalityList: NationalityResolverService },
        data: { breadcrumb: 'HotelDetails' }
      },
      {
        path: 'hotelmgmt/hoteldetails/:hotelcode',
        component: HotelDetailsComponent,
        resolve: { nationalityList: NationalityResolverService },
        data: { breadcrumb: 'HotelDetails' }
      },
      {
        path: 'hotelmgmt/guestdetails', component: GuestDetailsComponent, canDeactivate: [CanDeactivateGuardService],
        resolve: { salutationList: SalutationsResolverService,
          paymentTypeDetails: PaymentTypeDetailsResolverService},
        data: { breadcrumb: 'GuestDetails' }
      },
      {
        path: 'hotelmgmt/completebooking', component: BookingCompleteInformationComponent,
        resolve: { nationalityList: NationalityResolverService },
        data: { breadcrumb: 'CompleteBooking' }
      },
      {
        path: 'hotelmgmt/cancelbooking', component: BookingCancelInfoComponent,
        data: { breadcrumb: 'CancelBooking' }
      },
      { path: 'bookingmgmt', loadChildren: './b2b/booking-mgmt/booking-mgmt.module#BookingMgmtModule' },
      { path: 'agency', loadChildren: './b2b/agency-mgmt/agency-mgmt.module#AgencyMgmtModule' },
      { path: 'report', loadChildren: './b2b/report-mgmt/report-mgmt.module#ReportMgmtModule' },
      { path: 'markup', loadChildren: './b2b/markup-mgmt/markup-mgmt.module#MarkupMgmtModule' },
      { path: 'deals', loadChildren: './b2b/deal-mgmt/deal-mgmt.module#DealMgmtModule' },
      { path: 'payment', loadChildren: './b2b/payment-mgmt/payment-mgmt.module#PaymentMgmtModule' },
      { path: 'requestcredit', component: AgencyRequestCreditComponent,
       data: { breadcrumb: 'Request Credit' }, canDeactivate: [CanDeactivateGuardService] },
      { path: 'faq', component: FaqComponent, data: { breadcrumb: 'Faq' } },
      { path: 'userprofile', component: UserProfileComponent,
      data: { breadcrumb: 'User Profile' } },
      { path: 'changepassword', component: ChangePasswordComponent,
      data: { breadcrumb: 'Change Password' }, canDeactivate: [CanDeactivateGuardService] },
      {
        path: 'alertlist', component: AlertListComponent, canDeactivate: [CanDeactivateGuardService], resolve: {
          alertDetails: AlertDataResolverService,
        }
      },
      {
        path: 'msgformobilemenu', component: MsgForMobileMenuComponent,
        data: { breadcrumb: 'Mobile Version Message' }
      },/* ,
      { path: 'hotelmgmt/hotelsearch', component: HotelSearchComponent, resolve: { nationalityList: NationalityResolverService } }, */
    ]
  },
  { path: '', component: DefaultComponent },
  { path: '**', component: DefaultComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
