import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { ConfigurationService } from './configuration.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { MenuDataService } from './menu-data.service';
import { initialMenuItems } from '../../../app.menu';
import { UserDetailsViewModel, ApplicationPermissionViewModel } from '../../viewmodels/userdetailsviewmodel';
import { UserInfoViewModel } from '../../viewmodels/userinfoviewmodel';
import { CONSTANTS } from '../../constants';
import { TopBarDataService } from './top-bar-data.service';
import { AlertDataService } from './alert-data.service';
import { CurrencyApi } from '../../../framework/fw/currency/currency-api';
import { HomeCarouselDataService } from './home-carousel-data.service';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAccountService } from './user-account.service';
import { StateService } from './state.service';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
import { CallcenterService } from 'src/app/b2b/common/b2b-shared/services/callcenter.service';
import { HttpClient } from '@angular/common/http';

export interface IUserPermission {
  hasViewAccess: boolean;
  hasCreateAccess: boolean;
  hasEditAccess: boolean;
  hasDeleteAccess: boolean;
  hasApproveAccess: boolean;
}

export class UserLogin {
  userId: string;
  action: string;
  ipAddress: string;
  username: string;
  application = 'B2B';

  constructor(userId: string, ipAddress: string, action: string, username: string) {
    this.userId = userId;
    this.ipAddress = ipAddress;
    this.action = action;
    this.username = username;
  }
}

@Injectable({
  providedIn: 'root'
})

export class UserProfileService {
  userDetailsDto: UserDetailsViewModel;
  basicUserInfo: UserInfoViewModel;
  clientIPAddress: string = '';
  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService,
    private authService: AuthService, private _router: Router,
    private topBarDataService: TopBarDataService,
    private menuDataService: MenuDataService,
    private alertDataService: AlertDataService,
    private currencyApi: CurrencyApi,
    private homeCarouselDataService: HomeCarouselDataService,
    private stateService: StateService,
    private frameworkConfigService: FrameworkConfigService,
    private callCenterDataService: CallcenterService,
    private router: Router,
    private http: HttpClient
  ) {
    this.userDetailsDto = new UserDetailsViewModel;
  }
  // Event when the currency gets initialized.
  // private currencyInitSource = new Subject<null>();
  // currencyInit$ = this.currencyInitSource.asObservable();
  private subject = new Subject<boolean>();

  sendData(isUserProfileLoaded: boolean) {
    this.subject.next(isUserProfileLoaded);
  }

  getData(): Observable<boolean> {
    return this.subject.asObservable();
  }

  getUserProfile() {
    // alert('userprofile' + this.authService.isLoggedIn());
    if (this.authService.isLoggedIn()) {
      this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi,
        'api/User/' + this.authService.getSubjectId()).subscribe((user) => {
          this.userDetailsDto = user;
          if (this.userDetailsDto) {
            // alert('username' + this.userDetailsDto.userName);
            // Get only the b2b application
            this.KeepOnlyB2bApp();
            if (this.userDetailsDto.userType === CONSTANTS.userType.mgUser) {
              this.frameworkConfigService.IsCallCenterUser = true;
              this.frameworkConfigService.ShowStatusBar = false;
              this.frameworkConfigService.ShowMenu = false;
              this.callCenterDataService.GetChannelManagerList().subscribe(data => {
                this.callCenterDataService.sendChannelManagerListData(data);
              });
            }
            this.callCenterDataService.GetSupplierList().subscribe(data => {
              this.callCenterDataService.sendSupplierListData(data);
            });
            if (this.userDetailsDto.disabled || this.userDetailsDto.applicationPermissions === undefined
              || this.userDetailsDto.applicationPermissions.length === 0) {
              this.sendData(true);
              this.SetIPAddress('FailLogin', null, this.userDetailsDto.userName);
              this._router.navigate(['/unauthorized']);
            } else {
              this.SetIPAddress('Login', this.authService.getSubjectId());
              this.setBasicUserInfo(this.userDetailsDto);              
              this.sendData(true);
              if (this.userDetailsDto.isPasswordExpired) {
                this.router.navigate(['/authenticated/changepassword']);
              }
              else {
                // Give it all information that is needed to display the TopBar component
                this.topBarDataService.InitializeTopBarData(this.userDetailsDto);

                if (this.basicUserInfo.userType === CONSTANTS.userType.agentUser) {
                  // Get Agency Specfic Currency
                  this.currencyApi.getAgencyPreferredCurrencyList(this.basicUserInfo.agencyCode, this.basicUserInfo.agencyDefaultCurrencyId);
                }
                // Give it all information that is needed to display the Menu component
                this.menuDataService.initializeLoggedInMenus(this.userDetailsDto);

                // Need to call Alert Data Service
                this.alertDataService.InitializeAlertData();

                this.currencyApi.selectedCurrency = '';
                //  this._router.navigate(['/hotelmgmt/hotelinfo']);
                // Need to call Agency Specific Currencies
                this.homeCarouselDataService.initializeHomeCarousel();
                //  this._router.navigate(['/authenticated']);
                this.stateService.initializeState();
              }
            }
          }
        });
    } else {
      this.sendData(true);
      this.homeCarouselDataService.initializeHomeCarousel();
      // Initialize Non Logged in Menu
      this.menuDataService.initializeNonLoggedInMenus(initialMenuItems);
      // Call All Currencies.
      // this.currencyApi.getCurrencies();
      this.currencyApi.getCurrencyList();
      this.currencyApi.selectedCurrency = '';
      // this._router.navigate(['/home']);
      this.stateService.initializeState();
    }
  }

  private KeepOnlyB2bApp() {
    let isB2bFound = false;
    let b2bAppPermission: ApplicationPermissionViewModel =
      new ApplicationPermissionViewModel();
    this.userDetailsDto.applicationPermissions.forEach(element => {
      if (element.name.toUpperCase() === CONSTANTS.application.b2b.toUpperCase()) {
        b2bAppPermission = element;
        isB2bFound = true;
      }
    });
    // Remove all applications irrespective
    this.userDetailsDto.applicationPermissions.splice(0,
      this.userDetailsDto.applicationPermissions.length);
    // If Extranet Found then remove all Apps and keep only extranet
    if (isB2bFound) {
      this.userDetailsDto.applicationPermissions.push(b2bAppPermission);
    }
  }

  getApplicationID() {
    return this.userDetailsDto.applicationPermissions[0];
  }

  getUserRole() {
    return this.userDetailsDto.applicationPermissions[0].role;
  }


  setBasicUserInfo(userDetails: UserDetailsViewModel) {
    this.basicUserInfo = new UserInfoViewModel();
    this.basicUserInfo.firstName = userDetails.firstName;
    this.basicUserInfo.lastName = userDetails.lastName;
    this.basicUserInfo.userType = userDetails.userType;
    this.basicUserInfo.userName = userDetails.userName;
    this.basicUserInfo.email = userDetails.email;
    this.basicUserInfo.id = userDetails.id;
    this.basicUserInfo.isPasswordExpired = userDetails.isPasswordExpired;
    if (this.basicUserInfo.userType === CONSTANTS.userType.agentUser) {
      this.basicUserInfo.agencyNationalityId = userDetails.agencyNationalityId;
      this.basicUserInfo.agencyCode = userDetails.userName.split('_')[0];
      this.basicUserInfo.agencyId = userDetails.agencyId;
      this.basicUserInfo.agencyDefaultCurrencyId = userDetails.agencyDefaultCurrencyId;
      this.basicUserInfo.isCredit = userDetails.isCredit;
      // tslint:disable-next-line:max-line-length
      this.basicUserInfo.isSuperUser = (this.configurationService.config.superUserRoleId === userDetails.applicationPermissions[0]['roleId'] || this.configurationService.config.superUserViewOnlyRoleID === userDetails.applicationPermissions[0]['roleId']) ? true : false;
      this.basicUserInfo.agentId = userDetails.id; // added for agentID on b2b
    }
  }  

  setAgentInfo(userDetails: UserInfoViewModel) {
    // this.basicUserInfo = new UserInfoViewModel();
    this.basicUserInfo.isCredit = userDetails.isCredit;
    this.userDetailsDto.isCredit = userDetails.isCredit;
    this.userDetailsDto.agencyId = userDetails.agencyId;
    this.userDetailsDto.agencyDefaultCurrencyId = userDetails.agencyDefaultCurrencyId;
    this.userDetailsDto.agencyNationalityId = userDetails.agencyNationalityId;

    this.basicUserInfo.agentId = userDetails.agentId;
    this.basicUserInfo.agencyNationalityId = userDetails.agencyNationalityId;
    this.basicUserInfo.agencyId = userDetails.agencyId;
    this.basicUserInfo.agencyDefaultCurrencyId = userDetails.agencyDefaultCurrencyId;
    this.basicUserInfo.branchId = userDetails.branchId;
    this.basicUserInfo.isSuperUser = userDetails.isSuperUser;
    this.basicUserInfo.agencyCode = userDetails.userName !== undefined ? userDetails.userName.split('_')[0] : '';
  }

  getBasicUserInfo() {
    return this.basicUserInfo;
  }

  getDesignationById(designationId: number): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , designationId, 'api/GetDesignationById').pipe(map((data: any) => {
        let designationCode;
        if (data != null) {
          designationCode = data.result[0];
        }
        return designationCode;
      }));
  }

  GetUserPermissionsForFeature(applicationName: string, featureTypeId: number) {
    let priviligesArray = Array<string>();
    if (this.userDetailsDto.applicationPermissions !== null) {
      const apps = this.userDetailsDto.applicationPermissions
        .filter(app => app.name.toUpperCase() === applicationName.toUpperCase());
      if ( apps != null && apps.length > 0) {
        // First Check whether the feature exists by filter based on the passed feature , then do the below
        const feature = apps[0].featuresList.find(fea => fea.featureTypeId === featureTypeId );
        if (feature !== null) {
          const priv = apps[0].featuresList.find(fea => fea.featureTypeId === featureTypeId )
            .privileges;
          // const privArray = priv.split('|');
          priviligesArray = priv.split('|');
          return priviligesArray;
        }
      }
    }
    return null;
  }

  getAllPermissionForFeature(applicationName: string, featureTypeId: number): IUserPermission {
    const permissions: IUserPermission = {
      hasViewAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.View),
      hasCreateAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Create),
      hasEditAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Edit),
      hasDeleteAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Delete),
      hasApproveAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Approve)
    };
    return permissions;
  }

  doesFeatureHaveAnyPermission(applicationName: string, featureTypeId: number): boolean {
    const permissions: IUserPermission = {
      hasViewAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.View),
      hasCreateAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Create),
      hasEditAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Edit),
      hasDeleteAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Delete),
      hasApproveAccess: this.getSpecificPermissionForFeature(applicationName, featureTypeId, CONSTANTS.privileges.Approve)
    };

    return permissions.hasViewAccess || permissions.hasCreateAccess || permissions.hasEditAccess
      || permissions.hasDeleteAccess || permissions.hasApproveAccess;
  }

  getSpecificPermissionForFeature(applicationName: string,
    featureTypeId: number, permission: string): boolean {
    if (this.userDetailsDto.applicationPermissions !== null) {
      const apps = this.userDetailsDto.applicationPermissions
        .filter(app => app.name.toUpperCase() === applicationName.toUpperCase());
      if (apps != null && apps.length > 0) {
        const feature = apps[0].featuresList.find(fea => fea.featureTypeId === featureTypeId);
        if (feature !== null) {
          if (apps[0].featuresList.find(fea => fea.featureTypeId === featureTypeId) === undefined) {
            return false;
          }
          const priv = apps[0].featuresList.find(fea => fea.featureTypeId === featureTypeId)
            .privileges;
          if (priv.indexOf(permission) > -1) {
            return true;
          }
        }
      }
    }
    return false;
  }

  SetIPAddress(action: string, userId: string, username: string = '') {
    if(this.clientIPAddress === '') {
      this.http.get<{ip:string}>(CONSTANTS.ClientIPAddressURL).subscribe(data => {
        if(data) {
          this.clientIPAddress = data.ip;  
          this.SaveIPAddress(action, userId, username);        
        }  
      });
    }
    else {
      this.SaveIPAddress(action, userId, username);
    }
  }

  async SaveIPAddress(action: string, userId: string, username: string = '') {
    try {
      const userLoginModel = new UserLogin(userId, this.clientIPAddress, action, username);
      await this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi, userLoginModel
        , 'api/Miscellaneous/LoginAuditDetails').toPromise();
    }
    catch {
      console.log('Error in SaveIPAddress...');
    }
  }


}
