import { ConfigurationService } from './../../../../../common/shared/services/configuration.service';
import { PaymentDataService } from './../../services/payment-data.service';
import { UserProfileService } from './../../../../../common/shared/services/user-profile.service';
import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { CurrencyApi } from '../../../../../framework/fw/currency/currency-api';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelDataService } from '../../services/hotel-data.service';
import { RoomInfoDataService } from '../../../b2b-shared/services/room-info-data.service';
import { DialogsService } from '../../../b2b-shared/dialogs/dialogs.service';
import { SorterService } from '../../services/sorter.service';
import { JarvisError } from '../../../../../common/jarviserror';
import { Subscription } from 'rxjs';
import { RoomDetailViewModel, RoomCategoryInfoViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelviewmodel';
import { HotelRecheckService } from '../../services/hotel-recheck.service';
import { CONSTANTS } from 'src/app/common/constants';
import { Utilities } from 'src/app/common/utilities/utilities';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { ErrorCodes } from 'src/app/common/errorcodes';
import { SupplierCode } from '../../../../../common/enum';
import { RecheckRateDialogComponent } from 'src/app/b2b/hotel-mgmt/hotel-list-view/hotel-list-view.component';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
import { CallcenterService } from '../../services/callcenter.service';
import { DealsDataService } from '../../services/deals-data.service';
import { errorConstants } from 'src/app/common/friendlymessage';
import { AgencyProfileDataService } from '../../services/agency-profile-data.service';
import { ScreenService } from 'src/app/framework/fw/services/screen.service';
import { MGFPointsConverter } from 'src/app/common/viewmodels/appsettingsviewmodel';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.css']
})
export class RoomListComponent implements OnInit, OnDestroy , OnChanges  {
  roomId: number;
  title: string;
  roomSize: string;
  beds: string;
  guest: string;
  facilities: any;
  description: string;
  smoking: string;
  nightlyRate: any;
  noOfRooms: any;
  showNightlyRate: boolean;
  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  imagelist: any[] = [];
  ascending = CONSTANTS.sortDirection.ascending;
  promotionTypes = CONSTANTS.promotionTypes;
  supplierCode = SupplierCode;
  recheckXmlError = errorConstants.recheckErrorMessage;
  errorCode: string;
  errorMessage: string;
  HotelsBookPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.HotelsBookProperties);
  UnbeatableDealsBookPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.UnbeatableDealsBookProperties);
  ChainDealsBookPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ChainDealsBookProperties);
  disableBookForCallCenter: boolean;
  openDateHotelVoucher = this.configurationService.config.openDateHotelVoucher;
  isMGPointApplicable: any;
  mGFPointsConverter:MGFPointsConverter;
  exchangeRate: number;

  constructor(
    private activatedRoute: ActivatedRoute, private router: Router,
    private hotelDataService: HotelDataService,
    private roomInfoDataService: RoomInfoDataService,
    private dialogsService: DialogsService,
    private sorterService: SorterService,
    public dialog: MatDialog, public dealsDataService: DealsDataService,
    private hotelRecheckService: HotelRecheckService, private userProfileService: UserProfileService,
    public frameworkConfigService: FrameworkConfigService, private callCenterDataService: CallcenterService,
    public paymentDataService: PaymentDataService, public currencyApi: CurrencyApi,
    public configurationService: ConfigurationService, private agencyProfileDataService: AgencyProfileDataService, public screenService: ScreenService,
    private configservice: ConfigurationService, private topBarService: TopBarService) { }
    @Input() roomsArray: RoomCategoryInfoViewModel[];

  ngOnInit() {
    this.isMGPointApplicable = this.agencyProfileDataService.isMGPointApplicable;
    this.exchangeRate = +localStorage.getItem('exchangeRate');
    const subscription = this.paymentDataService.getPaymentTypeDetailsByAgencyId(
      this.currencyApi.selectedCurrency, this.userProfileService.getBasicUserInfo().agencyId).subscribe(data => {
        if (!data.isCreditBalance && !data.isWireTransfer && this.frameworkConfigService.IsCallCenterUser) {
          this.disableBookForCallCenter = true;
        } else {
          this.disableBookForCallCenter = false;
        }
        sessionStorage.setItem('paymentTypeDetails', JSON.stringify(data));
      },
        (error: JarvisError) => {
          // this.hideSpinner();
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          dialogRef.componentInstance.message = `${error.friendlyMessage}`;
          dialogRef.componentInstance.buttonText = 'OK';
        },
        // this.hideSpinner()
      );
    this.subscriptions.push(subscription);
    this.showNightlyRate = false;
    if (!Utilities.isNullOrUndefined(this.roomsArray)) {
      // this.roomsArray = this.sortRoomByIsMapped();
      if (this.frameworkConfigService.IsCallCenterUser) {
        this.roomsArray.forEach(roomCateInfo => {
          roomCateInfo.roomDetails.forEach(roomDetail => {
            if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('S')) {
              const supplierId = roomDetail.mgno.substring(1);
              if (!Utilities.isNullOrEmpty(supplierId) && !Utilities.isNullOrEmpty(this.callCenterDataService.supplierListData)) {
                this.callCenterDataService.supplierListData.subscribe(supplierdata => {
                  roomDetail.supplierName = supplierdata.filter(x => x.id === +supplierId).map(s => s.name)[0];
                });
              }
            } else if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('C')) {
              const channelManagerId = roomDetail.mgno.substring(1);
              // tslint:disable-next-line: max-line-length
              if (!Utilities.isNullOrEmpty(channelManagerId) && !Utilities.isNullOrEmpty(this.callCenterDataService.channelManagerListData)) {
                this.callCenterDataService.channelManagerListData.subscribe(cmdata => {
                  roomDetail.supplierName = 'MGDynamicCM - ' + cmdata.filter(x => x.id === +channelManagerId).map(s => s.name)[0];
                });
              }
            } else if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('T')) {
              const channelManagerId = roomDetail.mgno.substring(1);
              // tslint:disable-next-line: max-line-length
              if (!Utilities.isNullOrEmpty(channelManagerId) && !Utilities.isNullOrEmpty(this.callCenterDataService.channelManagerListData)) {
                this.callCenterDataService.channelManagerListData.subscribe(cmdata => {
                  roomDetail.supplierName = 'CMStatic - ' + cmdata.filter(x => x.id === +channelManagerId).map(s => s.name)[0];
                });
              }
            } else if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('D')) {
              const supplierId = roomDetail.mgno.substring(1);
              if (!Utilities.isNullOrEmpty(supplierId) && !Utilities.isNullOrEmpty(this.callCenterDataService.supplierListData)) {
                this.callCenterDataService.supplierListData.subscribe(supplierdata => {
                  roomDetail.supplierName = 'SupplierDirect - ' + supplierdata.filter(x => x.id === +supplierId).map(s => s.name)[0];
                });
              }
            }
          });
        });
      }

      this.roomsArray.forEach(roomCateInfo => {
        roomCateInfo.roomDetails.forEach(roomDetail => {
          if (roomDetail.mgno !== undefined && roomDetail.mgno.startsWith('S')) {
            const supplierId = roomDetail.mgno.substring(1);
            // tslint:disable-next-line:max-line-length
            if (!Utilities.isNullOrEmpty(supplierId)) {
              this.callCenterDataService.supplierListData.subscribe(supplierdata => {
                const supplierCode = supplierdata.filter(x => x.id === +supplierId).map(s => s.code)[0];
                // tslint:disable-next-line:max-line-length
                roomDetail.isMGPreferredSupplier = this.configurationService.config.listOfMGPreferredSupplier.includes(supplierCode) ? true : false;
              });
            }
          }
        });
      });
    }
    this.mGFPointsConverter = this.configservice.config.mgfPointsConverter;
  }

  ngOnChanges(): void {
    this.showNightlyRate = false;
    if (!Utilities.isNullOrUndefined(this.roomsArray)) {
      // this.roomsArray = this.sortRoomByIsMapped();
    }
  }

  onBookHotelClick(roomCode: string, roomDetailViewModel: RoomDetailViewModel) {
    // console.log(this.hotelDataService.selectedHotel.hotelId);
    this.hotelRecheckService.mapSelectedRoomDetails(roomDetailViewModel,
      roomCode, this.hotelDataService.selectedHotel.hotelId);
    this.showSpinner();
    const subscription = this.hotelDataService.getRecheckAvailability(this.hotelRecheckService.recheckSearchDetails).
      subscribe((data) => {
        if (data === null) {
          // tslint:disable-next-line:no-unused-expression
          // console.error(`Error in Recheck. ${error.friendlyMessage}`);
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          // tslint:disable-next-line: max-line-length
          dialogRef.componentInstance.message = `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.`;
          dialogRef.componentInstance.buttonText = 'OK';
          this.hideSpinner();
        } else {
          // tslint:disable-next-line: max-line-length
          if (!Utilities.isNullOrUndefined(data.errorModel) && data.errorModel.xmlErrorCode !== null && data.errorModel.errorCode === 200) {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Message';
            this.errorMessage = data.errorModel.message;
            let filterData = this.recheckXmlError.filter(error => {
              if (data.errorModel.xmlErrorCode === error.code) {
                this.errorMessage = error.message;
              }
            });
            filterData = {}[''];
            dialogRef.componentInstance.message = this.errorMessage;
            dialogRef.componentInstance.buttonText = 'OK';
            this.hideSpinner();
          } else {
          this.hotelRecheckService.hotelRecheckDetails = data;
          this.hotelRecheckService.recheckSearchDetails.cancellationPolicyType = data.hotel.roomDetails.cancellationPolicyType;
          this.hotelRecheckService.recheckSearchDetails.packageRate = data.hotel.roomDetails.packageRate;
          }
        }
        if (data.hotel !== undefined) {
          if ((roomDetailViewModel.grossPrice !== data.hotel.roomDetails.grossPrice) &&
            (data.hotel.roomDetails.grossPrice - roomDetailViewModel.grossPrice) > 0 ? true : false) {

            let dialogRef: MatDialogRef<RecheckRateDialogComponent>;
            dialogRef = this.dialog.open(RecheckRateDialogComponent, {
              data: {
                updatedGrossPrice: data.hotel.roomDetails.grossPrice,
                oldGrossPrice: roomDetailViewModel.grossPrice,
                grossPriceDiff: (data.hotel.roomDetails.grossPrice
                  - roomDetailViewModel.grossPrice),
                isAvailable: data.hotel.roomDetails.isAvail,
                currency: data.currency,
                isCreditCard: false,
                updatedNetPrice: data.hotel.roomDetails.netPrice,
                oldNetPrice: data.hotel.roomDetails.netPrice,
                netPriceDiff: (data.hotel.roomDetails.netPrice
                  - data.hotel.roomDetails.netPrice),
                isSecondRecheck: false,
                isQRISDialog: false
              }
            });
            return dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
                this.router.navigate(['/authenticated/hotelmgmt/guestdetails'], { relativeTo: this.activatedRoute });
              } else { return false; }
            });
        } else {
          this.router.navigate(['/authenticated/hotelmgmt/guestdetails'], { relativeTo: this.activatedRoute });
        }
      }
        window.scrollTo(0, 0);
        window.scroll(0, 0);
      //  this.router.navigate(['/authenticated/hotelmgmt/guestdetails'], { relativeTo: this.activatedRoute });
      },
      (error: JarvisError) => {
        this.hideSpinner();
        // console.error(`Error in Recheck. ${error.friendlyMessage}`);
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        // tslint:disable-next-line: max-line-length
        dialogRef.componentInstance.message = `${error.friendlyMessage}`;
        dialogRef.componentInstance.buttonText = 'OK';
      },
        () => this.hideSpinner());
    this.subscriptions.push(subscription);

  }

  // sortRoomByIsMapped() {
  //   let roomsArray = JSON.parse(JSON.stringify(this.roomsArray));
  //   for (let i = 0; i < roomsArray.length; i++) {
  //     roomsArray = (roomsArray.length > 0) ?
  //       this.sorterService.sort(roomsArray, 'isMapped', this.ascending) : [];
  //   }
  //   return roomsArray;
  // }

  getRoomBedsDetails(bedsObj) {
    let bedsDetail = '';
    for (let i = 0; i < bedsObj.length; i++) {
      bedsDetail += bedsObj[i].noOfBeds + ' ' + bedsObj[i].bedType + ', ';
    }
    return bedsDetail.replace(/,\s*$/, ''); // Removes last comma and trims space
  }

  getRoomGuestDetails(maxOccupancy, maxChildren) {
    let guestDetails = maxOccupancy ? 'Room sleeps ' + maxOccupancy + ' guests ' : '';
    guestDetails += maxChildren ? '(up to ' + maxChildren + ' children)' : '';
    return guestDetails.trim();
  }

  getRoomAmenitiesDetails(amenitiesObj) {
    const amenitiesDetail = [];
    for (let i = 0; i < amenitiesObj.length; i++) {
      const facilities = amenitiesObj[i].facilities;
      if (facilities.length > 0) {
        for (let j = 0; j < facilities.length; j++) {
          amenitiesDetail.push(facilities[j].name);
        }
      }
    }
    return amenitiesDetail;
  }

  getRoomPhotosDetails(photosObj) {
    const photoArr = [];
    // primeNG galleria accepts url link in source index
    for (let i = 0; i < photosObj.length; i++) {
      photoArr.push({
        source: photosObj[i].url,
        title: '',
        alt: ''
      });
    }
    return photoArr;
  }

  openRoomInfoDialog(roomCode) {
    this.showSpinner();
    const subscription = this.roomInfoDataService.getRoomInfoDetails({
      hotelCode: this.hotelDataService.selectedHotel.hotelId,
      roomCode: roomCode
    })
      .subscribe(response => {
        if (response.result) {
          const result = response.result;
          this.title = result.roomName;
          this.roomSize = result.size;
          this.description = result.roomDescription;
          this.smoking = result.isSmoking ? 'Allowed' : 'Non Smoking';
          this.imagelist = result.photos.length > 0 ? this.getRoomPhotosDetails(result.photos) : [];
          this.beds = result.beds.length > 0 ? this.getRoomBedsDetails(result.beds) : '';
          this.guest = (result.maxOccupancy || result.maxChildren) ? this.getRoomGuestDetails(result.maxOccupancy, result.maxChildren) : '';
          this.facilities = result.amenities.length > 0 ? this.getRoomAmenitiesDetails(result.amenities) : '';

          this.dialogsService
            .openRoomInfoModal(this.title, this.imagelist, this.roomSize, this.beds, this.guest, this.facilities,
              this.description, this.smoking)
            .subscribe(res => { },
              (error: JarvisError) => { this.hideSpinner(); console.error(`Error in getting room details. ${error.friendlyMessage}`); },
              () => this.hideSpinner());
        }
      },
        (error: JarvisError) => { this.hideSpinner(); console.error(`Error in getting room details. ${error.friendlyMessage}`); },
        () => this.hideSpinner());
  }

  getNightlyRate(roomCode, roomDetailIndex) {
    const nightlyRate = {
      currency: '',
      roomCount: 0,
      dates: [],
      rooms: [],
      totals: []
    };
    // Set default value
    this.nightlyRate = {};
    this.showNightlyRate = false;
    this.noOfRooms = 0;
    // Get filtered room array
    const roomCategoryInfoObj = this.getFilteredRoomForNightlyRate(roomCode);
    for (let j = 0; j < roomCategoryInfoObj.length; j++) {
      const roomDetailsObj = this.getObjByKey(roomCategoryInfoObj[j], 'roomDetails');
      const priceDetailsObj = this.getObjByKey(roomDetailsObj[roomDetailIndex], 'priceDetails');
      // Get room count
      if (nightlyRate.roomCount === 0 && priceDetailsObj.length !== 0) {
        nightlyRate.roomCount = priceDetailsObj.length;
      }
      for (let l = 0; l < priceDetailsObj.length; l++) {
        for (const pdKey in priceDetailsObj[l]) {
          if (priceDetailsObj[l].hasOwnProperty(pdKey)) {
            // Get currency code
            if (pdKey === 'currencyCode' && !nightlyRate.currency) {
              nightlyRate.currency = priceDetailsObj[l]['currencyCode'];
            }

            if (pdKey === 'nightlyRate') {
              const nightlyRateObj = priceDetailsObj[l][pdKey];
              for (let m = 0; m < nightlyRateObj.length; m++) {
                for (const nrKey in nightlyRateObj[m]) {
                  if (nightlyRateObj[m].hasOwnProperty(nrKey)) {
                    // Get dates
                    if (nrKey === 'date') {
                      if (!nightlyRate.dates.includes(nightlyRateObj[m]['date'])) {
                        nightlyRate.dates.push(nightlyRateObj[m]['date']);
                      }
                    }
                    // Get gross price
                    if (nrKey === 'grossPrice') {
                      if (!nightlyRate.rooms.includes(nightlyRate.rooms[m])) {
                        nightlyRate.rooms[m] = [];
                      }
                      nightlyRate.rooms[m].push(nightlyRateObj[m]['grossPrice']);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // Get total for each room(s) respectively
    nightlyRate.totals = nightlyRate.rooms.reduce((r, a) => a.map((b, index) => (r[index] || 0) + b), []);
    // Get no of rooms for making table heading
    this.noOfRooms = Array(nightlyRate.roomCount).fill(0).map((x, i) => i);
    this.nightlyRate = nightlyRate;
    this.showNightlyRate = true;
  }

  getFilteredRoomForNightlyRate(roomCode) {
    let rooms = JSON.parse(JSON.stringify(this.roomsArray));
    rooms = rooms.filter(r => roomCode === r.roomCode);
    return rooms;
  }

  getObjByKey(obj, key): any {
    let returnObj = {};
    for (const objKey in obj) {
      if (obj.hasOwnProperty(objKey)) {
        if (objKey === key) {
          returnObj = obj[key];
        }
      }
    }
    return returnObj;
  }

  displayExtraBedIcon(priceDetailsArray) {
    let isExtrabed = false;
    for (let i = 0; i < priceDetailsArray.length; i++) {
      if (priceDetailsArray[i].isExtrabed === true) {
        isExtrabed = true;
        break;
      }
    }
    return isExtrabed;
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  ngOnDestroy() {
    this.dealsDataService.navigatedFromUnbeatableDeals = false;
    this.dealsDataService.navigatedFromChainDeals = false;
    this.hideSpinner();
  }
}
