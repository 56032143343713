import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HotelFilterService {
  private hotelFilterChangeSource = new Subject<null>();
  hotelFilterChange$ = this.hotelFilterChangeSource.asObservable();

  private hotelFilterClearSource = new Subject<null>();
  hotelFilterClear$ = this.hotelFilterClearSource.asObservable();

  constructor() { }

  onHotelFilterChange($event) {
    this.hotelFilterChangeSource.next($event);
  }

  onHotelFilterClear() {
    this.hotelFilterClearSource.next();
  }
}
