import { Injectable } from '@angular/core';
import { CurrencyViewModel } from '../../viewmodels/currencyviewmodel';
import { Observable } from 'rxjs';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { ConfigurationService } from './configuration.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MasterLookupService {
  currencyDto: CurrencyViewModel[];
  _isTriggered: any;
  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }


  getCurrencies(languageId = 0): Observable<CurrencyViewModel[]> {
    return this.authenticatedHttpService.get
      (this.configurationService.config.baseUrls.backofficeApi
        , 'api/Currencies/Get?languageId=' + languageId).pipe(map((data) => {
          this.currencyDto = [];
          const filteredResult = data.result.filter(x => x.isActive === true && x.isDeleted === false);
          filteredResult.forEach(element => {
            const currency: CurrencyViewModel = { currencyId: element.id, currencyName: element.name, currencyCode: element.code };
            this.currencyDto.push(currency);
          });
          return this.currencyDto;
        }));
  }

  getAgencyCurreny(currencyId: number): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , currencyId, 'api/Miscellaneous/GetCurrencyById').pipe(map((data: any) => {
        let currencyCode;
        if (data != null) {
          currencyCode = data.result[0];
        }
        return currencyCode;
      }));
  }

  getAgencyCreditDetails(agencyId): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi
      , 'api/Agency/GetAgencyCreditLimitLowDetails?agencyId=' + agencyId).pipe(map((data: any) => {
        let creditLimitdata;
        if (data != null) {
          creditLimitdata = data.result;
        }
        return creditLimitdata;
      }));
  }

  getAccountManagerDeatils(): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi
    , 'api/Agency/GetAgencyAccountManagerDetails').pipe(map((data: any) => {
      let creditLimitdata;
      if (data != null) {
        creditLimitdata = data.result;
      }
      return creditLimitdata;
     }));
  }

  setLowcredit(value: boolean) {
    this.lowCreditAlert = value;
  }
  isLowCredit() {
    return this.lowCreditAlert;
  }

  set lowCreditAlert(value: any) {
    this._isTriggered = value;
  }

  get lowCreditAlert(): any {
    return this._isTriggered;
  }
}
