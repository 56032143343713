import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { AgentSearchViewModel } from '../../../../b2b/viewmodels/agency-mgmt/agent/agentsearchviewmodel';
import { Agentuserlistviewmodel } from '../../../../b2b/viewmodels/agency-mgmt/agent/agentuserlistviewmodel';
import { AgentInfoViewModel } from '../../../../b2b/viewmodels/agency-mgmt/agentinfoviewmodel';
import { map } from 'rxjs/operators';
import { Utilities } from 'src/app/common/utilities/utilities';
import { Observable } from 'rxjs';
import { RequestCreditNotification } from 'src/app/common/viewmodels/notification/requestcreditnotificationsviewmodel';

@Injectable({
  providedIn: 'root'
})
export class AgentUserDataService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }
  /* ---------- For Agent User -------------- */

  getAgentUserByEmailId(emailId: string): Observable<AgentInfoViewModel> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/GetAgentByEmailId?emailId=' + emailId).pipe(map(data => {
        if (data !== null) {
          let agentUserDto: AgentInfoViewModel;
          agentUserDto = new AgentInfoViewModel();
          agentUserDto.email = data.email;
          agentUserDto.id = data.id;
          agentUserDto.agency = { id: data.agencyId, name: data.agencyName, code: data.agencyCode, countryId: data.countryId };
          return agentUserDto;
        } else {
          return null;
        }
      }));
  }

  isAgentUserEmailIdAlreadyTaken(emailId: string, agecnyCode: string) {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/GetAgentByEmailId?emailId=' + emailId).pipe(map(data => {
        if (data !== null && !Utilities.isNullOrEmpty(agecnyCode) && data.agencyCode !== agecnyCode) {
          return true;
        } else {
          return false;
        }
      }));
  }

  getAgentUsers(): Observable<Agentuserlistviewmodel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/Get').pipe(map(data => {
        let agentUserListDto: Agentuserlistviewmodel[] = [];
        if (data !== null) {
          agentUserListDto = data;
        }
        return agentUserListDto;
      }));
  }

  createAgentUser(agentInfo: AgentInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.userMgmtApi
      , JSON.stringify(agentInfo), 'api/agent/Create/');
  }

  updateAgentUser(agentId: string, agentInfo: AgentInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.put(this.configurationService.config.baseUrls.userMgmtApi
      , JSON.stringify(agentInfo), 'api/Agent/Update/' + agentId);
  }

  deleteAgentUser(agentId: string): Observable<any> {
    return this.authenticatedHttpService.put(this.configurationService.config.baseUrls.userMgmtApi
      , JSON.stringify(agentId), 'api/Agent/Delete/');
  }

  getAgentUserById(agentId: string): Observable<AgentInfoViewModel> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/GetById?userId=' + agentId).pipe(map(data => {
        let agentDto: AgentInfoViewModel;
        agentDto = new AgentInfoViewModel();
        agentDto.id = data.id;
        agentDto.b2BRoleId = data.userApplicationRole[0].roleId;
        agentDto.roleName = data.userApplicationRole[0].roleName;
        agentDto.agency = { id: data.agencyId, name: data.agencyName, code: data.agencyCode, countryId: data.countryId };
        agentDto.branch = { id: data.branchId, name: data.branchName };
        agentDto.profilePictureUri = data.profilePictureUri;
        agentDto.firstName = data.firstName;
        agentDto.lastName = data.lastName;
        agentDto.userName = data.userName;
        agentDto.designationId = data.designationId;
        agentDto.designation = data.designation;
        agentDto.email = data.email;
        agentDto.contactNumber = data.contactNumber;
        agentDto.activationDate = data.activationDate;
        agentDto.updatedDate = data.updatedDate;
        agentDto.deActivationDate = data.deActivationDate;
        agentDto.inActivationType = data.inActivationType;
        agentDto.isActive = data.isActive;
        agentDto.createdBy = data.createdBy;
        agentDto.address1 = data.address1;
        agentDto.address2 = data.address2;
        agentDto.zipCode = data.zipCode;
        agentDto.countryId = data.countryId;
        agentDto.cityId = data.cityId;
        agentDto.updatedBy = data.updatedBy;
        agentDto.accessAgencyInvoice = data.accessAgencyInvoice;
        agentDto.accessAgencyVoucher = data.accessAgencyVoucher;
        agentDto.accessCustomerInvoice = data.accessCustomerInvoice;
        agentDto.accessCustomerVoucher = data.accessCustomerVoucher;
        agentDto.accessCustomerItinerary = data.accessCustomerItinerary;
        // // Image path fix
        // if (this.agentDto.profilePictureUri != null && this.agentDto.profilePictureUri.trim() !== '') {
        //   this.agentDto.profilePictureUri = this.portalService.appSettings.BaseUrls.ContentUrl +
        //                                         this.agentDto.profilePictureUri.replace('\\', '/');
        // }
        return agentDto;
      }));
  }

  isUserNameExistsForSelectedAgency(userName: string, agencyId: number, agentId: string): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/IsUserNameExistsForSelectedAgency?userName=' + userName + '&agencyId=' + agencyId + '&agentId=' + agentId);
  }

  isFirstNameLastNameExistsForSelectedAgencyAndBranch(firstName: string,
    lastName: string, agencyId: number, branchId: number, agentId: string): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/IsFirstNameLastNameExistsForSelectedAgencyAndBranch?firstName=' +
      firstName + '&lastName=' + lastName + '&agencyId=' + agencyId + '&branchId=' + branchId + '&agentId=' + agentId);
  }

  getAgents(search: AgentSearchViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.userMgmtApi
      , JSON.stringify(search), 'api/Agent/SearchAgent').pipe(map(data => {
        let agentUserListDto: any = [];
        if (data !== null) {
          agentUserListDto = data;
        }
        return agentUserListDto;
      }));
  }

  getAgentsByName(name: string, agencyId: number): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/GetByName?searchText=' + name + '&agencyId=' + agencyId).pipe(map(data => {
        let agentUserListDto: Agentuserlistviewmodel[] = [];
        if (data !== null) {
          agentUserListDto = data;
        }
        return agentUserListDto;
      }));
  }


  /* ---------- Agent User Ends -------------- */
}
