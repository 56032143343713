import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import { ScreenService } from '../services/screen.service';
import { AuthService } from '../../../common/shared/services/auth.service';
import { ConfigurationService } from '../../../common/shared/services/configuration.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent implements OnInit {
  currentYear = new Date();
  email = this.configurationService.config.emailConfig.statusBar;
  isShowCookiesMessage = true;
  isCookiesAccepted: string;

  constructor(private router: Router, public screenService: ScreenService,
    public authService: AuthService, private activatedRoute: ActivatedRoute,
    public configurationService: ConfigurationService) { }

  ngOnInit() {
    let cookieVal = localStorage.getItem('isCookiesAccepted');
    if (cookieVal === 'true') {
      this.isShowCookiesMessage = false;
    }
  }

  goToRegistrationPage() {
    this.router.navigate(['siteinfomgmt/registration']);
  }

  goToTermsandConditions() {
    // this.router.navigate([], { relativeTo: this.activatedRoute }).then(res => {
    //   window.open('siteinfomgmt/termsandconditions', '_blank');
    // });
    this.router.navigate([]).then(result => {  window.open('siteinfomgmt/termsandconditions/0', '_blank'); });
  }

  hideCookie() {
    this.isShowCookiesMessage = false;
    localStorage.setItem('isCookiesAccepted', 'true');
  }
}
