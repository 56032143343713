import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NationalityViewModel } from '../../../../viewmodels/group-booking/nationalityviewmodel';
import { HotelSearchPanelViewModel } from '../../viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';
import { SearchViewModel, DestinationSearchViewModel } from '../../viewmodels/hotel-search-panel/destinationsearchviewmodel';
import { DealsDataService } from '../../services/deals-data.service';
import { HotelDataService } from '../../services/hotel-data.service';
import { CurrencyApi } from '../../../../../framework/fw/currency/currency-api';
import { UserProfileService } from '../../../../../common/shared/services/user-profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorMessages } from '../../../../../common/errormessage';
import { HotelSearchService } from '../../services/hotel-search.service';
import { Location } from '@angular/common';
import { CONSTANTS } from 'src/app/common/constants';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';
import { Subscription } from 'rxjs';
import { HotelViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelviewmodel';
import { Utilities } from 'src/app/common/utilities/utilities';
import { GoogleAnalyticsViewModel, GASearchParametersViewModel } from 'src/app/common/viewmodels/googleanalyticsviewmodel';
import { GoogleAnalyticsService } from 'src/app/common/shared/services/google-analytics.service';
import { HotelBookService } from '../../services/hotel-book.service';
import { BreadCrumbService } from '../../services/bread-crumb.service';
import { PaymentDataService } from '../../services/payment-data.service';
import { JarvisError } from '../../../../../common/jarviserror';
import { AgencyProfileDataService } from '../../services/agency-profile-data.service';

@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.css']
})
export class HotelSearchComponent implements OnInit, OnDestroy {
  hotelSearchForm: FormGroup;
  invalidDate = (new Date()).setDate(new Date().getDate() - 1); // to disable past dates
  nationalityList: NationalityViewModel[];
  checkoutDate: any;
  totalNoOfRooms = 1;
  totalNoOfChildren = 0;
  totalNoOfAdults = 0;
  agencyCode: string;
  ageList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
  ];
  nightCount: number;
  minimumDate = new Date();
  cityArray: any[];
  hotelArray: any[];
  noDataAvailable: boolean;
  destinationSearch = new FormControl('', Validators.required);
  hotelSearchPanelArray: HotelSearchPanelViewModel = <HotelSearchPanelViewModel>{};
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  searchDetails: SearchViewModel = <SearchViewModel>{};
  searchData: DestinationSearchViewModel;
  checkinCheckOut = [];
  errorMessages = ErrorMessages;
  previousDestination: any;
  previousDestinationSearch: any;
  isShowCities: boolean;
  citychips: any;
  nationalityName: string;
  isMGPointApplicable: any;
  subscriptions: Subscription[] = [];
  maxDateValue: any;
  Math = Math;
  @ViewChild('datePicker') datePicker;

  constructor(public dealsDataService: DealsDataService,
    public hotelDataService: HotelDataService,
    public hotelSearchService: HotelSearchService,
    private currencyApi: CurrencyApi, private userProfileService: UserProfileService,
    private activatedRoute: ActivatedRoute, private router: Router, private changeDetector: ChangeDetectorRef,
    private location: Location,
    private topBarService: TopBarService, public googleAnalyticsService: GoogleAnalyticsService,
    // tslint:disable-next-line:max-line-length
    public hotelBookService: HotelBookService, public breadCrumbService: BreadCrumbService, public paymentDataService: PaymentDataService, private agencyProfileDataService: AgencyProfileDataService,
    private cdr: ChangeDetectorRef) {
    const subscriptionCurrencyChange = this.topBarService.currencyChange$.
      subscribe((curr) => this.updateSearchOnCurrencyChange(curr));
    this.subscriptions.push(subscriptionCurrencyChange);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.createFormModel();
    this.hotelSearchForm.get('checkInDate').markAsTouched();
    this.hotelSearchForm.get('checkOutDate').markAsTouched();
    const subscription =  this.paymentDataService.getPaymentTypeDetailsByAgencyId(
      this.currencyApi.selectedCurrency, this.userProfileService.getBasicUserInfo().agencyId).subscribe( data => {
        if ((data.isCreditBalance === false && (data.isCreditCard || data.isQRIS || data.isBankTransfer)) || data.isDisableCreditLimit) {
              this.hotelDataService.creditCardAgencyOnly = true;
          } else {
            this.hotelDataService.creditCardAgencyOnly = false;
          }
      },
      (error: JarvisError) => {
       // this.hideSpinner();
        console.error(`Error in Getting Agency data. ${error.friendlyMessage}`);
      },
       // this.hideSpinner()
      );
    this.subscriptions.push(subscription);

    this.getDestinationDeals();
    this.getAgencyCode();
    this.nationalityList = this.activatedRoute.snapshot.data['nationalityList'];
    this.getHotelSearchDetails();
    this.calcMaxDate();

    this.searchDetails.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    this.searchDetails.destinationName = null;
    this.citychips = this.hotelDataService.getCityData;
    if (this.citychips === null || this.citychips === undefined) {
      this.hotelDataService.getSearchedDestinations(this.searchDetails).subscribe((destinations) => {
        if (destinations !== null) {
          this.citychips = destinations.city;
          this.hotelDataService.setCityData(this.citychips);
        }
      });
    }
    this.calculateNightCount();
  }


  createFormModel() {
    this.hotelSearchForm = new FormGroup({
      destination: new FormControl('', Validators.required),
      guestNationalitycode: new FormControl('', Validators.required),
      checkInDate: new FormControl('', Validators.required),
      checkOutDate: new FormControl('', Validators.required),
      currencyCode: new FormControl(this.currencyApi.selectedCurrency, Validators.required),
      roomDetails: new FormArray([this.buildRoomDetailsForm()]),
    });
  }

  buildRoomDetailsForm(): FormGroup {
    let roomDetailsFormGroup: FormGroup;
    roomDetailsFormGroup = new FormGroup({
      roomNumber: new FormControl(1),
      noOfAdults: new FormControl(0),
      noOfChildren: new FormControl(0),
      childAges: new FormArray([])
    });

    return roomDetailsFormGroup;
  }

  buildChildrenAgeForm(): FormGroup {
    let childrenAgeFormGroup: FormGroup;
    childrenAgeFormGroup = new FormGroup({
      childAge: new FormControl('', Validators.required)
    });

    return childrenAgeFormGroup;
  }

  get roomDetails(): FormArray {
    return <FormArray>this.hotelSearchForm.get('roomDetails');
  }

  getChildrenAgeArray(roomIndex): FormArray {
    return <FormArray>this.roomDetails.controls[roomIndex].get('childAges');
  }

  getHotelSearchDetails() {
    if (Object.keys(this.hotelSearchService.destinationSearchDetails).length === 0
      && localStorage.getItem('hotelSearchPanelArray')
      && this.hotelSearchService.destinationSearchDetails !== JSON.parse(localStorage.getItem('hotelSearchPanelArray'))) {
      this.hotelSearchService.destinationSearchDetails = JSON.parse(localStorage.getItem('hotelSearchPanelArray'));
      this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0] =
        new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0]);
      this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1] =
        new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1]);
    }
    if (this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate !== undefined) {
      this.hotelSearchPanelArray = this.hotelSearchService.destinationSearchDetails;
      this.destinationSearch.setValue(this.hotelSearchPanelArray.destination.name);
      const roomDetails = this.hotelSearchPanelArray.roomDetails; 
    if (this.hotelSearchPanelArray) {
      this.hotelSearchForm.get('destination').setValue(this.hotelSearchPanelArray.destination);
      this.hotelSearchForm.get('guestNationalitycode').setValue(this.hotelSearchPanelArray.guestNationalitycode);
      this.nationalityList.forEach((nation) => {
        if (nation.code === this.hotelSearchPanelArray.guestNationalitycode) {
          this.nationalityName = nation.name;
        }
      });
      /* this.hotelSearchForm.get('checkInCheckOutDate').value[0] = new Date(this.hotelSearchPanelArray.checkInCheckOutDate[0]);
      this.hotelSearchForm.get('checkInCheckOutDate').value[1] = new Date(this.hotelSearchPanelArray.checkInCheckOutDate[1]); */
      this.hotelSearchForm.get('checkInDate').setValue(this.hotelSearchPanelArray.checkInCheckOutDate[0]);
      this.hotelSearchForm.get('checkOutDate').setValue(this.hotelSearchPanelArray.checkInCheckOutDate[1]);
      this.hotelSearchForm.get('currencyCode').setValue(this.hotelSearchPanelArray.currencyCode);
      // this.setCorrectDate();
      if (this.roomDetails.controls.length !== 0) {
        const roomDetailsLength = this.roomDetails.controls.length;
        for (let i = 0; i < roomDetailsLength; i++) {
          this.roomDetails.removeAt(0);
        }
      }

      for (let i = 0; i < this.hotelSearchPanelArray.roomDetails.length; i++) {
        this.roomDetails.push(this.buildRoomDetailsForm());
      }

      this.roomDetails.controls.forEach((control, index) => {
        control.get('roomNumber').setValue(roomDetails[index].roomNumber);
        control.get('noOfAdults').setValue(roomDetails[index].noOfAdults);
        control.get('noOfChildren').setValue(roomDetails[index].noOfChildren);

        if (this.getChildrenAgeArray(index).controls.length !== 0) {
          const childAgeArrayLength = this.getChildrenAgeArray(index).controls.length;
          for (let i = 0; i < childAgeArrayLength; i++) {
            this.getChildrenAgeArray(index).removeAt(0);
          }
        }

        for (let i = 0; i < roomDetails[index].childAges.length; i++) {
          this.getChildrenAgeArray(index).push(this.buildChildrenAgeForm());
        }

        this.getChildrenAgeArray(index).controls.forEach((control1, index1) => {
          if (roomDetails[index].childAges[index1]) {
            control1.get('childAge').setValue(roomDetails[index].childAges[index1].childAge);
          }
        });

      });
      this.calculateNightCount();
      this.getAdultAndChildrenCount();
    }
  }
  }

  getAdultAndChildrenCount() {
    const noOfRooms = this.roomDetails.controls.length;
    this.totalNoOfRooms = this.roomDetails.controls[noOfRooms - 1].get('roomNumber').value;
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
  }

  getDestinationDeals() {
    const agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    // 0 --> All Destinations.
    this.dealsDataService.getDestinationDeals(this.currencyApi.selectedCurrency,
      0, agencyId);
  }

  getAgencyCode() {
    this.agencyCode = this.userProfileService.getBasicUserInfo().agencyCode;
  }

  setNationalityName(event) {
    if (event.source.selected && event.value) {
      this.nationalityList.forEach((nation) => {
        if (nation.code === event.value) {
          localStorage.setItem('guestNationalityName', nation.name);
          this.hotelSearchService.guestNationalityName = nation.name;
          this.nationalityName = nation.name;
        }
      });
    }
  }

  getSeachArray() {
    if (this.searchData) {
      if (this.searchData.city && this.searchData.city.length > 0) {
        this.cityArray = this.searchData.city;
        this.cityArray.forEach(city => {
          city.highlightedName = this.highlight(city.name);
        });
      } else {
        this.cityArray = [];
      }
      if (this.searchData.hotel && this.searchData.hotel.length > 0) {
        this.hotelArray = this.searchData.hotel;
        this.hotelArray.forEach(hotel => {
          hotel.highlightedName = this.highlight(hotel.name);
        });
      } else {
        this.hotelArray = [];
      }
    }
  }

  highlight(text) {
    // to remove highlights from previously searched text
    text = text.replace(new RegExp('<span class="highlight">', 'g'), '').replace(new RegExp('<\/span>', 'g'), '');
    // highlight text
    const pattern = this.destinationSearch.value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');
    return this.destinationSearch.value ? text.replace(regex, match => '<span class="highlight">' + match + '</span>') : text;
  }

  selectDestination(event, destination) {
    if (event.type === 'click') {
      if (this.citychips !== null || this.citychips !== undefined) {
        this.hotelSearchForm.get('destination').setValue(destination);
        //  this.hotelSearchForm.get('destinationSearch').setValue(destination);
        this.destinationSearch.setValue(destination.name);
        this.searchData = <DestinationSearchViewModel>{};
        this.cityArray = [];
        this.hotelArray = [];
        this.isShowCities = false;
      }
    } else
      if (event.source.selected) {
        this.hotelSearchForm.get('destination').setValue(destination);
        this.searchData = <DestinationSearchViewModel>{};
        this.cityArray = [];
        this.hotelArray = [];
      }
  }

  onDestinationSearch(event, destination) {
    this.isShowCities = false;
    if (this.hotelSearchForm.get('destination').value && this.destinationSearch.value && event.key !== 'Enter') {
      this.hotelSearchForm.get('destination').setValue(null);
    }
    destination = destination.trim();
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
      if (destination && destination !== '' && destination.length > CONSTANTS.hotelSearch.searchParameterLength) {
        this.searchDetails.agencyId = this.agencyId;
        this.searchDetails.destinationName = destination;
        this.hotelDataService.getSearchedDestinations(this.searchDetails).subscribe((destinations) => {
          this.searchData = destinations;
          if (destinations) {
            if (destinations === null || (destinations.city.length === 0 && destinations.hotel.length === 0)) {
              this.noDataAvailable = true;
            } else {
              this.noDataAvailable = false;
            }
            this.getSeachArray();
          } else if (destinations === null) {
            this.noDataAvailable = true;
            this.searchData = <DestinationSearchViewModel>{};
            this.cityArray = [];
            this.hotelArray = [];
          }
        });
      } else {
        this.noDataAvailable = false;
        this.searchData = <DestinationSearchViewModel>{};
        this.cityArray = [];
        this.hotelArray = [];
      }
    }
  }

  calculateNightCount() {
    if (this.hotelSearchForm.get('checkInDate').value && this.hotelSearchForm.get('checkOutDate').value) {
      const dt1 = new Date(this.hotelSearchForm.get('checkInDate').value);
      const dt2 = new Date(this.hotelSearchForm.get('checkOutDate').value);
      this.nightCount = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
      if (this.nightCount > 30) {
        this.hotelSearchForm.get('checkOutDate').setErrors({ maxDateError: true });
      } else {
        this.hotelSearchForm.get('checkOutDate').setErrors(null);
      }
    }
  }

  increaseAdultCount(index) {
    // this.noOfAdults ++ ;
    this.totalNoOfAdults = 0;
    this.roomDetails.controls[index].get('noOfAdults').setValue(this.roomDetails.controls[index].get('noOfAdults').value + 1);
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }
  decreaseAdultCount(index) {
    this.totalNoOfAdults = 0;
    if (this.roomDetails.controls[index].get('noOfAdults').value > 0) {
      // this.noOfAdults -- ;
      this.roomDetails.controls[index].get('noOfAdults').setValue(this.roomDetails.controls[index].get('noOfAdults').value - 1);
    }
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }
  increaseChildCount(index) {
    this.totalNoOfChildren = 0;
    // this.noOfChildren ++;
    this.roomDetails.controls[index].get('noOfChildren').setValue(this.roomDetails.controls[index].get('noOfChildren').value + 1);
    this.getChildrenAgeArray(index).push(this.buildChildrenAgeForm());
    this.changeDetector.detectChanges();
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
  }

  decreaseChildCount(index) {
    this.totalNoOfChildren = 0;
    this.getChildrenAgeArray(index).removeAt(this.roomDetails.controls[index].get('noOfChildren').value - 1);
    if (this.roomDetails.controls[index].get('noOfChildren').value > 0) {
      this.roomDetails.controls[index].get('noOfChildren').setValue(this.roomDetails.controls[index].get('noOfChildren').value - 1);
    }
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
  }

  addRoom() {
    this.roomDetails.push(this.buildRoomDetailsForm());
    this.changeDetector.detectChanges();
    const noOfRooms = this.roomDetails.controls.length;
    this.roomDetails.controls.forEach((room, roomIndex) => {
      if (roomIndex === this.roomDetails.length - 1) {
        room.get('roomNumber').setValue(this.roomDetails.controls[noOfRooms - 2].get('roomNumber').value + 1);
        room.get('noOfAdults').setValue(2);
        room.get('noOfChildren').setValue(0);
        // room.get('childAge').setValue(null);
      }
    });
    this.totalNoOfRooms = this.roomDetails.controls[noOfRooms - 1].get('roomNumber').value;
    this.totalNoOfAdults = 0;
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }

  deleteRoom(index) {
    this.roomDetails.removeAt(index);
    this.changeDetector.detectChanges();
    this.roomDetails.controls.forEach((room, i) => {
      room.get('roomNumber').setValue(i + 1);
    });
    const noOfRooms = this.roomDetails.controls.length;
    this.totalNoOfRooms = this.roomDetails.controls[noOfRooms - 1].get('roomNumber').value;
    this.totalNoOfAdults = 0;
    this.totalNoOfChildren = 0;
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }

  onSearch() {
    Object.keys(this.hotelSearchForm.controls).forEach(key => {
      this.hotelSearchForm.get(key).markAsTouched();
    });
    if (!this.destinationSearch.hasError('required') && !this.hotelSearchForm.get('destination').value) {
      this.destinationSearch.setErrors({ selectError: true });
    }
    if (this.hotelSearchForm.valid) {

      // const updatedate = this.checkinCheckOut.length === 0 ? true :
      //   this.checkinCheckOut[0].getDate() !== this.hotelSearchForm.get('checkInCheckOutDate').value[0].getDate()
      //     && this.checkinCheckOut[1].getDate() !== this.hotelSearchForm.get('checkInCheckOutDate').value[1].getDate() ? true : false;

      // const updatedate = this.hotelSearchPanelArray.checkInCheckOutDate[0] !== this.hotelSearchForm.get('checkInDate').value
      //   && this.hotelSearchPanelArray.checkInCheckOutDate[1] !== this.hotelSearchForm.get('checkOutDate').value ? true : false;
      // if (updatedate) {
      //   this.setCorrectDate();
      // }

      this.hotelSearchPanelArray = this.hotelSearchForm.value;
      this.hotelSearchPanelArray.checkInCheckOutDate = [];
      this.hotelSearchPanelArray.checkInCheckOutDate.push(this.hotelSearchForm.get('checkInDate').value);
      this.hotelSearchPanelArray.checkInCheckOutDate.push(this.hotelSearchForm.get('checkOutDate').value);
      this.hotelSearchPanelArray.currencyCode = this.currencyApi.selectedCurrency;

      if (this.hotelDataService.creditCardAgencyOnly) {
        this.hotelSearchPanelArray.isCreditCardAgencyOnly = true;
      } else {
        this.hotelSearchPanelArray.isCreditCardAgencyOnly = false;
      }
      if (this.hotelSearchForm.valid) {
        const path = this.location.path();
        if (path) {
          localStorage.setItem('hotelSearchPanelArray', JSON.stringify(this.hotelSearchPanelArray));
          if (path.toLowerCase() === CONSTANTS.routeList.hotellist.toLowerCase()) {
            if (!Utilities.isNullOrEmpty(this.hotelSearchPanelArray.destination.type)
              && this.hotelSearchPanelArray.destination.type === 'Hotel') {
              this.hotelDataService.selectedHotel = <HotelViewModel>{};
              this.hotelDataService.selectedHotel.hotelId = this.hotelSearchPanelArray.destination.hotelCode;
              this.hotelDataService.selectedHotel.hotelName = this.hotelSearchPanelArray.destination.name;
              this.hotelSearchService.destinationSearchDetails = this.hotelSearchPanelArray;
              this.hotelSearchService.destinationSearchDetails.destination = this.hotelSearchForm.get('destination').value;
              // this.hotelBookService.bookFromUnbeatableDeals = false;
              localStorage.setItem('exchangeRate', ''+this.topBarService._exchangeRate);
              this.router.navigate(['/authenticated/hotelmgmt/hoteldetails'], { relativeTo: this.activatedRoute });
            } else {
              this.hotelSearchService.destinationSearchDetails = this.hotelSearchPanelArray;
              if (this.hotelSearchService.destinationSearchDetails.destination.type === 'City') {
                this.hotelSearchService.destinationSearchDetails.destination.hotelCode = null;
              }
              const destination = this.hotelSearchService.destinationSearchDetails.destination.name.split(',');
              this.hotelSearchService.cityName = destination[0];
              this.hotelSearchService.onHotelSearchChange(this.hotelSearchService.destinationSearchDetails);
            }
          } else if (!Utilities.isNullOrEmpty(this.hotelSearchPanelArray.destination.type)
            && this.hotelSearchPanelArray.destination.type === 'Hotel'
            && path.toLowerCase().includes(CONSTANTS.routeList.hotelDetails.toLowerCase())
          ) {
            this.hotelDataService.selectedHotel = <HotelViewModel>{};
            this.hotelDataService.selectedHotel.hotelId = this.hotelSearchPanelArray.destination.hotelCode;
            this.hotelDataService.selectedHotel.hotelName = this.hotelSearchPanelArray.destination.name;
            this.hotelSearchService.destinationSearchDetails = this.hotelSearchPanelArray;
            this.hotelSearchService.destinationSearchDetails.destination = this.hotelSearchForm.get('destination').value;
            // RS
            localStorage.setItem('selectedHotel', JSON.stringify(this.hotelDataService.selectedHotel));
            this.hotelSearchService.onHotelSearchChange(this.hotelSearchService.destinationSearchDetails);
          } else {
            // Route Explicitly to Hotel List always.
            this.hotelSearchService.destinationSearchDetails = this.hotelSearchPanelArray;
            this.hotelSearchService.destinationSearchDetails.destination = this.hotelSearchForm.get('destination').value;
            if (this.hotelSearchService.destinationSearchDetails.destination.type === 'City') {
              this.hotelSearchService.destinationSearchDetails.destination.hotelCode = null;
            }
            const destination = this.hotelSearchService.destinationSearchDetails.destination.name.split(',');
            this.hotelSearchService.cityName = destination[0];
            this.router.navigate(['/authenticated/hotelmgmt/hotellist'], { relativeTo: this.activatedRoute });
          }
        }
        this.breadCrumbService.HotelSearchUpdate(true);
        // ------------- Google Analytics ---------------------------------
        const googleAnalyticsViewModel: GoogleAnalyticsViewModel = {
          eventCategory: 'Search',
          eventAction: 'Panel',
          eventLabel: null,
          eventValue: null
        };
        let city;
        let hotel;
        if (this.hotelSearchPanelArray.destination.type === 'City') {
          const destinationSearch = this.destinationSearch.value.split(', ');
          city = destinationSearch[0];
          hotel = '-';
        } else if (this.hotelSearchPanelArray.destination.type === 'Hotel') {
          const destinationSearch = this.destinationSearch.value.split(', ');
          hotel = destinationSearch[0];
          city = destinationSearch[1];
        }
        let checkInDate = this.hotelSearchForm.get('checkInDate').value.getDate().toString();
        checkInDate =  ('0' + checkInDate).slice(-2);
        let checkInMonth = (this.hotelSearchForm.get('checkInDate').value.getMonth() + 1).toString();
        checkInMonth =  ('0' + checkInMonth).slice(-2);
        const checkInYear = this.hotelSearchForm.get('checkInDate').value.getFullYear().toString();

        const GACheckInDate = checkInYear + checkInMonth + checkInDate;

        // ------------- Search Parameters -----------------
        const googleAnalyticsSearchParameters: GASearchParametersViewModel = {
          city: city,
          hotel: hotel,
          totalNoOfRooms: this.totalNoOfRooms.toString(),
          nightCount: this.nightCount.toString(),
          checkInDate: GACheckInDate
        };
        this.googleAnalyticsService.customSearchEventEmitter(googleAnalyticsSearchParameters, googleAnalyticsViewModel);
        // ------------- Search Parameters Ends -----------------

        // ------------- Search Count -----------------
        // if (this.hotelSearchPanelArray.destination.type === 'Hotel') {
        const searchCount: GoogleAnalyticsViewModel = {
          eventCategory: 'Search Count',
          eventAction: 'Panel',
          eventLabel: null,
          eventValue: null
        };
        this.googleAnalyticsService.eventEmitter(searchCount);
        // }
        // ------------- Hotel Search Count -----------------

        // ------------- Hotel Browse -------------------------
        if (googleAnalyticsSearchParameters.hotel !== '-') {
          const googleAnalyticsHotelBrowse: GoogleAnalyticsViewModel = {
            eventCategory: 'Hotel',
            eventAction: 'Browse',
            eventLabel: null,
            eventValue: null
          };
          this.googleAnalyticsService.customHotelBookBrowseEventEmitter(googleAnalyticsSearchParameters.hotel, googleAnalyticsHotelBrowse);
        }
        // ------------- Hotel Browse Ends -------------------------

        // ------------- Google Analytics ---------------------------------
      }
    }

    const subscription = this.agencyProfileDataService.getById(this.agencyId).subscribe(data => {
      const agency = data[0];
      this.agencyProfileDataService.isMGPointApplicable = agency.mgAgency.isMGPointApplicable;
      this.isMGPointApplicable = this.agencyProfileDataService.isMGPointApplicable;
    }, );
    this.subscriptions.push(subscription);
    this.agencyProfileDataService.triggerUpdate();
  }

  hasErrors(controlName: string) {
    if (this.hotelSearchForm.get(controlName)) {
      return (this.hotelSearchForm.get(controlName).dirty || this.hotelSearchForm.get(controlName).touched)
        && this.hotelSearchForm.get(controlName).errors !== null;
    }
  }

  getValidationMessage(controlName: string) {
    if (this.hotelSearchForm.get(controlName).hasError('required')) {
      if (controlName === 'guestNationalitycode') {
        return ErrorMessages.requiredNationalityMessage;
      } else if (controlName === 'checkInDate' || controlName === 'checkOutDate') {
        return ErrorMessages.requiredCheckinCheckoutDatesMessage;
      } else {
        return ErrorMessages.requiredFieldMessage;
      }
    } else if (this.hotelSearchForm.get(controlName).hasError('maxDateError')) {
      if (controlName === 'checkOutDate') {
        return ErrorMessages.invalidDateRangeMessage;
      }
    } else {
      return '';
    }
  }

  updateSearchOnCurrencyChange(selectedCurrency: string) {
    this.hotelSearchPanelArray.currencyCode = selectedCurrency;
    this.hotelSearchService.destinationSearchDetails = this.hotelSearchPanelArray;
    localStorage.setItem('hotelSearchPanelArray', JSON.stringify(this.hotelSearchPanelArray));
    this.hotelSearchService.onHotelSearchChange(this.hotelSearchService.destinationSearchDetails);
  }

  calcMaxDate() {
    const today = new Date();
    this.maxDateValue = new Date();
    this.maxDateValue.setFullYear(today.getFullYear() + 2);
  }

  showCities(destination) {
    if (this.citychips !== undefined && (destination === '' || destination === null)) {
      this.isShowCities = true;
      this.noDataAvailable = false;
    }

  }

  setBlankForDestination() {
    this.previousDestination = this.hotelSearchForm.get('destination').value;
    this.previousDestinationSearch = this.destinationSearch.value;
    if (this.destinationSearch.value !== null) {
      this.destinationSearch.setValue(null);
      this.hotelSearchForm.get('destination').setValue(null);
    }
  }

  setPreviosDestination() {
    if (this.destinationSearch.value === null && this.hotelSearchForm.get('destination').value === null) {
      this.destinationSearch.setValue(this.previousDestinationSearch);
      this.hotelSearchForm.get('destination').setValue(this.previousDestination);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
