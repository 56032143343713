import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-markup-conflicts-list-popup',
  templateUrl: './markup-conflicts-list-popup.component.html',
  styleUrls: ['./markup-conflicts-list-popup.component.css']
})
export class MarkupConflictsListPopupComponent implements OnInit {
  title: any;
  conflictsList: any[];
  constructor(public dialogRef: MatDialogRef<MarkupConflictsListPopupComponent>) { }

  ngOnInit() {
  }

}
