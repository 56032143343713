import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { JarvisError } from '../../jarviserror';
import { Router } from '@angular/router';
import { CallcenterService } from 'src/app/b2b/common/b2b-shared/services/callcenter.service';
import { BoAuth } from 'src/app/b2b/viewmodels/callcenter/boauthviewmodel';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedHttpService {
  constructor(private authService: AuthService,
    private _http: HttpClient, private router: Router
  ) { }

  get(url, relativeuri = ''): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.authService.getAccessToken()}`)
    .set('relative-uri', relativeuri)
    .set('Cache-Control', 'no-store')
    .set('Pragma', 'no-cache')
    .set('Expires', '0')
    .set('agentid', BoAuth.agentId === undefined ? '' : BoAuth.agentId)
    .set('agencycode', BoAuth.agencyCode === undefined ? '' : BoAuth.agencyCode)
    .set('branchid', BoAuth.branchId === undefined ? '' : BoAuth.branchId);
    return this._http.get(url, { headers: headers })
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  post(url, data, relativeuri = '') {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getAccessToken())
      .set('Content-Type', 'application/json')
      .set('relative-uri', relativeuri)
      .set('agentid', BoAuth.agentId === undefined ? '' : BoAuth.agentId)
      .set('agencycode', BoAuth.agencyCode === undefined ? '' : BoAuth.agencyCode)
      .set('branchid', BoAuth.branchId === undefined ? '' : BoAuth.branchId)
      .set('requestKey', data.requestKey === undefined ? '' : data.requestKey);
    return this._http.post(url, data, { headers: headers })
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  postBlobResponse(url, data, relativeuri = '') {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getAccessToken())
      .set('Content-Type', 'application/json')
      .set('relative-uri', relativeuri)
      .set('agentid', BoAuth.agentId === undefined ? '' : BoAuth.agentId)
      .set('agencycode', BoAuth.agencyCode === undefined ? '' : BoAuth.agencyCode)
      .set('branchid', BoAuth.branchId === undefined ? '' : BoAuth.branchId);
      return this._http.post(url, data, { headers: headers , responseType: 'blob'})
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  put(url, data, relativeuri = '') {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getAccessToken())
      .set('Content-Type', 'application/json')
      .set('relative-uri', relativeuri)
      .set('agentid', BoAuth.agentId === undefined ? '' : BoAuth.agentId)
      .set('agencycode', BoAuth.agencyCode === undefined ? '' : BoAuth.agencyCode)
      .set('branchid', BoAuth.branchId === undefined ? '' : BoAuth.branchId);
    return this._http.put(url, data, { headers: headers })
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  private handleError(error: HttpErrorResponse) {
    // this.globalErrorHandler.handleError(error);
    // Create a custom error and send across? to UI?
    const dataError = new JarvisError();
    dataError.errorNumber = error.status;
    dataError.message = error.message;
    if (error.status === 500) {
      // tslint:disable-next-line: max-line-length
      dataError.friendlyMessage = 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.';
    } else if (error.status === 400) {
      dataError.friendlyMessage = 'Sorry, there are no results for your search. You might want to edit the fields and try again.';
    } else if (error.status === 503) {
      // tslint:disable-next-line: max-line-length
      dataError.friendlyMessage = 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.';
    } else {
      // tslint:disable-next-line: max-line-length
      dataError.friendlyMessage = 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.';
    }
    if (error.status === 401) {
      this.authService.logout();
      dataError.friendlyMessage = 'Could not refresh Auth Token . Logging Off';
      this.router.navigate(['/home']);
      // Should we navigate to home page ?? and not throw error?
    } else {
    return throwError(dataError);
    }
  }
}
