import { Component, OnInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { ScreenService } from '../services/screen.service';
import { AuthService } from 'src/app/common/shared/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { CONSTANTS } from 'src/app/common/constants';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { HotelDataService } from 'src/app/b2b/common/b2b-shared/services/hotel-data.service';
import { CallcenterService } from 'src/app/b2b/common/b2b-shared/services/callcenter.service';
import { FrameworkConfigService } from '../services/framework-config.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.css']
})
export class TitleBarComponent {
  currentRoute: string;
  showBanner: boolean;
  safeStayPoliciesRoute = CONSTANTS.routeList.safeStayPolicies;
  safeStayInfoRoute = CONSTANTS.routeList.safeStayInfo;

  constructor(public menuService: MenuService,
    public screenService: ScreenService,
    public authService: AuthService,
    private router: Router, private location: Location,
    public frameworkConfigService: FrameworkConfigService) {

    router.events.subscribe((val) => {
      if (location.path()) {
        this.currentRoute = location.path();
        if (this.currentRoute === CONSTANTS.routeList.home
          || this.currentRoute === CONSTANTS.routeList.hotelInfo
          || (!authService.isLoggedIn() && this.currentRoute !== this.safeStayPoliciesRoute
            && this.currentRoute !== this.safeStayInfoRoute)) {
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
      }
    });
  }

  goToTab() {
    if (this.frameworkConfigService.ShowMenu) {
      if (this.authService.isLoggedIn()) {
        this.router.navigate(['authenticated/hotelmgmt/hotelinfo']);
      } else {
        this.router.navigate(['/home']);
      }
    } else {
        this.router.navigate(['/']);
    }
  }
}
