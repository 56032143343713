import { CONSTANTS } from 'src/app/common/constants';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { DealsDataService } from '../../common/b2b-shared/services/deals-data.service';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { NationalityViewModel } from '../../viewmodels/nationalityviewmodel';
import {
  DestinationSearchViewModel,
  SearchViewModel
} from '../../common/b2b-shared/viewmodels/hotel-search-panel/destinationsearchviewmodel';
import {
  HotelSearchPanelViewModel,
  DestinationViewModel,
  RoomDetailsViewModel,
  ChildrenAgeViewModel
} from '../../common/b2b-shared/viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMessages } from './../../../common/errormessage';
import { HotelSearchService } from '../../common/b2b-shared/services/hotel-search.service';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { AuthService } from 'src/app/common/shared/services/auth.service';
import { AgencyProfileDataService } from '../../common/b2b-shared/services/agency-profile-data.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { DatePickerDateAdapterService } from '../../common/b2b-shared/services/date-picker-date-adapter.service';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { JarvisError } from 'src/app/common/jarviserror';
import { HotelViewModel } from '../../viewmodels/hotel-mgmt/hotelviewmodel';
import { GoogleAnalyticsService } from 'src/app/common/shared/services/google-analytics.service';
import { GoogleAnalyticsViewModel, GASearchParametersViewModel } from 'src/app/common/viewmodels/googleanalyticsviewmodel';
import { HotelBookService } from '../../common/b2b-shared/services/hotel-book.service';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
import { PaymentTypeDetailsViewModel } from '../../viewmodels/payment/paymenttypedetailsviewmodel';
import { PaymentDataService } from '../../common/b2b-shared/services/payment-data.service';
import { PushNotificationService } from '../../../common/shared/services/pushnotification.service';
import { SubscriptionViewModel } from '../../viewmodels/push-notification/subscriptionViewModel';
import { ScreenService } from '../../../framework/fw/services/screen.service';
import { SwiperOptions } from 'swiper';
import { TopBarService } from '../../../framework/fw/services/top-bar.service';

@Component({
  selector: 'app-hotel-info',
  templateUrl: './hotel-info.component.html',
  styleUrls: ['./hotel-info.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: DatePickerDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: CONSTANTS.DATE_FORMATS }
  ],
})
export class HotelInfoComponent implements OnInit, OnDestroy {

  hotelSearchForm: FormGroup;
  invalidDate = (new Date()).setDate(new Date().getDate() - 1); // to disable past dates
  nationalityList: NationalityViewModel[];
  agencyPaymentTypeDetails: PaymentTypeDetailsViewModel = <PaymentTypeDetailsViewModel>{};
  totalNoOfRooms = 1;
  totalNoOfChildren = 0;
  totalNoOfAdults = 2;
  agencyCode: string;
  ageList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
  ];
  nightCount: number;
  minimumDate = new Date();
  cityArray: any[];
  hotelArray: any[];
  noDataAvailable: boolean;
  destinationSearch = new FormControl('', Validators.required);
  hotelSearchPanelArray: HotelSearchPanelViewModel = <HotelSearchPanelViewModel>{};
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  searchDetails: SearchViewModel = <SearchViewModel>{};
  errorMessages = ErrorMessages;
  applicationId: any;
  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  searchData: DestinationSearchViewModel;
  isCredit = this.userProfileService.getBasicUserInfo().isCredit;
  iscashTopup: boolean;
  maxDateValue: any;
  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;
  checkinDate: Date;
  checkoutDate: Date;
  todaysDate: NgbDate;
  Math = Math;
  screenWidth: number;
  defaultNationalityCode: string;
  isShowCities: boolean;
  previousDestination: any;
  previousDestinationSearch: any;

  ContentUrl = this.configurationService.config.baseUrls.contentUrl;
  citychips: any;
  showPanel: boolean;
  B2BHotelsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.B2BHotels);
  ViewRatesAndPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewRatesAndProperties);
  ViewBookingsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewBookings);
  ViewDealsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewDeals);
  UnbeatableDealsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.UnbeatableDeals);
  ChainViewDealsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ChainViewDeals);
  ChainDealsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ChainDeals);
  // For Swipe Mobile
  isMediumConfig: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 2,
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 10,
  };
  isSmallConfig: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 10,
  };

  constructor(public dealsDataService: DealsDataService,
    public hotelDataService: HotelDataService, public authService: AuthService,
    public hotelSearchService: HotelSearchService, private agencyProfileDataService: AgencyProfileDataService,
    public currencyApi: CurrencyApi, private userProfileService: UserProfileService,
    public configurationService: ConfigurationService,
    private dialogsService: DialogsService,
    private activatedRoute: ActivatedRoute, private router: Router, private changeDetector: ChangeDetectorRef,
    private ngbCalendar: NgbCalendar, public googleAnalyticsService: GoogleAnalyticsService,
    public frameworkConfigService: FrameworkConfigService, public hotelBookService: HotelBookService,
    public paymentDataService: PaymentDataService, private topBarService: TopBarService,
    public notificationService: PushNotificationService, public screenService: ScreenService) {
    this.todaysDate = ngbCalendar.getToday();
  }

  ngOnInit() {
    /* window.localStorage.clear(); */
    this.setClientIPAddress();
    if (!this.frameworkConfigService.IsCallCenterUser) {
      this.notificationService.getSubscription(CONSTANTS.application.b2b);
    }
    this.nationalityList = this.activatedRoute.snapshot.data['nationalityList'];
    this.agencyPaymentTypeDetails = this.activatedRoute.snapshot.data['paymentTypeDetails'];
    const subscription = this.paymentDataService.getPaymentTypeDetailsByAgencyId(
      this.currencyApi.selectedCurrency, this.userProfileService.getBasicUserInfo().agencyId).subscribe(data => {
        if ((data.isCreditBalance === false && (data.isCreditCard || data.isQRIS || data.isBankTransfer)) || data.isDisableCreditLimit) {
          this.hotelDataService.creditCardAgencyOnly = true;
        } else {
          this.hotelDataService.creditCardAgencyOnly = false;
        }
      },
        (error: JarvisError) => {
          this.hideSpinner();
          console.error(`Error in Getting Agency data. ${error.friendlyMessage}`);
        },
        () => this.hideSpinner());
    this.subscriptions.push(subscription);
    this.nationalityList.forEach(nationality => {
      if (nationality.id === this.userProfileService.basicUserInfo.agencyNationalityId) {
        this.defaultNationalityCode = nationality.code;
        localStorage.setItem('guestNationalityName', nationality.name);
        this.hotelSearchService.guestNationalityName = nationality.name;
      }
    });
    this.createFormModel();
    this.getDestinationDeals();
    this.getAgencyCode();
    this.getApplicationId();
    this.getAgencyData(this.agencyId);
    this.calcMaxDate();
    this.screenWidth = window.innerWidth;
    this.searchDetails.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    this.searchDetails.destinationName = null;
    this.citychips = this.hotelDataService.getCityData;
    if (this.citychips === null || this.citychips === undefined) {
      this.hotelDataService.getSearchedDestinations(this.searchDetails).subscribe((destinations) => {
        if (destinations !== null) {
          this.citychips = destinations.city;
          this.hotelDataService.setCityData(this.citychips);
        }
      });
    }
    if (this.hotelSearchService.destinationSearchDetails.roomDetails) {
      this.destinationSearch.setValue(this.hotelSearchService.destinationSearchDetails.destination.name);
      const roomDetails = this.hotelSearchService.destinationSearchDetails.roomDetails;
      this.hotelSearchForm.get('destination').setValue(this.hotelSearchService.destinationSearchDetails.destination);
      this.hotelSearchForm.get('guestNationalitycode').setValue(this.hotelSearchService.destinationSearchDetails.guestNationalitycode);
      const cDate = this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0].toISOString();
      new Date(cDate).setHours(0, 0, 0, 0);
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      if (cDate < today.toISOString()) {
        this.hotelSearchForm.get('checkInDate').setValue(new Date((new Date()).setDate(new Date().getDate() + 1)));
        this.hotelSearchForm.get('checkOutDate').setValue(new Date((new Date()).setDate(new Date().getDate() + 2)));
      } else {
        this.hotelSearchForm.get('checkInDate').setValue(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0]);
        this.hotelSearchForm.get('checkOutDate').setValue(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1]);
      }
      this.hotelSearchForm.get('currencyCode').setValue(this.hotelSearchService.destinationSearchDetails.currencyCode);

      if (this.roomDetails.controls.length !== 0) {
        const roomDetailsLength = this.roomDetails.controls.length;
        for (let i = 0; i < roomDetailsLength; i++) {
          this.roomDetails.removeAt(0);
        }
      }

      for (let i = 0; i < this.hotelSearchService.destinationSearchDetails.roomDetails.length; i++) {
        this.roomDetails.push(this.buildRoomDetailsForm());
      }

      this.roomDetails.controls.forEach((control, index) => {
        control.get('roomNumber').setValue(roomDetails[index].roomNumber);
        control.get('noOfAdults').setValue(roomDetails[index].noOfAdults);
        control.get('noOfChildren').setValue(roomDetails[index].noOfChildren);

        if (this.getChildrenAgeArray(index).controls.length !== 0) {
          const childAgeArrayLength = this.getChildrenAgeArray(index).controls.length;
          for (let i = 0; i < childAgeArrayLength; i++) {
            this.getChildrenAgeArray(index).removeAt(0);
          }
        }

        for (let i = 0; i < roomDetails[index].childAges.length; i++) {
          this.getChildrenAgeArray(index).push(this.buildChildrenAgeForm());
        }

        this.getChildrenAgeArray(index).controls.forEach((control1, index1) => {
          if (roomDetails[index].childAges[index1]) {
            control1.get('childAge').setValue(roomDetails[index].childAges[index1].childAge);
          }
        });

      });
      this.getAdultAndChildrenCount();
    }
    this.calculateNightCount();
    this.topBarService.getCurrencyExchangeRateForMGF(this.currencyApi.selectedCurrency);
  }

  disableNotificationSection() {
    let element = document.querySelector('#pushNotificationDiv');
    if (element) {
      element.remove();
    }
    element = document.querySelector('.topnav-wrapper');
    if (element) {
      element.setAttribute("style", "top:0px;");
    }
  }

  turnOnNotifications() {
    this.notificationService.getSubscription(CONSTANTS.application.b2b);
    this.disableNotificationSection();
  }

  createFormModel() {
    this.hotelSearchForm = new FormGroup({
      destination: new FormControl('', Validators.required),
      guestNationalitycode: new FormControl(this.defaultNationalityCode, Validators.required),
      checkInDate: new FormControl(new Date((new Date()).setDate(new Date().getDate() + 1)), Validators.required),
      checkOutDate: new FormControl(new Date((new Date()).setDate(new Date().getDate() + 2)), Validators.required),
      currencyCode: new FormControl(this.currencyApi.selectedCurrency),
      roomDetails: new FormArray([this.buildRoomDetailsForm()]),
    });
  }

  buildRoomDetailsForm(): FormGroup {
    let roomDetailsFormGroup: FormGroup;
    roomDetailsFormGroup = new FormGroup({
      roomNumber: new FormControl(1),
      noOfAdults: new FormControl(2),
      noOfChildren: new FormControl(0),
      childAges: new FormArray([])
    });

    return roomDetailsFormGroup;
  }

  buildChildrenAgeForm(): FormGroup {
    let childrenAgeFormGroup: FormGroup;
    childrenAgeFormGroup = new FormGroup({
      childAge: new FormControl('', Validators.required)
    });

    return childrenAgeFormGroup;
  }

  getAdultAndChildrenCount() {
    const noOfRooms = this.roomDetails.controls.length;
    this.totalNoOfAdults = 0;
    this.totalNoOfRooms = this.roomDetails.controls[noOfRooms - 1].get('roomNumber').value;
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
  }

  getDestinationDeals() {
    if (this.currencyApi.selectedCurrency) {
      const agencyId = this.userProfileService.getBasicUserInfo().agencyId;
      // 0 --> All Destinations.
      this.dealsDataService.getDestinationDeals(this.currencyApi.selectedCurrency,
        0, agencyId);
      // 0 --> All Chains.
      this.dealsDataService.getChainDeals(this.currencyApi.selectedCurrency,
        0, agencyId);
    } else {
      const agencyId = this.userProfileService.getBasicUserInfo().agencyId;
      // 0 --> All Destinations.
      this.dealsDataService.getDestinationDeals('IDR',
        0, agencyId);
      // 0 --> All Chains.
      this.dealsDataService.getChainDeals('IDR',
        0, agencyId);
    }
  }

  getAgencyCode() {
    this.agencyCode = this.userProfileService.getBasicUserInfo().agencyCode;
  }

  getAgencyData(agencyId: number) {
    this.showSpinner();
    const subscription = this.agencyProfileDataService.getById(agencyId).subscribe(data => {
      const agency = data[0];
      this.agencyProfileDataService.isMGPointApplicable = agency.mgAgency.isMGPointApplicable;
      if (agency.payment.isCashTopUp) {
        this.iscashTopup = true;
      }
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Getting Agency data. ${error.friendlyMessage}`);
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }


  setNationalityName(event) {
    if (event.source.selected && event.value) {
      this.nationalityList.forEach((nationality) => {
        if (nationality.code === event.value) {
          localStorage.setItem('guestNationalityName', nationality.name);
          this.hotelSearchService.guestNationalityName = nationality.name;
        }
      });
    }
  }

  getApplicationId() {
    this.applicationId = this.userProfileService.getApplicationID().id || undefined;
  }

  getSeachArray() {
    if (this.searchData) {
      if (this.searchData.city && this.searchData.city.length > 0) {
        this.cityArray = this.searchData.city;
        this.cityArray.forEach(city => {
          city.highlightedName = this.highlight(city.name);
        });
      } else {
        this.cityArray = [];
      }
      if (this.searchData.hotel && this.searchData.hotel.length > 0) {
        this.hotelArray = this.searchData.hotel;
        this.hotelArray.forEach(hotel => {
          hotel.highlightedName = this.highlight(hotel.name);
        });
      } else {
        this.hotelArray = [];
      }
    }
  }

  setClientIPAddress() {
    const subscriptionClientIPAddress = this.hotelDataService.getIPAddress().subscribe(data => {
      localStorage.setItem('clientIPAddress', data.ip);
    },
      (error: JarvisError) => {
        console.error(`Error in Getting Client IPAddress. ${error.friendlyMessage}`);
      });
    this.subscriptions.push(subscriptionClientIPAddress);
  }

  selectDestination(event, destination) {
    if (event.type === 'click') {
      if (this.citychips !== null || this.citychips !== undefined) {
        this.hotelSearchForm.get('destination').setValue(destination);
        //  this.hotelSearchForm.get('destinationSearch').setValue(destination);
        this.destinationSearch.setValue(destination.name);
        this.searchData = <DestinationSearchViewModel>{};
        this.cityArray = [];
        this.hotelArray = [];
        this.isShowCities = false;
        this.showPanel = false;
        this.noDataAvailable = false;
      }
    } else
      if (event.source.selected) {
        this.destinationSearch.setValue(destination.name);
        this.hotelSearchForm.get('destination').setValue(destination);
        this.searchData = <DestinationSearchViewModel>{};
        this.cityArray = [];
        this.hotelArray = [];
        this.isShowCities = false;
        this.showPanel = true;
      }
  }

  onDestinationSearch(event, destination) {
    this.isShowCities = false;
    this.showPanel = true;
    if (this.hotelSearchForm.get('destination').value && this.destinationSearch.value && event.key !== 'Enter') {
      this.hotelSearchForm.get('destination').setValue(null);
    }
    destination = destination.trim();
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
      if (destination && destination !== '' && destination.length > CONSTANTS.hotelSearch.searchParameterLength) {
        this.searchDetails.agencyId = this.agencyId;
        this.searchDetails.destinationName = destination;
        this.hotelDataService.getSearchedDestinations(this.searchDetails).subscribe((destinations) => {
          this.searchData = destinations;
          if (destinations) {
            if (destinations === null || (destinations.city.length === 0 && destinations.hotel.length === 0)) {
              this.noDataAvailable = true;
            } else {
              this.noDataAvailable = false;
            }
            this.getSeachArray();
          } else if (destinations === null || (destinations.city.length === 0 && destinations.hotel.length === 0)) {
            this.noDataAvailable = true;
            this.searchData = <DestinationSearchViewModel>{};
            this.cityArray = [];
            this.hotelArray = [];
          }
        });
      } else {
        this.searchData = <DestinationSearchViewModel>{};
        this.noDataAvailable = false;
        this.cityArray = [];
        this.hotelArray = [];
      }
    }
  }

  highlight(text) {
    // to remove highlights from previously searched text
    text = text.replace(new RegExp('<span class="highlight">', 'g'), '').replace(new RegExp('<\/span>', 'g'), '');
    // highlight text
    const pattern = this.destinationSearch.value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');
    return this.destinationSearch.value ? text.replace(regex, match => '<span class="highlight">' + match + '</span>') : text;
  }

  calculateNightCount() {
    if (this.hotelSearchForm.get('checkInDate').value && this.hotelSearchForm.get('checkOutDate').value) {
      const dt1 = new Date(this.hotelSearchForm.get('checkInDate').value);
      const dt2 = new Date(this.hotelSearchForm.get('checkOutDate').value);
      this.nightCount = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
      if (this.nightCount > 30) {
        this.hotelSearchForm.get('checkOutDate').setErrors({ maxDateError: true });
      } else {
        this.hotelSearchForm.get('checkOutDate').setErrors(null);
      }
    }
  }

  get roomDetails(): FormArray {
    return <FormArray>this.hotelSearchForm.get('roomDetails');
  }

  getChildrenAgeArray(roomIndex): FormArray {
    return <FormArray>this.roomDetails.controls[roomIndex].get('childAges');
  }

  increaseAdultCount(index) {
    this.totalNoOfAdults = 0;
    this.roomDetails.controls[index].get('noOfAdults').setValue(this.roomDetails.controls[index].get('noOfAdults').value + 1);
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }
  decreaseAdultCount(index) {
    this.totalNoOfAdults = 0;
    if (this.roomDetails.controls[index].get('noOfAdults').value > 0) {
      this.roomDetails.controls[index].get('noOfAdults').setValue(this.roomDetails.controls[index].get('noOfAdults').value - 1);
    }
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }
  increaseChildCount(index) {
    this.totalNoOfChildren = 0;
    this.roomDetails.controls[index].get('noOfChildren').setValue(this.roomDetails.controls[index].get('noOfChildren').value + 1);
    this.getChildrenAgeArray(index).push(this.buildChildrenAgeForm());
    this.changeDetector.detectChanges();
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
  }

  decreaseChildCount(index) {
    this.totalNoOfChildren = 0;
    this.getChildrenAgeArray(index).removeAt(this.roomDetails.controls[index].get('noOfChildren').value - 1);
    if (this.roomDetails.controls[index].get('noOfChildren').value > 0) {
      this.roomDetails.controls[index].get('noOfChildren').setValue(this.roomDetails.controls[index].get('noOfChildren').value - 1);
    }
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
  }

  addRoom() {
    this.roomDetails.push(this.buildRoomDetailsForm());
    this.changeDetector.detectChanges();
    const noOfRooms = this.roomDetails.controls.length;
    this.roomDetails.controls.forEach((room, roomIndex) => {
      if (roomIndex === this.roomDetails.length - 1) {
        room.get('roomNumber').setValue(this.roomDetails.controls[noOfRooms - 2].get('roomNumber').value + 1);
        room.get('noOfAdults').setValue(2);
        room.get('noOfChildren').setValue(0);
      }
    });
    this.totalNoOfRooms = this.roomDetails.controls[noOfRooms - 1].get('roomNumber').value;
    this.totalNoOfAdults = 0;
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }

  deleteRoom(index) {
    this.roomDetails.removeAt(index);
    this.changeDetector.detectChanges();
    this.roomDetails.controls.forEach((room, i) => {
      room.get('roomNumber').setValue(i + 1);
    });
    const noOfRooms = this.roomDetails.controls.length;
    this.totalNoOfRooms = this.roomDetails.controls[noOfRooms - 1].get('roomNumber').value;
    this.totalNoOfAdults = 0;
    this.totalNoOfChildren = 0;
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfChildren = this.totalNoOfChildren + room.get('noOfChildren').value;
    });
    this.roomDetails.controls.forEach((room) => {
      this.totalNoOfAdults = this.totalNoOfAdults + room.get('noOfAdults').value;
    });
  }

  onSearch() {
    this.nationalityList.forEach((nationality) => {
      if (nationality.code === this.hotelSearchForm.get('guestNationalitycode').value) {
        localStorage.setItem('guestNationalityName', nationality.name);
        // this.hotelSearchService.guestNationalityName = nationality.name;
      }
    });
    Object.keys(this.hotelSearchForm.controls).forEach(key => {
      this.hotelSearchForm.get(key).markAsTouched();
    });
    Object.keys(this.roomDetails.controls).forEach(key => {
      this.roomDetails.get(key).markAsTouched();
    });
    if (!this.destinationSearch.hasError('required') && !this.hotelSearchForm.get('destination').value) {
      this.destinationSearch.setErrors({ selectError: true });
    }
    if (this.hotelSearchForm.valid) {
      this.hotelBookService.bookFromUnbeatableDeals = false;
      this.hotelBookService.bookFromChainDeals = false;
      sessionStorage.setItem('bookFromBanners', JSON.stringify(false));
      // localStorage.setItem('bookFromPromotion', JSON.stringify(false));
      sessionStorage.setItem('bookFromPromotion', JSON.stringify(false));
      // this.setCorrectDate();
      this.hotelSearchPanelArray = this.hotelSearchForm.value;
      this.hotelSearchPanelArray.checkInCheckOutDate = [];
      this.hotelSearchPanelArray.checkInCheckOutDate.push(this.hotelSearchForm.get('checkInDate').value);
      this.hotelSearchPanelArray.checkInCheckOutDate.push(this.hotelSearchForm.get('checkOutDate').value);
      this.hotelSearchPanelArray.currencyCode = this.currencyApi.selectedCurrency;
      if (this.hotelDataService.creditCardAgencyOnly) {
        this.hotelSearchPanelArray.isCreditCardAgencyOnly = true;
      } else {
        this.hotelSearchPanelArray.isCreditCardAgencyOnly = false;
      }
      localStorage.setItem('hotelSearchPanelArray', JSON.stringify(this.hotelSearchPanelArray));
      // Todo: Read the Form Control values
      if (this.hotelSearchPanelArray.destination.type === 'Hotel') {
        this.hotelDataService.selectedHotel = <HotelViewModel>{};
        this.hotelDataService.selectedHotel.hotelId = this.hotelSearchPanelArray.destination.hotelCode;
        this.hotelDataService.selectedHotel.hotelName = this.hotelSearchPanelArray.destination.name;
        this.hotelSearchService.destinationSearchDetails.destination = this.hotelSearchForm.get('destination').value;
        this.hotelSearchService.destinationSearchDetails = this.hotelSearchPanelArray;
        localStorage.setItem('selectedHotel', JSON.stringify(this.hotelDataService.selectedHotel));
        this.router.navigate(['../hoteldetails'], { relativeTo: this.activatedRoute });
      } else {
        this.hotelSearchService.destinationSearchDetails = this.hotelSearchPanelArray;
        if (this.hotelSearchService.destinationSearchDetails.destination.type === 'City') {
          this.hotelSearchService.destinationSearchDetails.destination.hotelCode = null;
        }
        const destination = this.hotelSearchService.destinationSearchDetails.destination.name.split(',');
        this.hotelSearchService.cityName = destination[0];
        this.router.navigate(['../hotellist'], { relativeTo: this.activatedRoute });
      }
      // ------------- Google Analytics ---------------------------------
      let checkInDate = this.hotelSearchForm.get('checkInDate').value.getDate().toString();
      checkInDate = ('0' + checkInDate).slice(-2);
      let checkInMonth = (this.hotelSearchForm.get('checkInDate').value.getMonth() + 1).toString();
      checkInMonth = ('0' + checkInMonth).slice(-2);
      const checkInYear = this.hotelSearchForm.get('checkInDate').value.getFullYear().toString();

      const GACheckInDate = checkInYear + checkInMonth + checkInDate;
      if (this.hotelSearchPanelArray.destination.type === 'City') {
        const destinationSearch = this.destinationSearch.value.split(', ');
        const city = destinationSearch[0];
        const googleAnalyticsSearchParameters: GASearchParametersViewModel = {
          city: city,
          hotel: '-',
          totalNoOfRooms: this.totalNoOfRooms.toString(),
          nightCount: this.nightCount.toString(),
          checkInDate: GACheckInDate
        };
        this.googleAnalytics(googleAnalyticsSearchParameters, 'Panel');
      } else if (this.hotelSearchPanelArray.destination.type === 'Hotel') {
        const destinationSearch = this.destinationSearch.value.split(', ');
        const hotel = destinationSearch[0];
        const city = destinationSearch[1];
        const googleAnalyticsSearchParameters: GASearchParametersViewModel = {
          city: city,
          hotel: hotel,
          totalNoOfRooms: this.totalNoOfRooms.toString(),
          nightCount: this.nightCount.toString(),
          checkInDate: GACheckInDate
        };
        this.googleAnalytics(googleAnalyticsSearchParameters, 'Panel');
      }
      // ------------- Google Analytics ---------------------------------
    }
  }

  goToHotelDetails(cityName, hotel, isUnbeatableDeal: boolean) {
    if(isUnbeatableDeal) {
      this.dealsDataService.navigatedFromUnbeatableDeals = true;
    } else {
      this.dealsDataService.navigatedFromChainDeals = true;
    }
    const todaysDate = new Date();
    const hotelInfo: HotelSearchPanelViewModel = <HotelSearchPanelViewModel>{
      destination: <DestinationViewModel>{},
      checkInCheckOutDate: <Date[]>{},
      roomDetails: <RoomDetailsViewModel[]>{}
    };

    hotelInfo.roomDetails = [];
    hotelInfo.checkInCheckOutDate = [];
    hotelInfo.roomDetails.push(<RoomDetailsViewModel>{ childAges: <ChildrenAgeViewModel[]>{} });
    hotelInfo.roomDetails[0].noOfAdults = 2;
    hotelInfo.roomDetails[0].noOfChildren = 0;
    hotelInfo.roomDetails[0].roomNumber = 1;
    hotelInfo.roomDetails[0].childAges = [];
    hotelInfo.checkInCheckOutDate.push((new Date(todaysDate.setDate(todaysDate.getDate() + 7))));
    hotelInfo.checkInCheckOutDate.push((new Date(todaysDate.setDate(todaysDate.getDate() + 1))));
    hotelInfo.currencyCode = this.currencyApi.selectedCurrency;
    hotelInfo.destination.type = 'Hotel';
    hotelInfo.destination.name = hotel.hotelName + ', ' + isUnbeatableDeal ? cityName : hotel.cityName + ', ' + hotel.countryName;
    hotelInfo.destination.hotelCode = hotel.hotelCode;
    hotelInfo.destination.cityCode = hotel.cityCode;
    hotelInfo.destination.countryCode = hotel.countryCode;
    hotelInfo.destination.hotelCode = hotel.hotelCode;
    hotelInfo.guestNationalitycode = this.configurationService.config.indonesiaGuestNationalityCode;
    if (this.hotelDataService.creditCardAgencyOnly) {
      hotelInfo.isCreditCardAgencyOnly = true;
    } else {
      hotelInfo.isCreditCardAgencyOnly = false;
    }
    this.nationalityList.forEach((nationality) => {
      if (nationality.code === this.configurationService.config.indonesiaGuestNationalityCode) {
        localStorage.setItem('guestNationalityName', nationality.name);
        this.hotelSearchService.guestNationalityName = nationality.name;
      }
    });
    localStorage.setItem('exchangeRate', ''+this.topBarService._exchangeRate);
    // ------------- Google Analytics ---------------------------------
    let checkInDate = hotelInfo.checkInCheckOutDate[0].getDate().toString();
    checkInDate = ('0' + checkInDate).slice(-2);
    let checkInMonth = (hotelInfo.checkInCheckOutDate[0].getMonth() + 1).toString();
    checkInMonth = ('0' + checkInMonth).slice(-2);
    const checkInYear = hotelInfo.checkInCheckOutDate[0].getFullYear().toString();

    const GACheckInDate = checkInYear + checkInMonth + checkInDate;
    const googleAnalyticsSearchParameters: GASearchParametersViewModel = {
      city: isUnbeatableDeal ? cityName : hotel.cityName,
      hotel: hotel.hotelName,
      totalNoOfRooms: '1',
      nightCount: '1',
      checkInDate: GACheckInDate
    };
    this.googleAnalytics(googleAnalyticsSearchParameters, isUnbeatableDeal ? 'Unbeatable Deals' : 'Chain Deals');
    // ------------- Google Analytics ---------------------------------
    this.hotelSearchService.destinationSearchDetails = hotelInfo;
    this.hotelDataService.selectedHotel = <HotelViewModel>{};
    this.hotelDataService.selectedHotel.hotelId = hotelInfo.destination.hotelCode;
    this.hotelDataService.selectedHotel.hotelName = hotelInfo.destination.name;
    if(isUnbeatableDeal) {
      this.hotelBookService.bookFromUnbeatableDeals = true;
    } else {
      this.hotelBookService.bookFromChainDeals = true;
    }
    sessionStorage.setItem('bookFromBanners', JSON.stringify(false));
    // localStorage.setItem('bookFromPromotion', JSON.stringify(false));
    sessionStorage.setItem('bookFromPromotion', JSON.stringify(false));
    // RS
    localStorage.setItem('hotelSearchPanelArray', JSON.stringify(hotelInfo));
    localStorage.setItem('selectedHotel', JSON.stringify(this.hotelDataService.selectedHotel));
    this.router.navigate(['../hoteldetails'], { relativeTo: this.activatedRoute });
  }

  googleAnalytics(googleAnalyticsSearchParameters: GASearchParametersViewModel, eventAction: string) {

    // ------------- Search Parameters -----------------
    const googleAnalyticsViewModel: GoogleAnalyticsViewModel = {
      eventCategory: 'Search',
      eventAction: eventAction,
      eventLabel: null,
      eventValue: null
    };
    this.googleAnalyticsService.customSearchEventEmitter(googleAnalyticsSearchParameters, googleAnalyticsViewModel);
    // ------------- Search Parameters Ends -----------------

    // ------------- Search Count -----------------
    // if (googleAnalyticsSearchParameters.hotel !== '-') {
    const searchCount: GoogleAnalyticsViewModel = {
      eventCategory: 'Search Count',
      eventAction: eventAction,
      eventLabel: null,
      eventValue: null
    };
    this.googleAnalyticsService.eventEmitter(searchCount);
    // }
    // ------------- Search Count Ends -----------------

    // ------------- Hotel Browse -------------------------
    if (googleAnalyticsSearchParameters.hotel !== '-') {
      const googleAnalyticsHotelBrowse: GoogleAnalyticsViewModel = {
        eventCategory: 'Hotel',
        eventAction: 'Browse',
        eventLabel: null,
        eventValue: null
      };
      this.googleAnalyticsService.customHotelBookBrowseEventEmitter(googleAnalyticsSearchParameters.hotel, googleAnalyticsHotelBrowse);
    }
    // ------------- Hotel Browse Ends -------------------------
  }

  hasErrors(controlName: string) {
    if (this.hotelSearchForm.get(controlName) !== null) {
      return (this.hotelSearchForm.get(controlName).dirty || this.hotelSearchForm.get(controlName).touched)
        && this.hotelSearchForm.get(controlName).errors !== null;
    }
  }

  getValidationMessage(controlName: string) {
    if (this.hotelSearchForm.get(controlName).hasError('required')) {
      if (controlName === 'guestNationalitycode') {
        return ErrorMessages.requiredNationalityMessage;
      } else if (controlName === 'checkInDate' || controlName === 'checkOutDate') {
        return ErrorMessages.requiredCheckinCheckoutDatesMessage;
      } else {
        return ErrorMessages.requiredFieldMessage;
      }
    } else if (this.hotelSearchForm.get(controlName).hasError('maxDateError')) {
      if (controlName === 'checkOutDate') {
        return ErrorMessages.invalidDateRangeMessage;
      }
    } else {
      return '';
    }
  }

  calcMaxDate() {
    const today = new Date();
    this.maxDateValue = new Date();
    this.maxDateValue.setFullYear(today.getFullYear() + 2);
  }

  setBlankForDestination() {
    this.previousDestination = this.hotelSearchForm.get('destination').value;
    this.previousDestinationSearch = this.destinationSearch.value;
    if (this.destinationSearch.value !== null) {
      this.destinationSearch.setValue(null);
      this.hotelSearchForm.get('destination').setValue(null);
    }
  }

  setPreviosDestination() {
    if (this.destinationSearch.value === null && this.hotelSearchForm.get('destination').value === null) {
      this.destinationSearch.setValue(this.previousDestinationSearch);
      this.hotelSearchForm.get('destination').setValue(this.previousDestination);
    }
  }

  showCities(destination) {
    if (this.citychips !== undefined && (destination === '' || destination === null)) {
      this.isShowCities = true;
      this.showPanel = true;
      this.noDataAvailable = false;
    }

  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
    this.disableNotificationSection();
  }

  callPaymentPage() {
    this.router.navigate(['/authenticated/payment/paymentgateway']);
  }
}
