import { ApplicationRoleViewModel } from '../applicationroleviewmodel';

export class Agentuserlistviewmodel {
  id: string;
  userName: string;
  lastName: string;
  fullName: string;
  firstName: string;
  agencyName: string;
  agencyCode: string;
  branchName: string;
  designation: string;
  email: string;
  roleName: string;
  userApplicationRole: ApplicationRoleViewModel[];
  activationDate: string;
  deActivationDate: string;
  isActive: boolean;
  totalRecords; number;
  updatedBy: string;
  updatedDate: string;
}