import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { PhotosDataService } from '../../services/photos-data.service';
import { CONSTANTS } from 'src/app/common/constants';
import { PhotoUploadViewModel } from 'src/app/common/viewmodels/photouploadviewmodel';
import { DocType } from 'src/app/common/enum';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';

@Component({
  selector: 'app-edit-photo-dialog',
  templateUrl: './edit-photo-dialog.component.html',
  styleUrls: ['./edit-photo-dialog.component.css']
})
export class EditPhotoDialogComponent implements OnInit, OnDestroy {

  roomId: number;
  isCurrentMainPhoto: boolean;
  imgPath: string;
  operation: string;
  allRoomsPhotos: any;
  create = CONSTANTS.operation.create;
  edit = CONSTANTS.operation.edit;
  // ContentUrl = this.portalService.appSettings.BaseUrls.ContentUrl;
  file: File;
  isFileUploaded: boolean;
  isUploadComplete: boolean;
  error: string;
  errorSize: string;
  image = new Image();
  src: any;
  extension: number;
  photoForm: FormGroup;
  hotelId: number;
  hotelCode: string;
  closeDialog: boolean;
  isRoomPhoto: boolean;
  rooms: any = [];
  tagName: string;
  roomTagsToCreate: any = [];
  roomTagsToUpdate: any = [];
  hotelTagId: number;
  snackBarCounter: number;
  isResponse: boolean;
  isApiCalled: boolean;
  subscriptions: Subscription[] = [];
  openConfirmBox: boolean;
  isDataLoaded: boolean;
  tempUrl: any;
  disabled: boolean;
  
  @Input() photoURLC: string;
  @Output() photoURLChangeC: EventEmitter<string> = new EventEmitter<string>();
  @Input() imgWidth: number;
  @Input() imgHeight: number;

  fileExt: string;
  maxSize = this.configservice.config.b2bPhotosConfig.maxSize;
  imageremoved: boolean;
  deactivateButtons: boolean;

  result: any;


  constructor(public dialogRef: MatDialogRef<EditPhotoDialogComponent>,
    public photosDataService: PhotosDataService, private configservice: ConfigurationService) { }

  ngOnInit() {
    this.fileExt = DocType[3] + ', ' + DocType[4] + ', ' + DocType[5];
    this.isFileUploaded = false;
    this.isUploadComplete = false;
    this.error = null;
    this.errorSize = null;
    this.isApiCalled = false;
    this.buildPhotoForm();

    if (this.disabled === true) {
      this.deactivateButtons = true;
    }
  }

  buildPhotoForm() {
    this.photoForm = new FormGroup({
      photoPath: new FormControl(),
    });
  }

  fileChanged($event): void {


    this.closeDialog = true;
    this.isUploadComplete = false;
    const thisObject = this;
    this.imageremoved = false;
    this.isFileUploaded = false;
    const reader: FileReader = new FileReader();
    this.file = $event.target.files[0];
    if (this.file) {
      this.isValidFileExtension(this.file);
      if (this.error || this.errorSize) {
      }
      if (!thisObject.error && !thisObject.errorSize) {
        this.image.src = window.URL.createObjectURL(this.file);
        reader.readAsDataURL(this.file);
        reader.onload = function (e: any) {
          thisObject.photoURLC = e.target.result;
        };
      }
    }
  }

  readFile(): void {
    const thisObject = this;
    const myReader: FileReader = new FileReader();
    myReader.readAsArrayBuffer(this.file);
    let formData = <PhotoUploadViewModel>{};
    let abc: string;
    myReader.onloadend = function (e) {
      const byteArray = new Uint8Array(<ArrayBuffer>myReader.result);
      const array = Array.from(byteArray);
      formData = {
        ImageBytes: array,
        extension: thisObject.extension
      };
      thisObject.photosDataService.uploadPhoto(formData)
        .subscribe(response => {
          if (response.result && !response.isError) {
            thisObject.photoForm.get('photoPath').setValue(response.result);
            abc = response.result;
            thisObject.isUploadComplete = true;
            thisObject.photoURLChangeC.emit(response.result);
          } else {
            thisObject.error = response.message;
          }
        });
      thisObject.isFileUploaded = true;
    };
  }

  isValidFileExtension(file) {
    const size = Math.round(file.size / 1024);
    const extensions = (this.fileExt.split(','))
      .map(function (x) { return x.toLocaleUpperCase().trim(); });
    const ext = file.name.toUpperCase().split('.').pop() || file.name;
    const exists = extensions.includes(ext);
    if (!exists) {
      this.error = 'Please choose an image (JPG, PNG) file only.';
      // this.file = null;
    } else {
      this.error = null;
      if (ext === DocType[3]) {
        this.extension = DocType.JPG;
      } else if (ext === DocType[4]) {
        this.extension = DocType.JPEG;
      } else if (ext === DocType[5]) {
        this.extension = DocType.PNG;
      }

      this.isValidFileSize(file);
    }

    // if (size > 200) {
    //   this.error = 'Maximum 200 KB image is allowed.';
    // }
    
  }

  isValidFileSize(file) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = fileSizeinMB * 100 / 100;
    if (size > this.maxSize) {
      this.error = 'File exceeds file size limit of ' + this.maxSize + 'MB';
    } else if (size === 0) {
      this.error = 'File is too small';
    } else {
      this.error = null;
      this.isValidDimensions();
    }
  }

  isValidDimensions() {
    const thisObject = this;
    thisObject.image.onload = function () {
      const height = thisObject.image.height;
      const width = thisObject.image.width;
      if (height !== thisObject.imgHeight || width !== thisObject.imgWidth) {
        thisObject.error = 'Please upload an image as per recommended Minimum/Maximum size dimensions (500 px X 500 px)';
      } else {
        thisObject.error = null;
      }
    };
  }

  cancelClicked() {

     this.dialogRef.close(this.isApiCalled);
    
  }

  closeConfirmDialog() {
    this.openConfirmBox = false;
  }

  savePhoto(closeDialog) {
    this.closeDialog = closeDialog;
    if (closeDialog) {
      this.dialogRef.close(true);
    } else {
      this.isUploadComplete = false;
      this.isFileUploaded = false;
      this.file = null;
      this.buildPhotoForm();
    }
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }

}
