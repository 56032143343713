import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-hotel-search-progress-dialog',
  templateUrl: './hotel-search-progress-dialog.component.html',
  styleUrls: ['./hotel-search-progress-dialog.component.css']
})
export class HotelSearchProgressDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<HotelSearchProgressDialogComponent>) { }

  ngOnInit() {
  }

}
