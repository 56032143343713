import { Injectable } from '@angular/core';
import {formatNumber, getLocaleNumberFormat, NumberFormatStyle } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor() { }
  getCurrentLocale(): string {
    // Todo For now hardcoding to en-US -- Change later when implementing Localization
      return 'en-US';
    // return 'fr-FR';
  }
  getSelectedLocaleNumberFormat(): string {
    return getLocaleNumberFormat(this.getCurrentLocale(), NumberFormatStyle.Decimal);
  }
}
