import { Injectable } from '@angular/core';
import { HotelSearchPanelViewModel } from '../viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';
import {Subject } from 'rxjs';
import { HotelRecheckSearchViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelrechecksearchviewmodel';
import { RoomDetailViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelviewmodel';

@Injectable({
  providedIn: 'root'
})
export class HotelSearchService {
  private hotelSearchChangeSource = new Subject<null>();
  hotelSearchChange$ = this.hotelSearchChangeSource.asObservable();
  guestNationalityName: string;

  private _cityName: string;
  private _destinationSearchDetails: HotelSearchPanelViewModel = <HotelSearchPanelViewModel>{};
  private _recheckSearchDetails: HotelRecheckSearchViewModel = <HotelRecheckSearchViewModel>{};

  constructor() { }
  get cityName(): string {
    return this._cityName;
  }

  set cityName(city: string) {
    this._cityName = city;
  }
  get destinationSearchDetails(): HotelSearchPanelViewModel {
    return this._destinationSearchDetails;
  }

  set destinationSearchDetails(searchDetails: HotelSearchPanelViewModel) {
    this._destinationSearchDetails = searchDetails;
  }

  onHotelSearchChange($event) {
    this.hotelSearchChangeSource.next($event);
  }

  /* get recheckSearchDetails(): HotelRecheckSearchViewModel {
    return this._recheckSearchDetails;
  }

  set recheckSearchDetails(recheckSearchDetails: HotelRecheckSearchViewModel) {
    this._recheckSearchDetails = recheckSearchDetails;
  } */
}
