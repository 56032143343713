import { SearchCriteriaViewModel } from './searchcriteriaviewmodel';

export class MarkupSearchViewModel extends SearchCriteriaViewModel {
    agencyId: number;
    branchId: number;
    fromDateKey?: string;
    fromDate?: string;
    toDateKey?: string;
    toDate?: string;
    dateType?: string;
    countryId?: number;
    cityId?: number;
    hotelId?: number;
    markupTypeId?: number;
    markupValueFrom?: number;
    markupValueTo?: number;
    tabId: number;
    tabName: string;
    currencyId?: number;
    statusId?: number;
    markupType?: string;
}
