import { AuthService } from './../../../common/shared/services/auth.service';
import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { BreadCrumbService } from 'src/app/b2b/common/b2b-shared/services/bread-crumb.service';
import { FrameworkConfigService } from '../services/framework-config.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-framework-body',
  templateUrl: './framework-body.component.html',
  styleUrls: ['./framework-body.component.css']
})
export class FrameworkBodyComponent implements OnInit, AfterViewChecked  {

    constructor(private cdRef: ChangeDetectorRef,
      public breadCrumbService: BreadCrumbService, public frameworkConfigService: FrameworkConfigService,
      public authService: AuthService) {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
