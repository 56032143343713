import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticatedHttpService } from '../../../../common/shared/services/authenticated-http.service';
import { TermsAndConditionsGroupViewModel } from '../../../viewmodels/terms-and-conditions/termsandconditionsgroupviewmodel';
import { AboutMgBedbankViewModel } from '../../../viewmodels/aboutmgbedbankviewmodel';
import { MgBedbankBenefitsViewModel } from '../../../viewmodels/mgbedbankbenefitsviewmodel';
import { ConfigurationService } from '../../../../common/shared/services/configuration.service';
import { SupplierInfoViewModel } from 'src/app/b2b/viewmodels/registration-forms/supplierinfoviewmodel';
import { AgentRegistrationInfoViewModel } from 'src/app/b2b/viewmodels/registration-forms/agentregistrationinfoviewmodel';
import { GroupBookingInfoViewModel } from 'src/app/b2b/viewmodels/group-booking/groupbookinginfoviewmodel';
import { FAQGroupViewModel } from 'src/app/b2b/viewmodels/faq/faqgroupviewmodel';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  mgBedbankBenefits$: Observable<MgBedbankBenefitsViewModel[]>;
  aboutMgBedBank$: Observable<AboutMgBedbankViewModel[]>;

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService, public datePipe: DatePipe) {
  }

  getAboutMGBedBank(): Observable<AboutMgBedbankViewModel[]> {
    return this.aboutMgBedBank$ = this.authenticatedHttpService.get(this.configurationService.config.baseUrls.b2BContentApi,
      'api/Home/GetAboutMGBedBank/').pipe(
        map((data) => {
          if (data) {
            return data.result;
          } else {
            return [];
          }
        }));
  }

  getMGBedBankBenefits(): Observable<MgBedbankBenefitsViewModel[]> {
    return this.mgBedbankBenefits$ = this.authenticatedHttpService.get(this.configurationService.config.baseUrls.b2BContentApi,
      'api/Home/GetMGBedBankBenefits/').pipe(
        map((data) => {
          if (data) {
            return data.result;
          } else {
            return [];
          }
        }));
  }

  getTermsAndConditions(): Observable<TermsAndConditionsGroupViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.b2BContentApi,
      'api/Home/GetTermsAndConditions/').pipe(
        map((data) => data ? data.result : []));
  }

  getSupplierTermsAndConditions(supId): Observable<any> {
    const supplierDetails = {
      id: supId
    };
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.b2BContentApi, supplierDetails,
      'api/TermsAndConditions/GetBySupplierId').pipe(
        map((data) => data ? data : null));
  }

  notifyNewSupplierRegistered(supplierInfo: SupplierInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, supplierInfo,
      'api/EmailNotification/NotifyNewSupplierRegistered').pipe(
        map((data) => data ? data : null));
  }

  notifyNewAgentRegistered(agentInfo: AgentRegistrationInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, agentInfo,
      'api/EmailNotification/NotifyNewTravelAgentRegistered').pipe(
        map((data) => data ? data : null));
  }

  notifyNewGroupBooking(groupBookingInfo: GroupBookingInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, groupBookingInfo,
      'api/EmailNotification/NotifyNewGroupBookingRequest').pipe(
        map((data) => data ? data : null));
  }

  notifyContactUs(contactUsData: any): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, contactUsData,
      'api/EmailNotification/NotifyNewEnquiry').pipe(
        map((data) => data ? data : null));
  }

  getFAQs(): Observable<FAQGroupViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.b2BContentApi,
      'Api/Home/GetFrequentlyAskedQuestions').pipe(
        map((data: any) => data ? data.result : []));
  }

  getMGBedbankAbout() {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.b2BContentApi,
      'Api/Home/GetMgBedBankDetails/').pipe(
        map((data: any) => data ? data.result : []));
  }

  getClientDetails(): Observable<any> {
    const clientDateTime = new Date();
    const timeZoneOffset = -clientDateTime.getTimezoneOffset() / 60;
    const clientDetailsRequest = {
      timeZoneOffset: timeZoneOffset,
      clientDateTime: this.datePipe.transform(new Date(), 'M/d/yy, h:mm:ss a')
    };
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.b2BContentApi, clientDetailsRequest,
      'Api/Home/GetClientDetails/').pipe(
        map((data) => data ? data : null));
  }
}
