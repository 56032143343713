export class HotelFilterUpdateViewModel {
    hotelName: string;
    fromPrice?: number;
    toPrice?: number;
    isMgPreferred?: boolean;
    isFreeCancellation?: boolean;
    isOpenDateHotelVoucher?: boolean;
    starRatings?:  StarRatingUpdateViewModel[];
    promotions?: PromotionUpdateViewModel[];
    areas?: AreaUpdateViewModel[];
    hotelTypes?: HotelTypeUpdateViewModel[];
    // areaId?: number[];
    // brand?: BrandUpdateViewModel; // Todo Remove this
    chainId?: number[];
    landmarkId?: number[];
    brandId?: number;
    facilities: FacilityUpdateViewModel[];
}

export class StarRatingUpdateViewModel {
    id: number;
    rating: number;
    isSelected: boolean;
    count: number;
}

export class PromotionUpdateViewModel {
    type: string;
    isSelected: boolean;
}

export class AreaUpdateViewModel {
    id: number;
    name: string;
    count: number;
}

export class HotelTypeUpdateViewModel {
    id: number;
    name: string;
    count: number;
}

/* export class BrandUpdateViewModel {
    id: number;
    name: string;
}

export class LandmarkUpdateViewModel {
    id: number;
    name: string;
} */

export class FacilityUpdateViewModel {
   // id: number;
    name: string;
    code: string;
}
