import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { CONSTANTS } from '../../../common/constants';
import { AgencyViewModel } from '../../viewmodels/agency-markup/agencyviewmodel';
import { AgencyMarkupService } from '../../common/b2b-shared/services/agency-markup.service';
import { UserProfileService } from '../../../common/shared/services/user-profile.service';


@Component({
  selector: 'app-agency-markup',
  templateUrl: './agency-markup.component.html',
  styleUrls: ['./agency-markup.component.css']
})
export class AgencyMarkupComponent implements OnInit {

  searchBranchForm: FormGroup;
  isDisabled = true;

  edit = CONSTANTS.operation.edit;
  create = CONSTANTS.operation.create;
  read = CONSTANTS.operation.read;
  operation: string;
  userRole: string;
  editView = false;
  readView = false;
  actions: string;
  agencyId: number;
  searchAgencyArray: AgencyViewModel[];
  noDataAvailable: boolean;
  showTabs: boolean;
  isReset = false;
  direction = 1;

  constructor(
    private agencyMarkupService: AgencyMarkupService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit() {
    this.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    this.userRole = this.userProfileService.getUserRole();
    this.searchBranchForm = new FormGroup({
      searchText: new FormControl()
    });

    if (this.agencyMarkupService.selectedMarkupBranch) {
      this.searchBranchForm.get('searchText').setValue(this.agencyMarkupService.selectedMarkupBranch);
      this.showTabs = true;
    }

    if (this.userRole !== 'SuperUser' && this.userRole !== 'SuperUser ViewOnly') {
      this.agencyMarkupService.getBranchByUserId(this.userProfileService.getBasicUserInfo().agentId).subscribe(agencyResult => {
        this.searchAgencyArray = agencyResult;
        this.agencyMarkupService.selectedMarkupBranch = agencyResult;
        this.searchBranchForm.get('searchText').setValue(this.agencyMarkupService.selectedMarkupBranch);
        this.showTabs = true;
      });
    }

    this.searchBranchForm.controls.searchText.valueChanges.subscribe(data => {
      this.searchAgencyArray = [];
      let flag;
      flag = (data === null || data === '') ? false : ((data !== null && data.id === undefined) ? true : false);
      if (flag) {
        this.agencyMarkupService.getAgencyNameAndCode(data.trim(), this.agencyId).subscribe(agencyResult => {
          this.searchAgencyArray = agencyResult;
        });
      }
    });
  }

  search(event, data) {
    if (event.source.selected) {
      this.isReset = false;
      this.showTabs = true;

      // Added to verify is all branch and further used in indivisual  branch
      if (data.type === 'Agency') {
        data.isAgency = true;
      }

      this.agencyMarkupService.selectedMarkupBranch = data;
      this.updateSelectedMarkupBranch(data);
    }
  }

  updateSelectedMarkupBranch(data): void {
    // send message to subscribers via observable subject
    this.agencyMarkupService.updateSelectedMarkupBranch(data);
  }

  displayFn(val: AgencyViewModel) {
    return val ? val.name : '';
  }

  resetSearch() {
    this.isReset = true;
    this.searchBranchForm.reset();
    this.noDataAvailable = false;
    this.isDisabled = true;
    this.showTabs = false;
    this.agencyMarkupService.clearSelectedBranch();

  }

  resetChange() {
    if (this.searchBranchForm.get('searchText').value === null || this.searchBranchForm.get('searchText').value === '') {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

}
