import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './common/shared/services/auth.service';
import { MenuDataService } from './common/shared/services/menu-data.service';
import { defaultMenuItems } from './app.menu';
import { UserProfileService } from './common/shared/services/user-profile.service';
import { Location } from '@angular/common';
import { Utilities } from './common/utilities/utilities';
import { StateService } from './common/shared/services/state.service';
import { ErrorService } from './framework/fw/services/error.service';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { filter } from 'rxjs/operators';
import { ConfigurationService } from './common/shared/services/configuration.service';
import { CONSTANTS } from './common/constants';
import { FrameworkConfigService } from './framework/fw/services/framework-config.service';

declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ClientApp';
  gaAccountId = this.configurationService.config.gaAccountId;
  routerLinks = CONSTANTS.routerLinks;
  constructor(private _authService: AuthService, private _router: Router,
    private menuDataService: MenuDataService, private location: Location,
    private stateService: StateService, private errorService: ErrorService,
    public configurationService: ConfigurationService,
    private frameWorkConfigService: FrameworkConfigService
  ) {
    let previousUrl: string;
    let currentUrl: string;
    currentUrl = this._router.url;
    const navEndEvents = _router.events.pipe(filter(event => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd) => {
      if (event.urlAfterRedirects !== this.routerLinks.b2bDefault && event.urlAfterRedirects !== this.routerLinks.BOHome) {
        previousUrl = currentUrl;
        currentUrl = event.url;
        this.stateService.previousUrl = previousUrl;
        this.stateService.currentUrl = currentUrl;

        if (this._authService.isLoggedIn) {
          gtag('config', this.gaAccountId, {
            'page_path': event.urlAfterRedirects,
            'custom_map': {
              'dimension1': 'Custom User Info',
              'dimension2': 'City', 'dimension3': 'Hotel Name',
              'dimension4': 'Number of Rooms',
              'dimension5': 'Number of Nights',
              'dimension6': 'Check-in Date',
              'dimension7': 'Agent ID',
              'dimension8': 'Agent Name',
              'dimension9': 'Agency ID',
              'dimension10': 'Agency Code'
            }
          });
          // gtag('set', 'dimension1', this._authService.getSubjectId());
          // gtag('event', 'test_event_name', {'City': 'test_value'});
          //// gtag('send', 'pageview');
        } else {
          gtag('config', this.gaAccountId, {
            'page_path': event.urlAfterRedirects
          });
        }
      }
    });
  }
  ngOnInit(): void {
    const cookieEnabled = window.navigator.cookieEnabled;
    if (cookieEnabled) {
      if (window.location.href.indexOf('?postLogout=true') > 0) {
        this._authService.signoutRedirectCallback().then(() => {
          const url: string = this._router.url.substring(
            0,
            this._router.url.indexOf('?')
          );
          this._router.navigateByUrl(url);
        });
      }

      // Had to do since the it was showing change detection error - So dummy menus
      this.menuDataService.initializeNonLoggedInMenus(defaultMenuItems);
      // Route to default by always
      if (!Utilities.isNullOrEmpty(this.location.path())) {
        this.stateService.State = this.location.path();
      }
      if (window.location.href.indexOf('/error') > 0) {
        const oidcError = localStorage.getItem('oidc-error')

        // tslint:disable-next-line: max-line-length
        //  *** handle special condition of time clock out of sync.Note If you upgrade the oidc library then validate whether the below message is same
        if (!Utilities.isNullOrEmpty(oidcError) && (oidcError.includes('iat is in the future')
          || oidcError.includes('exp is in the past'))) {
            // this.errorService.message = 'Your clock time is out of sync.Please update the clock time  and close the browser and try again ';
            this.errorService.oidcErrorMessage = 'Oh Snap! It appears that your computer clock time is not sync with our system. Please kindly help to set your computer clock time into "automatic", restart your browser by closing it and opening it again. If you need any help, please contact your MG PIC.';
            localStorage.removeItem('oidc-error');
            this._router.navigate(['/']);
          
        } else {
           // if No matching found in storage error comes user will be auto logged in and redirected to Home page, we did the workaround so user wont get stuck at error page and can proceed further
           localStorage.removeItem('oidc-error');
           this._authService.login();
           if (this._authService.isLoggedIn()) {
              this._router.navigate(['/']);
           } else {

              // tslint:disable-next-line: max-line-length
              this.errorService.oidcErrorMessage = 'Congratulations, It appears that you have discovered our secret error page. Please kindly help to notify your Sales PIC about this page and how did you get here by sharing your: Browser Type (Chrome/Firefox/Internet Explorer/Safari and whether you are on Incognito mode or not)';              
              this.errorService.oidcErrorMessage += oidcError;
              this._router.navigate(['/']);
//              this._router.navigate(['/error']);
           }
        }
        // localStorage.removeItem('oidc-error');
        // this._router.navigate(['/error']);
      } else if (!Utilities.isNullOrEmpty(this.location.path()) && this.location.path() === '/bohome') {
        this.frameWorkConfigService.IsCallCenterUser = true;
        this._router.navigate(['/bohome']);
      } else {
        this._router.navigate(['/default']);
      }
    } else {
      let cookieEnableLink = '';
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf('Chrome') > -1) {
        cookieEnableLink = 'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en';
      } else if (userAgent.indexOf('Firefox') > -1) {
        cookieEnableLink = 'https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them';
      } else if (userAgent.indexOf('Edge') > -1) {
        // tslint:disable-next-line:max-line-length
        cookieEnableLink = 'https://answers.microsoft.com/en-us/edge/forum/edge_other-edge_win10/enabling-cookies-for-microsoft-edge/7c583015-0cde-4ddc-a1ad-45cc9d24c9fc';
      }
      this.errorService.message = 'Please Enable Cookies on your browser ';
      this.errorService.link = cookieEnableLink;
      this._router.navigate(['/error']);
    }


  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    // Actually logOff only if userType is BO && is logged in
    // && not logged out --> This condition occurs only when the user cclicks the logout link in B2B
    // and during that time as well the event gets fired and by that time logout does not get completed.
    // if (this._authService.isLoggedIn() && !this._authService.isLoggedOut()
    //   && this._authService.isBoUser()
    // ) {
    //   this._authService.logout();

    // }

    // Reset Local Storage Items
    const path = this.location.path();
    if (path && path.toLowerCase() !== CONSTANTS.routeList.hotelDetails.toLowerCase()) {
        localStorage.removeItem('guestNationalityName');
        localStorage.removeItem('selectedHotel');
        localStorage.removeItem('selectedHotelForGet');
        localStorage.removeItem('selectedCurrency');
    }
  }
}
