import { HotelSearchService } from './../../services/hotel-search.service';
import { HotelDataService } from 'src/app/b2b/common/b2b-shared/services/hotel-data.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { HotelSummaryMapViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelsummarymapviewmodel';
import { flatMap } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { CONSTANTS } from 'src/app/common/constants';
import { HotelBookService } from '../../services/hotel-book.service';
import { Location } from '@angular/common';
import { TopBarService } from '../../../../../framework/fw/services/top-bar.service';
declare var google: any;

@Component({
  selector: 'app-mapsdialog',
  templateUrl: './mapsdialog.component.html',
  styleUrls: ['./mapsdialog.component.css']
})
export class MapsdialogComponent implements OnInit, OnDestroy {
  overlays: any[];
  orgLat: any;
  orgLng: any;
  hotelName: string;
  address: string;
  startPrice: number;
  currency: string;
  starRating: string;
  hotelImage: string;
  showDetails = false;
  MgPreferred = false;
  isHotelImage = false;
  imageFolderPath = CONSTANTS.imageFolderPath.hotelList;
  hotelSummary = new HotelSummaryMapViewModel();
  path: string;
  // ContentUrl = this.portalService.appSettings.BaseUrls.ContentUrl;
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private hotelDataService: HotelDataService,
    public hotelSearchService: HotelSearchService,
    public configurationService: ConfigurationService,
    public hotelBookService: HotelBookService,
    private location: Location,
    public mapdialogRef: MatDialogRef<MapsdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private topBarService: TopBarService) {
    this.hotelSummary = this.data.hotel;
    this.orgLat = this.hotelSummary.latitude;
    this.orgLng = this.hotelSummary.longitude;
    this.hotelName = this.hotelSummary.hotelName;
    this.address = this.hotelSummary.address;
    this.startPrice = this.hotelSummary.grossPrice;
    this.currency = this.hotelSummary.currency;
    // tslint:disable-next-line:max-line-length
    this.path = this.location.path();
    if (this.path && this.path.toLowerCase() === CONSTANTS.routeList.hotelDetails.toLowerCase()) {
      this.hotelImage = this.hotelSummary.image;
    } else {
      this.hotelImage = this.configurationService.config.imageUrl +
            this.hotelSummary.hotelId + this.imageFolderPath + this.hotelSummary.image;
    }
    if (this.hotelSummary.starRating.toString() === '0') {
      this.starRating = '0.0';
    } else {
      this.starRating = this.hotelSummary.starRating;
    }
    this.MgPreferred = this.hotelSummary.isMgPreferred;
    mapdialogRef.disableClose = true;
    if (this.hotelImage === null) {
      this.isHotelImage = false;
    } else {
      this.isHotelImage = true;
    }
  }

  addClass() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('show-hotel-map');
    body.classList.remove('show-hotel-map-full');
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('show-hotel-map');
    let mapOptions: any;
    // let mapLat: number;
    // let mapLng: number;
    let gmap: any;
    let marker: any;

    // ('Data received at dialog: ' + JSON.stringify(this.data));
    const mapDiv = document.getElementById('mapCanvas');
    mapOptions = {
      center: new google.maps.LatLng(this.orgLat, this.orgLng),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,

      clickableIcons: false
    };
    gmap = new google.maps.Map(mapDiv, mapOptions, {
      clickableIcons: false
    });
    const contentString = `<div style="padding: 5px; color: #004267;
      font-size: 13px; margin: 5px 0px 5px 0px; font-family:'+ 'maven_probold'+',
      sans-serif !important;"><b> ${ this.hotelName} </b> </div>`;

    marker = new google.maps.Marker({
      position: new google.maps.LatLng(this.orgLat, this.orgLng),
      // label: this.hotelName,
      map: gmap,

      // animation: google.maps.Animation.BOUNCE [routerLink] = "['/hoteldetails','hotelmgmt']"
    });

    const infowindow = new google.maps.InfoWindow({
      content: contentString
    });
    google.maps.event.addListener(marker, 'mouseover', (function (marker1) {
      return function () {

        infowindow.open(gmap, marker1);

      };
    })(marker));
    const self = this;
    google.maps.event.addListener(marker, 'click', (function (marker1) {
      return function () {
        infowindow.close(gmap, marker1);
        //  this.router.navigate(['../hoteldetails'], { relativeTo: this.activatedRoute });
        //  window.location.href = 'http://localhost:59575/authenticated/hotelmgmt/hoteldetails';
        redirectHotelDetails();
      };
      function redirectHotelDetails() {
        self.showDetails = true;
      }
    })(marker));

    function cancelEvent(e) {
      e.cancelBubble = true;
      if (e.stopPropagation) { e.stopPropagation(); }
    }
    // google.maps.event.addDomListener(gmap, 'click', function() {
    //  // window.alert('Map was clicked!');
    //  return null;
    // }
    // );
    google.maps.event.addDomListener(gmap, 'mousedown', cancelEvent);
    google.maps.event.addDomListener(gmap, 'click', cancelEvent);
  }
  redirectHotelDetails() {
    this.mapdialogRef.close('unselected');
    this.hotelBookService.bookFromUnbeatableDeals = false;
    this.hotelBookService.bookFromChainDeals = false;
    sessionStorage.setItem('bookFromBanners', JSON.stringify(false));
    // localStorage.setItem('bookFromPromotion', JSON.stringify(false));
    sessionStorage.setItem('bookFromPromotion', JSON.stringify(false));
    localStorage.setItem('exchangeRate', ''+this.topBarService._exchangeRate);
    if (this.path && this.path.toLowerCase() === CONSTANTS.routeList.hotelDetails.toLowerCase()) {
      this.router.navigate(['authenticated/hotelmgmt/hoteldetails']);
    } else {
      localStorage.setItem('selectedHotel', JSON.stringify(this.hotelDataService.selectedHotel));
      localStorage.setItem('guestNationalityName', this.hotelSearchService.guestNationalityName);
      this.router.navigate([], { relativeTo: this.activatedRoute }).then(res => {
        window.open('authenticated/hotelmgmt/hoteldetails', '_blank');
      });
    }
  }

  closeHotelInfo() {
    this.showDetails = false;
  }

  closeMap() {
    this.mapdialogRef.close('unselected');
  }


  showFullScree(){
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('show-hotel-map-full');
  }


  hideFullScree(){
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('show-hotel-map-full');
  }

  ngOnDestroy(): void {
    this.addClass();
  }

}
