import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IHomeCarouselItem {
  name?: string;
  imagePath: string;
  redirectUrl?: string;
  hotelId: number;
  agentLoginBannerUrl: string;
  hotelCode: string;
  cityCode: string;
  countryCode: string;
  hotelName: string;
  cityName: string;
  countryName: string;
}

@Injectable({
  providedIn: 'root'
})
export class HomeCarouselService {
  items: IHomeCarouselItem[];
  items$: Observable<IHomeCarouselItem[]>;
  constructor() { }
}
