import { NavigationExtras, Router } from '@angular/router';
import { CancelpopupService } from 'src/app/b2b/common/b2b-shared/dialogs/cancelpopup.service';
import * as ExcelProper from 'exceljs';

export class Utilities {
  static isNullOrEmpty = function (value: any): boolean {
    return value === undefined || value === null || value.toString().trim() === '';
  };

  static isNullOrEmptyArray = function (value: any[]): boolean {
    return value === undefined || value === null || value.length === 0;
  };
  static isNullOrUndefined = function (value: any): boolean {
    return value === undefined || value === null;
  };

  static confirmCancel(isFormDirty: boolean, cancelpopupService: CancelpopupService,
    router?: Router, navigateURL?: any[], navigateURLExtras?: NavigationExtras,
    beforeNavigationCallback?: Function, afterNavigationCallback?: Function, message?: string, okButtonText?: string,
    cancelButtonText?: string, confirmText?: string): any {
    let confirmCancelSubscription = null;
    let messageText = null;
    let confirmMessageText = null;
    if (isFormDirty) {
      if (message === undefined) {
        messageText = 'If you cancel, the current data will be lost';
      } else {
        messageText = message;
      }
      if (confirmText === undefined) {
        confirmMessageText = 'Confirm';
      } else {
        confirmMessageText = confirmText;
      }
      // tslint:disable-next-line:max-line-length
      confirmCancelSubscription = cancelpopupService.confirm(confirmMessageText, messageText, okButtonText, cancelButtonText).subscribe(res => {
        if (res) {
          Utilities.navigate(router, navigateURL, navigateURLExtras, beforeNavigationCallback, afterNavigationCallback);
        }
      });
    } else {
      Utilities.navigate(router, navigateURL, navigateURLExtras, beforeNavigationCallback, afterNavigationCallback);
    }
    return confirmCancelSubscription;
  }

  // tslint:disable-next-line:max-line-length
  static navigate(router?: Router, navigateURL?: any[], navigateURLExtras?: NavigationExtras, beforeNavigationCallback?: Function, afterNavigationCallback?: Function) {
    if (!Utilities.isNullOrEmpty(beforeNavigationCallback)) {
      beforeNavigationCallback.call(this);
    }
    if (!Utilities.isNullOrEmpty(router) && !Utilities.isNullOrEmpty(navigateURL)) {
      router.navigate(navigateURL, navigateURLExtras);
    }
    if (!Utilities.isNullOrEmpty(afterNavigationCallback)) {
      afterNavigationCallback.call(this);
    }
  }

  static base64ToBlob(base64, mimetype) {
    if (!atob || !Uint8Array) {
      // The current browser doesn't have the atob function. Cannot continue
      return null;
    }

    mimetype = mimetype || '';
    const slicesize = 512;
    const bytechars = atob(base64);
    const bytearrays = [];

    for (let offset = 0; offset < bytechars.length; offset += slicesize) {
      const slice = bytechars.slice(offset, offset + slicesize);
      const bytenums = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        bytenums[i] = slice.charCodeAt(i);
      }
      const bytearray = new Uint8Array(bytenums);
      bytearrays[bytearrays.length] = bytearray;
    }

    return new Blob(bytearrays, { type: mimetype });
  }

  static commaFormatted(amount) {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  static disableSubmitButtonOnceClicked(e: Event, thisObject: any, callBackFunction: Function, callBackFunctionParameters?: any[]) {
    const currentTarget = (e.currentTarget as any);
    if (currentTarget.localName === 'button') {
      currentTarget.disabled = true;
      callBackFunction.apply(thisObject, callBackFunctionParameters);
      currentTarget.disabled = false;
    } else if (currentTarget.localName === 'form') {
      currentTarget.querySelectorAll('button[type=submit]').forEach(b => b.disabled = true);
      callBackFunction.apply(thisObject, callBackFunctionParameters);
      currentTarget.querySelectorAll('button[type=submit]').forEach(b => b.disabled = false);
    } else {
      callBackFunction.apply(thisObject, callBackFunctionParameters);
    }
  }

  static getJakartaDate(date: Date) {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  }

  static downloadExcelFile(workbook: ExcelProper.Workbook, fileName: string) {
    workbook.xlsx.writeBuffer().then((data) => {
      // tslint:disable-next-line:max-line-length
      const blob = Utilities.Uint8ArrayToBlob((data as any), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {  // for other browsers
        const fileURL = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = fileURL;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      }
    });
  }

  static Uint8ArrayToBlob(Uint8ArrayContent: Uint8Array[], mimetype) {
    if (!Uint8Array) {
      // The current browser doesn't have Uint8Array function. Cannot continue
      return null;
    }
    mimetype = mimetype || '';
    const slicesize = 512;
    const bytearrays = [];

    for (let offset = 0; offset < Uint8ArrayContent.length; offset += slicesize) {
      const slice = Uint8ArrayContent.slice(offset, offset + slicesize);
      bytearrays[bytearrays.length] = slice;
    }
    return new Blob(bytearrays, { type: mimetype });
  }

  static showBookingStatus(status) {
    switch (status) {
      case 'CONF': return 'CONFIRMED';
      case 'NOTCONF': return 'NOT CONFIRMED';
      case 'CANCEL': return 'CANCELLED';
      case 'CANCELCONF': return 'CancelConf';
      case 'CANCELCHARGE': return 'CancelCharge';
      case 'OnHold': return 'ON HOLD';
      case 'RESERVED': return 'RESERVED';
    }
  }
}
