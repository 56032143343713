import { Directive, Renderer2, ElementRef, AfterViewInit, OnDestroy, ContentChildren, QueryList } from '@angular/core';
import { FormControlName, FormGroupDirective } from '@angular/forms';
import { Utilities } from '../../../../common/utilities/utilities';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  private originFormControlNameNgOnChanges;
  private valueChangesSubscriptions = [];
  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  @ContentChildren(FormGroupDirective) formGroupDirectiveContentChildren: QueryList<FormGroupDirective>;

  ngAfterViewInit() {
    this.originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
    const thisObject = this;
    const inputControls = this.elementRef.nativeElement.querySelectorAll('.mat-input-element');
    const dropdownControls = this.elementRef.nativeElement.querySelectorAll('.mat-select');
    if (!Utilities.isNullOrEmpty(this.formGroupDirectiveContentChildren) &&
      !Utilities.isNullOrEmpty(this.formGroupDirectiveContentChildren.first) &&
      !Utilities.isNullOrEmpty(this.formGroupDirectiveContentChildren.first.directives) &&
      this.formGroupDirectiveContentChildren.first.directives.length > 0) {
      this.formGroupDirectiveContentChildren.first.directives.forEach(element => {
        if (element.disabled && this.isNativeElementInput(element.valueAccessor)) {
          const valueChangesSubscription = element.valueChanges.subscribe(data => {
            if ((element.valueAccessor as any)._elementRef.nativeElement.type !== 'password') {
              const value = Utilities.isNullOrEmpty((element.valueAccessor as any)._elementRef.nativeElement.value) ? '' :
                (element.valueAccessor as any)._elementRef.nativeElement.value;
              (element.valueAccessor as any)._elementRef.nativeElement.title = value;
            }
          });
          this.valueChangesSubscriptions.push(valueChangesSubscription);
        } else if (element.disabled && this.isNativeElementAutoComplete(element.valueAccessor)) {
          const valueChangesSubscription = element.valueChanges.subscribe(data => {
            if ((element.valueAccessor as any)._element.nativeElement.type !== 'password') {
              let value = '';
              if (!Utilities.isNullOrEmpty(data) && !Utilities.isNullOrEmpty((element.valueAccessor as any).autocomplete) &&
                !Utilities.isNullOrEmpty((element.valueAccessor as any).autocomplete.displayWith)) {
                value = (element.valueAccessor as any).autocomplete.displayWith(data);
              } else {
                value = Utilities.isNullOrEmpty((element.valueAccessor as any)._element.nativeElement.value) ? '' :
                  (element.valueAccessor as any)._element.nativeElement.value;
              }
              (element.valueAccessor as any)._element.nativeElement.title = value;
            }
          });
          this.valueChangesSubscriptions.push(valueChangesSubscription);
        }
      });
    }

    inputControls.forEach(element => {
      if (element.type !== 'password') {
        this.renderer.listen(element, 'mouseenter', (event) => { this.onMouseHover(event.target); });
      }
    });

    dropdownControls.forEach(element => {
      this.renderer.listen(element, 'mouseenter', (event) => { this.onMouseHover(event.target); });
    });

    FormControlName.prototype.ngOnChanges = function () {
      // Call the FormControlName's origin ngOnChanges method (i.e. base method) first
      const result = thisObject.originFormControlNameNgOnChanges.apply(this, arguments);
      if (thisObject.isNativeElementInput(this.valueAccessor) || thisObject.isNativeElementMatSelect(this.valueAccessor)) {
        if ((this.valueAccessor as any)._elementRef.nativeElement.type !== 'password') {
          const value = Utilities.isNullOrEmpty(this.value) ? '' : this.value;
          (this.valueAccessor as any)._elementRef.nativeElement.title = value;
          thisObject.renderer.listen((this.valueAccessor as any)._elementRef.nativeElement, 'mouseenter', (event) => {
            thisObject.onMouseHover(event.target);
          });
        }
      }
      return result;
    };
  }

  isNativeElementNullOrEmpty(controlValueAccessor: any) {
    return Utilities.isNullOrEmpty(controlValueAccessor) || Utilities.isNullOrEmpty(controlValueAccessor._elementRef) ||
      Utilities.isNullOrEmpty(controlValueAccessor._elementRef.nativeElement);
  }

  isNativeElementOfAutoCompleteNullOrEmpty(controlValueAccessor: any) {
    return Utilities.isNullOrEmpty(controlValueAccessor) || Utilities.isNullOrEmpty(controlValueAccessor._element) ||
      Utilities.isNullOrEmpty(controlValueAccessor._element.nativeElement);
  }

  isNativeElementInput(controlValueAccessor: any) {
    return !this.isNativeElementNullOrEmpty(controlValueAccessor) && controlValueAccessor._elementRef.nativeElement.localName === 'input';
  }

  isNativeElementMatSelect(controlValueAccessor: any) {
    return !this.isNativeElementNullOrEmpty(controlValueAccessor) &&
      (controlValueAccessor as any)._elementRef.nativeElement.localName === 'mat-select';
  }

  isNativeElementAutoComplete(controlValueAccessor: any) {
    return !this.isNativeElementOfAutoCompleteNullOrEmpty(controlValueAccessor) &&
      controlValueAccessor._element.nativeElement.localName === 'input';
  }

  onMouseHover(target) {
    if (target.localName === 'input') {
      target.title = target.value;
    } else if (target.localName === 'mat-select') {
      target.title = target.innerText.trim();
    }
  }

  ngOnDestroy() {
    FormControlName.prototype.ngOnChanges = this.originFormControlNameNgOnChanges;
    if (!Utilities.isNullOrEmpty(this.valueChangesSubscriptions)) {
      this.valueChangesSubscriptions.forEach(element => {
        element.unsubscribe();
      });
    }
  }
}
