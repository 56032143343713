import { Utilities } from 'src/app/common/utilities/utilities';

export class AgencySearchInfoSyncValidators {
  static agencyValidator = function(control) {
    let isControlValueNullOrEmpty = false;
    let isAgencyCodeNullOrEmpty = false;
    if (Utilities.isNullOrEmpty(control.value)) {
      isControlValueNullOrEmpty = true;
    } else if (Utilities.isNullOrEmpty(control.value.code)) {
      isAgencyCodeNullOrEmpty = true;
    }
    return isControlValueNullOrEmpty ? { 'required': true } : isAgencyCodeNullOrEmpty ? { 'validAgency': true } : null;
  };

  static agencyBranchValidator = function(control) {
    let isControlValueNullOrEmpty = false;
    let isAgencyBranchCodeNullOrEmpty = false;
    if (Utilities.isNullOrEmpty(control.value)) {
      isControlValueNullOrEmpty = true;
    } else if (Utilities.isNullOrEmpty(control.value.id)) {
      isAgencyBranchCodeNullOrEmpty = true;
    }
    return isControlValueNullOrEmpty ? { 'required': true } : isAgencyBranchCodeNullOrEmpty ? { 'validAgencyBranch': true } : null;
  };

  static agentValidator = function(control) {
    let isControlValueNullOrEmpty = false;
    let isAgentCodeNullOrEmpty = false;
    if (Utilities.isNullOrEmpty(control.value)) {
      isControlValueNullOrEmpty = true;
    } else if (Utilities.isNullOrEmpty(control.value.id)) {
      isAgentCodeNullOrEmpty = true;
    }
    return isControlValueNullOrEmpty ? { 'required': true } : isAgentCodeNullOrEmpty ? { 'validAgent': true } : null;
  };
}
