import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utilities } from 'src/app/common/utilities/utilities';
import { AgencyInfoViewModel } from 'src/app/common/viewmodels/agencyprofile/agencyinfoviewmodel';
import { AgencyListViewModel } from '../../../viewmodels/agency-mgmt/agencylistviewmodel';
import { CreditLimitViewModel } from 'src/app/common/viewmodels/agencyprofile/creditlimitviewmodel';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgencyProfileDataService {

  _agencyName: any;
  _isMGPointApplicable: any;
  private updateSubject = new Subject<void>();

  updateObservable$: Observable<void> = this.updateSubject.asObservable();

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }

  isAgencyCodeExists(agencyCode: string, agencyId: number): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi,
      'api/Agency/IsAgencyCodeAlreadyExists?agencyCode=' + agencyCode + '&agencyId=' + agencyId).pipe(
        map((data) => {
          let agencyListDto: boolean;
          if (data !== null) {
            agencyListDto = data.isError;
          }
          return agencyListDto;
        }));
  }

  isContactEmailAddressExists(emailId: string, agencyId: number): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Agency/IsAgencyContactsEmailAlreadyExists?emailId=' + emailId + '&agencyId=' + agencyId).pipe(map(data => {
        let agencyListDto: boolean;
        if (data !== null) {
          agencyListDto = data.isError;
        }
        return agencyListDto;
      }));
  }

  isAgentUserEmailIdAlreadyTaken(emailId: string, agecnyCode: string) {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/GetAgentByEmailId?emailId=' + emailId).pipe(map(data => {
        if (data !== null && !Utilities.isNullOrEmpty(agecnyCode) && data.agencyCode !== agecnyCode) {
          return true;
        } else {
          return false;
        }
      }));
  }

  isUserNameExistsForSelectedAgency(userName: string, agencyId: number, agentId: string): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/IsUserNameExistsForSelectedAgency?userName=' + userName + '&agencyId=' + agencyId + '&agentId=' + agentId);
  }

  isUserNameExistsForAgency(userName: string, agencyId: number): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/IsUserNameExistsForAgency?userName=' + userName + '&agencyId=' + agencyId);
  }

  getById(agencyId: number): Observable<AgencyInfoViewModel> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Agency/GetById?agencyId=' + agencyId).pipe(map(data => {
        let agencyListDto: AgencyInfoViewModel;
        if (data !== null) {
          agencyListDto = data.result;
        }
        return agencyListDto;
      }));
  }

  updateAgencyDetails(agencyInfo: AgencyInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , JSON.stringify(agencyInfo), 'api/Agency/UpdateAgencyDetails');
  }
  getAgencyByCode(code: string): Observable<AgencyListViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Agency/Search?code=' + code).pipe(map(data => {
        let agencyListDto: AgencyListViewModel[] = [];
        if (data !== null) {
          agencyListDto = data.result;
        }
        return agencyListDto;
      }));
  }

   getCreditById(agencyId: number): Observable<CreditLimitViewModel> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , agencyId, 'api/Agency/GetAgencyCreditLimit').pipe(map((data: any) => {
     let agencyCreditDto: CreditLimitViewModel;
       if (data !== null) {
        agencyCreditDto =  data.result[0];
       }
        return agencyCreditDto;
     }));
  }

  getCurrencyExchangeRate(selectedCurrency: string, defaultCurrency: string): Observable<any> {
    const currencyDetails = {
      sourceCurrencyCode : selectedCurrency,
      targetCurrencyCode : defaultCurrency
    };
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      ,  currencyDetails, 'api/Agency/GetExchangeRate').pipe(map(data => {
        if (data !== null) {
          return data;
        } else {
          return '';
        }
      }));
  }

  // getCreditById(agencyId: number): Observable<CreditLimitViewModel> {
  //   return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
  //     , 'api/Agency/GeyAgencyCreditLimit?agencyId=' + agencyId).pipe(map(data => {
  //       let agencyCreditDto: CreditLimitViewModel;
  //       if (data !== null) {
  //         agencyCreditDto = data.result[0];
  //       }
  //       return agencyCreditDto;
  //     }));
  // }
  get agencyName(): any {
    return this._agencyName;
  }

  set agencyName(agencyName: any) {
    this._agencyName = agencyName;
  }

  get isMGPointApplicable(): any {
    return this._isMGPointApplicable;
  }

  set isMGPointApplicable(isMGPointApplicable: any) {
    this._isMGPointApplicable = isMGPointApplicable;
  }


  triggerUpdate(): void {
    this.updateSubject.next();
  }

}
