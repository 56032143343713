import { HotelListAndFilterViewModel } from './hotellistfilterviewmodel';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


@Injectable({
    providedIn: 'root'

})
// Todo: To remove later
export class TempHotelService {
    /* getTempHotelListAndFilter(): HotelListAndFilterViewModel {
        return {
            'hotelFilter': {
                'hotelName': '',
                'fromPrice': 5338008,
                'toPrice': 51654157,
                'starRatings': [
                    1, 2, 3, 4
                ],
                'promotions': [
                    {
                        'promotionId': 1,
                        'promotionType': 'Basic Deal',
                        'promotionCode': 'BSD'
                    },
                    {
                        'promotionId': 2,
                        'promotionType': 'Deal of the Day',
                        'promotionCode': 'DOD'
                    }
                ],
                'areas': [
                    {
                        'areaId': 1,
                        'area': 'Kharadi'
                    },
                    {
                        'areaId': 2,
                        'area': 'Aundh'
                    }
                ],
                'chains': [
                    {
                        'chainId': 1,
                        'chain': 'Accor'
                    },
                    {
                        'chainId': 2,
                        'chain': 'Barcelo Group'
                    }
                ],
                'landmarks': [
                    {
                        'landmarkId': 1,
                        'landmark': 'Behind Poona Station'
                    },
                    {
                        'landmarkId': 2,
                        'landmark': 'Behind Sports Ground'
                    }
                ],
                'facilities': [
                    {
                        'code' : 'IsWheelchair',
                        'name' : 'Wheelchair',
                        'imagePath': null
                    },
                    {
                        'code' : 'IsGym',
                        'name' : 'Gym',
                        'imagePath': null
                    },
                    {
                        'code' : 'IsExtrabed',
                        'name' : 'Extrabed Allowed',
                        'imagePath': null
                    },
                    {
                        'code' : 'IsSafety',
                        'name' : 'Safety',
                        'imagePath': null
                    }
                    
                ]
            },
            'hotelList': [
                {
                    'hotelCode': 'ID10003709',
                    'hotelName': 'Hilton Garden Inn Singapore Serangoon',
                    'hotelDescription': ' This four-star hotel can be reached only 20-minute drive from Ngurah Rai International Airport, 5 minutes from Bali International Convention Centre. The hotel is only 300 meters away from Nusa Dua beach, where guests can practice water sports while enjoying the breath-taking panorama on the island of gods.',
                    'address': '80363 Nusa Dua, Bali, Indonesia',
                    'mgPoints': 10,
                    'isMgPreferred': true,
                    'starRating': '4',
                    'areaId': 1,
                    'landmarkId': 1,
                    'chainId': 1,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'images': null,
                    'facilities': [
                        {
                            'code': 'IsWheelchair',
                            'name': 'Wheelchair',
                            'imagePath': 'assets/images/icons/wifi.png'
                        },
                    
                    ],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3640_3',
                            'roomName': 'DELUXE CITY VIEW',
                            'roomDetails': [
                                {
                                    'promotion': 'BSD',
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': 'Free Cancellation (100% refund) if you cancel this booking before 25 Dec, 2019',
                                    'grossPrice': 5338009.34,
                                    'priceDetails': [
                                        {
                                            'roomCode': 'Room 1', // ToDO : Not Needed
                                            'nights': 4,
                                            'guestDetails': '2',
                                            'currencyCode': 'IDR',
                                            'avgPricePerNight': 195750,
                                            'grossPrice': 53000
                                        },
                                        {
                                            'roomCode': 'Room 2',
                                            'nights': 4,
                                            'guestDetails': '2',
                                            'currencyCode': 'IDR',
                                            'avgPricePerNight': 1975750,
                                            'grossPrice': 5030120
                                        }
                                    ],
                                    'isAvailable': true
                                },
                                {
                                    'promotion': 'DOD',
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': 'Free Cancellation (100% refund) if you cancel this booking before 26 Dec, 2019',
                                    'grossPrice': 51654156.34,
                                    'priceDetails': [
                                        {
                                            'roomCode': 'Room 1',
                                            'nights': 4,
                                            'guestDetails': '2',
                                            'currencyCode': 'IDR',
                                            'avgPricePerNight': 1925750,
                                            'grossPrice': 52500
                                        },
                                        {
                                            'roomCode': 'Room 2',
                                            'nights': 4,
                                            'guestDetails': '2',
                                            'currencyCode': 'IDR',
                                            'avgPricePerNight': 19545750,
                                            'grossPrice': 512100
                                        }
                                    ],
                                    'isAvailable': false
                                }
                            ],
                        },
                        {
                            'roomCode': 'RM_3640_4',
                            'roomName': 'GUEST ROOM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': 'Free Cancellation (100% refund) if you cancel this booking before 27 Dec, 2019',
                                    'grossPrice': 5338009.34,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3640_5',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': 'Free Cancellation (100% refund) if you cancel this booking before 28 Dec, 2019',
                                    'grossPrice': 5338009.34,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'ID10003709',
                    'hotelName': 'Summer View Hotel',
                    'hotelDescription': null,
                    'address': 'Hilton Garden Inn Singapore Serangoon',
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'starRating': '0.5',
                    'areaId': 1,
                    'landmarkId': 1,
                    'chainId': 1,
                    'longitude': 101.688843,
                    'latitude': 3.134629,
                    'images': null,
                    'facilities': [
                        {
                            'code': 'IsWheelchair',
                            'name': 'Wheelchair',
                            'imagePath': 'assets/images/icons/wifi.png'
                        },
                       
                    ],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3675_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'ID10003709',
                    'hotelName': 'Hotel 81 Gold',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 10,
                    'isMgPreferred': true,
                    'starRating': '1',
                    'areaId': 1,
                    'landmarkId': 1,
                    'chainId': 1,
                    'longitude': 73.956909,
                    'latitude': 18.276862,
                    'images': null,
                    'facilities': [
                        {
                            'code': 'IsWheelchair',
                            'name': 'Wheelchair',
                            'imagePath': 'assets/images/icons/bed.png'
                        },
                        
                    ],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3677_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'ID10003709',
                    'hotelName': 'Goodwood Park Singapore City',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'longitude': 73.868029,
                    'latitude': 18.305751,
                    'isMgPreferred': true,
                    'starRating': '1.5',
                    'images': null,
                    'facilities': [
                        {
                            'code': 'IsWheelchair',
                            'name': 'Wheelchair',
                            'imagePath': 'assets/images/icons/wifi.png'
                        },
                        
                    ],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3682_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'ID10003709',
                    'hotelName': 'Parc Sovereign Hotel - Tyrwhitt',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4512_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.60,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4512_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'ID10003709',
                    'hotelName': 'Hotel Grand Central',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2.5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4543_1',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.56,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4543_2',
                            'roomName': 'EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.56,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4543_1',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4543_2',
                            'roomName': 'EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'ID10003709',
                    'hotelName': 'Marina Mandarin',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4813_5',
                            'roomName': 'PREMIER PROMOTION',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_6',
                            'roomName': 'DELUXE PROMOTION',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_7',
                            'roomName': 'MERITUS CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_8',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_9',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_5',
                            'roomName': 'PREMIER PROMOTION',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_6',
                            'roomName': 'DELUXE PROMOTION',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_7',
                            'roomName': 'MERITUS CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_8',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_9',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_5',
                            'roomName': 'PREMIER PROMOTION',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_6',
                            'roomName': 'DELUXE PROMOTION',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_7',
                            'roomName': 'MERITUS CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_8',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4813_9',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000451',
                    'hotelName': 'Fragrance Balestier',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3.5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5029_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5029_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000588',
                    'hotelName': 'Concorde Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5234_1',
                            'roomName': 'DELUXE BALCONY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_2',
                            'roomName': 'PREMIER CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_3',
                            'roomName': 'DELUXE PLUS BALCONY WITH BREAKFAST',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_4',
                            'roomName': 'PREMIER SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_5',
                            'roomName': 'DELUXE PLUS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_6',
                            'roomName': 'DELUXE NON REFUNDABLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_7',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_1',
                            'roomName': 'DELUXE BALCONY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_2',
                            'roomName': 'PREMIER CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_3',
                            'roomName': 'DELUXE PLUS BALCONY WITH BREAKFAST',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_4',
                            'roomName': 'PREMIER SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_5',
                            'roomName': 'DELUXE PLUS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_6',
                            'roomName': 'DELUXE NON REFUNDABLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5234_7',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000619',
                    'hotelName': 'Somerset Liang Court Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4.5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5265_2',
                            'roomName': '1 Bedroom Premier',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5265_3',
                            'roomName': 'STUDIO EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5265_4',
                            'roomName': '3 BEDROOM PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5265_5',
                            'roomName': '2 BEDROOM PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5265_6',
                            'roomName': '1 Bedroom Executive',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000109',
                    'hotelName': 'Hotel 81 (Premier) Hollywood',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3671_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000111',
                    'hotelName': 'V Lavender',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '5.5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3673_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3673_2',
                            'roomName': 'TRIPLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3673_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000116',
                    'hotelName': 'Klapstar Boutique Hotel',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '6',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3678_1',
                            'roomName': 'OASIS SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3678_2',
                            'roomName': 'THE EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3678_3',
                            'roomName': 'THE OASIS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3678_4',
                            'roomName': 'KLAPSTAR SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000250',
                    'hotelName': 'Ascott Orchard Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '6.5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4530_10',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4530_7',
                            'roomName': 'ONE BEDROOM DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4530_8',
                            'roomName': 'STUDIO EXECUTIVE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4530_9',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4530_10',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4530_7',
                            'roomName': 'ONE BEDROOM DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4530_8',
                            'roomName': 'STUDIO EXECUTIVE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4530_9',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000275',
                    'hotelName': 'Ritz Carlton Millenia',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '7',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4555_2',
                            'roomName': 'DELUXE MARINA',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4555_3',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4555_4',
                            'roomName': 'PREMIER SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4555_5',
                            'roomName': 'DELUXE KALLANG',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4555_6',
                            'roomName': 'GRAND KALLANG',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4555_7',
                            'roomName': 'GRAND MARINA',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000286',
                    'hotelName': 'Parc Sovereign Hotel - Albert',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '7.5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4791_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4791_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4791_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000415',
                    'hotelName': 'Kam Leng Hotel',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': true,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '8',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4993_2',
                            'roomName': 'SUPERIOR QUEEN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000547',
                    'hotelName': 'Hotel 81 Selegie',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5193_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000550',
                    'hotelName': 'Park Hotel Farrer Park',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5196_2',
                            'roomName': 'CRYSTAL CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5196_3',
                            'roomName': 'PREMIER LOFT',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5196_4',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5196_5',
                            'roomName': 'SKY LOFT',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000570',
                    'hotelName': 'Fragrance Crystal',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5216_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5216_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5216_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000579',
                    'hotelName': 'Aqueen Hotel Paya Lebar',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5225_2',
                            'roomName': 'SUPERIOR QUEEN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5225_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000581',
                    'hotelName': 'Days Singapore at Zhongshan Park',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5227_3',
                            'roomName': 'DAYS CITY VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5227_4',
                            'roomName': 'DAYS PARK VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000586',
                    'hotelName': 'Jen Orchardgateway Singapore by Shangri-La',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5232_1',
                            'roomName': 'SUPERIOR CITY VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5232_2',
                            'roomName': 'CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5232_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000591',
                    'hotelName': 'Hotel Chancellor @ Orchard',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5237_1',
                            'roomName': 'DELUXE TRIPLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5237_2',
                            'roomName': 'PREMIER TRIPLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5237_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5237_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5237_1',
                            'roomName': 'DELUXE TRIPLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5237_2',
                            'roomName': 'PREMIER TRIPLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5237_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5237_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000595',
                    'hotelName': 'Ramada Singapore at Zhongshan Park',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5241_1',
                            'roomName': 'RAMADA PARK VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5241_2',
                            'roomName': 'RAMADA CITY VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000611',
                    'hotelName': 'Quincy Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5257_1',
                            'roomName': 'STUDIO',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000643',
                    'hotelName': 'Genting Hotel Jurong',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5289_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5289_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5289_3',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000121',
                    'hotelName': 'Elizabeth Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3683_1',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3683_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3683_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3683_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000224',
                    'hotelName': 'Hotel 81 Tristar',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4504_1',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000241',
                    'hotelName': 'The Porcelain Hotel',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4521_3',
                            'roomName': 'SUPERIOR QUEEN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4521_4',
                            'roomName': 'STANDARD QUEEN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000282',
                    'hotelName': 'Orchard Parksuites',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '0',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4787_8',
                            'roomName': '1 Bedroom Deluxe Loft',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000283',
                    'hotelName': 'Pan Pacific Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4788_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000315',
                    'hotelName': 'Peninsula Excelsior',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4820_1',
                            'roomName': 'CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4820_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4820_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4820_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000442',
                    'hotelName': 'Hotel 81 Osaka',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5020_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000443',
                    'hotelName': 'Aqueen Hotel Balestier',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5021_2',
                            'roomName': 'STANDARD QUEEN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000461',
                    'hotelName': 'Hotel G Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3.5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5039_1',
                            'roomName': 'GREAT KING',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5039_2',
                            'roomName': 'GOOD ROOM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5039_1',
                            'roomName': 'GREAT KING',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5039_2',
                            'roomName': 'GOOD ROOM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000543',
                    'hotelName': 'Aqueen Heritage Hotel Joo Chiat',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5189_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5189_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000558',
                    'hotelName': 'Venue Hotel',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5204_1',
                            'roomName': 'STANDARD',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000559',
                    'hotelName': 'Hotel 81 Dickson',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5205_1',
                            'roomName': 'STANDARD',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000576',
                    'hotelName': 'Fragrance Ruby',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'Room_5222_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'Room_5222_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'Room_5222_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000640',
                    'hotelName': 'Ascott Raffles Place',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5286_1',
                            'roomName': 'CUTLER SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5286_2',
                            'roomName': 'COLLYER SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5286_3',
                            'roomName': 'BROWN SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5286_4',
                            'roomName': 'FINLAYSON SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5286_5',
                            'roomName': 'MALCOLM SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5286_6',
                            'roomName': 'ELIZABETH SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5286_7',
                            'roomName': 'CUTLER SUITE WITH BALCONY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000108',
                    'hotelName': 'Fragrance Pearl',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3670_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3670_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000117',
                    'hotelName': 'Hotel 81 Geylang',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3679_1',
                            'roomName': 'ROH',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000119',
                    'hotelName': 'Jen Tanglin By Shangri-La',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3681_4',
                            'roomName': 'SUPERIOR TWIN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3681_5',
                            'roomName': 'DELUXE TWIN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000260',
                    'hotelName': 'Fragrance Oasis',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4540_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000423',
                    'hotelName': 'Hotel 81 Changi',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5001_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000457',
                    'hotelName': 'Orchard Scotts Residences',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5035_8',
                            'roomName': 'ONE BEDROOM DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000603',
                    'hotelName': 'Fragrance Ocean View',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,
                    'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5249_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5249_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000648',
                    'hotelName': 'Hotel 81 Kovan',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5294_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 8540812.55,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000126',
                    'hotelName': 'Hilton Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3688_1',
                            'roomName': 'DELUXE PLUS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3688_2',
                            'roomName': 'PREMIUM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3688_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3688_4',
                            'roomName': 'EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000162',
                    'hotelName': 'Fragrance Royal',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4442_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4442_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4442_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000214',
                    'hotelName': 'Aqueen Heritage Hotel Little India',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '3',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4494_2',
                            'roomName': 'COURTYARD',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4494_3',
                            'roomName': 'COMFY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000244',
                    'hotelName': 'Festive by Resort World Sentosa',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4524_2',
                            'roomName': 'DELUXE GARDEN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4524_3',
                            'roomName': 'DELUXE POOL ACCESS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4524_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4524_5',
                            'roomName': 'DELUXE FAMILY KING',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4524_2',
                            'roomName': 'DELUXE GARDEN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4524_3',
                            'roomName': 'DELUXE POOL ACCESS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4524_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4524_5',
                            'roomName': 'DELUXE FAMILY KING',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000248',
                    'hotelName': 'Value Thomson',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '3',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4528_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000279',
                    'hotelName': 'Destination Singapore Beach Road',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '3',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4559_1',
                            'roomName': 'TRIPLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4559_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4559_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000290',
                    'hotelName': 'The Inn at Temple Street',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '3',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4795_1',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4795_2',
                            'roomName': 'STANDARD',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4795_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000312',
                    'hotelName': 'Grand Park City Hall',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4817_1',
                            'roomName': 'CRYSTAL CLUB DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4817_2',
                            'roomName': 'CRYSTAL CLUB PREMIER ROOM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4817_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4817_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000412',
                    'hotelName': 'One Farrer and Spa',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4990_1',
                            'roomName': 'SKYLINE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4990_2',
                            'roomName': 'URBAN',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000433',
                    'hotelName': 'Fragrance Emerald',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5011_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5011_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5011_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000450',
                    'hotelName': 'Grand Hyatt Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5028_1',
                            'roomName': 'GRAND',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5028_2',
                            'roomName': 'GRAND DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5028_1',
                            'roomName': 'GRAND',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5028_2',
                            'roomName': 'GRAND DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000551',
                    'hotelName': 'Fragrance Joo Chiat',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5197_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5197_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5197_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000566',
                    'hotelName': 'Fragrance Sunflower',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '2',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5212_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5212_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000613',
                    'hotelName': 'Regent Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5259_1',
                            'roomName': 'FOUR SEASON EXECUTIVE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5259_1',
                            'roomName': 'FOUR SEASON EXECUTIVE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000616',
                    'hotelName': 'Park Hotel Clarke Quay',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5262_2',
                            'roomName': 'CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5262_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5262_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000617',
                    'hotelName': 'Village Hotel Changi (ex Changi Village Hotel)',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5263_1',
                            'roomName': 'CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5263_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5263_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000630',
                    'hotelName': 'York Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5276_1',
                            'roomName': 'DELUXE FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5276_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5276_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000635',
                    'hotelName': 'Marina Bay Sands',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5281_1',
                            'roomName': 'DELUXE GARDEN VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5281_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5281_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5281_4',
                            'roomName': 'PREMIER GARDEN VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000082',
                    'hotelName': 'Fragrance Selegie',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '3',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3644_4',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000107',
                    'hotelName': 'Hard Rock by Resort World Sentosa',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3669_1',
                            'roomName': 'DELUXE POOL VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3669_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3669_3',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3669_1',
                            'roomName': 'DELUXE POOL VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3669_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3669_3',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000132',
                    'hotelName': 'Hotel Mi',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3694_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3694_2',
                            'roomName': 'TRIPLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3694_3',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000252',
                    'hotelName': 'Hotel 81 Heritage',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '2',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4532_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000295',
                    'hotelName': 'Fullerton Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4800_1',
                            'roomName': 'Premier Courtyard',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4800_2',
                            'roomName': 'QUAY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4800_3',
                            'roomName': 'HERITAGE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4800_4',
                            'roomName': 'POSTMASTER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000311',
                    'hotelName': 'Grand Copthorne Waterfront Hotel',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4816_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4816_5',
                            'roomName': 'CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4816_6',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000313',
                    'hotelName': 'Capella Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images': null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4818_6',
                            'roomName': 'PREMIER SEA VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4818_7',
                            'roomName': 'ONE BEDROOM GARDEN VILLA',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4818_8',
                            'roomName': 'PREMIER GARDEN VIEW',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000511',
                    'hotelName': 'Oasia Residence Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5157_3',
                            'roomName': 'ONE BEDROOM DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000538',
                    'hotelName': 'Venue Hotel Lily',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '3',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5184_1',
                            'roomName': 'STANDARD',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000584',
                    'hotelName': 'Michael by Resort World Sentosa',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '5',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5230_1',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5230_2',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5230_1',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5230_2',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000608',
                    'hotelName': 'Scarlet a Boutique',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5254_1',
                            'roomName': 'EXECUTIVE BALCONY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5254_2',
                            'roomName': 'PREMIUM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5254_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5254_4',
                            'roomName': 'EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000609',
                    'hotelName': 'Parkroyal on Beach Road',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5255_1',
                            'roomName': 'ORCHID CLUB DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_2',
                            'roomName': 'ORCHID CLUB PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_4',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_5',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_1',
                            'roomName': 'ORCHID CLUB DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_2',
                            'roomName': 'ORCHID CLUB PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_4',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_5',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_1',
                            'roomName': 'ORCHID CLUB DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_2',
                            'roomName': 'ORCHID CLUB PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_4',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5255_5',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000622',
                    'hotelName': 'Park Alexandra',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5268_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5268_2',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelCode': 'SG10000629',
                    'hotelName': 'Orchard Parade Singapore City',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false, 'longitude': 103.85256,                     'latitude': 1.297402,
                    'starRating': '4',
                    'images':null,
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5275_1',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5275_2',
                            'roomName': 'CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5275_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5275_4',
                            'roomName': 'DELUXE PLUS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5275_5',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                }, */
              /*  {
                    'hotelId': 'SG10000055',
                    'hotelName': 'Village Residence West Coast Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3617_5',
                            'roomName': 'ONE BEDROOM DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000106',
                    'hotelName': 'Dorsett Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3668_1',
                            'roomName': 'DORSETT ROOM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_3668_3',
                            'roomName': 'DELUXE ROOM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000257',
                    'hotelName': 'Four Seasons Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '5',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4537_3',
                            'roomName': 'ONE BEDROOM SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4537_4',
                            'roomName': 'BOULEVARD ROOM',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4537_7',
                            'roomName': 'EXECUTIVE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4537_8',
                            'roomName': 'PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4537_9',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000272',
                    'hotelName': 'Citadines Fusionopolis Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4552_1',
                            'roomName': '1 Bedroom Premier Loft',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4552_2',
                            'roomName': '1 Bedroom Deluxe Loft',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4552_3',
                            'roomName': '1 Bedroom Executive Loft',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000274',
                    'hotelName': 'Fragrance Waterfront',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '2',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4554_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4554_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000298',
                    'hotelName': 'Studio M Hotel Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4803_4',
                            'roomName': 'STUDIO LOFT',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4803_5',
                            'roomName': 'PREMIER LOFT',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000407',
                    'hotelName': 'Aqueen Hotel Jalan Besar',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '3',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4985_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4985_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000424',
                    'hotelName': 'Hotel 81 Sakura',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '2',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5002_1',
                            'roomName': 'ROH',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000428',
                    'hotelName': 'Copthorne Kings Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5006_2',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5006_3',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000441',
                    'hotelName': 'Aqueen Hotel Lavender',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '3',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5019_2',
                            'roomName': 'STANDARD',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000444',
                    'hotelName': 'Hotel 81 Chinatown',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '2',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5022_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000458',
                    'hotelName': 'Value Balestier',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '2',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5036_1',
                            'roomName': 'ROH',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000459',
                    'hotelName': 'Oakwood Studios Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5037_2',
                            'roomName': 'STUDIO',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000573',
                    'hotelName': 'Equarius by Resorts World Sentosa',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '5',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5219_1',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5219_1',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000642',
                    'hotelName': 'M Social Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5288_3',
                            'roomName': 'ALCOVE COSY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5288_4',
                            'roomName': 'LOFT GALLERY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000644',
                    'hotelName': 'Village Hotel Katong (ex East Village)',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5290_3',
                            'roomName': 'CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5290_4',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5290_5',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000133',
                    'hotelName': 'Amara Sanctuary Resort Sentosa',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '5',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_3695_5',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000253',
                    'hotelName': 'Amara Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4533_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4533_5',
                            'roomName': 'EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000287',
                    'hotelName': 'Somerset Bencoolen Singapore',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '4',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4792_2',
                            'roomName': '1 Bedroom Premier',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4792_3',
                            'roomName': '3 BEDROOM PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4792_4',
                            'roomName': '2 BEDROOM PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4792_5',
                            'roomName': '1 BEDROOM DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4792_2',
                            'roomName': '1 Bedroom Premier',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4792_3',
                            'roomName': '3 BEDROOM PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4792_4',
                            'roomName': '2 BEDROOM PREMIER',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4792_5',
                            'roomName': '1 BEDROOM DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000310',
                    'hotelName': 'Fragrance Riverside',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '3',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_4815_4',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_4815_5',
                            'roomName': 'EXECUTIVE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000439',
                    'hotelName': 'Fragrance Sapphire',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '2',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5017_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5017_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5017_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000448',
                    'hotelName': 'Fragrance Imperial',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '3',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5026_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5026_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5026_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5026_2',
                            'roomName': 'FAMILY',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5026_3',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5026_4',
                            'roomName': 'DELUXE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000489',
                    'hotelName': 'Crockfords Tower',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '5',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5135_1',
                            'roomName': 'PREMIUM SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5135_2',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5135_1',
                            'roomName': 'PREMIUM SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5135_2',
                            'roomName': 'DELUXE SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000580',
                    'hotelName': 'Hotel 81 Bugis',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '2',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5226_1',
                            'roomName': 'STANDARD',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000612',
                    'hotelName': 'Beach Villas by Resort World Sentosa',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '5',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5258_1',
                            'roomName': 'ONE BEDROOM VILLA',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5258_2',
                            'roomName': 'OCEAN SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5258_3',
                            'roomName': 'TREE TOP LOFTS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5258_1',
                            'roomName': 'ONE BEDROOM VILLA',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5258_2',
                            'roomName': 'OCEAN SUITE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5258_3',
                            'roomName': 'TREE TOP LOFTS',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676014.68,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000453',
                    'hotelName': 'Mandarin Orchard Singapore City',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '5',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5031_4',
                            'roomName': 'MERITUS CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5031_5',
                            'roomName': 'DELUXE NON REFUNDABLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5031_4',
                            'roomName': 'MERITUS CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5031_5',
                            'roomName': 'DELUXE NON REFUNDABLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5031_4',
                            'roomName': 'MERITUS CLUB',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        },
                        {
                            'roomCode': 'RM_5031_5',
                            'roomName': 'DELUXE NON REFUNDABLE',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'BDBF',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                },
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 10676064,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                },
                {
                    'hotelId': 'SG10000445',
                    'hotelName': 'Hotel 81 (Premier) Star',
                    'hotelDescription': null,
                    'address': null,
                    'mgPoints': 0,
                    'isMgPreferred': false,
                    'starRating': '2',
                    'images': [{ 'isPrimary': true, 'imagePath': 'http://172.27.127.102:7777//MGDocuments/B2B/DestinationBanners/BaliHotel.jpg' }],
                    'facilities': [],
                    'roomCategoryInfo': [
                        {
                            'roomCode': 'RM_5023_1',
                            'roomName': 'SUPERIOR',
                            'roomDetails': [
                                {
                                    'promotion': null,
                                    'mealType': 'RO',
                                    'cancellationPolicyId': 0,
                                    'cancellationPolicy': null,
                                    'grossPrice': 11076004,
                                    'priceDetails': [],
                                    'isAvailable': false
                                }
                            ]
                        }
                    ]
                } */
            /* ]
        };
    } */


    /*************************************** */
}



