export class CreditLimitViewModel {
    id: number;
    agencyId: number;
    creditAmount: number;
    creditLimitBalance: number;
    creditLimitConsumed: number;
    emergencyCreditLimit: number;
    createdBy: string;
    updatedBy: string;
  }
  