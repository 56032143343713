export class PropertyResolver {
    static resolve(path: string, obj: any) {
     return path.split('.').reduce((prev, curr) => {
        //  console.log('Prev' + JSON.stringify(prev));
        //  console.log('Curr' + JSON.stringify(curr));
        //  console.log('Type Of Prev' + Array.isArray(prev));
         /* if (Array.isArray(prev)) {
             this.resolve(curr, prev);
         } */
         return (prev ? prev[curr] : undefined);
     }, obj || self);
   }
}
