import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material';

@Injectable()

export class DatePickerDateAdapterService extends NativeDateAdapter {

  isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  format(date: Date, displayFormat: Object): string {
    date.setHours(0, 0, 0, 0);
    date.setMinutes((date.getTimezoneOffset() * -1));
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.toLocaleString('en-us', { month: 'long' });
      const year = date.getFullYear();
      return this.getTwoDigitDay(day) + '-' + (this.isIEOrEdge ? month.substring(0, 4) : month.substring(0, 3)) + '-' + year;
    } else {
      return date.toDateString();
    }
  }

  private getTwoDigitDay(n: number) {
    return ('00' + n).slice(-2);
  }
}
