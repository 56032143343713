import { Injectable } from '@angular/core';
import { HotelRecheckSearchViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelrechecksearchviewmodel';
import { RoomDetailViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelviewmodel';
import { HotelSearchService } from './hotel-search.service';
import { HotelSearchPanelViewModel, RoomDetailsViewModel } from '../viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';
import { HotelDataService } from './hotel-data.service';
import { HotelRecheckViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelrecheckviewmodel';

@Injectable({
  providedIn: 'root'
})
export class HotelRecheckService {
  private _recheckSearchDetails: HotelRecheckSearchViewModel = <HotelRecheckSearchViewModel>{};
  private _hotelRecheckViewModel: HotelRecheckViewModel = <HotelRecheckViewModel>{};
  constructor(private hotelSearchService: HotelSearchService,
    private hotelDataService: HotelDataService) { }

  get recheckSearchDetails(): HotelRecheckSearchViewModel {
    return this._recheckSearchDetails;
  }

  set recheckSearchDetails(recheckSearchDetails: HotelRecheckSearchViewModel) {
    this._recheckSearchDetails = recheckSearchDetails;
  }

  get hotelRecheckDetails(): HotelRecheckViewModel {
    return this._hotelRecheckViewModel;
  }

  set hotelRecheckDetails(hotelRecheckViewModel: HotelRecheckViewModel) {
    this._hotelRecheckViewModel = hotelRecheckViewModel;
  }

  mapSelectedRoomDetails(roomDetailViewModel: RoomDetailViewModel, roomCode: string,
    hotelCode: string) {
    const hotelRecheckSearchViewModel: HotelRecheckSearchViewModel = new HotelRecheckSearchViewModel();
    const hotelSearch = this.hotelSearchService.destinationSearchDetails;
    hotelRecheckSearchViewModel.basicSearch = new HotelSearchPanelViewModel();
    hotelRecheckSearchViewModel.basicSearch.destination = hotelSearch.destination;
    hotelRecheckSearchViewModel.basicSearch.destination.hotelCode = hotelCode;
    hotelRecheckSearchViewModel.basicSearch.guestNationalitycode = hotelSearch.guestNationalitycode;
    hotelRecheckSearchViewModel.basicSearch.checkInCheckOutDate = hotelSearch.checkInCheckOutDate;
    hotelRecheckSearchViewModel.sessionId = this.hotelDataService.sessionId;
    hotelRecheckSearchViewModel.basicSearch.currencyCode = hotelSearch.currencyCode;

    hotelRecheckSearchViewModel.isAvailable = roomDetailViewModel.isAvailable;
    hotelRecheckSearchViewModel.mealCode = roomDetailViewModel.mealCode;
    hotelRecheckSearchViewModel.cancellationPolicyType = roomDetailViewModel.cancellationPolicyType;
    hotelRecheckSearchViewModel.roomCode = roomCode;
    hotelRecheckSearchViewModel.packageRate = roomDetailViewModel.packageRate;
    const roomDetails: RoomDetailsViewModel[] = new Array<RoomDetailsViewModel>();
    roomDetailViewModel.priceDetails.forEach((pr, index) => {
      const roomDetail: RoomDetailsViewModel = new RoomDetailsViewModel();
      roomDetail.noOfAdults = hotelSearch.roomDetails[index].noOfAdults;
      roomDetail.noOfChildren = hotelSearch.roomDetails[index].noOfChildren;
      roomDetail.roomNumber = hotelSearch.roomDetails[index].roomNumber;
      roomDetail.rateKey = pr.rateKey;
      roomDetail.childAges = hotelSearch.roomDetails[index].childAges;
      roomDetails.push(roomDetail);
    });
    hotelRecheckSearchViewModel.basicSearch.roomDetails = roomDetails;
    this.recheckSearchDetails = hotelRecheckSearchViewModel;

  }
}
