import { HotelSearchPanelViewModel } from '../../common/b2b-shared/viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';

export class HotelRecheckSearchViewModel {
    sessionId: string;
    basicSearch: HotelSearchPanelViewModel;
    isAvailable: boolean;
    mealCode: string;
    cancellationPolicyType: string;
    roomCode: string;
    packageRate: any;
    grossPrice: number;
    mgReservationId: string;
    clientIPAddress: string;
}
