export let ErrorMessages = {
    requiredFieldMessage: 'This field is required',
    invalidEmailMessage: 'Please enter a valid email address',
    invalidPhoneNumberMessage: 'Please enter a valid phone number',
    maxlength150Message: 'Please enter a maximum of 150 characters',
    maxlength50Message: 'Please enter a maximum of 50 characters',
    maxlength15Message: 'Please enter a maximum of 15 characters',
    maxlength500Message: 'Please enter a maximum of 500 characters',
    recaptchaMessage: 'Please confirm that you are not a robot',
    alphaNumErrorMessage: 'Please enter alphanumeric value',
    numericErrorMessage: 'Please enter numeric value',
    invalidWebsiteMessage: 'Please enter a valid website',
    checkoutDateMessage: 'Please select check out date',
    duplicateAgencyCode: 'Legacy agency code already exists.',
    primeryContactDeletionMessage: 'This is a Primary Conatct, Are you sure you want to delete this contact?',
    contactDeletionConfirmationMessage: 'Are you sure you want to delete this contact?',
    duplicateEmailIdMessage: 'Email Id already exists.',
    duplicateUserNameMessage: 'UserName already exists.',
    passwordMismatchErrorMessage: 'New Password and Confirm New Password do not match.',
    passwordNumberErrorMessage: 'Password must have at least one digit (0-9)',
    passwordCaseErrorMessage: 'Password must have at least one upper case letter',
    passwordNonAlphanumErrorMessage: 'Password must have at least one non-alphanumeric character',
    passwordLengthErrorMessage: 'Password must have at least 8 characters.',
    uniqueNameMessage: 'Name already exists. Please enter a unique name',
    noSearchResultsMessage: 'No Search result(s) found.',
    userDeletionMessage: 'The user is deleted successfully',
    userDeletionConfirmationMessage: 'Are you sure you want to delete this user?',
    agentCreationMessage: 'New agent is created successfully',
    agentUpdationMessage: 'The agent is updated successfully',
    branchMessage: 'Please select valid Branch Name from the list',
    agencyMessage: 'Please select valid Agency Name from the list',
    agentMessage: 'Please select valid Agent Name from the list',
    patternMessage: 'Enter valid alphanumeric value',
    noOfBookingMessage: 'Value should be less than 32768',
    requestCreditMax: 'Amount upto 18 digits is allowed',
    requestCreditMin: 'Amount should be greater than 0',
    samePasswordErrorMessage: 'New Password cannot be same as Current Password',
    profileUpdatedMessage: 'User Profile is updated successfully',
    invalidNumberMessage: 'Enter valid number',
    duplicateAgencyReferenceNumber: 'Agency Reference Number already exists',
    duplicateTRNErrorMessage: 'This Transaction Reference Number already exists. Please reenter with a unique value.',
    requiredDestinationMessage: 'Please enter the Destination or a Hotel',
    requiredNationalityMessage: 'Please select the Guest’s Nationality',
    requiredCheckinCheckoutDatesMessage: 'Please select the Stay Period',
    requiredChildAgeMessage: 'Kindly select Child Age',
    invalidNameMessage: 'Enter valid name.',
    invalidDateRangeMessage: 'Date Range cannot be more than 30 Days',
    minlengthForGuestDetails: 'Please enter a minimum of 2 characters',
    maxAllowedLimit: 'Max allowable limit is upto ',
    minAllowedLimit: 'Min allowable limit is ',
    validAmount: 'Please enter valid amount',
    requireDestinationCountry: 'Please select Destination Country.',
    requireCountry: 'Please select Country.',
    requireBrandOrChain: 'Please select valid Brand/Chain',
    branchIdinvalidErrorMessage: 'Please type and select a valid Branch',
    agentIdForFilterinvalidErrorMessage: 'Please type and select a valid Agent Name',
    hotelinvalidErrorMessage: 'Please type and select a valid Hotel Name/Code',
    agencyHasCCPaymentMessage: 'Credit Card payments are not supported.',
    agencyHasOnlyCCPaymentMessage: 'Credit Card payments are not supported. You will not be able to make a booking for this agency.',
    cityIdinvalidErrorMessage: 'Please type and select a valid City.',
    invalidDestinationCountry: 'Please select atleast 1 Destination Country.',
    invalidCityList: 'Please select atleast 1 Destination City.',
    requiredTermsMessage: 'Please read and accept general terms'
};

export let CustomConfirmMessages = {
    changePasswordMessage: 'Do you want to leave this page without changing the password?',
    // tslint:disable-next-line:max-line-length
    confirmMessage:'Do you want to leave this page? All the current data is not saved and will be lost.Choose “LEAVE” to leave this page or “STAY” to stay on the current page.',
    cancelBookingProcess:'If you cancel this booking process, you will not be able to retrieve the details that you have entered. Please click "OK" if you still want to cancel this booking process.'
};

export let CustomConfirmButtonTexts = {
    Yes: 'Yes',
    No: 'No',
    Stay: 'STAY',
    Leave: 'LEAVE',
    StayOnPage: 'Stay on this Page'
};

