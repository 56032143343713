import { FrameworkConfigService } from './../../../../../framework/fw/services/framework-config.service';
import { GoogleAnalyticsService } from './../../../../../common/shared/services/google-analytics.service';
import { GASearchParametersViewModel, GoogleAnalyticsViewModel } from './../../../../../common/viewmodels/googleanalyticsviewmodel';
import { Utilities } from './../../../../../common/utilities/utilities';
import { AuthService } from './../../../../../common/shared/services/auth.service';
import { HotelBookService } from './../../services/hotel-book.service';
import { HotelSearchService } from './../../services/hotel-search.service';
import { Subscription } from 'rxjs';
import { NationalityViewModel } from './../../../../viewmodels/nationalityviewmodel';
import { LookupService } from './../../services/lookup.service';
import { JarvisError } from 'src/app/common/jarviserror';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { HotelSearchPanelViewModel, DestinationViewModel, RoomDetailsViewModel, ChildrenAgeViewModel } from './../../viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';
import { DealsDataService } from './../../services/deals-data.service';
import { HotelViewModel } from './../../../../viewmodels/hotel-mgmt/hotelviewmodel';
import { HotelDataService } from './../../services/hotel-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { SwiperOptions } from 'swiper';
import { ScreenService } from '../../../../../framework/fw/services/screen.service';
import { TopBarService } from '../../../../../framework/fw/services/top-bar.service';

@Component({
  selector: 'app-ngb-carousel',
  templateUrl: './ngb-carousel.component.html',
  styleUrls: ['./ngb-carousel.component.css']
})
export class NgbCarouselComponent implements OnInit {
  ContentUrl = this.configurationService.config.baseUrls.contentUrl;
  @Input() images: any[];
  nationalityList: NationalityViewModel[];
  subscriptions: Subscription[] = [];
  // For Swipe Mobile
  isSmallConfig: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 5,
  };

  constructor(config: NgbCarouselConfig, public configurationService: ConfigurationService,
    public router: Router, public hotelDataService: HotelDataService, public dealsDataService: DealsDataService,
    public currencyApi: CurrencyApi, public lookupService: LookupService, public hotelSearchService: HotelSearchService,
    public hotelBookService: HotelBookService, public activatedRoute: ActivatedRoute,
    public authService: AuthService, public googleAnalyticsService: GoogleAnalyticsService,
    public frameWorkConfigService: FrameworkConfigService, public screenService: ScreenService, private topBarService: TopBarService) {
    config.interval = 4000;
    config.pauseOnHover = false;
  }

  ngOnInit() {
    this.getNationality();
  }

  getNationality() {
    const subscription = this.lookupService.getNationality(0).subscribe((nationalityList) => {
      this.nationalityList = nationalityList;
    }, (error: JarvisError) => {
      console.error(`Error in Getting getBranchList. ${error.friendlyMessage}`);
    }
    );
    this.subscriptions.push(subscription);
  }

  redirection(image) {
    if (this.authService.isLoggedIn()) {
      if (Utilities.isNullOrEmpty(image.agentLoginBannerUrl) && image.hotelName && image.cityName && image.countryName) {
        this.dealsDataService.navigatedFromUnbeatableDeals = false;
        this.dealsDataService.navigatedFromChainDeals = false;
        const todaysDate = new Date();
        const hotelInfo: HotelSearchPanelViewModel = <HotelSearchPanelViewModel>{
          destination: <DestinationViewModel>{},
          checkInCheckOutDate: <Date[]>{},
          roomDetails: <RoomDetailsViewModel[]>{}
        };

        hotelInfo.roomDetails = [];
        hotelInfo.checkInCheckOutDate = [];
        hotelInfo.roomDetails.push(<RoomDetailsViewModel>{ childAges: <ChildrenAgeViewModel[]>{} });
        hotelInfo.roomDetails[0].noOfAdults = 2;
        hotelInfo.roomDetails[0].noOfChildren = 0;
        hotelInfo.roomDetails[0].roomNumber = 1;
        hotelInfo.roomDetails[0].childAges = [];
        hotelInfo.checkInCheckOutDate.push((new Date(todaysDate.setDate(todaysDate.getDate() + 1))));
        hotelInfo.checkInCheckOutDate.push((new Date(todaysDate.setDate(todaysDate.getDate() + 1))));
        hotelInfo.currencyCode = this.currencyApi.selectedCurrency;
        hotelInfo.destination.type = 'Hotel';
        hotelInfo.destination.name = image.hotelName + ', ' + image.cityName + ', ' + image.countryName;
        hotelInfo.destination.hotelCode = image.hotelCode;
        hotelInfo.destination.cityCode = image.cityCode;
        hotelInfo.destination.countryCode = image.countryCode;

        hotelInfo.guestNationalitycode = this.configurationService.config.indonesiaGuestNationalityCode;
        if (this.hotelDataService.creditCardAgencyOnly) {
          hotelInfo.isCreditCardAgencyOnly = true;
        } else {
          hotelInfo.isCreditCardAgencyOnly = false;
        }
        this.hotelSearchService.destinationSearchDetails = hotelInfo;
        this.hotelDataService.selectedHotel = <HotelViewModel>{};
        this.hotelDataService.selectedHotel.hotelId = hotelInfo.destination.hotelCode;
        this.hotelDataService.selectedHotel.hotelName = hotelInfo.destination.name;

        this.hotelBookService.bookFromUnbeatableDeals = false;
        this.hotelBookService.bookFromChainDeals = false;
        sessionStorage.setItem('bookFromBanners', JSON.stringify(true));
        sessionStorage.setItem('bookFromPromotion', JSON.stringify(false));

        // RS
        localStorage.setItem('hotelSearchPanelArray', JSON.stringify(hotelInfo));
        localStorage.setItem('selectedHotel', JSON.stringify(this.hotelDataService.selectedHotel));

        this.nationalityList.forEach((nationality) => {
          if (nationality.code === this.configurationService.config.indonesiaGuestNationalityCode) {
            localStorage.setItem('guestNationalityName', nationality.name);
            this.hotelSearchService.guestNationalityName = nationality.name;
          }
        });

        // ------------- Google Analytics ---------------------------------
        let checkInDate = hotelInfo.checkInCheckOutDate[0].getDate().toString();
        checkInDate = ('0' + checkInDate).slice(-2);
        let checkInMonth = (hotelInfo.checkInCheckOutDate[0].getMonth() + 1).toString();
        checkInMonth = ('0' + checkInMonth).slice(-2);
        const checkInYear = hotelInfo.checkInCheckOutDate[0].getFullYear().toString();

        const GACheckInDate = checkInYear + checkInMonth + checkInDate;
        const googleAnalyticsSearchParameters: GASearchParametersViewModel = {
          city: image.cityName,
          hotel: image.hotelName,
          totalNoOfRooms: '1',
          nightCount: '1',
          checkInDate: GACheckInDate
        };
        this.googleAnalytics(googleAnalyticsSearchParameters, 'Banners');
        // ------------- Google Analytics ---------------------------------
        localStorage.setItem('exchangeRate', ''+this.topBarService._exchangeRate);
        if (this.frameWorkConfigService.IsCallCenterUser) {
          this.router.navigate(['/authenticated/hotelmgmt/hoteldetails']);
        } else {
          this.router.navigate([], { relativeTo: this.activatedRoute }).then(res => {
            window.open('authenticated/hotelmgmt/hoteldetails', '_blank');
          });
        }
      } else if (!Utilities.isNullOrEmpty(image.agentLoginBannerUrl)) {
        window.open(image.agentLoginBannerUrl, '_blank');
      }
    } else {
      window.open(image.redirectUrl, '_blank');
    }
  }

  googleAnalytics(googleAnalyticsSearchParameters: GASearchParametersViewModel, eventAction: string) {
    // ------------- Search Parameters -----------------
    const googleAnalyticsViewModel: GoogleAnalyticsViewModel = {
      eventCategory: 'Search',
      eventAction: eventAction,
      eventLabel: null,
      eventValue: null
    };
    this.googleAnalyticsService.customSearchEventEmitter(googleAnalyticsSearchParameters, googleAnalyticsViewModel);
    // ------------- Search Parameters Ends -----------------

    // ------------- Search Count -----------------
    // if (googleAnalyticsSearchParameters.hotel !== ' ') {
    const searchCount: GoogleAnalyticsViewModel = {
      eventCategory: 'Search Count',
      eventAction: eventAction,
      eventLabel: null,
      eventValue: null
    };
    this.googleAnalyticsService.eventEmitter(searchCount);
    // }
    // ------------- Search Count ends -----------------

    // ------------- Hotel Browse -------------------------
    if (googleAnalyticsSearchParameters.hotel !== '-') {
      const googleAnalyticsHotelBrowse: GoogleAnalyticsViewModel = {
        eventCategory: 'Hotel',
        eventAction: 'Browse',
        eventLabel: null,
        eventValue: null
      };
      this.googleAnalyticsService.customHotelBookBrowseEventEmitter(googleAnalyticsSearchParameters.hotel, googleAnalyticsHotelBrowse);
    }
    // ------------- Hotel Browse Ends -------------------------
  }

}
