import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CONSTANTS } from 'src/app/common/constants';
import { PhotosDataService } from '../../services/photos-data.service';
import { DialogsService } from '../dialogs.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit, OnDestroy {

  @Input() photoURL: string;
  @Input() disabled: boolean;
  @Output() photoURLChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() photoURLChangeB2B: EventEmitter<string> = new EventEmitter<string>();
  @Input() imgWidth: number;
  @Input() imgHeight: number;

  deactivateButtons: boolean;
  imageremoved: boolean;
  result: any;
  operation: string;
  edit = CONSTANTS.operation.edit;
  create = CONSTANTS.operation.create;
  read = CONSTANTS.operation.read;
  contentUrl = this.configurationService.config.baseUrls.contentUrl;
  subscriptions: Subscription[] = [];

  constructor(public photosDataService: PhotosDataService,
    private dialogsService: DialogsService,
    private configurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.operation = this.activatedRoute.parent.snapshot.params['operation'];
    this.getContentUrl();

    if (this.disabled === true) {
      this.deactivateButtons = true;
    }
  }

  removeimage(): void {
    this.photoURL = null;
    this.photoURLChangeB2B.emit(this.photoURL);
  }

  getContentUrl() {
    this.photosDataService.getContentUrl().subscribe(response => {
      if (response !== null) {
        this.contentUrl = response;
      }
    });
  }

  onUploadPhotoClick() {
    const subscription = this.dialogsService.openPhotoModal(this.photoURL, this.imgWidth, this.imgHeight)
      .subscribe(response => {
        if (response) {
          this.photoURL = this.contentUrl + this.photosDataService.profilePhotoUrl.result;
          this.photoURLChangeB2B.emit(this.photosDataService.profilePhotoUrl.result);
        }
      });
      this.subscriptions.push(subscription);
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }

}
