import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LookupService } from './lookup.service';
import { Observable } from 'rxjs';
import { CountryViewModel } from '../../../../common/viewmodels/countryviewmodel';
import { SalutationViewModel } from '../../../viewmodels/registration-forms/salutationviewmodel';
import { StarRatingViewModel } from '../../../viewmodels/starratingviewmodel';
import { CurrencyViewModel } from 'src/app/common/viewmodels/currencyviewmodel';
import { DesignationViewModel } from 'src/app/b2b/viewmodels/designationviewmodel';
import { NationalityViewModel } from 'src/app/b2b/viewmodels/nationalityviewmodel';

@Injectable()
export class CountriesResolverService implements Resolve<CountryViewModel[]> {

  constructor(private lookupService: LookupService) { }

  resolve(): Observable<CountryViewModel[]> {
    return this.lookupService.getCountries();
  }
}

@Injectable()
export class SalutationsResolverService implements Resolve<SalutationViewModel[]> {

  constructor(private lookupService: LookupService) { }

  resolve(): Observable<SalutationViewModel[]> {
    return this.lookupService.getSalutation();
  }
}

@Injectable()
export class StarRatingResolverService implements Resolve<StarRatingViewModel[]> {

  constructor(private lookupService: LookupService) { }

  resolve(): Observable<StarRatingViewModel[]> {
    return this.lookupService.getStarRating();
  }
}

@Injectable()
export class CurrencyResolverService implements Resolve<CurrencyViewModel[]> {

  constructor(private lookupService: LookupService) { }

  resolve(): Observable<CurrencyViewModel[]> {
    return this.lookupService.getCurrencies(0);
  }
}

@Injectable()
export class NationalityResolverService implements Resolve<NationalityViewModel[]> {

  constructor(private lookupService: LookupService) { }

  resolve(): Observable<NationalityViewModel[]> {
    return this.lookupService.getNationality(0);
  }
}

