import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { map, tap } from 'rxjs/operators';
import { SystemConfigViewModel } from 'src/app/b2b/viewmodels/systemconfigviewmodel';

@Injectable({
  providedIn: 'root'
})
export class PhotosDataService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }

  profilePhotoUrl: any;

  // uploadPhoto(details: any): Observable<any> {
  //   return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.userMgmtApi,
  //     details, 'api/User/UploadPhoto')
  //     .pipe(
  //       map(data => {
  //         this.profilePhotoUrl = '';
  //         if (data) {
  //           this.profilePhotoUrl = data;
  //           return data;
  //         } else {
  //           return [];
  //         }
  //       })
  //     );
  // }

  uploadPhoto(details: any): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.contentUrl + 'api/Documents/UploadPhotoForBackOffice'
      , details)
      .pipe(tap(data => {
        this.profilePhotoUrl = '';
        if (data.result !== null) {
        this.profilePhotoUrl = data;
        }
      }));
  }

  getContentUrl(): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/SystemConfigurations/Get/').pipe(map(data => {
        let systemConfigDto: SystemConfigViewModel[] = [];
        let contentUrl = null;
        if (data !== null) {
          systemConfigDto = data.result;
          const len = systemConfigDto.length;
          for (let i = 0; i < len; i++) {
            if (systemConfigDto[i].keyName === 'ContentUrl' && systemConfigDto[i].configIsActive === true
                && systemConfigDto[i].categoryName === 'Web') {
                  contentUrl = systemConfigDto[i].value;
            }
          }
        }
        return contentUrl;
      }));
  }

}
