import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-shared-confirm-dialog',
  templateUrl: './shared-confirm-dialog.component.html',
  styleUrls: ['./shared-confirm-dialog.component.css']
})
export class SharedConfirmDialogComponent {

  public title: string;
  public message: string;
  public showCancel = true;
  public showCross = true;
  public cancelButtonName;
  public okButtonName;
  constructor(public dialogRef: MatDialogRef<SharedConfirmDialogComponent>) {

  }

}
