import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { RoomInfoViewModel } from '../viewmodels/roominfoviewmodel';

@Injectable({
  providedIn: 'root'
})
export class RoomInfoDataService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService,
    private dialog: MatDialog) { }

    getRoomInfoDetails(roomDetails: RoomInfoViewModel): Observable<any> {
      return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , roomDetails, 'GetRoomDetails')
      .pipe(
        map(data => {
          if (data !== null) {
            return data;
          } else {
            return [];
          }
        })
      );
    }
}
