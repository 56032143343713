import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../../constants';
import { Utilities } from '../../utilities/utilities';
import { UserDetailsViewModel } from '../../viewmodels/userdetailsviewmodel';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
@Injectable({
  providedIn: 'root'
})
export class StateService {
  private _initialState: string;
  private _previousUrl: string;
  private _currentUrl: string;
    constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute,
      private frameWorkConfigService: FrameworkConfigService) { }

    set State(initialState: string) {
      this._initialState = initialState;
    }

    get State() {
      return this._initialState;
    }
   initializeState() {
    // Hack: For Firefox private browser, local storage is getting cleared during redirection from Payment Gateway.
    // Hence, storing oidc.user details from session storage back to local storage.
    if (!this.authService.isLoggedIn() && this.State !== null && this.State !== undefined &&
        CONSTANTS.paymentGatewayExceptionList.includes(this.State.split('?')[0])) {
      this.authService.setCCUserFromSessionToLocalStorage();
    }
    this.authService.removeOidcUserSessionStorage();
    if (this.authService.isLoggedIn()) {
/*       if (!Utilities.isNullOrEmpty(this.State) &&
      CONSTANTS.authenticatedExceptionList.includes(this.State)) {
        this.router.navigate([this.State]);
      } else {
        this.router.navigate(['/authenticated']);
      } */
      let isAuthenticatedException = false;
      if (!Utilities.isNullOrEmpty(this.State)) {
        const authenticatedExceptionList = CONSTANTS.authenticatedExceptionList;
        for (let i = 0; i < authenticatedExceptionList.length; i++) {
          if (this.State.includes(authenticatedExceptionList[i])) {
            isAuthenticatedException = true;
            // if (this.State.includes('/authenticated/payment/paymentsuccess')) {
            //   console.log('Decoded Uri -->'+ decodeURI(this.State) );
            //   this.State = '/authenticated/payment/paymentsuccess';
            // } else if (this.State.includes('/authenticated/payment/paymentcancel')) {
            //   this.State = '/authenticated/payment/paymentcancel';
            // } else if (this.State.includes('/authenticated/payment/paymentfailed')) {
            //   this.State = '/authenticated/payment/paymentfailed';
            // }
          /*    if (this.State.includes('/authenticated/payment/paymentsuccess') ||
                this.State.includes('/authenticated/payment/paymentcancel') ||
                this.State.includes('/authenticated/payment/paymentfailed')) {
                  console.log('Decoded Uri -->'+ decodeURI(this.State) );
              this.State = decodeURI(this.State);
            }  */
            // this.router.navigate([this.State]);

              // tslint:disable-next-line:max-line-length
              if ((this.authService.isBoUser() || this.frameWorkConfigService.IsCallCenterUser) && this.State !== CONSTANTS.routeList.safeStayPolicies
              && this.State !== CONSTANTS.routeList.safeStayInfo) {
                this.router.navigate(['/authenticated/callcentermgmt/agencysearchinfo'], { relativeTo: this.activatedRoute });
              } else {
                this.router.navigate([this.State]);
              }
          }
        }
      }
      if (!isAuthenticatedException) {
        // tslint:disable-next-line:max-line-length
        if ((this.authService.isBoUser() || this.frameWorkConfigService.IsCallCenterUser) && this.State !== CONSTANTS.routeList.safeStayPolicies
        && this.State !== CONSTANTS.routeList.safeStayInfo) {
          this.router.navigate(['/authenticated/callcentermgmt/agencysearchinfo'], { relativeTo: this.activatedRoute });
        } else {
          this.router.navigate(['/authenticated']);
        }
      }
    } else {
/*       if (!Utilities.isNullOrEmpty(this.State) &&
          CONSTANTS.unAuthenticatedRouteList.includes(this.State)) {
        this.router.navigate([this.State]);
      } else {
        this.router.navigate(['/home']);
      } */
      let isAuthenticatedException = false;
      if (!Utilities.isNullOrEmpty(this.State)) {
        const unauthenticatedExceptionList = CONSTANTS.unAuthenticatedRouteList;
        for (let i = 0; i < unauthenticatedExceptionList.length; i++) {
          if (this.State.includes(unauthenticatedExceptionList[i])) {
            isAuthenticatedException = true;
            this.router.navigate([this.State]);
          }
        }
      }
      // alert('notloggedin stateservice');
      // alert(this.frameWorkConfigService.IsCallCenterUser);
      if (!isAuthenticatedException) {
        if (this.frameWorkConfigService.IsCallCenterUser) {
          this.authService.login();
        } else {
          this.router.navigate(['/home']);
        }
      }
    }
  }

  set previousUrl(url: string) {
    this._previousUrl = url;
  }

  get previousUrl() {
    return this._previousUrl;
  }

  set currentUrl(url: string) {
    this._currentUrl = url;
  }

  get currentUrl() {
    return this._currentUrl;
  }
}
