import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-show-hide-columns',
  templateUrl: './show-hide-columns.component.html',
  styleUrls: ['./show-hide-columns.component.css']
})
export class ShowHideColumnsComponent implements OnInit {

  @Input() displayedColumns;
  selectAll = false;
  unselectedAll: boolean;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.selectAllColumns();
  }

  selectAllColumns() {
    this.displayedColumns.forEach(column => {
      // if (!column.default) {
        if (this.selectAll === true) {
          column.show = true;
        } else {
          column.show = false;
        }
      // }
    });
    this.checkIfAllUnselected();
  }

  checkIfAllSelected() {
    this.selectAll = this.displayedColumns.every(function (item: any) {
      return item.show === true;
    });
    this.cd.detectChanges();
    this.checkIfAllUnselected();
  }

  checkIfAllUnselected() {
    this.unselectedAll = this.displayedColumns.every(function (item: any) {
      return item.show === false;
    });
    if (this.unselectedAll) {
      this.displayedColumns.forEach(column => {
        if (column.default) {
          column.show = true;
        }
      });
    }
  }

}
