import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BookingStatusFilterViewmodel } from 'src/app/b2b/viewmodels/reports/reoprtFilters/bookingStatusFilterViewmodel';
import { TopDestinationFilterViewmodel } from 'src/app/b2b/viewmodels/reports/reoprtFilters/topDestinationFilterViewmodel';
import { BookingComparisonFilterViewmodel } from 'src/app/b2b/viewmodels/reports/reoprtFilters/bookingComparisonFilterViewmodel';
import { BookingLeadTimeFilterViewmodel } from 'src/app/b2b/viewmodels/reports/reoprtFilters/bookingLeadTimeFilterViewmodel';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { LookupService } from '../lookup.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterService {

  constructor( private userProfileService: UserProfileService,
    private lookupService: LookupService) { }


  totalbookingParameters: BookingStatusFilterViewmodel;
  statusParameters: BookingStatusFilterViewmodel;
  totalDestinationParameters: TopDestinationFilterViewmodel;
  bookingComparisonParameters: BookingComparisonFilterViewmodel;
  bookingLeadTimeParameters: BookingLeadTimeFilterViewmodel;
  bookingTimeParameters: BookingLeadTimeFilterViewmodel;
  defaultParameters: any;
  agentList:any;


  private globalParams = new BehaviorSubject<any>(this.defaultParameters);
  castParam = this.globalParams.asObservable();

  changeFilterParams(newParam) {
    //const test=JSON.stringify(newParam)
   // this.globalParams.next(test);
   if (newParam.agentIdForFilter !== null) {
    this.lookupService.getAgentName(this.userProfileService.getBasicUserInfo().agencyId, + newParam.branchId).subscribe(data => {
      if (data) {
        this.setAgentList = data;
        this.globalParams.next(newParam);
      }
    });

  }else{
    this.globalParams.next(newParam);
  }
   
  }
  
  

  globalParameter: any;
  private paramObj = new BehaviorSubject<any>(this.globalParameter);
  PramaObject = this.paramObj.asObservable();
  //castUser = this.user.asObservable();

  changeParam(param: any) {
    this.paramObj.next(param);
    //  this.user.next(newUser); 
    // console.log('From service');
    // console.log(param);
    //this.setTotalBookingsFilter=param;
  }

  set setFilterParameters(params: any) {
    this.globalParameter = params;
    this.defaultParameters=params;
  }

  get getGlobalFilters() {
    return this.defaultParameters;
  }




  get getTotalBookingsReportFilter() {
    return this.totalbookingParameters;
  }

  set setTotalBookingsFilter(filterParams: BookingStatusFilterViewmodel) {
    this.totalbookingParameters = filterParams;
  }

  get getStatusReportFilter() {
    return this.statusParameters;
  }

  set setStatusReportFilter(filterParams: BookingStatusFilterViewmodel) {
    this.statusParameters = filterParams;
  }

  get getTotalDestinationReportFilter() {
    return this.totalDestinationParameters;
  }

  set setTotalDestinationReportFilter(filterParams: TopDestinationFilterViewmodel) {
    this.totalDestinationParameters = filterParams;
  }

  get getBookingComparisonReportFilter() {
    return this.bookingComparisonParameters;
  }

  set setBookingComparisonReportFilter(filterParams: BookingComparisonFilterViewmodel) {
    this.bookingComparisonParameters = filterParams;
  }

  get getBookingLeadTimeReportFilter() {
    return this.bookingLeadTimeParameters;
  }

  set setBookingLeadTimeReportFilter(filterParams: BookingLeadTimeFilterViewmodel) {
    this.bookingLeadTimeParameters = filterParams;
  }

  get getBookingTimeReportFilter() {
    return this.bookingTimeParameters;
  }

  set setBookingTimeReportFilter(filterParams: BookingLeadTimeFilterViewmodel) {
    this.bookingTimeParameters = filterParams;
  }

  get getAgentList() {
    return this.agentList;
  }

  set setAgentList(data: any) {
    this.agentList = data;
  }

}
