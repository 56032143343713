import { ErrorMessageComponent } from './error-message/error-message.component';
import { BranchMarkupInfoComponent } from './branch-markup-info/branch-markup-info.component';

import { AgencyMarkupComponent } from './agency-markup/agency-markup.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BranchMarkupComponent } from './branch-markup/branch-markup.component';
import { MarkupMgmtNavComponent } from './markup-mgmt-nav/markup-mgmt-nav.component';
import { CountryMarkupComponent } from './country-markup/country-markup.component';
import { CityMarkupComponent } from './city-markup/city-markup.component';

import { CountryMarkupInfoComponent } from './country-markup-info/country-markup-info.component';
import { CountriesResolverService, CurrencyResolverService } from '../common/b2b-shared/services/lookup-resolver.service';
import { CityMarkupInfoComponent } from './city-markup-info/city-markup-info.component';
import { HotelMarkupComponent } from './hotel-markup/hotel-markup.component';
import { HotelMarkupInfoComponent } from './hotel-markup-info/hotel-markup-info.component';

export const routes: Routes = [
  {
    path: 'markupmgmt',
    component: AgencyMarkupComponent,
    children: [
      { path: '', redirectTo: 'branchmarkup', pathMatch: 'full' },
      { path: 'branchmarkup', component: BranchMarkupComponent, data: {  breadcrumb: 'Branch Markup' } },
      { path: 'countrymarkup', component: CountryMarkupComponent , data: {  breadcrumb: 'Country Markup' }},
      { path: 'citymarkup', component: CityMarkupComponent, data: {  breadcrumb: 'City Markup' }},
      { path: 'hotelmarkup', component: HotelMarkupComponent, data: {  breadcrumb: 'Hotel Markup' }},
    ],
    resolve: {
                countryList: CountriesResolverService,
                currencyList: CurrencyResolverService
              },
  },
  { path: 'markupmgmt/branchmarkup/branchmarkupInfo/:agencyId/:branchId/:tabId/:groupId/:operation', component: BranchMarkupInfoComponent,
   data: {  breadcrumb: 'Branch Markup operation' } },
  {
    path: 'markupmgmt/countrymarkup/countrymarkupInfo/:agencyId/:branchId/:tabId/:groupId/:operation',
    component: CountryMarkupInfoComponent, data: {  breadcrumb: 'Country Markup Operation' },
    resolve: {
      countryList: CountriesResolverService,
      currencyList: CurrencyResolverService
     },
  },
  {
    path: 'markupmgmt/citymarkup/citymarkupInfo/:agencyId/:branchId/:tabId/:groupId/:operation',
    component: CityMarkupInfoComponent, data: {  breadcrumb: 'City Markup Operation' },
    resolve: {
      countryList: CountriesResolverService,
      currencyList: CurrencyResolverService
     },
  },
  {
    path: 'markupmgmt/hotelmarkup/hotelmarkupInfo/:agencyId/:branchId/:tabId/:groupId/:operation',
    component: HotelMarkupInfoComponent, data: {  breadcrumb: 'Hotel Markup Operation' },
    resolve: {
      countryList: CountriesResolverService,
      currencyList: CurrencyResolverService
     },
  },
  // { path: 'markupmgmt/countrymarkup/:id/:operation', component: CountryMarkupInfoComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarkupMgmtRoutingModule { }

export const routedAgencyComponents = [
  AgencyMarkupComponent,
  MarkupMgmtNavComponent,
  BranchMarkupComponent,
  CountryMarkupComponent,
  CityMarkupComponent,
  BranchMarkupInfoComponent,
  CountryMarkupInfoComponent,
  ErrorMessageComponent
];
