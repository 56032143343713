import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { UserProfileService } from './../../../../common/shared/services/user-profile.service';
import { PaymentDataService } from './payment-data.service';
import { PaymentTypeDetailsViewModel } from './../../../viewmodels/payment/paymenttypedetailsviewmodel';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeDetailsResolverService implements Resolve<PaymentTypeDetailsViewModel> {

  constructor(public paymentDataService: PaymentDataService, public userProfileService: UserProfileService,
    public currencyApi: CurrencyApi) { }

  // agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  // selectedCurrency = this.currencyApi.selectedCurrency;

  resolve(): Observable<PaymentTypeDetailsViewModel> {
    // tslint:disable-next-line:max-line-length
    return this.paymentDataService.getPaymentTypeDetailsByAgencyId(this.currencyApi.selectedCurrency, this.userProfileService.getBasicUserInfo().agencyId);
  }
}
