import { CommonDetailsViewModel } from './commondetailsviewmodel';
import { CancellationPolicyDetailsViewModel } from './cancellationpolicydetailsviewmodel';
import { AccommodationDetailsViewModel } from './accommodationdetailsviewmodel';
import { SellingPricingDetailsViewModel } from './sellingpricingdetailsviewmodel';

export class ReservationDetailsViewModel {
    commonDetails: CommonDetailsViewModel;
    accommodationDetails: AccommodationDetailsViewModel;
    sellingCancellationPolicy: CancellationPolicyDetailsViewModel;
    sellingPricingDetails: SellingPricingDetailsViewModel;
}
