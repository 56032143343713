export class MarkupInfoResponseViewModel {
      currencyId: number;
      isDeleted: boolean;
      isActive: boolean;
      chargeTypeId: number;
      markup: number;
      rateCategoryId?: number;
      hotelRoomTypeId: number;
      groupId: string;
      hotelId: number;
      countryId: number;
      nationalityId: number;
      agencyId: number;
      fromDatekey: number;
      toDatekey: number;
      fromDate: string;
      toDate: string;
      rowId?: number;
      cityId: number;
      tabId: number;
      branchId: number;
      valid: boolean;
      languageId?: number;
      createdBy: string;
      updatedBy: string;
      createdDate: string;
      updatedDate: string;
}
