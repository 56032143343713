import { CONSTANTS } from './../../../../common/constants';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'status' })
export class BookingStatusPipe implements PipeTransform {
  allStatus = CONSTANTS.bookingStatus;
  transform(status: string) {
    return status ? this.allStatus[status] : '';
  }
}
