import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';

import { SubscriptionViewModel } from 'src/app/b2b/viewmodels/push-notification/subscriptionViewModel';
import { JarvisError } from '../../jarviserror';
import { ConfigurationService } from './configuration.service';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { AuthService } from './auth.service';
import { SearchEventNotification } from 'src/app/b2b/viewmodels/push-notification/searcheventnotifications';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  public isAskNotification = false;
  subscriptionForNotification: PushSubscription;
  readonly VAPID_PUBLIC_KEY = this.configurationService.config.pushNotificationPublicKey;

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private swPush: SwPush) { }

  saveSubsciption(model: SubscriptionViewModel) {
    model.userId = this.authService.getSubjectId();
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , model, 'api/PushNotification/AddSubscription');
  }

  sendSearchEventNotifications(model: SearchEventNotification) {
    model.userId = this.authService.getSubjectId();
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , model, 'api/PushNotification/SendSearchEventNotification');
  }

  getSubscription(applicationName: string) {
    this.isAskNotification = false;
    if ('serviceWorker' in navigator && ('PushManager' in window) && this.swPush.isEnabled) {
      navigator.serviceWorker.register('./service-worker.js', { scope: './' }).then(reg => {
        reg.pushManager.getSubscription().then(subscription => {
          if (!subscription) {
            this.swPush.requestSubscription({
              serverPublicKey: this.VAPID_PUBLIC_KEY
            }).then(sub => {
              console.log("Received Push Notification Subscription Successfully...");
              const requestModel = <SubscriptionViewModel>{};
              requestModel.applicationName = applicationName;
              requestModel.subscriptionJson = JSON.stringify(sub);
              this.saveSubsciption(requestModel).subscribe((data) => {
                if (!data) {
                  console.error(`Error in saving Push Notification Subscription.`)
                }
              },
                (error: JarvisError) => console.error(`Error in saving Push Notification Subscription. ${error.friendlyMessage}`));
            }).catch(err => {
              if (Notification.permission === "default") {
                this.isAskNotification = true;
                let element = document.querySelector('.topnav-wrapper');
                if (element) {
                  element.setAttribute("style", "top:30px;");
                }
              }
              console.error(err)
            });
          }
          else {
            console.log('Push Notification Already Subscribed...');
            const requestModel = <SubscriptionViewModel>{};
            requestModel.applicationName = applicationName;
            requestModel.subscriptionJson = JSON.stringify(subscription);
            this.saveSubsciption(requestModel).subscribe((data) => {
              if (!data) {
                console.error(`Error in saving Push Notification Subscription.`)
              }
            },
              (error: JarvisError) => console.error(`Error in saving Push Notification Subscription. ${error.friendlyMessage}`));
          }
        });
      });
    }
  }
}
