import { Injectable } from '@angular/core';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { Observable } from 'rxjs';
import { CurrencyViewModel } from '../../viewmodels/currencyviewmodel';
import { MasterLookupService } from './lookup.service';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';
import { LookupService } from '../../../b2b/common/b2b-shared/services/lookup.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyApiService implements CurrencyApi {
  selectedCurrency = '';
  defaultCurrency: string;
  currencyList$: Observable<CurrencyViewModel[]>;
  agencyDefaultCurrencyId: number;
  constructor(private lookupService: MasterLookupService,
    private topBarService: TopBarService, private b2bLookupService: LookupService) {
    /* this.topBarService.currencyChange$.subscribe(curr => {
      // console.log('Changed Currency ' + curr);
      // console.log('Selected Currency in CurrencyApiService ' + this.selectedCurrency);
    }); */

  }
  getCurrencyList(): Observable<CurrencyViewModel[]> {
    return this.currencyList$ = this.lookupService.getCurrencies(0).pipe(map((data) => {
      data.sort((a, b) => {
        return a.currencyCode < b.currencyCode ? -1 : 1;
      });
      this.defaultCurrency = data.filter(currency => currency.currencyCode === 'IDR').map(currency => currency.currencyCode)[0];
      if (this.agencyDefaultCurrencyId) {
        this.defaultCurrency = data
          .filter(currency => currency.currencyId === this.agencyDefaultCurrencyId)
          .map(currency => currency.currencyCode)[0];
      }
      return data;
    }));
  }
  getAgencyPreferredCurrencyList(agencyCode: string, agencyDefaultCurrencyId: number): Observable<CurrencyViewModel[]> {
    return this.currencyList$ = this.b2bLookupService.getCurrencyByAgencyCode(agencyCode).pipe(map((data) => {
      if (!data || data.length < 1) {
        this.agencyDefaultCurrencyId = agencyDefaultCurrencyId;
        this.getCurrencyList();
      } else {
        data.sort((a, b) => {
          return a.currencyCode < b.currencyCode ? -1 : 1;
        });
        this.defaultCurrency = data
          .filter(currency => currency.currencyId === agencyDefaultCurrencyId)
          .map(currency => currency.currencyCode)[0];
        return data;
      }
    }));
  }

  getDefaultCurrancy() {
    return this.defaultCurrency;
  }
}
