import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgencyReferenceNumberViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/AgencyReferenceNumberViewModel';

@Injectable({
  providedIn: 'root'
})
export class GuestDetailsService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }

  isAgencyReferenceNumberUnique(agencyCode: string, agencyReferenceNumber: string): Observable<AgencyReferenceNumberViewModel> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi,
      { agencyCode: agencyCode, agencyReferenceNo: agencyReferenceNumber }
      , 'api/GuestDetails/IsAgencyReferenceNumberExist').pipe(map((data: any) => {
        if (data === null) {
          return null;
        } else {
          return data.result;
        }
      }));
  }
}
