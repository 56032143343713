import { Injectable } from '@angular/core';
import { TopBarService, TopBarItemViewModel } from '../../../framework/fw/services/top-bar.service';
import { UserDetailsViewModel } from '../../viewmodels/userdetailsviewmodel';

export interface ITopBarDataService {
  InitializeTopBarData(userDetails: UserDetailsViewModel);
}

@Injectable({
  providedIn: 'root'
})
export class TopBarDataService implements ITopBarDataService {
  private _userDetails: UserDetailsViewModel;
  private _topBarItem: TopBarItemViewModel;

  constructor(private topBarService: TopBarService
  ) {
  }

  InitializeTopBarData(userDetails: UserDetailsViewModel) {
    this._userDetails = userDetails;
    this.TransformTopBarData();
    this.topBarService.setTopBarItem(this._topBarItem);
  }

  private TransformTopBarData() {
    this._topBarItem = new TopBarItemViewModel();
    const topBarItemViewModel: TopBarItemViewModel = new TopBarItemViewModel();
    topBarItemViewModel.firstName = this._userDetails.firstName;
    topBarItemViewModel.lastName = this._userDetails.lastName;
    topBarItemViewModel.profilePictureUri = this._userDetails.profilePictureUri;
    topBarItemViewModel.isCredit = this._userDetails.isCredit;
     topBarItemViewModel.role = this._userDetails.applicationPermissions[0].role;
    this._topBarItem = topBarItemViewModel;
  }
}


