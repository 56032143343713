import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { BookingSummaryViewModel } from 'src/app/b2b/viewmodels/booking-summary/bookingsummaryviewmodel';
import { BookingsCountRequestViewModel } from 'src/app/b2b/viewmodels/booking-summary/bookingscountrequestviewmodel';

@Injectable({
  providedIn: 'root'
})
export class BookingSummaryService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService, private configurationService: ConfigurationService) { }

  getBookingByAgencyCode(countReqObj: BookingsCountRequestViewModel): Observable<any> {
    let request: any;
      const q = countReqObj;
      const start = q.startDate.toLocaleDateString('en-US');
      const end = q.endDate.toLocaleDateString('en-US');
      request = JSON.parse(JSON.stringify(countReqObj)); // deep copy
      request.startDate = start;
      request.endDate = end;
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, request,
      'api/Reservation/GetMyReservation').pipe(
        map((data) => data ? data : null));
  }

  getBookingsCountByAgencyCode(countReqObj: BookingsCountRequestViewModel): Observable<any> {
    let request: any;
      const q = countReqObj;
      const start = q.startDate.toLocaleDateString('en-US');
      const end = q.endDate.toLocaleDateString('en-US');
      request = JSON.parse(JSON.stringify(countReqObj)); // deep copy
      request.startDate = start;
      request.endDate = end;
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, request,
      'api/Reservation/GetReservationCountByAgency').pipe(
        map((data) => data ? data : null));
  }

}
