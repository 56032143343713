import { Injectable } from '@angular/core';
import { NavigatorService } from '../../../framework/fw/services/navigator.service';
import { formatNumber } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserInputTransformService {

  constructor(private navigatorService: NavigatorService) { }

  public userInputNumberTransform(value): string {
    if (value !== '') {
      let isDecimal = false;
      const splitValue = value.split('.');
      if (splitValue.length === 2) {
        isDecimal = true;
      }
      if (isDecimal) {
        const amount = Number(splitValue[0].replace(/[,]/g, ''));
          let isNumber = false;
          isNumber = isNaN(amount);
          if (isNumber === false) {
            return formatNumber(amount, this.navigatorService.getCurrentLocale()) + '.' + splitValue[1].trim();
          }
        } else {
          const amount = Number(value.replace(/[,]/g, ''));
          let isNumber = false;
          isNumber = isNaN(amount);
          if (isNumber === false) {
          return formatNumber(amount, this.navigatorService.getCurrentLocale());
          }
        }
      }
    }
}
