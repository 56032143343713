import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../common/material/material.module';
import { RouterModule } from '@angular/router';
import { AgencyRoutingModule, routedAgencyComponents } from './agency-mgmt-routing.module';
import { MatInputModule, MatButtonModule, MatSelectModule, MatRadioModule, MatCardModule } from '@angular/material';
import { DialogsModule } from '../common/b2b-shared/dialogs/dialogs.module';
import { ComponentsModule } from '../common/b2b-shared/components/components.module';
import { PaginatorModule } from 'primeng/paginator';
import { B2bSharedModule } from '../common/b2b-shared/b2b-shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    AgencyRoutingModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    DialogsModule,
    ComponentsModule,
    PaginatorModule,
    B2bSharedModule
  ],
  declarations: [routedAgencyComponents]
})
export class AgencyMgmtModule { }
