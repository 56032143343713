export class UserInfoViewModel {
  firstName: string;
  lastName: string;
  userType: number;
  userName: string;
  email: string;
  id: string;
  agencyCode: string;
  agencyId: number;
  agencyDefaultCurrencyId: number;
  isCredit: boolean;
  agencyNationalityId: number;
  agentId: string;
  branchId: number;
  isSuperUser: boolean;
  isPasswordExpired: boolean;
}
