import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { Component } from '@angular/core';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';

@Component({
  selector: 'app-agency-details',
  templateUrl: './agency-details.component.html',
  styleUrls: ['./agency-details.component.css']
})
export class AgencyDetailsComponent {
  // agencyNameCode = '';

  constructor(private userProfileService: UserProfileService,
  private dialogsService: DialogsService,
  private activatedRoute: ActivatedRoute,
  private router: Router,
  public frameworkConfigService: FrameworkConfigService
  ) { }


   editAgency() {
     const message = `This action will take user to “Agency Name/Code” selection page, doing this action will expire the current session.
                        Do you still want to navigation.`;
     const dialogSub = this.dialogsService.confirm('Confirmation', message, true);
     dialogSub.subscribe(res => {
       if (res) {
         this.router.navigate(['/authenticated/callcentermgmt/agencysearchinfo'], { relativeTo: this.activatedRoute });
       }
     });
   }

}
