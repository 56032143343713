import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgencySearchInfoComponent } from '../callcenter-mgmt/agency-search-info/agency-search-info.component';


const routes: Routes = [
  { path: 'agencysearchinfo', component: AgencySearchInfoComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CallcenterMgmtRoutingModule { }

export const routedCallcenterComponents = [
  AgencySearchInfoComponent
];
