import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { MaterialModule } from 'src/app/common/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PhotosComponent } from './photos/photos.component';
import { EditPhotoDialogComponent } from './edit-photo-dialog/edit-photo-dialog.component';
import { DialogsService } from './dialogs.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SharedConfirmDialogComponent } from './shared-confirm-dialog/shared-confirm-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { HotelSearchProgressDialogComponent } from './hotel-search-progress-dialog/hotel-search-progress-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    PhotosComponent,
    EditPhotoDialogComponent,
    ConfirmDialogComponent,
    SharedConfirmDialogComponent, SpinnerComponent, HotelSearchProgressDialogComponent, ErrorDialogComponent],
  exports: [
    PhotosComponent,
    EditPhotoDialogComponent, SpinnerComponent, HotelSearchProgressDialogComponent, ErrorDialogComponent],
  entryComponents: [
    PhotosComponent,
    EditPhotoDialogComponent,
    SharedConfirmDialogComponent,
    ConfirmDialogComponent, SpinnerComponent, HotelSearchProgressDialogComponent, ErrorDialogComponent],
  providers: [DialogsService]
})
export class DialogsModule { }
