import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from './../../../common/constants';

@Component({
  selector: 'app-markup-mgmt-nav',
  templateUrl: './markup-mgmt-nav.component.html',
  styleUrls: ['./markup-mgmt-nav.component.css']
})
export class MarkupMgmtNavComponent implements OnInit {


  routeLinks: any[];

  constructor() { }

  ngOnInit() {
    this.routeLinks = [
      {
        label: CONSTANTS.markup.tab.branch.label,
        path: 'branchmarkup'
      },
      {
        label: CONSTANTS.markup.tab.countrymarkup.label,
        path: 'countrymarkup'
      },
      {
        label: CONSTANTS.markup.tab.citymarkup.label,
        path: 'citymarkup'
      },
      {
        label: CONSTANTS.markup.tab.hotelmarkup.label,
        path: 'hotelmarkup'
      }
    ];
  }

}
