import { map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { BranchDataService } from 'src/app/b2b/common/b2b-shared/services/branch-data.service';

export class AgencyMgmtBranchAsyncValidators {
  static branchNameValidator(branchDataService: BranchDataService, branchId: string, isMainCheck: boolean) {
    return (control: AbstractControl) => {
      const agencyId = control.root.get('mgBranch.agencyId').value;
      return branchDataService.isBranchUnique(agencyId, branchId, control.root.get('mgBranch.name').value.trim(), isMainCheck).pipe(
        map(res => {
          return res ? { 'duplicateBranch': true } : null;
        }));
    };
  }
}


