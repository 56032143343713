export class RequestCreditNotification {
    agencyName: string;
    agencyId: number;
    agencyCode: string;
    contactPerson: string;
    designation: string;
    totalCreditLimit: string;
    consumed: string;
    balance: string;
    requestForCreditAmount: string;
    reason: string;
    email: string;
    currency:string;
}
