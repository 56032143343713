export let ErrorCodes = {
    RecheckAvailability: 'JRVB2B001',
    Book: 'JRVB2B002',
    CancelBooking: 'JRVB2B003',
    GenerateReservationDocument: 'JRVB2B004',
    SendReservationConfirmationEmail: 'JRVB2B005',
    SearchHotel: 'JRVB2B006',
    UpdateAgencyDetails: 'JRVB2B007',
    AgentCreate: 'JRVB2B008',
    AgentUpdate: 'JRVB2B009',
    BranchCreate: 'JRVB2B010',
    BranchUpdate: 'JRVB2B011',
    UpdateGuestDetails: 'JRVB2B012',
    ConfirmOnHoldBooking: 'JRVB2B013',
    ValidateMarkupRequest: 'JRVB2B014',
};
