import { Injectable } from '@angular/core';
import {
  HotelViewModel, RoomDetailViewModel, RoomCategoryInfoViewModel,
  HotelImagesViewModel
} from 'src/app/b2b/viewmodels/hotel-mgmt/hotelviewmodel';
import { Observable, of } from 'rxjs';

import { DestinationSearchViewModel, SearchViewModel } from '../viewmodels/hotel-search-panel/destinationsearchviewmodel';
import { AuthenticatedHttpService } from '../../../../common/shared/services/authenticated-http.service';
import { ConfigurationService } from '../../../../common/shared/services/configuration.service';
import { map } from 'rxjs/operators';
import { HotelSearchPanelViewModel } from '../viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';
import { HotelFilterViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelfilterviewmodel';
import { hotelFilterResults } from 'src/app/b2b/viewmodels/hotel-mgmt/filter-results';
import { HotelListAndFilterViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotellistfilterviewmodel';
import { TempHotelService } from 'src/app/b2b/viewmodels/hotel-mgmt/hotel-search-result';
import { HotelDetailsViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hoteldetailsviewmodel';
import { HotelRecheckSearchViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelrechecksearchviewmodel';
import { HotelRecheckViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelrecheckviewmodel';
import { BookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-request-viewmodel';
import { HotelBookService } from './hotel-book.service';
import { FullBookingResponseDetailsViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-response-viewmodel';
import { CancelBookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-request-viewmodel';
import { CancelBookingResponseViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';
import { HoldBookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/hold-booking-request-viewmodel';
import { ErrorMessageViewModel } from '../../../viewmodels/errorMessageViewModel';
import { v4 as uuid } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'src/app/common/constants';

@Injectable({
  providedIn: 'root'
})
export class HotelDataService {
  hotels$: Observable<HotelViewModel[]>;
  hotelFilters$: Observable<HotelFilterViewModel>;
  hotelListAndFilter$: Observable<HotelListAndFilterViewModel>;
  private _hotel: HotelViewModel;
  private _room: RoomDetailViewModel;
  private _sessionId: string;
  private _creditCardAgencyOnly: boolean;
  private _isSafeStay: boolean;
  private _safeStayPolicy: string;
  private _allEmployeesVaccinated: boolean;
  _cityData: any;
  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService,
    private tempHotelService: TempHotelService,
    private hotelBookService: HotelBookService,
    private http: HttpClient
  ) { }

  set creditCardAgencyOnly(v: boolean) {
    this._creditCardAgencyOnly = v;
  }

  get creditCardAgencyOnly(): boolean {
    return this._creditCardAgencyOnly;
  }

  get clientIPAddress(): string {
    return localStorage.getItem('clientIPAddress');
  }

  getSearchedDestinations(destination: SearchViewModel): Observable<DestinationSearchViewModel> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.b2BContentApi
      , destination, 'api/Home/GetHotelSearchData/').pipe(map((data: any) => {
        let destinationsDto: DestinationSearchViewModel = <DestinationSearchViewModel>{};
        if (data !== null) {
          destinationsDto = data.result;
        } else {
          destinationsDto = null;
        }
        return destinationsDto;
      }));
  }

  getHotelFilters(): Observable<HotelFilterViewModel> {
    return this.hotelFilters$ = of(hotelFilterResults);
  }

  getIPAddress(): Observable<{ip:string}> {
    return this.http.get<{ip:string}>(CONSTANTS.ClientIPAddressURL);   
  }

  getHotelListAndFilters(searchData: HotelSearchPanelViewModel): Observable<HotelListAndFilterViewModel> {
    searchData.clientIPAddress = this.clientIPAddress;

    const q = searchData.checkInCheckOutDate;
    const checkindate = q[0].toLocaleDateString('en-US');
    const checkoutdate = q[1].toLocaleDateString('en-US');

    const modelWithGuid = JSON.parse(JSON.stringify(searchData));
    modelWithGuid.requestKey = uuid();
    const checkInCheckoutDate = [];
    checkInCheckoutDate[0] = checkindate;
    checkInCheckoutDate[1] = checkoutdate;
    modelWithGuid.checkInCheckOutDate = checkInCheckoutDate;
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.xmlSellApi, modelWithGuid,
      '1.0/Hotel/SearchAvailability').pipe(map((data: any) => {
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        let hotelFilterSearch: HotelListAndFilterViewModel = new HotelListAndFilterViewModel();
        if (data !== null) {
          if (data.result !== null) {
          hotelFilterSearch = data.result;
          this.sessionId = hotelFilterSearch.sessionId;
        } else {
          xmlErrorMessage.message = data.message;
          xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
          xmlErrorMessage.errorCode = data.errorCode;
          hotelFilterSearch.errorModel = xmlErrorMessage;
       }
        }
        return hotelFilterSearch;
      }));
  }
  // Todo: Need to return the Observable<RecheckResultVM>
  getRecheckAvailability(recheckSearchData: HotelRecheckSearchViewModel, isRedirectionFromCC = false): Observable<HotelRecheckViewModel> {
    // console.log('Recheck Req -->' + JSON.stringify(recheckSearchData));
    recheckSearchData.clientIPAddress = this.clientIPAddress;
    let recheckRequest: any;

    if (isRedirectionFromCC) {
      recheckRequest = recheckSearchData;
    } else {
      const q = recheckSearchData.basicSearch.checkInCheckOutDate;
      const checkindate = q[0].toLocaleDateString('en-US');
      const checkoutdate = q[1].toLocaleDateString('en-US');
      recheckRequest = JSON.parse(JSON.stringify(recheckSearchData)); // deep copy
      const checkInCheckoutDate = [];
      checkInCheckoutDate[0] = checkindate;
      checkInCheckoutDate[1] = checkoutdate;
      recheckRequest.basicSearch.checkInCheckOutDate = checkInCheckoutDate;
    }

    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi,
      recheckRequest, 'api/GuestDetails/RecheckAvailability')
      .pipe(map((data: any) => {
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        let hotelRecheckResult: HotelRecheckViewModel = <HotelRecheckViewModel>{};
        if (data !== null) {
          if (data.result !== null) {
          hotelRecheckResult = data.result;
          } else {
            xmlErrorMessage.message = data.message;
            xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
            xmlErrorMessage.errorCode = data.errorCode;
            hotelRecheckResult.errorModel = xmlErrorMessage;
         }
          return hotelRecheckResult;
        } else { return null; }
      }));
  }

  set sessionId(sessionId: string) {
    this._sessionId = sessionId;
  }

  get sessionId(): string {
    return this._sessionId;
  }
  getHotelDetails(): Observable<HotelDetailsViewModel> {
    let hotel: any;
    if (this.selectedHotel && this.selectedHotel.hotelId) {
      hotel = {
        hotelCode: this.selectedHotel.hotelId,
        requestKey: uuid()
      };
    } else if (localStorage.getItem('selectedHotelForGet')) {
      hotel = {
        hotelCode: JSON.parse(localStorage.getItem('selectedHotelForGet')).hotelId,
        requestKey: uuid()
      };
    }
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , hotel, 'api/Hotel/GetHotelDetails/').pipe(map((data: any) => {
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        let hotelDetailsDto: HotelDetailsViewModel = <HotelDetailsViewModel>{};
        if (data !== null) {
          if (data.result !== null) {
              // hotelDetailsDto = data.hotel;
              hotelDetailsDto = data.result;
              this.isSafeStay = false;
              this.allEmployeesVaccinated = false;
              if (hotelDetailsDto.facilities !== null) {
                hotelDetailsDto.facilities.forEach(facilityGroup => {
                  if (facilityGroup.name === 'Hotel Facility') {
                    facilityGroup.facilityTypes.forEach (facilityType => {
                      if (facilityType.name === 'Safe Stay') {
                        facilityType.facilities.forEach (facility => {
                          if (facility.name === 'Safe Stay - Hotel Standard') {
                            this.safeStayPolicy = CONSTANTS.SafeStayPolicy.hotelStandard;
                            this.isSafeStay = true;
                          } else if (facility.name === 'Safe Stay - Chain Standard') {
                            this.safeStayPolicy = CONSTANTS.SafeStayPolicy.chainStandard;
                            this.isSafeStay = true;
                          } else if (facility.name === 'Safe Stay - Organisation Standard') {
                            this.safeStayPolicy = CONSTANTS.SafeStayPolicy.governmentStandard;
                            this.isSafeStay = true;
                          } else if (facility.name === 'Vaccinated') {
                            this.allEmployeesVaccinated = true;
                          }
                        });
                      }
                    });
                  }
                });
              }
            } else {
              xmlErrorMessage.message = data.message;
              xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
              xmlErrorMessage.errorCode = data.errorCode;
              hotelDetailsDto.errorModel = xmlErrorMessage;
           }
           return hotelDetailsDto;
        } else {
          return null;
        }
      }));
  }

  getRoomDetails(searchData: HotelSearchPanelViewModel, hotelCode: string): Observable<RoomCategoryInfoViewModel> {
    searchData.clientIPAddress = this.clientIPAddress;
    const q = searchData.checkInCheckOutDate;
    const checkindate = q[0].toLocaleDateString('en-US');
    const checkoutdate = q[1].toLocaleDateString('en-US');
    const tempSearchPanel = JSON.parse(JSON.stringify(searchData));
    tempSearchPanel.destination.hotelCode = hotelCode;
    // searchData.destination.hotelCode = hotelCode;
    const checkInCheckoutDate = [];
    checkInCheckoutDate[0] = checkindate;
    checkInCheckoutDate[1] = checkoutdate;
    tempSearchPanel.checkInCheckOutDate = checkInCheckoutDate;
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.xmlSellApi, tempSearchPanel,
      '1.0/Hotel/SearchAvailability').pipe(map((data: any) => {
        let roomDetails: RoomCategoryInfoViewModel = new RoomCategoryInfoViewModel();
        if (data !== null) {
          roomDetails = data.result.hotelList[0].roomCategoryInfo;
          this.sessionId = data.result.sessionId;
          this.selectedHotel = data.result.hotelList[0];
          localStorage.setItem('selectedHotelForGet', JSON.stringify(this.selectedHotel));
        } else {
          roomDetails = <RoomCategoryInfoViewModel>{};
        }
        return roomDetails;
      }));
  }

  getHotelPhotos(hotelCode: string, isSinglePhoto = false): Observable<HotelImagesViewModel[]> {
    let hotel: any;
    hotel = {
      hotelCode: hotelCode,
      isSinglePhoto: isSinglePhoto
    };
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.b2BContentApi, hotel,
      'api/home/GetAllPhotosByHotelCode/').pipe(map((data: any) => {
        let hotelImages: HotelImagesViewModel[];
        if (data !== null) {
          hotelImages = data.result;
        } else {
          hotelImages = null;
        }
        return hotelImages;
      }));
  }

  get selectedHotel(): HotelViewModel {
    return this._hotel;
  }

  set selectedHotel(userSelectedHotel: HotelViewModel) {
    this._hotel = userSelectedHotel;
  }

  get isSafeStay(): boolean {
    return this._isSafeStay;
  }

  set isSafeStay(isSafeStay: boolean) {
    this._isSafeStay = isSafeStay;
  }

  get safeStayPolicy(): string {
    return this._safeStayPolicy;
  }

  set safeStayPolicy(safeStayPolicy: string) {
    this._safeStayPolicy = safeStayPolicy;
  }

  get allEmployeesVaccinated(): boolean {
    return this._allEmployeesVaccinated;
  }

  set allEmployeesVaccinated(allEmployeesVaccinated: boolean) {
    this._allEmployeesVaccinated = allEmployeesVaccinated;
  }

  get selectedRoom(): RoomDetailViewModel {
    return this._room;
  }

  set selectedRoom(userSelectedRoom: RoomDetailViewModel) {
    this._room = userSelectedRoom;
  }

  confirmBooking(bookingRequestViewmodel: BookingRequestViewmodel): Observable<FullBookingResponseDetailsViewmodel> {
    bookingRequestViewmodel.clientIPAddress = this.clientIPAddress;
    this.hotelBookService.bookingRequestDetails = bookingRequestViewmodel;
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , bookingRequestViewmodel, 'api/booking/Book/').pipe(map((data: any) => {
        let bookingResponse: FullBookingResponseDetailsViewmodel = <FullBookingResponseDetailsViewmodel>{};
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        if (data !== null) {
          if (data.result !== null) {
            bookingResponse = data.result;
            this.hotelBookService.fullBookingResponseDetails = data.result;
          } else {
            xmlErrorMessage.message = data.message;
            xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
            xmlErrorMessage.errorCode = data.errorCode;
            bookingResponse.errorModel = xmlErrorMessage;
          }
        } else {
          bookingResponse = null;
        }
        return bookingResponse;
      }));
  }

  cancelBooking(cancelBookingRequestViewmodel: CancelBookingRequestViewmodel): Observable<CancelBookingResponseViewmodel> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , cancelBookingRequestViewmodel, 'api/booking/CancelBooking').pipe(map((data: any) => {
        let cancelBookingResponse: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        if (data !== null) {
          if (data.result !== null) {
            cancelBookingResponse = data.result;
          } else {
            xmlErrorMessage.message = data.message;
            xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
            xmlErrorMessage.errorCode = data.errorCode;
            cancelBookingResponse.errorModel = xmlErrorMessage;
          }
        } else {
          cancelBookingResponse = null;
        }
        return cancelBookingResponse;
      }));
  }

  cancelBookingReservation(cancelBookingRequestViewmodel: CancelBookingRequestViewmodel): Observable<CancelBookingResponseViewmodel> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , cancelBookingRequestViewmodel, 'api/booking/CancelBooking').pipe(map((data: any) => {
        let cancelBookingResponseReservation: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        if (data !== null) {
          if (data.result !== null) {
            cancelBookingResponseReservation = data.result;
            // this.hotelBookService.cancelBookingDetailsReservation = data.result;
          } else {
            xmlErrorMessage.message = data.message;
            xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
            xmlErrorMessage.errorCode = data.errorCode;
            cancelBookingResponseReservation.errorModel = xmlErrorMessage;
          }
        } else {
          cancelBookingResponseReservation = null;
        }
        return cancelBookingResponseReservation;
      }));
  }

  editBooking(fullBookingResponseDetailsViewmodel: FullBookingResponseDetailsViewmodel): Observable<FullBookingResponseDetailsViewmodel> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , fullBookingResponseDetailsViewmodel, 'api/booking/UpdateGuestDetails').pipe(map((data: any) => {
        if (data !== null) {
          return data.result;
        } else {
          return [];
        }
      }));
  }

  confirmBookingHold(holdBookingRequestViewmodel: HoldBookingRequestViewmodel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , holdBookingRequestViewmodel, 'api/Reservation/ConfirmOnHoldBooking').pipe(map((data: any) => {
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        const cancelBookingResponseReservation: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
        if (data !== null) {
          if (data.result !== null) {
            return data.result;
          } else {
            xmlErrorMessage.message = data.message;
            xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
            xmlErrorMessage.errorCode = data.errorCode;
            cancelBookingResponseReservation.errorModel = xmlErrorMessage;
            return cancelBookingResponseReservation;
          }
        } else {
          return false;
        }
      }));
  }

  setCityData(value: any) {
    this._cityData = value;
  }
  set CityData(value: any) {
    this._cityData = value;
  }

  get getCityData(): any {
    return this._cityData;
  }
}


