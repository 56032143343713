import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuTrigger } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { CONSTANTS } from 'src/app/common/constants';
import { Observable } from 'rxjs';
import { ScreenService } from 'src/app/framework/fw/services/screen.service';

@Component({
  selector: 'app-checkin-checkout-calendar',
  templateUrl: './checkin-checkout-calendar.component.html',
  styleUrls: ['./checkin-checkout-calendar.component.css']
})
export class CheckinCheckoutCalendarComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() isAsterisk: boolean;
  @Input() fromDateControl: string;
  @Input() toDateControl: string;
  @Input() isDisabled: string;
  @Input() acivatePreviousDates: string;
  @Input() customLabel: string;
  @Output() fromDateSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() toDateSelection: EventEmitter<any> = new EventEmitter<any>();
  @Input() events: Observable<void>;
  @Input() showCalenderIcon: boolean;
  @Input() enableToday: boolean;
  @Input() maxDate: Date;

  maxDateValue: any;
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  checkinDate: Date;
  checkoutDate: Date;
  todaysDate: NgbDate;
  Math = Math;
  //maxDate: NgbDate;
  minDate: NgbDate;
  checkoutMinDate: NgbDate;
  fromDateLabel: string;
  toDateLabel: string;
  fromDatePlaceholder: string;
  toDatePlaceholder: string;
  resetEventsSubscription: any;
  maxNGBDate: NgbDate;

  @ViewChild('checkindatepickerTrigger') checkindatepickerTrigger: MatMenuTrigger;
  @ViewChild('checkoutdatepickerTrigger') checkoutdatepickerTrigger: MatMenuTrigger;

  constructor(private ngbCalendar: NgbCalendar,public screenService: ScreenService) {
    this.todaysDate = ngbCalendar.getToday();
  }

  ngOnInit() {

    if (this.events) {
      this.resetEventsSubscription = this.events.subscribe(() => this.resetDatepicker());
    }

    this.minDate = (this.acivatePreviousDates === 'true') ? new NgbDate(1900, 1, 1) : this.todaysDate;
    // this.maxDate = (this.acivatePreviousDates === 'true') ? new NgbDate(1900, 1, 1) : this.todaysDate;
    if (this.maxDate) {
      this.maxNGBDate = new NgbDate(this.maxDate.getFullYear(), this.maxDate.getMonth() + 1, this.maxDate.getDate());
    } else {
      this.maxNGBDate = null;
    }
    this.checkoutMinDate = this.todaysDate;
    if (this.form.get(this.fromDateControl)) {

      this.fromDateLabel = this.customLabel
        ? CONSTANTS.datePicker[this.customLabel]['fromDate']['label']
        : CONSTANTS.datePicker[this.fromDateControl]['label'];
      this.fromDatePlaceholder = this.customLabel
        ? CONSTANTS.datePicker[this.customLabel]['fromDate']['placeholder']
        : CONSTANTS.datePicker[this.fromDateControl]['placeholder'];

      if (this.form.get(this.fromDateControl).value) {
        this.fromDate = new NgbDate(this.form.get(this.fromDateControl).value.getFullYear(),
          this.form.get(this.fromDateControl).value.getMonth() + 1, this.form.get(this.fromDateControl).value.getDate());
        const dt = new Date(JSON.parse(JSON.stringify(this.form.get(this.fromDateControl).value)));
        dt.setDate(dt.getDate() + (this.enableToday ? 0 : 1));
        this.checkoutMinDate = new NgbDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
      }
    }
    if (this.form.get(this.toDateControl)) {
      this.toDateLabel = this.customLabel
        ? CONSTANTS.datePicker[this.customLabel]['toDate']['label']
        : CONSTANTS.datePicker[this.toDateControl]['label'];
      this.toDatePlaceholder = this.customLabel
        ? CONSTANTS.datePicker[this.customLabel]['toDate']['placeholder']
        : CONSTANTS.datePicker[this.toDateControl]['placeholder'];

      if (this.form.get(this.toDateControl).value) {
        this.toDate = new NgbDate(this.form.get(this.toDateControl).value.getFullYear(),
          this.form.get(this.toDateControl).value.getMonth() + 1,
          this.form.get(this.toDateControl).value.getDate());
      }
    }
  }

  resetDatepicker() {
    this.fromDate = null;
    this.toDate = null;
    this.checkoutMinDate = null;
  }

  onCheckInDateSelection(date: NgbDate) {
    if ((!this.fromDate && !this.toDate) || (this.fromDate && !this.toDate) || (this.toDate && date.before(this.toDate))) {
      this.fromDate = date;
    } else if (this.toDate && date.after(this.toDate) || this.toDate && date.equals(this.toDate)) {
      this.toDate = null;
      this.checkoutDate = null;
      this.form.get(this.toDateControl).setValue(null);
      this.fromDate = date;
    } else {
      this.fromDate = date;
    }

    this.checkinDate = new Date(date.year, date.month - 1, date.day);
    this.checkinDate.setMinutes((this.checkinDate.getTimezoneOffset() * -1));
    const dt = new Date(JSON.parse(JSON.stringify(this.checkinDate)));
    dt.setDate(dt.getDate() + (this.enableToday ? 0 : 1));
    this.checkoutMinDate = new NgbDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
    this.form.get(this.fromDateControl).setValue(this.checkinDate);
    this.checkindatepickerTrigger.closeMenu();
    if (!this.toDate) {
      this.checkoutdatepickerTrigger.openMenu();
      // to set check-out date = check-in date + 1
      this.checkoutDate = dt;
      this.toDate = this.checkoutMinDate;
      this.form.get(this.toDateControl).setValue(this.checkoutDate);
    }
    this.fromDateSelection.emit();
  }

  onCheckOutDateSelection(date: NgbDate) {
    if ((!this.fromDate && !this.toDate) || (this.fromDate && !this.toDate && date.after(this.fromDate)) ||
      ((this.toDate && date.after(this.fromDate))) || !this.fromDate) {
      this.toDate = date;
    }
    this.checkoutdatepickerTrigger.closeMenu();
    this.checkoutDate = new Date(date.year, date.month - 1, date.day);
    this.checkoutDate.setMinutes((this.checkoutDate.getTimezoneOffset() * -1));
    this.form.get(this.toDateControl).setValue(this.checkoutDate);
    this.toDateSelection.emit();
  }

  isHovered(date: NgbDate, isCheckIn: boolean) {
    if (isCheckIn) {
      return this.fromDate && this.toDate && this.hoveredDate && date.before(this.toDate) && date.after(this.hoveredDate)
        && date.after(this.todaysDate);
    } else {
      return this.fromDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }
    // add !toDate check to remove trail on hover.
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate, isCheckIn: boolean) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date, isCheckIn);
  }

  calendarOpened() {
    this.hoveredDate = null;
  }

  ngOnDestroy() {
    if (this.resetEventsSubscription) {
      this.resetEventsSubscription.unsubscribe();
    }
  }

}
