import { ErrorCodes } from './../../../../../common/errorcodes';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CancelBookingResponseViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';
import { HotelBookService } from '../../services/hotel-book.service';
import { FullBookingResponseDetailsViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-response-viewmodel';
import {
  ReservationDetailsRequestViewModel
} from 'src/app/b2b/viewmodels/reservation/reservation-details/reservationdetailsrequestviewmodel';
import { ReservationDataService } from '../../services/reservation-data.service';
import { JarvisError } from 'src/app/common/jarviserror';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { ReservationType, Document, DocumentTypes } from '../../../../../common/enum';
import { DialogsService } from '../../dialogs/dialogs.service';
import { CONSTANTS } from 'src/app/common/constants';
import { EmailPdfRequestViewModel } from 'src/app/b2b/viewmodels/reservation/invoice-voucher/emailpdfrequestviewmodel';
import { InvoiceAndVouchers } from 'src/app/b2b/viewmodels/reservation/invoice-voucher/invoiceandvouchers';
import { ScreenService } from '../../../../../framework/fw/services/screen.service';
@Component({
  selector: 'app-reservation-cancel-summary.html',
  templateUrl: 'reservation-cancel-summary.html',
  styleUrls: ['./reservation-details.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class ReservationBookingCancelSummary implements OnInit, OnDestroy {
  cancelBookingDataReservation: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
  reservationDetails: FullBookingResponseDetailsViewmodel;
  totalNoOfAdults: number;
  totalNoOfChildren: number;
  nights: number;
  nationality: string;
  nationalityList: string;
  agencyId: string;
  agencyReferenceNo: string;
  bookingId: string;
  isDataLoaded: boolean;
  subscriptions: Subscription[] = [];
  apiError: boolean;
  todaysDate: string;
  checkinDate: string;
  bookingStatus: string;
  totalPrice: number;
  currency: string;
  cancelFree: boolean;
  cancelStatus: string;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  cancelDocumentId: string;
  invoiceAndVouchersList: InvoiceAndVouchers[];
  agencyInvoice: InvoiceAndVouchers;
  customerInvoice: InvoiceAndVouchers;
  emailPdfRequest: EmailPdfRequestViewModel;
  reservationDetailsStatus = CONSTANTS.reservationDetailsStatus;

  constructor(private hotelBookService: HotelBookService,
    private reservationDataService: ReservationDataService,
    private dialog: MatDialog, private activatedRoute: ActivatedRoute, private datePipe: DatePipe,
    private userProfileService: UserProfileService, private dialogsService: DialogsService,
    private router: Router,public screenService: ScreenService) {
  }
  ngOnInit() {
    this.cancelBookingDataReservation = this.hotelBookService.cancelBookingDetailsReservation;
    this.apiError = false;
    this.bookingId = this.cancelBookingDataReservation.bookingId;
    this.cancelDocumentId = this.cancelBookingDataReservation.documentID;
    this.agencyId = this.userProfileService.getBasicUserInfo().agencyId.toString();
    this.isDataLoaded = false;
    this.totalPrice = 0;
    this.totalNoOfAdults = 0;
    this.totalNoOfChildren = 0;
    this.cancelStatus = this.cancelBookingDataReservation.bookingStatus;
    this.getReservationDetails();
    this.showCancelType();
    // this.showCancelStatus();
    this.getInvoiceAndVouchersList(this.bookingId, this.cancelDocumentId);
  }
  getReservationDetails() {
    window.scroll(0, 0);
    const reservationDetailsRequest = new ReservationDetailsRequestViewModel;
    reservationDetailsRequest.agencyId = this.agencyId;
    reservationDetailsRequest.agencyReservationNo = this.cancelBookingDataReservation.agencyReferenceNumber;
    reservationDetailsRequest.mgReservationNo = this.cancelBookingDataReservation.bookingId;
    const subscription = this.reservationDataService.getReservationDetailsForB2B(reservationDetailsRequest).subscribe(reservationData => {
      this.isDataLoaded = true;
      this.reservationDetails = reservationData;
      this.nationality = this.cancelBookingDataReservation.nationality;
      this.reservationDetails.bookingDetails.hotels.roomDetails.rooms.forEach(room => {
        this.totalPrice = this.totalPrice + room.grossPrice;
        this.totalNoOfAdults = this.totalNoOfAdults + room.noOfAdults;
        this.totalNoOfChildren = this.totalNoOfChildren + room.noOfChild;
      });
      this.checkinDate = this.datePipe.transform(this.reservationDetails.bookingDetails.checkIn);
      const checkIn = new Date(this.reservationDetails.bookingDetails.checkIn);
      const checkOut = new Date(this.reservationDetails.bookingDetails.checkOut);
      this.nights = Math.round(Math.abs(checkOut.getTime() - checkIn.getTime()) / (24 * 60 * 60 * 1000));
      this.currency = this.reservationDetails.bookingDetails.currency;
    }, (error: JarvisError) => {
      this.isDataLoaded = true;
      this.apiError = true;
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    });
    this.subscriptions.push(subscription);
  }

  // showCancelStatus() {
  //   if (this.cancelStatus === 'CANCELCHARGE' || this.cancelStatus === 'CANCELCONF') {
  //     this.cancelStatus = 'CANCELLED';
  //   }
  // }

  showCancelType() {
    if (Number(this.cancelBookingDataReservation.chargedAmount) === 0) {
      this.cancelFree = true;
    } else { this.cancelFree = false; }
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
  }
  getInvoiceAndVouchersList(bookingId: string, documentId: string) {
    this.showSpinner();
    const subscription = this.reservationDataService.getInvoiceAndVouchers(bookingId, documentId).subscribe((invoiceAndVouchersList) => {
      this.invoiceAndVouchersList = invoiceAndVouchersList;
      this.invoiceAndVouchersList.forEach(element => {
        if (element.documentType.toLowerCase() === CONSTANTS.documentType.agency.toLowerCase()) {
          if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
            this.agencyInvoice = element;
          }
        } else if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
          this.customerInvoice = element;
        }
      });
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Getting Reservation- getInvoiceAndVouchersList. ${error.friendlyMessage}`);
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  buildDownloadOrSendEmailRequest(reservationType, doc, docType) {
    this.emailPdfRequest = new EmailPdfRequestViewModel();
    this.emailPdfRequest.agencyId = Number(this.agencyId);
    this.emailPdfRequest.MGReservationNumber = this.bookingId;
    this.emailPdfRequest.reservationDocId = this.cancelDocumentId;

    if (reservationType.toUpperCase() === CONSTANTS.reservationType.New) {
      this.emailPdfRequest.reservationType = ReservationType.New;
    } else {
      this.emailPdfRequest.reservationType = ReservationType.Cancel;
    }

    if (doc.toUpperCase() === CONSTANTS.document.All) {
      this.emailPdfRequest.document = Document.All;
    } else if (doc.toUpperCase() === CONSTANTS.document.Invoice) {
      this.emailPdfRequest.document = Document.Invoice;
    } else if (doc.toUpperCase() === CONSTANTS.document.Voucher) {
      this.emailPdfRequest.document = Document.Voucher;
    } else {
      this.emailPdfRequest.document = Document.Itinerary;
    }

    if (docType.toUpperCase() === CONSTANTS.documentType.agency) {
      this.emailPdfRequest.documentType = DocumentTypes.Agency;
    } else if (docType.toUpperCase() === CONSTANTS.documentType.customer) {
      this.emailPdfRequest.documentType = DocumentTypes.Customer;
    } else {
      this.emailPdfRequest.documentType = DocumentTypes.Hotel;
    }
    this.emailPdfRequest.isB2B = true;
  }

  downloadPDF(reservationType, doc, docType) {
    this.showSpinner();
    this.buildDownloadOrSendEmailRequest(reservationType, doc, docType);

    const subscription = this.reservationDataService.generatePDF(this.emailPdfRequest).subscribe((data) => {
      const blob = data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(blob);
      } else {  // for other browsers
        const fileURL = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = fileURL;
        a.download = this.bookingId + '_' + docType + '_' + doc;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      }
    },
    (error: JarvisError) => {
      this.hideSpinner();
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `Error Code ${ErrorCodes.GenerateReservationDocument}. ${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    },
    () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }
  goBack() {
    this.router.navigate(['authenticated/hotelmgmt/hotelinfo']);
  }
  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  ngOnDestroy() {
    this.dispose();
  }
}
