import { ErrorMessageViewModel } from '../errorMessageViewModel';
export class FullBookingResponseDetailsViewmodel {
        status: boolean;
        responseTime: number;
        sessionID: string;
        bookingDetails: BookingDetailsViewmodel;
        xsi: string;
        xsd: string;
        errorModel?: ErrorMessageViewModel;
}

export class BookingDetailsViewmodel {
        nationality: string;
        checkIn: string;
        checkOut: string;
        currency: string;
        hotels: HotelDetailsViewmodel;
        mgBookingID: string;
        agencyBookingID: string;
        mgBookingVersionID: string;
        agencyVoucherNo: string;
        agencyVoucherDate: string;
        status: string;
        isOpApprove: string;
        specialRequest: string;
        paymentBy: string;
        lastDateToConfirm: string;
        timeZoneOffset: string;
        transactionRefNo: string;
        mgPoints: number;
        supplierPaymentTypeCode: string;
}

export class HotelDetailsViewmodel {
        landmark: string;
        longitude: number;
        latitude: number;
        mgPreferred: number;
        mGPoints: string;
        isWheelchair: boolean;
        isGym: boolean;
        isExtrabed: boolean;
        isSafety: boolean;
        isPool: boolean;
        isWIFI: boolean;
        ihainId: number;
        iandmarkId: number;
        areaId: number;
        roomDetails: RoomDetailsViewmodel;
        photos: PhotoViewmodel[];
        address: string;
        description: string;
        code: string;
        hotelName: string;
        hotelRating: string;
}

export class RoomDetailsViewmodel {
        commission: number;
        avgNightPrice: number;
        grossPrice: number;
        netPrice: number;
        canHold: boolean;
        isOnHold: boolean;
        canAmend: boolean;
        availFlag: boolean;
        promoName: string;
        packageRate: boolean;
        promoCode: string;
        cancellationPolicyType: string;
        mealPlanName: string;
        mealPlan: string;
        name: string;
        code: string;
        supplier: string;
        rooms: RoomsViewmodel[];
        cancellationPolicies: CancellationPolicyDetailsViewmodel[];
        totalNoOfRooms: number;
        messages: string[];
}

export class PhotoViewmodel {
        isMain?: boolean;
        url: string;
}

export class RoomsViewmodel {
        paxDetails: PaxDetails[];
        nightlyRates: NightlyRates[];
        discountRates: DiscountRates[];
        packageRate: boolean;
        roomNo: number;
        rateKey: string;
        noOfAdults: number;
        noOfChild: number;
        extraBed: boolean;
        extraBedCount: number;
        status: string;
        roomBookingID: string;
        netPrice: number;
        grossPrice: number;
        avgNightPrice: number;
        commission: number;
        message: string;
}

export class CancellationPolicyDetailsViewmodel {
        fromDate: string;
        toDate: string;
        amount: string;
        nights: string;
        percent: string;
        noShow: boolean;
}

export class PaxDetails {
        salutation: string;
        firstName: string;
        lastName: string;
        age: number;
        id: string;
}

export class NightlyRates {
        srNo: number;
        netPrice: number;
        grossPrice: number;
        b2BMarkup: number;
}

export class DiscountRates {
        code: string;
        name: string;
        amount: number;
        description: string;
}
