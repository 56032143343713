import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { ConfigurationService } from './configuration.service';
import { map } from 'rxjs/operators';
import { IHomeCarouselItem, HomeCarouselService } from 'src/app/framework/fw/services/home-carousel.service';
import { Observable, of, queueScheduler } from 'rxjs';
import { QueueScheduler } from 'rxjs/internal/scheduler/QueueScheduler';
import { Utilities } from '../../utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class HomeCarouselDataService {

  geoLocationAPI: string;
  bannersRequest = {
    countryCode: null,
    countryName: null
  };

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService,
    private homeCarouselService: HomeCarouselService,
    private http: HttpClient) { }

  initializeHomeCarousel() {

    this.geoLocationAPI = this.configurationService.config.geoLocationAPI;

    if (!Utilities.isNullOrEmpty(this.geoLocationAPI)) {
      this.http.get(this.geoLocationAPI).subscribe((res: any) => {
        this.bannersRequest.countryCode = res ? res.country_code : null;
        this.bannersRequest.countryName = res ? res.country_name : null;
      }).add(() => {
        this.getBanners(this.bannersRequest);
      });
    } else {
      this.getBanners(this.bannersRequest);
    }
  }

  getBanners(bannersRequest) {
    this.homeCarouselService.items$ = new Observable<IHomeCarouselItem[]>();
      if (this.homeCarouselService.items$) {
        this.homeCarouselService.items$ = this.authenticatedHttpService.post
          (this.configurationService.config.baseUrls.bookingApi, bannersRequest, 'api/Banners/GetAll/')
          .pipe(
            map((data: any) => {
              if (data !== null) {
                // data.result.forEach((element, index) => {
                //   data.result[index].imagePath = element.imagePath ?
                //     this.configurationService.config.baseUrls.contentUrl + element.imagePath : '';
                // });
                return data.result;
              } else {
                return [];
              }
            }));
      }
  }
}
