import { PipesModule } from '../pipes/pipes.module';
import { GalleriaModule } from 'primeng/galleria';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyCreditComponent } from './agency-credit/agency-credit.component';
import { MaterialModule } from '../../../../common/material/material.module';
import { ChartModule } from 'primeng/chart';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BookingSummaryComponent } from './booking-summary/booking-summary.component';
import { HotelSearchComponent } from './hotel-search/hotel-search.component';
import { HighlightPipe } from '../pipes/hotel-search-highlight-pipe';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { BookingStatusPipe } from '../pipes/booking-status.pipe';
import { StarComponent } from './star/star.component';
import { RoomListComponent } from './room-list/room-list.component';
import { RouterModule } from '@angular/router';
import { ReservationDetailsComponent, ReservationHoldConfirmDialog } from './reservation-details/reservation-details.component';
import { ImageGalleriaComponent } from './image-galleria/image-galleria.component';
import { ShowHideColumnsComponent } from './show-hide-columns/show-hide-columns.component';
import { CheckoutConfirmationComponent } from './checkout-confirmation/checkout-confirmation.component';
import { ReadMoreViewComponent } from './read-more-view/read-more-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselComponent } from './ngb-carousel/ngb-carousel.component';
import { CheckinCheckoutCalendarComponent } from './checkin-checkout-calendar/checkin-checkout-calendar.component';
import {ReservationBookingCancelPreviewDialog,
  ReservationBookingCancelConfirmDialog} from './reservation-details/ReservationBookingCancelPreviewDialog.component';
import { ReservationBookingCancelSummary } from './reservation-details/ReservationBookingCancelSummary.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { MsgForMobileMenuComponent } from './msg-for-mobile-menu/msg-for-mobile-menu/msg-for-mobile-menu.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

@NgModule({
  imports: [
    CommonModule,
    ChartModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    CalendarModule,
    GalleriaModule,
    RouterModule,
    PipesModule,
    NgbModule,
    NgxUsefulSwiperModule
  ],
  declarations: [AgencyCreditComponent, BookingSummaryComponent, BookingStatusPipe,
    HighlightPipe, HotelSearchComponent, StarComponent, RoomListComponent, ReservationDetailsComponent,
    ReservationDetailsComponent,
    ImageGalleriaComponent, ShowHideColumnsComponent, CheckoutConfirmationComponent, ReadMoreViewComponent, NgbCarouselComponent,
    CheckinCheckoutCalendarComponent, ReservationBookingCancelPreviewDialog,
    ReservationBookingCancelConfirmDialog, ReservationBookingCancelSummary, BreadCrumbComponent, ReservationHoldConfirmDialog, MsgForMobileMenuComponent],
    entryComponents: [ReservationBookingCancelPreviewDialog, ReservationBookingCancelConfirmDialog, ReservationHoldConfirmDialog],
  exports: [AgencyCreditComponent, BookingSummaryComponent, StarComponent, HighlightPipe,
    RoomListComponent, HotelSearchComponent, ReservationDetailsComponent, ImageGalleriaComponent, ShowHideColumnsComponent,
    CheckoutConfirmationComponent, ReadMoreViewComponent, NgbCarouselComponent, CheckinCheckoutCalendarComponent, BreadCrumbComponent]
})
export class ComponentsModule { }
