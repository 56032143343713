import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomeService } from '../../common/b2b-shared/services/home.service';
import { FAQGroupViewModel } from '../../viewmodels/faq/faqgroupviewmodel';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit, OnDestroy {

  faqGroupList: FAQGroupViewModel[];
  faqGroupListClone: FAQGroupViewModel[] = [];
  searchText = new FormControl('');
  filteredData: FAQGroupViewModel[];
  isSearch: boolean;
  noRecordsFound: boolean;
  subscriptions: Subscription[] = [];

  constructor(private homeService: HomeService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.getFAQs();
  }

  getFAQs() {
    const subscription = this.homeService.getFAQs().subscribe(data => {
      data.forEach(faqGroup => {
        faqGroup.isExpanded = false;
        faqGroup.faqList.forEach(faq => {
          faq.isExpanded = false;
        });
      });
      this.faqGroupList = data;
      this.faqGroupListClone = JSON.parse(JSON.stringify(this.faqGroupList)); // deep copy
    });
    this.subscriptions.push(subscription);
  }

  searchFAQs() {
    this.isSearch = true;
    this.noRecordsFound = false;
    this.faqGroupList = JSON.parse(JSON.stringify(this.faqGroupListClone));
    const searchText = this.searchText.value;
    function getFilteredQuestionAnswers(faqGroup) {
      let isTrue;
      isTrue = false;
      const faqData = faqGroup.faqList.filter(faq => {
        if (faq.question.toLowerCase().includes(searchText) || faq.answer.toLowerCase().includes(searchText)) {
          isTrue = true;
          faq.question = highlight(faq.question);
          faq.answer = highlight(faq.answer);
          faq.isExpanded = true;
          return faq;
        }
      });
      if (isTrue) {
        faqGroup.name = highlight(faqGroup.name);
        faqGroup.isExpanded = true;
        faqGroup.faqList = faqData;
        return faqGroup;
      }
    }

    this.filteredData = this.faqGroupList.filter(faqGroup => {
      if (getFilteredQuestionAnswers(faqGroup) || faqGroup.name.toLowerCase().includes(searchText)) {
        faqGroup.name = highlight(faqGroup.name);
        faqGroup.isExpanded = true;
        return faqGroup;
      }
    });

    if (this.filteredData.length === 0) {
      this.noRecordsFound = true;
    }

    function highlight(text) {
      // to remove highlights from previously searched text
      text = text.replace(new RegExp('<span class="highlight-text">', 'g'), '').replace(new RegExp('<\/span>', 'g'), '');
      // highlight text
      const pattern = searchText.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return searchText ? text.replace(regex, match => '<span class="highlight-text">' + match + '</span>') : text;
    }

  }

  resetSearch() {
    this.searchText.setValue(null);
    this.noRecordsFound = false;
    this.faqGroupList = JSON.parse(JSON.stringify(this.faqGroupListClone));
    this.isSearch = false;
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }

}
