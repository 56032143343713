import { Injectable } from '@angular/core';
import { GoogleAnalyticsViewModel, GASearchParametersViewModel } from '../../viewmodels/googleanalyticsviewmodel';
// declare let ga: Function; // Declare ga as a function
declare var gtag;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }


  public eventEmitter(googleAnalyticsViewModel: GoogleAnalyticsViewModel) {

    gtag('event', googleAnalyticsViewModel.eventAction, {
      'event_category': googleAnalyticsViewModel.eventCategory,
      'event_label': googleAnalyticsViewModel.eventLabel,
      'eventValue': googleAnalyticsViewModel.eventValue
    });

  }

  public customSearchEventEmitter(gASearchParametersViewModel: GASearchParametersViewModel,
    googleAnalyticsViewModel: GoogleAnalyticsViewModel) {
    gtag('event', googleAnalyticsViewModel.eventAction, {
      'event_category': googleAnalyticsViewModel.eventCategory,
      'City': gASearchParametersViewModel.city,
      'Hotel Name': gASearchParametersViewModel.hotel,
      'Number of Rooms': gASearchParametersViewModel.totalNoOfRooms,
      'Number of Nights': gASearchParametersViewModel.nightCount,
      'Check-in Date': gASearchParametersViewModel.checkInDate
    });
  }

  public customHotelBookBrowseEventEmitter(hotelName: string,
    googleAnalyticsViewModel: GoogleAnalyticsViewModel) {
    gtag('event', googleAnalyticsViewModel.eventAction, {
      'event_category': googleAnalyticsViewModel.eventCategory,
      'Hotel Name': hotelName,
    });
  }

  public customUserDetailsEventEmitter(agentId: string, agentName: string, agencyId: number, agencyCode: string,
    googleAnalyticsViewModel: GoogleAnalyticsViewModel) {
    gtag('event', googleAnalyticsViewModel.eventAction, {
      'Agent ID': agentId,
      'Agent Name': agentName,
      'Agency ID': agencyId,
      'Agency Code': agencyCode
    });
  }

  public customUserInfoEventEmitter(userInfo: string, googleAnalyticsViewModel: GoogleAnalyticsViewModel) {
    gtag('event', googleAnalyticsViewModel.eventAction, {
      'Custom User Info': userInfo
    });
  }
}
