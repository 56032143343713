import { CONSTANTS } from './../../../../../common/constants';
import { UserProfileService } from './../../../../../common/shared/services/user-profile.service';
import { Component, OnInit, Input } from '@angular/core';
import { ReservationDetailsViewModel } from 'src/app/b2b/viewmodels/reservation/reservation-details/reservationdetailsviewmodel';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout-confirmation',
  templateUrl: './checkout-confirmation.component.html',
  styleUrls: ['./checkout-confirmation.component.css']
})
export class CheckoutConfirmationComponent implements OnInit {

  @Input() reservationDetails: ReservationDetailsViewModel;
  totalPrice: number;
  totalNoOfAdults: number;
  totalNoOfChildren: number;
  ViewBookingsAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewBookings);
  HotelsBookPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.HotelsBookProperties);

  constructor(private router: Router, private userProfileService: UserProfileService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.totalPrice = 0;
    this.totalNoOfAdults = 0;
    this.totalNoOfChildren = 0;
    this.reservationDetails.accommodationDetails.roomDetails.forEach(room => {
      this.totalPrice = this.totalPrice + room.totalNightsSellingPrice;
      this.totalNoOfAdults = this.totalNoOfAdults + room.noOfAdults;
      this.totalNoOfChildren = this.totalNoOfChildren + room.noOfChilds;
      room.sellingNightlyPrices.sort(function (a, b) {
        return +new Date(a.fullDateAlternateKey) - +new Date(b.fullDateAlternateKey);
      });
    });
  }

  showBookingStatus(status) {
    switch (status) {
      case 'CONF': return 'Confirmed';
      case 'NOTCONF': return 'Not Confirmed';
      case 'CANCEL': return 'Cancel';
      case 'CANCELCONF': return 'CancelConf';
      case 'CANCELCHARGE': return 'CancelCharge';
    }
  }

  goToReservationList() {
    this.router.navigate(['/authenticated/bookingmgmt/reservationList'],
      { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
  }

}
