export let CONSTANTS = {
screen: {
  largeBreakpoint: 839.98,
  mediumBreakpoint: 500,
  screenWidth: 1000 ,
  screenHeight: 800,
  mediumLargeBreakpoint:600,
  mobileLandscapeBreakpoint: 1280
}
};
