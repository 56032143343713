export let CONSTANTS = {
  idrCurrencyCode: 'IDR',
  vndCurrencyCode: 'VND',
  digitsInfo: '1.2-2',
  application: {
    backoffice: 'backoffice',
    extranet: 'extranet',
    b2b: 'b2b'
  },
  userType: {
    mgUser: 1,
    hotelUser: 2,
    agentUser: 3
  },
  operation: {
    read: 'read',
    create: 'create',
    edit: 'edit',
    delete: 'delete'
  },
  dummyPassword: 'Mg@11111111111111',
  toasterTimeOut: {
    duration: 1500
  },
  userTypeName: {
    mguser: 'MGUser',
    agentuser: 'AgentUser',
    hoteluser: 'HotelUser'
  },
  bookingStatus: {
    NOTCONF: 'Not confirmed',
    CANCELCHARGE: 'Cancelled Charged',
    CONF: 'Confirmed',
    CANCEL: 'Cancel Request',
    CANCELCONF: 'Cancelled Free',
    ONHOLD: 'On Hold',
    RESERVED: 'On Request',
    INPROGRESS: 'In Progress'
  },
  routeList: {
    home: '/home',
    default: '/default',
    hotelInfo: '/authenticated/hotelmgmt/hotelinfo',
    hotellist: '/authenticated/hotelmgmt/hotellist',
    hotelDetails: '/authenticated/hotelmgmt/hoteldetails',
    safeStayPolicies: '/siteinfomgmt/safestaypolicies',
    safeStayInfo: '/siteinfomgmt/safestayinfo'
  },
  unAuthenticatedRouteList: [
    '/home',
    '/siteinfomgmt/termsandconditions',
    '/siteinfomgmt/dealinfo',
    '/siteinfomgmt/contact',
    '/siteinfomgmt/safestaypolicies',
    '/siteinfomgmt/safestayinfo'
  ],
  authenticatedExceptionList: [
    '/siteinfomgmt/termsandconditions',
    '/authenticated/hotelmgmt/hoteldetails',
    '/authenticated/payment/paymentsuccess',
    '/authenticated/payment/paymentcancelled',
    '/authenticated/payment/paymentfailed',
    '/siteinfomgmt/safestaypolicies',
    '/siteinfomgmt/safestayinfo',
    '/authenticated/deals/chaindealinfo'
  ],
  paymentGatewayExceptionList: [
    '/authenticated/payment/paymentsuccess',
    '/authenticated/payment/paymentcancelled',
    '/authenticated/payment/paymentfailed'
  ],
  reservationDateType: {
    BookingDate: { id: 'BookingDate', value: 'Booking Date' },
    CheckInDate: { id: 'CheckInDate', value: 'CheckIn Date' },
    CheckOutDate: { id: 'CheckOutDate', value: 'CheckOut Date' }
  },
  // // FeatureTypeId's  are the same Id's as defined in FeatureType enum
  featuretypeid: {
    HotelProfile: 21,
    Templates: 14,
    Contracts: 12,
    Users: 1007,
    Agents: 1073,
    HotelSupplier: 1072,
    MG: 1071,
    RolesAndPermissions: 1074,
    BOReservation: 1004,
    ExchangeRates: 1031,
    SystemConfigurations: 1081,
    ClearApplicationCache: 1082,
    UserManagement: 4,
    Reports: 1009,
    Agency: 1021,
    Branch: 1022,
    Agent: 1023,
    CacheConfiguration: 1463,
    ExcludeHotels: 1024,
    PaymentReceived: 1025,
    Supplier: 1061,
    Country: 1561,
    Nationality: 1563,
    HotelType: 1564,
    HotelChain: 1565,
    HotelBrand: 1566,
    MealPlan: 1567,
    City: 1562,
    Markup: 1051,
    EmailTemplate: 1472,
    HotelFacilities: 1568,
    Currency: 1571,
    Designation: 1572,
    Role: 1573,
    Area: 1574,
    Landmark: 1575,
    RoomAmenities: 1576,
    ContractTerms: 1577
  },
  reservationStatus: {
    NOTCONF: 'NOTCONF',
    CANCELCHARGE: 'CANCELCHARGE',
    CONF: 'CONF',
    CANCEL: 'CANCEL',
    CANCELCONF: 'CANCELCONF',
    RESERVED: 'RESERVED',
    ONHOLD: 'ONHOLD'
  },
  hotelSearch: {
    searchParameterLength: 2,
    IndonesiaGuestNationalityCode: 'MA05110065'
  },
  userRole: {
    AGENCYUSER: 'AgencyUser',
    SUPERUSER: 'SuperUser',
    CALLCENTER: 'CallCenter'
  },
  sortDirection: {
    discending: -1,
    ascending: 1
  },
  listingPage: {
    pagesize: 30,
    reservationPageSize: 20
  },

  policies: {
    bedFree: 1,
    bedPaid: 2,
    messages: {
      noCotCapacity: 'Cots are not allowed for this property.',
      cotCapacityAvailable: 'Cots are allowed for this property.',
      noExtraBed: 'Extra Beds are not allowed for this property.',
      extraBedAvailable: 'Extra Beds are allowed for this property.',
      Free: 'FREE',
      Paid: 'PAID'
    },
  },
  faclities: {
    parking: 'Parking',
    pets: 'Pets',
    reserveParking: 'Do guests need to reserve a parking space?',
    parkingAvailable: 'Is parking available for guests?',
    messages: {
      petsAllowed: 'Pets are Allowed',
      noPets: 'Pets are not Allowed',
      parkingAvailable: 'Parking is Available',
      noParking: 'Parking not Available',
      reservedParking: 'Guests need to reserve a parking space'
    },
  },
  DATE_FORMATS: {
    parse: {
      dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
      dateInput: 'input',
      monthYearLabel: { year: 'numeric', month: 'short' },
      dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
      monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
  },
  arrCheckInCheckOut: [
    { Value: '00:00' }, { Value: '00:30' }, { Value: '01:00' },
    { Value: '01:30' }, { Value: '02:00' }, { Value: '02:30' },
    { Value: '03:00' }, { Value: '03:30' }, { Value: '04:00' },
    { Value: '04:30' }, { Value: '05:00' }, { Value: '05:30' },
    { Value: '06:00' }, { Value: '06:30' }, { Value: '07:00' },
    { Value: '07:30' }, { Value: '08:00' }, { Value: '08:30' },
    { Value: '09:00' }, { Value: '09:30' }, { Value: '10:00' },
    { Value: '10:30' }, { Value: '11:00' }, { Value: '11:30' },
    { Value: '12:00' }, { Value: '12:30' }, { Value: '13:00' },
    { Value: '13:30' }, { Value: '14:00' }, { Value: '14:30' },
    { Value: '15:00' }, { Value: '15:30' }, { Value: '16:00' },
    { Value: '16:30' }, { Value: '17:00' }, { Value: '17:30' },
    { Value: '18:00' }, { Value: '18:30' }, { Value: '19:00' },
    { Value: '19:30' }, { Value: '20:00' }, { Value: '20:30' },
    { Value: '21:00' }, { Value: '21:30' }, { Value: '22:00' },
    { Value: '22:30' }, { Value: '23:00' }, { Value: '23:30' }
  ],
  noOfBabyCot: [
    { Value: '0' }, { Value: '1' }, { Value: '2' },
    { Value: '3' }, { Value: '4' }, { Value: '5' },
    { Value: '6' }, { Value: '7' }, { Value: '8' },
    { Value: '9' }
  ],
  SpecialRequestConstants: {
    earlyCheckIn: 'Early Check-in at',
    lateCheckOut: 'Late Check-out at',
    connectingRoom: 'Connecting Room',
    smokingRoom: 'Smoking Room',
    nonSmokingRoom: 'Non Smoking Room',
    sameFloorRoom: 'Room On The Same Floor',
    requestBabyCot: 'Request Baby Cot'
  },
  reservationDetailsStatus: {
    NOTCONF: 'NOTCONF',
    CANCELCHARGE: 'CANCELCHARGE',
    CONF: 'CONF',
    CANCEL: 'CANCEL',
    CANCELCONF: 'CANCELCONF',
    RESERVED: 'RESERVED',
    ONHOLD: 'ONHOLD',
    INPROGRESS: 'INPROGRESS'
  },
  documentType: {
    agency: 'AGENCY',
    customer: 'CUSTOMER',
    hotel: 'HOTEL'
  },
  reservationType: {
    New: 'NEW',
    Cancel: 'CANCEL'
  },
  document: {
    All: 'ALL',
    Invoice: 'INVOICE',
    Voucher: 'VOUCHER',
    Itinerary: 'ITINERARY'
  },
  excelExport: {
    fetchNextForExcel: 50000
  },
  promotionTypes: {
    BasicDeal: 'Basic Deal',
    LastMinute: 'Last Minute',
    EarlyBirds: 'Early Bird',
    DealOfTheDay: 'Deal Of The Day',
    FreeNights: 'Free Night',
    Opaque: 'Opaque'
  },
  favoriteDestinationImageIndexes: [0, 5, 7, 11],
  routeListForCurrencyDropdown: [
    '/home',
    '/siteinfomgmt/dealinfo',
    '/authenticated/deals/dealinfo',
    '/authenticated/hotelmgmt/hotelinfo',
    '/authenticated/hotelmgmt/hotellist',
    '/authenticated/hotelmgmt/hoteldetails',
  ],
  markup: {
    tab: {
      branch: { id: 1, label: 'Branch' },
      countrymarkup: { id: 2, label: 'Destination Country' },
      citymarkup: { id: 3, label: 'Destination City' },
      hotelmarkup: { id: 4, label: 'Hotel' }
    },
    markupMaxAmount: 99999999,
    markupMaxPercent: 500,
    markupMinAmount: 0,
    markupMinPercent: 0,
    errorMessage: {
      title: 'Error Message',
      cityMsg: 'selected Destination Cities',
      countryMsg: 'selected Destination Countries',
      hotelMsg: 'selected Hotels',
    },
    dateTypeList: [
      { label: 'Created Date', value: 'CreatedDate' },
      { label: 'Booking Date', value: 'Check-in' },
      { label: 'Modified Date', value: 'ModifiedDate' }
    ]
  },
  datePicker: {
    checkInDate: { label: 'Check-in', placeholder: 'Check-in Date' },
    checkOutDate: { label: 'Check-out', placeholder: 'Check-out Date' },
    fromDate: { label: 'Booking From', placeholder: 'Booking From' },
    toDate: { label: 'Booking To', placeholder: 'Booking To' },
    fromTo: {
      fromDate: { label: 'From', placeholder: 'From' },
      toDate: { label: 'To', placeholder: 'To' }
    }
  },
  imageFolderPath: {
    hotelList: '/Photos/'
  },
  routerLinks: {
    b2bDefault: '/default',
    BOHome: '/bohome'
  },
  xmlErrorMessage: [
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML001', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML002', message: 'Sorry we are unable to confirm this booking due to insufficient balance. Please kindly arrange payment for the existing bookings or contact MG sales to apply for a temporary credit increase.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML003', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML004', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML005', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML007', message: 'There seems to be some fields missing on your search. Please kindly make sure that all fields are correct. If not please contact MG Customer Service for support.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML008', message: 'Sorry, the bookings are is to confirm. Please kindly edit the number of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML009', message: 'Sorry, the bookings are is to confirm. Please kindly edit the age of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML012', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML013', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML031', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML032', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML033', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML034', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML035', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML036', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML037', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML038', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Check-in and Check-out dates must be within 2 years from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML039', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Booking period exceeds 30 days limit from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML040', message: 'Sorry, hotel/room you were trying to book is no longer available as CheckIn and CheckOut dates cannot be on the same day.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML041', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be between 1 and 8.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML042', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of adults should be between 1 and 4.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML044', message: 'Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML045', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Room information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML046', message: 'The age entered for a child guest is either not valid or outside the allowed range.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML048', message: 'Sorry, no additional information about selected hotel is available at the moment.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML049', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-in Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML050', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-out Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML051', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML052', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML054', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our admin at salesadmin@mgbedbank.com or your MG Sales PIC to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML057', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Room Type and Child Age mismatch. Please check your request.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML058', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML059', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML060', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML061', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML062', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Currency Conversion Rate is unavailable: JRVXML062.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML064', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid RoomType.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML065', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid no of adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML066', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Room Type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML067', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid SeqNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML072', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML074', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: First & Last Name must contain a minimum of 2 Characters.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML075', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Last Name must not exceed 41 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML076', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  First Name must not exceed 26 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML077', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Pax Format.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML078', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Please enter the Pax Information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML079', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Salutation is blank: JRVXML079.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML087', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML090', message: 'Sorry, hotel/room you were trying to book is no longer available as Insufficient Data for (Hotel).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML091', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Number of Adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML098', message: 'Sorry, the hotel required is no longer available. You might want to try  similar hotel nearby or call MG customer service for assistence.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML101', message: 'Sorry, your cancellation limit has expired. This booking has been processed accordingly to all the policies set by hotels. Please kindly contact MG Customer Service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML105', message: 'Sorry, you are unble to cancel / edit due to the policy. Please kindly contact MG customer service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML106', message: 'Please to informed you that the reservations have been cancelled successfully.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML107', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Missing mandatory parameter - ResNo: JRVXML107.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML109', message: 'Sorry there have been no reservations found. Please kindly contact MG Customer Service for further assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML110', message: 'Sorry there been an error in your cancellation. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML112', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: OSRefno is not unique. Please update OSRefno.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML115', message: 'Sorry, your current session has expired. Please try login again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML122', message: 'Sorry, hotel/room you were trying to book is no longer available as Maximum allowed length (20 characters) exceeded for OSRefNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML127', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML140', message: 'Sorry we are unable to process your request. Please try to booking a single room type to proceed.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML142', message: 'Sorry there seems to be an error from the hotels in cancelling this booking. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML143', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML144', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML145', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML146', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML147', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML149', message: 'Sorry, it seems like something went wrong! Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML157', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML159', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be atleast 1 and max 10.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML161', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML170', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML178', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML181', message: 'Sorry we are unable to find records. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML183', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML185', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML187', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML188', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML190', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML191', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML205', message: 'Sorry, it seems like the current cancellation policy has changed to not allow this operation. Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: '500', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: '400', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: '503', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML149', message: 'Sorry, it seems like something went wrong! Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    {
      code: 'JRVXML060',
      message: `We're sorry, but due to real-time availability, the room category/type you requested is
    no longer available. Please make an alternative selection or contact our customer service team whom will be happy to assist.` },
  ],


  reports: {
    bookingStatus: [
      { label: 'Confirmed', colorCode: '#00884d' },
      { label: 'On Request', colorCode: '#27a9e0' },
      { label: 'Cancel Request', colorCode: '#666666' },
      { label: 'Cancelled Charged', colorCode: '#be2b3b' },
      { label: 'Not Confirmed', colorCode: '#ffc807' },
      { label: 'Cancelled Free', colorCode: '#e7565d' },
      { label: 'On Hold', colorCode: '#f17d02' }
    ],
    bookingType: [
      { label: 'Booking', id: 1 },
      { label: 'Revenue', id: 2 },
      { label: 'Room Nights', id: 3 },
    ],
    bookingTypeEnum: {
      Booking: 1,
      Revenue: 2,
      roomNights: 3,
    },
    bookingTypeCompare: [
      { label: 'Confirmed', value: 1 },
      { label: 'Pending', value: 2 },
      { label: 'Cancelled', value: 3 },
    ],
    bookingLeadTimeAxisLabels: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      '8-14',
      '15-21',
      '22-28',
      '>28'
    ],


  },
  privileges: {
    View: 'VW',
    Create: 'CR',
    Edit: 'ED',
    Delete: 'DE',
    Approve: 'AP'
  },
  b2bFeatureTypeId: {
    B2BHotels: 2001,
    UnbeatableDeals: 2002,
    ChainDeals: 2060,
    ViewBookings: 2003,
    B2BAgency: 2004,
    B2BReports: 2005,
    B2BGroupBooking: 2006,
    RequestCredit: 2007,
    B2BManageAgency: 2041,
    B2BMarkup: 2042,
    ViewRatesAndProperties: 2043,
    HotelsBookProperties: 2044,
    ViewDeals: 2045,
    UnbeatableDealsBookProperties: 2046,
    ChainViewDeals: 2061,
    ChainDealsBookProperties: 2062,
    AgencyProfile: 2047,
    Branch: 2048,
    AgentUser: 2049,
    AgencyBankDetails: 2050,
  },
  paymentOptions: {
    creditBalance: 'Credit Balance',
    creditCard: 'Credit Card Payment',
    wireTransfer: 'Wire Transfer',
    qris: 'QRIS',
    bankTransfer: 'Bank Transfer'
  },
  noRoomAvailableErrorCode: 'JRVXML060',
  redirctTimeInSec: 10,
  paymentBy: {
    creditCard: 'Credit Card',
    wireTransfer: 'Wire Transfer',
    qris: 'QRIS',
    bankTransfer: 'BankTransfer', 
    credit: 'Credit'
  },
  creditTabIndex: {
    creditBalance: 0,
    creditCard: 1,
    qris: 2,
    bankTransfer: 3
  },
  SystemStatus: {
    recheckCancelled: 'B-RecheckCancelled'
  },
  paymentTypeCodes: {
    creditCard: 'NCC',
    wireTransfer: 'NCW',
    qris: 'NCQ',
    bankTransfer: 'NCB'
  },
  SpeedTestIframeURL: 'https://mgbedbank.speedtestcustom.com',
  ClientIPAddressURL: 'https://api.ipify.org?format=json',
  // 'https://mgcybage.speedtestcustom.com/'
  SafeStayPolicy: {
    hotelStandard: 'HotelStandard',
    chainStandard: 'ChainStandard',
    governmentStandard: 'GovernmentStandard'
  },
  PaymentGateway: {
    asiaPay: 'ASIAPAY',
    xendit: 'XENDIT'
  },
  superUserRoleID: {
    superUser: 'BD1749B5-18F6-4474-89DF-C735F489CB8B',
    superUserViewOnly: '65BC5042-1CC5-4ABA-830F-08DA9555701A'
  }

};
