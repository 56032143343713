import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../common/b2b-shared/services/home.service';
import { ScreenService } from '../../../framework/fw/services/screen.service';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { ActivatedRoute } from '@angular/router';
import { DealsDataService } from '../../common/b2b-shared/services/deals-data.service';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { CONSTANTS } from 'src/app/common/constants';
import { ErrorService } from 'src/app/framework/fw/services/error.service';
import { Utilities } from 'src/app/common/utilities/utilities';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  aboutImgPath = 'assets/images/AboutMgBedBankIcons/';
  benefitsImgPath = 'assets/images/MgBedBankBenifits/';
  selectedCurrency: string;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  screenWidth: number;
  ContentUrl = this.configurationService.config.baseUrls.contentUrl;
  favoriteDestinationImageIndexes = CONSTANTS.favoriteDestinationImageIndexes;
  // For Swipe Mobile
  mediumConfig: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 3,
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 0,
  };
  smallConfig: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 5,
  };
  mediumFavConfig: SwiperOptions = {
    initialSlide: 0,
    slidesPerView: 2,
    pagination: { el: '.swiper-pagination', clickable: true },
    spaceBetween: 10,
  };

  constructor(public homeService: HomeService, public screenService: ScreenService,
    private currencyApi: CurrencyApi, private activatedRoute: ActivatedRoute,
    public dealsDataService: DealsDataService,
    private dialogsService: DialogsService, public configurationService: ConfigurationService,
    private errorService: ErrorService) {
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.selectedCurrency = this.activatedRoute.snapshot.data['defaultCurrency'];
    this.getAboutMGBedBank();
    this.getMGBedBankBenefits();
    this.getFavouriteDestinations();
    this.getUnbeatableDeals();
    this.screenWidth = window.innerWidth;
    if (!Utilities.isNullOrEmpty(this.errorService.oidcErrorMessage)) {
      this.dialogsService.showErrorPopup(this.errorService.oidcErrorMessage);
    }
  }

  getAboutMGBedBank() {
    this.homeService.getAboutMGBedBank();
  }

  getMGBedBankBenefits() {
    this.homeService.getMGBedBankBenefits();
  }

  getFavouriteDestinations() {
    if (this.currencyApi.selectedCurrency) {
      this.dealsDataService.getFavouriteDestinations();
    }
  }

  getUnbeatableDeals() {
    if (this.currencyApi.selectedCurrency) {
      this.dealsDataService.getUnbeatableDeals(this.currencyApi.selectedCurrency);
    }
  }

  showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }
}
