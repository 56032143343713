import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { UserAccountApi } from '../user-account-api';
import { UserProfileService } from '../../../../common/shared/services/user-profile.service';
import { MatSnackBar } from '@angular/material';
import { CONSTANTS } from '../../../../common/constants';
import { JarvisError } from '../../../../common/jarviserror';
import { FormGroup, ValidationErrors, AbstractControl, FormControl, Validators, AsyncValidatorFn } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { ChangePasswordViewModel } from 'src/app/common/viewmodels/changepasswordviewmodel';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';
import { ErrorMessages, CustomConfirmMessages, CustomConfirmButtonTexts } from 'src/app/common/errormessage';
import { map } from 'rxjs/operators';
import { Utilities } from 'src/app/common/utilities/utilities';
import { CancelpopupService } from 'src/app/b2b/common/b2b-shared/dialogs/cancelpopup.service';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { CanComponentDeactivate } from 'src/app/b2b//common/b2b-shared/services/guards/can-deactivate-guard.service';
import { MarkFormDirtyService } from 'src/app/b2b/common/b2b-shared/services/mark-form-dirty.service';
import { ScreenService } from '../../services/screen.service';

function MatchPasswordValidator(g: FormGroup) {
  if (!g.get('confirmPassword').value) {
    return null;
  } else {
    if (g.get('newPassword').value !== g.get('confirmPassword').value) {
      return { 'misMatch': true };
    }
  }
}

function currentPasswordValidator(userAccountApi: UserAccountApi, userProfileService: UserProfileService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {

    return userAccountApi.verifyCurrentPassword(userProfileService.getBasicUserInfo().userName, encodeURIComponent(control.value))
      .pipe(
        map(response => {
          return (!response) ? { 'passwordInvalid': true } : null;
        })
      );
  };
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  hideCurrentPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  passwordForm: FormGroup;
  newPasswordDetails: ChangePasswordViewModel = <ChangePasswordViewModel>{};
  passwordInvalid: boolean;
  regExpForNumber: any;
  regExpForCase: any;
  regExForNonAlphanum: any;
  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  disableSave: boolean;
  confirmCancel = false;
  isSaved = false;
  isPasswordExpired: boolean;

  constructor(private location: Location,
    private userAccountApi: UserAccountApi,
    private userProfileService: UserProfileService,
    public snackBar: MatSnackBar,
    private dialogsService: DialogsService, private cancelPopupService: CancelpopupService,
    private activatedRoute: ActivatedRoute, private router: Router,
    public markFormDirtyService: MarkFormDirtyService,
    public screenService: ScreenService  ) { }

  ngOnInit() {

    this.passwordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.required, [currentPasswordValidator(this.userAccountApi, this.userProfileService)]),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    }, MatchPasswordValidator);

    if (this.userProfileService.getBasicUserInfo()) {
      this.newPasswordDetails.email = this.userProfileService.getBasicUserInfo().userName;
      // this.newPasswordDetails.userId = this.userProfileService.getBasicUserInfo().id;
      this.newPasswordDetails.userName = this.userProfileService.getBasicUserInfo().userName;
      this.isPasswordExpired = this.userProfileService.getBasicUserInfo().isPasswordExpired;
      if(this.isPasswordExpired) {
        this.removeElement('.breadcrumb');   
        this.removeElement('.cookies-section');
        this.removeElement('.footer-container');        
        // Below items need to be removed only in case of cancel click         
        this.removeElement('.menu');        
        this.removeElement('.displayUser');        
        let mgLogoElement = document.querySelector('.logo');
        if (mgLogoElement) {
          mgLogoElement.setAttribute("href", "authenticated/confirmpassword");
        }
      }
    }
    this.regExpForNumber = new RegExp('(?=.*[0-9])');
    this.regExpForCase = new RegExp('(?=.*[A-Z])');
    this.regExForNonAlphanum = new RegExp('[^A-Za-z0-9]');
  }

  removeElement(element: string) {
    let domElement = document.querySelector(element);
    if (domElement) {
      domElement.remove();
    }
  }

  verifyCurrentPassword() {
    const subscription = this.userAccountApi.verifyCurrentPassword
      (this.userProfileService.getBasicUserInfo().userName, this.passwordForm.get('currentPassword').value).subscribe(response => {
        if (!response) {
          this.passwordForm.controls['currentPassword'].setErrors({ 'passwordInvalid': true });
        }
      }, (error: JarvisError) =>
          console.error(`Error in verifyCurrentPassword(). ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  validatePassword() {
    const newPassword = this.passwordForm.get('newPassword').value;
    if (newPassword.length < 8) {
      this.passwordForm.controls['newPassword'].setErrors({ 'lengthError': true });
    } else {
      if (!this.regExpForNumber.test(newPassword)) {
        this.passwordForm.controls['newPassword'].setErrors({ 'numberError': true });
      } else {
        if (!this.regExpForCase.test(newPassword)) {
          this.passwordForm.controls['newPassword'].setErrors({ 'caseError': true });
        } else {
          if (!this.regExForNonAlphanum.test(newPassword)) {
            this.passwordForm.controls['newPassword'].setErrors({ 'nonAlphanumError': true });
          } else {
            if (this.passwordForm.get('currentPassword').value === this.passwordForm.get('newPassword').value) {
              this.passwordForm.controls['newPassword'].setErrors({ 'samePasswordError': true });
            } else {
              this.passwordForm.controls['newPassword'].setErrors(null);
            }
          }
        }
      }
    }
  }

  matchPassword() {
    if (this.passwordForm.controls['confirmPassword'].dirty) {
      if (this.passwordForm.get('newPassword').value !== this.passwordForm.get('confirmPassword').value) {
        this.passwordForm.controls['confirmPassword'].setErrors({ 'misMatch': true });
      } else {
        this.passwordForm.controls['confirmPassword'].setErrors(null);
      }
    }
  }

  hasErrors(controlName: string) {
    if (this.passwordForm.get(controlName) !== null) {
      return (this.passwordForm.get(controlName).dirty ||
        this.passwordForm.get(controlName).touched) && this.passwordForm.get(controlName).errors !== null;
    }
  }

  getValidationMessage(controlName: string) {
    if (this.passwordForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.passwordForm.get(controlName).hasError('lengthError')) {
      return ErrorMessages.passwordLengthErrorMessage;
    } else if (this.passwordForm.get(controlName).hasError('numberError')) {
      return ErrorMessages.passwordNumberErrorMessage;
    } else if (this.passwordForm.get(controlName).hasError('caseError')) {
      return ErrorMessages.passwordCaseErrorMessage;
    } else if (this.passwordForm.get(controlName).hasError('nonAlphanumError')) {
      return ErrorMessages.passwordNonAlphanumErrorMessage;
    } else if (this.passwordForm.get(controlName).hasError('samePasswordError')) {
      return ErrorMessages.samePasswordErrorMessage;
    } else if (this.passwordForm.get(controlName).hasError('misMatch')) {
      return ErrorMessages.passwordMismatchErrorMessage;
    } else {
      return '';
    }
  }

    changePassword() {

      this.markFormDirtyService.markGroupDirty(this.passwordForm);
        if (this.passwordForm.valid && this.passwordForm.dirty){
            this.disableSave = true;
          this.showSpinner();
          this.newPasswordDetails.currentPassword = this.passwordForm.get('currentPassword').value;
          this.newPasswordDetails.newPassword = this.passwordForm.get('newPassword').value;
          const subscription = this.userAccountApi.changePassword(this.newPasswordDetails).subscribe(data => {
            // this.snackBar.open('Password Changed Successfully', '',
            //   { duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top', panelClass: 'showSnackBar' });
            this.isSaved = true;
            this.userProfileService.SaveIPAddress('PSChange', this.userProfileService.getBasicUserInfo().id);
            if(this.isPasswordExpired) {
              this.dialogsService.confirm('Success', 'Password Changed Successfully', false, undefined, undefined, false).subscribe(res => {
                window.location.reload();
              });
            }
            else {
              this.location.back();
            }
          }, (error: JarvisError) => {
            this.disableSave = false;
            this.hideSpinner();
            console.error(`Error in changing password. ${error.friendlyMessage}`);
          }, () => this.hideSpinner());
          this.subscriptions.push(subscription);
        }
  }

  showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  cancel() {
    let confirmCancelSubscription = null;
    const navigationPath = 'hotelmgmt/hotelinfo';
    confirmCancelSubscription = Utilities.confirmCancel(this.passwordForm.dirty, this.cancelPopupService, this.router, [navigationPath],
      { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0), null
      , CustomConfirmMessages.changePasswordMessage, CustomConfirmButtonTexts.Yes, CustomConfirmButtonTexts.No);
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      this.subscriptions.push(confirmCancelSubscription);
      this.confirmCancel = true;
    }
  }
  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.passwordForm.dirty && !this.confirmCancel && !this.isSaved) {
      return this.dialogsService.confirm('Confirm', CustomConfirmMessages.changePasswordMessage, true,
       CustomConfirmButtonTexts.Yes, CustomConfirmButtonTexts.No);
    } else if (this.passwordForm.dirty && this.isSaved) {
      return this.dialogsService.confirm('Success', 'Password Changed Successfully', false, undefined, undefined, false);
    } else {
      return true;
    }
    // return true;
  }
  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }
}
