import { AreaUpdateViewModel, HotelTypeUpdateViewModel } from './../../../viewmodels/hotel-mgmt/hotelfilterupdateviewmodel';
import { ConfigurationService } from './../../../../common/shared/services/configuration.service';
import { Injectable } from '@angular/core';
import { HotelViewModel, FacilitiesViewModel, RoomCategoryInfoViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/hotelviewmodel';
import {
  HotelFilterUpdateViewModel, StarRatingUpdateViewModel,
  FacilityUpdateViewModel,
  PromotionUpdateViewModel
} from 'src/app/b2b/viewmodels/hotel-mgmt/hotelfilterupdateviewmodel';
import { Utilities } from 'src/app/common/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  openDateHotelVoucher = this.configurationService.config.openDateHotelVoucher;

  constructor(public configurationService: ConfigurationService) { }

  filterHotel(dataSource: HotelViewModel[], hotelFilter: HotelFilterUpdateViewModel): HotelViewModel[] {
    let filteredDataSource = dataSource;
    // First Filter by Hotel Level
    if (hotelFilter.isMgPreferred !== null && hotelFilter.isMgPreferred) {
      filteredDataSource = this.applyMgPreferredFilter(filteredDataSource, hotelFilter.isMgPreferred);
    }
    if (hotelFilter.isFreeCancellation !== null && hotelFilter.isFreeCancellation) {
      filteredDataSource = this.applyCancellationPolicyFilter(filteredDataSource, hotelFilter.isFreeCancellation);
    }
    if (hotelFilter.isOpenDateHotelVoucher !== null && hotelFilter.isOpenDateHotelVoucher) {
      filteredDataSource = this.applyOpenDateHotelVoucherFilter(filteredDataSource, hotelFilter.isOpenDateHotelVoucher);
    }
    if (hotelFilter.starRatings !== null && hotelFilter.starRatings !== undefined
      && hotelFilter.starRatings.length) {
      filteredDataSource = this.applyStarRatingFilter(filteredDataSource, hotelFilter.starRatings);
    }
    if (hotelFilter.facilities !== null && hotelFilter.facilities !== undefined
      && hotelFilter.facilities.length) {
      filteredDataSource = this.applyFacilitiesFilter(filteredDataSource, hotelFilter.facilities);
    }
    if (hotelFilter.areas !== null && hotelFilter.areas !== undefined
      && hotelFilter.areas.length) {
      filteredDataSource = this.applyAreaFilter(filteredDataSource, hotelFilter.areas);
    }
    if (hotelFilter.hotelTypes !== null && hotelFilter.hotelTypes !== undefined
      && hotelFilter.hotelTypes.length) {
      filteredDataSource = this.applyHotelTypeFilter(filteredDataSource, hotelFilter.hotelTypes);
    }
    if (hotelFilter.landmarkId !== null && hotelFilter.landmarkId !== undefined && hotelFilter.landmarkId.length) {
      filteredDataSource = this.applyLandmarkFilter(filteredDataSource, hotelFilter.landmarkId);
    }
    // if (hotelFilter.areaId !== null && hotelFilter.areaId !== undefined && hotelFilter.areaId.length) {
    //   filteredDataSource = this.applyAreaFilter(filteredDataSource, hotelFilter.areaId);
    // }
    if (hotelFilter.chainId !== null && hotelFilter.chainId !== undefined && hotelFilter.chainId.length) {
      filteredDataSource = this.applyChainFilter(filteredDataSource, hotelFilter.chainId);
    }
    if (hotelFilter.fromPrice !== null && hotelFilter.fromPrice !== undefined &&
      hotelFilter.toPrice !== null && hotelFilter.toPrice !== undefined) {
      filteredDataSource = this.applyPriceFilter(filteredDataSource, hotelFilter.fromPrice, hotelFilter.toPrice);
    }

    if (!Utilities.isNullOrEmpty(hotelFilter.hotelName) &&  hotelFilter.hotelName.length > 2) {
      filteredDataSource = this.applyHotelNameFilter(filteredDataSource, hotelFilter.hotelName);
    }

    // Then Filter by Room Level
    if (hotelFilter.promotions !== null && hotelFilter.promotions !== undefined
      && hotelFilter.promotions.length) {
        filteredDataSource = this.applyPromotionsFilter(filteredDataSource, hotelFilter.promotions);
    }
    return filteredDataSource;
  }

  applyMgPreferredFilter(dataSource: HotelViewModel[], mgPreferred: boolean) {
    dataSource = dataSource.filter(h =>
      h.isMgPreferred === mgPreferred);
    return dataSource;
  }

  applyCancellationPolicyFilter(dataSource: HotelViewModel[], freeCP: boolean) {
    let dataSourceClone = JSON.parse(JSON.stringify(dataSource));
    dataSourceClone = dataSourceClone.filter((h) => {
      h.roomCategoryInfo = h.roomCategoryInfo.filter((rc) => {
        rc.roomDetails = rc.roomDetails.filter(rd => rd.canHold === true);
        return true;
      });
      return true;
    });
    return dataSourceClone;
  }

  applyOpenDateHotelVoucherFilter(dataSource: HotelViewModel[], isOpenDate: boolean) {
    let dataSourceClone = JSON.parse(JSON.stringify(dataSource));
    dataSourceClone = dataSourceClone.filter((h) => {
      h.roomCategoryInfo = h.roomCategoryInfo.filter(rc => rc.roomName && rc.roomName.toUpperCase().startsWith(this.openDateHotelVoucher.toUpperCase()));
        // rc.roomDetails = rc.roomDetails.filter(rd => rd.canHold === true);
        return true;
      // });
      // return true;
    });
    return dataSourceClone;
  }

  applyStarRatingFilter(dataSource: HotelViewModel[],
    starRatings: StarRatingUpdateViewModel[]) {
    const ratings: string[] = [];
    starRatings.forEach(star => {
      ratings.push(star.rating.toString());
    });
    dataSource = dataSource.filter(h =>
      ratings.includes(h.starRating));
    return dataSource;
  }

  applyPromotionsFilter(dataSource: HotelViewModel[],
    promotionsList: PromotionUpdateViewModel[]) {
    let dataSourceClone = JSON.parse(JSON.stringify(dataSource));
    const promotionsFilters: string[] = [];
    promotionsList.forEach(promotion => {
      promotionsFilters.push(promotion.type.toLowerCase());
    });

    dataSourceClone = dataSourceClone.filter((h) => {
      let promotionFound = false;
      h.roomCategoryInfo = h.roomCategoryInfo.filter((rc) => {
        let roomCategoryFound = false;
        rc.roomDetails = rc.roomDetails.filter((rd) => {
          let roomDetailFound = false;
          rd.promotions = rd.promotions.filter((pro) => promotionsFilters.includes(pro.promotionType.toLowerCase()));
          roomDetailFound = rd.promotions.length > 0 ? true : false;
          // If promotion found then filter out roomDetails obj
          if (!roomCategoryFound) {
            roomCategoryFound = roomDetailFound ? true : false;
          }
          return roomDetailFound; // filtering 'roomDetails'
        });
        roomCategoryFound = rc.roomDetails.length > 0 ? true : false;
        // If roomDetails obj found then filter out roomCategoryInfo obj
        if (!promotionFound) {
          promotionFound = roomCategoryFound ? true : false;
        }
        return roomCategoryFound; // filtering 'roomCategoryInfo'
      });
      return promotionFound; // filtering hotel
    });
    return dataSourceClone;
  }

  filterPromotions(promotionsFilters: string[], roomCategories: RoomCategoryInfoViewModel[]) {

  }
  applyPriceFilter(dataSource: HotelViewModel[] , fromPrice, toPrice) {
    let dataSourceClone = JSON.parse(JSON.stringify(dataSource));
    dataSourceClone = dataSourceClone.filter(h => {
      let hotelFound = false;
      h.roomCategoryInfo = h.roomCategoryInfo.filter(roomCategory => {
        let roomFound = false;
        roomCategory.roomDetails = roomCategory.roomDetails.filter(roomDetails => {
          let roomLiesInRange = false;
          roomLiesInRange = roomDetails.grossPrice >= fromPrice && roomDetails.grossPrice <= toPrice ? true : false;
          return roomLiesInRange;
        });
        roomFound = roomCategory.roomDetails.length > 0 ? true : false;
        if (!hotelFound) {
          hotelFound = roomFound ? true : false;
        }
        return roomFound;
      });
      return hotelFound;
    });
    return dataSourceClone;
  }

  applyFacilitiesFilter(dataSource: HotelViewModel[],
    faclitiesList: FacilityUpdateViewModel[]) {
    const facilitiesFilters: string[] = [];
    faclitiesList.forEach(facility => {
      facilitiesFilters.push(facility.code.toLowerCase());
    });
    dataSource = dataSource.filter(h => this.filterFacs(facilitiesFilters,
      h.facilities));
    return dataSource;
  }

  filterFacs(facilityFilters: string[],
    hotelFacilities: FacilitiesViewModel[]) {
    let allFacilitiesFound = false;
    if (hotelFacilities.length === 0) {
      return false;
    }
    const FacNames: string[] = [];
    hotelFacilities.forEach(fac => {
      FacNames.push(fac.code.toLowerCase());
    });
    for (let i = 0; i < facilityFilters.length; i++) {
      allFacilitiesFound = FacNames.includes(facilityFilters[i]);
      if (!allFacilitiesFound) {
        break;
      }
    }
    return allFacilitiesFound;
  }

  // applyAreaFilter(dataSource: HotelViewModel[] , areaId: number[]) {
  //   dataSource = dataSource.filter(h =>
  //     areaId.includes(h.areaId));
  //   return dataSource;
  // }

  applyAreaFilter(dataSource: HotelViewModel[],
    areaList: AreaUpdateViewModel[]) {
    const areaFilters: number[] = [];
    areaList.forEach(area => {
      areaFilters.push(area.id);
    });
    dataSource = dataSource.filter(h =>
      areaFilters.includes(h.areaId));
    return dataSource;
  }

  applyHotelTypeFilter(dataSource: HotelViewModel[],
    hotelTypeList: HotelTypeUpdateViewModel[]) {
    const hotelTypeFilters: number[] = [];
    hotelTypeList.forEach(hotelType => {
      hotelTypeFilters.push(hotelType.id);
    });
    dataSource = dataSource.filter(h =>
      hotelTypeFilters.includes(h.hotelTypeId));
    return dataSource;
  }

  applyLandmarkFilter(dataSource: HotelViewModel[], landmarkId: number[]) {
    dataSource = dataSource.filter(h =>
      landmarkId.includes(h.landmarkId));
    return dataSource;
  }

  applyChainFilter(dataSource: HotelViewModel[] , chainId: number[]) {
    dataSource = dataSource.filter(h =>
      chainId.includes(h.chainId));
    return dataSource;
  }

  applyHotelNameFilter(dataSource: HotelViewModel[] , hotelName: string) {
    dataSource = dataSource.filter(h =>
      h.hotelName.toUpperCase().includes(hotelName.toUpperCase()));
    return dataSource;
  }

  applyFromPriceFilter() {

  }

  applyToPriceFilter() {

  }
}
