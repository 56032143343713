import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Utilities } from 'src/app/common/utilities/utilities';

@Directive({
  selector: '[appAllowDecimalNumber]'
})
export class AllowDecimalNumberDirective {

  constructor() { }

  @Input() allowDecimal: boolean;
  @Input() numberOfDigitsAfterDecimal: number;
  @Input() allowNegativeNumber: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    const currentValue = event.currentTarget.value;
    // Allow: 8 - backspace, 9 - tab, 13 - carriage return i.e. enter, 27 - escape, 46 - delete
    if ([8, 9, 13, 27, 46].indexOf(e.keyCode) !== -1 ||
      // Allow: . only if isAllowDecimal is true
      ((e.keyCode === 110 || e.keyCode === 190) && this.isAllowDecimal(event, currentValue)) ||
      // Allow: - only if isAllowNegativeNumber is true
      ((e.keyCode === 109 || e.keyCode === 189 || e.keyCode === 173) && this.isAllowNegativeNumber(event, currentValue)) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
    } else if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105))
    || this.isNumberOfDigitsAfterDecimalExceedsLimit(event, currentValue)) {
        // If it is not a number Or the number of digits after decimal exceeds the specified limit then stop the keypress
        e.preventDefault();
    }
  }

  isAllowDecimal(event, currentValue) {
    let isAllowDecimal = false;
    // Allow: . only if
    // 1) allowDecimal is true And
    // 2) (for only one occurence Or if the selected text contains .) And
    // 3) if the digits after the current cursor position are less than numberOfDigitsAfterDecimal
    // The value of this.allowDecimal is coming as string, so apply JSON.parse() method to convert it from string to boolean.
    this.allowDecimal = !Utilities.isNullOrEmpty(this.allowDecimal) ? JSON.parse(this.allowDecimal as any) : false;
    if (this.allowDecimal
        && (!currentValue.includes('.')
            || currentValue.substring((event.currentTarget as any).selectionStart,
            (event.currentTarget as any).selectionEnd).indexOf('.') >= 0)
        && (currentValue.length - (event.currentTarget as any).selectionEnd) <= this.numberOfDigitsAfterDecimal) {
      isAllowDecimal = true;
    }
    return isAllowDecimal;
  }

  isNumberOfDigitsAfterDecimalExceedsLimit(event, currentValue) {
    let isNumberOfDigitsAfterDecimalExceedsLimit = false;
    if ((event.currentTarget as any).selectionStart === (event.currentTarget as any).selectionEnd) {
      // The value of this.allowDecimal is coming as string, so apply JSON.parse() method to convert it from string to boolean.
      this.allowDecimal = !Utilities.isNullOrEmpty(this.allowDecimal) ? JSON.parse(this.allowDecimal as any) : false;
      if (this.allowDecimal && !Utilities.isNullOrEmpty(this.numberOfDigitsAfterDecimal)) {
        // If allowDecimal = true and numberOfDigitsAfterDecimal is specified then,
        // limit the number of digits after decimal to the specified limit
        const currentValueArray = currentValue.split('.');
        if ((event.currentTarget as any).selectionStart > currentValueArray[0].length
        && currentValueArray.length > 1 && currentValueArray[1].length >= this.numberOfDigitsAfterDecimal) {
        // if (currentValueArray.length > 1 && currentValueArray[1].length >= this.numberOfDigitsAfterDecimal) {
          isNumberOfDigitsAfterDecimalExceedsLimit = true;
        }
      }
    }
    return isNumberOfDigitsAfterDecimalExceedsLimit;
  }

  isAllowNegativeNumber(event, currentValue) {
    let isAllowNegativeNumber = false;
    // Allow: - only if
    // 1) allowNegativeNumber is true And
    // 2) (for only one occurence Or if the selected text contains -) And
    // 3) minus sign should be the first character
    // The value of this.allowNegativeNumber is coming as string, so apply JSON.parse() method to convert it from string to boolean.
    this.allowNegativeNumber = !Utilities.isNullOrEmpty(this.allowNegativeNumber) ? JSON.parse(this.allowNegativeNumber as any) : false;
    if (this.allowNegativeNumber &&
        (!currentValue.includes('-')
        || currentValue.substring((event.currentTarget as any).selectionStart, (event.currentTarget as any).selectionEnd).indexOf('-') >= 0)
        && (event.currentTarget as any).selectionStart === 0) {
          isAllowNegativeNumber = true;
    }
    return isAllowNegativeNumber;
  }

  // Restrict mouse right click
  @HostListener('contextmenu', ['$event']) oncontextmenu() {
    return false;
  }
}
