
import { ErrorCodes } from './../../../../common/errorcodes';
import { MessageDialogComponent } from './../../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { CONSTANTS } from 'src/app/common/constants';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { OnInit, OnDestroy, ChangeDetectorRef, Component } from '@angular/core';
import { BranchInfoViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/branchinfoviewmodel';
import { CountryViewModel } from 'src/app/common/viewmodels/countryviewmodel';
import { CityViewModel } from 'src/app/common/viewmodels/cityviewmodel';
import { Observable, Subscription, of } from 'rxjs';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { LookupService } from 'src/app/b2b/common/b2b-shared/services/lookup.service';
import { ErrorMessages, CustomConfirmMessages, CustomConfirmButtonTexts } from 'src/app/common/errormessage';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { DesignationViewModel } from 'src/app/b2b/viewmodels/designationviewmodel';
import { JarvisError } from 'src/app/common/jarviserror';
import { BranchDataService } from 'src/app/b2b/common/b2b-shared/services/branch-data.service';
import { AgencyMgmtBranchAsyncValidators } from './branch-info.validators';
import { Utilities } from 'src/app/common/utilities/utilities';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';
import { CancelpopupService } from 'src/app/b2b/common/b2b-shared/dialogs/cancelpopup.service';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { CanComponentDeactivate } from '../../../common/b2b-shared/services/guards/can-deactivate-guard.service';
import { MarkFormDirtyService } from 'src/app/b2b/common/b2b-shared/services/mark-form-dirty.service';

@Component({
  selector: 'app-branch-info',
  templateUrl: './branch-info.component.html',
  styleUrls: ['./branch-info.component.css']
})
export class BranchInfoComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  branchForm: FormGroup;
  edit = CONSTANTS.operation.edit;
  create = CONSTANTS.operation.create;
  read = CONSTANTS.operation.read;
  branchId: string;
  branchInfo: BranchInfoViewModel = <BranchInfoViewModel>{};
  operation: string;
  isRead: boolean;
  isPrimary: boolean;
  countryList: CountryViewModel[] = [];
  cityList: CityViewModel[];
  designationList: Observable<DesignationViewModel[]>;
  subscriptions: Subscription[] = [];
  index: any;
  userId: string;
  count: number;
  result: any;
  actions: string;
  isDisabled = true;
  onlyActive = false;
  confirmCancel = false;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
 
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public lookupService: LookupService,
    private userProfileService: UserProfileService,
    private branchDataService: BranchDataService,
    private dialogsService: DialogsService,
    private cancelPopupService: CancelpopupService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public markFormDirtyService: MarkFormDirtyService
  ) { }

  ngOnInit() {
    this.branchId = this.activatedRoute.snapshot.params['id'];
    this.operation = this.activatedRoute.snapshot.params['operation'];
    this.countryList = this.activatedRoute.parent.snapshot.data['countryList'];
    this.getDesignations();
    this.count = 0;

    this.userId = this.userProfileService.getBasicUserInfo().id;

    this.branchForm = new FormGroup({
      mgBranch: this.buildBranchForm(),
      branchContacts: new FormArray([this.buildContactsDetails()]),
    });

    if (this.operation.toLowerCase().trim() === this.create) {
      this.onlyActive = true;
    } else if (this.operation.toLowerCase().trim() === this.edit) {
      this.getBranch(this.branchId);
    } else if (this.operation.toLowerCase().trim() === this.read) {
      this.isRead = true;
      this.getBranch(this.branchId);
      this.branchForm.disable();
    }

    if (this.operation === this.create) {
      this.branchContacts.controls.forEach((contact, i) => {
        if (i === 0) {
          contact.get('isPrimary').setValue(0);
        }
      });
    }   
  }

  getDesignations() {
    const subscription = this.lookupService.getDesignationByType(CONSTANTS.userTypeName.agentuser).subscribe(data => {
      this.designationList = data.result;
    });
    this.subscriptions.push(subscription);
  }

  getCities(countryId) {
    const subscription = this.lookupService.getCities(countryId).subscribe(data => {
      this.cityList = data;
    });
    this.subscriptions.push(subscription);
  }

  buildBranchForm(): FormGroup {
    let BranchFromGroup: FormGroup;
    BranchFromGroup = new FormGroup({
      agencyId: new FormControl(this.agencyId),
      isActive: new FormControl(true),
      name: new FormControl('', {
        validators: [Validators.required, Validators.pattern('.*[^ ].*')],
        asyncValidators: [AgencyMgmtBranchAsyncValidators.branchNameValidator(this.branchDataService, this.branchId, false)]
      }),
      isMain: new FormControl(false),
      address1: new FormControl('', [Validators.required, Validators.pattern('.*[^ ].*')]),
      address2: new FormControl(),
      countryId: new FormControl('', Validators.required),
      cityId: new FormControl('', Validators.required),
      zipCode: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')])
    });
    return BranchFromGroup;
  }

  buildContactsDetails(): FormGroup {
    let contactsGroup: FormGroup;
    contactsGroup = new FormGroup({
      id: new FormControl(0),
      isPrimary: new FormControl({ value: null, disabled: this.isRead }),
      designationId: new FormControl({ value: null, disabled: this.isRead }, Validators.required),
      email: new FormControl({ value: '', disabled: this.isRead }, [Validators.required,
      // Validators.pattern(Patterns.email)
      Validators.email]),
      // tslint:disable-next-line:max-line-length
      contactNumber: new FormControl({ value: '', disabled: this.isRead }, [Validators.required, Validators.pattern('([ ]*[0-9+-]+[ ]*)*')]),
      contactPerson: new FormControl({ value: '', disabled: this.isRead }, [Validators.required, Validators.pattern('.*[^ ].*')]),
      createdBy: new FormControl(this.userProfileService.getBasicUserInfo().id)
    });
    return contactsGroup;
  }

  get branchContacts(): FormArray {
    return <FormArray>this.branchForm.get('branchContacts');
  }

  get mgBranch(): FormArray {
    return <FormArray>this.branchForm.get('mgBranch');
  }

  saveBranch() {
    this.markFormDirtyService.markGroupDirty(this.branchForm);
    if (this.branchForm.valid && this.branchForm.dirty) {
      const branchData = Object.assign({}, this.branchInfo, this.branchForm.value);
      branchData.mgBranch.name = branchData.mgBranch.name.trim();
      branchData.mgBranch.languageId = '0';
      if (this.mgBranch.get('isMain').value) {
        branchData.mgBranch.isMain = true;
      }
      if (this.mgBranch.get('isActive').value) {
        branchData.mgBranch.isActive = true;
      }
      branchData.branchContacts.forEach((data, index) => {
        data.updatedBy = this.userId;
        data.branchId = this.branchId;
        data.isActive = true;
        data.languageId = '0';

        if (data.isPrimary !== null) {
          data.isPrimary = true;
        } else {
          data.isPrimary = false;
        }
      });
      branchData.mgBranch.updatedBy = this.userId;
      const branch = branchData.mgBranch.name;

      if (this.operation === this.create) {
        branchData.mgBranch.createdBy = this.userId;
        branchData.branchContacts.forEach(data => {
          data.createdBy = this.userId;
        });
        const subscription = this.branchDataService.createBranch(branchData as BranchInfoViewModel)
          .subscribe(data => {
            window.scrollTo(0, 0);
            if (data.isError) {
              let snackBarMessage = 'Error while creating new branch';
              if (data.errors && data.errors.length > 0) {
                snackBarMessage += ' - ' + data.errors[0].description;
              }
              this.snackBar.open(snackBarMessage, '', {
                duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top',
                panelClass: 'showSnackBar'
              });
            } else {
              this.snackBar.open('The new Branch has been created successfully', '', {
                duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top',
                panelClass: 'showSnackBar'
              });
              this.router.navigate(['/authenticated/agency/agencymgmt/branch'],
                { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
            }
          },
          (error: JarvisError) => {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Error';
            dialogRef.componentInstance.message = `Error Code ${ErrorCodes.BranchCreate}. ${error.friendlyMessage}`;
            dialogRef.componentInstance.buttonText = 'OK';
          });
        this.subscriptions.push(subscription);
      } else {
        branchData.mgBranch.createdBy = this.branchInfo.mgBranch.createdBy;
        branchData.mgBranch.id = this.branchInfo.mgBranch.id;
        branchData.mgBranch.agencyId = this.branchInfo.mgBranch.agencyId;
        const subscription = this.branchDataService.updateBranch(branchData as BranchInfoViewModel)
          .subscribe(data => {
            if (data.isError) {
              let snackBarMessage = 'Error while updating  branch';
              if (data.errors && data.errors.length > 0) {
                snackBarMessage += ' - ' + data.errors[0].description;
              }
              this.snackBar.open(snackBarMessage, '', {
                duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top',
                panelClass: 'showSnackBar'
              });
            } else {
              this.snackBar.open('The branch ' + branch + ' has been edited successfully', '', {
                duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top',
                panelClass: 'showSnackBar'
              });
              this.router.navigate(['/authenticated/agency/agencymgmt/branch'],
                { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
            }
          },
          (error: JarvisError) => {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Error';
            dialogRef.componentInstance.message = `Error Code ${ErrorCodes.BranchUpdate}. ${error.friendlyMessage}`;
            dialogRef.componentInstance.buttonText = 'OK';
          });
        this.subscriptions.push(subscription);
      }
      this.confirmCancel = true;
    }
  }

  setIsPrimary(event, index) {
    this.branchContacts.controls.forEach((val, i) => {
      if (index === i) {
        this.branchContacts.controls[i].get('isPrimary').setValue(i);
      } else {
        this.branchContacts.controls[i].get('isPrimary').setValue(null);
      }
    });
  }

  confirmDeleteContact(index: number, length) {
    if (Utilities.isNullOrEmpty(this.branchContacts.value[index].contactNumber) &&
      Utilities.isNullOrEmpty(this.branchContacts.value[index].email) &&
      Utilities.isNullOrEmpty(this.branchContacts.value[index].contactPerson) &&
      Utilities.isNullOrEmpty(this.branchContacts.value[index].designationId)) {
      this.deleteContact(index, length);
    } else {
      let confirmationMessage = '';
      if (this.branchContacts.value[index].isPrimary !== null) {
        confirmationMessage = ErrorMessages.primeryContactDeletionMessage;
      } else {
        confirmationMessage = ErrorMessages.contactDeletionConfirmationMessage;
      }
      const subscribtion = this.dialogsService
        .confirm('Confirm', confirmationMessage).subscribe(res => {
          this.result = res;
          if (this.result) {
            this.deleteContact(index, length);
            this.branchForm.get('branchContacts').markAsDirty();
            this.branchForm.get('branchContacts').markAsTouched();
            this.branchForm.get('branchContacts').updateValueAndValidity();
          } else {
            this.actions = null;
          }
        },
          (error: JarvisError) => console.error(`Error in deleting agency-conatct(index: number). ${error.friendlyMessage}`));
      this.subscriptions.push(subscribtion);
    }
  }

  deleteContact(index: number, length) {
    let primaryContactIndex;
    this.branchContacts.controls.forEach((val, i) => {
      if (this.branchContacts.controls[i].get('isPrimary').value === i) {
        primaryContactIndex = i;
      }
    });
    this.branchContacts.removeAt(index);
    this.count = length - 2;
    if (index === primaryContactIndex) {
      this.branchContacts.controls[0].get('isPrimary').setValue(0);
      this.cd.detectChanges();
    } else if (primaryContactIndex > index || primaryContactIndex >= this.branchContacts.length) {
      primaryContactIndex = primaryContactIndex - 1;
      this.branchContacts.controls[primaryContactIndex].get('isPrimary').setValue(primaryContactIndex);
      this.cd.detectChanges();
    }
  }

  addContact(length) {
    this.count = length;
    this.branchContacts.push(this.buildContactsDetails());
    this.branchForm.get('branchContacts').updateValueAndValidity();
  }

  getValidationMessage(controlName: string) {
    if (this.branchForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.branchForm.get(controlName).hasError('pattern')) {
      if (controlName === 'mgBranch.name' || controlName === 'mgBranch.address1' ||
        controlName.search('^branchContacts.[0-9]+.contactPerson$') === 0) {
        return ErrorMessages.requiredFieldMessage;
      } else if (controlName.search('^branchContacts.[0-9]+.contactNumber$') === 0) {
        return ErrorMessages.invalidPhoneNumberMessage;
      } else if (controlName.search('^branchContacts.[0-9]+.email$') === 0) {
        return ErrorMessages.invalidEmailMessage;
      } else if (controlName === 'mgBranch.zipCode') {
        return ErrorMessages.alphaNumErrorMessage;
      }
    } else if (this.branchForm.get(controlName).hasError('duplicateBranch')) {
      return ErrorMessages.uniqueNameMessage;
    } else if (this.branchForm.get(controlName).hasError('email')) {
      return ErrorMessages.invalidEmailMessage;
    } else {
      return '';
    }
  }

  hasErrors(controlName: string) {
    return (this.branchForm.get(controlName).dirty || this.branchForm.get(controlName).touched) &&
      this.branchForm.get(controlName).errors !== null;
  }

  validateMainBranch(event) {
    let isMain = false;
    const agencyId = this.mgBranch.get('agencyId').value;
    const branchName = this.mgBranch.get('name').value;
    const checked = this.mgBranch.get('isMain').value;
    if (agencyId !== null && agencyId !== undefined) {
      const subscription = this.branchDataService.isBranchUnique(agencyId, this.branchId, branchName, true).subscribe((data) => {
        if (data.length !== 0) {
          isMain = true;
          if (isMain) {
            if (event.checked) {
              const subscribtion = this.dialogsService
                .confirm('Confirm', 'Are you sure you want to replace this as the Main Branch with Branch "' + data[0].name + '"?')
                .subscribe(res => {
                  this.result = res;
                  if (this.result && checked) {
                    this.mgBranch.get('isMain').setValue(true);
                  } else {
                    this.mgBranch.get('isMain').setValue(false);
                  }
                },
                  (error: JarvisError) => console.error(`Error in deleting AM-Branch-validateMainBranch(). ${error.friendlyMessage}`));
              this.subscriptions.push(subscribtion);
            }
            if (event.option !== undefined && event.option.selected && checked) {
              const subscribtion = this.dialogsService
                .confirm('Confirm', 'Are you sure you want to replace this as the Main Branch with "Branch ' + data[0].name + '"?')
                .subscribe(res => {
                  this.result = res;
                  if (this.result) {
                    this.mgBranch.get('isMain').setValue(true);
                  } else {
                    this.mgBranch.get('isMain').setValue(false);
                  }
                },
                  (error: JarvisError) => console.error(`Error in deleting AM-Branch-validateMainBranch(). ${error.friendlyMessage}`));
              this.subscriptions.push(subscribtion);
            }

          }
        }
      },
        (error: JarvisError) => console.error(`Error in Getting AM-Branch-validateMainBranch(). ${error.friendlyMessage}`));
      this.subscriptions.push(subscription);
    }
  }

  checkMain(event) {
    if (!event.checked) {
      this.mgBranch.get('isMain').setValue(false);
      this.mgBranch.get('isMain').disable();
    } else {
      this.mgBranch.get('isMain').enable();
    }
  }

  getBranch(id) {
    const subscription = this.branchDataService.getBranchById(id).subscribe(
      (branchData) => {

        this.branchInfo = branchData;
        this.mgBranch.get('isActive').setValue(branchData.mgBranch.isActive);
        if (!branchData.mgBranch.isActive) {
          this.mgBranch.get('isMain').disable();
        }
        this.mgBranch.get('name').setValue(branchData.mgBranch.name);
        if (branchData.mgBranch.isMain) {
          this.mgBranch.get('isMain').disable();
          this.mgBranch.get('isActive').disable();
        }
        this.mgBranch.get('isMain').setValue(branchData.mgBranch.isMain);
        this.mgBranch.get('address1').setValue(branchData.mgBranch.address1);
        this.mgBranch.get('address2').setValue(branchData.mgBranch.address2);
        this.mgBranch.get('countryId').setValue(branchData.mgBranch.countryId);
        this.getCities(branchData.mgBranch.countryId);
        this.mgBranch.get('cityId').setValue(branchData.mgBranch.cityId);
        this.mgBranch.get('zipCode').setValue(branchData.mgBranch.zipCode);

        const contacts = branchData.branchContacts;
        for (let index = 1; index < contacts.length; index++) {
          this.addContact(contacts.length);
        }

        this.branchContacts.controls.forEach((control, index) => {
          control.get('id').setValue(contacts[index].id);
          if (contacts[index].isPrimary === true) {
            control.get('isPrimary').setValue(index);
          } else {
            control.get('isPrimary').setValue(null);
          }
          control.get('designationId').setValue(contacts[index].designationId);
          control.get('email').setValue(contacts[index].email);
          control.get('contactNumber').setValue(contacts[index].contactNumber);
          control.get('contactPerson').setValue(contacts[index].contactPerson);
          control.get('createdBy').setValue(contacts[index].createdBy);
        });
        this.mgBranch.get('agencyId').disable();
        this.isDisabled = true;
        this.count = this.branchContacts.length - 1;
      },
      (error: JarvisError) => console.error(`Error in Getting AM-Branch-getBranch(id). ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);

  }

  cancel() {
    let confirmCancelSubscription = null;
    confirmCancelSubscription = Utilities.confirmCancel(this.branchForm.dirty, this.cancelPopupService, this.router,
      ['/authenticated/agency/agencymgmt/branch'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () =>
        window.scrollTo(0, 0), null, CustomConfirmMessages.confirmMessage,
      CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      this.subscriptions.push(confirmCancelSubscription);
      this.confirmCancel = true;
    }
  }
  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.branchForm.dirty && !this.confirmCancel) {
      //  return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
      return this.dialogsService.confirm('Confirm', CustomConfirmMessages.confirmMessage, true,
        CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    } else {
      return of(true);
    }
    // return true;
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  cityClicked() {

  }

}
