import { Component, OnInit } from '@angular/core';
import { HomeCarouselService } from '../services/home-carousel.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.css']
})
export class HomeCarouselComponent implements OnInit {
  ContentUrl = this.configurationService.config.baseUrls.contentUrl;

  constructor(public homeCarouselService: HomeCarouselService, public configurationService: ConfigurationService) { }

  ngOnInit() {

  }

}
