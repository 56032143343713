import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from 'src/app/common/constants';
import { DateAdapter, MAT_DATE_FORMATS, MatTableDataSource, MatPaginator, MatSort, Sort, PageEvent, MatSnackBar } from '@angular/material';
import { DialogsService } from '../../../common/b2b-shared/dialogs/dialogs.service';
import { Agentuserlistviewmodel } from 'src/app/b2b/viewmodels/agency-mgmt/agent/agentuserlistviewmodel';
import { FormGroup, FormControl } from '@angular/forms';
import { AgentUserDataService } from '../../../../b2b/common/b2b-shared/services/agent-user-data.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { JarvisError } from 'src/app/common/jarviserror';
import { Subscription } from 'rxjs';
import { ErrorMessages } from 'src/app/common/errormessage';
import { AgentSearchViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/agent/agentsearchviewmodel';
import { AgencyBranchListViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/agencybranchlistviewmodel';
import { Utilities } from 'src/app/common/utilities/utilities';
import { BranchDataService } from 'src/app/b2b/common/b2b-shared/services/branch-data.service';
import { DatePipe } from '@angular/common';
import { AgencyProfileDataService } from '../../../../b2b/common/b2b-shared/services/agency-profile-data.service';
import { AgencyListViewModel } from '../../../viewmodels/agency-mgmt/agencylistviewmodel';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.css'],
})
export class AgentListComponent implements OnInit, OnDestroy {
  create = CONSTANTS.operation.create;
  edit = CONSTANTS.operation.edit;
  read = CONSTANTS.operation.read;
  operation: string;
  subscriptions: Subscription[] = [];
  dataSource: MatTableDataSource<Agentuserlistviewmodel>;
  displayedColumns = ['firstName', 'userName', 'branchName', 'designation', 'email', 'userApplicationRole',
    'updatedDate', 'updatedBy', 'isActive', 'actions'];
  actions: string;
  agentUserList: Agentuserlistviewmodel[];
  searchAgentForm: FormGroup;
  searchAgencyArray: AgencyBranchListViewModel[];
  searchAgentArray: Agentuserlistviewmodel[];
  agentId: string;
  name: string;
  type: string;
  code: string;
  id: string;
  firstName: string;
  lastName: string;
  searchAgents: AgentSearchViewModel;
  showGrid = false;
  pageSize = 20;
  offset = 0;
  orderBy = 'updatedDate';  // Default Order;
  sortOrder = 'DESC';        // Default Sort Order
  isDisabledAgency = true;
  isDisabledAgent = true;
  agencyList: AgencyListViewModel[];
  totalCreditLimit: string;
  creditBalance: string;
  creditConsumed: string;
  currency: string;
  // showCredit = false;
  noDataAvailable: boolean;
  pageEvent: PageEvent;
  Math: any;
  totalRecords: number;
  pageIndex = 0;
  agencyId: number;

  isSearchByAgency = false;
  isSearchByName = false;

  @ViewChild(MatSort) sort: MatSort;

  privileges: string[];
  deleteView = false;
  createView = false;
  readView = false;

  @Input()
  spinnerDialogId: string;
  spinnerDialogTimeout: number;

  // Validation Properties
  noRecordFoundValidationMsg = ErrorMessages.noSearchResultsMessage;
  AgentUserAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.AgentUser);

  constructor(private agentUserDataService: AgentUserDataService,
    private dialogsService: DialogsService,
    private userProfileService: UserProfileService,
    private agencyProfileDataService: AgencyProfileDataService,
    private branchDataService: BranchDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef) { this.Math = Math; }

  ngOnInit() {
    this.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    if (!this.AgentUserAccess.hasViewAccess) {
      this.router.navigate(['/']);
    }
    this.searchAgentForm = new FormGroup({
      searchByAgency: new FormControl(),
      searchByName: new FormControl()
    });

    // this.getPrivileges();

    this.name = this.activatedRoute.snapshot.queryParams['name'] || null;
    this.type = this.activatedRoute.snapshot.queryParams['type'] || null;
    this.code = this.activatedRoute.snapshot.queryParams['code'] || null;
    this.id = this.activatedRoute.snapshot.queryParams['id'] || null;
    this.agentId = this.activatedRoute.snapshot.queryParams['agentId'] || null;
    this.firstName = this.activatedRoute.snapshot.queryParams['firstName'] || null;
    this.lastName = this.activatedRoute.snapshot.queryParams['lastName'] || null;

    this.getAllAgents();

    if (!Utilities.isNullOrEmpty(this.name) && !Utilities.isNullOrEmpty(this.type)) {
      this.buildSearchModel();
      const branchModel = new AgencyBranchListViewModel;

      if (this.type.toUpperCase() === 'AGENCY') {
        this.getAgencyCredit(this.code);
        // this.showCredit = true;
        branchModel.code = this.code;
      } else {
        branchModel.code = '';
      }
      branchModel.type = this.type;
      branchModel.name = this.name;
      this.searchAgentForm.get('searchByAgency').setValue(branchModel);

      if (this.id !== '') {
        this.searchAgentForm.value.searchByAgency.id = this.id;
      }
      this.getAgentList(this.searchAgents);
    } else if (!Utilities.isNullOrEmpty(this.firstName) && !Utilities.isNullOrEmpty(this.lastName)) {
      const agentModel = new Agentuserlistviewmodel;
      agentModel.firstName = this.firstName;
      agentModel.lastName = this.lastName;
      agentModel.id = this.agentId;
      this.setAgentData(agentModel);
      this.searchAgentForm.get('searchByName').setValue(agentModel);
    }

    this.searchAgentForm.controls.searchByAgency.valueChanges.subscribe(data => {
      this.searchAgencyArray = [];
      let flag;
      (data === null) ? (flag = true) : (data.id === undefined ? flag = true : flag = false);
      if (flag) {
        this.branchDataService.getAgencyBranchByNameOrCode(data, false, this.agencyId).subscribe(result => {
          this.searchAgencyArray = result;
        });
      }
    });

    this.searchAgentForm.controls.searchByName.valueChanges.pipe(debounceTime(250),
      distinctUntilChanged()).subscribe(data => {
        this.searchAgentArray = [];
        let flag;
        (data === null) ? (flag = true) : (data.id === undefined ? flag = true : flag = false);
        if (flag) {
          this.agentUserDataService.getAgentsByName(data, this.agencyId).subscribe(result => {
            this.searchAgentArray = result;
          });
        }
      });

  }

  getAgencyCredit(agencyCode: string) {
    const subscription = this.agencyProfileDataService.getAgencyByCode(agencyCode).subscribe((agencyList) => {
      this.agencyList = agencyList;
      if (agencyList.length !== 0) {
        this.totalCreditLimit = Utilities.commaFormatted(+ agencyList[0].totalCreditLimit);
        this.creditBalance = Utilities.commaFormatted(+agencyList[0].creditBalance);
        this.creditConsumed = Utilities.commaFormatted(+agencyList[0].creditConsumed);
        this.currency = agencyList[0].currencyCode;

      }
    }, (error: JarvisError) => { console.error(`Error in Getting getAgencyCredit. ${error.friendlyMessage}`); });
    this.subscriptions.push(subscription);
  }

  getAllAgents() {
    this.searchAgents = {
      agencyId: this.agencyId,
      branchId: 0,
      offset: this.offset,
      fetchNext: this.pageSize,
      orderBy: this.orderBy,
      sortOrder: this.sortOrder
    };
    this.getAgentList(this.searchAgents);
  }

  getAgentList(searchrequest: AgentSearchViewModel) {
    this.isSearchByAgency = true;
    this.isSearchByName = false;
    // this.showSpinner();
    const subscription = this.agentUserDataService.getAgents(searchrequest).subscribe((agentList) => {
      this.agentUserList = agentList;
      this.dataSource = new MatTableDataSource<Agentuserlistviewmodel>(this.agentUserList);
      if (agentList.length === 0) {
        this.totalRecords = 0;
        this.noDataAvailable = true;
        this.showGrid = false;
      } else {
        this.totalRecords = this.agentUserList[0].totalRecords;
        this.noDataAvailable = false;
        this.showGrid = true;
      }
      this.dataSource.sort = this.sort;
      this.resetAgencyChange();
    }, (error: JarvisError) => { this.hideSpinner(); console.error(`Error in Getting getAgentList. ${error.friendlyMessage}`); },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  search(event, data) {
    this.isSearchByAgency = true;
    this.isSearchByName = false;
    this.searchAgentForm.get('searchByName').reset();
    if (event.source.selected) {
      this.type = data.type;
      this.id = data.id;
      this.buildSearchModel();
      this.name = data.name;
      this.code = data.code;
      // if (data.type.toUpperCase() === 'AGENCY') {
      //   this.getAgencyCredit(this.code);
      //   this.showCredit = true;
      // } else {
      //   this.showCredit = false;
      // }
      this.getAgentList(this.searchAgents);
    }
    this.resetAgentChange();
  }

  searchAgent(event, data) {
    this.isSearchByName = true;
    this.isSearchByAgency = false;
    this.searchAgentForm.get('searchByAgency').reset();
    // this.showCredit = false;
    this.setAgentData(data);
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.resetAgencyChange();
  }

  setAgentData(data) {
    this.isSearchByName = true;
    this.agentId = data.id;
    this.isSearchByAgency = false;
    const subscription = this.agentUserDataService.getAgentUserById(data.id).subscribe((agentList) => {
      const agent = new Agentuserlistviewmodel();
      agent.id = agentList.id;
      agent.firstName = agentList.firstName;
      agent.lastName = agentList.lastName;
      agent.userName = agentList.userName;
      agent.agencyName = agentList.agency.name;
      agent.agencyCode = agentList.agency.code;
      agent.branchName = agentList.branch.name;
      agent.designation = agentList.designation;
      agent.email = agentList.email;
      agent.roleName = agentList.roleName;
      agent.updatedBy = agentList.updatedBy;
      agent.updatedDate = agentList.updatedDate;
      agent.isActive = agentList.isActive;
      this.agentUserList = [];
      this.agentUserList.push(agent);
      this.dataSource = new MatTableDataSource<Agentuserlistviewmodel>(this.agentUserList);
      if (this.agentUserList.length === 0) {
        this.totalRecords = 0;
        this.noDataAvailable = true;
        this.showGrid = false;
      } else {
        this.totalRecords = this.agentUserList.length;
        this.noDataAvailable = false;
        this.showGrid = true;
      }
      this.dataSource.sort = this.sort;
      this.resetAgentChange();
    }, (error: JarvisError) => { this.hideSpinner(); console.error(`Error in Getting setAgentData. ${error.friendlyMessage}`); },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  // getPrivileges() {
  //   this.privileges = this.userProfileService.GetUserPermissionsForFeature(CONSTANTS.application.backoffice,
  //     CONSTANTS.featuretypeid.Agents);
  //   if (this.privileges !== null) {
  //     for (const privilege of this.privileges) {
  //       if (privilege === 'VW') {
  //         this.readView = true;
  //         // this.createView = false;
  //       } else if (privilege === 'CR') {
  //         this.createView = true;
  //       } else if (privilege === 'DE') {
  //         this.deleteView = true;
  //       }
  //     }
  //   }
  // }

  displayFn(val: AgencyBranchListViewModel) {
    if (val === undefined || val === null) {
      return val;
    } else if (val.code === '') {
      return val.name;
    } else {
      return val.name + ' - ' + val.code;
    }
  }

  displayFnAgent(val: Agentuserlistviewmodel) {
    if (val === undefined || val === null) {
      return val;
    } else if (val.firstName !== '' || val.lastName !== '') {
      return val.firstName + ' ' + val.lastName;
    }
  }

  resetAgencyChange() {
    if (this.searchAgentForm.get('searchByAgency').value === null || this.searchAgentForm.get('searchByAgency').value === '') {
      this.isDisabledAgency = true;
    } else {
      this.isDisabledAgency = false;
    }
  }

  resetAgentChange() {
    if (this.searchAgentForm.get('searchByName').value === null || this.searchAgentForm.get('searchByName').value === '') {
      this.isDisabledAgent = true;
    } else {
      this.isDisabledAgent = false;
    }
  }

  resetSearchByAgency() {
    this.searchAgentForm.get('searchByAgency').reset();
    this.isDisabledAgency = true;
    if (this.isSearchByAgency && !this.isSearchByName) {
      this.resetFlags();
    }
    this.getAllAgents();
  }
  resetSearchByName() {
    this.searchAgentForm.get('searchByName').reset();
    this.isDisabledAgent = true;
    if (!this.isSearchByAgency && this.isSearchByName) {
      this.resetFlags();
    }
    this.getAllAgents();
  }

  resetFlags() {
    this.showGrid = false;
    // this.showCredit = false;
    this.dataSource = null;
    this.totalRecords = 0;
    this.noDataAvailable = false;
    this.isDisabledAgency = true;
    this.isDisabledAgent = true;
    this.isSearchByAgency = false;
    this.isSearchByName = false;
  }

  buildSearchModel() {
    this.showGrid = true;
    this.pageIndex = 0;
    this.offset = 0;
    this.searchAgents = new AgentSearchViewModel();
    if (this.type.toUpperCase() === 'AGENCY') {
      this.searchAgents.agencyId = parseInt(this.id, 0);
    } else {
      this.searchAgents.branchId = parseInt(this.id, 0);
    }
    this.searchAgents.offset = this.offset;
    this.searchAgents.fetchNext = this.pageSize;

    this.searchAgents.orderBy = this.orderBy;
    this.searchAgents.sortOrder = this.sortOrder;
  }

  sortData(sort: Sort) {
    if (this.searchAgentForm.get('searchByAgency').value === null || this.searchAgentForm.get('searchByAgency').value === '') {
      if (sort.direction !== '') {
        this.orderBy = sort.active;
        this.sortOrder = sort.direction;
        this.searchAgents.orderBy = sort.active;
        this.searchAgents.sortOrder = sort.direction;
        this.searchAgents.offset = this.offset;
        this.searchAgents.fetchNext = this.pageSize;
        this.getAgentList(this.searchAgents);
      }
    }
  }

  onPageChange(event) {
    this.pageSize = event.rows;        // event.rows = Number of rows to display in new page
    this.pageIndex = event.page;        // event.page = Index of the new page
    if (event.pageCount > 1 && (this.searchAgentForm.get('searchByAgency').value === null || this.searchAgentForm.get('searchByAgency').value === '')) {
      this.offset = event.first;        // event.first = Index of the first record
      this.searchAgents.offset = this.offset;
      this.searchAgents.fetchNext = this.pageSize;
      this.getAgentList(this.searchAgents);
    }
    // window.scrollTo(0, 0);
  }

  createAgent() {
    this.router.navigate(['../agent', 0, CONSTANTS.operation.create], { relativeTo: this.activatedRoute });
  }

  deleteAgentUser(agentUserId) {
    const subscription = this.agentUserDataService.deleteAgentUser(agentUserId).subscribe(data => {
      window.scrollTo(0, 0);
      this.snackBar.open(ErrorMessages.userDeletionMessage, '', {
        duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top',
        panelClass: 'showSnackBar'
      });
      if (this.isSearchByAgency) {
        this.getAgentList(this.searchAgents);
      } else {
        this.resetSearchByName();
      }

    },
      (error: JarvisError) => console.error(`Error in Deleting AM-Agent-deleteAgent(agentUserId). ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  goUserDelete(value) {
    const val = value.split(':');
    const agentUserId = val[0];
    const subscription = this.dialogsService
      .confirm('Confirm', ErrorMessages.userDeletionConfirmationMessage).subscribe(res => {
        const result = res;
        if (result) {
          this.deleteAgentUser(agentUserId);
        } else {
          this.actions = null;
        }
      },
        (error: JarvisError) => console.error(`Error in Getting AM-Agent-goUserDelete(value). ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  goUserUpdate(value) {
    const val = value.split(':');
    const agentUserId = val[0];
    this.operation = val[1];
    this.router.navigate(['../agent', agentUserId, this.operation.trim().toLowerCase()],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          name: this.isSearchByAgency ? this.name : null,
          code: this.isSearchByAgency ? this.code : null,
          type: this.isSearchByAgency ? this.type : null,
          id: this.isSearchByAgency ? this.id : null,
          agentId: this.isSearchByName ? this.agentId : null,
          firstName: this.isSearchByName ? this.firstName : null,
          lastName: this.isSearchByName ? this.lastName : null,
        }
      });
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.hideSpinner();
  }

}
