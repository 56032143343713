import { IMenuItem } from './framework/fw/services/menu.service';

export let initialMenuItems: Array<IMenuItem> = [

  {
    text: 'Unbeatable Deals',
    icon: 'person',
    route: 'siteinfomgmt/dealinfo',
    submenu: null
  },
  {
    text: 'System Check',
    icon: 'person', // Todo Change Icon as appropriate
    route: 'siteinfomgmt/systemcheck',
    submenu: null
  },
  {
    text: 'About MG',
    icon: 'person', // Todo Change Icon as appropriate
    route: 'https://www.mgholidaygroup.com',
    submenu: null
  }
  /*      {
      text: 'Settings',
      icon: 'person',
      route: null ,
      submenu: [{
        text: 'Roni',
        icon: 'adb',
        route: 'null',
      submenu: [{
        text: 'Sports',
      icon: 'person',
      route: 'dealinfo',
      submenu: null
      }]
      }]
    } */
];


// The number of elements here should be the same as that in the initial menus in initialMenuItems
export let defaultMenuItems: Array<IMenuItem> = [
  {
    text: '',
    icon: '',
    route: '',
    submenu: null
  },
  {
    text: '',
    icon: '',
    route: '',
    submenu: null
  },
];



