import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from 'src/app/common/constants';
import { MatTableDataSource, MatPaginator, MatSort, Sort, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { Subscription, Observable, Subject } from 'rxjs';
import { JarvisError } from 'src/app/common/jarviserror';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';

import { MarkupListViewModel } from '../../viewmodels/markup-mgmt/common/markupdatalistviewmodel';
import { MarkupSearchViewModel } from '../../viewmodels/markup-mgmt/common/markupsearchviewmodel';
import { CurrencyApiService } from '../../../common/shared/services/currency-api.service';
import { AgencyMarkupService } from '../../common/b2b-shared/services/agency-markup.service';
import { DatePipe, DecimalPipe, formatNumber } from '@angular/common';
import { DatePickerDateAdapterService } from '../../common/b2b-shared/services/date-picker-date-adapter.service';
import { ChargeTypeViewModel } from '../../viewmodels/markup-mgmt/common/chargetypeviewmodel';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { ErrorMessages } from '../../../common/errormessage';
import { Paginator } from 'primeng/paginator';
import { MarkupUtilities } from '../../viewmodels/markup-mgmt/common/markupUtilities';
import { UserInputTransformService } from '../../../common/shared/services/user-input-transform.service';
import { NavigatorService } from '../../../framework/fw/services/navigator.service';


@Component({
  selector: 'app-branch-markup',
  templateUrl: './branch-markup.component.html',
  styleUrls: ['./branch-markup.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: DatePickerDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: CONSTANTS.DATE_FORMATS },
    DecimalPipe
  ]
})
export class BranchMarkupComponent implements OnInit {
  actions: string;
  create = CONSTANTS.operation.create;
  edit = CONSTANTS.operation.edit;
  read = CONSTANTS.operation.read;
  editView = false;
  readView = false;
  deleteView = false;

  searchBranchMarkupForm: FormGroup;
  markupSearch: MarkupSearchViewModel = <MarkupSearchViewModel>{};
  dataSource: MatTableDataSource<MarkupListViewModel>;
  branchList: MarkupListViewModel[] = [<MarkupListViewModel>{}];

  chargeTypeList$: Observable<ChargeTypeViewModel[]>;
  chargeTypeArr: ChargeTypeViewModel[] = [];

  markupBranch: any;

  noDataAvailable: boolean;
  totalRecords: number;
  Math: any;
  showGrid = false;
  pageSize = 20;
  offset = 0;
  direction = 1;
  orderBy = 'ModifiedDate';  // Default Order;
  sortOrder = 'DESC';        // Default Sort Order
  pageIndex: number;
  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  showFilter: boolean;
  defaultCurrancy: string;
  operation: string;
  selectedMarkupBranch: any;
  isPageChanged: boolean;
  isFilterApplied: boolean;
  todaysDate = new Date();
  displayedColumns = [
    'createdDate', 'from', 'to', 'markupType', 'markupValue',
    'currency', 'updatedDate', 'updatedBy', 'isActive', 'actions'
  ];

  status = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 },
  ];

  subscription: Subscription;
  messages: any = {};
  amount = 1;
  percentage = 2;

  dateTypeList = CONSTANTS.markup.dateTypeList;
  B2BMarkupAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.B2BMarkup);

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('pp') paginator: Paginator;

  private resetDatepickerSubject: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private agencyMarkupService: AgencyMarkupService,
    private dialogsService: DialogsService,
    private currencyApiService: CurrencyApiService,
    private userProfileService: UserProfileService,
    public userInputTransformService: UserInputTransformService,
    private navigatorService: NavigatorService,
    public decimalPipe: DecimalPipe
  ) {

    this.subscription = this.agencyMarkupService.getSelectedBranch().subscribe(message => {
      if (message) {
        this.selectedMarkupBranch = message;
        this.searchBranchMarkup();
      } else {
        this.messages = undefined;
      }
    });

  }

  ngOnInit() {
    this.selectedMarkupBranch = this.agencyMarkupService.selectedMarkupBranch;
    this.defaultCurrancy = this.currencyApiService.getDefaultCurrancy();

    this.Math = Math;
    this.sort.disableClear = true;
    this.getChargeType();

    this.searchBranchMarkupForm = new FormGroup({
      dateType: new FormControl(),
      fromDate: new FormControl(),
      toDate: new FormControl(),
      chargeTypeId: new FormControl(),
      markupValueFrom: new FormControl(''),
      markupValueTo: new FormControl(''),
      statusId: new FormControl(),
    });

    this.showGrid = false;
    this.noDataAvailable = true;
    this.isFilterApplied = false;

    this.searchBranchMarkup();
    this.addValidations();
  }

  removeCurrencyPipeFormat($event) {
    $event.target.value = MarkupUtilities.replaceComma($event.target.value);
  }

  filterMarkup() {
    this.offset = 0;
    this.pageSize = 20;
    this.isFilterApplied = true;
    this.searchBranchMarkup();
  }

  resetDatePicker() {
    this.resetDatepickerSubject.next();
  }

  buildModel() {
    this.showGrid = true;
    this.markupSearch = new MarkupSearchViewModel();
    this.markupSearch.offset = this.offset;
    this.markupSearch.fetchNext = this.pageSize;
    this.markupSearch.orderBy = this.orderBy;
    this.markupSearch.sortOrder = this.sortOrder;

    this.markupSearch.fromDateKey = null;
    this.markupSearch.toDateKey = null;

    this.markupSearch.dateType = this.isFilterApplied ? this.searchBranchMarkupForm.get('dateType').value : null;
    this.markupSearch.statusId = this.isFilterApplied ? this.searchBranchMarkupForm.get('statusId').value : null;
    const mValueFrom = this.isFilterApplied ? (this.searchBranchMarkupForm.get('markupValueFrom').value ?
      +MarkupUtilities.replaceComma(this.searchBranchMarkupForm.get('markupValueFrom').value) : null) : null;
    this.markupSearch.markupValueFrom = mValueFrom;
    const mValueTo = this.isFilterApplied ? (this.searchBranchMarkupForm.get('markupValueTo').value ?
      +MarkupUtilities.replaceComma(this.searchBranchMarkupForm.get('markupValueTo').value) : null) : null;
    this.markupSearch.markupValueTo = mValueTo;

    this.markupSearch.fromDate = this.isFilterApplied ? this.searchBranchMarkupForm.get('fromDate').value : null;
    this.markupSearch.toDate = this.isFilterApplied ? this.searchBranchMarkupForm.get('toDate').value : null;

    this.markupSearch.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    this.markupSearch.branchId = (this.selectedMarkupBranch.isAgency) ? 0 :
      this.selectedMarkupBranch.id; // selected all 0
    this.markupSearch.currencyId = this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId; // 14;

    this.markupSearch.tabId = CONSTANTS.markup.tab.branch.id;
    this.markupSearch.tabName = CONSTANTS.markup.tab.branch.label;
    this.markupSearch.markupTypeId = this.searchBranchMarkupForm.get('chargeTypeId').value;
  }

  getChargeType() {
    this.chargeTypeList$ = this.agencyMarkupService.getChargeType(0);
    this.chargeTypeList$.subscribe((ele) => {
      ele.forEach(element => {
        this.chargeTypeArr.push(element);
      });
    });
  }

  getMarkupTypeByID(id) {
    let chargetype1;
    this.chargeTypeArr.forEach(element => {
      if (element['id'] === id) {
        return chargetype1 = element['chargeType'];
      }
    });

    if (chargetype1) {
      return chargetype1;
    }
  }

  searchBranchMarkup() {
    this.buildModel();
    this.showSpinner();
    const subscription = this.agencyMarkupService.getMarkupList(this.markupSearch).subscribe((branchList) => {
      this.branchList = branchList.result;

      if (this.branchList && this.branchList.length > 0) {
        this.dataSource = new MatTableDataSource<MarkupListViewModel>(this.branchList);
        this.totalRecords = +this.branchList[0]['total'];
        this.noDataAvailable = false;
        this.showGrid = true;

        if (this.offset === 0 && this.isPageChanged === true) {
          setTimeout(() => {
            this.paginator.changePage(0);
          }, 100);
        }

      } else {
        this.dataSource = new MatTableDataSource<MarkupListViewModel>();
        this.totalRecords = 0;
        this.noDataAvailable = true;
        this.showGrid = false;
      }

      this.dataSource.sort = this.sort;

    }, (error: JarvisError) => {
      this.hideSpinner(); console.error(`Error in getting branch markup. ${error.friendlyMessage}`);
    },
      () => this.hideSpinner()
    );
    this.subscriptions.push(subscription);
  }

  addValidations() {
    if (this.searchBranchMarkupForm.get('chargeTypeId').value && this.searchBranchMarkupForm.get('chargeTypeId').value === this.amount) {
      this.searchBranchMarkupForm.get('markupValueFrom')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxAmount),
          Validators.min(CONSTANTS.markup.markupMinAmount),
          MarkupUtilities.markupAmountValidatior,
          MarkupUtilities.rangeValidator
        ]);
      this.searchBranchMarkupForm.get('markupValueTo')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxAmount),
          Validators.min(CONSTANTS.markup.markupMinAmount),
          MarkupUtilities.markupAmountValidatior,
          MarkupUtilities.rangeValidator
        ]);
    } else {
      this.searchBranchMarkupForm.get('markupValueFrom')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxPercent),
          Validators.min(CONSTANTS.markup.markupMinPercent),
          MarkupUtilities.markupPercentValidatior,
          MarkupUtilities.rangeValidator
        ]);
      this.searchBranchMarkupForm.get('markupValueTo')
        .setValidators([
          Validators.max(CONSTANTS.markup.markupMaxPercent),
          Validators.min(CONSTANTS.markup.markupMinPercent),
          MarkupUtilities.markupPercentValidatior,
          MarkupUtilities.rangeValidator
        ]);
    }
    this.searchBranchMarkupForm.get('markupValueFrom').updateValueAndValidity();
    this.searchBranchMarkupForm.get('markupValueTo').updateValueAndValidity();
  }

  addDateValidation(control) {
    this.resetDatePicker();
    this.searchBranchMarkupForm.get('fromDate').setValue('');
    this.searchBranchMarkupForm.get('toDate').setValue('');
    if (control === 'dateType' && this.searchBranchMarkupForm.get(control).value) {
      this.searchBranchMarkupForm.get('fromDate').setErrors({ required: true });
      this.searchBranchMarkupForm.get('toDate').setErrors({ required: true });
    } else {
      this.searchBranchMarkupForm.get('fromDate').setValidators(null);
      this.searchBranchMarkupForm.get('toDate').setValidators(null);
    }
  }

  hasErrors(controlName: string) {
    if (this.searchBranchMarkupForm.get(controlName)) {
      return (this.searchBranchMarkupForm.get(controlName).dirty || this.searchBranchMarkupForm.get(controlName).touched)
        && this.searchBranchMarkupForm.get(controlName).errors !== null;
    }
  }

  // TODO: refactor
  getMarkupValidationMessage(controlName: string) {
    if (this.searchBranchMarkupForm.get(controlName).hasError('required')) {
      if (controlName === 'fromDate' || controlName === 'toDate') {
        return ErrorMessages.requiredFieldMessage;
      }
    } else if (this.searchBranchMarkupForm.get(controlName).hasError('maxDateError')) {
      if (controlName === 'toDate') {
        return ErrorMessages.invalidDateRangeMessage;
      }
    } else if (this.searchBranchMarkupForm.get(controlName).hasError('max')) {
      const markupLimitMessage =
        (this.searchBranchMarkupForm.get('chargeTypeId').value) === this.amount
          ? ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxAmount)
          : ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxPercent);
      // `${CONSTANTS.markup.markupMaxAmount | number }`
      return markupLimitMessage;
    } else if (this.searchBranchMarkupForm.get(controlName).hasError('min')) {
      const markupLimitMessage =
        (this.searchBranchMarkupForm.get('chargeTypeId').value) === this.amount
          ? ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinAmount)
          : ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinPercent);

      return markupLimitMessage;
    } else {
      return '';
    }
  }

  transformAmount(amount, column) {
    if (amount && amount !== '') {
      amount = Number(amount.replace(/[,]/g, ''));
      let markup = null;
      let isNumber = false;
      isNumber = isNaN(amount);
      if (isNumber === false) {
        amount = Number(amount);
        if (column === 'From') {
          // tslint:disable-next-line:max-line-length
          this.searchBranchMarkupForm.controls['markupValueFrom'].setValue(formatNumber(amount, this.navigatorService.getCurrentLocale(), CONSTANTS.digitsInfo));
          markup = this.searchBranchMarkupForm.get('markupValueFrom');
          if (this.searchBranchMarkupForm.get('markupValueTo').hasError('validRange')) {
            this.searchBranchMarkupForm.get('markupValueTo').updateValueAndValidity();
          }
        } else {
          // tslint:disable-next-line:max-line-length
          this.searchBranchMarkupForm.controls['markupValueTo'].setValue(formatNumber(amount, this.navigatorService.getCurrentLocale(), CONSTANTS.digitsInfo));
          markup = this.searchBranchMarkupForm.get('markupValueTo');
          if (this.searchBranchMarkupForm.get('markupValueFrom').hasError('validRange')) {
            this.searchBranchMarkupForm.get('markupValueFrom').updateValueAndValidity();
          }
        }
      }
      markup.updateValueAndValidity();
    }
  }

  numberFormatInCurrentLocaleFromPrice(value) { // If index not present, then remove the parameter
    if (value && value !== '') {
      this.searchBranchMarkupForm.get('markupValueFrom').setValue(this.userInputTransformService.userInputNumberTransform(value));
    }
  }

  numberFormatInCurrentLocaleToPrice(value) { // If index not present, then remove the parameter
    if (value && value !== '') {
      this.searchBranchMarkupForm.get('markupValueTo').setValue(this.userInputTransformService.userInputNumberTransform(value));
    }
  }


  calculateNightCount() {
    if (this.searchBranchMarkupForm.get('fromDate').value && this.searchBranchMarkupForm.get('toDate').value) {
      const dt1 = new Date(this.searchBranchMarkupForm.get('fromDate').value);
      const dt2 = new Date(this.searchBranchMarkupForm.get('toDate').value);
      const nightCount = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
      if (nightCount > 29) {
        this.searchBranchMarkupForm.get('toDate').setErrors({ maxDateError: true });
      } else {
        this.searchBranchMarkupForm.get('toDate').setErrors(null);
      }
    }
  }

  createNewBranch() {
    this.router.navigate(['./branchmarkupInfo/',
      this.userProfileService.getBasicUserInfo().agencyId,
      this.selectedMarkupBranch.id,
      CONSTANTS.markup.tab.branch.id,
      0,
      'create'], { relativeTo: this.activatedRoute });
  }

  showFilterSection() {
    this.showFilter = !this.showFilter;
  }

  buildSearchModel() {
    this.showGrid = true;
    this.pageIndex = 0;
    this.offset = 0;
    this.markupSearch = new MarkupSearchViewModel();

    this.markupSearch.offset = this.offset;
    this.markupSearch.fetchNext = this.pageSize;
    this.markupSearch.orderBy = this.orderBy;
    this.markupSearch.sortOrder = this.sortOrder;
  }

  reset() {
    this.isFilterApplied = false;
    this.offset = 0;
    this.pageSize = 20;
    this.searchBranchMarkupForm.reset();
    this.searchBranchMarkup();
    this.resetDatePicker();
    // this.isPageChanged = true;
  }

  updateMarkup(value, operation) {
    this.router.navigate(['./branchmarkupInfo/',
      value.agencyId,
      value.branchId,
      CONSTANTS.markup.tab.branch.id,
      value.groupId,
      operation],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  sortData(sort: Sort) {
    if (sort.direction !== '') {
      this.orderBy = sort.active;
      this.sortOrder = sort.direction;
      this.markupSearch.orderBy = sort.active;
      this.markupSearch.sortOrder = sort.direction;
      this.markupSearch.offset = this.offset;
      this.markupSearch.fetchNext = this.pageSize;
      this.searchBranchMarkup();
    }
  }

  onPageChange(event) {
    this.pageIndex = event.pageIndex;
    this.offset = event.first;
    this.pageSize = event.rows;
    this.markupSearch.offset = this.offset;
    this.markupSearch.fetchNext = this.pageSize;
    this.isPageChanged = (event.page === 0) ? false : true;
    this.searchBranchMarkup();
    setTimeout(() => {
      window.scroll(0, 0);
      window.scrollTo(0, 0);
    }, 400);
  }

  showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

}
