import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { UserAlertApi } from '../../../../framework/fw/users/user-alert-api';
import { CONSTANTS } from 'src/app/common/constants';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { AlertResponceViewModel } from '../../../viewmodels/alerts/alertResponseviewmodel';
import { AlertDataService } from '../../services/alert-data.service';
import { UserAccountApi } from '../../../../framework/fw/users/user-account-api';
import { Subscription, Observable, of } from 'rxjs';
import { JarvisError } from 'src/app/common/jarviserror';
import { FormGroup, FormControl } from '@angular/forms';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';
import { DatePipe } from '@angular/common';
import { CanComponentDeactivate } from 'src/app/b2b/common/b2b-shared/services/guards/can-deactivate-guard.service';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.css']
})
export class AlertListComponent implements OnInit, CanComponentDeactivate {
  dataSource: MatTableDataSource<AlertResponceViewModel> = new MatTableDataSource([]);
  alertList: AlertResponceViewModel[];
  displayedColumns = ['createdDate', 'messageBody'];
  searchAlerts: FormGroup;
  subscriptions: Subscription[] = [];
  subscription: Subscription;
  noDataAvailable: boolean;
  pageSize = 20;
  pageIndex = 0;
  totalRecords: number;
  Math: any;
  isSearch: boolean;
  filteredData: MatTableDataSource<AlertResponceViewModel>;
  noRecordsFound: boolean;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  // readArray: EntityListViewModel;
  isRead: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private alertData: AlertDataService, private activatedRoute: ActivatedRoute, private changeDetectorRefs: ChangeDetectorRef,
    public datepipe: DatePipe, private dialogsService: DialogsService, public userAlertApi: UserAlertApi) {
    this.Math = Math;
  }

  ngOnInit() {
    this.alertList = this.activatedRoute.snapshot.data['alertDetails'];
    if (this.alertList !== undefined) {
      this.totalRecords = this.alertList.length;
    } else {
      this.noDataAvailable = true;
    }
    this.dataSource = new MatTableDataSource<AlertResponceViewModel>(this.alertList);
    this.sortTable(this.dataSource);
    setTimeout(() => this.dataSource.paginator = this.paginator);

    this.isSearch = false;
    this.searchAlerts = new FormGroup({
      fromDate: new FormControl(),
      toDate: new FormControl(),
      searchText: new FormControl()
    });
    if (this.alertList !== undefined) {
      this.setRead();
    }
  }

  getAlertList() {
    this.showSpinner();
    this.isSearch = false;
    this.noRecordsFound = false;
    this.subscription = this.alertData.getAlertDetailsForCurrentApp('B2B')
      .subscribe(data => {
        this.dataSource = new MatTableDataSource<AlertResponceViewModel>(data);
        if (data.length === 0) {
          this.noDataAvailable = true;
        }
        this.totalRecords = data.length;
        this.dataSource.paginator = this.paginator;
        this.alertList = data;
      }, (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in getting message list. ${error.friendlyMessage}`);
      }, () => this.hideSpinner());
    this.subscriptions.push(this.subscription);
    this.sortTable(this.dataSource);
  }
  onPageChange(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    if (this.isSearch) {
      this.dataSource = this.filteredData;
    } else {
      this.getAlertList();
    }
    this.changeDetectorRefs.detectChanges();
  }
  findAlerts() {
    let filterValue = this.searchAlerts.get('searchText').value;
    let filterFrom = this.searchAlerts.get('fromDate').value;
    let filterTo = this.searchAlerts.get('toDate').value;
    let startOrEndDate;
    if (this.searchAlerts.get('fromDate') !== null || this.searchAlerts.get('toDate') !== null) {
      startOrEndDate = 'start';
    } else {
      startOrEndDate = null;
    }

    if (filterFrom !== null || filterTo !== null || (filterValue !== null && filterValue.length >= 3)) {
      this.isSearch = true;
    }
    if (filterFrom !== null) {
      filterFrom = this.datepipe.transform(filterFrom, 'yyyy-MM-dd');
    }
    if (filterTo !== null) {
      filterTo = this.datepipe.transform(filterTo, 'yyyy-MM-dd');
    }
    if (filterValue !== null) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
    }

    if (this.isSearch) {
      this.filteredData = new MatTableDataSource<AlertResponceViewModel>(this.alertList.filter(alert => {
        const createdDate = this.datepipe.transform(alert.createdDate, 'yyyy-MM-dd');
        // const endDate = this.datepipe.transform(alert.endDate, 'yyyy-MM-dd');
        if (startOrEndDate === 'start') {
          if (filterValue !== null && (filterFrom === null && filterTo === null)) {
            if (alert.messageBody.toLowerCase().includes(filterValue)
            ) {
              return alert;
            }
          } else if (filterFrom !== null && (filterTo === null && filterValue === null)) {
            return createdDate >= filterFrom;
          } else if (filterTo !== null && (filterFrom === null && filterValue === null)) {
            return createdDate <= filterTo;
          } else if (filterValue === null && (filterFrom !== null && filterTo !== null)) {
            return filterFrom <= createdDate && createdDate <= filterTo;
          } else if (filterFrom === null && (filterValue !== null && filterTo !== null)) {
            if ((alert.messageBody.toLowerCase().includes(filterValue))
              &&
              createdDate <= filterTo) {
              return alert;
            }
          } else if (filterTo === null && (filterFrom !== null && filterValue !== null)) {
            if ((alert.messageBody.toLowerCase().includes(filterValue)) &&
              createdDate >= filterFrom) {
              return alert;
            }
          } else if (filterFrom !== null && filterTo !== null && filterValue !== null) {
            if ((alert.messageBody.toLowerCase().includes(filterValue)
            ) &&
              filterFrom <= createdDate && createdDate <= filterTo) {
              return alert;
            }
          }
        } else {
          if (filterValue !== null && (filterFrom === null && filterTo === null)) {
            if (alert.messageBody.toLowerCase().includes(filterValue)

            ) {
              return alert;
            }
          }
        }
      }));
      if (this.filteredData.data.length === 0 && (filterFrom !== null || filterTo !== null || filterValue !== null)) {
        this.noRecordsFound = true;
      }
      if (this.filteredData.data.length === 0 && (filterFrom === null && filterTo === null && filterValue == null)) {
        this.totalRecords = this.alertList.length;
        this.dataSource.paginator = this.paginator;
        this.isSearch = false;
      } else {
        this.totalRecords = this.filteredData.data.length;
        this.filteredData.paginator = this.dataSource.paginator;
        this.pageIndex = 0;
        this.filteredData.paginator.firstPage();
      }
      this.sortTable(this.filteredData);
      this.changeDetectorRefs.detectChanges();
    }
  }

  checkIsInputCleared() {
    if (this.searchAlerts.get('searchText').value && this.searchAlerts.get('searchText').value.length < 3 &&
      this.searchAlerts.get('fromDate').value === null && this.searchAlerts.get('toDate').value === null) {
      this.getAlertList();
    }
  }

  resetSearch() {
    this.searchAlerts.reset();
    if (this.isSearch) {
      this.getAlertList();
    }
  }

  sortTable(datasource) {
    datasource.sort = this.sort;
    datasource.sortingDataAccessor = (item, property) => {
      if (typeof item[property] === 'string') {
        return item[property].toLowerCase();
      } else {
        return item[property];
      }
    };
  }
  showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  setRead() {
    // const numm: number[] = [];
    // const len = this.alertList.length;
    // if (this.alertList.length > 0) {
    //   this.alertList.forEach((elementdata, index) => {
    //     if (index <= len && elementdata.isRead === false) {
    //       numm.push(elementdata.id);
    //     }
    //   });
    // //  this.readArray = { ids: numm };
    // }
    // if (numm.length > 0) {
    //   // const subscription = this.alertData.setAllRead(this.readArray)
    //   //   .subscribe(dataAlert => {
    //   //     this.isRead = dataAlert;
    //   //   },
    //   //   (error: JarvisError) => console.error(`Error in Getting alert listing. ${error.friendlyMessage}`));
    //   // this.subscriptions.push(subscription);
    // }
  }
  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.searchAlerts.dirty) {
      return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
    } else {
      return of(true);
    }
   // return true;
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}

