import { map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { GuestDetailsService } from '../../common/b2b-shared/services/guest-details.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { of } from 'rxjs';

export class GuestDetailsValidator {
    static agencyReferenceNumberValidator(guestDetailsService: GuestDetailsService, userProfileService: UserProfileService) {
        return (control: AbstractControl) => {
            if (control.root.value !== undefined && control.root.value !== null && control.root.value !== '') {
                if (control.root.get('agencyReferenceNumber').value.trim() !== '') {
                    const agencyCode = userProfileService.getBasicUserInfo().agencyCode;
                    // tslint:disable-next-line:max-line-length
                    return guestDetailsService.isAgencyReferenceNumberUnique(agencyCode, control.root.get('agencyReferenceNumber').value.trim())
                        .pipe(
                            map(res => {
                                return res ? { 'duplicateAgencyReferenceNumber': true } : null;
                            }));
                } else {
                    return of(null);
                }
            } else {
                return of(null);
            }
        };
    }
}


