import { Component, OnInit, OnDestroy } from '@angular/core';
import { PasswordValidators } from 'src/app/common/customvalidators/password-validators';
import { Validators, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { AgentUserDataService } from 'src/app/b2b/common/b2b-shared/services/agent-user-data.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { AgentInfoViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/agentinfoviewmodel';
import { JarvisError } from 'src/app/common/jarviserror';
import { Subscription, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorMessages } from 'src/app/common/errormessage';
import { Utilities } from 'src/app/common/utilities/utilities';
import { CancelpopupService } from 'src/app/b2b/common/b2b-shared/dialogs/cancelpopup.service';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CONSTANTS } from 'src/app/common/constants';
import { UserProfileViewModel } from 'src/app/common/viewmodels/userprofileviewmodel';
import { UserAccountApi } from '../user-account-api';
import { TopBarDataService } from 'src/app/common/shared/services/top-bar-data.service';
import { TopBarService, TopBarItemViewModel } from '../../services/top-bar.service';
// import { Patterns } from 'src/app/common/patterns';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { MarkFormDirtyService } from 'src/app/b2b/common/b2b-shared/services/mark-form-dirty.service';


function AgentUserEmailValidator(agentUserDataService: AgentUserDataService, agencyCode: string) {
  return (control: AbstractControl) => {
    return agentUserDataService.isAgentUserEmailIdAlreadyTaken(control.value.trim(), agencyCode).pipe(map(res => {
      return res ? { 'duplicateEmail': true } : null;
    }));
  };
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy {


  constructor(private agentUserDataService: AgentUserDataService, private userAccountApi: UserAccountApi,
    private userProfileService: UserProfileService, private cancelPopupService: CancelpopupService,
    private activatedRoute: ActivatedRoute, private router: Router, private snackBar: MatSnackBar,
    private topbarService: TopBarService, private dialogsService: DialogsService, private configurationService: ConfigurationService,
    private markFormDirtyService: MarkFormDirtyService) { }
  agentForm: FormGroup;
  agencyCode = this.userProfileService.getBasicUserInfo().agencyCode;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  agentId = this.userProfileService.getBasicUserInfo().id;
  type = this.userProfileService.getBasicUserInfo().userType;
  agentInfo: AgentInfoViewModel;
  photoURL: string;
  profilePictureUri: string;
  contentUrl = this.configurationService.config.baseUrls.contentUrl;
  subscriptions: Subscription[] = [];
  userProfileUpdate: UserProfileViewModel = new UserProfileViewModel();
  topbarItem: TopBarItemViewModel = new TopBarItemViewModel();
  isphotoUploaded: boolean;

  ngOnInit() {

    this.agentForm = new FormGroup({
      branch: new FormControl(''),
      userName: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      designation: new FormControl(''),
      email: new FormControl('', {
        validators: [Validators.required,
        // Validators.pattern(Patterns.email)
        Validators.email],
        asyncValidators: [AgentUserEmailValidator(this.agentUserDataService, this.agencyCode)]
      }),
      // contactNumber: new FormControl('', [Validators.pattern('[0-9# "+-]*')]),
      b2BRoleId: new FormControl(''),
    });

    this.getAgentUser(this.agentId);
  }

  getAgentUser(agentId) {
    const subscription = this.agentUserDataService.getAgentUserById(agentId).subscribe(
      (data) => {
        this.agentInfo = data;
        // this.getBranches();
        this.agentForm.get('email').setValue(data.email);
        if (data.profilePictureUri != null && data.profilePictureUri.trim() !== '') {
          this.photoURL =  this.contentUrl + data.profilePictureUri;
        }
        this.profilePictureUri = data.profilePictureUri;
        this.agentForm.get('designation').setValue(data.designation);
        this.agentForm.get('firstName').setValue(data.firstName);
        this.agentForm.get('lastName').setValue(data.lastName);
        this.agentForm.get('branch').setValue(data.branch.name);
        this.agentForm.get('designation').disable();
        this.agentForm.get('firstName').disable();
        this.agentForm.get('lastName').disable();
        this.agentForm.get('branch').disable();

        this.topbarItem.firstName = data.firstName;
        this.topbarItem.lastName = data.lastName;
        this.topbarItem.profilePictureUri = data.profilePictureUri;

      },
      (error: JarvisError) => console.error(`Error while getting UM-AgentUser-Info-getAgentUser(agentId). ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  getValidationMessage(controlName: string) {
    if (this.agentForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.agentForm.get(controlName).hasError('pattern')) {
      if (controlName === 'contactNumber') {
        return ErrorMessages.invalidPhoneNumberMessage;
      } else if (controlName === 'email') {
        return ErrorMessages.invalidEmailMessage;
      } else {
        return ErrorMessages.patternMessage;
      }
    } else if (this.agentForm.get(controlName).hasError('duplicateEmail')) {
     this.chcechRepeatEmail();
     return ErrorMessages.duplicateEmailIdMessage;
    } else if (this.agentForm.get(controlName).hasError('email')) {
      return ErrorMessages.invalidEmailMessage;
    } else {
      return '';
    }
  }

  hasErrors(controlName: string) {

    return (this.agentForm.get(controlName).dirty || this.agentForm.get(controlName).touched)
      && this.agentForm.get(controlName).errors !== null;
  }

  chcechRepeatEmail() {

    if (this.agentInfo !== undefined && this.agentInfo.email === this.agentForm.get('email').value) {
      this.agentForm.get('email').setErrors(null);
    } else {
      return ErrorMessages.duplicateEmailIdMessage;
    }

  }

  onPhotoURLChange(updatedPhotoURL) {
    this.profilePictureUri = updatedPhotoURL;
    this.agentForm.markAsDirty();
    this.agentForm.markAsTouched();
    this.isphotoUploaded = true;
  }


  cancel() {
    let confirmCancelSubscription = null;
    const navigationPath = 'hotelmgmt/hotelinfo';
    confirmCancelSubscription = Utilities.confirmCancel(this.agentForm.dirty, this.cancelPopupService, this.router, [navigationPath],
      { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0));
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      this.subscriptions.push(confirmCancelSubscription);
    }
  }
  updateProfile() {
    this.markFormDirtyService.markGroupDirty(this.agentForm);
    if(this.agentForm.valid && this.agentForm.dirty) {
      const agent = Object.assign({}, this.agentInfo, this.agentForm.value);
    //     agent.agencyId = this.agencyId;
    //     agent.branchId = agent.branch.id;
    // agent.email=agent.
    agent.profilePictureUri = this.profilePictureUri;

    agent.createdBy = this.agentInfo.createdBy;
    this.userProfileUpdate.id = this.agentId;
    this.userProfileUpdate.email = agent.email;
    this.userProfileUpdate.profilePictureUri = this.profilePictureUri;
    this.topbarItem.profilePictureUri = this.profilePictureUri;


    const subscription = this.userAccountApi.updateUserProfile(this.userProfileUpdate as UserProfileViewModel)
      .subscribe(data => {
        if (data === true) {
          this.snackBar.open(ErrorMessages.profileUpdatedMessage, '', {
            duration: CONSTANTS.toasterTimeOut.duration,
            verticalPosition: 'top', panelClass: 'showSnackBar'
          });
          this.topbarService.setTopBarItem(this.topbarItem);
          this.router.navigate(['hotelmgmt/hotelinfo'],
            { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
        } else {
          // TODO: Need to check how we are doing error handling.
          this.snackBar.open('Error occourred while updating Agent. ' + data.errors[0].description,
            '', { duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top', panelClass: 'showSnackBar' });
        }
      },
        (error: JarvisError) => console.error(`Error while creating UM-AgentUser-Info-updateAgentUser. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);

    // this.photosDataService.profilePhotoUrl = '';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
