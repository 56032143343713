import { PaymentTypeDetailsViewModel } from './../../viewmodels/payment/paymenttypedetailsviewmodel';
import { MessageDialogComponent } from './../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { ErrorCodes } from './../../../common/errorcodes';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgencyProfileDataService } from '../../common/b2b-shared/services/agency-profile-data.service';
import { ErrorMessages, CustomConfirmMessages, CustomConfirmButtonTexts } from 'src/app/common/errormessage';
import { CountryViewModel } from 'src/app/common/viewmodels/countryviewmodel';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { LookupService } from '../../common/b2b-shared/services/lookup.service';
import { CityViewModel } from 'src/app/common/viewmodels/cityviewmodel';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { CurrencyViewModel } from 'src/app/common/viewmodels/currencyviewmodel';
import { DesignationViewModel } from '../../viewmodels/designationviewmodel';
import { CONSTANTS } from 'src/app/common/constants';
import { Utilities } from 'src/app/common/utilities/utilities';
import { JarvisError } from 'src/app/common/jarviserror';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { CustomErrorStateMatcher } from 'src/app/common/customerrorstatematcher';
import { AgencyInfoViewModel } from 'src/app/common/viewmodels/agencyprofile/agencyinfoviewmodel';
import { MatSnackBar, MatChipInputEvent, MatChipList, MatDialogRef, MatDialog } from '@angular/material';
import { CancelpopupService } from '../../common/b2b-shared/dialogs/cancelpopup.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { PhotosDataService } from '../../common/b2b-shared/services/photos-data.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { PasswordValidators } from 'src/app/common/customvalidators/password-validators';
// import { Patterns } from 'src/app/common/patterns';
import { ENTER } from '@angular/cdk/keycodes';
import { CanComponentDeactivate } from '../../common/b2b-shared/services/guards/can-deactivate-guard.service';
import { MarkFormDirtyService } from '../../common/b2b-shared/services/mark-form-dirty.service';

function DuplicateLegacyAgencyCode(agencyProfileDataService: AgencyProfileDataService, agencyId: number) {
  return (control: AbstractControl) => {
    if (control.value === null) {
      return of(null);
      // return null;
    } else {
      return agencyProfileDataService.isAgencyCodeExists(control.value.trim(), agencyId).pipe(map(res => {
        return res ? { 'duplicateAgencyCode': true } : null;
      }));
    }
  };
}

function DuplicateConatctsEmail(agencyProfileDataService: AgencyProfileDataService, agencyId: number) {
  return (control: AbstractControl) => {
    if (control.value === null) {
      return of(null);
    } else {
      return agencyProfileDataService.isContactEmailAddressExists(control.value.trim(), agencyId).pipe(map(res => {
        return res ? { 'duplicateEmail': true } : null;
      }));
    }
  };
}

function AgentUserNameValidator(agencyProfileDataService: AgencyProfileDataService, agencyId: number) {
  return (control: AbstractControl) => {
    if (control.value === null) {
      return of(null);
    } else {
      return agencyProfileDataService.isUserNameExistsForAgency(control.value.trim(), agencyId).pipe(map(res => {
        return res.isError ? { 'duplicateUserName': true } : null;
      }));
    }
  };
}

function AgentUserEmailValidator(agencyProfileDataService: AgencyProfileDataService) {
  return (control: AbstractControl) => {
    if (control.root.value !== undefined && control.root.value !== null) {
      let agencyCode = '';
      // tslint:disable-next-line:max-line-length
      if (!Utilities.isNullOrEmpty(control.root.get('mgAgency').value) && !Utilities.isNullOrEmpty(control.root.get('mgAgency').value.code)) {
        agencyCode = control.root.get('mgAgency').value.code;
      }
      if (control.value !== null) {
        return agencyProfileDataService.isAgentUserEmailIdAlreadyTaken(control.value.trim(), agencyCode).pipe(map(res => {
          return res ? { 'duplicateEmail': true } : null;
        }));
      } else {
        return of(null);
      }
    } else {
      return of(null);
    }
  };
}

function MatchPasswordValidator(control) {
  if (control.root.controls !== undefined) {
    const superUser = control.root.controls.superUserAgent;
    const password = superUser.get('password');
    const confirmPassword = superUser.get('confirmPassword');
    if (password.value !== null && confirmPassword.value !== null) {
      if (confirmPassword.value === '' && password.value === '') {
        return null;
      } else {
        return password.value !== confirmPassword.value
          ? { 'passwordMismatch': true } : null;
      }
    }
  }
}

@Component({
  selector: 'app-agency-profile',
  templateUrl: './agency-profile.component.html',
  styleUrls: ['./agency-profile.component.css']
})
export class AgencyProfileComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  agencyForm: FormGroup;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  agencyGroup: FormGroup;
  countryList: CountryViewModel[];
  subscriptions: any[] = [];
  cityList: CityViewModel[];
  currencyList: Observable<CurrencyViewModel[]>;
  userDesignationist: Observable<DesignationViewModel[]>;
  count = 0;
  result: boolean;
  actions: string;
  errorStateMatcher = new CustomErrorStateMatcher();
  agencyInfoViewModel: AgencyInfoViewModel = <AgencyInfoViewModel>{};
  photoURL: string;
  Utilities = Utilities;
  profilePictureUri: string;
  contentUrl = this.configurationService.config.baseUrls.contentUrl;
  isCredit = this.userProfileService.getBasicUserInfo().isCredit;
  iscashTopup = false;
  isVirtualAccount = false;
  username = this.userProfileService.getBasicUserInfo().userName;
  confirmCancel = false;
  isSuperUser: boolean;
  readonly separatorKeysCodes: number[] = [ENTER];
  phoneNumberList: Array<string> = [];
  paymentTypeDetails: PaymentTypeDetailsViewModel;
  @ViewChild('chipList') chipList: MatChipList;
  removable = true;
  // maxNumberOfContacts = CONSTANTS.AgencyContacts.maxNumberOfContacts;
  AgencyProfileAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.AgencyProfile);
  BranchAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.Branch);
  AgentUserAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.AgentUser);
  constructor(private agencyProfileDataService: AgencyProfileDataService, private activatedRoute: ActivatedRoute,
    private lookupService: LookupService, private cd: ChangeDetectorRef, private dialogsService: DialogsService,
    private snackBar: MatSnackBar, private router: Router, private cancelPopupService: CancelpopupService,
    private userProfileService: UserProfileService, public photosDataService: PhotosDataService,
    private configurationService: ConfigurationService, public dialog: MatDialog,
    private markFormDirtyService: MarkFormDirtyService) { }

  ngOnInit() {
    if (!this.AgencyProfileAccess.hasViewAccess) {
      if (this.BranchAccess.hasViewAccess) {
        this.router.navigate(['/authenticated/agency/agencymgmt/branch']);
      } else if (!this.BranchAccess.hasViewAccess) {
        if (this.AgentUserAccess.hasViewAccess) {
          this.router.navigate(['/authenticated/agency/agencymgmt/agent']);
        } else {
          this.router.navigate(['/']);
        }
      } else {
        this.router.navigate(['/']);
      }
    }
    this.countryList = this.activatedRoute.parent.snapshot.data['countryList'];
    this.currencyList = this.activatedRoute.parent.snapshot.data['currencyList'];
    this.paymentTypeDetails = this.activatedRoute.parent.snapshot.data['paymentTypeDetails'];
    this.isSuperUser = this.userProfileService.getBasicUserInfo().isSuperUser;
    this.getDesignations();

    this.agencyForm = new FormGroup({
      mgAgency: this.buildMgAgencyDetails(),
      agentId: new FormControl(),
      defaultCurrency: new FormControl(),
      agencyContacts: new FormArray([this.buildContactsDetails()]),
      superUserAgent: this.buildSuperUserDetails(),
    });

    this.getAgencyData(this.agencyId);
    if (this.chipList) {
      this.mgAgency.get('phoneNumber').statusChanges.subscribe(status =>
        this.chipList.errorState = (status === 'INVALID' && (this.mgAgency.get('phoneNumber').hasError('pattern'))) ? true : false
      );
    }
    if (!this.AgencyProfileAccess.hasEditAccess) {
      this.agencyForm.disable();
    }
  }

  buildMgAgencyDetails(): FormGroup {

    this.agencyGroup = new FormGroup({
      agencyName: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern('^[a-zA-Z0-9_ ]*$')]),
      name: new FormControl(''),
      code: new FormControl({ value: null, disabled: true }),
      legacyAgencyCode: new FormControl({ value: null, disabled: true }, {
        validators: [Validators.pattern('^[a-zA-Z0-9]*$'), Validators.required],
        asyncValidators: [DuplicateLegacyAgencyCode(this.agencyProfileDataService, this.agencyId)],
      }),
      logoURL: new FormControl(null),
      website: new FormControl('', Validators.maxLength(50)),
      addressLine1: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(500)]),
      addressLine2: new FormControl({ value: '', disabled: true }, Validators.maxLength(500)),
      countryId: new FormControl({ value: '', disabled: true }, Validators.required),
      cityId: new FormControl({ value: '', disabled: true }, Validators.required),
      zipcode: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')]),
      currencyId: new FormControl({ value: '', disabled: true }, Validators.required),
      emailAddress: new FormControl('', [Validators.required,
      // Validators.pattern(Patterns.email)
      Validators.email]),
      faxNumber: new FormControl(null, Validators.pattern('^[0-9]*$')),
      vatId: new FormControl({ value: '', disabled: !this.isSuperUser }, [Validators.maxLength(20), Validators.minLength(1)]),
      phoneNumber: new FormControl('', Validators.pattern('[0-9+-]*')),
      virtualAccountNumber: new FormControl({ value: '', disabled: true }),
      bankName: new FormControl({ value: '', disabled: true }),
      
    });
    return this.agencyGroup;
  }

  buildContactsDetails(): FormGroup {
    let contactsGroup: FormGroup;
    contactsGroup = new FormGroup({
      isPrimary: new FormControl(null),
      designationId: new FormControl('', Validators.required),
      email: new FormControl('', {
        validators: [Validators.required,
        // Validators.pattern(Patterns.email)
        Validators.email],
        asyncValidators: [DuplicateConatctsEmail(this.agencyProfileDataService, this.agencyId)],
      }),
      contactNumber: new FormControl('', [Validators.required, Validators.pattern('([ ]*[0-9+-]+[ ]*)*')]),
      contactPerson: new FormControl('', [Validators.required, Validators.pattern('.*[^ ].*')]),
      id: new FormControl(0)
    });
    return contactsGroup;
  }


  get mgAgency(): FormGroup {
    return <FormGroup>this.agencyForm.get('mgAgency');
  }

  get agencyContacts(): FormArray {
    return <FormArray>this.agencyForm.get('agencyContacts');
  }

  hasErrorsForAgencyGroup(controlName: string) {
    return (this.agencyGroup.get(controlName).dirty || this.agencyGroup.get(controlName).touched)
      && this.agencyGroup.get(controlName).errors !== null;
  }

  getValidationMessageForAgencyGroup(controlName: string) {
    if (this.agencyGroup.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.agencyGroup.get(controlName).hasError('duplicateAgencyCode')) {
      return ErrorMessages.duplicateAgencyCode;
    } else if (this.agencyGroup.get(controlName).hasError('pattern')) {
      if (controlName === 'legacyAgencyCode') {
        return ErrorMessages.alphaNumErrorMessage;
      } else if (controlName === 'website') {
        return ErrorMessages.invalidWebsiteMessage;
      } else if (controlName === 'zipcode') {
        return ErrorMessages.alphaNumErrorMessage;
      } else if (controlName === 'emailAddress') {
        return ErrorMessages.invalidEmailMessage;
      } else if (controlName === 'phoneNumber') {
        return ErrorMessages.invalidPhoneNumberMessage;
      } else if (controlName === 'faxNumber' || controlName === 'noOfBooking') {
        return ErrorMessages.numericErrorMessage;
      } else if (controlName === 'faxNumber' || controlName === 'noOfBooking' ||
        controlName === 'faxNumber') {
        return ErrorMessages.requiredFieldMessage;
      }
    } else if (this.agencyGroup.get(controlName).hasError('maxlength')) {
      if (controlName === 'website') {
        return ErrorMessages.maxlength50Message;
      } else if (controlName === 'addressLine1' || controlName === 'addressLine2') {
        return ErrorMessages.maxlength500Message;
      }
    } else if (this.agencyGroup.get(controlName).hasError('email')) {
      return ErrorMessages.invalidEmailMessage;
    } else {
      return '';
    }
  }

  hasErrorsForAgencyForm(controlName: string) {
    return (this.agencyForm.get(controlName).dirty || this.agencyForm.get(controlName).touched)
      && this.agencyForm.get(controlName).errors !== null;
  }

  getValidationMessageForAgencyForm(controlName: string) {
    if (this.agencyForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.agencyForm.get(controlName).hasError('duplicateEmail')) {
      if (this.agencyContacts !== null) {
        for (let i = 0; i < this.agencyContacts.length; i++) {
          if (controlName === ('agencyContacts.' + i + '.email')) {
            return ErrorMessages.duplicateEmailIdMessage;
          }
        }
      }
    } else if (this.agencyForm.get(controlName).hasError('pattern')) {
      if (this.agencyContacts !== null) {
        for (let i = 0; i < this.agencyContacts.length; i++) {
          if (controlName === ('agencyContacts.' + i + '.email')) {
            return ErrorMessages.invalidEmailMessage;
          } else if (controlName === ('agencyContacts.' + i + '.contactNumber')) {
            return ErrorMessages.invalidPhoneNumberMessage;
          }
        }
      }
      if (controlName === ('superUserAgent.email')) {
        return ErrorMessages.invalidEmailMessage;
      } else if (controlName === ('superUserAgent.contactNumber')) {
        return ErrorMessages.invalidPhoneNumberMessage;
      }
      if (controlName.search('^agencyContacts.[0-9]+.contactPerson$') === 0 || controlName === 'superUserAgent.firstName'
        || controlName === 'superUserAgent.lastName' || controlName === 'superUserAgent.userName') {
        return ErrorMessages.requiredFieldMessage;
      }
    } else if (this.agencyForm.get(controlName).hasError('duplicateUserName')) {
      return ErrorMessages.duplicateUserNameMessage;
    } else if (this.agencyForm.get(controlName).hasError('passwordLengthError')) {
      return ErrorMessages.passwordLengthErrorMessage;
    } else if (this.agencyForm.get(controlName).hasError('passwordNumberError')) {
      return ErrorMessages.passwordNumberErrorMessage;
    } else if (this.agencyForm.get(controlName).hasError('passwordCaseError')) {
      return ErrorMessages.passwordCaseErrorMessage;
    } else if (this.agencyForm.get(controlName).hasError('passwordNonAlphanumError')) {
      return ErrorMessages.passwordNonAlphanumErrorMessage;
    } else if (this.agencyForm.get(controlName).hasError('passwordMismatch')) {
      return ErrorMessages.passwordMismatchErrorMessage;
    } else if (this.agencyForm.get(controlName).hasError('email')) {
      return ErrorMessages.invalidEmailMessage;
    } else {
      return '';
    }
  }

  getCities(countryId) {
    const subscription = this.lookupService.getCities(countryId).subscribe(data => {
      this.cityList = data;
    });
    this.agencyGroup.get('cityId').setValue(null);
    this.subscriptions.push(subscription);
  }

  setDefaultCurrency() {
    const subscription = this.currencyList.pipe(map(currency => currency
      .filter(x => x.currencyId === this.agencyGroup.get('currencyId').value)))
      .subscribe(val => {
        this.agencyForm.get('defaultCurrency').setValue(val[0].currencyName);
      });

    this.subscriptions.push(subscription);
  }

  setIsPrimary(event, index) {
    this.agencyContacts.controls.forEach((val, i) => {
      if (index === i) {
        this.agencyContacts.controls[i].get('isPrimary').setValue(i);
      } else {
        this.agencyContacts.controls[i].get('isPrimary').setValue(null);
      }
    });
  }

  getDesignations() {
    const subscription = this.lookupService.getDesignationByType(CONSTANTS.userTypeName.agentuser).subscribe(data => {
      this.userDesignationist = data.result;
    });
    this.subscriptions.push(subscription);
  }

  confirmDeleteContact(index: number, length) {
    if (Utilities.isNullOrEmpty(this.agencyContacts.value[index].contactNumber) &&
      Utilities.isNullOrEmpty(this.agencyContacts.value[index].email) &&
      Utilities.isNullOrEmpty(this.agencyContacts.value[index].contactPerson) &&
      Utilities.isNullOrEmpty(this.agencyContacts.value[index].designationId)) {
      this.deleteContact(index, length);
    } else {
      let confirmationMessage = '';
      if (this.agencyContacts.value[index].isPrimary !== null) {
        confirmationMessage = ErrorMessages.primeryContactDeletionMessage;
      } else {
        confirmationMessage = ErrorMessages.contactDeletionConfirmationMessage;
      }
      const subscribtion = this.dialogsService
        .confirm('Confirm', confirmationMessage).subscribe(res => {
          this.result = res;
          if (this.result) {
            this.deleteContact(index, length);
            this.agencyForm.get('agencyContacts').markAsDirty();
            this.agencyForm.get('agencyContacts').markAsTouched();
            this.agencyForm.get('agencyContacts').updateValueAndValidity();
          } else {
            this.actions = null;
          }
        },
          (error: JarvisError) => console.error(`Error in deleting agency-conatct(index: number). ${error.friendlyMessage}`));
      this.subscriptions.push(subscribtion);
    }
  }

  deleteContact(index: number, length) {
    let primaryContactIndex;
    this.agencyContacts.controls.forEach((val, i) => {
      if (this.agencyContacts.controls[i].get('isPrimary').value === i) {
        primaryContactIndex = i;
      }
    });
    this.agencyContacts.removeAt(index);
    this.count = length - 2;
    if (index === primaryContactIndex) {
      this.agencyContacts.controls[0].get('isPrimary').setValue(0);
      this.cd.detectChanges();
    } else if (primaryContactIndex > index || primaryContactIndex >= this.agencyContacts.length) {
      primaryContactIndex = primaryContactIndex - 1;
      this.agencyContacts.controls[primaryContactIndex].get('isPrimary').setValue(primaryContactIndex);
      this.cd.detectChanges();
    }
  }

  addContact(length) {
    this.count = length;
    this.agencyContacts.push(this.buildContactsDetails());
    this.agencyForm.get('agencyContacts').updateValueAndValidity();
  }

  get superUserAgent(): FormGroup {
    return <FormGroup>this.agencyForm.get('superUserAgent');
  }

  buildSuperUserDetails(): FormGroup {
    let superUserGroup: FormGroup;
    superUserGroup = new FormGroup({
      designationId: new FormControl('', Validators.required),
      contactNumber: new FormControl('', [Validators.pattern('([ ]*[0-9+-]+[ ]*)*')]),
      firstName: new FormControl('', [Validators.required, Validators.pattern('.*[^ ].*')]),
      lastName: new FormControl('', [Validators.required, Validators.pattern('.*[^ ].*')]),
      // tslint:disable-next-line:max-line-length
      email: new FormControl(null, {
        validators: [ // Validators.pattern(Patterns.email)
          Validators.email],
        asyncValidators: [AgentUserEmailValidator(this.agencyProfileDataService)],
      }),
      userName: new FormControl(null, {
        validators: [Validators.required, Validators.pattern('.*[^ ].*')],
        asyncValidators: [AgentUserNameValidator(this.agencyProfileDataService, this.agencyId)],
      }),
      password: new FormControl('', {
        validators: [PasswordValidators.passwordCaseValidator, PasswordValidators.passwordLengthValidator,
        PasswordValidators.passwordNonAlphanumValidator, PasswordValidators.passwordNumberValidator, Validators.required]
      }),
      confirmPassword: new FormControl('', {
        validators: [MatchPasswordValidator],
      }),
    });
    return superUserGroup;
  }

  onPasswordChange() {
    this.superUserAgent.get('confirmPassword').markAsTouched();
    this.superUserAgent.get('confirmPassword').updateValueAndValidity();
  }

  onPhotoURLChange(updatedPhotoURL) {
    this.profilePictureUri = updatedPhotoURL;
    this.agencyForm.markAsDirty();
    this.agencyForm.markAsTouched();
  }

  getAgencyData(agencyId: number) {
    const subscription = this.agencyProfileDataService.getById(agencyId).subscribe(data => {
      this.agencyInfoViewModel = data[0];
      this.agencyProfileDataService.agencyName = this.agencyInfoViewModel.mgAgency.name;
      this.agencyGroup.get('faxNumber').setValue(this.agencyInfoViewModel.mgAgency.faxNumber);
      this.agencyGroup.get('addressLine2').setValue(this.agencyInfoViewModel.mgAgency.addressLine2);
      this.agencyGroup.get('addressLine1').setValue(this.agencyInfoViewModel.mgAgency.addressLine2);
      this.agencyGroup.get('countryId').setValue(this.agencyInfoViewModel.mgAgency.countryId);
      this.getCities(this.mgAgency.get('countryId').value);
      this.agencyGroup.get('cityId').setValue(this.agencyInfoViewModel.mgAgency.cityId);
      this.agencyGroup.get('currencyId').setValue(this.agencyInfoViewModel.mgAgency.currencyId);
      this.agencyGroup.get('agencyName').setValue(this.agencyInfoViewModel.mgAgency.name);
      this.agencyGroup.get('code').setValue(this.agencyInfoViewModel.mgAgency.code);
      this.agencyGroup.get('name').setValue(this.agencyInfoViewModel.mgAgency.name);
      this.agencyGroup.get('zipcode').setValue(this.agencyInfoViewModel.mgAgency.zipcode);
      this.agencyGroup.get('website').setValue(this.agencyInfoViewModel.mgAgency.website);
      this.agencyGroup.get('addressLine1').setValue(this.agencyInfoViewModel.mgAgency.addressLine1);
      this.agencyGroup.get('emailAddress').setValue(this.agencyInfoViewModel.mgAgency.emailAddress);
      this.agencyGroup.get('legacyAgencyCode').setValue(this.agencyInfoViewModel.mgAgency.legacyAgencyCode);
      this.agencyGroup.get('vatId').setValue(this.agencyInfoViewModel.mgAgency.vatId);
      this.agencyGroup.get('virtualAccountNumber').setValue(this.agencyInfoViewModel.mgAgency.virtualAccountNumber);
      this.agencyGroup.get('bankName').setValue(this.agencyInfoViewModel.virtualAccountConfigDetails.bankName);
     
      // this.agencyGroup.get('phoneNumber').setValue(this.agencyInfoViewModel.mgAgency.phoneNumber);
      if (!Utilities.isNullOrEmpty(this.agencyInfoViewModel.mgAgency.phoneNumber)) {
        this.agencyInfoViewModel.mgAgency.phoneNumber.split(';').forEach(email => {
          this.phoneNumberList.push(email);
        });
        this.mgAgency.get('phoneNumber').updateValueAndValidity();
        this.mgAgency.get('phoneNumber').markAsTouched();
      }

      if (this.agencyInfoViewModel.mgAgency.logoURL != null && this.agencyInfoViewModel.mgAgency.logoURL.trim() !== '') {
        // this.photoURL = this.agencyInfoViewModel.mgAgency.logoURL;
        this.photosDataService.getContentUrl().subscribe(response => {
          if (response !== null) {
            this.contentUrl = response;
          }
          this.photoURL = this.contentUrl + this.agencyInfoViewModel.mgAgency.logoURL;
        });
      }
      this.profilePictureUri = this.agencyInfoViewModel.mgAgency.logoURL;
      const agencyCode = this.agencyInfoViewModel.mgAgency.code;
      this.superUserAgent.get('firstName').setValue(this.agencyInfoViewModel.superUserAgent.firstName);
      this.superUserAgent.get('lastName').setValue(this.agencyInfoViewModel.superUserAgent.lastName);
      this.superUserAgent.get('email').setValue(this.agencyInfoViewModel.superUserAgent.email);
      this.superUserAgent.get('designationId').setValue(this.agencyInfoViewModel.superUserAgent.designationId);
      this.superUserAgent.get('contactNumber').setValue(this.agencyInfoViewModel.superUserAgent.contactNumber);
      this.superUserAgent.get('userName').setValue(
        this.agencyInfoViewModel.superUserAgent.userName.replace(agencyCode, '').replace('__', '').trim());
      this.superUserAgent.get('password').setValue(CONSTANTS.dummyPassword);
      this.agencyForm.get('agentId').setValue(this.agencyInfoViewModel.superUserAgent.id);

      
      if (this.agencyInfoViewModel.payment.isCashTopUp && this.agencyInfoViewModel.mgAgency.virtualAccountNumber) {
        this.isVirtualAccount = true;
      }

      const contacts = this.agencyInfoViewModel.agencyContacts;
      if (contacts !== null) {
        for (let i = 1; i < contacts.length; i++) {
          this.addContact(contacts.length);
        }
        this.agencyContacts.controls.forEach((contact, index) => {
          contact.get('id').setValue(contacts[index].id);
          if (contacts[index].isPrimary === true) {
            contact.get('isPrimary').setValue(index);
          } else {
            contact.get('isPrimary').setValue(null);
          }
          contact.get('contactNumber').setValue(contacts[index].contactNumber);
          contact.get('contactPerson').setValue(contacts[index].contactPerson);
          contact.get('email').setValue(contacts[index].email);
          contact.get('designationId').setValue(contacts[index].designationId);
        });

        if (this.agencyInfoViewModel.payment.isCashTopUp) {
          this.iscashTopup = true;
        }
      }

      this.count = this.agencyContacts.length - 1;

      // this.currencyList.pipe(map(currency => currency
      //   .filter(x => x.currencyId === this.mgAgency.get('currencyId').value)))
      //   .subscribe(val => {
      //     this.agencyForm.get('defaultCurrency').setValue(val[0].currencyName);
      //   });
    },
      (error: JarvisError) => console.error(`Error in Getting AgencyData. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  saveAgency() {
    this.markFormDirtyService.markGroupDirty(this.agencyForm);
    if(this.agencyForm.valid && this.agencyForm.dirty) {
      const agency = Object.assign({}, this.agencyInfoViewModel, this.agencyForm.value);
    // tslint:disable-next-line:max-line-length
    agency.superUserAgent.password = this.agencyForm.get('superUserAgent.password').value === CONSTANTS.dummyPassword ? '' : this.agencyForm.get('superUserAgent.password').value;

    agency.mgAgency.logoURL = this.profilePictureUri;
    const updatedBy = this.userProfileService.getBasicUserInfo().id;

    agency.mgAgency.logoURL = this.profilePictureUri;

    // const createdBy = this.userProfileService.getBasicUserInfo().id;

    agency.mgAgency.updatedBy = agency.payment.updatedBy = updatedBy;
    agency.superUserAgent.updatedBy = updatedBy;


    agency.mgAgency.languageId = agency.payment.languageId = 0;
    agency.superUserAgent.languageId = 0;
    agency.superUserAgent.userName = this.agencyInfoViewModel.mgAgency.code + '__' + agency.superUserAgent.userName.trim();

    this.agencyContacts.controls.forEach((contact, i = 0) => {
      contact.value.updatedBy = updatedBy;
      contact.value.languageId = 0;
      if (contact.value.isPrimary !== null) {
        contact.value.isPrimary = true;
      } else {
        contact.value.isPrimary = false;
      }
      contact.value.isActive = true;
      contact.value.agencyId = this.agencyInfoViewModel.mgAgency.id;
      contact.value.createdBy = this.agencyInfoViewModel.mgAgency.createdBy;
    });

    agency.mgAgency.createdBy = agency.payment.createdBy = this.agencyInfoViewModel.mgAgency.createdBy;
    agency.mgAgency.id = agency.payment.agencyId = this.agencyInfoViewModel.mgAgency.id;

    if (agency.superUserAgent !== null) {
      agency.superUserAgent.createdBy = agency.mgAgency.updatedBy;
      agency.superUserAgent.id = this.agencyInfoViewModel.superUserAgent.id;
      agency.superUserAgent.b2BRoleId = this.agencyInfoViewModel.superUserAgent.b2BRoleId;
      agency.superUserAgent.branchId = agency.branch[0].mgBranch.id;
      agency.superUserAgent.agencyId = agency.mgAgency.id;
    }
    if (this.phoneNumberList.length > 0) {
      agency.mgAgency.phoneNumber = '';
      this.phoneNumberList.forEach(element => {
        agency.mgAgency.phoneNumber += ';' + element;
      });
      agency.mgAgency.phoneNumber = agency.mgAgency.phoneNumber.substr(1);
    }
    agency.mgAgency.setToUnlimited = this.agencyInfoViewModel.mgAgency.setToUnlimited;
    agency.mgAgency.isB2BUser = this.agencyInfoViewModel.mgAgency.isB2BUser;
    agency.mgAgency.isB2B = this.agencyInfoViewModel.mgAgency.isB2B;
    agency.mgAgency.isB2C = this.agencyInfoViewModel.mgAgency.isB2C;
    agency.mgAgency.isAccessLiveRequest = this.agencyInfoViewModel.mgAgency.isAccessLiveRequest;
    agency.mgAgency.isAccessCache = this.agencyInfoViewModel.mgAgency.isAccessCache;
    agency.mgAgency.throttlingLimit = this.agencyInfoViewModel.mgAgency.throttlingLimit;
    agency.mgAgency.useOldCode = this.agencyInfoViewModel.mgAgency.useOldCode;
    agency.mgAgency.reCheckCorrectionFactory = this.agencyInfoViewModel.mgAgency.reCheckCorrectionFactory;
    agency.mgAgency.cancellationPolicyBufferDays = this.agencyInfoViewModel.mgAgency.cancellationPolicyBufferDays;
    agency.mgAgency.marketId = this.agencyInfoViewModel.mgAgency.marketId;
    agency.mgAgency.minCheckInDays = this.agencyInfoViewModel.mgAgency.minCheckInDays;
    agency.mgAgency.generateCustomerDocuments = this.agencyInfoViewModel.mgAgency.generateCustomerDocuments;
    agency.mgAgency.xmlPassword = this.agencyInfoViewModel.mgAgency.xmlPassword;
    agency.mgAgency.xmlUserName = this.agencyInfoViewModel.mgAgency.xmlUserName;
    agency.mgAgency.internalRemark = this.agencyInfoViewModel.mgAgency.internalRemark;
    agency.mgAgency.countryId = this.agencyInfoViewModel.mgAgency.countryId;
    agency.mgAgency.cityId = this.agencyInfoViewModel.mgAgency.cityId;
    agency.mgAgency.currencyId = this.agencyInfoViewModel.mgAgency.currencyId;
    agency.mgAgency.nameItemId = this.agencyInfoViewModel.mgAgency.nameItemId;
    agency.mgAgency.isActive = this.agencyInfoViewModel.mgAgency.isActive;
    agency.mgAgency.isDeleted = this.agencyInfoViewModel.mgAgency.isDeleted;
    agency.mgAgency.hasDynamicAvailabilityAccess = this.agencyInfoViewModel.mgAgency.hasDynamicAvailabilityAccess;
    agency.mgAgency.hasStaticAvailabilityAccess = this.agencyInfoViewModel.mgAgency.hasStaticAvailabilityAccess;
    agency.mgAgency.code = this.agencyInfoViewModel.mgAgency.code;
    agency.mgAgency.addressLine1 = this.agencyInfoViewModel.mgAgency.addressLine1;
    agency.mgAgency.addressLine2 = this.agencyInfoViewModel.mgAgency.addressLine2;
    agency.mgAgency.zipcode = this.agencyInfoViewModel.mgAgency.zipcode;
    agency.mgAgency.remark = this.agencyInfoViewModel.mgAgency.remark;
    agency.mgAgency.legacyAgencyCode = this.agencyInfoViewModel.mgAgency.legacyAgencyCode;
    agency.mgAgency.xmlEmailAddress = this.agencyInfoViewModel.mgAgency.xmlEmailAddress;
    agency.superUserAgent.isActive = this.agencyInfoViewModel.superUserAgent.isActive;

    const subscription = this.agencyProfileDataService.updateAgencyDetails(agency as AgencyInfoViewModel)
      .subscribe(data => {
        if (data.isError === false) {
          this.snackBar.open('The Agency has been updated successfully', '', {
            duration: CONSTANTS.toasterTimeOut.duration,
            verticalPosition: 'top', panelClass: 'showSnackBar'
          });
          this.router.navigate(['/agencymgmt/agency'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
        } else {
          // TODO: Need to check how we are doing error handling.
          this.snackBar.open('Error occourred while updating Agency. ' + data.errorMessages,
            '', { duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top', panelClass: 'showSnackBar' });
          this.router.navigate(['/agencymgmt/agency'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
        }
      },
        (error: JarvisError) => {
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          dialogRef.componentInstance.message = `Error Code ${ErrorCodes.UpdateAgencyDetails}. ${error.friendlyMessage}`;
          dialogRef.componentInstance.buttonText = 'OK';
        });
    this.subscriptions.push(subscription);
    }
  }
  addPhoneNumber(e: MatChipInputEvent) {
    const input = e.input;
    const value = e.value;
    if (value === '') {
      this.mgAgency.controls['phoneNumber'].updateValueAndValidity();
    } else {
      // tslint:disable-next-line:max-line-length
      if (!this.mgAgency.get('phoneNumber').hasError('pattern') && !this.mgAgency.get('phoneNumber').hasError('maxlength') && value !== '') {
        this.phoneNumberList.push(value);
        if (input) {
          input.value = '';
        }
        // this.flagHotelDetailsAsEdited();
        this.mgAgency.controls['phoneNumber'].updateValueAndValidity();
      }
    }
  }

  removePhoneNumber(index) {
    this.mgAgency.get('phoneNumber').markAsDirty();
    this.phoneNumberList.splice(index, 1);
    if (this.phoneNumberList.length === 0) {
      this.mgAgency.get('phoneNumber').setValue('');
    }
  }
  cancel() {
    let confirmCancelSubscription = null;
    confirmCancelSubscription = Utilities.confirmCancel(this.agencyForm.dirty, this.cancelPopupService, this.router,
      ['/agencymgmt/agency'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0),
      null, CustomConfirmMessages.confirmMessage,
      CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      // confirmCancelSubscription.confirm('Confirm', CustomConfirmMessages.confirmMessage,
      // CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
      this.subscriptions.push(confirmCancelSubscription);
      this.confirmCancel = true;
    }
  }

  save() {
    if(this.agencyForm.valid && this.agencyForm.dirty) {
      this.confirmCancel = true;
    }
  }
  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.agencyForm.dirty && !this.confirmCancel) {
      // return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
      return this.dialogsService.confirm('Confirm', CustomConfirmMessages.confirmMessage, true,
        CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    } else {
      return of(true);
    }
    // return true;
  }

  ngOnDestroy() {
    if (!Utilities.isNullOrEmpty(this.subscriptions)) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
}
