import { ChangePasswordViewModel } from '../../../common/viewmodels/changepasswordviewmodel';
import { RequestCreditNotification } from 'src/app/common/viewmodels/notification/requestcreditnotificationsviewmodel';
import { UserProfileViewModel } from 'src/app/common/viewmodels/userprofileviewmodel';


export abstract class UserAccountApi {
  abstract changePassword(newPasswordDetails: ChangePasswordViewModel);
  abstract verifyCurrentPassword(userName: string, password: string);
  abstract notifyAgencyRequst(notifyAgency: RequestCreditNotification);
  abstract updateUserProfile(userProfile: UserProfileViewModel);
}
