import { Component, Input, ElementRef, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-read-text',
    templateUrl: './read-more-view.component.html',
    styleUrls: ['./read-more-view.component.css']
})
export class ReadMoreViewComponent implements OnChanges {
    @Input() text: string;
    @Input() maxLength = 100;
    @Input() contentLength: number;
    currentText: string;
    hideToggle = true;

    isCollapsed = true;

    constructor(private elementRef: ElementRef) {

    }
    toggleView() {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }
    determineView() {
        if (!this.text || this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = false;
            this.hideToggle = true;
            return;
        }
        this.hideToggle = false;
        if (this.isCollapsed === true) {
            this.currentText = this.text.substring(0, this.maxLength) + '...';
        } else if (this.isCollapsed === false) {
            this.currentText = this.text;
        }

    }
    ngOnChanges() {
        this.determineView();
    }
}
