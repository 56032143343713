import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {

  title: string;
  message: string;
  viewConflicts: string;
  messages: any[];
  viewButtonText: string;
  cancelButtonText: string;
  discardButtonText: string;
  isDisacardDisabled: boolean;
  constructor(public dialogRef: MatDialogRef<ErrorMessageComponent>) {}

  ngOnInit() {
  }

}
