import { Component, OnInit } from "@angular/core";
import { UserProfileService } from "../../../common/shared/services/user-profile.service";
import { AuthService } from "src/app/common/shared/services/auth.service";

@Component({
    selector: 'app-bo-home',
    templateUrl: './bo-home.component.html'
})
export class BoHomeComponent implements OnInit {
    isUserProfileLoadedSuccess = false;
    constructor(private userProfileService: UserProfileService, private authService: AuthService) {
    }

    ngOnInit() {
        this.authService.removeUser().then((data) => {
            return this.userProfileService.getUserProfile();
        });
    }
}
