/**
 * Includes the services used to perform CRUD
 * operation on reservation/booking
 */
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticatedHttpService } from '../../../../common/shared/services/authenticated-http.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';

import { ReservationListViewModel } from './../../../viewmodels/reservation/reservation-list/reservationlistviewmodel';
import { SearchReservationViewModel } from '../../../viewmodels/reservation/reservation-list/searchreservationviewmodel';
import { ReservationDetailsViewModel } from 'src/app/b2b/viewmodels/reservation/reservation-details/reservationdetailsviewmodel';
import {
  ReservationDetailsRequestViewModel
} from 'src/app/b2b/viewmodels/reservation/reservation-details/reservationdetailsrequestviewmodel';
import { FullBookingResponseDetailsViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-response-viewmodel';
import { InvoiceAndVouchers } from '../../../viewmodels/reservation/invoice-voucher/invoiceandvouchers';
import { EmailPdfRequestViewModel } from '../../../viewmodels/reservation/invoice-voucher/emailpdfrequestviewmodel';
import { ReservationType } from '../../../../common/enum';
import { OnHoldBookingTimerRequestViewModel } from '../../../viewmodels/reservation/reservation-details/onholdbookingtimerrequestviewmodel';
import { HoldBookingConfirmationResponse } from '../../../viewmodels/reservation/reservation-details/holdbookingconfirmationresponse';
import { ErrorMessageViewModel } from '../../../viewmodels/errorMessageViewModel';
import { Utilities } from 'src/app/common/utilities/utilities';

@Injectable()
export class ReservationDataService {

  _documentId: string;
  private _reservationBookingId: string;

  get reservationBookingId(): string {
    return this._reservationBookingId;
  }

  set reservationBookingId(bookingId: string) {
    this._reservationBookingId = bookingId;
  }

  get documentId(): string {
    return this._documentId;
  }

  set documentId(documentId: string) {
    this._documentId = documentId;
  }

  onHotelCodeValueChange: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService,
  ) {}
  public AgencyRefNumber: string;

  getReservations(searchReservation: any): Observable<ReservationListViewModel[]> {
    let reservationRequest: any;
    const from: Date = searchReservation.fromDate;
    const to: Date = searchReservation.toDate;
    reservationRequest = JSON.parse(JSON.stringify(searchReservation)); // deep copy
    reservationRequest.fromDate = Utilities.isNullOrEmpty(reservationRequest.fromDate) ? null : from.toLocaleDateString('en-US');
    reservationRequest.toDate = Utilities.isNullOrEmpty(reservationRequest.toDate) ? null : to.toLocaleDateString('en-US');
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , reservationRequest, 'api/Reservation/GetReservationListByAgency').pipe(map((data: any) => {
        let reservationDto: ReservationListViewModel[] = [];
        if (data !== null) {
          reservationDto = data.result;
        } else {
          reservationDto = null;
        }
        return reservationDto;
      }));
  }

  getBookingStatus(applicationId): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/BookingStatus/GetByApplication?applicationId=' + applicationId).pipe(map((data: any) => {
        let bookingStatusDto = [];
        if (data !== null) {
          bookingStatusDto = data.result.filter(x => x.isDeleted === false);
        } else {
          bookingStatusDto = null;
        }
        return bookingStatusDto;
      }));
  }

  getReservationDetails(bookingId: string, documentId: string, agencyId: string): Observable<ReservationDetailsViewModel> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi
      , 'api/Reservation/GetReservationDetailsForBackOffice?bookingId=' + bookingId + '&documentId=' + documentId + '&agencyId=' + agencyId)
      .pipe(map(data => {
        let reservationDetailsForBackOfficeDto: ReservationDetailsViewModel;
        if (data !== null) {
          reservationDetailsForBackOfficeDto = data.result;
        }
        return reservationDetailsForBackOfficeDto;
      }));
  }

  // TODO: need to refactor the code, update API URL
  completeReservation() {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi
      , 'api/Reservation/GetReservationDetailsForBackOffice?bookingId=')
      .pipe(map(data => {
        let reservationDetailsForBackOfficeDto: ReservationDetailsViewModel;
        if (data !== null) {
          reservationDetailsForBackOfficeDto = data.result;
        }
        // return reservationDetailsForBackOfficeDto;
        return true;
      }));
  }

  getReservationDetailsForB2B(reservationDetailsRequest: ReservationDetailsRequestViewModel):
    Observable<FullBookingResponseDetailsViewmodel> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, reservationDetailsRequest
      , 'api/Reservation/GetReservationDetailsForB2B')
      .pipe(map((data: any) => {
        const xmlErrorMessage: ErrorMessageViewModel = new ErrorMessageViewModel();
        let reservationDetailsForBackOfficeDto: FullBookingResponseDetailsViewmodel = <FullBookingResponseDetailsViewmodel>{};
        if (data !== null) {
          if (data.result !== null) {
            reservationDetailsForBackOfficeDto = data.result;
            return reservationDetailsForBackOfficeDto;
            } else {
              xmlErrorMessage.message = data.message;
              xmlErrorMessage.xmlErrorCode = data.xmlErrorCode;
              xmlErrorMessage.errorCode = data.errorCode;
              reservationDetailsForBackOfficeDto.errorModel = xmlErrorMessage;
              return reservationDetailsForBackOfficeDto;
           }
        } else { return null; }
      }));
  }

  getInvoiceAndVouchers(bookingId: string, documentId: string): Observable<InvoiceAndVouchers[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi
      , 'api/Reservation/GetInvoiceAndVouchersForB2B?bookingId=' + bookingId + '&documentId=' + documentId).pipe(map(data => {
        let invoiceAndVouchersDto: InvoiceAndVouchers[] = [];
        if (data !== null) {
          invoiceAndVouchersDto = data.result;
        }
        return invoiceAndVouchersDto;
      }));
  }

  sendEmail(request: EmailPdfRequestViewModel): Observable<boolean> {
    let isMailSent: boolean;
    if (request.reservationType === ReservationType.New) {
      return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
        , request, 'api/Reservation/SendReservationConfirmationEmail').pipe(map((data: any) => {
          if (data !== null) {
            isMailSent = data.result;
          }
          return isMailSent;
        }));
    } else {
      return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
        , request, 'api/Reservation/SendReservationCancelNotification').pipe(map((data: any) => {
          if (data !== null) {
            isMailSent = data.result;
          }
          return isMailSent;
        }));
    }
  }

  generatePDF(emailPdfRequest): Observable<any[]> {
    return this.authenticatedHttpService.postBlobResponse(this.configurationService.config.baseUrls.bookingApi + 'GetFileResult/'
      , emailPdfRequest, 'api/Reservation/GenerateReservationDocument').pipe(map((PDFdata: any) => {
        if (PDFdata !== null) {
          return PDFdata;
        }
      }));
  }

  getTimerForHoldBooking(onHoldBookingRequest: OnHoldBookingTimerRequestViewModel): Observable<HoldBookingConfirmationResponse> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi, onHoldBookingRequest,
      'api/Booking/GetTimerForHoldBookings').pipe(map((data: any) => {
        return data.result;
      }));
  }

  getBookingSourceList(): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/BookingSource/Get?languageId=0' ).pipe(map((data: any) => {
        return data ? data['result'] : [];
      }));
  }

  getBranchList(agencyId, text): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/GetByName?agencyId=' + agencyId + '&searchText=' + text ).pipe(map((data: any) => {
        return data ? data['result'] : [];
      }));
  }

  getBranchByUserId(userId: string): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Agent/GetById?userId=' + userId).pipe(map((data: any) => {
        return data || {};
      }));
  }


  // getAgentList(agencyId, text): Observable<any> {
  //   return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
  //     , 'api/Agent/GetByName?searchText=' + text + '&agencyId=' + agencyId).pipe(map((data: any) => {
  //       return data ? data : [];
  //     }));
  // }

  getAgentListByBranch(branchId, agencyCode, text): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi
      , 'api/Reservation/GetAgentNames?searchText=' + text + '&branchId=' + branchId + '&agencyCode=' + agencyCode).pipe(map((data: any) => {
        return data ? data['result'] : [];
      }));
  }
  

  hotelCodeChange(hotelCode: string): void {
    this.onHotelCodeValueChange.next(hotelCode);
  }

  uniqueTRNCheck(bookingId: string, trn: string) {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi
      , `api/Reservation/UniqueTRNCheck?bookingId=${bookingId}&trn=${trn}`).pipe(map(
        data => {
          return data.result;
        }));
  }

}
