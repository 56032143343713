import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigurationService } from './services/configuration.service';
import { UserAccountApi } from '../../framework/fw/users/user-account-api';
import { UserAccountService } from './services/user-account.service';
import { UserAlertApi } from '../../framework/fw/users/user-alert-api';
import { AlertDataService } from './services/alert-data.service';
import { CurrencyApiService } from './services/currency-api.service';
import { CurrencyApi } from '../../framework/fw/currency/currency-api';
import { AlertListComponent } from './alerts/alert-list/alert-list.component';
import { AlertDataResolverService } from './services/alertdata-resolver.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,

  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  declarations: [AlertListComponent],
  providers: [
    {
      // Here we request that configuration loading be done at app-
      // initialization time (prior to rendering)
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService) =>
        () => configService.loadConfigurationData(),
      deps: [ConfigurationService],
      multi: true
    },
    { provide: UserAccountApi, useExisting: UserAccountService },
    { provide: UserAlertApi, useExisting: AlertDataService },
    { provide: CurrencyApi, useExisting: CurrencyApiService },
    AlertDataResolverService, DatePipe
  ]
})
export class SharedModule {
  // Preventing importing this modules more than once
  // https://angular.io/guide/ngmodule-faq
  constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
    if (parentModule) {
      throw new Error(
        'SharedModule is already loaded. Import it in the AppModule only');
    }
  }
}
