import { ChildrenAgeViewModel } from '../../common/b2b-shared/viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';

export class HotelViewModel {
    hotelId: string;
    hotelName: string;
    hotelDescription: string;
    address: string;
    mgPoints?: number;
    mgPointValidity: string;
    isMgPreferred?: boolean;
    longitude: number;
    latitude: number;
    starRating?: string;
    areaId?: number;
    hotelTypeId?: number;
    landmarkId?: number;
    chainId?: number;
    grossPrice?: number;
    // message: string;
    //  images?: ImagesViewModel[];
    // images?: string[];
    message: string;
    images?: string;
    facilities?: FacilitiesViewModel[];
    roomCategoryInfo?:  RoomCategoryInfoViewModel[];
    currentImageIndex: number;
    isExpanded: boolean;
}

export class ImagesViewModel {
    isPrimary?: boolean;
    imagePath: string;
}
export class FacilitiesViewModel {
    code: string;
    name: string;
    imagePath?: string;
}

export class HotelImagesViewModel {
    isMainPhoto?: boolean;
    filePath: string;
}

export class RoomCategoryInfoViewModel {
    roomCode: string;
    roomName: string;
    isMapped: boolean;
    // messages: string[];
    roomDetails: RoomDetailViewModel[];
}

export class RoomDetailViewModel {
    currencyCode: string;
    promotions: PromotionsViewModel[];
    mealType: string;
    cancellationPolicyId: number;
    cancellationPolicies: string[];
    cancellationPolicyType: string;
    grossPrice: number;
    priceDetails: PriceDetailsViewModel[];
    isAvailable: boolean;
    mealCode: string;
    packageRate: any;
    isMGPreferred: boolean;
    mgno: string;
    supplierName: string;
    canHold: boolean;
    messages: string[];
    isMGPreferredSupplier: boolean;
    mgPoints: number;
}

export class PromotionsViewModel {
    promotionCode: string;
    promotionType: string;
}
export class PriceDetailsViewModel {
    roomCode: string;
    roomNo: number;
    nights: number;
    guestDetails: string;
    noOfAdults: number;
    noOfChild: number;
    childAges: ChildrenAgeViewModel[];
    currencyCode: string;
    avgPricePerNight: number;
    grossPrice: number;
    rateKey?: string;
    supplierCode: string;
}
