import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// tslint:disable-next-line:max-line-length
import { CountriesResolverService, SalutationsResolverService, StarRatingResolverService, CurrencyResolverService, NationalityResolverService } from './services/lookup-resolver.service';
import { LookupService } from './services/lookup.service';
import { HomeService } from './services/home.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/common/material/material.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { ComponentsModule } from './components/components.module';
import { PhotosComponent } from './dialogs/photos/photos.component';
import { TooltipDirective } from './directive/tooltip.directive';
import { ReportFilterService } from './services/reports/report-filter.service';
import { InvalidControlScrollDirective } from './directive/invalid-control-scroll.directive';
// import { AllowDecimalNumberDirective } from './directive/allow-decimal-number/allow-decimal-number.directive';
// ReportFilterService
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DialogsModule
  ],
  declarations: [TooltipDirective, InvalidControlScrollDirective],
  providers: [
    LookupService,
    HomeService,
    CountriesResolverService,
    StarRatingResolverService,
    SalutationsResolverService,
    CurrencyResolverService,
    NationalityResolverService,
    ReportFilterService
  ],
  exports: [ComponentsModule, PhotosComponent, TooltipDirective, InvalidControlScrollDirective]
})
export class B2bSharedModule { }
