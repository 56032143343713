export class HotelFilterViewModel {
    hotelName: string;
    fromPrice: number;
    toPrice: number;
    isMgPreferred?: boolean;
    isFreeCancellation?: boolean;
    isOpenDateHotelVoucher?: boolean;
    starRatings?: StarRatingViewModel[];
    starRatingsHotelCount?: StarRatingsHotelCountModel[];
    promotions: string[];
    areas?: AreaViewModel[];
    hotelTypes?: HotelTypeViewModel[];
    areasHotelCount?: AreasHotelCountModel[];
    chains?: ChainViewModel[];
    // Brand is not added here since we cant get relation for chain and then apply filter
    // If Brand needs to be there it needs to be independent of Chain
    landmarks?: Landmark[];
    facilities?: FacilityViewModel[];
}

export class StarRatingsHotelCountModel {
    starRating: number;
    hotelCount: number;
}

export class AreasHotelCountModel {
    areaId: number;
    hotelCount: number;
}

export class AreaViewModel {
    areaId: number;
    area: string;
    count: string;
}

export class HotelTypeViewModel {
    hotelTypeId: number;
    hotelType: string;
    count: string;
}

export class StarRatingViewModel {
    rating: number;
    count: number;
}

export class ChainViewModel {
    chainId: number;
    chain: string;
}

export class Landmark {
    landmarkId: number;
    landmark: string;
}

export class FacilityViewModel {
    /* facilityId: number;
    facility: string; */
    code: string;
    name: string;
    imagePath?: string;
}

