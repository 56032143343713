import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelInfoComponent } from './hotel-info/hotel-info.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/common/material/material.module';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { CarouselModule } from 'primeng/carousel';
import { CarouselModule as NgxBootstrapCarouselModule } from 'ngx-bootstrap/carousel';
import { SliderModule } from 'primeng/slider';
import { ComponentsModule } from '../common/b2b-shared/components/components.module';
import { HotelListComponent } from './hotel-list/hotel-list.component';
import { HotelFilterComponent } from './hotel-filter/hotel-filter.component';
import { GuestDetailsComponent } from './guest-details/guest-details.component';
import { BookingSummaryNavComponent } from './booking-summary-nav/booking-summary-nav.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { GalleriaModule } from 'primeng/galleria';
import { RoomInfoDialogComponent } from './room-info-dialog/room-info-dialog.component';

import {
  HotelListViewComponent,
  RecheckRateDialogComponent
} from './hotel-list-view/hotel-list-view.component';
import { HotelMapViewComponent } from './hotel-map-view/hotel-map-view.component';
import { HotelDetailsComponent } from './hotel-details/hotel-details.component';
import { PaginatorModule } from 'primeng/paginator';
import { MapsdialogComponent } from '../common/b2b-shared/dialogs/mapsdialog/mapsdialog.component';
import {
  MessageDialogComponent
} from '../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { RoomInfoDataService } from '../common/b2b-shared/services/room-info-data.service';
import { ReservationDataService } from '../common/b2b-shared/services/reservation-data.service';
import { BookingCompleteInformationComponent,
  BookingHoldConfirmDialog } from './booking-complete-information/booking-complete-information.component';
import {
  BookingCancelPreviewDialog,
  BookingCancelConfirmDialog
} from './booking-complete-information/BookingCancelPreviewDialog';
import { BookingCancelInfoComponent } from './booking-cancel-info/booking-cancel-info.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { B2bSharedModule } from '../common/b2b-shared/b2b-shared.module';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GalleriaModule,
    MaterialModule,
    RouterModule,
    CarouselModule,
    NgxBootstrapCarouselModule,
    CalendarModule,
    ComponentsModule,
    SliderModule,
    ProgressBarModule,
    PaginatorModule,
    NgbDatepickerModule,
    B2bSharedModule,
    NgxUsefulSwiperModule
  ],
  declarations: [HotelInfoComponent, HotelListComponent, HotelFilterComponent, GuestDetailsComponent,
    BookingSummaryNavComponent, HotelListViewComponent, HotelMapViewComponent, HotelDetailsComponent,
    RoomInfoDialogComponent, MapsdialogComponent, MessageDialogComponent, BookingCompleteInformationComponent,
    RecheckRateDialogComponent,
    BookingCancelInfoComponent, BookingCancelPreviewDialog, BookingCancelConfirmDialog, BookingHoldConfirmDialog],
  providers: [RoomInfoDataService, ReservationDataService],
  entryComponents: [RoomInfoDialogComponent, MapsdialogComponent, MessageDialogComponent, RecheckRateDialogComponent,
    BookingCancelPreviewDialog, BookingCancelConfirmDialog, BookingHoldConfirmDialog]
})
export class HotelMgmtModule { }
