import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CurrencyApi } from '../currency/currency-api';
import { AgencyProfileDataService } from 'src/app/b2b/common/b2b-shared/services/agency-profile-data.service';
import { JarvisError } from 'src/app/common/jarviserror';

export interface ITopBarItem {
  firstName: string;
  lastName: string;
  profilePictureUri: string;
  activeRole?: string;
  activeCurrency?: string;
  role?:string;
}
export class TopBarItemViewModel implements ITopBarItem {
  constructor() { }
  firstName: string;
  lastName: string;
  profilePictureUri: string;
  profileLink?: string;
  activeRole?: string;
  activeCurrency?: string;
  isCredit?:boolean;
  role?:string;

}
export interface ITopBarService {
  setTopBarItem(item: ITopBarItem);
  getTopBarItem(): ITopBarItem;
}

@Injectable({
  providedIn: 'root'
})
export class TopBarService implements ITopBarService {
  constructor(private currencyApi: CurrencyApi, private agencyProfileDataService: AgencyProfileDataService) {
  }
  private currencyChangeSource = new Subject<null>();
  currencyChange$ = this.currencyChangeSource.asObservable();

  _item: ITopBarItem;
  _exchangeRate: number;
  subscription: Subscription;

  getTopBarItem(): ITopBarItem {
    return this._item;
  }

  setTopBarItem(item: TopBarItemViewModel) {
    this._item = item;
  }

  onCurrencyChange($event): void {
    this.currencyChangeSource.next($event);
    let targetCurrency = this.currencyApi.selectedCurrency;
    this.getCurrencyExchangeRateForMGF(targetCurrency);
  }

  getCurrencyExchangeRateForMGF(targetCurrency) {
    let sourceCurrency = "IDR";
    if (targetCurrency !== sourceCurrency && sourceCurrency && targetCurrency) {
      this.subscription = this.agencyProfileDataService.getCurrencyExchangeRate(sourceCurrency, targetCurrency).subscribe(data => {
        this._exchangeRate = data.result;
        localStorage.setItem('exchangeRate', ''+this._exchangeRate);
      },
        (error: JarvisError) => console.error(`Error in Getting Currency Exchange Rate. ${error.friendlyMessage}`));
    } else if (targetCurrency === sourceCurrency) {
      this._exchangeRate = 1;
      localStorage.setItem('exchangeRate', ''+this._exchangeRate);
    }
  }
}
