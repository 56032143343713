import { HotelFilterViewModel } from './hotelfilterviewmodel';
import { HotelViewModel } from './hotelviewmodel';
import { ErrorMessageViewModel } from '../errorMessageViewModel';

export class HotelListAndFilterViewModel {
    hotelFilter: HotelFilterViewModel;
    hotelList: HotelViewModel[];
    sessionId?: string;
    errorModel?: ErrorMessageViewModel;
}
