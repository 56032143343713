import { Subscription } from 'rxjs';
import { JarvisError } from './../../../common/jarviserror';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CancelBookingRequestViewmodel } from '../../viewmodels/hotel-mgmt/cancel-booking-request-viewmodel';
import { FullBookingResponseDetailsViewmodel } from '../../viewmodels/hotel-mgmt/booking-response-viewmodel';
import { BookingRequestViewmodel } from '../../viewmodels/hotel-mgmt/booking-request-viewmodel';
import { DatePipe } from '@angular/common';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { HotelBookService } from '../../common/b2b-shared/services/hotel-book.service';
import { CancelBookingResponseViewmodel } from '../../viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';
import { CONSTANTS } from 'src/app/common/constants';
import { MessageDialogComponent } from '../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { ErrorCodes } from 'src/app/common/errorcodes';
import { Utilities } from 'src/app/common/utilities/utilities';
import { errorConstants } from 'src/app/common/friendlymessage';
import { ScreenService } from './../../../framework/fw/services/screen.service';

@Component({
  selector: 'app-booking-cancel-preview-dialog',
  templateUrl: 'booking-cancel-dialog-preview.html',
})
// tslint:disable-next-line:component-class-suffix
export class BookingCancelPreviewDialog implements OnInit {
  bookingResponseDetail: FullBookingResponseDetailsViewmodel;
  bookingRequestDetails: BookingRequestViewmodel;
  isPreview: boolean;
  cancelBookingData: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
  xmlError = errorConstants.cancelReservationErrorMessage;
  errorCode: string;
  errorMessage: string;
  paymentBy = CONSTANTS.paymentBy;
  isDataLoaded: boolean;
  subscriptions: Subscription[] = [];

  constructor(private datePipe: DatePipe, private hotelDataService: HotelDataService,
    private hotelBookService: HotelBookService, public dialogRef: MatDialogRef<BookingCancelPreviewDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data, public dialog: MatDialog, private router: Router, public activatedRoute: ActivatedRoute, public screenService: ScreenService) { }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.isDataLoaded = true;
    this.bookingResponseDetail = this.hotelBookService.fullBookingResponseDetails;
    this.bookingRequestDetails = this.hotelBookService.bookingRequestDetails;
  }
  cancelConfirmBooking() {
    this.isDataLoaded = false;
    const cancelBookingRequestViewmodel = new CancelBookingRequestViewmodel();
    cancelBookingRequestViewmodel.AgencyBookingId = this.bookingRequestDetails.agencyBookingId;
    cancelBookingRequestViewmodel.CancelDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    cancelBookingRequestViewmodel.MGBookingId = this.bookingResponseDetail.bookingDetails.mgBookingID;
    cancelBookingRequestViewmodel.SimulationFlag = false;
    const subscription = this.hotelDataService.cancelBooking(cancelBookingRequestViewmodel).subscribe((data) => {
      this.dialogRef.close(true);
      if (data === null) {
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        dialogRef.componentInstance.message = `Please try again .If the problem persists ,Kindly contact support MG Team`;
        dialogRef.componentInstance.buttonText = 'OK';
      } else {
        // tslint:disable-next-line: max-line-length
        if (!Utilities.isNullOrUndefined(data.errorModel) && data.errorModel.xmlErrorCode !== null && data.errorModel.errorCode === 200) {
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Message';
          this.errorMessage = data.errorModel.message;
          let filterData = this.xmlError.filter(error => {
            if (data.errorModel.xmlErrorCode === error.code) {
              this.errorMessage = error.message;
            }
          });
          filterData = {}[''];
          dialogRef.componentInstance.message = this.errorMessage;
          dialogRef.componentInstance.buttonText = 'OK';
        } else {
          this.cancelBookingData = data;
          this.hotelBookService.cancelBookingDetails.documentID = this.cancelBookingData.documentID;
          this.openDialog();
        }
      }
    },
    (error: JarvisError) => {
      this.isDataLoaded = true;
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    },
    () => this.isDataLoaded = true);
    this.subscriptions.push(subscription);
  }
  openDialog(): void {
    this.dialog.open(BookingCancelConfirmDialog, {
      panelClass:"modal-heading-dialog"
    });
  }
  customClose(dialogRef) {
    dialogRef.close();
  }
  comapareAmount(chargedAmount) {
    // tslint:disable-next-line:radix
    return parseInt(chargedAmount) > 0;
  }
}
@Component({
  selector: 'app-booking-cancel-confirm-dialog',
  templateUrl: 'booking-cancel-confirm-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class BookingCancelConfirmDialog {
  constructor(public dialogRef: MatDialogRef<BookingCancelConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router, public activatedRoute: ActivatedRoute) {
  }

  customClose(dialogRef) {
    dialogRef.close();
    this.router.navigate(['authenticated/hotelmgmt/cancelbooking'], { relativeTo: this.activatedRoute });
  }
}
