import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { ConfigurationService } from './configuration.service';
import { Observable , of} from 'rxjs';
import {map} from 'rxjs/operators';

import { CountryListViewModel } from './../../../b2b/common/b2b-shared/viewmodels/country/countrylistviewmodel';
import { CityInfoViewModel } from './../../../b2b/common/b2b-shared/viewmodels/city/cityInfoViewModel';
import { CityListViewModel } from './../../../b2b/common/b2b-shared/viewmodels/city/cityListViewModel';
import { CitySearchViewModel } from './../../../b2b/common/b2b-shared/viewmodels/city/citySearchViewModel';
import { HotelViewModel } from './../../../b2b/viewmodels/reservation/reservation-list/hotelviewmodel';
import { Utilities } from './../../utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class CityDataService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }

  getCities(search: CitySearchViewModel): Observable<CityListViewModel[]> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , JSON.stringify(search), 'api/Cities/GetMaster').pipe(map(data => {
        let cityListDto: CityListViewModel[] = [];
           if (data !== null) {
           cityListDto = data['result'];
           }
           return cityListDto;
       }));
  }

  searchByCountryId(countryId: number, searchText: string, searchByNameOnly: boolean = false): Observable<CityListViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Cities/SearchByCountryId?countryId=' + countryId +
      '&searchText=' + searchText + '&searchByNameOnly=' + searchByNameOnly).pipe(map(data => {
        let resultDto: CityListViewModel[] = [];
        if (data !== null) {
          resultDto = data.result;
        }
        return resultDto;
      }));
  }

getCountryByContinentId(continentId: number): Observable<CountryListViewModel[]> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
    , 'api/Countries/GetByContinentId?continentId=' + continentId).pipe(map(data => {
      let countryListDto: CountryListViewModel[] = [];
   if (data !== null) {
     countryListDto = data.result;
   }
   return countryListDto;
   }));
}

getHotelbrandOrChain(cityId: number, countryId: number, searchText: string): Observable<HotelViewModel[]> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
  , 'api/RevenueMarkup/GetHotelBrandChain?searchText='
  + searchText + '&countryId=' + countryId + '&cityId=' + cityId ).pipe(map(data => {
    let searchHotelsDto: HotelViewModel[] = [];
      if (!Utilities.isNullOrEmpty(data) && !Utilities.isNullOrEmpty(data['result'])) {
        searchHotelsDto = data['result'];
      }
      return searchHotelsDto;
    })
    );
}

searchHotelsByCityId(cityId: number, searchText: string): Observable<HotelViewModel[]> {
  return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
  , cityId, 'api/ExcludeHotels/SearchHotelsByCityId?searchText=' + searchText).pipe(map(data => {
    let searchHotelsDto: HotelViewModel[] = [];
      if (!Utilities.isNullOrEmpty(data) && !Utilities.isNullOrEmpty(data['result'])) {
        searchHotelsDto = data['result'];
      }
      return searchHotelsDto;
    })
    );
}
// + param['searchText']
searchHotelsByCityIdOrBrandChainId(param: any): Observable<HotelViewModel[]> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi,
    'api/RevenueMarkup/GetHotelsByCityIdOrBrandChainId?countryId='
  + param['countryId']
  + '&cityId=' + param['cityId']
  + '&searchText='
  + '&brandId=' + param['brandId']
  + '&chainId=' + param['chainId']).pipe(map(data => {
    let searchHotelsDto: HotelViewModel[] = [];
      if (!Utilities.isNullOrEmpty(data) && !Utilities.isNullOrEmpty(data['result'])) {
        searchHotelsDto = data['result'];
      }
      return searchHotelsDto;
    })
    );
}

}
