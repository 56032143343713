import { AreaUpdateViewModel, HotelTypeUpdateViewModel } from './../../viewmodels/hotel-mgmt/hotelfilterupdateviewmodel';
import { Component, OnInit, Input, AfterViewInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { MatOption } from '@angular/material';
import { Subscription } from 'rxjs';
import { HotelFilterViewModel } from '../../viewmodels/hotel-mgmt/hotelfilterviewmodel';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HotelFilterService } from '../../common/b2b-shared/services/hotel-filter.service';
import { Utilities } from 'src/app/common/utilities/utilities';
import { CurrencyApi } from '../../../framework/fw/currency/currency-api';
import {
  HotelFilterUpdateViewModel, StarRatingUpdateViewModel,
  PromotionUpdateViewModel,
  FacilityUpdateViewModel
} from '../../viewmodels/hotel-mgmt/hotelfilterupdateviewmodel';
import { ErrorMessages } from 'src/app/common/errormessage';
import { UserInputTransformService } from '../../../common/shared/services/user-input-transform.service';
import { NavigatorService } from '../../../framework/fw/services/navigator.service';
import { formatNumber } from '@angular/common';
import { CONSTANTS } from '../../../common/constants';
import { ScreenService } from 'src/app/framework/fw/services/screen.service';

function checkFromRangeValue( min: number, max: number): ValidatorFn {
  return (control: FormControl): { [key: string]: boolean } | null => {
    if (control.value !== undefined && (isNaN(Number(control.value.toString().replace(/[,]/g, '')))
        || Number(control.value.toString().replace(/[,]/g, '')) < min)) {
          return { 'fromPriceMinValue': true };
      } else if (control.value !== undefined && (isNaN(Number(control.value.toString().replace(/[,]/g, '')))
      || Number(control.value.toString().replace(/[,]/g, '')) > max)) {
      return { 'fromPriceMaxValue': true };
      } else if (control.value !== undefined && (isNaN(Number(control.value.toString().replace(/[,]/g, '')))
          || Number(control.value.toString().replace(/[,]/g, '')) < min || Number(control.value.toString().replace(/[,]/g, '')) > max)) {
          return { 'fromPriceRange': true };
      }
      return null;
  };
}
  function checkToRangeValue( min: number, max: number): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (isNaN(Number(control.value.toString().replace(/[,]/g, '')))
            || Number(control.value.toString().replace(/[,]/g, '')) < min)) {
            return { 'toPriceMinValue': true };
        } else if (control.value !== undefined && (isNaN(Number(control.value.toString().replace(/[,]/g, '')))
        || Number(control.value.toString().replace(/[,]/g, '')) > max)) {
        return { 'toPriceMaxValue': true };
        } else if (control.value !== undefined && (isNaN(Number(control.value.toString().replace(/[,]/g, '')))
            || Number(control.value.toString().replace(/[,]/g, '')) < min || Number(control.value.toString().replace(/[,]/g, '')) > max)) {
            return { 'toPriceRange': true };
        }
        return null;
    };
}
@Component({
  selector: 'app-hotel-filter',
  templateUrl: './hotel-filter.component.html',
  styleUrls: ['./hotel-filter.component.css']
})

export class HotelFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  rangeValues: number[] = [0, 0];
  minSelector = 0;
  maxSelector = 0;
  hotelFilterForm: FormGroup;
  @Input() hotelFilter: HotelFilterViewModel = <HotelFilterViewModel>{};
  @Input() isSideNavDisplay: boolean;
  @Output() isSideNavDisplayEmit: EventEmitter<boolean> = new EventEmitter();
  updatedHotelFilter: HotelFilterUpdateViewModel = <HotelFilterUpdateViewModel>{};
  subscriptions: Subscription[] = [];
  @ViewChild('allChainsSelected') private allChainsSelected: MatOption;
  // @ViewChild('allAreasSelected') private allAreasSelected: MatOption;
  @ViewChild('allLandmarksSelected') private allLandmarksSelected: MatOption;
  pattern = '^\\$?([0-9]{0,}[,|.]([0-9]{0,}[,|.])*[0-9]{3}|[0-9]+)([,|.][0-9][0-9])?$';
  promotionTypes = CONSTANTS.promotionTypes;
  selectedAllPromotion: boolean;
  isHotelTypeExpanded = false;
  isAreaExpanded = false;
  maxNoOfHotelTypeAndAreaFilter = 5;
  

  constructor(public hotelDataService: HotelDataService,
    public currencyApi: CurrencyApi,
    private hotelFilterService: HotelFilterService,
    public userInputTransformService: UserInputTransformService,
    private navigatorService: NavigatorService,
    public screenService: ScreenService
  ) { }

  ngOnInit() {
    this.hotelFilter.toPrice = Math.ceil(this.hotelFilter.toPrice);
    this.hotelFilter.fromPrice = Math.floor(this.hotelFilter.fromPrice);
    this.createFormModel();
  }

  clearAllFilters() {
    this.hotelFilterService.onHotelFilterClear();
    this.isHotelTypeExpanded = false;
    this.isAreaExpanded = false;
    // this.setFromToValue();
  }

  clearHotelNameFilters() {
    this.hotelFilterForm.get('hotelName').setValue('');
    this.updateFilter();
  }

  clearStarFilters() {
    // Clear the Star Form Model
    this.starRatings.controls.forEach(ctrl => {
      ctrl.get('isSelected').setValue(false);
    });
  }
  clearFacilitiesFilter() {
    this.facilities.controls.forEach(ctrl => {
      ctrl.get('isSelected').setValue(false);
    });
  }

  clearAreaFilter() {
    this.areas.controls.forEach(ctrl => {
      ctrl.get('isSelected').setValue(false);
    });
    this.isAreaExpanded = false;
  }

  clearHotelTypeFilter() {
    this.hotelTypes.controls.forEach(ctrl => {
      ctrl.get('isSelected').setValue(false);
    });
    this.isHotelTypeExpanded = false;
  }

  clearPromotionsFilter() {
    this.selectedAllPromotion = false;
    this.promotions.controls.forEach(ctrl => {
      ctrl.get('isSelected').setValue(false);
    });
  }
  ngAfterViewInit(): void {
    this.trackValueChanges();
  }

  trackValueChanges() {
    // Todo: Unsubscribe

    if (this.hotelFilterForm.get('fromPrice').valid === true && this.hotelFilterForm.get('toPrice').valid === true) {
      const subscFromPrice = this.hotelFilterForm.get('fromPrice').valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
      ).subscribe(data => {
        // // console.log('Changed fromPrice =>' + data);
        this.setFromToValue();
        this.updateFilter();
      });
      this.subscriptions.push(subscFromPrice);

      const subscToPrice = this.hotelFilterForm.get('toPrice').valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
      ).subscribe(data => {
        // console.log('Changed toPrice =>' + data);
        this.setFromToValue();
        this.updateFilter();
      });
      this.subscriptions.push(subscToPrice);
    }

    // const subscAreaId = this.hotelFilterForm.get('areaId').valueChanges.pipe(
    //   debounceTime(1000),
    //   distinctUntilChanged(),
    // ).subscribe(data => {
    //   // console.log('Changed AreaId =>' + data);
    //   this.updateFilter();
    // });
    // this.subscriptions.push(subscAreaId);

    const subscLandmarkId = this.hotelFilterForm.get('landmarkId').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(data => {
      // console.log('Changed landmarkId =>' + data);
      this.updateFilter();
    });
    this.subscriptions.push(subscLandmarkId);

    const subscChainId = this.hotelFilterForm.get('chainId').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(data => {
      // console.log('Changed chainId =>' + data);
      this.updateFilter();
    });
    this.subscriptions.push(subscChainId);

    const subscIsMgPreferred = this.hotelFilterForm.get('isMgPreferred').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(data => {
      // console.log('Changed isMgPreferred =>' + data);
      this.updateFilter();
    });
    this.subscriptions.push(subscIsMgPreferred);

    const subscisFreeCancellation = this.hotelFilterForm.get('isFreeCancellation').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(data => {
      // console.log('Changed isFreeCancellation =>' + data);
      this.updateFilter();
    });
    this.subscriptions.push(subscisFreeCancellation);

    const subscisOpenDateHotelVoucher = this.hotelFilterForm.get('isOpenDateHotelVoucher').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(data => {
      // console.log('Changed isOpenDateHotelVoucher =>' + data);
      this.updateFilter();
    });
    this.subscriptions.push(subscisOpenDateHotelVoucher);

    this.starRatings.controls.forEach(ctrl => {
      const subscription = ctrl.get('isSelected').valueChanges.pipe(debounceTime(1000),
        distinctUntilChanged()).subscribe(data => {
          // console.log('Changed rating =>' + data);
          this.updateFilter();
        });
      this.subscriptions.push(subscription);
    });

    this.promotions.controls.forEach(ctrl => {
      const subscription = ctrl.get('isSelected').valueChanges.pipe(debounceTime(1000),
        distinctUntilChanged()).subscribe(data => {
          // console.log('Changed promotions =>' + data);
          this.updateFilter();
        });
      this.subscriptions.push(subscription);
    });

    this.facilities.controls.forEach(ctrl => {
      const subscription = ctrl.get('isSelected').valueChanges.pipe(debounceTime(1000),
        distinctUntilChanged()).subscribe(data => {
          // console.log('Changed facilities =>' + data);
          this.updateFilter();
          ctrl.get('isSelected').setValue(data, {onlySelf: true, emitEvent: false});
        });
      this.subscriptions.push(subscription);
    });

    this.areas.controls.forEach(ctrl => {
      const subscription = ctrl.get('isSelected').valueChanges.pipe(debounceTime(1000),
        distinctUntilChanged()).subscribe(data => {
          this.updateFilter();
        });
      this.subscriptions.push(subscription);
    });

    this.hotelTypes.controls.forEach(ctrl => {
      const subscription = ctrl.get('isSelected').valueChanges.pipe(debounceTime(1000),
        distinctUntilChanged()).subscribe(data => {
          this.updateFilter();
        });
      this.subscriptions.push(subscription);
    });
  }

  // getHotelCountForStarRating(starRating: any) {
  //   let starRatingsHotelCountFilter = JSON.parse(JSON.stringify(this.hotelFilter.starRatingsHotelCount));
  //   starRatingsHotelCountFilter = starRatingsHotelCountFilter.filter(sr => sr.starRating === starRating);
  //   return starRatingsHotelCountFilter[0].hotelCount;
  // }

  // getHotelCountForArea(area: any) {
  //   let areasHotelCountFilter = JSON.parse(JSON.stringify(this.hotelFilter.areasHotelCount));
  //   areasHotelCountFilter = areasHotelCountFilter.filter(sr => sr.areaId === area);
  //   return areasHotelCountFilter[0].hotelCount;
  // }

  convertFloatStarToIntStar(starRating: string) {
    let intStar: string = starRating;
    const splitStarRating = starRating.split('.');
    if (splitStarRating.length > 1) {
      if (splitStarRating[1] === '0') {
        intStar = splitStarRating[0];
      }
    }
    return Number(intStar);
  }

  onHotelNameSearch() {
    const selectedHotelName: string = this.hotelFilterForm.get('hotelName').value;
    if (!Utilities.isNullOrEmpty(selectedHotelName) && selectedHotelName.length > 2) {
      this.updateFilter();
    }
  }

  createFormModel() {
    this.hotelFilterForm = new FormGroup({
      hotelName: new FormControl(''),
      fromPrice: new FormControl(formatNumber(this.hotelFilter.fromPrice, this.navigatorService.getCurrentLocale()),
            [Validators.pattern(this.pattern),
              checkFromRangeValue(Math.floor(this.hotelFilter.fromPrice),
              Math.floor(this.hotelFilter.toPrice))]), // '^[0-9][,]*$'
      toPrice: new FormControl(formatNumber(this.hotelFilter.toPrice, this.navigatorService.getCurrentLocale()),
        // tslint:disable-next-line:max-line-length
        [Validators.pattern(this.pattern),
          checkToRangeValue(Math.floor(this.hotelFilter.fromPrice),
          Math.floor(this.hotelFilter.toPrice))]),
      isMgPreferred: new FormControl(false),
      isFreeCancellation: new FormControl(false),
      isOpenDateHotelVoucher: new FormControl(false),
      starRatings: (this.hotelFilter.starRatings !== null &&
        this.hotelFilter.starRatings !== undefined &&
        this.hotelFilter.starRatings.length) ? this.buildStarRatings() : new FormArray([]),
      promotions: (this.hotelFilter.promotions !== null &&
        this.hotelFilter.promotions !== undefined &&
        this.hotelFilter.promotions.length) ? this.buildPromotions() : new FormArray([]),
      // areaId: new FormControl(),
      areas: (this.hotelFilter.areas !== null &&
        this.hotelFilter.areas !== undefined &&
        this.hotelFilter.areas.length) ?
        this.buildAreas() : new FormArray([]),
      hotelTypes: (this.hotelFilter.hotelTypes !== null &&
        this.hotelFilter.hotelTypes !== undefined &&
        this.hotelFilter.hotelTypes.length) ?
        this.buildHotelTypes() : new FormArray([]),
      chainId: new FormControl(),
      landmarkId: new FormControl(),
      facilities: (this.hotelFilter.facilities !== null &&
        this.hotelFilter.facilities !== undefined &&
        this.hotelFilter.facilities.length) ?
        this.buildFacilities() : new FormArray([]),
      rangeValues: new FormControl([Math.floor(this.hotelFilter.fromPrice), Math.ceil(this.hotelFilter.toPrice)])
    });
    this.minSelector = Number(this.hotelFilterForm.get('fromPrice').value.toString().replace(/[,]/g, ''));
    // this.hotelFilterForm.get('fromPrice').value;
    this.maxSelector = Number(this.hotelFilterForm.get('toPrice').value.toString().replace(/[,]/g, ''));
    // this.hotelFilterForm.get('toPrice').value;
  }

  numberFormatInLocaleFromPrice(value) {
    if (value !== '') {
      this.hotelFilterForm.get('fromPrice').setValue(this.userInputTransformService.userInputNumberTransform(value));
    }
  }

  numberFormatInLocaleToPrice(value) {
    if (value !== '') {
      this.hotelFilterForm.get('toPrice').setValue(this.userInputTransformService.userInputNumberTransform(value));
    }
  }

  buildStarRatings(): FormArray {
    let ratingGroup: FormGroup;
    let ratingGroupArray: FormArray;
    // sort  starRatings
    this.hotelFilter.starRatings = this.hotelFilter.starRatings.sort((aVal, bVal) => {
      if (aVal.rating === bVal.rating) {
        return 0;
      } else if (aVal.rating > bVal.rating) {
        return -1;
      } else {
        return 1;
      }
    });

    this.hotelFilter.starRatings.forEach(rating => {
      ratingGroup = new FormGroup({
        id: new FormControl(rating.rating),
        rating: new FormControl(rating.rating),
        isSelected: new FormControl(false),
        count: new FormControl(rating.count)
      });
      if (ratingGroupArray === null || ratingGroupArray === undefined) {
        ratingGroupArray = new FormArray([ratingGroup]);
      } else {
        ratingGroupArray.push(ratingGroup);
      }
    });

    return ratingGroupArray;
  }

  get starRatings(): FormArray {
    return <FormArray>this.hotelFilterForm.get('starRatings');
  }

  buildPromotions(): FormArray {
    let promoGroup: FormGroup;
    let promoGroupArray: FormArray;
    this.hotelFilter.promotions.forEach(promo => {
      promoGroup = new FormGroup({
        type: new FormControl(promo),
        isSelected: new FormControl(false)
      });
      if (promoGroupArray === null || promoGroupArray === undefined) {
        promoGroupArray = new FormArray([promoGroup]);
      } else {
        promoGroupArray.push(promoGroup);
      }
    });

    return promoGroupArray;
  }

  get promotions(): FormArray {
    return <FormArray>this.hotelFilterForm.get('promotions');
  }

  /* get areas(): FormArray {
    return <FormArray>this.hotelFilterForm.get('areas');
  } */

  buildFacilities(): FormArray {
    let facilityGroup: FormGroup;
    let facilityGroupArray: FormArray;
    this.hotelFilter.facilities.forEach(fac => {
      facilityGroup = new FormGroup({
        id: new FormControl(fac.code),
        facilityName: new FormControl(fac.name),
        isSelected: new FormControl(false)
      });
      if (facilityGroupArray === null || facilityGroupArray === undefined) {
        facilityGroupArray = new FormArray([facilityGroup]);
      } else {
        facilityGroupArray.push(facilityGroup);
      }
    });

    return facilityGroupArray;
  }

  get facilities(): FormArray {
    return <FormArray>this.hotelFilterForm.get('facilities');
  }

  buildAreas(): FormArray {
    let areaGroup: FormGroup;
    let areaGroupArray: FormArray;
    this.hotelFilter.areas.forEach(area => {
      areaGroup = new FormGroup({
        id: new FormControl(area.areaId),
        areaName: new FormControl(area.area),
        isSelected: new FormControl(false),
        count: new FormControl(area.count)
      });
      if (areaGroupArray === null || areaGroupArray === undefined) {
        areaGroupArray = new FormArray([areaGroup]);
      } else {
        areaGroupArray.push(areaGroup);
      }
    });

    return areaGroupArray;
  }

  get areas(): FormArray {
    return <FormArray>this.hotelFilterForm.get('areas');
  }

  buildHotelTypes(): FormArray {
    let hotelTypeGroup: FormGroup;
    let hotelTypeArray: FormArray;
    this.hotelFilter.hotelTypes.forEach(hotelType => {
      hotelTypeGroup = new FormGroup({
        hotelTypeId: new FormControl(hotelType.hotelTypeId),
        hotelType: new FormControl(hotelType.hotelType),
        isSelected: new FormControl(false),
        count: new FormControl(hotelType.count)
      });
      if (hotelTypeArray === null || hotelTypeArray === undefined) {
        hotelTypeArray = new FormArray([hotelTypeGroup]);
      } else {
        hotelTypeArray.push(hotelTypeGroup);
      }
    });

    return hotelTypeArray;
  }

  get hotelTypes(): FormArray {
    return <FormArray>this.hotelFilterForm.get('hotelTypes');
  }
  
  showNav() {
    if (this.isSideNavDisplay) {
      this.isSideNavDisplayEmit.emit(false);
    } else { this.isSideNavDisplayEmit.emit(true); }
  }

  updateFilter() {
    // this.updatedHotelFilter = Object.assign({}, this.hotelFilterForm.value);
    // this.setFromToValue();
    this.updatedHotelFilter = this.filterByUserSelections();
    // console.log('Updated Filter:' + JSON.stringify(this.updatedHotelFilter));

    this.hotelFilterService.onHotelFilterChange(this.updatedHotelFilter);
  }

  filterByUserSelections(): HotelFilterUpdateViewModel {
    const hotelFilterUpdateVM = new HotelFilterUpdateViewModel();
    const selectedHotelName: string = this.hotelFilterForm.get('hotelName').value;
    if (!Utilities.isNullOrEmpty(selectedHotelName) && selectedHotelName.length > 2) {
      hotelFilterUpdateVM.hotelName = selectedHotelName;
    }

    if (this.hotelFilterForm.get('fromPrice').valid === true && this.hotelFilterForm.get('toPrice').valid === true) {
      const selectedFromPrice = Number(this.hotelFilterForm.get('fromPrice').value.toString().replace(/[,]/g, ''));
      // this.hotelFilterForm.get('fromPrice').value;
      if (Number(selectedFromPrice) >= 0) {
        hotelFilterUpdateVM.fromPrice = Number(selectedFromPrice.toString().replace(/[,]/g, ''));
      }

      const selectedToPrice = Number(this.hotelFilterForm.get('toPrice').value.toString().replace(/[,]/g, ''));
      // this.hotelFilterForm.get('toPrice').value;
      if (Number(selectedToPrice) >= 0) {
        hotelFilterUpdateVM.toPrice = Number(selectedToPrice.toString().replace(/[,]/g, ''));
      }
    }

    const selectedIsMgPreferred: boolean = this.hotelFilterForm.get('isMgPreferred').value;
    if (selectedIsMgPreferred === true) {
      hotelFilterUpdateVM.isMgPreferred = selectedIsMgPreferred;
    }

    const selectedIsFreeCancellation: boolean = this.hotelFilterForm.get('isFreeCancellation').value;
    if (selectedIsFreeCancellation === true) {
      hotelFilterUpdateVM.isFreeCancellation = selectedIsFreeCancellation;
    }

    const selectedIsOpenDateHotelVoucher: boolean = this.hotelFilterForm.get('isOpenDateHotelVoucher').value;
    if (selectedIsOpenDateHotelVoucher === true) {
      hotelFilterUpdateVM.isOpenDateHotelVoucher = selectedIsOpenDateHotelVoucher;
    }

    const selectedStarRatings: StarRatingUpdateViewModel[] = [];
    this.starRatings.controls.forEach((control, index) => {
      if (control.get('isSelected').value === true) {
        const selectedStarRating: StarRatingUpdateViewModel = new StarRatingUpdateViewModel();
        selectedStarRating.id = control.get('id').value;
        selectedStarRating.rating = control.get('rating').value;
        selectedStarRatings.push(selectedStarRating);
      }
    });
    if (selectedStarRatings.length) {
      hotelFilterUpdateVM.starRatings = selectedStarRatings;
    }
    const selectedPromotions: PromotionUpdateViewModel[] = [];
    this.promotions.controls.forEach((control, index) => {
      if (control.get('isSelected').value === true) {
        const selectedPromotion: PromotionUpdateViewModel = new PromotionUpdateViewModel();
        selectedPromotion.type = control.get('type').value;
        selectedPromotions.push(selectedPromotion);
      }
    });
    if (selectedPromotions.length) {
      hotelFilterUpdateVM.promotions = selectedPromotions;
    }

    // const selectedAreaId = this.hotelFilterForm.get('areaId').value;
    // if (selectedAreaId && selectedAreaId.length) {
    //   hotelFilterUpdateVM.areaId = selectedAreaId;
    // }

    const selectedLandmarkId = this.hotelFilterForm.get('landmarkId').value;
    if (selectedLandmarkId && selectedLandmarkId.length) {
      hotelFilterUpdateVM.landmarkId = selectedLandmarkId;
    }

    const selectedChainId = this.hotelFilterForm.get('chainId').value;
    if (selectedChainId && selectedChainId.length) {
      hotelFilterUpdateVM.chainId = selectedChainId;
    }

    const selectedFacilities: FacilityUpdateViewModel[] = [];
    this.facilities.controls.forEach((control, index) => {
      if (control.get('isSelected').value === true) {
        const selectedFacility: FacilityUpdateViewModel = new FacilityUpdateViewModel();
        selectedFacility.code = control.get('id').value;
        selectedFacility.name = control.get('facilityName').value;
        selectedFacilities.push(selectedFacility);
      }
    });
    if (selectedFacilities.length) {
      hotelFilterUpdateVM.facilities = selectedFacilities;
    }

    const selectedAreas: AreaUpdateViewModel[] = [];
    this.areas.controls.forEach((control, index) => {
      if (control.get('isSelected').value === true) {
        const selectedArea: AreaUpdateViewModel = new AreaUpdateViewModel();
        selectedArea.id = control.get('id').value;
        selectedArea.name = control.get('areaName').value;
        selectedAreas.push(selectedArea);
      }
    });
    if (selectedAreas.length) {
      hotelFilterUpdateVM.areas = selectedAreas;
    }

    const selectedHotelTypes: HotelTypeUpdateViewModel[] = [];
    this.hotelTypes.controls.forEach((control, index) => {
      if (control.get('isSelected').value === true) {
        const selectedHotelType: HotelTypeUpdateViewModel = new HotelTypeUpdateViewModel();
        selectedHotelType.id = control.get('hotelTypeId').value;
        selectedHotelType.name = control.get('hotelType').value;
        selectedHotelTypes.push(selectedHotelType);
      }
    });
    if (selectedHotelTypes.length) {
      hotelFilterUpdateVM.hotelTypes = selectedHotelTypes;
    }

    return hotelFilterUpdateVM;
  }

  updateOnHotelSearchChange() {
    if (!Utilities.isNullOrUndefined(this.hotelFilter)) {
      this.createFormModel();
      this.trackValueChanges();
    }
  }

  resetOnFilterClear() {
    // console.log('Original parameters' + this.hotelFilter);
    this.createFormModel();
    this.trackValueChanges();
  }

  onSliderChange() {
    const tempRangeValues = this.hotelFilterForm.get('rangeValues').value;
    this.hotelFilterForm.get('fromPrice').setValue(
      formatNumber(tempRangeValues[0], this.navigatorService.getCurrentLocale()));
    this.hotelFilterForm.get('toPrice').setValue(
      formatNumber(tempRangeValues[1], this.navigatorService.getCurrentLocale()));
    // this.hotelFilterForm.get('fromPrice').setValidators([Validators.min(this.minSelector),
    // Validators.max(tempRangeValues[1]),
    // Validators.pattern(this.pattern)]);
    this.hotelFilterForm.get('fromPrice').setValidators([Validators.pattern(this.pattern),
      checkFromRangeValue(Math.floor(this.hotelFilter.fromPrice),
      Math.floor(this.hotelFilter.toPrice))]);

    // this.hotelFilterForm.get('toPrice').setValidators([Validators.min(tempRangeValues[0]),
    // Validators.max(this.maxSelector),
    // Validators.pattern(this.pattern)]);
    this.hotelFilterForm.get('toPrice').setValidators([Validators.pattern(this.pattern),
      checkFromRangeValue(Math.floor(this.hotelFilter.fromPrice),
      Math.floor(this.hotelFilter.toPrice))]);
  }

  setFromToValue() {
    if (this.hotelFilterForm.get('fromPrice').valid === true && this.hotelFilterForm.get('toPrice').valid === true) {
      this.hotelFilterForm.get('rangeValues').setValue([
        (Number(this.hotelFilterForm.get('fromPrice').value.toString().replace(/[,]/g, ''))),
        (Number(this.hotelFilterForm.get('toPrice').value.toString().replace(/[,]/g, '')))]);
    }
  }

  getValidationMessage(controlName: string) {
    if (this.hotelFilterForm.get(controlName).hasError('fromPriceMinValue')) {
      if (controlName === 'fromPrice') {
        return ErrorMessages.invalidNumberMessage;
      }
    }
      if (this.hotelFilterForm.get(controlName).hasError('fromPriceMaxValue')) {
        if (controlName === 'fromPrice') {
          return ErrorMessages.invalidNumberMessage;
        }
      }
    if (this.hotelFilterForm.get(controlName).hasError('toPriceMinValue')) {
      if ( controlName === 'toPrice') {
        return ErrorMessages.invalidNumberMessage;
      }
    }
    if (this.hotelFilterForm.get(controlName).hasError('toPriceMaxValue')) {
      if ( controlName === 'toPrice') {
        return ErrorMessages.invalidNumberMessage;
      }
    }
    if (this.hotelFilterForm.get(controlName).hasError('pattern')) {
      if (controlName === 'fromPrice' || controlName === 'toPrice') {
        return ErrorMessages.invalidNumberMessage;
      }
    } else if (this.hotelFilterForm.get(controlName).hasError('fromPriceRange')) {
      if (controlName === 'fromPrice') {
        return ErrorMessages.invalidNumberMessage;
      }
    } else if (this.hotelFilterForm.get(controlName).hasError('toPriceRange')) {
      if (controlName === 'toPrice') {
        return ErrorMessages.invalidNumberMessage;
      }
    } else {
     // return '';
     return ErrorMessages.invalidNumberMessage;
    }
  }



  removeCurrencyPipeFormat(element) {
    element.target.value = element.target.value.replace(/[,]/g, '');
  }

  hasErrors(controlName: string) {
    if (this.hotelFilterForm.get(controlName) !== null) {
      return (
        (this.hotelFilterForm.get(controlName).dirty ||
          this.hotelFilterForm.get(controlName).touched) &&
        this.hotelFilterForm.get(controlName).errors !== null
      );
    }
  }

  toggleSingleChainSelection() {
    if (!Utilities.isNullOrEmpty(this.allChainsSelected) && this.allChainsSelected.selected) {
      this.allChainsSelected.deselect();
      const selectedValues = this.hotelFilterForm.get('chainId').value;
      if (selectedValues && selectedValues.includes('-1')) {
        selectedValues.splice(selectedValues.findIndex(x => x === -1), 1);
        this.hotelFilterForm.get('chainId').patchValue(selectedValues);
      }
      return false;
    }
    this.selectAllChainOption();
  }

  toggleAllChainsSelection() {
    if (!Utilities.isNullOrEmpty(this.allChainsSelected) && this.allChainsSelected.selected) {
      this.hotelFilterForm.get('chainId').patchValue([...this.hotelFilter.chains.map(item => item.chainId), -1]);
    } else {
      this.hotelFilterForm.get('chainId').patchValue([]);
    }
  }

  selectAllChainOption() {
    if (!Utilities.isNullOrEmpty(this.hotelFilterForm.get('chainId')) &&
      this.hotelFilterForm.get('chainId').value.length > 0 &&
      !Utilities.isNullOrEmpty(this.hotelFilterForm.get('chainId').value)) {
      // If all unique chainIds are selected then select All option
      if ((this.hotelFilterForm.get('chainId').value).includes('-1')) {
        this.allChainsSelected.select();
        this.hotelFilterForm.get('chainId').patchValue([...this.hotelFilterForm.get('chainId').value, -1]);
      } else if ((this.hotelFilter.chains.length > 1) &&
        (this.hotelFilterForm.get('chainId').value.length > (this.hotelFilter.chains.length - 1)) && this.allChainsSelected) {
        this.allChainsSelected.select();
      }
    }
  }

  // toggleSingleAreaSelection() {
  //   if (!Utilities.isNullOrEmpty(this.allAreasSelected) && this.allAreasSelected.selected) {
  //     this.allAreasSelected.deselect();
  //     const selectedValues = this.hotelFilterForm.get('areaId').value;
  //     if (selectedValues && selectedValues.includes('-1')) {
  //       selectedValues.splice(selectedValues.findIndex(x => x === -1), 1);
  //       this.hotelFilterForm.get('areaId').patchValue(selectedValues);
  //     }
  //     return false;
  //   }
  //   this.selectAllAreaOption();
  // }

  // toggleAllAreasSelection() {
  //   if (!Utilities.isNullOrEmpty(this.allAreasSelected) && this.allAreasSelected.selected) {
  //     this.hotelFilterForm.get('areaId').patchValue([...this.hotelFilter.areas.map(item => item.areaId), -1]);
  //   } else {
  //     this.hotelFilterForm.get('areaId').patchValue([]);
  //   }
  // }

  // selectAllAreaOption() {
  //   if (!Utilities.isNullOrEmpty(this.hotelFilterForm.get('areaId')) &&
  //     this.hotelFilterForm.get('areaId').value.length > 0 &&
  //     !Utilities.isNullOrEmpty(this.hotelFilterForm.get('areaId').value)) {
  //     // If all unique areaIds are selected then select All option
  //     if ((this.hotelFilterForm.get('areaId').value).includes('-1')) {
  //       this.allAreasSelected.select();
  //       this.hotelFilterForm.get('areaId').patchValue([...this.hotelFilterForm.get('areaId').value, -1]);
  //     } else if ((this.hotelFilter.areas.length > 1) &&
  //       (this.hotelFilterForm.get('areaId').value.length > (this.hotelFilter.areas.length - 1)) && this.allAreasSelected) {
  //       this.allAreasSelected.select();
  //     }
  //   }
  // }

  toggleSingleLandmarkSelection() {
    if (!Utilities.isNullOrEmpty(this.allLandmarksSelected) && this.allLandmarksSelected.selected) {
      this.allLandmarksSelected.deselect();
      const selectedValues = this.hotelFilterForm.get('landmarkId').value;
      if (selectedValues && selectedValues.includes('-1')) {
        selectedValues.splice(selectedValues.findIndex(x => x === -1), 1);
        this.hotelFilterForm.get('landmarkId').patchValue(selectedValues);
      }
      return false;
    }
    this.selectAllLandmarkOption();
  }

  toggleAllLandmarksSelection() {
    if (!Utilities.isNullOrEmpty(this.allLandmarksSelected) && this.allLandmarksSelected.selected) {
      this.hotelFilterForm.get('landmarkId').patchValue([...this.hotelFilter.landmarks.map(item => item.landmarkId), -1]);
    } else {
      this.hotelFilterForm.get('landmarkId').patchValue([]);
    }
  }

  selectAllLandmarkOption() {
    if (!Utilities.isNullOrEmpty(this.hotelFilterForm.get('landmarkId')) &&
      this.hotelFilterForm.get('landmarkId').value.length > 0 &&
      !Utilities.isNullOrEmpty(this.hotelFilterForm.get('landmarkId').value)) {
      // If all unique landmarkIds are selected then select All option
      if ((this.hotelFilterForm.get('landmarkId').value).includes('-1')) {
        this.allLandmarksSelected.select();
        this.hotelFilterForm.get('landmarkId').patchValue([...this.hotelFilterForm.get('landmarkId').value, -1]);
      } else if ((this.hotelFilter.landmarks.length > 1) &&
        (this.hotelFilterForm.get('landmarkId').value.length > (this.hotelFilter.landmarks.length - 1)) && this.allLandmarksSelected) {
        this.allLandmarksSelected.select();
      }
    }
  }

  promotionSelectAll() {
    this.promotions.controls.forEach( promotion => {
      promotion.patchValue({
        isSelected: this.selectedAllPromotion
      });
    });
  }
  promotionCheckIfAllSelected() {
    this.selectedAllPromotion = this.promotions.value.every(function(item: any) {
        return item.isSelected === true;
      });
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }

}
