import { PaymentTypeDetailsViewModel } from './../../../../viewmodels/payment/paymenttypedetailsviewmodel';
import { PaymentDataService } from './../../services/payment-data.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CreditLimitViewModel } from 'src/app/common/viewmodels/agencyprofile/creditlimitviewmodel';
import { JarvisError } from 'src/app/common/jarviserror';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { AgencyInfoViewModel } from 'src/app/common/viewmodels/agencyprofile/agencyinfoviewmodel';
import { AgencyProfileDataService } from 'src/app/b2b/common/b2b-shared/services/agency-profile-data.service';
import { MasterLookupService } from 'src/app/common/shared/services/lookup.service';
import { HotelDataService } from '../../services/hotel-data.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';

@Component({
  selector: 'app-agency-credit',
  templateUrl: './agency-credit.component.html',
  styleUrls: ['./agency-credit.component.css']
})
export class AgencyCreditComponent implements OnInit, OnDestroy {
  showLowCreditDailog: boolean;
  showIsDisableCreditOption: boolean;

  constructor(private agencyProfileDataService: AgencyProfileDataService, private userProfileService: UserProfileService,
    private lookupServices: MasterLookupService, private hotelDataService: HotelDataService, public dialog: MatDialog,
    private router: Router, public currencyApi: CurrencyApi, private configurationService: ConfigurationService,
    public paymentDataService: PaymentDataService) {
  }
  creditLimit: CreditLimitViewModel = new CreditLimitViewModel;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  currencyId = this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId;
  currencyCode: string;
  consumedCreditPercent = 0;
  balancedCreditPercent = 0;
  totalCreditAmount = 0;
  creditLimitConsumed = 0;
  creditLimitBalance = 0;
  creditRequired;
  creditAfterBooking = 0;
  data: any;
  hotelData: any;
  options: any;
  isTriggered: boolean;
  isCreditLimitLow: boolean;
  creditThreshold: number;
  @Input() isGuestDetailsPage: boolean;
  @Input() requiredForBooking: number;
  agencyInfoViewModel: AgencyInfoViewModel = <AgencyInfoViewModel>{};
  subscriptions: any[] = [];
  accountManager: string;
  selectedCurrency: string;
  defaultCurrency: string;
  exchangeRate = 1;
  email = this.configurationService.config.emailConfig.creditRequest;
  @Output() creditLimitDetails: EventEmitter<CreditLimitViewModel> = new EventEmitter<CreditLimitViewModel>();
  paymentTypeDetails: PaymentTypeDetailsViewModel;

  ngOnInit() {
    this.isTriggered = this.lookupServices.isLowCredit();
    this.getCurrencyCode(this.currencyId);
    if (!this.isTriggered) {
      this.getAgencyCreditLimit();
    }
    this.getCurrencyExchangeRate();
    const subscription = this.paymentDataService.getPaymentTypeDetailsByAgencyId(
      this.currencyApi.selectedCurrency, this.userProfileService.getBasicUserInfo().agencyId).subscribe(data => {
        this.paymentTypeDetails = data;
        if (this.paymentTypeDetails.isDisableCreditLimit && (this.router.url.search('hotelinfo') > -1 ||
          this.router.url.search('agencysearchinfo') > -1) && !this.isTriggered) {
          this.showIsDisableCreditOption = true;
        }
      },
        (error: JarvisError) => {
          console.error(`Error in Getting Agency data. ${error.friendlyMessage}`);
        },
       );
    this.subscriptions.push(subscription);
  }

  getCurrencyExchangeRate() {
    this.selectedCurrency = this.currencyApi.selectedCurrency;
    this.defaultCurrency = this.currencyApi.defaultCurrency;
    if (this.defaultCurrency !== this.selectedCurrency && this.selectedCurrency && this.defaultCurrency) {
      // tslint:disable-next-line:max-line-length
      const subscription = this.agencyProfileDataService.getCurrencyExchangeRate(this.selectedCurrency, this.defaultCurrency).subscribe(data => {
        this.exchangeRate = data.result;
        this.getAgencyData(this.agencyId);
      },
        (error: JarvisError) => console.error(`Error in Getting Currency Exchange Rate. ${error.friendlyMessage}`));
      this.subscriptions.push(subscription);
    } else if (this.defaultCurrency === this.selectedCurrency) {
      this.exchangeRate = 1;
      this.getAgencyData(this.agencyId);
    } else {
      this.getAgencyData(this.agencyId);
    }
  }

  getCurrencyCode(currecyId: number) {
    const subscription = this.lookupServices.getAgencyCurreny(currecyId).subscribe(data => {
      this.currencyCode = data.code;
    },
      (error: JarvisError) => console.error(`Error in Getting AgencyData. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);

  }

  getAgencyCreditLimit() {
    let datatest;
    const subscription = this.lookupServices.getAgencyCreditDetails(this.agencyId).subscribe(data => {
      datatest = data;
      if (datatest !== null) {
        this.isCreditLimitLow = datatest.isAgencyCreditLimitLow;
        this.creditThreshold = datatest.creditLimitThresholdPercentage;
        this.accountManager = datatest.email;
      }
      // this.getAgencyData(this.agencyId);
      if (this.isCreditLimitLow && this.router.url.search('hotelinfo') > -1 && !this.isTriggered) {
        // this.lookupServices.getAccountManagerDeatils().subscribe(managerdata => {
        //   if (managerdata) {
        //     this.accountManager = managerdata.email;
        //   }
        // },
        //   (error: JarvisError) => console.error(`Error in Getting AgencyData. ${error.friendlyMessage}`));
        this.lookupServices.setLowcredit(true);
        this.showLowCreditDailog = true;
      }
    },
      (error: JarvisError) => console.error(`Error in Getting Agency Credit threshold . ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }
  getAgencyData(agencyId: number) {
    const subscription = this.agencyProfileDataService.getCreditById(agencyId).subscribe(data => {
      this.creditLimit = data;
      this.creditLimitDetails.emit(data);
      if (this.creditLimit !== undefined) {
        this.totalCreditAmount = this.creditLimit.creditAmount + this.creditLimit.emergencyCreditLimit;
        this.creditLimitConsumed = this.creditLimit.creditLimitConsumed > 0 ? this.creditLimit.creditLimitConsumed : 0;
        this.creditLimitBalance = this.creditLimit.creditLimitBalance;
        this.creditAfterBooking = isNaN(this.creditLimitBalance - (this.requiredForBooking * this.exchangeRate))
          ? 0 : this.creditLimitBalance - (this.requiredForBooking * this.exchangeRate);
        if (this.creditLimit.creditLimitConsumed < 0) {
          this.consumedCreditPercent = 0;
          this.creditLimit.creditLimitConsumed = 0;
        } else if (this.creditLimit.creditLimitConsumed > this.totalCreditAmount) {
          this.consumedCreditPercent = 100;
        } else {
          this.consumedCreditPercent = Math.round((this.creditLimit.creditLimitConsumed / this.totalCreditAmount) * 100);
        }

        if (this.creditLimit.creditLimitBalance < 0) {
          this.balancedCreditPercent = 0;
        } else if (this.creditLimit.creditLimitBalance > this.totalCreditAmount) {
          this.balancedCreditPercent = 100;
        } else {
          this.balancedCreditPercent = 100 - this.consumedCreditPercent;
        }
      } else {
        this.consumedCreditPercent = 0;
        this.balancedCreditPercent = 100;
        this.totalCreditAmount = null;
        this.creditLimitConsumed = null;
        this.creditLimitBalance = null;
      }

      this.options = {
        responsive: true,
        cutoutPercentage: 86,
        legend: { display: false },
        tooltips: {
          enabled: false
        }
      };

      this.data = {
        labels: ['Used', 'Available'],
        datasets: [
          {
            data: [this.consumedCreditPercent, this.balancedCreditPercent],
            // data: [50, 50],
            backgroundColor: [
              '#25aae1',
              '#75b879'
            ],
            hoverBackgroundColor: [
              '#25aae1',
              '#75b879'
            ],
            borderWidth: [0, 0]
          }]
      };
    },
      (error: JarvisError) => console.error(`Error in Getting AgencyData. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);

  }
  closeDialog() {
    this.showLowCreditDailog = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
