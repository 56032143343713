import { CallcenterMgmtModule } from './../../b2b/callcenter-mgmt/callcenter-mgmt.module';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { MenuComponent } from './menus/menu/menu.component';
import { MenuItemComponent } from './menus/menu-item/menu-item.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { MaterialModule } from '../../common/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FrameworkBodyComponent } from './framework-body/framework-body.component';
import { ContentComponent } from './content/content.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SideNavComponent } from './menus/side-nav/side-nav.component';
import { SideNavItemComponent } from './menus/side-nav-item/side-nav-item.component';
import { CarouselModule } from 'primeng/carousel';
import { HomeCarouselComponent } from './home-carousel/home-carousel.component';
import {
  AgencyRequestCreditComponent,
  RequestCreditSubmittedDialog,
  RequestCreditPreviewDialog
} from './users/agency-request-credit/agency-request-credit.component';
import { ComponentsModule } from 'src/app/b2b/common/b2b-shared/components/components.module';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { PhotosComponent } from 'src/app/b2b/common/b2b-shared/dialogs/photos/photos.component';
import { B2bSharedModule } from 'src/app/b2b/common/b2b-shared/b2b-shared.module';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { ErrorComponent } from './error/error.component';
import { AgencyDetailsComponent } from 'src/app/b2b/callcenter-mgmt/agency-details/agency-details.component';
import { LiveChatComponent } from './live-chat/live-chat.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    ComponentsModule, B2bSharedModule
  ],
  declarations: [StatusBarComponent, TopBarComponent,
    MenuComponent, MenuItemComponent, TitleBarComponent,
    FrameworkBodyComponent, ContentComponent, UnauthorizedComponent, SideNavComponent, SideNavItemComponent, HomeCarouselComponent,
    AgencyRequestCreditComponent,
    UserProfileComponent,
    ChangePasswordComponent, RequestCreditSubmittedDialog, RequestCreditPreviewDialog, ErrorComponent, AgencyDetailsComponent,
    LiveChatComponent],
  exports: [
    FrameworkBodyComponent, AgencyRequestCreditComponent
  ],
  entryComponents: [RequestCreditSubmittedDialog, RequestCreditPreviewDialog]
})
export class FwModule {
  // Preventing importing this modules more than once
  // https://angular.io/guide/ngmodule-faq

  constructor(@Optional() @SkipSelf() parentModule: FwModule) {
    if (parentModule) {
      throw new Error(
        'FwModule is already loaded. Import it in the AppModule only');
    }
  }
}
