import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../common/shared/services/auth.service';
import { TopBarService } from '../services/top-bar.service';
import { UserAccountApi } from '../users/user-account-api';
import { UserAlertApi } from '../users/user-alert-api';
import { CONSTANTS } from 'src/app/common/constants';
import { CurrencyApi } from '../currency/currency-api';
import { CurrencyViewModel } from 'src/app/common/viewmodels/currencyviewmodel';
import { MatMenuTrigger } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { Location } from '@angular/common';
import { Utilities } from 'src/app/common/utilities/utilities';
import { FrameworkConfigService } from '../services/framework-config.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  currencyList: CurrencyViewModel[] = [];

  constructor(private _authService: AuthService, public topBarService: TopBarService,
    private router: Router,
    private userAccountApi: UserAccountApi, public userAlertApi: UserAlertApi,
    public currencyApi: CurrencyApi, private location: Location, public userProfileService: UserProfileService,
    public frameworkConfigService: FrameworkConfigService, private configurationService: ConfigurationService
  ) {
    router.events.subscribe((val) => {
      if (location.path()) {
        this.currentRoute = location.path();
      }
    });
  }
  isCredit: boolean;
  superUser = CONSTANTS.userRole.SUPERUSER;
  routeListForCurrencyDropdown = CONSTANTS.routeListForCurrencyDropdown;
  contentUrl = this.configurationService.config.baseUrls.contentUrl;
  RequestCreditAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.RequestCredit);
  CONSTANTS = CONSTANTS;
  currentRoute: string;
  safeStayPoliciesRoute = CONSTANTS.routeList.safeStayPolicies;
  safeStayInfoRoute = CONSTANTS.routeList.safeStayInfo;
  ngOnInit() {

  }

  login() {
      this._authService.login();

  }

  logout() {
    if (this.frameworkConfigService.IsCallCenterUser) {
      this._authService.removeUser();
      window.close();
      window.location.reload();
    } else {
      this.userProfileService.SaveIPAddress('Logout', this._authService.getSubjectId()).then(() => {
        this._authService.logout();
      })
    }
  }

  isLoggedIn() {
    const loggedInStatus = this._authService.isLoggedIn();
   if (loggedInStatus !== undefined &&  loggedInStatus === false
    && !this._authService.isLoggedOut()) {
    this._authService.logout();
   }

    return loggedInStatus;
  }

  changePassword() {
    this.router.navigate(['/authenticated/changepassword']);
  }

  getLatestUnReadAlerts() {
    this.userAlertApi.getLatestUnReadAlerts(CONSTANTS.application.b2b.toUpperCase());
  }

  openalert() {
    this.trigger.openMenu();
    this.getLatestUnReadAlerts();
  }

  onCurrencyChange($event) {
    this.currencyApi.selectedCurrency = $event.value;
    localStorage.setItem('selectedCurrency', $event.value);
    this.topBarService.onCurrencyChange($event.value);
  }
  goToList() {
    this.router.navigate(['/authenticated/alertlist']);
  }
  requestCredit() {
    this.router.navigate(['/authenticated/requestcredit']);
  }
  userProfile() {
    // console.log(this.topBarService.getTopBarItem().role);
    this.router.navigate(['/authenticated/userprofile']);
  }

  isCurrencyDropdown() {
    return (this.routeListForCurrencyDropdown.indexOf(this.location.path()) > -1);
  }

}
