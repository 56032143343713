import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CurrencyApiService } from 'src/app/common/shared/services/currency-api.service';
import { MasterLookupService } from 'src/app/common/shared/services/lookup.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DefaultCurrencyResolverService implements Resolve<string> {

  constructor(private currencyApiService: CurrencyApiService,
    private lookupService: MasterLookupService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<string> {
      if (this.currencyApiService.selectedCurrency !== ''  ) {
        return of(this.currencyApiService.selectedCurrency);
      } else {
        return this.lookupService.getCurrencies().pipe(map(item => {
          if (item.length > 0) {
            if (item.findIndex( val => val.currencyCode.toUpperCase() === 'IDR') > -1) {
              this.currencyApiService.selectedCurrency = 'IDR';
              } else {
              this.currencyApiService.selectedCurrency = item[0].currencyCode;
              }
            return this.currencyApiService.selectedCurrency;
          } else {
            return '';
          }
        }));
      }
  }
}
