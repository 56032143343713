import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { EditPhotoDialogComponent } from './edit-photo-dialog/edit-photo-dialog.component';
import { SharedConfirmDialogComponent } from './shared-confirm-dialog/shared-confirm-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { RoomInfoDialogComponent } from '../../../hotel-mgmt/room-info-dialog/room-info-dialog.component';
import { HotelSearchProgressDialogComponent } from './hotel-search-progress-dialog/hotel-search-progress-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, message: string, showCancel?: boolean, okButtonName?: string, cancelButtonName?: string, showCross?: boolean):
    Observable<boolean> {

    let dialogRef: MatDialogRef<SharedConfirmDialogComponent>;
    dialogRef = this.dialog.open(SharedConfirmDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    if (showCancel === undefined) {
      dialogRef.componentInstance.showCancel = true;
    } else {
      dialogRef.componentInstance.showCancel = showCancel;
    }
    if (showCross === undefined) {
      dialogRef.componentInstance.showCross = true;
    } else {
      dialogRef.componentInstance.showCross = showCross;
    }
    if (okButtonName === undefined) {
      dialogRef.componentInstance.okButtonName = 'Ok';
    } else {
      dialogRef.componentInstance.okButtonName = okButtonName;
    }

    if (cancelButtonName === undefined) {
      dialogRef.componentInstance.cancelButtonName = 'Cancel';
    } else {
      dialogRef.componentInstance.cancelButtonName = cancelButtonName;
    }

    return dialogRef.afterClosed();
  }

  public openPhotoModal(imgPath: string, imgHeight: number, imgWidth: number): Observable<boolean> {
    let dialogRef: MatDialogRef<EditPhotoDialogComponent>;
    dialogRef = this.dialog.open(EditPhotoDialogComponent,
    {
      panelClass: "edit-photo-dialog"
    });
    dialogRef.componentInstance.imgWidth = imgWidth;
    dialogRef.componentInstance.imgHeight = imgHeight;
    return dialogRef.afterClosed();
  }

  public showSpinner(): string {
    let dialogRef: MatDialogRef<SpinnerComponent>;
    dialogRef = this.dialog.open(SpinnerComponent);
    dialogRef.disableClose = true;
    return dialogRef.id;
  }

  public hideSpinner(dialogId: string): void {
    let dialogRef: MatDialogRef<SpinnerComponent>;
    dialogRef = this.dialog.getDialogById(dialogId);
    if (dialogRef !== undefined && dialogRef !== null) {
      dialogRef.close();
    }
  }

  public openRoomInfoModal(title: string, imagelist: any, roomSize: string, beds: string, guest: string, facilities: any, description:
    string, smoking: string): Observable<boolean> {
    let dialogRef: MatDialogRef<RoomInfoDialogComponent>;
    dialogRef = this.dialog.open(RoomInfoDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.imagelist = imagelist;
    dialogRef.componentInstance.roomSize = roomSize;
    dialogRef.componentInstance.beds = beds;
    dialogRef.componentInstance.guest = guest;
    dialogRef.componentInstance.facilities = facilities;
    dialogRef.componentInstance.description = description;
    dialogRef.componentInstance.smoking = smoking;
    return dialogRef.afterClosed();
  }

  public showHotelSearchProgressSpinner(): string {
    let dialogRef: MatDialogRef<HotelSearchProgressDialogComponent>;
    dialogRef = this.dialog.open(HotelSearchProgressDialogComponent);
    dialogRef.disableClose = true;
    return dialogRef.id;
  }

  public hideHotelSearchProgressSpinner(dialogId: string): void {
    let dialogRef: MatDialogRef<HotelSearchProgressDialogComponent>;
    dialogRef = this.dialog.getDialogById(dialogId);
    if (dialogRef !== undefined && dialogRef !== null) {
      dialogRef.close();
    }
  }

  public showErrorPopup(message: string): void {
    let dialogRef: MatDialogRef<ErrorDialogComponent>;
    dialogRef = this.dialog.open(ErrorDialogComponent);
    dialogRef.componentInstance.message = message;
  }
}
