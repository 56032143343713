import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertDataService } from './alert-data.service';
import { CONSTANTS } from '../../constants';
import { TopBarService } from '../../../framework/fw/services/top-bar.service';
import { AlertResponceViewModel } from '../../viewmodels/alerts/alertResponseviewmodel';
import { Observable } from 'rxjs';


@Injectable()
export class AlertDataResolverService implements Resolve<AlertResponceViewModel[]> {

  constructor(private alertDataService: AlertDataService, public topBarService: TopBarService) {
  }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<AlertResponceViewModel[]> {
    const info = this.topBarService.getTopBarItem();

    return this.alertDataService.getAlertDetailsForCurrentApp('B2B');
  }

}
