import { UserProfileService, IUserPermission } from './../../../../common/shared/services/user-profile.service';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/common/shared/services/auth.service';
import { BreadCrumb } from '../components/bread-crumb/bread-crumb.component';
import { HotelSearchService } from './hotel-search.service';
import { HotelDataService } from './hotel-data.service';
import { ReservationDataService } from './reservation-data.service';
import { CONSTANTS } from 'src/app/common/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbService {
  edit = CONSTANTS.operation.edit.toUpperCase();
  create = CONSTANTS.operation.create.toUpperCase();
  read = CONSTANTS.operation.read.toUpperCase();
  _previousRoute: string;
  _Destination: string;

  public set Destination(isHomeRoute: string) {
    this._Destination = isHomeRoute;
  }
  public get Destination(): string {
    return this._Destination;
  }

  public set previousRoute(isHomeRoute: string) {
    this._previousRoute = isHomeRoute;
  }
  public get previousRoute(): string {
    return this._previousRoute;
  }

  reservationId: string; // Booking Id will be used for Confirm Booking Page
  ViewRatesAndPropertiesAccess: IUserPermission;

  constructor(private authService: AuthService,
    private hotelSearchService: HotelSearchService,
    private hotelDataService: HotelDataService,
    private reservationDataService: ReservationDataService,
    private userProfileService: UserProfileService) {
    this.previousRoute = '';
  }

  // If Hotel Search Pannel updated in hotel List or Hotel Details page
  private _isHotelSearchUpdate = new BehaviorSubject<boolean>(false);
  isHotelSearchUpdate = this._isHotelSearchUpdate.asObservable();

  // This will use for Guest Details page, when a pop-up appeared
  // for  booking is not done or booking done and then redirect to Complete booking page or
  // Hotel List Page.
  private isGuestDetailsTransition = new BehaviorSubject<boolean>(false);
  _isGuestDetailsTransition = this.isGuestDetailsTransition.asObservable();
  GuestDetailsTransition(_isGuestDetailsTransition: boolean) {
    this.isGuestDetailsTransition.next(_isGuestDetailsTransition);
  }


  HotelSearchUpdate(isHotelSearchUpdate: boolean) {
    this._isHotelSearchUpdate.next(isHotelSearchUpdate);
  }

  getRouteforBreadcrumb(route: string): string {
    if (this.authService.isLoggedIn()) {
      if (route.toUpperCase() === 'DEALINFO') {
        return 'authenticated/deals/dealinfo';
      }
    }
    return route;
  }

  getBreadcrumbsForRoute(route: string, label = ''): BreadCrumb[] {
    const breadcrumbs: BreadCrumb[] = [];
    const hotelSearch = this.hotelSearchService.destinationSearchDetails;
    this.ViewRatesAndPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewRatesAndProperties);
    if (this.authService.isLoggedIn()) {
      if (route.toUpperCase() === 'DEALINFO') {
        const breadcrumb: BreadCrumb = {
          label: label,
          url: `/authenticated/deals/dealinfo`,
          isClickable: false
        };
        breadcrumbs.push(breadcrumb);
      } else if (route.toUpperCase() === 'HOTELMGMT/HOTELLIST') {
        const countryCity: string[] = hotelSearch.destination.name.split(',');
        this.Destination = countryCity[1];
        if (countryCity.length === 3) {
          breadcrumbs.push({
            label: countryCity[2], url: `/authenticated/hotelmgmt/hotelinfo`,
            isClickable: false
          });
          breadcrumbs.push({
            label: countryCity[1], url: `/authenticated/hotelmgmt/hotelinfo`,
            isClickable: false
          });
        } else {
          breadcrumbs.push({
            label: countryCity[1], url: `/authenticated/hotelmgmt/hotelinfo`,
            isClickable: false
          });
          breadcrumbs.push({
            label: countryCity[0], url: `/authenticated/hotelmgmt/hotelinfo`,
            isClickable: false
          });
        }
      } else if (route.toUpperCase().includes('HOTELMGMT/HOTELDETAILS')) {
        if (this.previousRoute.toLowerCase().indexOf('chaindealinfo') === 0) {
          breadcrumbs.push({
            label: 'Chain Deals', url: `/authenticated/deals/chaindealinfo`,
            isClickable: true
          });
          breadcrumbs.push({
            label: this.hotelDataService.selectedHotel != undefined ? this.hotelDataService.selectedHotel.hotelName : '', url: ``,
            isClickable: false
          });
        } else if (this.previousRoute.toLowerCase().indexOf('dealinfo') >= 0) {
          breadcrumbs.push({
            label: 'Unbeatable Deals', url: `/authenticated/deals/dealinfo`,
            isClickable: true
          });
          breadcrumbs.push({
            label: this.hotelDataService.selectedHotel != undefined ? this.hotelDataService.selectedHotel.hotelName : '', url: ``,
            isClickable: false
          });
        } else {
          const countryCity: string[] = hotelSearch.destination.name.split(',');
          if (hotelSearch.destination.type.toString().toLowerCase() === 'city') {
            breadcrumbs.push({
              label: countryCity[1], url: `/authenticated/hotelmgmt/hotelinfo`,
              isClickable: false
            });
            breadcrumbs.push({
              label: countryCity[0], url: `/authenticated/hotelmgmt/hotellist`,
              isClickable: this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b,
                 CONSTANTS.b2bFeatureTypeId.ViewRatesAndProperties).hasViewAccess
            });
            breadcrumbs.push({
              label: this.hotelDataService.selectedHotel != undefined ? this.hotelDataService.selectedHotel.hotelName : '', url: ``,
              isClickable: false
            });
          } else {
            if (countryCity.length === 3) {
              breadcrumbs.push({
                label: countryCity[2], url: `/authenticated/hotelmgmt/hotellist`,
                isClickable: false
              });
            } // Country
            breadcrumbs.push({
              label: countryCity[1], url: `/authenticated/hotelmgmt/hotellist`,
              isClickable: this.ViewRatesAndPropertiesAccess.hasViewAccess ? true : false
            }); // city
            breadcrumbs.push({
              label: this.hotelDataService.selectedHotel != undefined ? this.hotelDataService.selectedHotel.hotelName : '', url: ``,
              isClickable: false
            });
          }
        }
      } else if (route.toUpperCase() === 'HOTELMGMT/GUESTDETAILS'
        || route.toUpperCase() === 'HOTELMGMT/COMPLETEBOOKING') {
        // Home > Indonesia > Bali > Grand Whiz Nusa Dua
        
        const countryCity: string[] = hotelSearch.destination.name.split(',');
        if ( countryCity.length <= 2) {
        breadcrumbs.push({
          label: countryCity[1], url: `/authenticated/hotelmgmt/hotelinfo`,
          isClickable: false
        });
        breadcrumbs.push({
          label: countryCity[0], url: `/authenticated/hotelmgmt/hotellist`,
          isClickable: this.ViewRatesAndPropertiesAccess.hasViewAccess ? true : false
        });
        breadcrumbs.push({
          label: this.hotelDataService.selectedHotel.hotelName, url: ``,
          isClickable: false
        });
      } else if ( countryCity.length === 3) {
        breadcrumbs.push({
          label: countryCity[2], url: `/authenticated/hotelmgmt/hotelinfo`,
          isClickable: false
        });
        breadcrumbs.push({
          label: countryCity[1], url: `/authenticated/hotelmgmt/hotellist`,
          isClickable: this.ViewRatesAndPropertiesAccess.hasViewAccess ? true : false
        });
        breadcrumbs.push({
          label: countryCity[0], url: `/authenticated/hotelmgmt/hotellist`,
          isClickable: false
        });
      }

        //
      } else if (route.toUpperCase() === 'RESERVATIONLIST') {
        this.reservationId = '';
        breadcrumbs.push({
          label: 'View Bookings', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('RESERVATIONDETAILS') >= 0 ||
        route.toUpperCase().indexOf('INVOICEANDVOUCHERS') >= 0 ||
        route.toUpperCase().indexOf('CANCELSUMMARY') >= 0 ||
        route.toUpperCase().indexOf('EDITBOOKING') >= 0
      ) {
        const bookingId: string[] = route.toUpperCase().split('/');
        breadcrumbs.push({
          label: 'View Bookings', url: `/authenticated/bookingmgmt/reservationList`,
          isClickable: true
        });
        if (route.toUpperCase().indexOf('RESERVATIONDETAILS') >= 0) {
          this.reservationId = bookingId[1];
          breadcrumbs.push({
            label: bookingId[1], url: ``,
            isClickable: false
          });
        } else if (route.toUpperCase().indexOf('CANCELSUMMARY') >= 0) {
          breadcrumbs.push({
            label: this.reservationId, url: ``,
            isClickable: false
          });
        } else {
          breadcrumbs.push({
            label: bookingId[1], url: ``,
            isClickable: false
          });
        }
      } else if (route.toUpperCase() === 'AGENCYPROFILE') {
        breadcrumbs.push({
          label: 'Agency', url: ``,
          isClickable: false
        });
        breadcrumbs.push({
          label: 'Agency Management', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase() === 'BRANCH') {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Branch', url: `/authenticated/agency/agencymgmt/branch`,
          isClickable: false
        });
      } else if (route.toUpperCase() === 'BRANCH/0/' + this.create) {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Branch', url: `/authenticated/agency/agencymgmt/branch`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Create New Branch', url: `/authenticated/agency/agencymgmt/branch`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('BRANCH/') >= 0) {
        if (route.toUpperCase().indexOf(this.edit) >= 0) {
          breadcrumbs.push({
            label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
            isClickable: true
          });
          breadcrumbs.push({
            label: 'Branch', url: `/authenticated/agency/agencymgmt/branch`,
            isClickable: true
          });
          breadcrumbs.push({
            label: 'Edit Branch', url: `/authenticated/agency/agencymgmt/branch`,
            isClickable: false
          });
        }
      } else if (route.toUpperCase() === 'AGENT') {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Agent User', url: `/authenticated/agency/agencymgmt/agent`,
          isClickable: false
        });
      } else if (route.toUpperCase() === 'AGENT/0/' + this.create) {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Agent User', url: `/authenticated/agency/agencymgmt/agent`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Create New Agent User', url: `/authenticated/agency/agencymgmt/agent`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('AGENT/') >= 0) {
        if (route.toUpperCase().indexOf(this.edit) >= 0) {
          breadcrumbs.push({
            label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
            isClickable: true
          });
          breadcrumbs.push({
            label: 'Agent User', url: `/authenticated/agency/agencymgmt/agent`,
            isClickable: true
          });
          breadcrumbs.push({
            label: 'Edit Agent User', url: `/authenticated/agency/agencymgmt/agent`,
            isClickable: false
          });
        }
      } else if (route.toUpperCase() === 'AGENCYBANKDETAILS') {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Bank Details', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase() === 'ROLESANDPERMISSIONS') {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/agency/agencymgmt/agencyprofile`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Roles & Permissions', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase() === 'BOOKINGREPORT') {
        breadcrumbs.push({
          label: 'Reports', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase() === 'CANCELSUMMARY') {
        breadcrumbs.push({
          label: 'Booking Cancel', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase() === 'BRANCHMARKUP') {
        breadcrumbs.push({
          label: 'Agency', url: ``,
          isClickable: false
        });
        breadcrumbs.push({
          label: 'Agency Markup', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('BRANCHMARKUP') >= 0) {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Branch Markup', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        // Create // Edit // View
        if (route.toUpperCase().indexOf('CREATE') >= 0) {
          breadcrumbs.push({
            label: 'Add New Branch Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('EDIT') >= 0) {
          breadcrumbs.push({
            label: 'Edit Branch Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('READ') >= 0) {
          breadcrumbs.push({
            label: 'View Branch Markup', url: ``,
            isClickable: false
          });
        }

      } else if (route.toUpperCase() === 'COUNTRYMARKUP') {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Destination Country Markup', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('COUNTRYMARKUP') >= 0) {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Destination Country Markup', url: `/authenticated/markup/markupmgmt/countrymarkup`,
          isClickable: true
        });
        // Create // Edit // View
        if (route.toUpperCase().indexOf('CREATE') >= 0) {
          breadcrumbs.push({
            label: 'Add New Destination Country Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('EDIT') >= 0) {
          breadcrumbs.push({
            label: 'Edit Destination Country Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('READ') >= 0) {
          breadcrumbs.push({
            label: 'View Destination Country Markup', url: ``,
            isClickable: false
          });
        }

      } else if (route.toUpperCase() === 'CITYMARKUP') {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Destination City Markup', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('CITYMARKUP') >= 0) {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Destination City Markup', url: `/authenticated/markup/markupmgmt/citymarkup`,
          isClickable: true
        });
        // Create // Edit // View
        if (route.toUpperCase().indexOf('CREATE') >= 0) {
          breadcrumbs.push({
            label: 'Add New Destination City Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('EDIT') >= 0) {
          breadcrumbs.push({
            label: 'Edit Destination City Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('READ') >= 0) {
          breadcrumbs.push({
            label: 'View Destination City Markup', url: ``,
            isClickable: false
          });
        }

      } else if (route.toUpperCase() === 'HOTELMARKUP') {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Hotel Markup', url: ``,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('HOTELMARKUP') >= 0) {
        breadcrumbs.push({
          label: 'Agency', url: `/authenticated/markup/markupmgmt/branchmarkup`,
          isClickable: true
        });
        breadcrumbs.push({
          label: 'Hotel Markup', url: `/authenticated/markup/markupmgmt/hotelmarkup`,
          isClickable: true
        });
        // Create // Edit // View
        if (route.toUpperCase().indexOf('CREATE') >= 0) {
          breadcrumbs.push({
            label: 'Add New Hotel Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('EDIT') >= 0) {
          breadcrumbs.push({
            label: 'Edit Hotel Markup', url: ``,
            isClickable: false
          });
        }
        if (route.toUpperCase().indexOf('READ') >= 0) {
          breadcrumbs.push({
            label: 'View Hotel Markup', url: ``,
            isClickable: false
          });
        }
      } else if (route.toUpperCase().indexOf('REQUESTCREDIT') >= 0) {
        breadcrumbs.push({
          label: 'Request Credit', url: `/authenticated/requestcredit`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('CHANGEPASSWORD') >= 0) {
        breadcrumbs.push({
          label: 'Change Password', url: `/authenticated/changepassword`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('USERPROFILE') >= 0) {
        breadcrumbs.push({
          label: 'User Profile', url: `/authenticated/userprofile`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('GROUPBOOKING') >= 0) {
        breadcrumbs.push({
          label: 'Group Booking', url: `/siteinfomgmt/groupbooking`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('Registration') >= 0) {
        breadcrumbs.push({
          label: 'Register Now', url: `/siteinfomgmt/groupbooking`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('SYSTEMCHECK') >= 0) {
        breadcrumbs.push({
          label: 'System Check', url: `/siteinfomgmt/systemcheck`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('FAQ') >= 0) {
        breadcrumbs.push({
          label: 'FAQ', url: `faq`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('TERMSANDCONDITIONS') >= 0) {
        breadcrumbs.push({
          label: 'Terms & Conditions', url: `/siteinfomgmt/termsandconditions`,
          isClickable: false
        });
      }
    } else {
      if (route.toUpperCase() === 'DEALINFO') {
        const breadcrumb: BreadCrumb = {
          label: label,
          url: `/authenticated/deals/dealinfo`,
          isClickable: false
        };
        breadcrumbs.push(breadcrumb);
      } else if (route.toUpperCase().indexOf('REGISTRATION') >= 0) {
        breadcrumbs.push({
          label: 'Register Now', url: `/siteinfomgmt/groupbooking`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('FAQ') >= 0) {
        breadcrumbs.push({
          label: 'FAQ', url: `faq`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('CONTACT') >= 0) {
        breadcrumbs.push({
          label: 'Contact', url: `/siteinfomgmt/contact`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('ABOUT') >= 0) {
        breadcrumbs.push({
          label: 'MG', url: `/siteinfomgmt/about`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('TERMSANDCONDITIONS') >= 0) {
        breadcrumbs.push({
          label: 'Terms & Conditions', url: `/siteinfomgmt/termsandconditions`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('GROUPBOOKING') >= 0) {
        breadcrumbs.push({
          label: 'Group Booking', url: `/siteinfomgmt/groupbooking`,
          isClickable: false
        });
      } else if (route.toUpperCase().indexOf('SYSTEMCHECK') >= 0) {
        breadcrumbs.push({
          label: 'System Check', url: `/siteinfomgmt/systemcheck`,
          isClickable: false
        });
      }
    }
    this.previousRoute = route;
    return breadcrumbs;
  }
}
