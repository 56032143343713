import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AgencyViewModel } from '../../viewmodels/agency-mgmt/agencyviewmodel';
import { ErrorMessages } from 'src/app/common/errormessage';
import { BranchViewModel } from '../../viewmodels/agency-mgmt/branchviewmodel';
import { JarvisError } from 'src/app/common/jarviserror';
import { Subscription, Observable } from 'rxjs';
import { CallcenterService } from '../../common/b2b-shared/services/callcenter.service';
import { AgentViewModel } from '../../viewmodels/agency-mgmt/agentviewmodel';
import { CurrencyViewModel } from 'src/app/common/viewmodels/currencyviewmodel';
import { AgencySearchInfoSyncValidators } from './agency-search-info.validator';
import { UserInfoViewModel } from 'src/app/common/viewmodels/userinfoviewmodel';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/common/shared/services/auth.service';
import { BoAuth } from '../../viewmodels/callcenter/boauthviewmodel';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { map, startWith } from 'rxjs/operators';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { CONSTANTS } from 'src/app/common/constants';

@Component({
  selector: 'app-agency-search-info',
  templateUrl: './agency-search-info.component.html',
  styleUrls: ['./agency-search-info.component.css']
})
export class AgencySearchInfoComponent implements OnInit, OnDestroy {
  agencySearchForm: FormGroup;
  agencyList: AgencyViewModel[] = [];
  branchList: BranchViewModel[] = [];
  agentList: AgentViewModel[] = [];
  subscriptions: Subscription[] = [];
  currencyList: CurrencyViewModel[];
  userDetails = new UserInfoViewModel();
  filteredBranchOptions: Observable<BranchViewModel[]>;
  filteredAgentOptions: Observable<AgentViewModel[]>;
  isDisabled = true;
  agencyId: number;
  branchId: number;
  isCredit: boolean;
  isCashTopUp: boolean;
  isNonCredit: boolean;
  isAgencySelected: boolean;
  agencyNameCode: string;
  ErrorMessages = ErrorMessages;
  isActiveEPayment: boolean;
  isActiveTransferMoney: boolean;
  isQRIS: boolean;
  isBankTranfer: boolean;

  constructor(
    private callcenterDataService: CallcenterService,
    private router: Router,
    private userProfileService: UserProfileService,
    private authService: AuthService,
    private frameworkConfigService: FrameworkConfigService,
    private currencyApi: CurrencyApi,
    private topBarService: TopBarService
  ) { }

  ngOnInit() {
    this.frameworkConfigService.ShowAgencyDetails = false;
    this.agencyNameCode = this.frameworkConfigService.AgencyNameCode;
    this.frameworkConfigService.AgencyNameCode = '';
    this.frameworkConfigService.ShowStatusBar = false;
    this.frameworkConfigService.ShowTopBar = false;
    this.frameworkConfigService.ShowBanner = false;
    this.frameworkConfigService.ShowMenu = false;
    this.frameworkConfigService.IsCallCenterUser = true;
    this.agencySearchForm = new FormGroup({
      agency: new FormControl('', { validators: [AgencySearchInfoSyncValidators.agencyValidator],
        updateOn: 'change'
      }),
      branch: new FormControl('' , { validators: [AgencySearchInfoSyncValidators.agencyBranchValidator],
        updateOn: 'change'
      }),
      agent: new FormControl('', { validators: [AgencySearchInfoSyncValidators.agentValidator],
        updateOn: 'change'
      }),
      currencyId: new FormControl('', Validators.required)
    });

    this.agencySearchForm.get('branch').disable();
    this.agencySearchForm.get('agent').disable();
    this.agencySearchForm.get('currencyId').disable();

    this.agencySearchForm.get('agency').valueChanges.subscribe(data => {
      this.agencyList = [];
      if ((this.agencySearchForm.get('agency').value) !== '') {
        this.callcenterDataService.getAgencyNameAndCode(data, true).subscribe(agencyResult => {
          this.agencyList = agencyResult;
        });
    } else {
      this.agencyList = null;
    }
    });
  }


  displayFn(val: AgencyViewModel) {
    return val ? val.name + ' - ' + val.code : val;
  }

  onAgencySelectionChange(id) {
    this.isCredit = false;
    this.isCashTopUp = false;
    this.isActiveEPayment = false;
    this.isQRIS = false;
    this.isBankTranfer = false;
    this.isActiveTransferMoney = false;
    if (!this.agencySearchForm.get('branch').disabled) {
      this.agencySearchForm.get('branch').reset();
      this.agencySearchForm.get('branch').disable();
    }
    if (!this.agencySearchForm.get('agent').disabled) {
      this.agencySearchForm.get('agent').reset();
      this.agencySearchForm.get('agent').disable();
    }

    this.agencyId = id;
    if (this.agencySearchForm.controls.agency.markAsTouched) {
      this.agencySearchForm.get('branch').enable();
      this.agencySearchForm.get('branch').setValue('');
      this.agencySearchForm.get('currencyId').enable();
      this.getCurrency(id);
      this.getBranches(id);
    }
    this.userDetails.agencyId = this.agencyId;

  }

  onCurrencyChange(event?) {
      if (event !== null && event !== undefined) {
        const currencyCode = event.source.triggerValue.split('-')[0];
        this.setCurrencyCode(currencyCode.trim());
      } else {
        this.userDetails.agencyDefaultCurrencyId = this.agencySearchForm.get('currencyId').value;
        const code = this.currencyList.filter(x => x.currencyId === (this.agencySearchForm.get('currencyId').value))[0].currencyCode;
        this.currencyApi.defaultCurrency = code;
        this.setCurrencyCode(code);
        this.userProfileService.setAgentInfo(this.userDetails);
        this.callcenterDataService.getPaymentType(this.agencyId).subscribe(result => {
            this.isCredit = result[0].isCredit;
            this.isCashTopUp = result[0].isCashTopUp;
            this.isNonCredit = result[0].isNonCredit;
            this.isActiveEPayment = result[0].isActiveEPayment;
            this.isActiveTransferMoney = result[0].isActiveTransferMoney;
            this.isQRIS = result[0].isQRIS;
            this.isBankTranfer = result[0].isBankTranfer;
        });
      }
  }

  setCurrencyCode(code) {
    this.currencyApi.selectedCurrency = code;
    localStorage.setItem('selectedCurrency', code);
    this.topBarService.onCurrencyChange(code);
  }

  resetAgencyChange() {
    if (this.agencySearchForm.get('agency').value === null || this.agencySearchForm.get('agency').value === '') {
      this.reset();
      this.agencySearchForm.get('agency').markAsTouched();
         this.isDisabled = true;
    } else {
         this.isDisabled = false;
    }
  }

  // Branch

  getBranches(agencyId) {
    this.branchList.splice(0, this.branchList.length);
    if (agencyId !== null) {
      this.callcenterDataService.getAgencyBranches(agencyId).subscribe((data) => {
        this.branchList = data;
        this.setFilteredBranchOptions();
      },
      (error: JarvisError) => console.error(`Error in Getting UM-AgentUser-Info-getBranches(agencyId). ${error.friendlyMessage}`));
      // this.subscriptions.push(subscription);
     }
  }

  setFilteredBranchOptions() {
    this.filteredBranchOptions = this.agencySearchForm.controls.branch.valueChanges
                                  .pipe(startWith(''), map(val => this.branchList.length > 0
                                                                  ? this.filterBranch(val)
                                                                  : []));
  }

  filterBranch(val: string): BranchViewModel[] {
    if (this.branchList.length > 0 && val !== null) {
      return this.branchList.filter(option => option.name.toString().toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
    } else {
      return [];
    }
  }

  displayBranchFn(val: BranchViewModel) {
    return val ? val.name : val ;
  }

  resetBranchChange() {
    if (this.agencySearchForm.get('branch').value === null || this.agencySearchForm.get('branch').value === '') {
      this.isDisabled = true;
    } else {
       this.isDisabled = false;
    }
  }

  onBranchSelectionChange(branchId) {
    this.branchId = branchId;
    if (this.agencySearchForm.controls.branch.markAsTouched) {
      this.agencySearchForm.get('agent').enable();
      this.agencySearchForm.get('agent').setValue('');
      this.getAgents(branchId);
    }
  }

  // agent

  getAgents(branchId) {
    this.agencyList.splice(0, this.agentList.length);
    if (branchId !== null) {
      this.callcenterDataService.getAgentName(this.agencyId, branchId)
      .subscribe((data) => {
        this.agentList = data;
        this.setFilteredAgentOptions();
      },
      (error: JarvisError) => console.error(`Error in Getting UM-AgentUser-Info-getAgents(agencyId). ${error.friendlyMessage}`));
      // this.subscriptions.push(subscription);
     }
  }

  setFilteredAgentOptions() {
    this.filteredAgentOptions = this.agencySearchForm.controls.agent.valueChanges
                                  .pipe(startWith(''), map(val => this.agentList.length > 0
                                                                 ? this.filterAgent(val)
                                                                  : []));
  }

  filterAgent(val: string): AgentViewModel[] {
    if (this.agentList.length > 0 && val !== null) {
      return this.agentList.filter(option => option.name.toString().toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
    } else {
      return [];
    }
  }

  displayAgentFn(val: AgentViewModel) {
    return val ? val.name : val ;
  }

  resetAgentChange() {
    if (this.agencySearchForm.get('agent').value === null || this.agencySearchForm.get('agent').value === '') {
       this.isDisabled = true;
    } else {
       this.isDisabled = false;
    }
  }

  onAgentSelectionChange() {
    BoAuth.agentId = this.userDetails.agentId = this.agencySearchForm.get('agent').value.userId;
    this.userProfileService.setAgentInfo(this.userDetails);
  }

  getCurrency(id) {
    const subscription = this.callcenterDataService.getCurrencyByAgencyId(id).subscribe(
      (data) => {
        if (data !== null) {
          this.currencyList = data;
          this.GetAgencyDefaultCurrencyById(id);
        }
      },
      (error: JarvisError) => console.error(`Error in Create RM-Definemarkup-Info-getSupplierById. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  GetAgencyDefaultCurrencyById(id: number) {
    const subscription = this.callcenterDataService.GetAgencyDefaultCurrencyById(id).subscribe(
      (data) => {
        if (data !== null) {
          this.agencySearchForm.get('currencyId').setValue(data);
          this.onCurrencyChange();
        }
      },
      (error: JarvisError) => console.error(`Error in Create RM-Definemarkup-Info-getSupplierById. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  reset() {
    this.agencySearchForm.reset();
    this.isDisabled = true;
    this.isCashTopUp = false;
    this.isCredit = false;
    this.isNonCredit = false;
    this.isActiveTransferMoney = false;
    this.isActiveEPayment = false;
    this.isQRIS = false;
    this.isBankTranfer = false;
    this.agencySearchForm.get('branch').disable();
    this.agencySearchForm.get('agent').disable();
    this.agencySearchForm.get('currencyId').disable();
  }

  next() {
        this.userDetails.userName = this.agencySearchForm.get('agent').value.userName;
        this.userDetails.agencyId = this.agencySearchForm.get('agency').value.id;
        this.userDetails.agencyNationalityId = this.agencySearchForm.get('agency').value.countryId;
        BoAuth.branchId = this.userDetails.branchId = this.agencySearchForm.get('branch').value.id;
        BoAuth.agencyCode = this.userDetails.userName.split('_')[0];
        this.userDetails.isCredit = this.isCredit;
        this.userDetails.isSuperUser =  this.agencySearchForm.get('agent').value.isSuperUser;
        this.userProfileService.setAgentInfo(this.userDetails);
        // tslint:disable-next-line:max-line-length
        this.frameworkConfigService.AgencyNameCode = (`${this.agencySearchForm.get('agency').value.name} - ${this.agencySearchForm.get('agency').value.code}`);
        this.agencyNameCode = this.frameworkConfigService.AgencyNameCode;
        this.frameworkConfigService.ShowAgencyDetails = true;
        this.frameworkConfigService.ShowMenu = true;
        this.callcenterDataService.sendBranchData(this.agencySearchForm.get('branch').value);
        this.router.navigate(['/authenticated']);
  }


  getValidationMessage(controlName: string) {
    if (this.agencySearchForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.agencySearchForm.get(controlName).hasError('validAgency')) {
      return ErrorMessages.agencyMessage;
    } else if (this.agencySearchForm.get(controlName).hasError('validAgencyBranch')) {
      return ErrorMessages.branchMessage;
    } else if (this.agencySearchForm.get(controlName).hasError('validAgent')) {
      return ErrorMessages.agentMessage;
    } else {
      return '';
    }
  }

  hasErrors(controlName: string) {
      return (this.agencySearchForm.get(controlName).dirty || this.agencySearchForm.get(controlName).touched) &&
      this.agencySearchForm.get(controlName).errors !== null;
  }

  ngOnDestroy() {
    this.frameworkConfigService.ShowAgencyDetails = true;
    this.frameworkConfigService.ShowMenu = true;
    this.frameworkConfigService.AgencyNameCode = this.agencyNameCode;
  }

}
