import { Injectable } from '@angular/core';
import { UserAlertApi } from '../../../framework/fw/users/user-alert-api';
import { Observable , of} from 'rxjs';
import { AlertResponceViewModel } from '../../viewmodels/alerts/alertResponseviewmodel';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { ConfigurationService } from './configuration.service';
import {map} from 'rxjs/operators';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AlertDataService implements UserAlertApi  {
  alertCount$: Observable<number>;
  alertDetails$: Observable<AlertResponceViewModel[]>;

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
               private configurationService: ConfigurationService) { }
  InitializeAlertData() {
    this.getAlertCountForCurrentApp(CONSTANTS.application.b2b.toUpperCase());
    this.getAlertDetailsForCurrentApp(CONSTANTS.application.b2b.toUpperCase());
  }

  getAlertCountForCurrentApp(app: string): Observable<number> {
    return this.alertCount$ = this.authenticatedHttpService.post
    (this.configurationService.config.baseUrls.extranetApi,
      { ApplicationType: app }, 'api/Alerts/GetUnreadAlertsCount/')
      .pipe(
          map((data: any) => data ? data.result : Observable.create(0)));
  }

  getAlertDetailsForCurrentApp(app: string): Observable<AlertResponceViewModel[]> {
    return this.alertDetails$ = this.authenticatedHttpService.post
    (this.configurationService.config.baseUrls.extranetApi
      , { ApplicationType: app }, 'api/Alerts/GetAlerts/')
      .pipe(
        map((data: any) => data ? data.result :  [] ));
  }

  markMessagesAsRead() {

  }

  getLatestUnReadAlerts(app: string): Observable<AlertResponceViewModel[]> {
    return this.alertDetails$ = this.authenticatedHttpService.post
    (this.configurationService.config.baseUrls.extranetApi
      , { ApplicationType: app }, 'api/Alerts/GetLatestUnreadAlerts/')
      .pipe(
        map((data: any) => data ? data.result :  [] ));
  }
}
