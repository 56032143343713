import { Router, ActivatedRoute } from '@angular/router';
import { UserProfileService } from './../../../common/shared/services/user-profile.service';
import { Component, OnInit, NgZone, OnDestroy, OnChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { MapsdialogComponent } from '../../common/b2b-shared/dialogs/mapsdialog/mapsdialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { HotelDetailsViewModel } from '../../viewmodels/hotel-mgmt/hoteldetailsviewmodel';
import { HotelSearchService } from '../../common/b2b-shared/services/hotel-search.service';
import { JarvisError } from '../../../common/jarviserror';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { RoomCategoryInfoViewModel, HotelViewModel } from '../../viewmodels/hotel-mgmt/hotelviewmodel';
import { CONSTANTS } from 'src/app/common/constants';
import { HotelSummaryMapViewModel } from '../../viewmodels/hotel-mgmt/hotelsummarymapviewmodel';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { Utilities } from 'src/app/common/utilities/utilities';
import { errorConstants } from 'src/app/common/friendlymessage';
import { MessageDialogComponent } from '../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { SearchEventNotification } from '../../viewmodels/push-notification/searcheventnotifications';
import { PushNotificationService } from 'src/app/common/shared/services/pushnotification.service';
import { FrameworkConfigService } from 'src/app/framework/fw/services/framework-config.service';
import { DestinationViewModel } from '../../common/b2b-shared/viewmodels/hotel-search-panel/hotelsearchpanelviewmodel';
import { HotelSearchComponent } from '../../common/b2b-shared/components/hotel-search/hotel-search.component';
import { AgencyProfileDataService } from '../../common/b2b-shared/services/agency-profile-data.service';
import { ScreenService } from '../../../framework/fw/services/screen.service';
import { ConfigurationService } from '../../../common/shared/services/configuration.service';
import { MGFPointsConverter } from '../../../common/viewmodels/appsettingsviewmodel';
import { TopBarService } from '../../../framework/fw/services/top-bar.service';

@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.css']
})
export class HotelDetailsComponent implements OnInit, OnDestroy, OnChanges {

  constructor(public hotelDataService: HotelDataService,
    private zone: NgZone,
    public dialog: MatDialog,
    public hotelSearchService: HotelSearchService,
    public currencyApi: CurrencyApi,
    private dialogsService: DialogsService,
    private userProfileService: UserProfileService,
    public frameworkConfigService: FrameworkConfigService,
    public notificationService: PushNotificationService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private agencyProfileDataService: AgencyProfileDataService,
    public screenService: ScreenService,
    private configservice: ConfigurationService,
    private topBarService: TopBarService
  ) {
    const subscriptionSearchChange = this.hotelSearchService.hotelSearchChange$.
      subscribe((searchCriteria) => this.updateHotelSearchResults(searchCriteria));
    this.subscriptions.push(subscriptionSearchChange);
  }
  hotelDetails: HotelDetailsViewModel;
  imagelist: any[] = [];
  roomDetails: RoomCategoryInfoViewModel;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  bedFree = CONSTANTS.policies.bedFree;
  bedPaid = CONSTANTS.policies.bedPaid;
  extrabedAvailable = CONSTANTS.policies.messages.extraBedAvailable;
  noExtraBed = CONSTANTS.policies.messages.noExtraBed;
  cotCapacityAvailabe = CONSTANTS.policies.messages.cotCapacityAvailable;
  noCotCapacity = CONSTANTS.policies.messages.noCotCapacity;
  free = CONSTANTS.policies.messages.Free;
  paid = CONSTANTS.policies.messages.Paid;
  reserveParking = CONSTANTS.faclities.reserveParking;
  petsAllow = CONSTANTS.faclities.messages.petsAllowed;
  noPets = CONSTANTS.faclities.messages.noPets;
  guestParkingAvailable = CONSTANTS.faclities.parkingAvailable;
  parkingAvail = CONSTANTS.faclities.messages.parkingAvailable;
  noParking = CONSTANTS.faclities.messages.noParking;
  parking = CONSTANTS.faclities.parking;
  reservedParkingMessage = CONSTANTS.faclities.messages.reservedParking;
  pets = CONSTANTS.faclities.pets;
  checkIn: Date;
  checkOut: Date;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  subscriptions: Subscription[] = [];
  activeSection: string;
  @ViewChild('aboutUs') aboutUs: ElementRef;
  @ViewChild('roomType') roomType: ElementRef;
  @ViewChild('hotelFacilities') facilities: ElementRef;
  @ViewChild('hotelPolicies') policies: ElementRef;
  @ViewChild(HotelSearchComponent) childHotelSearch: HotelSearchComponent;
  showMgValidity: boolean;
  isRoomLoaded: boolean;
  ViewRatesAndPropertiesAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.ViewRatesAndProperties);
  hotelDetailsXmlError = errorConstants.hotelDetailsErrorMessage;
  errorCode: string;
  errorMessage: string;
  safeStayPolicy = CONSTANTS.SafeStayPolicy;
  isVaccinatedClicked = false;
  isMGPointApplicable: any;
  mgPointsForHotel: number;
  private updateSubscription: Subscription;
  mGFPointsConverter: MGFPointsConverter;
  exchangeRate: number;

  ngOnInit() {
    this.updateSubscription = this.agencyProfileDataService.updateObservable$.subscribe(() => {
      this.getAgencyData(this.agencyId);
    });
    this.showMgValidity = true;
    this.getAgencyData(this.agencyId);
    this.getHotelDetails();
    this.checkLocalStorageAndSetData();
    this.mGFPointsConverter = this.configservice.config.mgfPointsConverter;
}

  getAgencyData(agencyId: number) {
    const subscription = this.agencyProfileDataService.getById(agencyId).subscribe(data => {
      const agency = data[0];
      this.agencyProfileDataService.isMGPointApplicable = agency.mgAgency.isMGPointApplicable;
      this.isMGPointApplicable = agency.mgAgency.isMGPointApplicable;
    },);
    this.subscriptions.push(subscription);
  }
  openSafeStayPolicy() {
    localStorage.setItem('isVaccinatedClicked', JSON.stringify(this.isVaccinatedClicked));
    this.router.navigate([]).then(result => { window.open('siteinfomgmt/safestaypolicies', '_blank'); });
  }

  ngOnChanges(): void {
    this.checkLocalStorageAndSetData();
    this.getHotelDetails();
  }

  @HostListener('document:click')
  clickout() {
    this.showMgValidity = false;
  }

  getHotelDetails() {
    // this.isMGPointApplicable = this.agencyProfileDataService.isMGPointApplicable;
    // console.log(`in hotel details api is mg applicable:`, this.isMGPointApplicable);
    // this.hotelDetails = this.hotelDataService.selectedHotel;
    this.roomDetails = null;
    this.activeSection = 'about-us';
    // tslint:disable-next-line:max-line-length
    if ((this.hotelDataService.selectedHotel && this.hotelDataService.selectedHotel.hotelId) || localStorage.getItem('selectedHotelForGet')) {
      this.showSpinner();
      const subscription = this.hotelDataService.getHotelDetails().subscribe((hotelData) => {
        if (hotelData) {
          // tslint:disable-next-line: max-line-length
          if (!Utilities.isNullOrUndefined(hotelData.errorModel) && hotelData.errorModel.xmlErrorCode !== null && hotelData.errorModel.errorCode === 200) {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Message';
            this.errorMessage = hotelData.errorModel.message;
            let filterData = this.hotelDetailsXmlError.filter(error => {
              if (hotelData.errorModel.xmlErrorCode === error.code) {
                this.errorMessage = error.message;
              }
            });
            filterData = {}[''];
            dialogRef.componentInstance.message = this.errorMessage;
            dialogRef.componentInstance.buttonText = 'OK';
          } else {
            this.showMgValidity = true;
            // this.hotelSearchService.destinationSearchDetails.destination.hotelCode = hotelData.hotelCode;
            if (!Utilities.isNullOrEmpty(this.activatedRoute.snapshot.params.hotelcode)) {
              this.hotelSearchService.destinationSearchDetails.destination = <DestinationViewModel>{};
              this.hotelSearchService.destinationSearchDetails.destination.cityCode = hotelData.address.cityCode;
              this.hotelSearchService.destinationSearchDetails.destination.countryCode = hotelData.address.countryCode;
              this.hotelSearchService.destinationSearchDetails.destination.hotelCode = hotelData.hotelCode;
              this.hotelSearchService.destinationSearchDetails.destination.type = 'Hotel';
              this.hotelSearchService.destinationSearchDetails.destination.name = hotelData.name + ', ' + hotelData.address.cityName + ', ' + hotelData.address.countryName;
              localStorage.setItem('hotelSearchPanelArray', JSON.stringify(this.hotelSearchService.destinationSearchDetails));
              this.hotelDataService.selectedHotel.hotelName = hotelData.name;
              this.childHotelSearch.getHotelSearchDetails();
              this.childHotelSearch.totalNoOfAdults = 0;
              this.childHotelSearch.totalNoOfChildren = 0;
              this.hotelSearchService.destinationSearchDetails.roomDetails.forEach(room => {
                this.childHotelSearch.totalNoOfAdults += room.noOfAdults;
                this.childHotelSearch.totalNoOfChildren += room.noOfChildren;
              });
              this.childHotelSearch.breadCrumbService.HotelSearchUpdate(true);
            }
            this.getRoomDetails(hotelData.hotelCode);
            if (!(Utilities.isNullOrUndefined(hotelData.photos)) &&
              !(Utilities.isNullOrEmptyArray(hotelData.photos))) {
              this.imagelist = [];
              hotelData.photos.forEach((element, index) => {
                this.imagelist.push({
                  source: element.url,
                  title: '',
                  alt: ''
                });
              });
            } else {
              this.imagelist = [];
            }
            this.hotelDetails = hotelData;
            this.exchangeRate = this.topBarService._exchangeRate ? this.topBarService._exchangeRate : +localStorage.getItem('exchangeRate');
          }
        }
      },
        (error: JarvisError) => {
          this.hideSpinner();
          console.error(`Error in Getting HotelDetails. ${error.friendlyMessage}`);
        },
        () => {
          this.hideSpinner();
        });
      this.subscriptions.push(subscription);
    } else {
      this.router.navigate(['/authenticated/hotelmgmt/hotelinfo']);
    }
  }

  sendSearchEventNotifications(hotelCode: string) {
    const model = new SearchEventNotification();
    model.cityCode = this.hotelSearchService.destinationSearchDetails.destination.cityCode;
    model.hotelCode = hotelCode;
    model.checkInDate = this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0];
    const subscription = this.notificationService.sendSearchEventNotifications(model).subscribe();
    this.subscriptions.push(subscription);
  }

  getRoomDetails(hotelCode: string) {
    // this.hotelDetails = this.hotelDataService.selectedHotel;
    this.isRoomLoaded = false;
    if (!this.frameworkConfigService.IsCallCenterUser &&
      (Utilities.isNullOrEmpty(this.activatedRoute.snapshot.params) || this.activatedRoute.snapshot.params.hotelcode !== this.hotelDataService.selectedHotel.hotelId)) {
      this.sendSearchEventNotifications(hotelCode);
    }
    const subscription = this.hotelDataService.getRoomDetails(this.hotelSearchService.destinationSearchDetails, hotelCode)
      .subscribe((roomData) => {
        if (roomData) {
          this.roomDetails = roomData;
          this.mgPointsForHotel = JSON.parse(localStorage.getItem('selectedHotelForGet')).mgPoints;
          this.isRoomLoaded = true;
        } else {
          this.roomDetails = null;
        }
      },
        (error: JarvisError) => {
          this.isRoomLoaded = true;
          this.roomDetails = <RoomCategoryInfoViewModel>{};
          // console.error(`Error in Getting Room Details. ${error.friendlyMessage}`);
        },
        () => {
          /* this.hideSpinner(); */
          this.isRoomLoaded = true;
        });
    this.subscriptions.push(subscription);
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  openMap(mapGLat, mapGLng): void {
    let hotel: HotelViewModel;
    hotel = this.hotelDataService.selectedHotel;
    const hotelSummary = new HotelSummaryMapViewModel();
    hotelSummary.hotelId = this.hotelDetails.hotelCode;
    hotelSummary.hotelName = this.hotelDetails.name;
    let completeAdress = '';
    completeAdress = this.hotelDetails.address.address1 ? this.hotelDetails.address.address1 : completeAdress;
    completeAdress = this.hotelDetails.address.address2 ? completeAdress + ', ' + this.hotelDetails.address.address2 : completeAdress;
    completeAdress = this.hotelDetails.address.area ? completeAdress + ', ' + this.hotelDetails.address.area : completeAdress;
    completeAdress = this.hotelDetails.address.landmark ? completeAdress + ', ' + this.hotelDetails.address.landmark : completeAdress;
    completeAdress = this.hotelDetails.address.cityName ? completeAdress + ', ' + this.hotelDetails.address.cityName : completeAdress;
    completeAdress = this.hotelDetails.address.zipCode ? completeAdress + ', ' + this.hotelDetails.address.zipCode : completeAdress;
    completeAdress = this.hotelDetails.address.countryName ? completeAdress + ', ' + this.hotelDetails.address.countryName : completeAdress;
    hotelSummary.address = completeAdress;
    if (hotel.facilities && hotel.grossPrice) {
      hotelSummary.facilities = hotel.facilities;
      hotelSummary.grossPrice = hotel.grossPrice;
    }
    hotelSummary.starRating = this.hotelDetails.rating;
    hotelSummary.currency = this.currencyApi.selectedCurrency;
    hotelSummary.latitude = this.hotelDetails.geoLocation.latitude;
    hotelSummary.longitude = this.hotelDetails.geoLocation.longitude;
    hotelSummary.isMgPreferred = this.hotelDetails.isMGPreferred;
    hotelSummary.image = (this.hotelDetails.photos && this.hotelDetails.photos.length > 0) ?
      this.hotelDetails.photos[0].url : null;
    this.zone.run(() => {
      this.dialog.open(MapsdialogComponent, {
        width: '700px',
        height: '560px',
        data: { hotel: hotelSummary }
      }).afterClosed().subscribe(result => {
        const retResult = `${result}`;
        if (retResult !== 'undefined' && retResult !== 'unselected') {
          const parseresult = JSON.parse(result);
        } else {
          if (mapGLat === null || mapGLng === null) {
            mapGLat = 0;
            mapGLng = 0;
          }
        }
      });
    });
  }

  checkLocalStorageAndSetData() {
    const hotelCode = this.activatedRoute.snapshot.params.hotelcode;
    if (!Utilities.isNullOrEmpty(hotelCode)) {
      this.hotelDataService.selectedHotel = new HotelViewModel();
      this.hotelDataService.selectedHotel.hotelId = hotelCode;
    }
    else if (!this.hotelDataService.selectedHotel && localStorage.getItem('selectedHotel')) {
      const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
      this.hotelDataService.selectedHotel = selectedHotel;
    }

    if ((Object.keys(this.hotelSearchService.destinationSearchDetails).length === 0 &&
      localStorage.getItem('hotelSearchPanelArray') &&
      this.hotelSearchService.destinationSearchDetails !== JSON.parse(localStorage.getItem('hotelSearchPanelArray'))) ||
      (!this.hotelSearchService.destinationSearchDetails && localStorage.getItem('hotelSearchPanelArray'))) {
      // Set search panel details
      this.hotelSearchService.destinationSearchDetails = JSON.parse(localStorage.getItem('hotelSearchPanelArray'));
      this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0] =
        new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0]);
      this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1] =
        new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1]);
    }

    if ((this.currencyApi.selectedCurrency && localStorage.getItem('selectedCurrency') &&
      this.currencyApi.selectedCurrency !== localStorage.getItem('selectedCurrency')) ||
      (!this.currencyApi.selectedCurrency && localStorage.getItem('selectedCurrency'))) {
      // Set currency
      const selectedCurrency = this.currencyApi.selectedCurrency;
      if ((!this.currencyApi.selectedCurrency) || selectedCurrency !== localStorage.getItem('selectedCurrency')) {
        this.currencyApi.selectedCurrency = localStorage.getItem('selectedCurrency');
        // localStorage.setItem('selectedCurrency', '');
      }
    }

    if ((this.hotelSearchService.guestNationalityName && localStorage.getItem('guestNationalityName') &&
      this.hotelSearchService.guestNationalityName !== localStorage.getItem('guestNationalityName')) ||
      (!this.hotelSearchService.guestNationalityName && localStorage.getItem('guestNationalityName'))) {
      // Set GuestNationality
      const guestNationalityName = this.hotelSearchService.guestNationalityName;
      if ((!this.hotelSearchService.guestNationalityName) || guestNationalityName !== localStorage.getItem('guestNationalityName')) {
        this.hotelSearchService.guestNationalityName = localStorage.getItem('guestNationalityName');
        // localStorage.setItem('guestNationalityName', '');
      }
    }
  }

  updateHotelSearchResults(hotelSearch) {
    if (this.hotelSearchService.destinationSearchDetails.destination.type === 'Hotel') {
      this.getHotelDetails();
    }
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
    this.hideSpinner();
  }

  scroll(sectionName: string) {
    if (sectionName === 'about-us') {
      this.aboutUs.nativeElement.scrollIntoView();
    } else if (sectionName === 'room-type') {
      this.roomType.nativeElement.scrollIntoView();
    } else if (sectionName === 'hotel-facilities') {
      this.facilities.nativeElement.scrollIntoView();
    } else {
      this.policies.nativeElement.scrollIntoView();
    }
    this.activeSection = sectionName;
  }

  scrollToTop(id) {
    // window.scroll(0, 0);
    if (this.screenService.isBelowLarge()) {
      var element = document.getElementById(id);
      var headerOffset = 64;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.scrollY - headerOffset;
      setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }, 100);
    } else {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView();
      }, 100);
    }
  }

  ScrollToTop() {
    window.scrollTo(0, 0);
  }

}
