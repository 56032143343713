import { ErrorCodes } from './../../../../common/errorcodes';
import { MessageDialogComponent } from './../../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Utilities } from 'src/app/common/utilities/utilities';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CancelpopupService } from 'src/app/b2b/common/b2b-shared/dialogs/cancelpopup.service';
import { Subscription, Observable, of } from 'rxjs';
import { AgentInfoViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/agentinfoviewmodel';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { CONSTANTS } from 'src/app/common/constants';
import { AgentUserDataService } from 'src/app/b2b/common/b2b-shared/services/agent-user-data.service';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { ErrorMessages, CustomConfirmMessages, CustomConfirmButtonTexts } from 'src/app/common/errormessage';
import { JarvisError } from 'src/app/common/jarviserror';
import { PhotosDataService } from 'src/app/b2b/common/b2b-shared/services/photos-data.service';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';
import { startWith, map } from 'rxjs/operators';
import { LookupService } from 'src/app/b2b/common/b2b-shared/services/lookup.service';
import { BranchViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branchviewmodel';
import { DesignationViewModel } from 'src/app/b2b/viewmodels/designationviewmodel';
import { CustomErrorStateMatcher } from 'src/app/common/customerrorstatematcher';
import { PasswordValidators } from 'src/app/common/customvalidators/password-validators';
import { MgRoleViewModel } from 'src/app/b2b/viewmodels/mgroleviewmodel';
import { CountryViewModel } from 'src/app/common/viewmodels/countryviewmodel';
import { CityViewModel } from 'src/app/common/viewmodels/cityviewmodel';
import { AgencyProfileDataService } from 'src/app/b2b/common/b2b-shared/services/agency-profile-data.service';
// import { Patterns } from 'src/app/common/patterns';
import { CanComponentDeactivate } from '../../../common/b2b-shared/services/guards/can-deactivate-guard.service';
import { MarkFormDirtyService } from 'src/app/b2b/common/b2b-shared/services/mark-form-dirty.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';

function AgencyBranchValidator(control) {
  let isControlValueNullOrEmpty = false;
  let isAgencyBranchCodeNullOrEmpty = false;
  if (Utilities.isNullOrEmpty(control.value)) {
    isControlValueNullOrEmpty = true;
  } else if (Utilities.isNullOrEmpty(control.value.id)) {
    isAgencyBranchCodeNullOrEmpty = true;
  }
  return isControlValueNullOrEmpty ? { 'required': true } : isAgencyBranchCodeNullOrEmpty ? { 'validAgencyBranch': true } : null;
}

function AgentUserNameValidator(agentUserDataService: AgentUserDataService, agentId: string, agencyId: number, agencyCode: string) {
  return (control: AbstractControl) => {
    const userName = Utilities.isNullOrEmpty(agencyCode) ? control.value.trim() : agencyCode + '__' + control.value.trim();
    return agentUserDataService.isUserNameExistsForSelectedAgency(userName, agencyId, agentId).pipe(map(res => {
      return res.isError ? { 'duplicateUserName': true } : null;
    }));
  };
}

// function AgentUserEmailValidator(agentUserDataService: AgentUserDataService, agencyCode: string) {
//   return (control: AbstractControl) => {
//     return agentUserDataService.isAgentUserEmailIdAlreadyTaken(control.value.trim(), agencyCode).pipe(map(res => {
//       return res ? { 'duplicateEmail': true } : null;
//     }));
//   };
// }

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.css']
})
export class AgentInfoComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  agentForm: FormGroup;
  subscriptions: Subscription[] = [];
  isEdit = false;
  isCreate = false;
  isRead = false;
  Utilities = Utilities;
  agentInfo: AgentInfoViewModel = <AgentInfoViewModel>{};
  photoURL: string;
  profilePictureUri: string;
  isActiveAgent: boolean;
  agentId: string;
  agencyCode = this.userProfileService.getBasicUserInfo().agencyCode;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  isDisabledBranch = true;
  validatedString = '';
  filteredBranchOptions: Observable<BranchViewModel[]>;
  branchList: BranchViewModel[] = [];
  designationList: Observable<DesignationViewModel[]>;
  errorStateMatcher = new CustomErrorStateMatcher();
  roleList: MgRoleViewModel[];
  countryList: CountryViewModel[] = [];
  cityList: Observable<CityViewModel[]>;
  isSuperUser = false;
  superUserId = CONSTANTS.superUserRoleID.superUser;
  superUserViewOnlyId = CONSTANTS.superUserRoleID.superUserViewOnly;
  agencyName: any;
  branchName: any;
  confirmCancel = false;
  defaultCountryId: number;
  contentUrl = this.configurationService.config.baseUrls.contentUrl;

  constructor(private activatedRoute: ActivatedRoute, private cancelPopupService: CancelpopupService,
    private router: Router, private userProfileService: UserProfileService, private agentUserDataService: AgentUserDataService,
    private snackBar: MatSnackBar, private photosDataService: PhotosDataService, private dialogsService: DialogsService,
    private lookupService: LookupService, private agencyProfileDataService: AgencyProfileDataService, public dialog: MatDialog,
    private markFormDirtyService: MarkFormDirtyService,private configurationService: ConfigurationService,) { }

  ngOnInit() {

    this.agentId = this.activatedRoute.snapshot.params['id'];
    this.countryList = this.activatedRoute.parent.snapshot.data['countryList'];
    this.defaultCountryId = this.countryList.filter(country => country.countryName === 'Indonesia').map(c => c.countryId)[0];
    this.agencyName = this.agencyProfileDataService.agencyName;
    this.getBranches();
    this.getDesignations();
    this.getRoles();

    switch (this.activatedRoute.snapshot.params['operation'].toLowerCase().trim()) {
      case CONSTANTS.operation.create :
            this.isActiveAgent = true;
            this.isCreate = true;
            break;
      case CONSTANTS.operation.edit :
            this.isEdit = true;
            break;
      case CONSTANTS.operation.read :
            this.isRead = true;
            break;
    }

    this.agentForm = new FormGroup({
      branch: new FormControl('' , { validators: [AgencyBranchValidator]}),
      userName: new FormControl('', { validators: [Validators.required, Validators.pattern('^(?!\\s*$)(?!.*__.*)[-a-zA-Z0-9_.]*$')],
         asyncValidators: [AgentUserNameValidator(this.agentUserDataService, this.agentId, this.agencyId, this.agencyCode)]}),
      firstName: new FormControl('', [Validators.required, Validators.pattern('.*[^ ].*')]),
      lastName: new FormControl('', [Validators.required, Validators.pattern('.*[^ ].*')]),
      designationId: new FormControl('', Validators.required),
      email: new FormControl('', { validators: [Validators.required,
                               // Validators.pattern(Patterns.email)
      Validators.email],
                                  //  asyncValidators: [AgentUserEmailValidator(this.agentUserDataService, this.agencyCode)]
      }),
      contactNumber: new FormControl('', [Validators.pattern('([ ]*[0-9# "+-]+[ ]*)*')]),
      b2BRoleId: new FormControl('', Validators.required),
      isActive: new FormControl(this.isActiveAgent),
      password: new FormControl('', { validators: [Validators.required, PasswordValidators.passwordCaseValidator,
                                               PasswordValidators.passwordLengthValidator,
         PasswordValidators.passwordNonAlphanumValidator, PasswordValidators.passwordNumberValidator]}),
      confirmPassword: new FormControl('', { validators: this.isCreate ? [PasswordValidators.matchPasswordValidator, Validators.required]
                                            : [PasswordValidators.matchPasswordValidator]}),
      address1: new FormControl('', [Validators.pattern('.*[^ ].*')]),
      address2: new FormControl('', [Validators.pattern('.*[^ ].*')]),
      countryId: new FormControl(this.defaultCountryId),
      cityId: new FormControl(''),
      zipCode: new FormControl(''),
      accessAgencyInvoice: new FormControl(true),
      accessAgencyVoucher: new FormControl(true),
      accessCustomerInvoice: new FormControl(true),
      accessCustomerVoucher: new FormControl(true),
      accessCustomerItinerary: new FormControl(true)
    });
    this.getCities(this.defaultCountryId);
    if (this.isEdit) {
      this.getAgentUser(this.agentId);
      this.agentForm.controls.branch.disable();
    }
    if (this.isRead) {
      this.isRead = true;
      this.agentForm.disable();
      this.getAgentUser(this.agentId);
    }
  }

  resetBranchChange() {
    if (this.agentForm.get('branch').value === null || this.agentForm.get('branch').value === '') {
      this.isDisabledBranch = true;
    } else {
      this.isDisabledBranch = false;
    }
  }

  checkIfAgentNameExists() {
    const firstName =  this.agentForm.controls.firstName.value;
    const lastName =  this.agentForm.controls.lastName.value;
    const branch = this.agentForm.controls.branch.value;
    if (!Utilities.isNullOrEmpty(firstName) && !Utilities.isNullOrEmpty(lastName)
          && this.agencyId !== undefined && branch.id !== undefined ) {
      const tmpValidation = this.agencyId + ',' + branch.id + ',' + firstName + ',' + lastName;
      if (tmpValidation.toUpperCase() !== this.validatedString.toUpperCase()) {
        this.agentUserDataService.isFirstNameLastNameExistsForSelectedAgencyAndBranch
        (firstName, lastName, this.agencyId, branch.id, this.agentId).subscribe(res => {
          if (res.isError) {
            // tslint:disable-next-line:max-line-length
            this.dialogsService.confirm('Confirm', 'Same Agent name for selected agency and branch already exists, click Ok if you wish to continue.').subscribe(response => {
              if (response) {
                this.validatedString = tmpValidation;
              } else {
                this.agentForm.controls.firstName.setValue('');
                this.agentForm.controls.lastName.setValue('');
                this.validatedString = '';
              }
            });
          }
        });
      }
    }
  }

  displayBranchFn(val: BranchViewModel) {
    return val ? val.name : val ;
  }

  setFilteredBranchOptions() {
    this.filteredBranchOptions = this.agentForm.controls.branch.valueChanges
                                  .pipe(startWith(''), map(val => this.branchList.length > 0
                                                                  ? this.filterBranch(val.toString())
                                                                  : []));
  }

  filterBranch(val: string): BranchViewModel[] {
    if (this.branchList.length > 0) {
      return this.branchList.filter(option => option.name.toString().toLowerCase().indexOf(val.toString().toLowerCase()) === 0);
    } else {
      return [];
    }
  }

  onBranchSelectionChange(value, branchName) {
    if (!Utilities.isNullOrEmpty(value)) {
      this.isDisabledBranch = false;
    } else {
      this.isDisabledBranch = true;
    }
    this.branchName = branchName;
  }

  resetBranch() {
    this.isDisabledBranch = true;
    this.agentForm.get('branch').setValue('');
  }

  onPasswordChange() {
    if (this.isEdit) {
      this.agentForm.controls.confirmPassword.markAsTouched();
    }
    this.agentForm.controls.confirmPassword.updateValueAndValidity();
  }

  getBranches() {
    this.branchList.splice(0, this.branchList.length);
    this.isDisabledBranch = true;
    if (this.agencyId !== null) {
      const subscription = this.lookupService.getAgencyBranches(this.agencyId).subscribe((data) => {
        this.branchList = data;
        this.setFilteredBranchOptions();
      },
        (error: JarvisError) => console.error(`Error in Getting UM-AgentUser-Info-getBranches(agencyId). ${error.friendlyMessage}`));
      this.subscriptions.push(subscription);
     }
  }

  getDesignations() {
    const subscription = this.lookupService.getDesignationByType(CONSTANTS.userTypeName.agentuser).subscribe(data => {
      this.designationList = data.result;
    });
    this.subscriptions.push(subscription);
  }

  toggleActivation(event) {
    this.isActiveAgent = event.checked;
  }

  onPhotoURLChange(updatedPhotoURL) {
    this.profilePictureUri = updatedPhotoURL;
    this.agentForm.markAsDirty();
    this.agentForm.markAsTouched();
  }

  getRoles() {
    const subscription = this.lookupService.getRolesByApplicationName(CONSTANTS.application.b2b,
      CONSTANTS.userType.agentUser).subscribe(data => {
        this.roleList = data;
        if (this.isCreate || (this.isEdit && !this.isSuperUser)) {
          // tslint:disable-next-line:max-line-length
          this.roleList = this.roleList.filter(r => r.id.toString().toUpperCase() !== this.superUserId.toUpperCase() || r.id.toString().toUpperCase() !== this.superUserViewOnlyId.toUpperCase());
        }
      });
    this.subscriptions.push(subscription);
  }

  getCities(countryId) {
    const subscription = this.lookupService.getCities(countryId).subscribe(data => {
      this.cityList = data;
    });
    this.agentForm.get('cityId').setValue(null);
    this.subscriptions.push(subscription);
  }

  getAgentUser(agentId) {
    const subscription = this.agentUserDataService.getAgentUserById(agentId).subscribe(
      (data) => {
        this.agentInfo = data;
        this.getBranches();
        this.agentForm.get('email').setValue(data.email);
        if (data.profilePictureUri != null && data.profilePictureUri.trim() !== '') {  
          this.photoURL = this.contentUrl + data.profilePictureUri;
       
        }
        this.profilePictureUri = data.profilePictureUri;
        this.agentForm.get('designationId').setValue(data.designationId);
        this.agentForm.get('contactNumber').setValue(data.contactNumber);
        this.agentForm.get('b2BRoleId').setValue(data.b2BRoleId);
        // tslint:disable-next-line:max-line-length
        this.isSuperUser = data.b2BRoleId.toString().toUpperCase() === this.superUserId.toUpperCase() || data.b2BRoleId.toString().toUpperCase() === this.superUserViewOnlyId.toUpperCase() ? true : false;
        if (this.isSuperUser) {
          this.agentForm.controls.b2BRoleId.disable();
        }
        this.agentForm.get('firstName').setValue(data.firstName);
        this.agentForm.get('lastName').setValue(data.lastName);
        const val =  data.userName.split('__');
        const userName = val[1];
        this.agentForm.get('userName').setValue(userName);
        if (this.isEdit) {
          this.agentForm.get('password').setValue(CONSTANTS.dummyPassword);
        }
        this.agentForm.get('branch').setValue(data.branch);
        this.branchName = data.branch.name;
        this.isActiveAgent = data.isActive;
        this.agentForm.get('address1').setValue(data.address1);
        this.agentForm.get('address2').setValue(data.address2);
        this.agentForm.get('zipCode').setValue(data.zipCode);
        if (data.countryId !== null) {
          this.agentForm.get('countryId').setValue(data.countryId);
          this.getCities(data.countryId);
          this.agentForm.get('cityId').setValue(data.cityId);
        }
        this.agentForm.get('accessAgencyInvoice').setValue(data.accessAgencyInvoice);
        this.agentForm.get('accessAgencyVoucher').setValue(data.accessAgencyVoucher);
        this.agentForm.get('accessCustomerInvoice').setValue(data.accessCustomerInvoice);
        this.agentForm.get('accessCustomerVoucher').setValue(data.accessCustomerVoucher);
        this.agentForm.get('accessCustomerItinerary').setValue(data.accessCustomerItinerary);
     },
      (error: JarvisError) => console.error(`Error while getting UM-AgentUser-Info-getAgentUser(agentId). ${error.friendlyMessage}`));
     this.subscriptions.push(subscription);
  }

  getValidationMessage(controlName: string) {
    if (this.agentForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.agentForm.get(controlName).hasError('pattern')) {
      if (controlName === 'contactNumber') {
        return ErrorMessages.invalidPhoneNumberMessage;
      } else if (controlName === 'email') {
        return ErrorMessages.invalidEmailMessage;
      } else if (controlName === 'firstName' || controlName === 'lastName' || controlName === 'address1' || controlName === 'address2') {
        return ErrorMessages.requiredFieldMessage;
      } else {
        return ErrorMessages.patternMessage;
      }
    } else if (this.agentForm.get(controlName).hasError('duplicateEmail')) {
      return ErrorMessages.duplicateEmailIdMessage;
    } else if (this.agentForm.get(controlName).hasError('validAgencyBranch')) {
      return ErrorMessages.branchMessage;
    } else if (this.agentForm.get(controlName).hasError('duplicateUserName')) {
      return ErrorMessages.duplicateUserNameMessage;
    } else if (this.agentForm.get(controlName).hasError('passwordLengthError')) {
      return ErrorMessages.passwordLengthErrorMessage;
    } else if (this.agentForm.get(controlName).hasError('passwordNumberError')) {
      return ErrorMessages.passwordNumberErrorMessage;
    } else if (this.agentForm.get(controlName).hasError('passwordCaseError')) {
      return ErrorMessages.passwordCaseErrorMessage;
    } else if (this.agentForm.get(controlName).hasError('passwordNonAlphanumError')) {
      return ErrorMessages.passwordNonAlphanumErrorMessage;
    } else if (this.agentForm.get(controlName).hasError('passwordMismatch')) {
      return ErrorMessages.passwordMismatchErrorMessage;
    } else if (this.agentForm.get(controlName).hasError('email')) {
      return ErrorMessages.invalidEmailMessage;
    } else {
      return '';
    }
  }

  hasErrors(controlName: string) {
    return (this.agentForm.get(controlName).dirty || this.agentForm.get(controlName).touched)
    && this.agentForm.get(controlName).errors !== null;
  }

  cancel() {
    let confirmCancelSubscription = null;
    const navigationPath = '/' + 'authenticated/agency/agencymgmt' + '/agent';
    confirmCancelSubscription = Utilities.confirmCancel(this.agentForm.dirty, this.cancelPopupService, this.router, [navigationPath],
       { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0), null,
       CustomConfirmMessages.confirmMessage,
       CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      this.subscriptions.push(confirmCancelSubscription);
      this.confirmCancel = true;
    }
  }
  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.agentForm.dirty && !this.confirmCancel) {
     // return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
     return this.dialogsService.confirm('Confirm',  CustomConfirmMessages.confirmMessage, true,
     CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    } else {
      return of(true);
    }
   // return true;
  }
  saveAgent() {
    this.markFormDirtyService.markGroupDirty(this.agentForm);
    if(this.agentForm.valid && this.agentForm.dirty) {
      const agent = Object.assign({}, this.agentInfo, this.agentForm.value);
    agent.firstName = agent.firstName.trim();
    agent.lastName = agent.lastName.trim();
    agent.address1 = agent.address1.trim();
    agent.address2 = agent.address2.trim();
    agent.agencyId = this.agencyId;
    agent.branchId = agent.branch.id;
    agent.userName = this.agencyCode + '__' + agent.userName.trim();
    agent.updatedBy = this.userProfileService.getBasicUserInfo().id;
    agent.profilePictureUri = this.profilePictureUri;
    agent.isActive = this.isActiveAgent;
    agent.password = this.agentForm.controls.password.value === CONSTANTS.dummyPassword ? '' :
    this.agentForm.controls.password.value;
    if (this.isCreate) {
      agent.createdBy = this.userProfileService.getBasicUserInfo().id;

      const subscription = this.agentUserDataService.createAgentUser(agent as AgentInfoViewModel)
        .subscribe(data => {
          if (data.succeeded === true ) {
            // tslint:disable-next-line:max-line-length
            this.snackBar.open('The new Agent has been created successfully for ' + this.agencyName + ' Agency and ' + this.branchName + ' Branch' , '', { duration: CONSTANTS.toasterTimeOut.duration,
              verticalPosition: 'top', panelClass: 'showSnackBar'});
            this.router.navigate(['/' + 'authenticated/agency/agencymgmt' + '/agent'],
                        {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
          } else {
            // TODO: Need to check how we are doing error handling.
            this.snackBar.open('Error occourred while saving Agent. ' + data.errors[0].description ,
            '', { duration:  CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top', panelClass: 'showSnackBar'});
          }
        },
        (error: JarvisError) => {
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          dialogRef.componentInstance.message = `Error Code ${ErrorCodes.AgentCreate}. ${error.friendlyMessage}`;
          dialogRef.componentInstance.buttonText = 'OK';
        });
        this.subscriptions.push(subscription);
      } else {
        agent.createdBy = this.agentInfo.createdBy;
        const subscription = this.agentUserDataService.updateAgentUser(this.agentId, agent as AgentInfoViewModel)
        .subscribe(data => {
          if (data.succeeded === true ) {
            // tslint:disable-next-line:max-line-length
            this.snackBar.open('The Agent has been updated successfully for ' + this.agencyName + ' Agency and ' + this.branchName + ' Branch', '', { duration: CONSTANTS.toasterTimeOut.duration,
              verticalPosition: 'top', panelClass: 'showSnackBar'});
            this.router.navigate(['/' + 'authenticated/agency/agencymgmt' + '/agent'],
            {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
          } else {
            // TODO: Need to check how we are doing error handling.
            this.snackBar.open('Error occourred while updating Agent. ' + data.errors[0].description ,
            '', { duration:  CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top', panelClass: 'showSnackBar'});
          }
        },
        (error: JarvisError) => {
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          dialogRef.componentInstance.message = `Error Code ${ErrorCodes.AgentUpdate}. ${error.friendlyMessage}`;
          dialogRef.componentInstance.buttonText = 'OK';
        });
        this.subscriptions.push(subscription);
      }
      this.photosDataService.profilePhotoUrl = '';
      this.confirmCancel = true;
    }    
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}

