import { RolesAndPermissionsService } from './../../common/b2b-shared/services/roles-and-permissions.service';
import { RolesAndPermissionsViewModel } from './../../viewmodels/roles-and-permissions/rolesandpermissionsviewmodel';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roles-and-permissions',
  templateUrl: './roles-and-permissions.component.html',
  styleUrls: ['./roles-and-permissions.component.css']
})
export class RolesAndPermissionsComponent implements OnInit {
  featureArray: RolesAndPermissionsViewModel[];
  roles: string[] = [];

  constructor(private rolesAndPermissionsService: RolesAndPermissionsService) { }

  ngOnInit() {
    this.getRolesAndPermissions();
  }

  getRolesAndPermissions() {
    this.rolesAndPermissionsService.getRolesAndPermission().subscribe(data => {
      this.featureArray = data;
      this.roles = data[0].rolesAndPermission.map(r => r.roleName);
      // --- to replace parentName and childName with null after 1st row ---
      let parentName, childName;
      for (let i = 0; i < this.featureArray.length - 1; i++) {
        if (this.featureArray[i].parentName) {
          parentName = this.featureArray[i].parentName;
        }
        if (parentName === this.featureArray[i + 1].parentName) {
          this.featureArray[i + 1].parentName = null;
        } else {
          parentName = this.featureArray[i + 1].parentName;
        }
        if (this.featureArray[i].childName) {
          childName = this.featureArray[i].childName;
        }
        if (childName === this.featureArray[i + 1].childName && parentName !== this.featureArray[i + 1].parentName) {
          this.featureArray[i + 1].childName = null;
        } else {
          childName = this.featureArray[i + 1].childName;
        }
      }
      // ------
    });
  }
}
