import { Injectable } from '@angular/core';
import { FullBookingResponseDetailsViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-response-viewmodel';
import { BookingRequestViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/booking-request-viewmodel';
import { CancelBookingResponseViewmodel } from 'src/app/b2b/viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';

@Injectable({
  providedIn: 'root'
})
export class HotelBookService {

  private _fullBookingResponseDetails: FullBookingResponseDetailsViewmodel;
  private _bookingRequestDetails: BookingRequestViewmodel;
  private _cancelBookingDetails: CancelBookingResponseViewmodel;
  private _cancelBookingDetailsReservation: CancelBookingResponseViewmodel;
  private _bookFromUnbeatableDeals = false;
  private _bookFromChainDeals = false;

  get fullBookingResponseDetails(): FullBookingResponseDetailsViewmodel {
    return this._fullBookingResponseDetails;
  }

  set fullBookingResponseDetails(fullBookingResponseDetailsViewmodel: FullBookingResponseDetailsViewmodel) {
    this._fullBookingResponseDetails = fullBookingResponseDetailsViewmodel;
  }
  get bookingRequestDetails(): BookingRequestViewmodel {
    return this._bookingRequestDetails;
  }

  set bookingRequestDetails(fullBookingResponseDetailsViewmodel: BookingRequestViewmodel) {
    this._bookingRequestDetails = fullBookingResponseDetailsViewmodel;
  }

  get cancelBookingDetails(): CancelBookingResponseViewmodel {
    return this._cancelBookingDetails;
  }

  set cancelBookingDetails(cancelBookingData: CancelBookingResponseViewmodel) {
    this._cancelBookingDetails = cancelBookingData;
  }

  get cancelBookingDetailsReservation(): CancelBookingResponseViewmodel {
    return this._cancelBookingDetailsReservation;
  }

  set cancelBookingDetailsReservation(cancelBookingDataReservation: CancelBookingResponseViewmodel) {
    this._cancelBookingDetailsReservation = cancelBookingDataReservation;
  }

  set bookFromUnbeatableDeals(url: boolean) {
    this._bookFromUnbeatableDeals = url;
  }

  get bookFromUnbeatableDeals() {
    return this._bookFromUnbeatableDeals;
  }

  set bookFromChainDeals(url: boolean) {
    this._bookFromChainDeals = url;
  }

  get bookFromChainDeals() {
    return this._bookFromChainDeals;
  }

  constructor() { }
}
