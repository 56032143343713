import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Orderbypipe } from './orderbypipe';
import { BookingTimePipe } from './booking-time.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ Orderbypipe, BookingTimePipe],
  exports: [ Orderbypipe, BookingTimePipe ],
  providers:    [ BookingTimePipe ]
})
export class PipesModule { }
