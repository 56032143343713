
import { Observable} from 'rxjs';
import { AlertResponceViewModel } from '../../../common/viewmodels/alerts/alertResponseviewmodel';

export abstract class UserAlertApi {
    alertCount$: Observable<number>;
    alertDetails$: Observable<AlertResponceViewModel[]>;
    abstract getAlertCountForCurrentApp(app: string): Observable<number>;
    abstract getAlertDetailsForCurrentApp(app: string): Observable<AlertResponceViewModel[]>;
    abstract markMessagesAsRead();
    abstract getLatestUnReadAlerts(app: string): Observable<AlertResponceViewModel[]>;
  }
