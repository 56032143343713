import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { Bankdetailsviewmodel } from 'src/app/b2b/viewmodels/bankdetailsviewmodel';
import { map } from 'rxjs/operators';
import { BankDetailsInfoViewModel } from 'src/app/b2b/viewmodels/bank-details-info-view-model';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsDataService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }

  getBankDetails(): Observable<Bankdetailsviewmodel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi,
       'api/Agency/GetAgencyBank').pipe(map(data => {
      const bankDetailsDTo: Bankdetailsviewmodel[] = [];
      if (data !== null) {
        data.result.forEach(element => {
          const bankDetails: Bankdetailsviewmodel = { name: element.name, id: element.id };
          bankDetailsDTo.push(bankDetails);
        });
      }
     return bankDetailsDTo;
    }));
  }

  getBankDetailsById(agencyId: number): Observable<BankDetailsInfoViewModel> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.b2BContentApi
      , 'api/Home/GetBankDetailsById?agencyId=' + agencyId).pipe(map(data => {
        let bankDetailsListDto = new BankDetailsInfoViewModel();
        if (data !== null) {
          bankDetailsListDto = data.result;
        }
        return bankDetailsListDto;
      }));
  }

  updateAgencyBankDetails(agencyBankInfo: BankDetailsInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.put(this.configurationService.config.baseUrls.b2BContentApi
      ,agencyBankInfo, 'api/Home/UpdateBankDetails');
  }


}
