import { CONSTANTS } from './../../../../common/constants';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bookingtime', pure: false })
export class BookingTimePipe implements PipeTransform {
    SECOND = 1000;
    MINUTE: any = 60 * this.SECOND;
    HOUR: any  = 60 * this.MINUTE;
    DAY: any  = 24 * this.HOUR;
    localToday: any;

    transform(hotelUTCTime) {
      if (hotelUTCTime != null) {
          // create Date object for current location
          // convert to msec
          // add local time zone offset
          // get UTC time in msec
          // const utc = todaysDate.getTime() + (todaysDate.getTimezoneOffset() * 60000);
          // create new Date object for different city
          // using supplied offset
        //   const localToday = new Date();
        this.localToday = new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta'});
        this.localToday = new Date(this.localToday);
          const msLocalToday: any = this.localToday.getTime().toString();

          // substracted -1 day from confirmation date
          const hotelUTCTimeConverted = new Date(hotelUTCTime);
          // hotelUTCTimeConverted.setDate(hotelUTCTimeConverted.getDate()-1);
          const hotelTimeToLocal: any = (hotelUTCTimeConverted).getTime().toString();

          // const hotelTimeToLocal: any = (new Date(hotelUTCTime + 'Z')).getTime().toString();

          let dd, hh, mm, ss;
          let ms: any = (hotelTimeToLocal - msLocalToday);
          if (ms > this.DAY) {
              dd = ms / this.DAY;
              ms %= this.DAY;
          } else {
              dd = 0;
          }
          if (ms > this.HOUR) {
              hh = ms / this.HOUR;
              ms %= this.HOUR;
          } else {
            hh = 0;
        }
          if (ms > this.MINUTE) {
              mm = ms / this.MINUTE;
              ms %= this.MINUTE;
          } else {
            mm = 0;
        }
          if (ms > this.SECOND) {
              ss = ms / this.SECOND;
              ms %= this.SECOND;
          } else {
            ss = 0;
        }

          return  ( '' + (Math.floor(dd))).slice(-5) + ' days \n'
                  + ( '0' + (Math.floor(hh))).slice(-2) + ':'
                  + ( '0' + (Math.floor(mm))).slice(-2) + ':'
                  + ( '0' + (Math.floor(ss))).slice(-2) + ' hours ';
      } else {
          return '';
      }

  }
}
