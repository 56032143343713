import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ScreenService } from './screen.service';

export interface IMenuItem {
  text: string;
  icon: string;
  route: string;
  submenu: Array<IMenuItem>;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  items: Array<IMenuItem>;
  isVertical = false;
  showingLeftSideMenu = false;

  public appDrawer: any;

  constructor(breakpointObserver: BreakpointObserver) {
    const layoutChanges = breakpointObserver.observe([
      '(orientation: landscape)'
    ]);

    layoutChanges.subscribe(result => {
      if (result.breakpoints['(orientation: landscape)']) {
        this.showingLeftSideMenu = false;
        this.closeNav()
      }
    });
  }

  toggleLeftSideMenu(): void {
    // this.isVertical = true;
    this.showingLeftSideMenu = !this.showingLeftSideMenu;
    // console.log('showing left side menu' + this.showingLeftSideMenu );
    this.toggleNav();
    //  this.openNav();
  }
  public closeNav() {
    if (this.appDrawer !== undefined) {
      this.appDrawer.close();
      document.body.classList.remove('hide-scroll-y');
    }
  }

  public openNav() {
    this.showingLeftSideMenu = true;
    if (this.appDrawer !== undefined) {
      this.appDrawer.open();
    }
  }

  public toggleNav() {
    if (this.appDrawer !== undefined) {
      this.appDrawer.toggle();
    }
    document.body.classList.toggle('hide-scroll-y');
  }
}
