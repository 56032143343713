

import { Observable} from 'rxjs';
import { CurrencyViewModel } from '../../../common/viewmodels/currencyviewmodel';

export abstract class CurrencyApi {
    abstract currencyList$: Observable<CurrencyViewModel[]>;
    abstract selectedCurrency: string;
    abstract defaultCurrency: string;
    abstract getCurrencyList(): Observable<CurrencyViewModel[]>;
    abstract getAgencyPreferredCurrencyList(agencyCode: string, agencyDefaultCurrencyId: number): Observable<CurrencyViewModel[]>;

 }
