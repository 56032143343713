import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { CONSTANTS } from './../../../../common/constants';
import { map } from 'rxjs/operators';
import { PaymentTypeDetailsViewModel } from './../../../viewmodels/payment/paymenttypedetailsviewmodel';
import { ConfigurationService } from './../../../../common/shared/services/configuration.service';
import { AuthenticatedHttpService } from './../../../../common/shared/services/authenticated-http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecureHashParameterViewModel } from '../../../viewmodels/payment/secureHashParameterViewModel';

@Injectable({
  providedIn: 'root'
})
export class PaymentDataService {
  secureHashParameterViewModel: SecureHashParameterViewModel = <SecureHashParameterViewModel>{};
  paymentTypeDetails$: Observable<PaymentTypeDetailsViewModel>;
  isAccessAllowed$: Observable<any>;
  paymentGatways = CONSTANTS.PaymentGateway;

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService, public currencyApi: CurrencyApi) { }

  getPaymentTypeDetailsByAgencyId(currency: string, agencyId = 0): Observable<PaymentTypeDetailsViewModel> {
    return this.paymentTypeDetails$ = this.authenticatedHttpService
      .get(this.configurationService.config.baseUrls.b2BContentApi,
        'api/Payment/GetPaymentTypeDetailsByAgencyId?currency=' + currency + '&agencyId=' + agencyId).pipe(
          map((data) => {
            if (data === null) {
              return data;
            } else {
              return data.result;
            }
          }));
  }

  // tslint:disable-next-line:max-line-length
  // IMP NOTE: As getSecureHashKey is use for only asiaPay payment gateway directly ccPaymentGatewayId, ccMerchantId and ccPaymentGatewayCode are used
  getSecureHash(mgBookingId): Observable<string> {
    // const paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails'));
    const paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
    const reservationDetails = JSON.parse(sessionStorage.getItem('reservationDetails'));
    const isOnHold: string = sessionStorage.getItem('isOnHold') || null;
    this.secureHashParameterViewModel.merchantId = paymentTypeDetails.ccMerchantId;
    if (mgBookingId === null) {
      this.secureHashParameterViewModel.mgBookingId = paymentTypeDetails.mGBookingID;
    } else {
      this.secureHashParameterViewModel.mgBookingId = mgBookingId;
    }
    // tslint:disable-next-line:max-line-length
    if(isOnHold && isOnHold === 'TRUE') {
      this.secureHashParameterViewModel.amount = reservationDetails.bookingDetails.currency === CONSTANTS.idrCurrencyCode || reservationDetails.bookingDetails.currency === CONSTANTS.vndCurrencyCode ? Math.ceil(paymentTypeDetails.netPrice) : paymentTypeDetails.netPrice;
    }else{
    this.secureHashParameterViewModel.amount = this.currencyApi.selectedCurrency === CONSTANTS.idrCurrencyCode || this.currencyApi.selectedCurrency === CONSTANTS.vndCurrencyCode ? Math.ceil(paymentTypeDetails.netPrice) : paymentTypeDetails.netPrice;
    }
    // 1245556; // this.paymentDataService.paymentTypeDetails.amount;
    this.secureHashParameterViewModel.creditCardConfigurationId = paymentTypeDetails.ccPaymentGatewayId;
   // secureHashParameterViewModel.function = 0;
    this.secureHashParameterViewModel.currencyCode = paymentTypeDetails.currencyCode;
    if (paymentTypeDetails.ccPaymentGatewayCode !== null &&
      paymentTypeDetails.ccPaymentGatewayCode.toUpperCase() === this.paymentGatways.asiaPay) {
      return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.b2BContentApi,
        this.secureHashParameterViewModel
        , 'api/Payment/GenerateSecureHashKey')
        .pipe(map((data: any) => {
          // let reservationDetailsForBackOfficeDto: FullBookingResponseDetailsViewmodel;
          if (data !== null) {
            return data.result;
          }
        }));
    } else {
      return null;
    }
  }

  isAccessAllowed(mgReservationNumber: string): Observable<any> {
    return this.isAccessAllowed$ = this.authenticatedHttpService
      .get(this.configurationService.config.baseUrls.bookingApi,
        'api/Payment/IsAccessAllowed?mgReservationNumber=' + mgReservationNumber).pipe(
          map((data) => {
              if (data === null) {
                return data;
              } else {
                return data.result;
              }
          }));
  }

  capture(paymentGatewayRequest): Observable<any> {
    return this.authenticatedHttpService
      .post(this.configurationService.config.baseUrls.bookingApi, paymentGatewayRequest,
        'api/Payment/Capture').pipe(
          map((data) => {
            return data;
          }));
  }

  void(paymentGatewayRequest): Observable<any> {
    return this.authenticatedHttpService
      .post(this.configurationService.config.baseUrls.bookingApi, paymentGatewayRequest,
        'api/Payment/Void');
  }

  updatePaymentDetails(paymentGatewayRequest): Observable<any> {
    return this.authenticatedHttpService
      .post(this.configurationService.config.baseUrls.bookingApi, paymentGatewayRequest,
        'api/Payment/UpdatePaymentDetails');
  }

  createInvoice(paymentGatewayRequest): Observable<any> {
    return this.authenticatedHttpService
      .post(this.configurationService.config.baseUrls.bookingApi, paymentGatewayRequest,
        'api/Payment/CreateInvoice').pipe(
          map((data) => {
            return data;
          }));
  }

  updateXenditPaymentStatus(paymentGatewayRequest): Observable<any> {
    return this.authenticatedHttpService
      .post(this.configurationService.config.baseUrls.bookingApi, paymentGatewayRequest,
        'api/Payment/UpdateXenditPaymentStatus').pipe(
          map((data) => {
            return data;
          }));
  }

  voidForXendit(paymentGatewayRequest): Observable<any> {
    return this.authenticatedHttpService
      .post(this.configurationService.config.baseUrls.bookingApi, paymentGatewayRequest,
        'api/Payment/RefundInvoice').pipe(
          map((data) => {
            return data;
          }));
  }

  createDisbursement(paymentGatewayRequest): Observable<any> {
    return this.authenticatedHttpService
      .post(this.configurationService.config.baseUrls.bookingApi, paymentGatewayRequest,
        'api/Payment/CreateDisbursement').pipe(
          map((data) => {
            return data;
          }));
  }

}
