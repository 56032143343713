import { BreadCrumbService } from './../../common/b2b-shared/services/bread-crumb.service';
import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilities } from 'src/app/common/utilities/utilities';
import { MatSnackBar } from '@angular/material';
import { CONSTANTS } from 'src/app/common/constants';
import { HotelRecheckService } from '../../common/b2b-shared/services/hotel-recheck.service';
import { HotelSearchService } from '../../common/b2b-shared/services/hotel-search.service';
import { HotelRecheckViewModel } from '../../viewmodels/hotel-mgmt/hotelrecheckviewmodel';
import { DatePipe } from '@angular/common';
import { AgencyProfileDataService } from '../../common/b2b-shared/services/agency-profile-data.service';
import { ScreenService } from '../../../framework/fw/services/screen.service';
import { MGFPointsConverter } from 'src/app/common/viewmodels/appsettingsviewmodel';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { TopBarService } from 'src/app/framework/fw/services/top-bar.service';

@Component({
  selector: 'app-booking-summary-nav',
  templateUrl: './booking-summary-nav.component.html',
  styleUrls: ['./booking-summary-nav.component.css']
})
export class BookingSummaryNavComponent implements OnInit, OnDestroy {

  timeLeftMin = 20;
  timeLeftSec = 0;
  progressBarValue = this.timeLeftMin * 60;
  totalProgressBarValue = this.timeLeftMin * 60;
  progressBarPercentage = 100;
  interval;
  hotelRecheckData: HotelRecheckViewModel = <HotelRecheckViewModel>{};
  subscriptions: any[] = [];
  guestNationality: string;
  confirmCancel = false;
  nightlyRate: any;
  showNightlyRate: boolean;
  // previousRoute = this.breadCrumbService.previousRoute;
  @Input() previousRoute: string;
  safeStayPolicy = CONSTANTS.SafeStayPolicy;
  isVaccinatedClicked = false;
  isMGPointApplicable: any;
  mGFPointsConverter:MGFPointsConverter;
  exchangeRate: number;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private hotelRecheckService: HotelRecheckService,
    private snackBar: MatSnackBar,
    private hotelSearchService: HotelSearchService, public breadCrumbService: BreadCrumbService, private agencyProfileDataService: AgencyProfileDataService,public screenService: ScreenService,
    private configservice: ConfigurationService, private topBarService: TopBarService) { }

  ngOnInit() {
    this.isMGPointApplicable = this.agencyProfileDataService.isMGPointApplicable;
    this.exchangeRate = this.topBarService._exchangeRate ? this.topBarService._exchangeRate : +localStorage.getItem('exchangeRate');
    window.scroll(0, 0);
    this.startTimer();
    this.getHotelData();
    this.guestNationality = localStorage.getItem('guestNationalityName');
    this.showNightlyRate = false;
    window.scroll(0, 0);
    this.mGFPointsConverter = this.configservice.config.mgfPointsConverter;
  }

  openSafeStayPolicy() {
    localStorage.setItem('isVaccinatedClicked', JSON.stringify(this.isVaccinatedClicked));
    this.router.navigate([]).then(result => { window.open('siteinfomgmt/safestaypolicies', '_blank'); });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeftSec > 0) {
        this.timeLeftSec--;
        this.progressBarValue--;
        this.progressBarPercentage = (this.progressBarValue / this.totalProgressBarValue) * 100;
      } else {
        this.timeLeftSec = 59;
        this.timeLeftMin--;
      }
      if (this.timeLeftMin === 0 && this.timeLeftSec === 0) {
        this.snackBar.open('You will be redirected to the home page', '', {
          duration: CONSTANTS.toasterTimeOut.duration,
          verticalPosition: 'top', panelClass: 'showSnackBar'
        });
        this.confirmCancel = true;
        this.router.navigate(['/authenticated/hotelmgmt/hotelinfo'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  getHotelData() {
    this.hotelRecheckData = this.hotelRecheckService.hotelRecheckDetails;
  }

  getNightlyRate(roomCode: string, roomIndex: number) {
    const nightlyRate = {
      currency: '',
      roomCount: 0,
      dates: [],
      rooms: [],
      roomNo: roomIndex + 1
    };
    // Set default value
    this.nightlyRate = {};
    this.showNightlyRate = false;
    // Get filtered room array
    const roomObj = this.getFilteredRoomForNightlyRate(roomCode, roomIndex);
    // Get room count
    if (nightlyRate.roomCount === 0 && roomObj.length !== 0) {
      nightlyRate.roomCount = roomObj.length;
    }
    for (let l = 0; l < roomObj.length; l++) {
      for (const pdKey in roomObj[l]) {
          if (roomObj[l].hasOwnProperty(pdKey)) {
          // Get currency code
          if (pdKey === 'currencyCode' && !nightlyRate.currency) {
              nightlyRate.currency = roomObj[l]['currencyCode'];
          }

          if (pdKey === 'nightlyRate') {
            const nightlyRateObj = roomObj[l][pdKey];
            for (let m = 0; m < nightlyRateObj.length; m++) {
              for (const nrKey in nightlyRateObj[m]) {
                if (nightlyRateObj[m].hasOwnProperty(nrKey)) {
                  // Get dates
                  if (nrKey === 'date') {
                      if (!nightlyRate.dates.includes(nightlyRateObj[m]['date'])) {
                      nightlyRate.dates.push(nightlyRateObj[m]['date']);
                      }
                  }
                  // Get gross price
                  if (nrKey === 'grossPrice') {
                      if (!nightlyRate.rooms.includes(nightlyRate.rooms[m])) {
                      nightlyRate.rooms[m] = [];
                      }
                      nightlyRate.rooms[m].push(nightlyRateObj[m]['grossPrice']);
                  }
                }
              }
            }
          }
        }
      }
    }
    // Get no of rooms for making table heading
    this.nightlyRate = nightlyRate;
    this.showNightlyRate = true;
  }

  getFilteredRoomForNightlyRate(roomCode, roomIndex) {
    let rooms = JSON.parse(JSON.stringify(this.hotelRecheckData.hotel.roomDetails.rooms));
    rooms = rooms.filter(r => ((roomCode === r.roomCode) && (r.roomNo === (roomIndex + 1))));
    return rooms;
  }

  getObjByKey(obj, key): any {
    let returnObj = {};
      for (const objKey in obj) {
        if (obj.hasOwnProperty(objKey)) {
          if (objKey === key) {
            returnObj = obj[key];
          }
        }
      }
    return returnObj;
  }

  ngOnDestroy() {
    this.pauseTimer();
    if (!Utilities.isNullOrEmpty(this.subscriptions)) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

}
