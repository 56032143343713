import { Injectable } from '@angular/core';
import { UserAccountApi } from '../../../framework/fw/users/user-account-api';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { ChangePasswordViewModel } from '../../viewmodels/changepasswordviewmodel';
import { Observable } from 'rxjs';
import { RequestCreditNotification } from '../../viewmodels/notification/requestcreditnotificationsviewmodel';
import { map } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { UserProfileViewModel } from '../../viewmodels/userprofileviewmodel';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService implements UserAccountApi {

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }
  changePassword(newPasswordDetails: ChangePasswordViewModel): Observable<any> {
     return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.userMgmtApi
      , newPasswordDetails, 'api/user/ChangePassword/');
  }

  verifyCurrentPassword(userName: string, password: string): Observable<boolean> {
     return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/user/VerifyCurrentPassword?userName=' + userName + '&password=' + password);
      // Todo:
     // return null;
  }

  notifyAgencyRequst(notifyAgency: RequestCreditNotification): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.bookingApi
      , notifyAgency, 'api/EmailNotification/NotifyAgencyCreditRequest').pipe(map((data: any) => {
        let sentNotification;
        if (data != null) {
          sentNotification = data.result;
        }
        return sentNotification;
      }));
  }

  updateUserProfile(userProfile: UserProfileViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.userMgmtApi
      , userProfile, 'api/Agent/UpdateAgentProfile').pipe(map((data: any) => {
        let sentNotification;
        if (data != null) {
          sentNotification = data;
        }
        return sentNotification;
      }));
  }
}
