import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { CONSTANTS } from 'src/app/common/constants';
import { BankDetailsDataService } from '../../common/b2b-shared/services/bank-details-data.service';
import { Bankdetailsviewmodel } from '../../viewmodels/bankdetailsviewmodel';
import { JarvisError } from 'src/app/common/jarviserror';
import { BankDetailsInfoViewModel } from '../../viewmodels/bank-details-info-view-model';
import { Observable, Subscription, of } from 'rxjs';
import { Utilities } from 'src/app/common/utilities/utilities';
import { CancelpopupService } from '../../common/b2b-shared/dialogs/cancelpopup.service';
import { ErrorMessages, CustomConfirmMessages, CustomConfirmButtonTexts } from 'src/app/common/errormessage';
import { MarkFormDirtyService } from '../../common/b2b-shared/services/mark-form-dirty.service';
import { MatSnackBar } from '@angular/material';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';


@Component({
  selector: 'app-agency-bank-details',
  templateUrl: './agency-bank-details.component.html',
  styleUrls: ['./agency-bank-details.component.css']
})
export class AgencyBankDetailsComponent implements OnInit {

  bankDetailsForm: FormGroup;
  bankDetailsList: Bankdetailsviewmodel[];
  bankDetailsInfo:  BankDetailsInfoViewModel = <BankDetailsInfoViewModel>{};
  bankDetailsInfodData: BankDetailsInfoViewModel;
  subscriptions: Subscription[] = [];
  create = CONSTANTS.operation.create;
  operation: string;
  userId: string;
  agencyId: number;
  Utilities = Utilities;
  confirmCancel = false;
  AgencyBankDetailsAccess = 
  this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.AgencyBankDetails);

  constructor(private userProfileService: UserProfileService, private bankDetailsDataServices:BankDetailsDataService,
    private cancelPopupService: CancelpopupService,private router: Router,private activatedRoute: ActivatedRoute,
    private markFormDirtyService: MarkFormDirtyService, private snackBar: MatSnackBar, private dialogsService: DialogsService,) { }

  ngOnInit() {
    
    this.bankDetailsForm = new FormGroup({
      accountName: new FormControl('', Validators.required),
      accountNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      bankId: new FormControl('',Validators.required)
    });
    this.getBankDetails();
    this.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    this.userId = this.userProfileService.getBasicUserInfo().id;
    this.getBankData(this.agencyId);

    if (!this.AgencyBankDetailsAccess.hasEditAccess) {
      this.bankDetailsForm.disable();
    }
  }
  getBankDetails() {
    const subscription = this.bankDetailsDataServices.getBankDetails().subscribe(result => {
      this.bankDetailsList = result;
    });
    this.subscriptions.push(subscription);
  }
 
  getBankData(agencyId: number) {
    const subscription = this.bankDetailsDataServices.getBankDetailsById(agencyId).subscribe(
      (data) => {

        this.bankDetailsInfo = data;
        this.bankDetailsForm.get('bankId').setValue(this.bankDetailsInfo.bankId);
        this.bankDetailsForm.get('accountName').setValue(this.bankDetailsInfo.accountName);
        this.bankDetailsForm.get('accountNumber').setValue(this.bankDetailsInfo.accountNumber);
      },
      (error: JarvisError) => console.error(`Error in Getting getBankdetailsById. ${error.friendlyMessage}`)
      );
    this.subscriptions.push(subscription);
  }
  
  updateBankDetails() {
    this.markFormDirtyService.markGroupDirty(this.bankDetailsForm);
    if(this.bankDetailsForm.valid && this.bankDetailsForm.dirty) {
      const bankData = Object.assign({}, this.bankDetailsInfo, this.bankDetailsForm.value);
      bankData.updatedBy=this.userId;
      bankData.agencyId=this.agencyId;

      const subscription = this.bankDetailsDataServices.updateAgencyBankDetails(bankData as BankDetailsInfoViewModel )
      .subscribe(data => {
        if (data.isError === false) {
          this.snackBar.open('The Bank Details has been updated successfully', '', {
            duration: CONSTANTS.toasterTimeOut.duration,
            verticalPosition: 'top', panelClass: 'showSnackBar'
          });
          this.router.navigate(['/agencymgmt/agency'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
        } else {
          // TODO: Need to check how we are doing error handling.
          this.snackBar.open('Error occourred while updating bankDetails. ' + data.errorMessages,
            '', { duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top', panelClass: 'showSnackBar' });
          this.router.navigate(['/agencymgmt/agency'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
        }
      },
      (error: JarvisError) => console.error(`Error while updating bankDetails. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
    }
  }

  getValidationMessage(controlName: string) {
    if (this.bankDetailsForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.bankDetailsForm.get(controlName).hasError('pattern')) {
      if (controlName === 'accountNumber') {
        return ErrorMessages.numericErrorMessage;
      }
    }
    else {
      return '';
    }
  }

  hasErrors(controlName: string) {

    return (this.bankDetailsForm.get(controlName).dirty || this.bankDetailsForm.get(controlName).touched)
      && this.bankDetailsForm.get(controlName).errors !== null;
  }

  cancel(){
    let confirmCancelSubscription = null;
    confirmCancelSubscription = Utilities.confirmCancel(this.bankDetailsForm.dirty, this.cancelPopupService, this.router,
      ['/agencymgmt/agency'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0),
      null, CustomConfirmMessages.confirmMessage,
      CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      this.subscriptions.push(confirmCancelSubscription);
      this.confirmCancel = true;
    }
  }

  save() {
    if(this.bankDetailsForm.valid && this.bankDetailsForm.dirty) {
      this.confirmCancel = true;
    }
  }

  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.bankDetailsForm.dirty && !this.confirmCancel) {
      //  return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
      return this.dialogsService.confirm('Confirm', CustomConfirmMessages.confirmMessage, true,
        CustomConfirmButtonTexts.Leave, CustomConfirmButtonTexts.Stay);
    } else {
      return of(true);
    }
    // return true;
  }

  ngOnDestroy() {
    if (!Utilities.isNullOrEmpty(this.subscriptions)) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }


}
