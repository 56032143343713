export let errorConstants = {
  searchErrorMessage: [
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML001', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team whom will be happy to assist.` },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML002', message: 'Unable to proceed with this booking due to insufficient credit balance. Your Credit Balance seems to be lower than the  total booking value. Please contact your MG Sales PIC to increase your credit limit.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML003', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team whom will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML004', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML005', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML007', message: 'There seems to be some fields missing on your search. Please kindly make sure that all fields are correct. If not please contact MG Customer Service for support.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML008', message: 'Sorry, we are unable to proceed with your booking because the number of children guest is outside the allowed range for that room. Error Code: JRVXML008' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML009', message: 'Sorry, we are unable to proceed with your booking because the age of children that you inserted is outside the allowed age range for that room. Please try again and consider the children as an Adult guest. Error Code: JRVXML009' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML012', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Wrong date format for Check-in : JRVXML012.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML013', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Wrong date format for Check-out : JRVXML013.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML031', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML032', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML033', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML034', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML035', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML036', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML037', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML038', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Check-in and Check-out dates must be within 2 years from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML039', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Booking period exceeds 30 days limit from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML040', message: 'Sorry, hotel/room you were trying to book is no longer available as CheckIn and CheckOut dates cannot be on the same day.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML041', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be between 1 and 8.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML042', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of adults should be between 1 and 4.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML044', message: 'Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML045', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Room information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML046', message: 'The age entered for a child guest is either not valid or outside the allowed range.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML048', message: 'Sorry, no additional information about selected hotel is available at the moment.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML049', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-in Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML050', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-out Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML051', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML052', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML054', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our admin at salesadmin@mgbedbank.com or your MG Sales PIC to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML057', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Room Type and Child Age mismatch. Please check your request.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML058', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML059', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML060', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML061', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML062', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Currency Conversion Rate is unavailable: JRVXML062.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML064', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid RoomType.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML065', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid no of adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML066', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Room Type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML067', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid SeqNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML072', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML074', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: First & Last Name must contain a minimum of 2 Characters.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML075', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Last Name must not exceed 41 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML076', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  First Name must not exceed 26 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML077', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Pax Format.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML078', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Please enter the Pax Information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML079', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Salutation is blank: JRVXML079.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML087', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team whom will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML090', message: 'Sorry, hotel/room you were trying to book is no longer available as Insufficient Data for (Hotel).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML091', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Number of Adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML098', message: 'Sorry, the hotel required is no longer available. You might want to try  similar hotel nearby or call MG customer service for assistence.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML100', message: 'There is an error at supplier end: Booking not successful.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML101', message: 'Sorry, your cancellation limit has expired. This booking has been processed accordingly to all the policies set by hotels. Please kindly contact MG Customer Service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML105', message: 'Sorry, you are unble to cancel / edit due to the policy. Please kindly contact MG customer service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML106', message: 'Pleased to inform you that the reservations have been cancelled successfully.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML107', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Missing mandatory parameter - ResNo: JRVXML107.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML109', message: 'Sorry there have been no reservations found. Please kindly contact MG Customer Service for further assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML110', message: 'Sorry there been an error in your cancellation. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML112', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: OSRefno is not unique. Please update OSRefno.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML115', message: 'Sorry, your current session has expired. Please try login again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML122', message: 'Sorry, hotel/room you were trying to book is no longer available as Maximum allowed length (20 characters) exceeded for OSRefNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML127', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML140', message: 'Sorry we are unable to process your request. Please try to booking a single room type to proceed.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML142', message: 'Sorry there seems to be an error from the hotels in cancelling this booking. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML143', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML144', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML145', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML146', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML147', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML149', message: 'Sorry, it seems like something went wrong! Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML157', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team whom will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML159', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be atleast 1 and max 10.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML161', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML170', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML178', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML181', message: 'Sorry we are unable to find records. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML183', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML185', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML187', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML188', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML190', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML191', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML205', message: 'Sorry, it seems like the current cancellation policy has changed to not allow this operation. Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: '500', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: '400', message: 'There seems to be some fields missing on your search. Please kindly make sure that all fields are correct. If not please contact MG Customer Service for support.' },
    // tslint:disable-next-line: max-line-length
    { code: '503', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
  ],

  recheckErrorMessage: [
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML001', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML002', message: 'Unable to proceed with this booking due to insufficient credit balance. Your Credit Balance seems to be lower than the total booking value. Please contact your MG Sales PIC to increase your credit limit.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML003', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML004', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML005', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML007', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML008', message: 'Sorry, the bookings are is to confirm. Please kindly edit the number of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML009', message: 'Sorry, the bookings are is to confirm. Please kindly edit the age of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML010', message: 'Sorry, the hotel/room you were trying to book is no longer available. Please select other Hotel / Rooms.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML011', message: 'Sorry, the hotel/room you were trying to book is no longer available/sold out. Please choose another.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML012', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML013', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML031', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML032', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML033', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML034', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML035', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML036', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML037', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML038', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Check-in and Check-out dates must be within 2 years from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML039', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Booking period exceeds 30 days limit from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML040', message: 'Sorry, hotel/room you were trying to book is no longer available as CheckIn and CheckOut dates cannot be on the same day.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML041', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be between 1 and 8.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML042', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of adults should be between 1 and 4.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML044', message: 'Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML045', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Room information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML046', message: 'The age entered for a child guest is either not valid or outside the allowed range.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML048', message: 'Sorry, no additional information about selected hotel is available at the moment.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML049', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-in Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML050', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-out Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML051', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML052', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid FlagAvail.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML054', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML057', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Room Type and Child Age mismatch. Please check your request.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML058', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML059', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML060', message: `Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML061', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML062', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Currency Conversion Rate is unavailable: JRVXML062.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML064', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid RoomType.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML065', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid no of adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML066', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Room Type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML067', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid SeqNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML072', message: 'Sorry, the room required is no longer available. Please kindly try another different room type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML074', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: First & Last Name must contain a minimum of 2 Characters.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML075', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Last Name must not exceed 41 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML076', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  First Name must not exceed 26 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML077', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Pax Format.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML078', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Please enter the Pax Information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML079', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Salutation is blank: JRVXML079.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML087', message: `Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. XML Access Prohibited : JRVXML087.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML090', message: 'Sorry, hotel/room you were trying to book is no longer available as Insufficient Data for (Hotel).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML091', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Number of Adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML098', message: 'Sorry, the hotel required is no longer available. You might want to try  similar hotel nearby or call MG customer service for assistence.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML100', message: 'There is an error at supplier end: Booking not successful.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML101', message: 'Sorry, your cancellation limit has expired. This booking has been processed accordingly to all the policies set by hotels. Please kindly contact MG Customer Service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML105', message: 'Sorry, you are unble to cancel / edit due to the policy. Please kindly contact MG customer service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML106', message: 'Pleased to inform you that the reservations have been cancelled successfully.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML107', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Missing mandatory parameter - ResNo: JRVXML107.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML109', message: 'Sorry there have been no reservations found. Please kindly contact MG Customer Service for further assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML110', message: 'Sorry there been an error in your cancellation. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML112', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: OSRefno is not unique. Please update OSRefno.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML115', message: 'Sorry, your current session has expired. Please try login again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML122', message: 'Sorry, hotel/room you were trying to book is no longer available as Maximum allowed length (20 characters) exceeded for OSRefNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML127', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML140', message: 'Sorry we are unable to process your request. Please try to booking a single room type to proceed.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML142', message: 'Sorry there seems to be an error from the hotels in cancelling this booking. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML143', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML144', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML145', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML146', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML147', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML149', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Recheck Request mismatch with Search Request : JRVXML149' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML157', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML159', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be atleast 1 and max 10.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML161', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Something went wrong. Please try again later: JRVXML161' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML170', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML178', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML181', message: 'Sorry we are unable to find records. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML183', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML185', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML187', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML188', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML190', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML191', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML205', message: 'Sorry, it seems like the current cancellation policy has changed to not allow this operation. Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: '500', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: '400', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: '503', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
  ],

  bookErrorMessage: [
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML001', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML002', message: 'Sorry we are unable to confirm this booking due to insufficient balance. Please kindly arrange payment for the existing bookings or contact MG sales to apply for a temporary credit increase.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML003', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML004', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML005', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML007', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML008', message: 'Sorry, the bookings are is to confirm. Please kindly edit the number of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML009', message: 'Sorry, the bookings are is to confirm. Please kindly edit the age of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML010', message: 'Sorry, the hotel/room you were trying to book is no longer available. Please select other Hotel / Rooms.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML011', message: 'Sorry, the hotel/room you were trying to book is no longer available/sold out. Please choose another.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML012', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Wrong date format for Check-in : JRVXML012.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML013', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Wrong date format for Check-out : JRVXML013.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML031', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML032', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML033', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML034', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML035', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML036', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML037', message: 'Sorry, it seems like the selected currency is not available for you to make a booking. Please select other currency to proceed with this booking. If the problem persists kindly contact your MG Sales PIC.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML038', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Check-in and Check-out dates must be within 2 years from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML039', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Booking period exceeds 30 days limit from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML040', message: 'Sorry, hotel/room you were trying to book is no longer available as CheckIn and CheckOut dates cannot be on the same day.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML041', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be between 1 and 8.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML042', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of adults should be between 1 and 4.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML044', message: 'Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML045', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Room information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML046', message: 'The age entered for a child guest is either not valid or outside the allowed range.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML048', message: 'Sorry, no additional information about selected hotel is available at the moment.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML049', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-in Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML050', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-out Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML051', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML052', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid FlagAvail.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML054', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML057', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Room Type and Child Age mismatch. Please check your request.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML058', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML059', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML060', message: `Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML061', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML062', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Currency Conversion Rate is unavailable: JRVXML062.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML064', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid RoomType.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML065', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid no of adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML066', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Room Type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML067', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid SeqNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML072', message: 'Sorry, the room required is no longer available. Please kindly try another different room type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML074', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: First & Last Name must contain a minimum of 2 Characters.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML075', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Last Name must not exceed 41 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML076', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  First Name must not exceed 26 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML077', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Pax Format.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML078', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Please enter the Pax Information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML079', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Salutation is blank: JRVXML079.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML087', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML090', message: 'Sorry, hotel/room you were trying to book is no longer available as Insufficient Data for (Hotel).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML091', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Number of Adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML098', message: 'Sorry, the hotel required is no longer available. You might want to try  similar hotel nearby or call MG customer service for assistence.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML100', message: 'Your booking could not be completed. Please select another room type or hotel and retry or contact customers service for more assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML101', message: 'Sorry, your cancellation limit has expired. This booking has been processed accordingly to all the policies set by hotels. Please kindly contact MG Customer Service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML105', message: 'Sorry, you are unble to cancel / edit due to the policy. Please kindly contact MG customer service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML106', message: 'Pleased to inform you that the reservations have been cancelled successfully.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML107', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Missing mandatory parameter - ResNo: JRVXML107.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML109', message: 'Sorry there have been no reservations found. Please kindly contact MG Customer Service for further assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML110', message: 'Sorry there been an error in your cancellation. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML112', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: OSRefno is not unique. Please update OSRefno.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML115', message: 'Sorry, your current session has expired. Please try login again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML122', message: 'Sorry, hotel/room you were trying to book is no longer available as Maximum allowed length (20 characters) exceeded for OSRefNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML127', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML140', message: 'Sorry we are unable to process your request. Please try to booking a single room type to proceed.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML142', message: 'Sorry there seems to be an error from the hotels in cancelling this booking. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML143', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML144', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML145', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML146', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML147', message: 'Sorry, your requested room is not available. If you need to book this hotel, please try to book other rooms that is available.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML149', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Recheck Request mismatch with Search Request : JRVXML149' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML157', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML159', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be atleast 1 and max 10.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML161', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Something went wrong. Please try again later: JRVXML161' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML170', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML178', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML181', message: 'Sorry we are unable to find records. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML183', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML185', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML187', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML188', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML190', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML191', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML205', message: 'Sorry, it seems like the current cancellation policy has changed to not allow this operation. Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: '500', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: '400', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: '503', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
  ],

  hotelDetailsErrorMessage: [
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML001', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML002', message: 'Sorry we are unable to confirm this booking due to insufficient balance. Please kindly arrange payment for the existing bookings or contact MG sales to apply for a temporary credit increase.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML003', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML004', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML005', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML007', message: 'Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML008', message: 'Sorry, the bookings are is to confirm. Please kindly edit the number of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML009', message: 'Sorry, the bookings are is to confirm. Please kindly edit the age of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML010', message: 'Sorry, the hotel/room you were trying to book is no longer available. Please select other Hotel / Rooms.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML011', message: 'Sorry, the hotel/room you were trying to book is no longer available/sold out. Please choose another.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML012', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Wrong date format for Check-in : JRVXML012.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML013', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Wrong date format for Check-out : JRVXML013.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML031', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML032', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML033', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML034', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML035', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML036', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML037', message: 'Sorry, it seems like the selected currency is not available for you to make a booking. Please select other currency to proceed with this booking. If the problem persists kindly contact your MG Sales PIC.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML038', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Check-in and Check-out dates must be within 2 years from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML039', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Booking period exceeds 30 days limit from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML040', message: 'Sorry, hotel/room you were trying to book is no longer available as CheckIn and CheckOut dates cannot be on the same day.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML041', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be between 1 and 8.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML042', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of adults should be between 1 and 4.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML044', message: 'Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML045', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Room information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML046', message: 'The age entered for a child guest is either not valid or outside the allowed range.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML048', message: 'Sorry, no additional information about selected hotel is available at the moment.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML049', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-in Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML050', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-out Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML051', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML052', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid FlagAvail.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML054', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML057', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Room Type and Child Age mismatch. Please check your request.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML058', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML059', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML060', message: `Sorry, we have no available hotels that match with your requested criteria.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML061', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML062', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Currency Conversion Rate is unavailable: JRVXML062.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML064', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid RoomType.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML065', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid no of adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML066', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Room Type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML067', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid SeqNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML072', message: 'Sorry, the room required is no longer available. Please kindly try another different room type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML074', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: First & Last Name must contain a minimum of 2 Characters.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML075', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Last Name must not exceed 41 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML076', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  First Name must not exceed 26 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML077', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Pax Format.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML078', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Please enter the Pax Information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML079', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Salutation is blank: JRVXML079.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML087', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML090', message: 'Sorry, hotel/room you were trying to book is no longer available as Insufficient Data for (Hotel).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML091', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Number of Adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML098', message: 'Sorry, the hotel required is no longer available. You might want to try  similar hotel nearby or call MG customer service for assistence.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML100', message: 'There is an error at supplier end: Booking not successful.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML101', message: 'Sorry, your cancellation limit has expired. This booking has been processed accordingly to all the policies set by hotels. Please kindly contact MG Customer Service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML105', message: 'Sorry, you are unble to cancel / edit due to the policy. Please kindly contact MG customer service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML106', message: 'Pleased to inform you that the reservations have been cancelled successfully.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML107', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Missing mandatory parameter - ResNo: JRVXML107.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML109', message: 'Sorry there have been no reservations found. Please kindly contact MG Customer Service for further assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML110', message: 'Sorry there been an error in your cancellation. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML112', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: OSRefno is not unique. Please update OSRefno.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML115', message: 'Sorry, your current session has expired. Please try login again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML122', message: 'Sorry, hotel/room you were trying to book is no longer available as Maximum allowed length (20 characters) exceeded for OSRefNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML127', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML140', message: 'Sorry we are unable to process your request. Please try to booking a single room type to proceed.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML142', message: 'Sorry there seems to be an error from the hotels in cancelling this booking. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML143', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML144', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML145', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML146', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML147', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML149', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Recheck Request mismatch with Search Request : JRVXML149' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML157', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML159', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be atleast 1 and max 10.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML161', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML170', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML178', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML181', message: 'Sorry we are unable to find records. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML183', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML185', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML187', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML188', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML190', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML191', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML205', message: 'Sorry, it seems like the current cancellation policy has changed to not allow this operation. Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: '500', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: '400', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: '503', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
  ],

  reservationDetailsErrorMessage: [
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML001', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML002', message: 'Unable to proceed with this booking due to insufficient credit balance. Your Credit Balance seems to be lower than the total booking value. Please contact your MG Sales PIC to increase your credit limit.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML003', message: `Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML004', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML005', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML007', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML008', message: 'Sorry, the bookings are is to confirm. Please kindly edit the number of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML009', message: 'Sorry, the bookings are is to confirm. Please kindly edit the age of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML010', message: 'Sorry, the hotel/room you were trying to book is no longer available. Please select other Hotel / Rooms.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML011', message: 'Sorry, the hotel/room you were trying to book is no longer available/sold out. Please choose another.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML012', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML013', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML031', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML032', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML033', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML034', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML035', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML036', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML037', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML038', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Check-in and Check-out dates must be within 2 years from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML039', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Booking period exceeds 30 days limit from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML040', message: 'Sorry, hotel/room you were trying to book is no longer available as CheckIn and CheckOut dates cannot be on the same day.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML041', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be between 1 and 8.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML042', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of adults should be between 1 and 4.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML044', message: 'Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML045', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Room information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML046', message: 'The age entered for a child guest is either not valid or outside the allowed range.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML048', message: 'Sorry, no additional information about selected hotel is available at the moment.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML049', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-in Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML050', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-out Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML051', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML052', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid FlagAvail.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML054', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML057', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Room Type and Child Age mismatch. Please check your request.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML058', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML059', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML060', message: `Sorry, we have no available hotels that match with your requested criteria.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML061', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML062', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Currency Conversion Rate is unavailable: JRVXML062.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML064', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid RoomType.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML065', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid no of adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML066', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Room Type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML067', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid SeqNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML072', message: 'Sorry, the room required is no longer available. Please kindly try another different room type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML074', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: First & Last Name must contain a minimum of 2 Characters.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML075', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Last Name must not exceed 41 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML076', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  First Name must not exceed 26 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML077', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Pax Format.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML078', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Please enter the Pax Information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML079', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Salutation is blank: JRVXML079.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML087', message: `Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML090', message: 'Sorry, hotel/room you were trying to book is no longer available as Insufficient Data for (Hotel).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML091', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Number of Adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML098', message: 'Sorry, the hotel required is no longer available. You might want to try  similar hotel nearby or call MG customer service for assistence.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML100', message: 'There is an error at supplier end: Booking not successful.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML101', message: 'Sorry, your cancellation limit has expired. This booking has been processed accordingly to all the policies set by hotels. Please kindly contact MG Customer Service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML105', message: 'Sorry, you are unble to cancel / edit due to the policy. Please kindly contact MG customer service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML106', message: 'Pleased to inform you that the reservations have been cancelled successfully.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML107', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Missing mandatory parameter - ResNo: JRVXML107.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML109', message: 'Sorry there have been no reservations found. Please kindly contact MG Customer Service for further assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML110', message: 'Sorry there been an error in your cancellation. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML112', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: OSRefno is not unique. Please update OSRefno.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML115', message: 'Sorry, your current session has expired. Please try login again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML122', message: 'Sorry, hotel/room you were trying to book is no longer available as Maximum allowed length (20 characters) exceeded for OSRefNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML127', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML140', message: 'Sorry we are unable to process your request. Please try to booking a single room type to proceed.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML142', message: 'Sorry there seems to be an error from the hotels in cancelling this booking. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML143', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML144', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML145', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML146', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML147', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML149', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Recheck Request mismatch with Search Request : JRVXML149' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML157', message: `Sorry, we are experiencing some technical error that might be due to your browser settings. Kindly contact MG sales or customer service and we will be happy to assist you in setting the right configuration.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML159', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be atleast 1 and max 10.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML161', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Something went wrong. Please try again later: JRVXML161' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML170', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML178', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML181', message: 'Sorry we are unable to find records. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML183', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML185', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML187', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML188', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML190', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML191', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML205', message: 'Sorry, it seems like the current cancellation policy has changed to not allow this operation. Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: '500', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: '400', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: '503', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
  ],

  cancelReservationErrorMessage: [
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML001', message: `Sorry, there are no results for your search. You might want to edit the fields and try again.` },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML002', message: 'Sorry we are unable to confirm this booking due to insufficient balance. Please kindly arrange payment for the existing bookings or contact MG sales to apply for a temporary credit increase.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML003', message: `Sorry, there are no results for your search. You might want to edit the fields and try again.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML004', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML005', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML007', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML008', message: 'Sorry, the bookings are is to confirm. Please kindly edit the number of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML009', message: 'Sorry, the bookings are is to confirm. Please kindly edit the age of children guest.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML012', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML013', message: 'Sorry, there are no results for your search. You might want to check your date entered and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML031', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML032', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML033', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML034', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML035', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML036', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML037', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML038', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Check-in and Check-out dates must be within 2 years from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML039', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Booking period exceeds 30 days limit from today.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML040', message: 'Sorry, hotel/room you were trying to book is no longer available as CheckIn and CheckOut dates cannot be on the same day.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML041', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be between 1 and 8.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML042', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of adults should be between 1 and 4.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML044', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML045', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Room information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML046', message: 'The age entered for a child guest is either not valid or outside the allowed range.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML048', message: 'Sorry, no additional information about selected hotel is available at the moment.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML049', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-in Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML050', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Check-out Date.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML051', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML052', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML054', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML057', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  Room Type and Child Age mismatch. Please check your request.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML058', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML059', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML060', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML061', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML062', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Currency Conversion Rate is unavailable: JRVXML062.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML064', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid RoomType.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML065', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid no of adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML066', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Room Type.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML067', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid SeqNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML072', message: 'Sorry, we have no available hotels that match with your requested criteria. Please kindly try another hotel.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML074', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: First & Last Name must contain a minimum of 2 Characters.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML075', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Last Name must not exceed 41 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML076', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request:  First Name must not exceed 26 characters (including space).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML077', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Pax Format.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML078', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Please enter the Pax Information.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML079', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Salutation is blank: JRVXML079.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML087', message: `Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. XML Access Prohibited : JRVXML087.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML090', message: 'Sorry, hotel/room you were trying to book is no longer available as Insufficient Data for (Hotel).' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML091', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: Invalid Number of Adults.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML098', message: 'Sorry, the hotel required is no longer available. You might want to try  similar hotel nearby or call MG customer service for assistence.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML100', message: 'There is an error at supplier end: Booking not successful.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML101', message: 'Sorry, your cancellation limit has expired. This booking has been processed accordingly to all the policies set by hotels. Please kindly contact MG Customer Service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML105', message: 'Sorry, you are unble to cancel / edit due to the policy. Please kindly contact MG customer service if you need assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML106', message: 'Pleased to inform you that the reservations have been cancelled successfully.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML107', message: 'Sorry, seems like something went wrong. Please try again after 2 minutes If the problem persists kindly contact your MG Sales PIC. Invalid Request: Missing mandatory parameter - ResNo: JRVXML107.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML109', message: 'Sorry no reservations have been found. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML110', message: 'Sorry there been an error in your cancellation. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML112', message: 'Sorry, hotel/room you were trying to book is no longer available as Invalid Request: OSRefno is not unique. Please update OSRefno.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML115', message: 'Sorry, your current session has expired. Please try login again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML122', message: 'Sorry, hotel/room you were trying to book is no longer available as Maximum allowed length (20 characters) exceeded for OSRefNo.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML127', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML140', message: 'Sorry we are unable to process your request. Please try to booking a single room type to proceed.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML142', message: 'Sorry there seems to be an error from the hotels in cancelling this booking. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML143', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML144', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML145', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML146', message: 'Sorry, please try selecting another currency for this search or booking.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML147', message: 'Sorry, we have no available hotels that match with your requested criteria.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML149', message: 'Sorry, it seems like something went wrong! Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML157', message: `Sorry, there are no results for your search. You might want to edit the fields and try again.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML159', message: 'Sorry, hotel/room you were trying to book is no longer available as Number of rooms should be atleast 1 and max 10.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML161', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line:max-line-length
    { code: 'JRVXML170', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML178', message: 'Welcome back and thinking of MG again. Your login has expired. Please kindly contact our MG Sales to reactivate again. We look forward to having your support again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML181', message: 'Sorry we are unable to find records. Please kindly contact MG customer service for assistance.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML183', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML185', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML187', message: `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.` },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML188', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML190', message: 'Please kindly edit the cancellation date and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML191', message: 'Sorry, there are no results for your search. You might want to edit the fields and try again.' },
    // tslint:disable-next-line: max-line-length
    { code: 'JRVXML205', message: 'Sorry, it seems like the current cancellation policy has changed to not allow this operation. Please try again after 5 minutes. If the problem persists kindly contact sales@mgbedbank.com' },
    // tslint:disable-next-line: max-line-length
    { code: '500', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
    // tslint:disable-next-line: max-line-length
    { code: '400', message: 'Sorry, it seems like the selected currency is not available for you to make a booking. Please select other currency to proceed with this booking. If the problem persists kindly contact your MG Sales PIC.' },
    // tslint:disable-next-line: max-line-length
    { code: '503', message: 'Thanks for thinking of MG as your preferred hotel supplier. We are experiencing high volume of search from all our agent partners like you. Please be patient and try again in 2 minutes.' },
  ],
};
