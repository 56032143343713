import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { map } from 'rxjs/operators';
import { BranchInfoViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/branchinfoviewmodel';
import { AgencyBranchListViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/agencybranchlistviewmodel';
import { SearchCriteriaViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/searchcriteriaviewmodel';
import { BranchListViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/branchlistviewmodel';

@Injectable({
  providedIn: 'root'
})
export class BranchDataService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService, private configurationService: ConfigurationService) { }

  isBranchUnique(agencyId: number, branchId: string, branchName: string, isMainCheck: boolean): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/isBranchUnique?agencyId=' + agencyId + '&branchId=' + branchId +
      '&branchName=' + branchName + '&isMainCheck=' + isMainCheck).pipe(map(data => {
        if (data !== null) {
          if (isMainCheck) {
            return data.result;
          } else {
            return data.isError;
          }
        }
      }));
  }

  createBranch(branchViewModel: BranchInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , branchViewModel, 'api/Branch/Create/');
  }

  getBranchById(id: number): Observable<BranchInfoViewModel> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/GetById?branchId=' + id).pipe(map(data => {
        let branchDto: BranchInfoViewModel;
        branchDto = new BranchInfoViewModel();
        if (data !== null) {
          branchDto = data.result[0];
        }
        return branchDto;
      }));
  }

  getBranchByAgencyId(id: number): Observable<BranchInfoViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/GetBranchByAgancyId?agencyId=' + id).pipe(map(data => {
        let branchDto: BranchInfoViewModel[] = [];
        if (data !== null) {
          branchDto = data.result;
        }
        return branchDto;
      }));
  }

  getAgencyBranchByNameOrCode(searchText: string, onlyActive: boolean, agencyId: number): Observable<AgencyBranchListViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/GetByNameOrCode?searchText=' + searchText + '&onlyActive=' + onlyActive + '&agencyId=' + agencyId).pipe(map(data => {
        let resultDto: AgencyBranchListViewModel[] = [];
        if (data !== null) {
          resultDto = data.result;
        }
        return resultDto;
      }));
  }

  getBranches(request: SearchCriteriaViewModel): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , request, 'api/Branch/Search/').pipe(map(data => {
        return data ? data : [];
      }));
  }

  updateBranch(branchViewModel: BranchInfoViewModel): Observable<any> {
    return this.authenticatedHttpService.put(this.configurationService.config.baseUrls.backofficeApi
      , branchViewModel, 'api/Branch/Update/');
  }

  deleteBranch(branchId: number): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.backofficeApi
      , JSON.stringify(branchId), 'api/Branch/Delete/');
  }
}
