export enum DocType {
    PDF = 0,
    DOC,
    DOCX,
    JPG,
    JPEG,
    PNG
}

export enum ObjectState {
    Unchanged = 0,
    Added,
    Modified,
    Deleted
}

export enum Document {
    All = 0,
    Invoice = 1,
    Voucher = 2,
    Itinerary = 3
  }

  export enum DocumentTypes {
    Agency = 1,
    Hotel = 2,
    Customer = 3,
  }

  export enum ReservationType {
    New,
    Cancel
  }

  export enum SupplierCode {
    MGS = 'MGS',
    MGD = 'MGD'
  }

  export enum PaymentAction {
    Authorization = 1,
    Void = 2,
    Settlement = 3,
    Cancelled = 4
  }

  export enum ActionStatus {
    Success = 1,
    Failed = 2,
    Pending = 3
  }
  
