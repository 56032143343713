import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreadCrumbService } from '../../services/bread-crumb.service';
import { HotelSearchService } from '../../services/hotel-search.service';
import { HotelDataService } from '../../services/hotel-data.service';
import { AuthService } from '../../../../../common/shared/services/auth.service';

export interface BreadCrumb {
  label: string;
  url: string;
  isClickable: boolean;
}

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.css']
})
export class BreadCrumbComponent implements OnInit {
  public breadcrumbs: BreadCrumb[];
  showBreadCrumb = true;
  constructor(private authService: AuthService,
    private router: Router, private route: ActivatedRoute,
    public breadCrumbService: BreadCrumbService,
    private hotelDataService: HotelDataService,
    private hotelSearchService: HotelSearchService) {
      // if ((Object.keys(this.hotelSearchService.destinationSearchDetails).length === 0 &&
      // localStorage.getItem('hotelSearchPanelArray') &&
      // this.hotelSearchService.destinationSearchDetails !== JSON.parse(localStorage.getItem('hotelSearchPanelArray'))) ||
      // (!this.hotelSearchService.destinationSearchDetails && localStorage.getItem('hotelSearchPanelArray'))) {
      //   // Set search panel details
      //   this.hotelSearchService.destinationSearchDetails = JSON.parse(localStorage.getItem('hotelSearchPanelArray'));
      //   this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0] =
      //     new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0]);
      //   this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1] =
      //     new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1]);
      // }

      // if (!this.hotelDataService.selectedHotel && localStorage.getItem('selectedHotel')) {
      //   const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
      //   this.hotelDataService.selectedHotel = selectedHotel;
      // }
     }

  ngOnInit() {
    let breadcrumb: BreadCrumb;
    this.breadCrumbService._isGuestDetailsTransition.subscribe(boolVal => {
      if (boolVal) { this.showBreadCrumb = false; }
    });

    this.breadCrumbService.isHotelSearchUpdate.subscribe(boolVal => {
      if (boolVal) {
      this.breadcrumbs = this.getBreadcrumbs(this.route.root);
      this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];
      }
    });
    // ***************
    const navStartEvents = this.router.events.pipe(filter(event => event instanceof NavigationStart));
    navStartEvents.subscribe((event: NavigationStart) => {
      if ( event.url.toLowerCase().indexOf('hotellist') >= 0) {
        const countryCity: string[]  = this.hotelSearchService.destinationSearchDetails.destination.name.split(',');
        this.hotelSearchService.destinationSearchDetails.destination.type = 'City';
        this.hotelSearchService.destinationSearchDetails.destination.hotelCode = null;
        if (countryCity.length === 3) {
          this.hotelSearchService.destinationSearchDetails.destination.name = countryCity[1].trim() + ',' + countryCity[2];
        } else if (countryCity.length === 2) {
          this.hotelSearchService.destinationSearchDetails.destination.name = countryCity[0].trim() + ',' + countryCity[1];
        }
        localStorage.setItem('hotelSearchPanelArray', JSON.stringify(this.hotelSearchService.destinationSearchDetails));
      }
    });
    // ***********

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if ((Object.keys(this.hotelSearchService.destinationSearchDetails).length === 0
      &&
      localStorage.getItem('hotelSearchPanelArray')
       &&
      this.hotelSearchService.destinationSearchDetails !== JSON.parse(localStorage.getItem('hotelSearchPanelArray')))
      ||
      (!this.hotelSearchService.destinationSearchDetails && localStorage.getItem('hotelSearchPanelArray'))) {
        // Set search panel details
        this.hotelSearchService.destinationSearchDetails = JSON.parse(localStorage.getItem('hotelSearchPanelArray'));
        this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0] =
          new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0]);
        this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1] =
          new Date(this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[1]);
      }

      if (!this.hotelDataService.selectedHotel && localStorage.getItem('selectedHotel')) {
        const selectedHotel = JSON.parse(localStorage.getItem('selectedHotel'));
        this.hotelDataService.selectedHotel = selectedHotel;
      }

      if (this.authService.isLoggedIn()) {
        breadcrumb = { label: 'Home',
          url: 'authenticated/hotelmgmt/hotelinfo',
          isClickable: true
        };
      } else {
        breadcrumb = { label: 'Home',
          url: 'home',
          isClickable: true
        }; }
      // set breadcrumbs
      const root: ActivatedRoute = this.route.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
      this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];
      if (this.breadcrumbs.length === 1 && this.breadcrumbs[0].label.toLocaleLowerCase() === 'home') {
        // this.breadCrumbService.isHomeRoute = true;
        this.showBreadCrumb = false;
      } else {
       // this.breadCrumbService.isHomeRoute = false;
        this.showBreadCrumb = true;
      }
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '',
    breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    //  console.log(route);
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }
    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length === 0) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      const routeLabel: string = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      breadcrumbs = this.breadCrumbService.getBreadcrumbsForRoute(routeURL, routeLabel);
    }
    return breadcrumbs;
  }
}

