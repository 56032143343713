import { GoogleAnalyticsService } from './../../../common/shared/services/google-analytics.service';
import { ConfigurationService } from './../../../common/shared/services/configuration.service';
import { GoogleAnalyticsViewModel } from './../../../common/viewmodels/googleanalyticsviewmodel';
import { HotelBookService } from './../../common/b2b-shared/services/hotel-book.service';
import { FrameworkConfigService } from './../../../framework/fw/services/framework-config.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
import { FormGroup, FormArray } from '@angular/forms';
import { HotelListAndFilterViewModel } from '../../viewmodels/hotel-mgmt/hotellistfilterviewmodel';
import { HotelFilterViewModel } from '../../viewmodels/hotel-mgmt/hotelfilterviewmodel';

import { HotelFilterService } from '../../common/b2b-shared/services/hotel-filter.service';
import { HotelSearchService } from '../../common/b2b-shared/services/hotel-search.service';
import { HotelFilterUpdateViewModel } from '../../viewmodels/hotel-mgmt/hotelfilterupdateviewmodel';
import { FilterDataService } from '../../common/b2b-shared/services/filter-data.service';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { HotelViewModel } from '../../viewmodels/hotel-mgmt/hotelviewmodel';
import { of, Subscription, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PagedDataService } from '../../common/b2b-shared/services/paged-data.service';
import { SorterService } from '../../common/b2b-shared/services/sorter.service';
import { HotelFilterComponent } from '../hotel-filter/hotel-filter.component';
import { JarvisError } from 'src/app/common/jarviserror';
import { Utilities } from 'src/app/common/utilities/utilities';
import { CONSTANTS } from 'src/app/common/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Paginator } from 'primeng/paginator';
import { BreadCrumbService } from '../../common/b2b-shared/services/bread-crumb.service';
import { ErrorCodes } from '../../../common/errorcodes';
import { MatDialogRef, MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { errorConstants } from 'src/app/common/friendlymessage';
import { PushNotificationService } from 'src/app/common/shared/services/pushnotification.service';
import { SearchEventNotification } from '../../viewmodels/push-notification/searcheventnotifications';
import { ScreenService } from 'src/app/framework/fw/services/screen.service';
// import { PagedDataServiceService } from '../../common/b2b-shared/services/paged-data-service.service';


@Component({
  selector: 'app-hotel-list',
  templateUrl: './hotel-list.component.html',
  styleUrls: ['./hotel-list.component.css']
})
export class HotelListComponent implements OnInit, OnDestroy {

  cityName: string;
  hotelFilterList: HotelListAndFilterViewModel;
  hotelListClone: HotelViewModel[] = [];
  filteredHotelListClone: HotelViewModel[] = [];
  hotelFilter: HotelFilterViewModel;
  hotelFilterClone: HotelFilterViewModel;
  isSideNavDisplay: boolean;
  showMapView: boolean;
  totalRecords = 0;
  pageSize = CONSTANTS.listingPage.pagesize;
  currentPageIndex = 0;
  discending = CONSTANTS.sortDirection.discending;
  ascending = CONSTANTS.sortDirection.ascending;
  sortBy: string;
  direction: number;
  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerHotelSearchDialogId: string;
  spinnerDialogTimeout: number;
  spinnerHotelSearchDialogTimeout: number;
  initialViewCheck = false;
  nowSearching = true;
  timeout: any;
  xmlError = errorConstants.searchErrorMessage;
  errorCode: string;
  errorMessage: string;
  apiStartTime: any;
  apiEndTime: any;
  changeDetectionInterval: any;
  pageNumber: number;

  @ViewChild('pp') paginator: Paginator;
  @ViewChild(HotelFilterComponent) hotelFilterComponentRef: HotelFilterComponent;
  @ViewChild('backToTop') backToTop: ElementRef;

  constructor(public hotelDataService: HotelDataService,
    private activatedRoute: ActivatedRoute, private hotelFilterService: HotelFilterService,
    public hotelSearchService: HotelSearchService, private filterDataService: FilterDataService, private pagedDataService: PagedDataService,
    private sorterService: SorterService, public dialog: MatDialog,
    private router: Router, private dialogsService: DialogsService,
    private breadCrumbService: BreadCrumbService, public frameWorkConfigService: FrameworkConfigService,
    public hotelBookService: HotelBookService, public configurationService: ConfigurationService,
    public googleAnalyticsService: GoogleAnalyticsService, public frameworkConfigService: FrameworkConfigService,
    public notificationService: PushNotificationService, public screenService: ScreenService, public cd: ChangeDetectorRef
  ) {
    const subscriptionFilterChange = this.hotelFilterService.hotelFilterChange$.
      subscribe((filter) => this.applyFiltersOnHotelSearchResults(filter));
    this.subscriptions.push(subscriptionFilterChange);

    const subscriptionSearchChange = this.hotelSearchService.hotelSearchChange$.
      subscribe((searchCriteria) => this.updateHotelSearchResults(searchCriteria));
    this.subscriptions.push(subscriptionSearchChange);

    const subscriptionFilterClear = this.hotelFilterService.hotelFilterClear$
      .subscribe(() => this.resetHotelFilterAndSearch());
    this.subscriptions.push(subscriptionFilterClear);

    cd.detach();
    this.changeDetectionInterval = setInterval(() => {
      this.cd.detectChanges();
    }, 100);
  }

  bottomReached(): boolean {
    // tslint:disable-next-line:max-line-length
    const largeScreenThreshold = Math.min(100, document.documentElement.scrollHeight * 0.15);
    return (window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight - largeScreenThreshold);
  }

  scroll = (event): void => {
    if (this.backToTop !== undefined) {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        this.backToTop.nativeElement.style.display = 'inline';
      } else {
        this.backToTop.nativeElement.style.display = 'none';
      }
    }
    if (this.screenService.isBelowLarge()) {
      if (this.bottomReached() && this.pageNumber < this.totalRecords) {
        this.pageNumber++;
        this.applyPagingOnHotelSearchResults(this.pageNumber);
        this.pageSize += CONSTANTS.listingPage.pagesize;
        this.hideSpinner();
        this.cd.detectChanges();
      }
    }
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    // if (this.userProfileService.getUserRole() === CONSTANTS.userRole.CALLCENTER) {
    //   this.callCenterDataService.sendData(true);
    // }
    this.breadCrumbService.GuestDetailsTransition(false);
    this.hotelFilterList = new HotelListAndFilterViewModel();
    this.getHotelList();
    // ***********************************
    // Search on list page
    // this.hotelFilterList = this.activatedRoute.snapshot.data['hotelFilterList'];
    // ***********************************
    // if (!Utilities.isNullOrUndefined(this.hotelFilterList) && !Utilities.isNullOrUndefined(this.hotelFilterList.hotelList)) {
    //   this.hotelFilterList.hotelList = this.sortRoomByIsMapped();
    // }
    // if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
    //   !Utilities.isNullOrUndefined(this.hotelFilterList.hotelFilter) &&
    //   !Utilities.isNullOrUndefined(this.hotelFilterList.hotelFilter.starRatings)) {
    //   this.hotelFilterList.hotelFilter.starRatingsHotelCount = this.getStarRatingsHotelCountFilter();
    // }
    // if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
    //   !Utilities.isNullOrEmptyArray(this.hotelFilterList.hotelList)) {
    //   this.hotelListClone = this.hotelFilterList.hotelList.slice(0);
    //   this.filteredHotelListClone = this.hotelFilterList.hotelList.slice(0);
    // } else {
    //   this.hotelListClone = null;
    //   this.filteredHotelListClone = null;
    // }
    // if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
    //   !Utilities.isNullOrUndefined(this.hotelFilterList.hotelList)) {
    //   this.totalRecords = this.hotelFilterList.hotelList.length;
    //   if (this.totalRecords > 0) {
    //     this.currentPageIndex = 0;
    //     this.applyPagingOnHotelSearchResults(this.currentPageIndex); // Index 0  Means 1st Page
    //   }
    // }
    // this.hotelFilter = (this.hotelFilterList != null &&
    //   this.hotelFilterList.hotelFilter != null) ? this.hotelFilterList.hotelFilter : null;
    // this.hotelFilterClone = (this.hotelFilterList != null &&
    //   this.hotelFilterList.hotelFilter != null) ? this.hotelFilterList.hotelFilter : null;
    // this.isSideNavDisplay = true;
    // this.showMapView = false;
    // // Already the data returned is sorted by price.
    // this.sortBy = 'isMgPreferred';
    // this.direction = 1;
    // this.sort('isMgPreferred', this.ascending);

  }

  sendSearchEventNotifications() {
    if (!this.frameworkConfigService.IsCallCenterUser) {
      const model = new SearchEventNotification();
      model.cityCode = this.hotelSearchService.destinationSearchDetails.destination.cityCode;
      model.hotelCode = this.hotelSearchService.destinationSearchDetails.destination.hotelCode;
      model.checkInDate = this.hotelSearchService.destinationSearchDetails.checkInCheckOutDate[0];
      const subscription = this.notificationService.sendSearchEventNotifications(model).subscribe();
      this.subscriptions.push(subscription);
    }
  }

  getHotelList() {
    this.sendSearchEventNotifications();
    this.nowSearching = true;
    if (this.hotelSearchService.destinationSearchDetails.destination.type.toLowerCase() === 'city') {
      this.hotelSearchService.destinationSearchDetails.destination.hotelCode = null;
    }
    this.showHotelSearchProgressSpinnerSpinner();
    const subscription = this.hotelDataService.getHotelListAndFilters
      (this.hotelSearchService.destinationSearchDetails).subscribe(data => {
        // console.log('Search: get list response at: ' + new Date());
        this.apiStartTime = Date.now();
        this.nowSearching = false;
        if (data !== null) {
          this.hotelFilterList = data;
          const destination = this.hotelSearchService.destinationSearchDetails.destination.name.split(',');
          this.hotelSearchService.cityName = destination[0];
          if (!Utilities.isNullOrUndefined(this.hotelFilterList) && !Utilities.isNullOrUndefined(this.hotelFilterList.hotelList)) {
            // this.hotelFilterList.hotelList = this.sortRoomByIsMapped();
          }
          if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
            !Utilities.isNullOrEmptyArray(this.hotelFilterList.hotelList)) {
            this.hotelListClone = this.hotelFilterList.hotelList.slice(0);
            this.filteredHotelListClone = this.hotelFilterList.hotelList.slice(0);
          } else {
            this.hotelListClone = null;
            this.filteredHotelListClone = null;
          }
          if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
            !Utilities.isNullOrUndefined(this.hotelFilterList.hotelList)) {
            this.totalRecords = this.hotelFilterList.hotelList.length;
            if (this.totalRecords > 0) {
              this.currentPageIndex = 0;
              const eventStartTime = Date.now();
              this.pageNumber = 0;
              if (this.screenService.isLarge()) {
                this.applyPagingOnHotelSearchResults(this.currentPageIndex);
              } else { this.applyPagingOnHotelSearchResults(this.pageNumber); }

              const eventEndTime = Date.now();
              console.log('Search: Pagination Function:');
              console.log(new Date(), eventEndTime.valueOf() - eventStartTime.valueOf());
            }
          }
          // tslint:disable-next-line: max-line-length
          if (!Utilities.isNullOrUndefined(this.hotelFilterList.errorModel) && this.hotelFilterList.errorModel.xmlErrorCode !== null && this.hotelFilterList.errorModel.errorCode === 200) {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Message';
            this.errorMessage = data.errorModel.message;
            let filterData = this.xmlError.filter(error => {
              if (data.errorModel.xmlErrorCode === error.code) {
                this.errorMessage = error.message;
              }
            });
            filterData = {}[''];
            dialogRef.componentInstance.message = this.errorMessage;
            dialogRef.componentInstance.buttonText = 'OK';
          } else {
            this.hotelFilter = (this.hotelFilterList != null &&
              this.hotelFilterList.hotelFilter != null) ? this.hotelFilterList.hotelFilter : null;
            this.hotelFilterClone = (this.hotelFilterList != null &&
              this.hotelFilterList.hotelFilter != null) ? this.hotelFilterList.hotelFilter : null;
            if (this.screenService.isLarge()) {
              this.isSideNavDisplay = true;
            } else {
              this.isSideNavDisplay = false;
            }

            this.showMapView = false;
            // Already the data returned is sorted by price.
            this.sortBy = 'isMgPreferred';
            this.direction = 1;
            this.sort('isMgPreferred', this.ascending);
          }
        }
      },
        (error: JarvisError) => {
          this.hideHotelSearchProgressSpinner();
          let dialogRef: MatDialogRef<MessageDialogComponent>;
          dialogRef = this.dialog.open(MessageDialogComponent);
          dialogRef.componentInstance.title = 'Error';
          dialogRef.componentInstance.message = `${error.friendlyMessage}`;
          dialogRef.componentInstance.buttonText = 'OK';
        }
        ,
        () => {
          this.hideHotelSearchProgressSpinner();
          this.apiEndTime = Date.now();
          console.log('Search API Time:');
          console.log(new Date(), this.apiEndTime.valueOf() - this.apiStartTime.valueOf());
        });
    this.subscriptions.push(subscription);
  }


  showNav() {
    if (this.isSideNavDisplay) {
      this.isSideNavDisplay = false;
    } else { this.isSideNavDisplay = true; }
  }

  isSideNavDisplayHandler(isSideNavDisplay: boolean) {
    this.isSideNavDisplay = isSideNavDisplay;
  }

  showMapOrList() {
    if (this.showMapView) {
      this.showMapView = false;
    } else {
      this.showMapView = true;
    }
  }

  // sortRoomByIsMapped() {
  //   const sortedHotelList = [];
  //   const hotelList = JSON.parse(JSON.stringify(this.hotelFilterList.hotelList));
  //   for (let i = 0; i < hotelList.length; i++) {
  //     hotelList[i].roomCategoryInfo = (hotelList[i].roomCategoryInfo && hotelList[i].roomCategoryInfo.length > 0) ?
  //       this.sorterService.sort(hotelList[i].roomCategoryInfo, 'isMapped', this.ascending) : [];
  //     sortedHotelList.push(hotelList[i]);
  //   }
  //   return sortedHotelList;
  // }

  // getStarRatingsHotelCountFilter() {
  //   const starRatingsHotelCountFilter = [];
  //   for (let i = 0; i < this.hotelFilterList.hotelFilter.starRatings.length; i++) {
  //     let hotelFilterList = JSON.parse(JSON.stringify(this.hotelFilterList));
  //     const starFilterObj = {
  //       'starRating': hotelFilterList.hotelFilter.starRatings[i],
  //       'hotelCount': 0
  //     };

  //     hotelFilterList = hotelFilterList.hotelList.filter(h =>
  //       h.starRating === hotelFilterList.hotelFilter.starRatings[i].toString());

  //     starFilterObj.hotelCount = hotelFilterList.length > 0 ? hotelFilterList.length : 0;
  //     starRatingsHotelCountFilter.push(starFilterObj);
  //   }
  //   return starRatingsHotelCountFilter;
  // }

  // getAreasHotelCountFilter() {
  //   const areasHotelCountFilter = [];
  //   for (let i = 0; i < this.hotelFilterList.hotelFilter.areas.length; i++) {
  //     let hotelFilterList = JSON.parse(JSON.stringify(this.hotelFilterList));
  //     const areasFilterObj = {
  //       'areaId': hotelFilterList.hotelFilter.areas[i].areaId,
  //       'hotelCount': 0
  //     };

  //     hotelFilterList = hotelFilterList.hotelList.filter(h =>
  //       h.areaId === hotelFilterList.hotelFilter.areas[i].areaId);

  //     areasFilterObj.hotelCount = hotelFilterList.length > 0 ? hotelFilterList.length : 0;
  //     areasHotelCountFilter.push(areasFilterObj);
  //   }
  //   return areasHotelCountFilter;
  // }

  applyFiltersOnHotelSearchResults(hotelFilter: HotelFilterUpdateViewModel) {
    const tempHotelList = this.hotelListClone;
    this.hotelFilterList.hotelList = this.filterDataService
      .filterHotel(tempHotelList, hotelFilter);
    this.filteredHotelListClone = this.hotelFilterList.hotelList.slice(0);
    this.totalRecords = this.hotelFilterList.hotelList.length;
    if (this.totalRecords > 0) {
      this.currentPageIndex = 0; // May be need to change instead of 0
      this.pageNumber = 0;  // Index 0  Means 1st Page
      if (this.screenService.isLarge()) {
        this.applyPagingOnHotelSearchResults(this.currentPageIndex);
      } else { this.applyPagingOnHotelSearchResults(this.pageNumber); }

      if (!Utilities.isNullOrUndefined(this.paginator)) {
        this.paginator.changePage(0); // Index 0  Means 1st Page
      }
      this.sort(this.sortBy, this.direction);
    }
  }

  updateHotelSearchResults(hotelSearch) {
    this.nowSearching = true;
    this.hotelFilterList = null;
    this.sendSearchEventNotifications();
    // this.showSpinner();
    this.showHotelSearchProgressSpinnerSpinner();
    const subscription = this.hotelDataService.getHotelListAndFilters(hotelSearch).subscribe((data) => {
      // console.log('Update: get list response at: ' + new Date());
      this.apiStartTime = Date.now();
      this.nowSearching = false;
      this.hideSpinner();
      this.hotelFilterList = data;
      if (!Utilities.isNullOrUndefined(this.hotelFilterList) && !Utilities.isNullOrUndefined(this.hotelFilterList.hotelList)) {
        // this.hotelFilterList.hotelList = this.sortRoomByIsMapped();
      }
      // if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
      //   !Utilities.isNullOrUndefined(this.hotelFilterList.hotelFilter) &&
      //   !Utilities.isNullOrUndefined(this.hotelFilterList.hotelFilter.starRatings)) {
      //     const eventStartTime = Date.now();
      //   this.hotelFilterList.hotelFilter.starRatingsHotelCount = this.getStarRatingsHotelCountFilter();
      //   const eventEndTime = Date.now();
      //         console.log('Update: Star Rating Count:');
      //         console.log(new Date(), eventEndTime.valueOf() - eventStartTime.valueOf());
      // }
      // if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
      //   !Utilities.isNullOrUndefined(this.hotelFilterList.hotelFilter) &&
      //   !Utilities.isNullOrUndefined(this.hotelFilterList.hotelFilter.areas)) {
      //     const eventStartTime = Date.now();
      //   this.hotelFilterList.hotelFilter.areasHotelCount = this.getAreasHotelCountFilter();
      //   const eventEndTime = Date.now();
      //         console.log('Update: Area Count:');
      //         // console.log();
      //         console.log(new Date(), eventEndTime.valueOf() - eventStartTime.valueOf());
      // }

      if (!Utilities.isNullOrUndefined(this.hotelFilterList) &&
        !Utilities.isNullOrEmptyArray(this.hotelFilterList.hotelList)) {
        this.hotelListClone = this.hotelFilterList.hotelList.slice(0);
        this.filteredHotelListClone = this.hotelFilterList.hotelList.slice(0);

        this.hotelFilter = this.hotelFilterList.hotelFilter || null;
        this.hotelFilterClone = this.hotelFilterList.hotelFilter || null;
        if (!Utilities.isNullOrUndefined(this.hotelFilterComponentRef)) {
          this.hotelFilterComponentRef.hotelFilter = this.hotelFilter;
          // const eventStartTime = Date.now();
          this.hotelFilterComponentRef.updateOnHotelSearchChange();
          // const eventEndTime = Date.now();
          //       console.log('Update: Update Filters:');
          //       console.log(new Date(), eventEndTime.valueOf() - eventStartTime.valueOf());
          /* this.totalRecords = this.hotelFilterList.hotelList.length;
          if (this.totalRecords > 0) {
            this.currentPageIndex = 0;
            this.applyPagingOnHotelSearchResults(this.currentPageIndex); // Index 0  Means 1st Page
            if (!Utilities.isNullOrUndefined(this.paginator)) {
              this.paginator.changePage(0); // Index 0  Means 1st Page
            }
            this.sortBy = 'isMgPreferred';
            this.direction = 1;
            this.sort('isMgPreferred', this.ascending);
          } */
        }

        this.totalRecords = this.hotelFilterList.hotelList.length;
        if (this.totalRecords > 0) {
          this.currentPageIndex = 0;
          const eventStartTime = Date.now();
          this.pageSize = CONSTANTS.listingPage.pagesize;
          this.pageNumber = 0;
          if (this.screenService.isLarge()) {
            this.applyPagingOnHotelSearchResults(this.currentPageIndex);
          } else { this.applyPagingOnHotelSearchResults(this.pageNumber); }
          const eventEndTime = Date.now();
          console.log('Update: Pagination Function:');
          console.log(new Date(), eventEndTime.valueOf() - eventStartTime.valueOf());
          if (!Utilities.isNullOrUndefined(this.paginator)) {
            this.paginator.changePage(0); // Index 0  Means 1st Page
          }
          this.sortBy = 'isMgPreferred';
          this.direction = 1;
          this.sort('isMgPreferred', this.ascending);
        }

      } else {
        this.hotelListClone = null;
        this.filteredHotelListClone = null;
      }
      // tslint:disable-next-line: max-line-length
      if (!Utilities.isNullOrUndefined(this.hotelFilterList.errorModel) && this.hotelFilterList.errorModel.xmlErrorCode !== null && this.hotelFilterList.errorModel.errorCode === 200) {
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Message';
        this.errorMessage = data.errorModel.message;
        let filterData = this.xmlError.filter(error => {
          if (data.errorModel.xmlErrorCode === error.code) {
            this.errorMessage = error.message;
          }
        });
        filterData = {}[''];
        dialogRef.componentInstance.message = this.errorMessage;
        dialogRef.componentInstance.buttonText = 'OK';
      }
    }, (error: JarvisError) => {
      this.nowSearching = false;
      // this.hideSpinner();
      this.hideHotelSearchProgressSpinner();
      this.hotelFilterList = null;
      // console.error(`Error in Getting updateHotelSearchResult. ${error.friendlyMessage}`);
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    },
      () => {
        // this.hideSpinner();
        this.hideHotelSearchProgressSpinner();
        this.apiEndTime = Date.now();
        console.log('Update API Time: ');
        console.log(new Date(), this.apiEndTime.valueOf() - this.apiStartTime.valueOf());
        this.nowSearching = false;
      });
    this.subscriptions.push(subscription);
  }

  resetHotelFilterAndSearch() {
    this.hotelFilterList.hotelList = this.hotelListClone.slice(0);
    this.filteredHotelListClone = this.hotelFilterList.hotelList.slice(0);
    this.hotelFilter = this.hotelFilterClone;
    this.hotelFilterComponentRef.resetOnFilterClear();
    this.totalRecords = this.hotelFilterList.hotelList.length;
    if (this.totalRecords > 0) {
      this.currentPageIndex = 0;
      this.pageNumber = 0;
      if (this.screenService.isLarge()) {
        this.applyPagingOnHotelSearchResults(this.currentPageIndex);
      } else { this.applyPagingOnHotelSearchResults(this.pageNumber); }
      if (!Utilities.isNullOrUndefined(this.paginator)) {
        this.paginator.changePage(0); // Index 0  Means 1st Page
      }
      this.sort(this.sortBy, this.direction);
    }
  }

  applyPagingOnHotelSearchResults(pageIndex: number) {
    if (this.screenService.isBelowLarge()) {
      this.pageNumber = pageIndex;
      const skipRecords = (this.pageNumber) * this.pageSize;
      const takeRecords = this.pageSize;
      const tempHotelList = this.filteredHotelListClone;
      if(this.hotelFilterList) {
        this.hotelFilterList.hotelList = this.pagedDataService.pagedDataListForMobile(tempHotelList,
          skipRecords, takeRecords);
      }
    } else {
      const skipRecords = (pageIndex) * this.pageSize;
      const takeRecords = this.pageSize;
      const tempHotelList = this.filteredHotelListClone;
      this.hotelFilterList.hotelList = this.pagedDataService.pagedDataList(tempHotelList,
        skipRecords, takeRecords);
    }
  }

  sort(prop: string, direction: number) {
    this.sortBy = prop;
    this.direction = direction;
    const tempHotelList = this.filteredHotelListClone;
    if (!Utilities.isNullOrEmptyArray(tempHotelList)) {
      this.hotelFilterList.hotelList = this.sorterService.sort(tempHotelList, prop, direction);
      this.totalRecords = this.hotelFilterList.hotelList.length;
      if (this.totalRecords > 0) {
        this.currentPageIndex = 0;
        this.pageNumber = 0;
        if (this.screenService.isLarge()) {
          this.applyPagingOnHotelSearchResults(this.currentPageIndex);
        } else { this.applyPagingOnHotelSearchResults(this.pageNumber); }
        if (!Utilities.isNullOrUndefined(this.paginator)) {
          this.paginator.changePage(0); // Index 0  Means 1st Page
        }
      }
    }
  }

  paginate(event) {
    // event.first = Index of the first record
    // event.rows = Number of rows to display in new page
    // event.page = Index of the new page
    //  console.log('index of new page' + event.page);
    this.currentPageIndex = event.page;
    this.applyPagingOnHotelSearchResults(event.page);
    // event.pageCount = Total number of pages
    this.timeout = setTimeout(function () {
      window.scrollTo(0, 0);
    }, 5);
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  public showHotelSearchProgressSpinnerSpinner() {
    this.spinnerHotelSearchDialogTimeout = setTimeout(() => {
      this.spinnerHotelSearchDialogId = this.dialogsService.showHotelSearchProgressSpinner();
    });
  }

  public hideHotelSearchProgressSpinner() {
    if (this.spinnerHotelSearchDialogId !== null &&
      this.spinnerHotelSearchDialogId !== undefined && this.spinnerHotelSearchDialogId !== '') {
      this.dialogsService.hideHotelSearchProgressSpinner(this.spinnerHotelSearchDialogId);
      this.spinnerHotelSearchDialogId = undefined;
    }
    clearTimeout(this.spinnerHotelSearchDialogTimeout);
    this.spinnerHotelSearchDialogTimeout = undefined;
  }

  // goToPromotionalHotelDetails() {
  //   const searchCount: GoogleAnalyticsViewModel = {
  //     eventCategory: 'Search Count',
  //     eventAction: 'Promotional Banner - Hotel Details',
  //     eventLabel: null,
  //     eventValue: null
  //   };
  //   this.googleAnalyticsService.eventEmitter(searchCount);
  //   this.hotelBookService.bookFromUnbeatableDeals = false;
  //   sessionStorage.setItem('bookFromBanners', JSON.stringify(false));
  //   // localStorage.setItem('bookFromPromotion', JSON.stringify(true));
  //   sessionStorage.setItem('bookFromPromotion', JSON.stringify(true));
  //   this.hotelDataService.selectedHotel = <HotelViewModel>{};
  //   this.hotelDataService.selectedHotel.hotelId = this.configurationService.config.promotionalBannerInfo.hotelCode;
  //   this.hotelDataService.selectedHotel.hotelName = this.configurationService.config.promotionalBannerInfo.hotelName;
  //   localStorage.setItem('selectedHotel', JSON.stringify(this.hotelDataService.selectedHotel));
  //   localStorage.setItem('guestNationalityName', this.hotelSearchService.guestNationalityName);
  //   if (this.frameworkConfigService.IsCallCenterUser) {
  //     this.router.navigate(['../hoteldetails'], { relativeTo: this.activatedRoute });
  //   } else {
  //     this.router.navigate([], { relativeTo: this.activatedRoute }).then(res => {
  //       window.open('authenticated/hotelmgmt/hoteldetails', '_blank');
  //     });
  //   }
  // }

  ngOnDestroy(): void {
    this.hideSpinner();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    clearTimeout(this.timeout);
    window.removeEventListener('scroll', this.scroll, true);
    clearInterval(this.changeDetectionInterval);
  }

  ScrollToTop() {
    if (this.screenService.isBelowLarge()) {
      const scrollHeight = 480;
      window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo(0, 0);
    }
  }
}
