import { Injectable } from '@angular/core';
import { PropertyResolver } from '../property-resolver';

@Injectable({
  providedIn: 'root'
})
export class SorterService {
  property: string = null;
  direction = 1;

  constructor() { }

  sort(collection: any[], prop: any, direction = 1) {
    this.property = prop;
    // this.direction = (this.property === prop) ? this.direction * -1 : 1;
    this.direction = direction;
    return collection.sort((a: any, b: any) => {
      let aVal: any;
      let bVal: any;

      if (prop && prop.indexOf('.') > -1) {
        aVal = PropertyResolver.resolve(prop, a);
        bVal = PropertyResolver.resolve(prop, b);
      } else {
        aVal = a[prop];
        bVal = b[prop];
      }
      if (aVal === bVal) {
        return 0;
      } else if (aVal > bVal) {
        return this.direction * -1;
      } else {
        return this.direction * 1;
      }
    });
  }
}
