import { HotelFilterViewModel } from './hotelfilterviewmodel';

export let hotelFilterResults: HotelFilterViewModel =  {
'hotelName': '',
'fromPrice': 10000,
'toPrice': 20000,
'starRatings': [
    // 1, 2, 3, 4
],
'promotions' : [


],

};
