import { MarkFormDirtyService } from './../../common/b2b-shared/services/mark-form-dirty.service';
import { AgencyProfileDataService } from './../../common/b2b-shared/services/agency-profile-data.service';
import { ConfigurationService } from './../../../common/shared/services/configuration.service';
import { CreditLimitViewModel } from './../../../common/viewmodels/agencyprofile/creditlimitviewmodel';
import { PaymentGatewayRequestViewModel } from './../../viewmodels/payment/paymentgatewayrequestviewmodel';
import { FrameworkConfigService } from './../../../framework/fw/services/framework-config.service';
import { PaymentDataService } from './../../common/b2b-shared/services/payment-data.service';
import { CurrencyApi } from 'src/app/framework/fw/currency/currency-api';
import { PaymentTypeDetailsViewModel } from './../../viewmodels/payment/paymenttypedetailsviewmodel';
import { Component, OnInit, Input, OnDestroy, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { MatDialog, MatDialogRef, MatTabGroup, MatTabChangeEvent } from '@angular/material';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Observable, Subscription, of, timer } from 'rxjs';
import { JarvisError } from '../../../common/jarviserror';
import {
  MessageDialogComponent
} from '../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { HotelSearchService } from '../../common/b2b-shared/services/hotel-search.service';
import { ReservationDataService } from '../../common/b2b-shared/services/reservation-data.service';
import { HotelRecheckService } from '../../common/b2b-shared/services/hotel-recheck.service';
import { HotelRecheckViewModel } from '../../viewmodels/hotel-mgmt/hotelrecheckviewmodel';
import { SalutationViewModel } from '../../viewmodels/registration-forms/salutationviewmodel';
import { ErrorMessages, CustomConfirmMessages, CustomConfirmButtonTexts } from 'src/app/common/errormessage';
import { GuestDetailsValidator } from './guest-details-validator';
import { GuestDetailsService } from '../../common/b2b-shared/services/guest-details.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { HotelDataService } from '../../common/b2b-shared/services/hotel-data.service';
// tslint:disable-next-line:max-line-length
import { RoomDetailsViewModel, BookingRequestViewmodel, BookRoom, GuestDetails } from '../../viewmodels/hotel-mgmt/booking-request-viewmodel';
import { Utilities } from 'src/app/common/utilities/utilities';
import { CancelpopupService } from '../../common/b2b-shared/dialogs/cancelpopup.service';
import { MatSnackBar } from '@angular/material';
import { CONSTANTS } from 'src/app/common/constants';
import { FullBookingResponseDetailsViewmodel } from '../../viewmodels/hotel-mgmt/booking-response-viewmodel';
import { CanComponentDeactivate } from '../../common/b2b-shared/services/guards/can-deactivate-guard.service';
import { ErrorCodes } from 'src/app/common/errorcodes';
import { RecheckRateDialogComponent } from '../hotel-list-view/hotel-list-view.component';
import { BreadCrumbService } from '../../common/b2b-shared/services/bread-crumb.service';
import { GoogleAnalyticsViewModel } from 'src/app/common/viewmodels/googleanalyticsviewmodel';
import { GoogleAnalyticsService } from 'src/app/common/shared/services/google-analytics.service';
import { HotelBookService } from '../../common/b2b-shared/services/hotel-book.service';
import { PaymentAction, ActionStatus } from '../../../common/enum';
import { errorConstants } from 'src/app/common/friendlymessage';
import { HotelRecheckSearchViewModel } from './../../viewmodels/hotel-mgmt/hotelrechecksearchviewmodel';

@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  styleUrls: ['./guest-details.component.css']
})
export class GuestDetailsComponent implements OnInit, OnDestroy, CanComponentDeactivate, AfterViewInit {
  guestDetailsForm: FormGroup;
  adults: FormArray;
  childrens: FormArray;
  isShow = false;
  RoomArray: any = [{ 'Adults': [], 'Childrens': [] }];
  titleArray: any = ['Mr.', 'Mrs.'];
  Rooms: any = [];
  SearchArray: any = [];
  isGuestDetailsPage = true;
  isChecked: boolean;
  reservationsDetails: any; // TODO: assign model
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  subscriptions: Subscription[] = [];
  salutations: SalutationViewModel[];
  guestDetailsSave = [];
  requiredForBooking: number;
  hotelData: any = {};
  confirmCancel = false;
  hotelRecheckData: HotelRecheckViewModel = <HotelRecheckViewModel>{};
  previousHotelRecheckData: HotelRecheckViewModel = <HotelRecheckViewModel>{};
  checkInCheckOut = CONSTANTS.arrCheckInCheckOut;
  noOfBabyCot = CONSTANTS.noOfBabyCot;
  specialRequestConstants = CONSTANTS.SpecialRequestConstants;
  copyPrimaryContact = false;
  // disableIsPrimaryCheckbox = true;
  hideCopyCheckbox = false;
  timeLeftMin = 20;
  timeLeftSec = 0;
  progressBarValue = this.timeLeftMin * 60;
  totalProgressBarValue = this.timeLeftMin * 60;
  progressBarPercentage = 100;
  interval;
  @ViewChild('timer') timer;
  showTimer = false;
  openConfirmationDialog: boolean;
  isConfirmed: boolean;
  isError: boolean;
  // namePattern = /^[^ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9][a-z A-Z]*$/;
  supId: string;
  bookingType: boolean;
  isHoldBooking: boolean;
  isOnRequest: boolean;
  reservationDetailsStatus = CONSTANTS.reservationDetailsStatus;
  recheckXmlError = errorConstants.recheckErrorMessage;
  bookXmlError = errorConstants.bookErrorMessage;
  errorCode: string;
  errorMessage: string;
  redirctTimeInSec = CONSTANTS.redirctTimeInSec;
  paymentTypeDetails: PaymentTypeDetailsViewModel;
  selectedCurrency: string;
  defaultCurrency: string;
  selectedPaymentOption: string;
  paymentOptions = CONSTANTS.paymentOptions;
  @ViewChild('paymentTabGroup') paymentTabGroup: MatTabGroup;
  noRoomAvailableErrorCode = CONSTANTS.noRoomAvailableErrorCode;
  paymentGatewayRequest: any;
  paymentAction = PaymentAction;
  actionStatus = ActionStatus;
  creditLimitDetails: CreditLimitViewModel = new CreditLimitViewModel;
  disableCreditBalance: boolean;
  selectedTabIndex: number;
  creditTabIndex = CONSTANTS.creditTabIndex;
  isButtonClicked: boolean;
  guestDetailsValidations = this.configurationService.config.guestDetailsValidations;
  paymentTypeCodes = CONSTANTS.paymentTypeCodes;
  recheckRequest: HotelRecheckSearchViewModel;
  exchangeRate = 1;
  // bookFromPromotion: boolean = JSON.parse(localStorage.getItem('bookFromPromotion')) || null;
  bookFromPromotion: boolean = JSON.parse(sessionStorage.getItem('bookFromPromotion')) || null;
  bookFromBanners: boolean = JSON.parse(sessionStorage.getItem('bookFromBanners')) || null;
  idrCurrencyCode = CONSTANTS.idrCurrencyCode;
  vndCurrencyCode = CONSTANTS.vndCurrencyCode;
  Math: any;
  previousRoute = '/authenticated/' + this.breadCrumbService.previousRoute;
  isSubmitted: boolean;
  requiredTermsMessage = ErrorMessages.requiredTermsMessage;
  guestNationalityCode = this.hotelRecheckService.recheckSearchDetails.basicSearch.guestNationalitycode;
  paymentGatways = CONSTANTS.PaymentGateway;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  isOnHoldEnableForCC: boolean;

  constructor(private formBuilder: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute, public hotelSearchService: HotelSearchService,
    private reservationDataService: ReservationDataService,
    private dialogsService: DialogsService, private hotelRecheckService: HotelRecheckService,
    public dialog: MatDialog,
    private hotelDataService: HotelDataService,
    private userProfileService: UserProfileService,
    private guestDetailsService: GuestDetailsService,
    private cancelPopupService: CancelpopupService,
    private snackBar: MatSnackBar,
    private breadCrumbService: BreadCrumbService, public googleAnalyticsService: GoogleAnalyticsService,
    public hotelBookService: HotelBookService, public currencyApi: CurrencyApi, public paymentDataService: PaymentDataService,
    public frameworkConfigService: FrameworkConfigService, public configurationService: ConfigurationService,
    public agencyProfileDataService: AgencyProfileDataService, public markFormDirtyService: MarkFormDirtyService
  ) { this.Math = Math; }

  ngOnInit() {
    window.scrollTo(0, 0);
    window.scroll(0, 0);
    this.startTimer();
    this.selectedCurrency = this.currencyApi.selectedCurrency;
    this.salutations = this.activatedRoute.snapshot.data['salutationList'];
    this.paymentTypeDetails = this.activatedRoute.snapshot.data['paymentTypeDetails'];
    this.getRecheckData();
    this.createFormModel();
    this.getRequiredCredit();
    this.getAgencyData(this.agencyId);
    // this.requiredForBooking = this.hotelRecheckData.hotel.roomDetails.grossPrice;
    this.isButtonClicked = false;
    this.isSubmitted = false;
    const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
    // deep copy
    this.recheckRequest = <HotelRecheckSearchViewModel>JSON.parse(JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
    this.recheckRequest.basicSearch.checkInCheckOutDate = q;
  }

  ngAfterViewInit() {
    if (this.paymentTabGroup._tabs.length > 0) {
      this.selectedPaymentOption = this.paymentTabGroup._tabs.first.textLabel;
    }
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler() {
    if (this.timer) {
      if (this.timer.nativeElement.getBoundingClientRect().top < -150) {
        this.showTimer = true;
      } else {
        this.showTimer = false;
      }
    }
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeftSec > 0) {
        this.timeLeftSec--;
        this.progressBarValue--;
        this.progressBarPercentage = (this.progressBarValue / this.totalProgressBarValue) * 100;
      } else {
        this.timeLeftSec = 59;
        this.timeLeftMin--;
      }
      if (this.timeLeftMin === 0 && this.timeLeftSec === 0) {
        this.snackBar.open('You will be redirected to the home page', '', {
          duration: CONSTANTS.toasterTimeOut.duration,
          verticalPosition: 'top', panelClass: 'showSnackBar'
        });
        this.confirmCancel = true;
        this.router.navigate(['/authenticated/hotelmgmt/hotelinfo'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
        window.scroll(0, 0);
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
  getRecheckData() {
    this.hotelRecheckData = this.hotelRecheckService.hotelRecheckDetails;
    this.previousHotelRecheckData = this.hotelRecheckData;
    if (this.hotelRecheckData.hotel.roomDetails.rooms.length === 1) {
      if (this.hotelRecheckData.hotel.roomDetails.isAvail) {
        this.isOnRequest = false;
      } else {
        this.isOnRequest = true;
      }
      this.hotelRecheckData.hotel.roomDetails.rooms.forEach(room => {
        if (room.noOfAdults === 1 && room.noOfChild === 0) {
          this.hideCopyCheckbox = true;
        }
      });
    }
  }
  getRequiredCredit() {
    // tslint:disable-next-line: max-line-length
    this.requiredForBooking = isNaN(this.hotelRecheckData.hotel.roomDetails.grossPrice - this.hotelRecheckData.hotel.roomDetails.commission) ? this.hotelRecheckData.hotel.roomDetails.grossPrice : this.hotelRecheckData.hotel.roomDetails.grossPrice - this.hotelRecheckData.hotel.roomDetails.commission;
  }
  createFormModel() {
    this.guestDetailsForm = new FormGroup({
      agencyReferenceNumber: new FormControl('', {
        // validators: [Validators.pattern('^[a-zA-Z0-9]*$')],
        asyncValidators: [GuestDetailsValidator.agencyReferenceNumberValidator(this.guestDetailsService, this.userProfileService)]
      }),
      isChecked: new FormControl(false),
      specialRequestMessage: new FormControl(''),
      roomDetails: (this.hotelRecheckData.hotel.roomDetails.rooms.length !== 0) ? this.buildRoomDetails() : new FormArray([]),
      isEarlyCheckIn: new FormControl(false),
      checkInTime: new FormControl(),
      isEarlyCheckOut: new FormControl(false),
      checkOutTime: new FormControl(),
      isConnectingRoom: new FormControl(false),
      isSmokingRoom: new FormControl(false),
      isNonSmokingRoom: new FormControl(false),
      isRoomOnTheSameFloor: new FormControl(false),
      isBabyCotRequested: new FormControl(false),
      noOfBabyCot: new FormControl(),
      requestMessage: new FormControl(''),
    });
  }

  get roomDetails(): FormArray {
    return <FormArray>this.guestDetailsForm.get('roomDetails');
  }

  getAdultArrayForRoom(roomIndex: number): FormArray {
    return <FormArray>this.roomDetails.controls[roomIndex].get('adults');
  }

  getChildArrayForRoom(roomIndex: number): FormArray {
    return <FormArray>this.roomDetails.controls[roomIndex].get('children');
  }

  buildRoomDetails(): FormArray {
    let roomDetailGroup: FormGroup;
    let roomDetailArray: FormArray;

    this.hotelRecheckData.hotel.roomDetails.rooms.forEach((room, index) => {
      roomDetailGroup = new FormGroup({
        roomNo: new FormControl(room.roomNo),
        roomName: new FormControl(this.hotelRecheckData.hotel.roomDetails.name),
        mealPlanName: new FormControl(this.hotelRecheckData.hotel.roomDetails.mealPlanName),
        noOfChild: new FormControl(room.noOfChild),
        noOfAdults: new FormControl(room.noOfAdults),
        childAges: new FormControl(room.childAges),
        rateKey: new FormControl(room.rateKey),
        isExtrabed: new FormControl(room.isExtrabed),
        adults: this.buildAdultsDetails(room.noOfAdults),
        children: (room.noOfChild !== null && room.noOfChild !== undefined && room.noOfChild > 0) ?
          this.buildChildsArray(room.noOfChild) : new FormArray([])
      });

      if (room.supplierName !== null && room.supplierName !== undefined && room.supplierName !== '') {
        this.supId = room.supplierName;
      } else {
        this.supId = '0';
      }
      if (roomDetailArray === null || roomDetailArray === undefined) {
        roomDetailArray = new FormArray([roomDetailGroup]);
      } else {
        roomDetailArray.push(roomDetailGroup);
      }
    });

    return roomDetailArray;
  }

  buildAdultsDetails(numberOfAdults: number): FormArray {
    let adultGroup: FormGroup;
    let adultArray: FormArray;
    for (let i = 0; i < numberOfAdults; i++) {
      adultGroup = new FormGroup({
        salutation: new FormControl(this.salutations[0].name, Validators.required),
        firstName: new FormControl('', [Validators.required,
          Validators.pattern(new RegExp(unescape(this.guestDetailsValidations.firstName.pattern))),
          Validators.minLength(this.guestDetailsValidations.firstName.minLength),
          Validators.maxLength(this.guestDetailsValidations.firstName.maxLength)]),
        lastName: new FormControl('', [Validators.required,
          Validators.pattern(new RegExp(unescape(this.guestDetailsValidations.lastName.pattern))),
          Validators.minLength(this.guestDetailsValidations.lastName.minLength),
          Validators.maxLength(this.guestDetailsValidations.lastName.maxLength)])
      });

      if (adultArray === null || adultArray === undefined) {
        adultArray = new FormArray([adultGroup]);
      } else {
        adultArray.push(adultGroup);
      }
    }
    return adultArray;

  }
  buildChildsArray(numberOfChildren: number): FormArray {
    let childrenGroup: FormGroup;
    let childrenArray: FormArray;
    for (let i = 0; i < numberOfChildren; i++) {
      childrenGroup = new FormGroup({
        salutation: new FormControl(this.salutations[0].name, Validators.required),
        firstName: new FormControl('', [Validators.required,
          Validators.pattern(new RegExp(unescape(this.guestDetailsValidations.firstName.pattern))),
          Validators.minLength(this.guestDetailsValidations.firstName.minLength),
          Validators.maxLength(this.guestDetailsValidations.firstName.maxLength)]),
        lastName: new FormControl('', [Validators.required,
          Validators.pattern(new RegExp(unescape(this.guestDetailsValidations.lastName.pattern))),
          Validators.minLength(this.guestDetailsValidations.lastName.minLength),
          Validators.maxLength(this.guestDetailsValidations.lastName.maxLength)])
      });

      if (childrenArray === null || childrenArray === undefined) {
        childrenArray = new FormArray([childrenGroup]);
      } else {
        childrenArray.push(childrenGroup);
      }
    }
    return childrenArray;
  }

  onShow() {
    this.isShow = !this.isShow;
  }

  compareWithRecheckData() {
    this.isSubmitted = true;
    this.markFormDirtyService.markGroupDirty(this.guestDetailsForm);
    if (this.guestDetailsForm.valid && this.guestDetailsForm.dirty && this.guestDetailsForm.get('isChecked').value === true) {
      this.showSpinner();
      this.isButtonClicked = true;
      if (this.isHoldBooking === true) {
        this.bookingType = true;
      } else { this.bookingType = false; }
      // tslint:disable-next-line:max-line-length
      this.hotelRecheckService.recheckSearchDetails.basicSearch.destination.hotelCode = this.recheckRequest.basicSearch.destination.hotelCode;

      const subscription = this.hotelDataService.getRecheckAvailability(this.recheckRequest).
        subscribe((data) => {
          if (data === null) {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Error';
            // tslint:disable-next-line: max-line-length
            dialogRef.componentInstance.message = `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.`;
            dialogRef.componentInstance.buttonText = 'OK';
            dialogRef.disableClose = true;
            this.isButtonClicked = false;
            this.hideSpinner();
          } else {
            // tslint:disable-next-line:max-line-length
            if (!Utilities.isNullOrUndefined(data.errorModel) && data.errorModel.xmlErrorCode !== null && data.errorModel.errorCode === 200) {
              if (data.errorModel.xmlErrorCode === this.noRoomAvailableErrorCode) {
                let dialogRef: MatDialogRef<MessageDialogComponent>;
                dialogRef = this.dialog.open(MessageDialogComponent);
                dialogRef.componentInstance.title = 'Message';
                // tslint:disable-next-line:max-line-length
                this.errorMessage = 'Your dates are popular - We have run out of rooms at this property! Please refine your search criteria.';
                dialogRef.componentInstance.message = this.errorMessage;
                dialogRef.componentInstance.buttonText = 'OK';
                dialogRef.disableClose = true;
                this.isButtonClicked = false;
                this.hideSpinner();
                return dialogRef.afterClosed().subscribe(result => {
                  this.guestDetailsForm.markAsPristine();
                  this.router.navigate(['/authenticated/hotelmgmt/hotellist']);
                });
              } else {
                let dialogRef: MatDialogRef<MessageDialogComponent>;
                dialogRef = this.dialog.open(MessageDialogComponent);
                dialogRef.componentInstance.title = 'Message';
                this.errorMessage = data.errorModel.message;
                let filterData = this.recheckXmlError.filter(error => {
                  if (data.errorModel.xmlErrorCode === error.code) {
                    this.errorMessage = error.message;
                  }
                });
                filterData = {}[''];
                dialogRef.componentInstance.message = this.errorMessage;
                dialogRef.componentInstance.buttonText = 'OK';
                dialogRef.disableClose = true;
                this.isButtonClicked = false;
                this.hideSpinner();
                return dialogRef.afterClosed().subscribe(result => {
                  this.guestDetailsForm.markAsPristine();
                  this.router.navigate(['/authenticated/hotelmgmt/hotellist']);
                });
              }
            } else {
              this.hotelRecheckService.hotelRecheckDetails = data;
              this.hotelRecheckService.recheckSearchDetails.cancellationPolicyType = data.hotel.roomDetails.cancellationPolicyType;
              this.hotelRecheckService.recheckSearchDetails.packageRate = data.hotel.roomDetails.packageRate;
              this.hotelRecheckData = data;
            }
          }
          if (data.hotel !== undefined) {
            if ((this.previousHotelRecheckData.hotel.roomDetails.grossPrice !== data.hotel.roomDetails.grossPrice) &&
               // tslint:disable-next-line:max-line-length
               ((data.hotel.roomDetails.grossPrice - this.previousHotelRecheckData.hotel.roomDetails.grossPrice) > 0 ? true : false) && (this.selectedPaymentOption === this.paymentOptions.qris) && (Math.ceil(data.hotel.roomDetails.netPrice) > 10000000)) {
              let dialogRef: MatDialogRef<RecheckRateDialogComponent>;
              // dialogRef.disableClose = true;
              dialogRef = this.dialog.open(RecheckRateDialogComponent, {
                data: {
                  updatedGrossPrice: data.hotel.roomDetails.grossPrice,
                  oldGrossPrice: this.previousHotelRecheckData.hotel.roomDetails.grossPrice,
                  grossPriceDiff: (data.hotel.roomDetails.grossPrice
                    - this.previousHotelRecheckData.hotel.roomDetails.grossPrice),
                  isAvailable: data.hotel.roomDetails.isAvail,
                  currency: data.currency,
                  // tslint:disable-next-line:max-line-length
                  isCreditCard: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ? true : false,
                  // tslint:disable-next-line:max-line-length
                  updatedNetPrice: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ? Math.ceil(data.hotel.roomDetails.netPrice)
                    : data.hotel.roomDetails.netPrice,
                  // tslint:disable-next-line:max-line-length
                  oldNetPrice: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ? Math.ceil(this.previousHotelRecheckData.hotel.roomDetails.netPrice)
                    : this.previousHotelRecheckData.hotel.roomDetails.netPrice,
                  // tslint:disable-next-line:max-line-length
                  netPriceDiff: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ?
                    (Math.ceil(data.hotel.roomDetails.netPrice) - Math.ceil(this.previousHotelRecheckData.hotel.roomDetails.netPrice)) :
                    (data.hotel.roomDetails.netPrice - this.previousHotelRecheckData.hotel.roomDetails.netPrice),
                  isSecondRecheck: true,
                  isQRISDialog: true
                }
              });
              return dialogRef.afterClosed().subscribe(result => {
                  this.guestDetailsForm.markAsPristine();
                  this.router.navigate(['/authenticated/hotelmgmt/hotellist']);
              });
            } else if ((this.previousHotelRecheckData.hotel.roomDetails.grossPrice !== data.hotel.roomDetails.grossPrice) &&
              (data.hotel.roomDetails.grossPrice - this.previousHotelRecheckData.hotel.roomDetails.grossPrice) > 0 ? true : false ||
              ((this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris))
              || this.selectedPaymentOption === this.paymentOptions.bankTransfer) {

              let dialogRef: MatDialogRef<RecheckRateDialogComponent>;
              // dialogRef.disableClose = true;
              dialogRef = this.dialog.open(RecheckRateDialogComponent, {
                data: {
                  updatedGrossPrice: data.hotel.roomDetails.grossPrice,
                  oldGrossPrice: this.previousHotelRecheckData.hotel.roomDetails.grossPrice,
                  grossPriceDiff: (data.hotel.roomDetails.grossPrice
                    - this.previousHotelRecheckData.hotel.roomDetails.grossPrice),
                  isAvailable: data.hotel.roomDetails.isAvail,
                  currency: data.currency,
                  // tslint:disable-next-line:max-line-length
                  isCreditCard: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ? true : false,
                  // tslint:disable-next-line:max-line-length
                  updatedNetPrice: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ? Math.ceil(data.hotel.roomDetails.netPrice)
                    : data.hotel.roomDetails.netPrice,
                  // tslint:disable-next-line:max-line-length
                  oldNetPrice: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ? Math.ceil(this.previousHotelRecheckData.hotel.roomDetails.netPrice)
                    : this.previousHotelRecheckData.hotel.roomDetails.netPrice,
                  // tslint:disable-next-line:max-line-length
                  netPriceDiff: this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer ?
                    (Math.ceil(data.hotel.roomDetails.netPrice) - Math.ceil(this.previousHotelRecheckData.hotel.roomDetails.netPrice)) :
                    (data.hotel.roomDetails.netPrice - this.previousHotelRecheckData.hotel.roomDetails.netPrice),
                  isSecondRecheck: true,
                  isQRISDialog: false
                }
              });
              return dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                  // this.router.navigate(['../guestdetails'], { relativeTo: this.activatedRoute });
                  this.completeBooking();
                } else {
                  this.guestDetailsForm.markAsPristine();
                  this.router.navigate(['/authenticated/hotelmgmt/hotellist']);
                }
              });

            } else {
              // this.router.navigate(['../guestdetails'], { relativeTo: this.activatedRoute });
              this.completeBooking();
            }
          }
          window.scrollTo(0, 0);
          window.scroll(0, 0);
        },
          (error: JarvisError) => {
            this.isButtonClicked = false;
            this.hideSpinner();
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Error';
            // tslint:disable-next-line: max-line-length
            dialogRef.componentInstance.message = `We're sorry, but due to real-time availability, the room category/type you requested is no longer available. Please make an alternative selection or contact our customer service team who will be happy to assist.`;
            dialogRef.componentInstance.buttonText = 'OK';
            dialogRef.disableClose = true;
          },
          () => {
            this.isButtonClicked = false;
            this.hideSpinner();
          });
      this.subscriptions.push(subscription);
    }
  }

  completeBooking() {
    this.openConfirmationDialog = true;
    this.breadCrumbService.GuestDetailsTransition(true);
    const guestDetails = Object.assign({}, this.guestDetailsSave, this.guestDetailsForm.value);
    const bookRoom: BookRoom[] = [];
    let guestDetail: GuestDetails[] = [];

    guestDetails.roomDetails.forEach((room, index) => {
      guestDetail = [];
      room.adults.forEach(adult => {
        const a: GuestDetails = {
          salutation: adult.salutation,
          firstName: adult.firstName.trim(),
          lastName: adult.lastName.trim(),
          age: null
        };
        guestDetail.push(a);
      });
      room.children.forEach((child, childIndex) => {
        const b: GuestDetails = {
          salutation: child.salutation,
          firstName: child.firstName.trim(),
          lastName: child.lastName.trim(),
          age: room.childAges[childIndex].childAge
        };
        guestDetail.push(b);
      });
      const roomD: BookRoom = {
        roomNumber: this.hotelSearchService.destinationSearchDetails.roomDetails[index].roomNumber,
        noOfAdults: this.hotelSearchService.destinationSearchDetails.roomDetails[index].noOfAdults,
        noOfChildren: this.hotelSearchService.destinationSearchDetails.roomDetails[index].noOfChildren,
        childAges: this.hotelSearchService.destinationSearchDetails.roomDetails[index].childAges,
        ratekey: room.rateKey,
        isExtrabed: room.isExtrabed,
        guestDetail: guestDetail
      };
      bookRoom.push(roomD);
    });

    const roomDetails: RoomDetailsViewModel = {
      cancellationPolicyType: this.hotelRecheckData.hotel.roomDetails.cancellationPolicyType,
      code: this.hotelRecheckData.hotel.roomDetails.code,
      mealCode: this.hotelRecheckData.hotel.roomDetails.mealPlan,
      room: bookRoom,
      packageRate: this.hotelRecheckData.hotel.roomDetails.packageRate
    };

    const specialRequestArray = [];

    if (guestDetails.isEarlyCheckIn === true) {
      specialRequestArray.push(this.specialRequestConstants.earlyCheckIn + ' ' + guestDetails.checkInTime);
    }
    if (guestDetails.isEarlyCheckOut === true) {
      specialRequestArray.push(this.specialRequestConstants.lateCheckOut + ' ' + guestDetails.checkOutTime);
    }
    if (guestDetails.isConnectingRoom === true) {
      specialRequestArray.push(this.specialRequestConstants.connectingRoom);
    }
    if (guestDetails.isSmokingRoom === true) {
      specialRequestArray.push(this.specialRequestConstants.smokingRoom);
    }
    if (guestDetails.isNonSmokingRoom === true) {
      specialRequestArray.push(this.specialRequestConstants.nonSmokingRoom);
    }
    if (guestDetails.isRoomOnTheSameFloor === true) {
      specialRequestArray.push(this.specialRequestConstants.sameFloorRoom);
    }
    if (guestDetails.isBabyCotRequested === true) {
      specialRequestArray.push(this.specialRequestConstants.requestBabyCot + ' ' + guestDetails.noOfBabyCot);
    }
    if (guestDetails.requestMessage !== '') {
      specialRequestArray.push(guestDetails.requestMessage);
    }

    const bookingRequestViewmodel = new BookingRequestViewmodel();
    bookingRequestViewmodel.sessionId = this.hotelRecheckData.sessionID;
    bookingRequestViewmodel.agencyBookingId = this.guestDetailsForm.get('agencyReferenceNumber').value.trim();
    bookingRequestViewmodel.checkIn = this.hotelRecheckData.checkIn;
    bookingRequestViewmodel.checkOut = this.hotelRecheckData.checkOut;
    bookingRequestViewmodel.currency = this.hotelRecheckData.currency;
    bookingRequestViewmodel.hotelCode = this.hotelRecheckData.hotel.hotelId;
    bookingRequestViewmodel.isAvailable = this.hotelRecheckData.hotel.roomDetails.isAvail;
    bookingRequestViewmodel.isCopyGuestDetail = false;
    bookingRequestViewmodel.isOnHold = this.bookingType;
    bookingRequestViewmodel.nationality = this.hotelRecheckService.recheckSearchDetails.basicSearch.guestNationalitycode;
    bookingRequestViewmodel.specialRequest = specialRequestArray.join(', ');
    bookingRequestViewmodel.roomDetail = roomDetails;
    if (this.selectedPaymentOption === this.paymentOptions.creditCard) {
      bookingRequestViewmodel.paymentType = this.paymentTypeCodes.creditCard;
    } else if (this.selectedPaymentOption === this.paymentOptions.wireTransfer) {
      bookingRequestViewmodel.paymentType = this.paymentTypeCodes.wireTransfer;
    } else if (this.selectedPaymentOption === this.paymentOptions.qris) {
      bookingRequestViewmodel.paymentType = this.paymentTypeCodes.qris;
    } else if (this.selectedPaymentOption === this.paymentOptions.bankTransfer) {
      bookingRequestViewmodel.paymentType = this.paymentTypeCodes.bankTransfer;
    }
    const subscription = this.hotelDataService.confirmBooking(bookingRequestViewmodel).subscribe((response) => {

      const bookingResponse: FullBookingResponseDetailsViewmodel = response;
      if (bookingResponse !== null) {
        if (bookingResponse.status) {
          window.scrollTo(0, 0);
          window.scroll(0, 0);

          if (!this.isHoldBooking && this.selectedPaymentOption === this.paymentOptions.creditCard) {
            this.paymentTypeDetails.mGBookingID = bookingResponse.bookingDetails.mgBookingID;
            this.paymentTypeDetails.docId = bookingResponse.bookingDetails.mgBookingVersionID;
            this.paymentTypeDetails.supplierPaymentTypeCode = bookingResponse.bookingDetails.supplierPaymentTypeCode;
            this.paymentTypeDetails.netPrice = this.hotelRecheckData.hotel.roomDetails.netPrice;
            this.paymentTypeDetails.grossPrice = this.hotelRecheckData.hotel.roomDetails.grossPrice;
            this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.creditCard;
            // this.paymentDataService.paymentTypeDetails = this.paymentTypeDetails;
            // localStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
            // localStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
            // localStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
            // localStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
            const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
            const checkindate = q[0].toLocaleDateString('en-US');
            const checkoutdate = q[1].toLocaleDateString('en-US');
            const checkInCheckoutDate = [];
            checkInCheckoutDate[0] = checkindate;
            checkInCheckoutDate[1] = checkoutdate;
            this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate = checkInCheckoutDate;
            sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
            sessionStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
            sessionStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
            sessionStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
            sessionStorage.setItem('bookFromChainDeals', JSON.stringify(this.hotelBookService.bookFromChainDeals));
            // this.paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails'));
            this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
            // tslint:disable-next-line:max-line-length

            if (this.paymentTypeDetails.ccPaymentGatewayCode !== null &&
              this.paymentTypeDetails.ccPaymentGatewayCode.toUpperCase() === this.paymentGatways.asiaPay) {
              const subscription3 = this.paymentDataService.getSecureHash(bookingResponse.bookingDetails.mgBookingID).subscribe(data => {
                const authorizationRequest = {
                  merchantId: this.paymentTypeDetails.ccMerchantId,
                  // tslint:disable-next-line:max-line-length
                  amount: this.currencyApi.selectedCurrency === CONSTANTS.idrCurrencyCode || this.currencyApi.selectedCurrency === CONSTANTS.vndCurrencyCode ?
                    Math.ceil(this.paymentTypeDetails.netPrice) : this.paymentTypeDetails.netPrice,
                  orderRef: this.paymentTypeDetails.mGBookingID,
                  currCode: this.paymentTypeDetails.currencyCode,
                  mpsMode: 'NIL',
                  successUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentsuccess`,
                  failUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentfailed`,
                  cancelUrl: `${window.location.protocol}//${window.location.host}/authenticated/payment/paymentcancelled`,
                  payType: 'H',
                  lang: 'E',
                  payMethod: 'CC',
                  secureHash: data
                };
                this.paymentGatewayRequest = {
                  mGBookingId: this.paymentTypeDetails.mGBookingID,
                  creditCardConfigurationId: this.paymentTypeDetails.ccPaymentGatewayId,
                  amount: this.paymentTypeDetails.netPrice,
                  status: this.actionStatus[this.actionStatus.Pending],
                  action: this.paymentAction[this.paymentAction.Authorization],
                  authorizationRequest: JSON.stringify(authorizationRequest),
                  merchantId: this.paymentTypeDetails.ccMerchantId
                };
                const subscription1 = this.paymentDataService.updatePaymentDetails(this.paymentGatewayRequest).subscribe(data1 => {
                  this.router.navigate(['/authenticated/payment/paymentgateway']);
                },
                (error: JarvisError) => console.error(`Error in Update Payment Details. ${error.friendlyMessage}`));
                this.subscriptions.push(subscription1);
              },
                (error: JarvisError) => console.error(`Error in Getting Secure Hash Key. ${error.friendlyMessage}`));
              this.subscriptions.push(subscription3);
            } else if (this.paymentTypeDetails.ccPaymentGatewayCode !== null &&
              this.paymentTypeDetails.ccPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
              this.paymentGatewayRequest = {
                mGBookingId: this.paymentTypeDetails.mGBookingID,
                creditCardConfigurationId: this.paymentTypeDetails.ccPaymentGatewayId,
                amount: this.paymentTypeDetails.netPrice,
                status: this.actionStatus[this.actionStatus.Pending],
                action: this.paymentAction[this.paymentAction.Authorization],
                merchantId: this.paymentTypeDetails.ccMerchantId,
                actualAmount: this.paymentTypeDetails.netPrice
              };
              const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
                this.router.navigate(['/authenticated/payment/paymentgateway']);
              },
              (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
              this.subscriptions.push(subscription1);
            }
          } else if (!this.isHoldBooking && this.selectedPaymentOption === this.paymentOptions.qris) {
            this.paymentTypeDetails.mGBookingID = bookingResponse.bookingDetails.mgBookingID;
            this.paymentTypeDetails.docId = bookingResponse.bookingDetails.mgBookingVersionID;
            this.paymentTypeDetails.supplierPaymentTypeCode = bookingResponse.bookingDetails.supplierPaymentTypeCode;
            this.paymentTypeDetails.netPrice = this.hotelRecheckData.hotel.roomDetails.netPrice;
            this.paymentTypeDetails.grossPrice = this.hotelRecheckData.hotel.roomDetails.grossPrice;
            this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.qris;
            // this.paymentDataService.paymentTypeDetails = this.paymentTypeDetails;
            // localStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
            // localStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
            // localStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
            // localStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
            const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
            const checkindate = q[0].toLocaleDateString('en-US');
            const checkoutdate = q[1].toLocaleDateString('en-US');
            const checkInCheckoutDate = [];
            checkInCheckoutDate[0] = checkindate;
            checkInCheckoutDate[1] = checkoutdate;
            this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate = checkInCheckoutDate;
            sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
            sessionStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
            sessionStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
            sessionStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
            sessionStorage.setItem('bookFromChainDeals', JSON.stringify(this.hotelBookService.bookFromChainDeals));
            // this.paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails'));
            this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
            // tslint:disable-next-line:max-line-length

            // Note: QRIS is only implemented for XENDIT
            if (this.paymentTypeDetails.qrisPaymentGatewayCode !== null &&
              this.paymentTypeDetails.qrisPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
              this.paymentGatewayRequest = {
                mGBookingId: this.paymentTypeDetails.mGBookingID,
                creditCardConfigurationId: this.paymentTypeDetails.qrisPaymentGatewayId,
                amount: this.paymentTypeDetails.netPrice,
                status: this.actionStatus[this.actionStatus.Pending],
                action: this.paymentAction[this.paymentAction.Authorization],
                merchantId: this.paymentTypeDetails.qrisMerchantId,
                actualAmount: this.paymentTypeDetails.netPrice
              };
              const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
                this.router.navigate(['/authenticated/payment/paymentgateway']);
              },
              (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
              this.subscriptions.push(subscription1);
            }
          } else if (!this.isHoldBooking && this.selectedPaymentOption === this.paymentOptions.bankTransfer) {
            this.paymentTypeDetails.mGBookingID = bookingResponse.bookingDetails.mgBookingID;
            this.paymentTypeDetails.docId = bookingResponse.bookingDetails.mgBookingVersionID;
            this.paymentTypeDetails.supplierPaymentTypeCode = bookingResponse.bookingDetails.supplierPaymentTypeCode;
            this.paymentTypeDetails.netPrice = this.hotelRecheckData.hotel.roomDetails.netPrice;
            this.paymentTypeDetails.grossPrice = this.hotelRecheckData.hotel.roomDetails.grossPrice;
            this.paymentTypeDetails.selectedPaymentTypeCode = this.paymentTypeCodes.bankTransfer;
            // this.paymentDataService.paymentTypeDetails = this.paymentTypeDetails;
            // localStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
            // localStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
            // localStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
            // localStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
            const q = this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate;
            const checkindate = q[0].toLocaleDateString('en-US');
            const checkoutdate = q[1].toLocaleDateString('en-US');
            const checkInCheckoutDate = [];
            checkInCheckoutDate[0] = checkindate;
            checkInCheckoutDate[1] = checkoutdate;
            this.hotelRecheckService.recheckSearchDetails.basicSearch.checkInCheckOutDate = checkInCheckoutDate;
            sessionStorage.setItem('paymentTypeDetails', JSON.stringify(this.paymentTypeDetails));
            sessionStorage.setItem('recheckSearchDetails', JSON.stringify(this.hotelRecheckService.recheckSearchDetails));
            sessionStorage.setItem('bookingRequest', JSON.stringify(bookingRequestViewmodel));
            sessionStorage.setItem('bookFromUnbeatableDeals', JSON.stringify(this.hotelBookService.bookFromUnbeatableDeals));
            sessionStorage.setItem('bookFromChainDeals', JSON.stringify(this.hotelBookService.bookFromChainDeals));
            // this.paymentTypeDetails = JSON.parse(localStorage.getItem('paymentTypeDetails'));
            this.paymentTypeDetails = JSON.parse(sessionStorage.getItem('paymentTypeDetails'));
            // tslint:disable-next-line:max-line-length

            // Note: Bank Transfer is only implemented for XENDIT
            if (this.paymentTypeDetails.btPaymentGatewayCode !== null &&
              this.paymentTypeDetails.btPaymentGatewayCode.toUpperCase() === this.paymentGatways.xendit) {
              this.paymentGatewayRequest = {
                mGBookingId: this.paymentTypeDetails.mGBookingID,
                creditCardConfigurationId: this.paymentTypeDetails.btPaymentGatewayId,
                amount: this.paymentTypeDetails.netPrice,
                status: this.actionStatus[this.actionStatus.Pending],
                action: this.paymentAction[this.paymentAction.Authorization],
                merchantId: this.paymentTypeDetails.btMerchantId,
                actualAmount: this.paymentTypeDetails.netPrice
              };
              const subscription1 = this.paymentDataService.updateXenditPaymentStatus(this.paymentGatewayRequest).subscribe(data1 => {
                this.router.navigate(['/authenticated/payment/paymentgateway']);
              },
              (error: JarvisError) => console.error(`Error in Update Xendit Payment Details. ${error.friendlyMessage}`));
              this.subscriptions.push(subscription1);
            }
          } else {
            if (bookingResponse.bookingDetails.status.toLocaleLowerCase() === this.reservationDetailsStatus.RESERVED.toLocaleLowerCase()) {
              this.isOnRequest = true;
            } else {
              this.isConfirmed = true;
            }
            this.isError = false;
            const googleAnalyticsHotelBook: GoogleAnalyticsViewModel = {
              eventCategory: 'Hotel',
              eventAction: 'Book',
              eventLabel: null,
              eventValue: null
            };
            // tslint:disable-next-line:max-line-length
            this.googleAnalyticsService.customHotelBookBrowseEventEmitter(this.hotelRecheckData.hotel.hotelName, googleAnalyticsHotelBook);
            if (this.hotelBookService.bookFromUnbeatableDeals === true) {
              const googleAnalyticsBookFrom: GoogleAnalyticsViewModel = {
                eventCategory: 'Booked From',
                eventAction: 'Unbeatable Deals',
                eventLabel: null,
                eventValue: null
              };
              this.googleAnalyticsService.eventEmitter(googleAnalyticsBookFrom);
            } else if (this.hotelBookService.bookFromChainDeals === true) {
              const googleAnalyticsBookFrom: GoogleAnalyticsViewModel = {
                eventCategory: 'Booked From',
                eventAction: 'Chain Deals',
                eventLabel: null,
                eventValue: null
              };
              this.googleAnalyticsService.eventEmitter(googleAnalyticsBookFrom);
            } else if (this.bookFromPromotion) {
              const googleAnalyticsBookFrom: GoogleAnalyticsViewModel = {
                eventCategory: 'Booked From',
                eventAction: 'Promotional Banner - Hotel Details',
                eventLabel: null,
                eventValue: null
              };
              this.googleAnalyticsService.eventEmitter(googleAnalyticsBookFrom);
            } else if (this.bookFromBanners === true) {
              const googleAnalyticsBookFrom: GoogleAnalyticsViewModel = {
                eventCategory: 'Booked From',
                eventAction: 'Banners',
                eventLabel: null,
                eventValue: null
              };
              this.googleAnalyticsService.eventEmitter(googleAnalyticsBookFrom);
            } else {
              const googleAnalyticsBookFrom: GoogleAnalyticsViewModel = {
                eventCategory: 'Booked From',
                eventAction: 'Hotel Details/List',
                eventLabel: null,
                eventValue: null
              };
              this.googleAnalyticsService.eventEmitter(googleAnalyticsBookFrom);
            }

            this.startRedirectTimer();
            setTimeout(() => {
              this.router.navigate(['../completebooking'], { relativeTo: this.activatedRoute });
            }, this.redirctTimeInSec * 1000);

            window.scrollTo(0, 0);
            window.scroll(0, 0);
          }
        } else {
          // tslint:disable-next-line: max-line-length
           if (!Utilities.isNullOrUndefined(response.errorModel) && response.errorModel.xmlErrorCode !== null && response.errorModel.errorCode === 200) {
            let dialogRef: MatDialogRef<MessageDialogComponent>;
            dialogRef = this.dialog.open(MessageDialogComponent);
            dialogRef.componentInstance.title = 'Message';
            this.errorMessage = response.errorModel.message;
            let filterData = this.bookXmlError.filter(error => {
              if (response.errorModel.xmlErrorCode === error.code) {
                this.errorMessage = error.message;
              }
            });
            filterData = {}[''];
            dialogRef.componentInstance.message = this.errorMessage;
            dialogRef.componentInstance.buttonText = 'OK';
            dialogRef.disableClose = true;
            return dialogRef.afterClosed().subscribe(result => {
              this.guestDetailsForm.markAsPristine();
              this.isConfirmed = true;
              this.isError = true;
              this.startRedirectTimer();
              setTimeout(() => {
                this.router.navigate(['/authenticated/bookingmgmt/reservationList'], { relativeTo: this.activatedRoute });
              }, this.redirctTimeInSec * 1000);
            });
          } /* else {
            this.isConfirmed = true;
            this.isError = true;
            this.startRedirectTimer();
            setTimeout(() => {
              this.router.navigate(['/authenticated/bookingmgmt/reservationList'], { relativeTo: this.activatedRoute });
            }, this.redirctTimeInSec * 1000);
          } */
        }
      } else {
        this.isConfirmed = true;
        this.isError = true;
        this.startRedirectTimer();
        setTimeout(() => {
          this.router.navigate(['/authenticated/bookingmgmt/reservationList'], { relativeTo: this.activatedRoute });
        }, this.redirctTimeInSec * 1000);
      }
    },
      (error: JarvisError) => {
        this.hideSpinner();
        let dialogRef: MatDialogRef<MessageDialogComponent>;
        dialogRef = this.dialog.open(MessageDialogComponent);
        dialogRef.componentInstance.title = 'Error';
        // tslint:disable-next-line:max-line-length
        dialogRef.componentInstance.message = 'Thank you for thinking of MG as your preferred hotel supplier. It seems like we lost you for a moment when you were making a booking. To ensure that the booking is not duplicated, we will redirect you to View Reservation List now. Please check the status of your booking request as it may have been confirmed while you were disconnected';
        dialogRef.componentInstance.buttonText = 'OK';
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
          // if (error.message.includes('Http failure response') && error.errorNumber === 500) {
          //   this.router.navigate(['/authenticated/hotelmgmt/hotellist']);
          // } else {
            this.router.navigate(['/authenticated/bookingmgmt/reservationList'], { relativeTo: this.activatedRoute });
          // }
        });
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
    this.confirmCancel = true;
  }

  // TODO: revisit complete reservation flow
  navigateToReservationDetails(value, operation) {
    this.router.navigate(['/authenticated/bookingmgmt/checkoutconfdetails',
      value.bookingId, value.docID, value.agencyId, operation.trim().toLowerCase()],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          BId: null,
          BStatus: null,
          BSource: null,
          GN: null,
          AN: null,
          AC: null,
          ARN: null,
          DT: null,
          From: null,
          To: null,
          // BId: this.searchReservation.bookingId,
          // BStatus: this.searchReservation.bookingStatus,
          // BSource: this.searchReservation.bookingSource,
          // GN: this.searchReservation.guestName,
          // AN: this.searchReservation.agencyName,
          // AC: this.searchReservation.agencyCode,
          // ARN: this.searchReservation.agencyReferenceNo,
          // DT: this.searchReservation.datetype,
          // From: this.searchReservation.fromDate,
          // To: this.searchReservation.toDate
          // , OB: this.searchReservation.orderBy,  SO: this.searchReservation.sortOrder
        }
      });
  }

  // showCancellationPolicy(cancellationPolicies: any[]) {
  //   let dialogRef: MatDialogRef<MessageDialogComponent>;
  //   dialogRef = this.dialog.open(MessageDialogComponent);
  //   dialogRef.componentInstance.title = 'Cancellation policy conditions';
  //   dialogRef.componentInstance.messages = cancellationPolicies;
  //   return dialogRef.afterClosed();
  // }

  hasErrors(controlName: string) {
    return (this.guestDetailsForm.get(controlName).dirty || this.guestDetailsForm.get(controlName).touched)
      && this.guestDetailsForm.get(controlName).errors !== null;
  }

  getValidationMessage(controlName: string) {
    if (this.guestDetailsForm.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.guestDetailsForm.get(controlName).hasError('pattern')) {
      if (controlName === 'agencyReferenceNumber') {
        return ErrorMessages.alphaNumErrorMessage;
      }
      const x = controlName.split('.');
      if (x[x.length - 1] === 'firstName' || x[x.length - 1] === 'lastName') {
        return ErrorMessages.invalidNameMessage;
      }
    } else if (this.guestDetailsForm.get(controlName).hasError('duplicateAgencyReferenceNumber')) {
      return ErrorMessages.duplicateAgencyReferenceNumber;
    } else if (this.guestDetailsForm.get(controlName).hasError('minlength')) {
      const x = controlName.split('.');
      if (x[x.length - 1] === 'firstName') {
        return 'Please enter a minimum of ' + this.guestDetailsValidations.firstName.minLength + ' characters';
      } else if (x[x.length - 1] === 'lastName') {
        return 'Please enter a minimum of ' + this.guestDetailsValidations.lastName.minLength + ' characters';
      }
    } else if (this.guestDetailsForm.get(controlName).hasError('maxlength')) {
      const x = controlName.split('.');
      if (x[x.length - 1] === 'firstName') {
        return 'Please enter a maximum of ' + this.guestDetailsValidations.firstName.maxLength + ' characters';
      } else if (x[x.length - 1] === 'lastName') {
        return 'Please enter a maximum of ' + this.guestDetailsValidations.lastName.maxLength + ' characters';
      }
    } else {
      return '';
    }
  }

  onCopyChange() {
    if (this.copyPrimaryContact === true) {
      // this.disableIsPrimaryCheckbox = true;
      // this.copySimilarNames();
      this.getAdultArrayForRoom(0).controls.forEach((adult, index) => {
        if (index === 0) {
          if (adult.value.firstName !== null && adult.value.lastName !== null) {
            if (adult.value.salutation !== '' && adult.value.firstName.trim() !== '' && adult.value.lastName.trim() !== ''
              && adult.value.salutation !== null) {
              // this.disableIsPrimaryCheckbox = true;
              this.copySimilarNames();
            }
          }
        }
      });
    }
  }

  onPrimaryChange(roomIndex, adultIndex) {
    if (this.copyPrimaryContact === false) {
      if (roomIndex === 0 && adultIndex === 0) {
        this.getAdultArrayForRoom(0).controls.forEach((adult, index) => {
          if (index === 0) {
            if (adult.value.firstName !== null && adult.value.lastName !== null) {
              if (adult.value.salutation !== '' && adult.value.firstName.trim() !== '' && adult.value.lastName.trim() !== ''
                && adult.value.salutation !== null) {
                // this.disableIsPrimaryCheckbox = false;
              }
            }
          }
        });
      }
    }
  }

  copySimilarNames() {
    const primaryContactArray = [];
    // let number = 0;
    const initialAlphabet = 'a';
    let alphabet = 'a';
    let alphabetIndex = -1;
    let previousAlphabet: any;
    this.roomDetails.controls.forEach((room, roomIndex) => {
      this.getAdultArrayForRoom(roomIndex).controls.forEach((adult, adultIndex) => {
        if (roomIndex === 0 && adultIndex === 0) {
          primaryContactArray.push(adult.value.salutation);
          primaryContactArray.push(adult.value.firstName);
          primaryContactArray.push(adult.value.lastName);
        } else {
          // number++;
          previousAlphabet = alphabet;
          alphabetIndex++;
          if (alphabet.length === 1) {
            alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
          } else if (alphabet.length === 2 && alphabet !== 'aa') {
            // tslint:disable-next-line:max-line-length
            alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
          } else if (alphabet.length === 3 && alphabet !== 'aaa') {
            // tslint:disable-next-line:max-line-length
            alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
          } else if (alphabet.length === 4 && alphabet !== 'aaaa') {
            // tslint:disable-next-line:max-line-length
            alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
          }
          adult.patchValue({
            salutation: primaryContactArray[0],
            firstName: primaryContactArray[1] + alphabet,
            lastName: primaryContactArray[2]
          });
          if (alphabet === 'z') {
            alphabet = 'aa';
            alphabetIndex = -1;
          }
          if (previousAlphabet === 'aa' && alphabet === 'aa') {
            alphabet = 'bb';
          }

          if (alphabet === 'zz') {
            alphabet = 'aaa';
            alphabetIndex = -1;
          }
          if (previousAlphabet === 'aaa' && alphabet === 'aaa') {
            alphabet = 'bbb';
          }

          if (alphabet === 'zzz') {
            alphabet = 'aaaa';
            alphabetIndex = -1;
          }
          if (previousAlphabet === 'aaaa' && alphabet === 'aaaa') {
            alphabet = 'bbbb';
          }
        }
        if (adult.value) {
          adult.get('firstName').markAsTouched();
          adult.get('lastName').markAsTouched();
        }
      });
      this.getChildArrayForRoom(roomIndex).controls.forEach((child, adultIndex) => {
        // number++;
        previousAlphabet = alphabet;
        alphabetIndex++;
        if (alphabet.length === 1) {
          alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
        } else if (alphabet.length === 2 && alphabet !== 'aa') {
          // tslint:disable-next-line:max-line-length
          alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
        } else if (alphabet.length === 3 && alphabet !== 'aaa') {
          // tslint:disable-next-line:max-line-length
          alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
        } else if (alphabet.length === 4 && alphabet !== 'aaaa') {
          // tslint:disable-next-line:max-line-length
          alphabet = String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex) + String.fromCharCode(initialAlphabet.charCodeAt(0) + alphabetIndex);
        }
        child.patchValue({
          salutation: primaryContactArray[0],
          firstName: primaryContactArray[1] + alphabet,
          lastName: primaryContactArray[2]
        });
        if (alphabet === 'z') {
          alphabet = 'aa';
          alphabetIndex = -1;
        }
        if (previousAlphabet === 'aa' && alphabet === 'aa') {
          alphabet = 'bb';
        }

        if (alphabet === 'zz') {
          alphabet = 'aaa';
          alphabetIndex = -1;
        }
        if (previousAlphabet === 'aaa' && alphabet === 'aaa') {
          alphabet = 'bbb';
        }

        if (alphabet === 'zzz') {
          alphabet = 'aaaa';
          alphabetIndex = -1;
        }
        if (previousAlphabet === 'aaaa' && alphabet === 'aaaa') {
          alphabet = 'bbbb';
        }

        if (child.value) {
          child.get('firstName').markAsTouched();
          child.get('lastName').markAsTouched();
        }
      });

    });
  }

  resetRoomDetails() {
    this.copyPrimaryContact = false;
    // this.disableIsPrimaryCheckbox = true;
    this.roomDetails.controls.forEach((room, roomIndex) => {
      this.getAdultArrayForRoom(roomIndex).reset();
      this.getChildArrayForRoom(roomIndex).reset();
      this.getAdultArrayForRoom(roomIndex).controls.forEach((adult) => {
        adult.patchValue({
          salutation: this.salutations[0].name
        });
      });
      this.getChildArrayForRoom(roomIndex).controls.forEach((child) => {
        child.patchValue({
          salutation: this.salutations[0].name
        });
      });
    });
    this.markFormDirtyService.markGroupDirty(this.guestDetailsForm);
  }

  cancelBooking() {
    // this.router.navigate(['/authenticated/hotelmgmt/hotellist']);
    let confirmCancelSubscription = null;
    confirmCancelSubscription = Utilities.confirmCancel(this.guestDetailsForm.dirty, this.cancelPopupService, this.router,
      // tslint:disable-next-line:max-line-length
      [this.previousRoute], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0),
      null, CustomConfirmMessages.cancelBookingProcess, null, CustomConfirmButtonTexts.StayOnPage, 'Confirm Cancellation');
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      this.subscriptions.push(confirmCancelSubscription);
      this.confirmCancel = true;
    }
  }

  getCreditLimitDetails(creditLimitDetails) {
    this.creditLimitDetails = creditLimitDetails;
    const creditLimitBalance = creditLimitDetails.creditLimitBalance;
    // const creditLimitBalance = 112878;
    this.defaultCurrency = this.currencyApi.defaultCurrency;
    if (this.defaultCurrency !== this.selectedCurrency && this.selectedCurrency && this.defaultCurrency) {
      // tslint:disable-next-line:max-line-length
      const subscription = this.agencyProfileDataService.getCurrencyExchangeRate(this.selectedCurrency, this.defaultCurrency).subscribe(data => {
        this.exchangeRate = data.result;
        this.setDisableCreditLimit(creditLimitBalance);
      },
        (error: JarvisError) => console.error(`Error in Getting Currency Exchange Rate. ${error.friendlyMessage}`));
      this.subscriptions.push(subscription);
    } else {
      this.exchangeRate = 1;
      this.setDisableCreditLimit(creditLimitBalance);
    }
  }

  setDisableCreditLimit(creditLimitBalance) {
    const netPrice = this.hotelRecheckData.hotel.roomDetails.netPrice * this.exchangeRate;
    if (creditLimitBalance >= netPrice) {
      this.disableCreditBalance = false;
      if (this.paymentTypeDetails.isDisableCreditLimit) {
        this.selectedTabIndex = this.creditTabIndex.creditCard;
      } else {
        this.selectedTabIndex = this.creditTabIndex.creditBalance;
      }
    } else {
      this.disableCreditBalance = true;
      this.selectedTabIndex = this.creditTabIndex.creditCard;
    }
  }

  getAgencyData(agencyId: number) {
    const subscription = this.agencyProfileDataService.getById(agencyId).subscribe(data => {
      const agency = data[0];
      if (agency.mgAgency.isOnHoldEnableForCC) {
        this.isOnHoldEnableForCC = true;
      }
    },
      (error: JarvisError) => console.error(`Error in Getting Agency data. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.guestDetailsForm.dirty && !this.confirmCancel) {
      return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
    } else {
      return of(true);
    }
    // return true;
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  goToTermsandConditions() {
    this.router.navigate([]).then(result => { window.open('siteinfomgmt/termsandconditions/' + this.supId, '_blank'); });
  }

  startRedirectTimer() {
    this.interval = setInterval(() => {
      if (this.redirctTimeInSec > 0) {
        this.redirctTimeInSec--;
      }
    }, 1000);
  }

  pauseRedirectTimer() {
    clearInterval(this.interval);
  }

  onPaymentTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedPaymentOption = tabChangeEvent.tab.textLabel;
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setHoldFlagForOnRequest() {
    this.isHoldBooking = false;
  }

  ngOnDestroy() {
    this.pauseTimer();
    this.pauseRedirectTimer();
    if (this.selectedPaymentOption !== this.paymentOptions.creditCard && this.selectedPaymentOption !== this.paymentOptions.qris
      && this.selectedPaymentOption !== this.paymentOptions.bankTransfer || (this.isHoldBooking && (this.selectedPaymentOption === this.paymentOptions.creditCard || this.selectedPaymentOption === this.paymentOptions.qris || this.selectedPaymentOption === this.paymentOptions.bankTransfer))) {
      // localStorage.removeItem('bookFromPromotion');
      sessionStorage.removeItem('bookFromPromotion');
      sessionStorage.removeItem('bookFromBanners');
    }
    this.dispose();
    this.hideSpinner();
    this.dialog.closeAll();
    window.scroll(0, 0);
  }

}

