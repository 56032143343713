import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from 'src/app/common/constants';
import { MatTableDataSource, MatSort, Sort, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { ErrorMessages } from 'src/app/common/errormessage';
import { BranchDataService } from 'src/app/b2b/common/b2b-shared/services/branch-data.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';
import { FormGroup, FormControl } from '@angular/forms';
import { BranchListViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/branchlistviewmodel';
import { BranchSearchViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/branchsearchviewmodel';
import { AgencyBranchListViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branch/agencybranchlistviewmodel';
import { JarvisError } from 'src/app/common/jarviserror';
import { Utilities } from 'src/app/common/utilities/utilities';

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.css']
})
export class BranchListComponent implements OnInit, OnDestroy {

  actions: string;
  create = CONSTANTS.operation.create;
  edit = CONSTANTS.operation.edit;
  read = CONSTANTS.operation.read;
  editView = false;
  readView = false;
  deleteView = false;
  searchBranchForm: FormGroup;
  noDataAvailable: boolean;
  operation: string;
  privileges: string[];
  dataSource: MatTableDataSource<BranchListViewModel>;
  branchList: BranchListViewModel[] = [<BranchListViewModel>{}];
  searchBranch: BranchSearchViewModel;
  searchArray: AgencyBranchListViewModel[];
  isDisabled = true;
  totalRecords: number;
  Math: any;
  showGrid = false;
  pageSize = 20;
  offset = 0;
  orderBy = 'ModifiedDate';  // Default Order;
  sortOrder = 'DESC';        // Default Sort Order
  pageIndex: number;
  subscriptions: Subscription[] = [];
  name: string;
  type: string;
  code: string;
  id: number;
  displayedColumns = ['branchName', 'contactPerson', 'designation', 'contactEmail', 'location',
    'modifiedDate', 'modifiedBy', 'status', 'actions'];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  noSearchResultsMessage = ErrorMessages.noSearchResultsMessage;
  agencyId: number;
  BranchAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.Branch);
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private branchDataService: BranchDataService,
    private userProfileService: UserProfileService,
    private dialogsService: DialogsService,
    private snackBar: MatSnackBar) {
    this.Math = Math;
  }

  ngOnInit() {
    this.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    if (!this.BranchAccess.hasViewAccess) {
      this.router.navigate(['/']);
    }
    this.searchBranchForm = new FormGroup({
      searchText: new FormControl()
    });
    this.getAllBranches();
    this.searchBranchForm.controls.searchText.valueChanges.subscribe(data => {
      this.searchArray = [];
      let flag;
      (data === null) ? (flag = true) : (data.id === undefined ? flag = true : flag = false);
      if (flag) {
        this.branchDataService.getAgencyBranchByNameOrCode(data, false, this.agencyId).subscribe(result => {
          this.searchArray = [];
          result.forEach(res => this.searchArray.push({ name: res.name.trim(), id: res.id, code: res.code, type: res.type }));
        });
      }
    });
  }

  createNewBranch() {
    this.router.navigate(['../branch', 0, this.create], { relativeTo: this.activatedRoute });
  }

  displayFn(val: AgencyBranchListViewModel) {
    if (val === undefined || val === null) {
      return val;
    } else if (val.code === '') {
      return val.name;
    } else {
      return val.name + ' - ' + val.code;
    }
  }

  search(event, data) {
    if (event.source.selected) {
      this.buildSearchModel();
      this.searchBranch.id = this.id = data.id;
      this.searchBranch.type = this.type = data.type;
      this.name = data.name;
      this.code = data.code;
      this.getBranch(this.searchBranch);
    }
  }

  buildSearchModel() {
    this.showGrid = true;
    this.pageIndex = 0;
    this.offset = 0;
    this.searchBranch = new BranchSearchViewModel();
    this.searchBranch.offset = this.offset;
    this.searchBranch.fetchNext = this.pageSize;
    this.searchBranch.orderBy = this.orderBy;
    this.searchBranch.sortOrder = this.sortOrder;
  }

  getAllBranches() {
    this.searchBranch = {
      id: this.agencyId,
      type: 'Agency',
      offset: this.offset,
      fetchNext: this.pageSize,
      orderBy: this.orderBy,
      sortOrder: this.sortOrder
    };
    this.getBranch(this.searchBranch);
  }

  getBranch(searchrequest: BranchSearchViewModel) {
    this.showSpinner();
    const subscription = this.branchDataService.getBranches(searchrequest).subscribe((branchList) => {
      this.branchList = branchList.result;
      this.dataSource = new MatTableDataSource<BranchListViewModel>(this.branchList);
      if (!branchList.result) {
        this.totalRecords = 0;
        this.noDataAvailable = true;
        this.showGrid = false;
      } else {
        this.totalRecords = this.branchList.length;
        this.noDataAvailable = false;
        this.showGrid = true;
      }
      this.dataSource.sort = this.sort;
      this.resetChange();
      window.scrollTo(0, 0);
    }, (error: JarvisError) => {
      this.hideSpinner(); console.error(`Error in Getting getBranchList. ${error.friendlyMessage}`);
    },
      () => this.hideSpinner()
    );
    this.subscriptions.push(subscription);
  }

  sortData(sort: Sort) {
    if (sort.direction !== '') {
      this.orderBy = sort.active;
      this.sortOrder = sort.direction;
      this.searchBranch.orderBy = sort.active;
      this.searchBranch.sortOrder = sort.direction;
      this.searchBranch.offset = this.offset;
      this.searchBranch.fetchNext = this.pageSize;
      this.getBranch(this.searchBranch);
    }
  }

  onPageChange(event) {
    this.pageIndex = event.page;        // event.page = Index of the new page
    this.offset = event.first;        // event.first = Index of the first record
    this.pageSize = event.rows;        // event.rows = Number of rows to display in new page
    this.searchBranch.offset = this.offset;
    this.searchBranch.fetchNext = this.pageSize;
    this.getBranch(this.searchBranch);
    window.scrollTo(0, 0);
  }

  resetSearch() {
    this.searchBranchForm.reset();
    this.showGrid = false;
    this.dataSource = null;
    this.totalRecords = 0;
    this.noDataAvailable = false;
    this.isDisabled = true;
    this.getAllBranches();
  }

  resetChange() {
    if (this.searchBranchForm.get('searchText').value === null || this.searchBranchForm.get('searchText').value === '') {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

  createBranch() {
    window.scrollTo(0, 0);
    this.router.navigate(['../branch', 0, this.create], { relativeTo: this.activatedRoute });
  }

  goBranchUpdate(value) {
    window.scrollTo(0, 0);
    const val = value.split(':');
    const branchId = val[0];
    this.operation = val[1];
    this.router.navigate(['../branch', branchId, this.operation.trim().toLowerCase()],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          name: this.name,
          code: this.code,
          type: this.type,
          id: this.id
        }
      });
  }

  goBranchDelete(value) {
    const val = value.split(':');
    const branchId = val[0];
    const subscription = this.dialogsService
      .confirm('Confirm', 'Are you sure you want to delete this branch?').subscribe(res => {
        const result = res;
        if (result) {
          this.deleteBranch(branchId);
        } else {
          this.actions = null;
        }
      },
        (error: JarvisError) => console.error(`Error in Getting AM-Branch-goBranchDelete(value). ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  deleteBranch(branchId) {
    const subscription = this.branchDataService.deleteBranch(branchId).subscribe(data => {
      if (data) {
        window.scrollTo(0, 0);
        this.snackBar.open('The branch is deleted successfully.', '', {
          duration: CONSTANTS.toasterTimeOut.duration, verticalPosition: 'top',
          panelClass: 'showSnackBar'
        });
        if (this.type.toUpperCase() === 'AGENCY') {
          this.getBranch(this.searchBranch);
        } else {
          this.resetSearch();
        }
      }
    },
      (error: JarvisError) => console.error(`Error in Deleting branch. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    if (!Utilities.isNullOrEmpty(this.subscriptions)) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

}
