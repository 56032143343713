import { ErrorCodes } from './../../../common/errorcodes';
import { MessageDialogComponent } from './../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HotelRecheckService } from '../../common/b2b-shared/services/hotel-recheck.service';
import { HotelBookService } from '../../common/b2b-shared/services/hotel-book.service';
import { CancelBookingResponseViewmodel } from '../../viewmodels/hotel-mgmt/cancel-booking-response-viewmodel';
import { FullBookingResponseDetailsViewmodel } from '../../viewmodels/hotel-mgmt/booking-response-viewmodel';
import { BookingRequestViewmodel } from '../../viewmodels/hotel-mgmt/booking-request-viewmodel';
import { HotelRecheckViewModel } from '../../viewmodels/hotel-mgmt/hotelrecheckviewmodel';
import { HotelSearchService } from '../../common/b2b-shared/services/hotel-search.service';
import { ReservationDataService } from '../../common/b2b-shared/services/reservation-data.service';
import { DialogsService } from '../../common/b2b-shared/dialogs/dialogs.service';
import { ReservationType, Document, DocumentTypes } from '../../../common/enum';
import { InvoiceAndVouchers } from '../../viewmodels/reservation/invoice-voucher/invoiceandvouchers';
import { CONSTANTS } from '../../../common/constants';
import { JarvisError } from 'src/app/common/jarviserror';
import { Subscription } from 'rxjs';
import { EmailPdfRequestViewModel } from '../../viewmodels/reservation/invoice-voucher/emailpdfrequestviewmodel';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { Router } from '@angular/router';
import { ScreenService } from './../../../framework/fw/services/screen.service';


@Component({
  selector: 'app-booking-cancel-info',
  templateUrl: './booking-cancel-info.component.html',
  styleUrls: ['./booking-cancel-info.component.css']
})
export class BookingCancelInfoComponent implements OnInit, OnDestroy {
  cancelBookingData: CancelBookingResponseViewmodel = <CancelBookingResponseViewmodel>{};
  bookingResponseDetail: FullBookingResponseDetailsViewmodel;
  bookingRequestDetails: BookingRequestViewmodel;
  hotelRecheckData: HotelRecheckViewModel = <HotelRecheckViewModel>{};
  guestNationality: string;
  cancelStatus: string;
  cancelFree: boolean;
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  bookingId: string;
  cancelDocumentId: string;
  invoiceAndVouchersList: InvoiceAndVouchers[];
  agencyInvoice: InvoiceAndVouchers;
  customerInvoice: InvoiceAndVouchers;
  subscriptions: Subscription[] = [];
  emailPdfRequest: EmailPdfRequestViewModel;
  agencyId: number;
  reservationDetailsStatus = CONSTANTS.reservationDetailsStatus;

  constructor(private hotelRecheckService: HotelRecheckService,
    private hotelBookService: HotelBookService,
    private hotelSearchService: HotelSearchService, private reservationDataService: ReservationDataService,
    private dialogsService: DialogsService, private userProfileService: UserProfileService,
    private router: Router, public dialog: MatDialog,public screenService: ScreenService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    window.scroll(0, 0);
    this.cancelBookingData = this.hotelBookService.cancelBookingDetails;
    this.bookingResponseDetail = this.hotelBookService.fullBookingResponseDetails;
    this.bookingRequestDetails = this.hotelBookService.bookingRequestDetails;
    this.bookingId = this.bookingResponseDetail.bookingDetails.mgBookingID;
    this.cancelDocumentId = this.cancelBookingData.documentID;
    this.hotelRecheckData = this.hotelRecheckService.hotelRecheckDetails;
    this.agencyId = this.userProfileService.getBasicUserInfo().agencyId;
    this.hotelSearchService.guestNationalityName = localStorage.getItem('guestNationalityName');
    this.guestNationality = this.hotelSearchService.guestNationalityName;
    this.cancelStatus = this.cancelBookingData.bookingStatus;
    this.showCancelStatus();
    this.showCancelType();
    this.getInvoiceAndVouchersList(this.bookingId, this.cancelDocumentId);
  }

  showCancelStatus() {
    if (this.cancelStatus === 'CANCELCHARGE' || this.cancelStatus === 'CANCELCONF') {
      this.cancelStatus = 'CANCELLED';
    }
  }

  showCancelType() {
    if (Number(this.cancelBookingData.chargedAmount) === 0) {
      this.cancelFree = true;
    } else {this.cancelFree = false; }
  }

  public showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  public hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
  }

  getInvoiceAndVouchersList(bookingId: string, cancelDocumentId: string) {
    this.showSpinner();
    const subscription = this.reservationDataService.getInvoiceAndVouchers(bookingId,
      cancelDocumentId).subscribe((invoiceAndVouchersList) => {
      this.invoiceAndVouchersList = invoiceAndVouchersList;
      this.invoiceAndVouchersList.forEach(element => {
        if (element.documentType.toLowerCase() === CONSTANTS.documentType.agency.toLowerCase()) {
          if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
            this.agencyInvoice = element;
          }
        } else if (element.fileType.toLowerCase() === CONSTANTS.document.Invoice.toLowerCase()) {
            this.customerInvoice = element;
          }
      });
    },
      (error: JarvisError) => {
        this.hideSpinner();
        console.error(`Error in Getting Reservation- getInvoiceAndVouchersList. ${error.friendlyMessage}`);
      },
      () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  buildDownloadOrSendEmailRequest(reservationType, doc, docType) {
    this.emailPdfRequest = new EmailPdfRequestViewModel();
    this.emailPdfRequest.agencyId = this.agencyId;
    this.emailPdfRequest.MGReservationNumber = this.bookingId;
    this.emailPdfRequest.reservationDocId = this.cancelDocumentId;

    if (reservationType.toUpperCase() === CONSTANTS.reservationType.New) {
      this.emailPdfRequest.reservationType = ReservationType.New;
    } else {
      this.emailPdfRequest.reservationType = ReservationType.Cancel;
    }

    if (doc.toUpperCase() === CONSTANTS.document.All) {
      this.emailPdfRequest.document = Document.All;
    } else if (doc.toUpperCase() === CONSTANTS.document.Invoice) {
      this.emailPdfRequest.document = Document.Invoice;
    } else if (doc.toUpperCase() === CONSTANTS.document.Voucher) {
      this.emailPdfRequest.document = Document.Voucher;
    } else {
      this.emailPdfRequest.document = Document.Itinerary;
    }

    if (docType.toUpperCase() === CONSTANTS.documentType.agency) {
      this.emailPdfRequest.documentType = DocumentTypes.Agency;
    } else if (docType.toUpperCase() === CONSTANTS.documentType.customer) {
      this.emailPdfRequest.documentType = DocumentTypes.Customer;
    } else {
      this.emailPdfRequest.documentType = DocumentTypes.Hotel;
    }
    this.emailPdfRequest.isB2B = true;
  }

  downloadPDF(reservationType, doc, docType) {
    this.showSpinner();
    this.buildDownloadOrSendEmailRequest(reservationType, doc, docType);

    const subscription = this.reservationDataService.generatePDF(this.emailPdfRequest).subscribe((data) => {
      const blob = data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(blob);
      } else {  // for other browsers
        const fileURL = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = fileURL;
        a.download = this.bookingId + '_' + docType + '_' + doc;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      }
    },
    (error: JarvisError) => {
      this.hideSpinner();
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `Error Code ${ErrorCodes.GenerateReservationDocument}. ${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    },
    () => this.hideSpinner());
    this.subscriptions.push(subscription);

  }

  goBack() {
      this.router.navigate(['authenticated/hotelmgmt/hotelinfo']);
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  ngOnDestroy() {
    this.dispose();
  }
}

