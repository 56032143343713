import { ConfigurationService } from './../../../common/shared/services/configuration.service';
import { Subscription } from 'rxjs';
import { UserProfileService } from './../../../common/shared/services/user-profile.service';
import { AuthService } from './../../../common/shared/services/auth.service';
import { FrameworkConfigService } from './../services/framework-config.service';
import { Component, OnInit, Renderer2, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScreenService } from '../services/screen.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.css']
})
export class LiveChatComponent implements OnInit, OnDestroy {

  // private liveChat: any;
  subscriptions: Subscription[] = [];
  showWhatsAppChat = false;
  whatsAppChatLink: string;

  constructor(
    // private _renderer2: Renderer2,
    // @Inject(DOCUMENT) private _document,
    public authService: AuthService,
    public frameworkConfigService: FrameworkConfigService,
    public userProfileService: UserProfileService,
    private configurationService: ConfigurationService, public screenService: ScreenService) {
    const subscription = this.userProfileService.getData().subscribe(isUserProfileLoaded => {
      if (isUserProfileLoaded && this.authService.isLoggedIn() && !this.frameworkConfigService.IsCallCenterUser) {
        // Note: Commented code is for loading zoho live chat js file which is in assets folder
        // this.liveChat = this._renderer2.createElement('script');
        // this.liveChat.type = `text/javascript`;
        // this.liveChat.src = `../../assets/live-chat.js`;
        // this._renderer2.appendChild(this._document.body, this.liveChat);
        this.showWhatsAppChat = true;
      } else {
        this.showWhatsAppChat = false;
      }
    });
    this.subscriptions.push(subscription);
  }

  ngOnInit() {
    this.whatsAppChatLink = this.configurationService.config.whatsAppChatLink;
  }

  ngOnDestroy() {
    // Note: Commented code is for loading zoho live chat js file which is in assets folder
    // if (this.authService.isLoggedIn() && !this.frameworkConfigService.IsCallCenterUser) {
    //   this._renderer2.removeChild(this._document.body, this.liveChat);
    // }
  }

}
