import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticatedHttpService } from '../../../../common/shared/services/authenticated-http.service';
import { CountryViewModel } from '../../../../common/viewmodels/countryviewmodel';
import { CityViewModel } from '../../../../common/viewmodels/cityviewmodel';
import { StarRatingViewModel } from '../../../viewmodels/starratingviewmodel';
import { ConfigurationService } from '../../../../common/shared/services/configuration.service';
import { NationalityViewModel } from '../../../viewmodels/nationalityviewmodel';
import { SalutationViewModel } from '../../../viewmodels/registration-forms/salutationviewmodel';
import { CurrencyViewModel } from '../../../../common/viewmodels/currencyviewmodel';
import { DesignationViewModel } from 'src/app/b2b/viewmodels/designationviewmodel';
import { BranchViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branchviewmodel';
import { MgRoleViewModel } from 'src/app/b2b/viewmodels/mgroleviewmodel';
import { AgentViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/agentviewmodel';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  currencyDto: CurrencyViewModel[];

  constructor(private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService) { }


  getCountries(): Observable<CountryViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.extranetApi, 'api/MasterData/GetCountries/').pipe(
      map((data) => data ? data.result : []));
  }

  getCities(countryId): Observable<any> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.extranetApi, 'api/MasterData/GetCities?id=' +
      countryId).pipe(
        map((data) => data ? data.result : []));
  }

  getStarRating(): Observable<StarRatingViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.extranetApi, 'api/MasterData/GetStarRating/').pipe(
      map((data) => data ? data.result : []));
  }

  getSalutation(): Observable<SalutationViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.b2BContentApi, 'api/Home/GetSalutation/').pipe(
      map((data) => data ? data.result : []));
  }

  getNationality(languageId: number): Observable<NationalityViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi, 'api/Nationalities/Get?languageId=' +
      languageId).pipe(
        map((data) => data ? data.result.filter(function (x) { return x.isDeleted === false && x.isActive && x.code !== 'ALL'; }) : []));
  }

  getCurrencyByAgencyCode(code: string): Observable<CurrencyViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi,
      'api/Agency/GetCurrenciesByCode?code=' + code).pipe(map(data => {
        const currencyDto: Array<CurrencyViewModel> = [];
        const filteredResult = data.result.filter(x => x.isActive === true && x.isDeleted === false);
        filteredResult.forEach(element => {
          const currency: CurrencyViewModel = { currencyId: element.id, currencyName: element.name, currencyCode: element.code };
          currencyDto.push(currency);
        });
        return currencyDto;
      }));
  }

  getDesignationByType(userType): Observable<any> {
    return this.authenticatedHttpService.post(this.configurationService.config.baseUrls.extranetApi,
      JSON.stringify(userType), 'api/Miscellaneous/GetDesignation/').pipe(
        map((data) => data ? data : []));
  }

  getCurrencies(languageId: number): Observable<CurrencyViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Currencies/Get?languageId=' + languageId).pipe(map( data => {
        this.currencyDto = [];
        if (data !== null) {
        const filteredResult = data.result.filter( x => x.isActive === true && x.isDeleted === false);
        filteredResult.forEach(element => {
          const currency: CurrencyViewModel = { currencyId : element.id, currencyName: element.name, currencyCode: element.code};
          this.currencyDto.push(currency);
        });
      }
      return this.currencyDto;
    }));
  }

  getAgencyBranches(id: number, includeInActive: boolean = false): Observable<BranchViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/GetBranchByAgancyId?agencyId=' + id).pipe(map(
        data => {
          const branchDto: BranchViewModel[] = [];
          if (data !== null && data.result !== null) {
            data.result.forEach(element => {
              if (!element.mgBranch.isDeleted && (element.mgBranch.isActive || (!element.mgBranch.isActive && includeInActive))) {
                const branch: BranchViewModel = { id: element.mgBranch.id, name: element.mgBranch.name };
                branchDto.push(branch);
              }
            });
          }
          return branchDto;
        }));
  }

  getRolesByApplicationName(applicationName: string, userType: number): Observable<MgRoleViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.userMgmtApi
      , 'api/Role/GetByApplicationName?name=' + applicationName + '&userType=' + userType).pipe(map(data => {
        const rolesDto: MgRoleViewModel[] = data;
        return rolesDto;
      }));
  }

  getSearchedCity(searchText: string): Observable<any[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.extranetApi
      , 'api/MasterData/GetAllCity?searchText='+searchText).pipe(map(data => {
        const cityDto: any= data;
        return cityDto.result;
      }));
  }

  getAgentName(agencyId: number, branchId: number): Observable<AgentViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Branch/GetAgentBysearchText?agencyId=' + agencyId + '&branchId=' + branchId).pipe(
        map(data => {
          let agentDto: AgentViewModel[] = [];
          if (data !== null) {
            agentDto = data.result;
          }
          return agentDto;
        })
      );
  }

}
