import { Component, OnInit, Input } from '@angular/core';
import { HotelViewModel } from '../../viewmodels/hotel-mgmt/hotelviewmodel';

@Component({
  selector: 'app-hotel-map-view',
  templateUrl: './hotel-map-view.component.html',
  styleUrls: ['./hotel-map-view.component.css']
})
export class HotelMapViewComponent implements OnInit {
  @Input() hotels: HotelViewModel[] = [];

  constructor() { }

  ngOnInit() {
  }

}
