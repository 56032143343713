import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagedDataService {

  constructor() { }

  pagedDataList(dataSource: any[], skipRecords: number, takeRecords: number) {
    let pagedDataSource = dataSource;
    pagedDataSource = pagedDataSource.filter((h, index) => index >= skipRecords);
    pagedDataSource = pagedDataSource.filter((h, index) => index < takeRecords);
    return pagedDataSource;
  }

  pagedDataListForMobile(dataSource: any[], skipRecords: number, takeRecords: number) {
    let pagedDataSource = dataSource;
    pagedDataSource = pagedDataSource.filter((h, index) => index < takeRecords);
    return pagedDataSource;
  }
}
