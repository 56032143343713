import { Injectable } from '@angular/core';
import { AuthenticatedHttpService } from 'src/app/common/shared/services/authenticated-http.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { AgencyViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/agencyviewmodel';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BranchViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/branchviewmodel';
import { AgentViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/agentviewmodel';
import { CurrencyViewModel } from 'src/app/common/viewmodels/currencyviewmodel';
import { SupplierViewModel, ChannelManagerViewModel } from 'src/app/b2b/viewmodels/hotel-mgmt/rateidsupplierviewmodel';

@Injectable({
  providedIn: 'root'
})
export class CallcenterService {

  constructor(
    private authenticatedHttpService: AuthenticatedHttpService,
    private configurationService: ConfigurationService
  ) { }

  private subject: Subject<any> = new BehaviorSubject<any>(null);
  private supplierSubject: Subject<any> = new BehaviorSubject<any>(null);
  private channelManagerSubject: Subject<any> = new BehaviorSubject<any>(null);

  get branchData() {
    return this.subject.asObservable();
  }

  sendBranchData(data: any) {
    this.subject.next(data);
  }

  get supplierListData() {
    return this.supplierSubject.asObservable();
  }

  sendSupplierListData(data: SupplierViewModel[]) {
    this.supplierSubject.next(data);
  }

  get channelManagerListData() {
    return this.channelManagerSubject.asObservable();
  }

  sendChannelManagerListData(data: ChannelManagerViewModel[]) {
    this.channelManagerSubject.next(data);
  }


  getAgencyNameAndCode(searchText, onlyActive: boolean, includeAll = false): Observable<AgencyViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
    , 'api/Agency/GetByNameOrCode?searchText=' + searchText + '&onlyActive=' + onlyActive + '&includeAll=' + includeAll).pipe(
      map(data => {
     let agencyDto: AgencyViewModel[] = [];
       if (data !== null) {
         agencyDto = data.result;
       }
        return agencyDto.sort(this.compare);
     }));
 }

 getAgencyBranches(id: number, includeInActive: boolean = false): Observable<BranchViewModel[]> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
   , 'api/Branch/GetBranchByAgancyId?agencyId=' + id).pipe(map(
      data => {
      const branchDto: BranchViewModel[] = [];
      if (data !== null && data.result !== null) {
        data.result.forEach(element => {
          if (!element.mgBranch.isDeleted && (element.mgBranch.isActive || (!element.mgBranch.isActive && includeInActive))) {
            const branch: BranchViewModel = { id : element.mgBranch.id, name: element.mgBranch.name };
            branchDto.push(branch);
          }
        });
      }
  return branchDto;
}));
}

getAgentName(agencyId: number, branchId: number): Observable<AgentViewModel[]> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
    , 'api/Branch/GetAgentBysearchText?agencyId=' + agencyId + '&branchId=' + branchId).pipe(
      map(data => {
        let agentDto: AgentViewModel[] = [];
        if (data !== null) {
          agentDto = data.result;
        }
        return agentDto.sort(this.compare);
      })
    );
}

getPaymentType(agencyId: number): Observable<any> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
    , 'api/Agency/GetPaymentType?agencyId=' + agencyId).pipe(
      map(data => {
        let  payment: any;
        if (data !== null) {
          payment = data.result;
        }
        return payment;
      })
    );
}

getCurrencyByAgencyId(id: number): Observable<CurrencyViewModel[]> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi,
    'api/Agency/GetCurrencyByAgencyId?id=' + id).pipe(map(data => {
      const currencyDto: Array<CurrencyViewModel> = [];
      const filteredResult = data.result.filter(x => x.isActive === true && x.isDeleted === false);
      filteredResult.forEach(element => {
        const currency: CurrencyViewModel = { currencyId: element.id, currencyName: element.name, currencyCode: element.code };
        currencyDto.push(currency);
      });
      return currencyDto;
    }));
}

GetAgencyDefaultCurrencyById(id: number): Observable<number> {
  return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
  , 'api/Agency/GetAgencyDefaultCurrencyById?id=' + id).pipe(map(data => {
   let agencyListDto: number;
     if (data !== null) {
       agencyListDto = data.result;
     }
      return agencyListDto;
   }));
}

  GetSupplierList(): Observable<SupplierViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/Supplier/GetSupplierList').pipe(map(data => {
       let supplierListDto: SupplierViewModel[];
         if (data !== null) {
          supplierListDto = data;
         }
          return supplierListDto;
       }));
  }

  GetChannelManagerList(): Observable<ChannelManagerViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.backofficeApi
      , 'api/ChannelManager/GetChannelManagerList').pipe(map(data => {
       let channelManagerListDto: ChannelManagerViewModel[];
         if (data !== null) {
          channelManagerListDto = data;
         }
          return channelManagerListDto;
       }));
  }

 compare(a: any, b: any) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}
}
