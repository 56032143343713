import { CONSTANTS } from './../../../../common/constants';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AgentUserDataService } from 'src/app/b2b/common/b2b-shared/services/agent-user-data.service';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { map } from 'rxjs/operators';
import { JarvisError } from 'src/app/common/jarviserror';
import { AgentInfoViewModel } from 'src/app/b2b/viewmodels/agency-mgmt/agentinfoviewmodel';
import { Subscription, Observable, of } from 'rxjs';
import { MasterLookupService } from 'src/app/common/shared/services/lookup.service';
import { CreditLimitViewModel } from 'src/app/common/viewmodels/agencyprofile/creditlimitviewmodel';
import { AgencyProfileDataService } from 'src/app/b2b/common/b2b-shared/services/agency-profile-data.service';
import { RequestCreditNotification } from 'src/app/common/viewmodels/notification/requestcreditnotificationsviewmodel';
import { ErrorMessages } from 'src/app/common/errormessage';
import { UserAccountApi } from '../user-account-api';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CanComponentDeactivate } from 'src/app/b2b/common/b2b-shared/services/guards/can-deactivate-guard.service';
import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';
import { ConfigurationService } from 'src/app/common/shared/services/configuration.service';
import { MarkFormDirtyService } from 'src/app/b2b/common/b2b-shared/services/mark-form-dirty.service';

@Component({
  selector: 'app-agency-request-credit',
  templateUrl: './agency-request-credit.component.html',
  styleUrls: ['./agency-request-credit.component.css']
})
export class AgencyRequestCreditComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  subscriptions: Subscription[] = [];


  constructor(private router: Router, private agentUserDataService: AgentUserDataService,
    private userProfileService: UserProfileService, private lookupServices: MasterLookupService,
    private agencyProfileDataService: AgencyProfileDataService, public dialog: MatDialog,
    private userAccountApi: UserAccountApi, private dialogsService: DialogsService,
    public markFormDirtyService: MarkFormDirtyService) { }
  creditRequestForm: FormGroup;
  creditInput: FormGroup;
  agentId = this.userProfileService.getBasicUserInfo().id;
  agencyId = this.userProfileService.getBasicUserInfo().agencyId;
  creditLimit: CreditLimitViewModel = new CreditLimitViewModel;
  currencyId = this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId;
  currencyCode: string;
  agencyCode: string;
  agencyNameCode: string;
  agentInfo: AgentInfoViewModel;
  agencyName: string;
  requestNotification: RequestCreditNotification = new RequestCreditNotification();
  RequestCreditAccess =
    this.userProfileService.getAllPermissionForFeature(CONSTANTS.application.b2b, CONSTANTS.b2bFeatureTypeId.RequestCredit);
    

  ngOnInit() {
    this.getAgentUser(this.agentId);
    this.getCurrencyCode(this.currencyId);
    this.getAgencyData(this.agencyId);
    this.creditRequestForm = new FormGroup({
      agencyNameCode: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      designation: new FormControl(''),
      email: new FormControl('', {
        validators: [Validators.required,
        // Validators.pattern('^[\\w+]+(\\.[\\w+]+)*@[A-Za-z0-9]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z0-9]{2,4})$')
        Validators.email],
      }),
      totalCredit: new FormControl(''),
      usedCredit: new FormControl(''),
      availableCredit: new FormControl('')
    });

    this.creditInput = new FormGroup({
      requestAmount: new FormControl('', [Validators.required, Validators.max(999999999999999999.999999),
      Validators.pattern('^\\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$')]),
      reason: new FormControl(''),
    });
    if (!this.RequestCreditAccess.hasCreateAccess) {
      this.creditRequestForm.disable();
      this.creditInput.disable();
    }
  }

  getAgentUser(agentId) {
    const subscription = this.agentUserDataService.getAgentUserById(agentId).subscribe(
      (data) => {
        this.agentInfo = data;
        this.agencyNameCode = data.agency.name + '-' + data.agency.code;
        this.creditRequestForm.get('email').setValue(data.email);
        this.creditRequestForm.get('designation').setValue(data.designation);
        this.creditRequestForm.get('firstName').setValue(data.firstName);
        this.creditRequestForm.get('lastName').setValue(data.lastName);
        this.creditRequestForm.get('agencyNameCode').setValue(this.agencyNameCode);
        this.creditRequestForm.get('agencyNameCode').disable();
        this.creditRequestForm.get('designation').disable();
        this.creditRequestForm.get('lastName').disable();
        this.creditRequestForm.get('firstName').disable();
        this.creditRequestForm.get('agencyNameCode').disable();
        this.creditRequestForm.get('totalCredit').disable();
        this.creditRequestForm.get('usedCredit').disable();
        this.creditRequestForm.get('availableCredit').disable();
        this.agencyCode = data.agency.code;
      },
      (error: JarvisError) => console.error(`Error while getting UM-AgentUser-Info-getAgentUser(agentId). ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }
  getCurrencyCode(currecyId: number) {
    const subscription = this.lookupServices.getAgencyCurreny(currecyId).subscribe(data => {
      this.currencyCode = data.code;
    },
      (error: JarvisError) => console.error(`Error in Getting AgencyData. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);

  }
  getAgencyData(agencyId: number) {
    const subscription = this.agencyProfileDataService.getCreditById(agencyId).subscribe(data => {
      this.creditLimit = data;
      if (this.creditLimit.creditLimitConsumed < 0) {
        this.creditLimit.creditLimitConsumed = 0;
      }
    },
      (error: JarvisError) => console.error(`Error in Getting AgencyData. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  submitRequest() 
  {
    this.markFormDirtyService.markGroupDirty(this.creditInput);
    if (this.creditInput.valid && this.creditInput.dirty)
  
  {

    this.requestNotification.agencyCode = this.agentInfo.agency.code;
    this.requestNotification.agencyId = this.agencyId;
    this.requestNotification.agencyName = this.agentInfo.agency.name;
    this.requestNotification.balance = this.creditLimit.creditLimitBalance.
      toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).toString();
    this.requestNotification.consumed = this.creditLimit.creditLimitConsumed.
      toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).toString();
    this.requestNotification.contactPerson = this.agentInfo.firstName + ' ' + this.agentInfo.lastName;
    this.requestNotification.designation = this.agentInfo.designation;
    this.requestNotification.totalCreditLimit = this.creditLimit.creditAmount.
      toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).toString();
    this.requestNotification.email = this.creditRequestForm.get('email').value;
    this.requestNotification.requestForCreditAmount = this.creditInput.get('requestAmount').value.toString();
    this.requestNotification.reason = this.creditInput.get('reason').value.toString();
    this.requestNotification.currency = this.currencyCode;
    if (this.creditInput.valid && this.creditRequestForm.valid) {
      this.openDialog();
    } else {
      if (this.creditInput.invalid) {
        this.creditInput.get('requestAmount').markAsTouched();
      } else if (this.creditRequestForm.invalid) {
        this.creditRequestForm.get('email').markAsTouched();
      }
    }

  }}

  removeCurrencyPipeFormat(element) {
    element.target.value = element.target.value.replace(/[,]/g, '');
  }

  transformAmount(requestAmount) {
    if (Number(requestAmount) > 999999999999999999.999999) {
      this.creditInput.get('requestAmount').setErrors({ maxpriceError: true });
      // this.mealPriceValidation = true;
    } else if (requestAmount !== '') {
      let isNumber = false;
      isNumber = isNaN(requestAmount);
      if (isNumber === false) {
        requestAmount = Number(requestAmount);
        this.creditInput.get('requestAmount').setValue
          (requestAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
      }
    }
  }

  removeZero(event) {
    if (event.target.value !== null && (event.target.value === 0 || event.target.value === '0' ||
      event.target.value === '0.00' || event.target.value === 0.00)) {
      this.creditInput.get('requestAmount').setValue('');
    } else {
      this.creditInput.get('requestAmount').setValue(event.target.value);
    }
  }

  addZero(event) {
    if (event.target.value === null || event.target.value === '') {
      // this.creditInput.get('requestAmount').setValue(0);
    } else {
      this.creditInput.get('requestAmount').setValue(event.target.value);
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(RequestCreditPreviewDialog
      , {
        data: this.requestNotification
      }
    );
  }

  hasErrors(controlName: string) {
    if (this.creditInput.get(controlName) !== null) {
      return (this.creditInput.get(controlName).dirty ||
        this.creditInput.get(controlName).touched) && this.creditInput.get(controlName).errors !== null;
    }
  }
  hasErrorsEmail(controlName: string) {
    if (this.creditRequestForm.get(controlName) !== null) {
      return (this.creditRequestForm.get(controlName).dirty ||
        this.creditRequestForm.get(controlName).touched) && this.creditRequestForm.get(controlName).errors !== null;
    }
  }

  getValidationMessage(controlName: string) {
    if (controlName !== 'requestAmount') {
      if (this.creditRequestForm.get(controlName).hasError('required')) {
        return ErrorMessages.requiredFieldMessage;
      } else if (this.creditRequestForm.get(controlName).hasError('email')) {
        return ErrorMessages.invalidEmailMessage;
      }
    }

    if (this.creditInput.get(controlName).hasError('required')) {
      return ErrorMessages.requiredFieldMessage;
    } else if (this.creditInput.get(controlName).hasError('min')) {
      return ErrorMessages.requestCreditMin;
    } else if (this.creditInput.get(controlName).hasError('max')) {
      const x = controlName.split('.');
      if (controlName === 'requestAmount') {
        return ErrorMessages.requestCreditMax;
      }
    } else if (this.creditInput.get(controlName).hasError('pattern')) {
      const x = controlName.split('.');
      if (controlName === 'requestAmount') {
        return ErrorMessages.validAmount;
      } else {
        return ErrorMessages.invalidNumberMessage;
      }
    } else if (this.creditRequestForm.get(controlName).hasError('email')) {
      return ErrorMessages.invalidEmailMessage;
    } else {
      return '';
    }
  }
  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.creditRequestForm.dirty) {
      return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
    } else {
      return of(true);
    }
    // return true;
  }
  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }
}

@Component({
  selector: 'app-request-credit-preview-dialog',
  templateUrl: 'request-credit-preview-dialog.html',
})

// tslint:disable-next-line:component-class-suffix
export class RequestCreditPreviewDialog implements OnDestroy {
  subscriptions: Subscription[] = [];
  showConfirmationDailog: boolean;
  constructor(public dialogRef: MatDialogRef<RequestCreditPreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, private router: Router,
    private userAccountApi: UserAccountApi) { }

  notifyAgencyRequst() {

    const subscription = this.userAccountApi.notifyAgencyRequst(this.data).subscribe(response => {
      if (response) {
        this.openDialog();
      }
    },
      (error: JarvisError) => console.error(`Error while creating Group Booking. ${error.friendlyMessage}`));
    this.subscriptions.push(subscription);
  }

  openDialog(): void {
    this.showConfirmationDailog = true;
    const dialogRef = this.dialog.open(RequestCreditSubmittedDialog, {
      data: { email: this.data.email, agencyName: this.data.agencyName }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
      this.router.navigate(['/authenticated']);
    });
  }

  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.dispose();
  }
}

@Component({
  selector: 'app-request-credit-submitted-dialog',
  templateUrl: 'request-credit-submitted-dialog.html',
})

// tslint:disable-next-line:component-class-suffix
export class RequestCreditSubmittedDialog {
  email = this.configurationService.config.emailConfig.creditRequest;

  constructor(
    public dialogRef: MatDialogRef<RequestCreditSubmittedDialog>,
    @Inject(MAT_DIALOG_DATA) public data, public configurationService: ConfigurationService
  ) { }

}
