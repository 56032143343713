export class HotelSummaryMapViewModel {
    hotelId: string;
    hotelName: string;
    address: string;
    grossPrice?: number;
    starRating?: string;
    currency: string;
    longitude: number;
    latitude: number;
    image: string;
    isMgPreferred: boolean;
    facilities?: FacilitiesViewModel[];
}

export class FacilitiesViewModel {
    code: string;
    name: string;
    imagePath?: string;
}
