import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LookupService } from '../lookup.service';
import { CurrencyApiService } from 'src/app/common/shared/services/currency-api.service';
import { Observable, of } from 'rxjs';
import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { map } from 'rxjs/operators';
import { CONSTANTS } from 'src/app/common/constants';

@Injectable({
  providedIn: 'root'
})
export class AgencyCurrencyResolverService implements Resolve<string> {
  constructor(private currencyApiService: CurrencyApiService,
    private lookupService: LookupService, private userProfileService: UserProfileService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<string> {
      if (this.currencyApiService.selectedCurrency !== '') {
        return of(this.currencyApiService.selectedCurrency);
      } else {
        if (this.userProfileService.getBasicUserInfo() !== undefined && this.userProfileService.getBasicUserInfo().agencyCode !== undefined
             && this.userProfileService.getBasicUserInfo().agencyCode !== '') {
         return this.lookupService.
          getCurrencyByAgencyCode(this.userProfileService.getBasicUserInfo().agencyCode).
          pipe(map(item => {
            if (item.length > 0) {
              this.currencyApiService.selectedCurrency = item
                .filter(currency => currency.currencyId === this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId)
                .map(currency => currency.currencyCode)[0];
              return this.currencyApiService.selectedCurrency;
            } else {
              this.lookupService.getCurrencies(0).subscribe(data => {
                this.currencyApiService.selectedCurrency =
                  data.filter(currency => currency.currencyId === this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId)
                    .map(currency => currency.currencyCode)[0];
              });
              return this.currencyApiService.selectedCurrency;
            }
          }));
      }
    }
    }
}
