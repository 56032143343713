import { map } from 'rxjs/operators';
import { ConfigurationService } from './../../../../common/shared/services/configuration.service';
import { AuthenticatedHttpService } from './../../../../common/shared/services/authenticated-http.service';
import { Observable } from 'rxjs';
import { RolesAndPermissionsViewModel } from './../../../viewmodels/roles-and-permissions/rolesandpermissionsviewmodel';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RolesAndPermissionsService {

  constructor(private authenticatedHttpService: AuthenticatedHttpService, private configurationService: ConfigurationService) { }

  getRolesAndPermission(): Observable<RolesAndPermissionsViewModel[]> {
    return this.authenticatedHttpService.get(this.configurationService.config.baseUrls.bookingApi,
      'api/RolesAndPermission/GetRolesAndPermission/').pipe(
        map((data) => {
          if (data) {
            return data.result;
          } else {
            return [];
          }
        }));
  }
}
