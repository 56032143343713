import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared/shared.module';
import { FwModule } from './framework/fw/fw.module';
import { DefaultModule } from './common/default/default.module';
import { AppRoutingModule } from './app-routing.module';
import {HomeMgmtModule} from './b2b/home-mgmt/home-mgmt.module';
import { HotelMgmtModule } from './b2b/hotel-mgmt/hotel-mgmt.module';
import { B2bSharedModule } from './b2b/common/b2b-shared/b2b-shared.module';
import { AuthenticatedUserComponent } from './b2b/authenticated-user/authenticated-user.component';
import { AgencyMgmtModule } from './b2b/agency-mgmt/agency-mgmt.module';
import { MarkupMgmtModule } from './b2b/markup-mgmt/markup-mgmt.module';
import { CallcenterMgmtModule } from './b2b/callcenter-mgmt/callcenter-mgmt.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    AuthenticatedUserComponent
  ],
  imports: [
    FwModule,
    SharedModule,
    DefaultModule,
    HomeMgmtModule,
    HotelMgmtModule,
    B2bSharedModule,
    AgencyMgmtModule,
    MarkupMgmtModule,
     // Routing Module should be the last.
     AppRoutingModule,
     CallcenterMgmtModule,
     ServiceWorkerModule.register('./service-worker.js', { enabled: environment.production })

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
