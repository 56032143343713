import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarkFormDirtyService {

  constructor() { }

  markGroupDirty(formG: Object) {
    const formGroup = <FormGroup>formG;
    Object.keys(formGroup.controls).forEach(key => {
      if (formGroup.get(key) instanceof FormGroup) {
        this.markGroupDirty(formGroup.get(key) as FormGroup);
      } else if (formGroup.get(key) instanceof FormArray) {
        this.markArrayDirty(formGroup.get(key) as FormArray);
      } else if (formGroup.get(key) instanceof FormControl) {
        this.markControlDirty(formGroup.get(key) as FormControl);
      }
    });
  }
  markArrayDirty(formA: Object) {
    const formArray = <FormArray>formA;
    Object.keys(formArray.controls).forEach(control => {
      if (formArray.get(control) instanceof FormGroup) {
        this.markGroupDirty(formArray.get(control) as FormGroup);
      } else if (formArray.get(control) instanceof FormArray) {
        this.markArrayDirty(formArray.get(control) as FormArray);
      } else if (formArray.get(control) instanceof FormControl) {
        this.markControlDirty(formArray.get(control) as FormControl);
      }
    });
  }
  markControlDirty(formC: Object) {
    const formControl = <FormControl>formC;
    formControl.markAsDirty();
    formControl.markAsTouched();
  }
}
