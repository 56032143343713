import { Injectable } from '@angular/core';
import { MenuService, IMenuItem } from '../../../framework/fw/services/menu.service';
import { UserDetailsViewModel, FeaturePermissionViewModel } from '../../viewmodels/userdetailsviewmodel';
import { CONSTANTS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class MenuDataService {
  _userDetails: UserDetailsViewModel;
  constructor(private menuService: MenuService) { }

  initializeNonLoggedInMenus(menuItems: IMenuItem[]) {
    this.menuService.items = this.initializeMenuData(menuItems);
  }

  initializeLoggedInMenus(userDetails: UserDetailsViewModel) {
    this._userDetails = userDetails;
    this.transformMenuData(CONSTANTS.application.b2b.toUpperCase());
  }
  transformMenuData(app: string) {
    const appPermission = this._userDetails.applicationPermissions.find(x => x.name.trim().toUpperCase()
      === app.trim().toUpperCase());
    const featureListData: Array<FeaturePermissionViewModel> = appPermission.featuresList;
    let parentsMenuData: Array<FeaturePermissionViewModel> = new Array<FeaturePermissionViewModel>();
    if (featureListData != null && featureListData.length > 0) {
      // Top Level Menus
      parentsMenuData = featureListData.filter(val => val.parentFeatureId == null && val.isMenuRequired
        && val.privileges.includes(CONSTANTS.privileges.View));
      parentsMenuData.forEach(parentMenuItem => {
        this.buildTreeviewMenu(parentMenuItem, featureListData);
      });
    }
    const translatedMenus = this.translateModelToMenusRecursively(parentsMenuData);
    //  console.log('TranslatedMenus' + JSON.stringify(translatedMenus));
    this.menuService.items = translatedMenus;

  }
  private translateModelToMenusRecursively(featurePermissionViewModel: Array<FeaturePermissionViewModel>) {
    const translatedMenus: IMenuItem[] = [];
    featurePermissionViewModel.forEach(element => {

      const tempMenuItem: IMenuItem = { icon: '', route: '', submenu: null, text: '' };
      tempMenuItem.icon = element.icon;
      tempMenuItem.route = element.route;
      tempMenuItem.text = element.label;

      if (element.subFeaturePermissionViewModel != null && element.subFeaturePermissionViewModel.length > 0) {
        tempMenuItem.submenu = this.translateModelToMenusRecursively(element.subFeaturePermissionViewModel);
      }
      // tslint:disable-next-line:one-line
      else {
        tempMenuItem.submenu = null;
      }
      translatedMenus.push(tempMenuItem);
    });
    return translatedMenus;
  }

  private buildTreeviewMenu(parentMenuItem: FeaturePermissionViewModel,
    menuData: Array<FeaturePermissionViewModel>) {
    let menuItems: Array<FeaturePermissionViewModel>;
    // Get all child menu Items for the Parent
    menuItems = menuData.filter(menu => menu.parentFeatureId === parentMenuItem.featureTypeId && menu.isMenuRequired &&
      menu.privileges.includes(CONSTANTS.privileges.View));
    if (menuItems != null && menuItems.length > 0) {
      parentMenuItem.subFeaturePermissionViewModel = new Array<FeaturePermissionViewModel>();
      this.AddAdditionalMenuAttributes(parentMenuItem, true);
      menuItems.forEach((childMenu) => {
        parentMenuItem.subFeaturePermissionViewModel.push(childMenu);
        this.buildTreeviewMenu(childMenu, menuData);
      });
    }
    // tslint:disable-next-line:one-line
    else {
      this.AddAdditionalMenuAttributes(parentMenuItem, false);
    }
  }

  private AddAdditionalMenuAttributes(menuItem: FeaturePermissionViewModel, parent?: boolean):
    FeaturePermissionViewModel {
    if (menuItem != null) {
      menuItem.icon = this.getIconforMenuFeature(menuItem.typeName);
      if (parent === false) {
        menuItem.route = this.getRoutforMenuFeature(menuItem.typeName);
      } else {
        menuItem.route = null;
      }

    }
    return menuItem;
  }
  private getIconforMenuFeature(feature: string): string {
    if (feature.toUpperCase() === 'B2BHOTELS') {
      return 'person';
    } else if (feature.toUpperCase() === 'UNBEATABLEDEALS') {
      return 'person';
    } else if (feature.toUpperCase() === 'VIEWBOOKINGS') {
      return 'person';
    } else {
      return null;
    }
  }

  private getRoutforMenuFeature(feature: string): string {
    if (feature.toUpperCase() === 'B2BHOTELS') {
      return 'authenticated/hotelmgmt/hotelinfo';
    } else if (feature.toUpperCase() === 'UNBEATABLEDEALS') {
      return 'authenticated/deals/dealinfo';
    } else if (feature.toUpperCase() === 'VIEWBOOKINGS') {
      // Todo Change these
      return 'authenticated/bookingmgmt/reservationList';
    } else if (feature.toUpperCase() === 'B2BMANAGEAGENCY') {
      // Todo Change these
      return 'authenticated/agency/agencymgmt';
    } else if (feature.toUpperCase() === 'B2BMARKUP') {
      // Todo Change these
      return 'authenticated/markup/markupmgmt';
    } else if (feature.toUpperCase() === 'B2BGROUPBOOKING') {
      // Todo Change these
      return 'siteinfomgmt/groupbooking';
    } else if (feature.toUpperCase() === 'SYSTEMCHECK') {
      // Todo Change these
      return 'siteinfomgmt/systemcheck';
    } else if (feature.toUpperCase() === 'B2BREPORTS') {
      return 'authenticated/report/reportmgmt';
    } else {
      return null;
    }
  }


  private initializeMenuData(menuItems: Array<IMenuItem>) {
    const translatedMenus: Array<IMenuItem> = new Array<IMenuItem>();
    menuItems.forEach(item => {
      const tempMenuItem: IMenuItem = { icon: '', route: '', submenu: null, text: '' };
      tempMenuItem.icon = item.icon;
      tempMenuItem.route = item.route;
      tempMenuItem.text = item.text;
      if (item.submenu !== null) {
        tempMenuItem.submenu = this.initializeMenuData(item.submenu);
      } else {
        tempMenuItem.submenu = null;
      }
      translatedMenus.push(tempMenuItem);
    });
    return translatedMenus;
  }
}
