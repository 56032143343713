import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CallcenterMgmtRoutingModule, routedCallcenterComponents } from './callcenter-mgmt-routing.module';
import { MaterialModule } from 'src/app/common/material/material.module';
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatSelectModule, MatRadioModule, MatCardModule } from '@angular/material';
import { ComponentsModule } from '../common/b2b-shared/components/components.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgencyDetailsComponent } from './agency-details/agency-details.component';
import { PipesModule } from '../common/b2b-shared/pipes/pipes.module';
import { B2bSharedModule } from '../common/b2b-shared/b2b-shared.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CallcenterMgmtRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ComponentsModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    PipesModule,
    B2bSharedModule
  ],
  declarations: [routedCallcenterComponents],
  exports: []
})
export class CallcenterMgmtModule { }
