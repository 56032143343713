export class HotelSearchPanelViewModel {
    destination: DestinationViewModel;
    guestNationalitycode: string;
    checkInCheckOutDate: Date[];
    currencyCode: string;
    roomDetails: RoomDetailsViewModel[];
    isCreditCardAgencyOnly: boolean;
    clientIPAddress: string;
}

export class DestinationViewModel {
    name: string;
    cityCode: string;
    countryCode: string;
    hotelCode?: string;
    type: string;
}

export class RoomDetailsViewModel {
    roomNumber: number;
    noOfAdults: number;
    noOfChildren: number;
    childAges: ChildrenAgeViewModel[];
    rateKey?: string;
}

export class ChildrenAgeViewModel {
    childAge: number;
}
