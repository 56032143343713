import { ErrorCodes } from './../../../common/errorcodes';
import { MessageDialogComponent } from './../../common/b2b-shared/dialogs/message-dialog/message-dialog.component';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Subscription,  of, Observable } from 'rxjs';
import { JarvisError } from 'src/app/common/jarviserror';

import { CONSTANTS } from 'src/app/common/constants';
import { DateAdapter, MAT_DATE_FORMATS, MatDialogRef, MatDialog } from '@angular/material';
import { DatePipe, DecimalPipe, formatNumber } from '@angular/common';
import { DatePickerDateAdapterService } from '../../common/b2b-shared/services/date-picker-date-adapter.service';

import { DialogsService } from 'src/app/b2b/common/b2b-shared/dialogs/dialogs.service';

import { UserProfileService } from 'src/app/common/shared/services/user-profile.service';
import { ChargeTypeViewModel } from '../../viewmodels/markup-mgmt/common/chargetypeviewmodel';
import { AgencyMarkupService } from '../../common/b2b-shared/services/agency-markup.service';
import { MarkupInfoViewModel } from '../../viewmodels/markup-mgmt/common/markupInfoviewmodel';
import { CurrencyApiService } from '../../../common/shared/services/currency-api.service';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { GetMarkupByIdViewModel } from '../../viewmodels/markup-mgmt/common/getmarkupbyidviewmodel';
import { MarkupInfoResponseViewModel } from '../../viewmodels/markup-mgmt/common/markupinforesponseviewmodel';
import { ErrorMessages } from '../../../common/errormessage';
import { MatSnackBar } from '@angular/material';
import { Utilities } from '../../../common/utilities/utilities';
import { CancelpopupService } from '../../common/b2b-shared/dialogs/cancelpopup.service';
import { MarkupUtilities } from '../../viewmodels/markup-mgmt/common/markupUtilities';
import { UserInputTransformService } from '../../../common/shared/services/user-input-transform.service';
import { NavigatorService } from '../../../framework/fw/services/navigator.service';
import { MarkFormDirtyService } from '../../common/b2b-shared/services/mark-form-dirty.service';

@Component({
  selector: 'app-branch-markup-info',
  templateUrl: './branch-markup-info.component.html',
  styleUrls: ['./branch-markup-info.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: DatePickerDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: CONSTANTS.DATE_FORMATS },
    DecimalPipe
  ]
})
export class BranchMarkupInfoComponent implements OnInit {

  chargeTypeList$: Observable<ChargeTypeViewModel[]>;

  defaultCurrancy: string;
  isConflicts: boolean;

  subscriptions: Subscription[] = [];
  spinnerDialogId: string;
  spinnerDialogTimeout: number;
  paramObj: any;
  isChecked: boolean;
  confirmCancel: boolean;
  isRead: boolean;
  isEdit:boolean;
  currencyList: any;


  updateMarkupForm: FormGroup;
  markupBranch: any;
  saveMarkupInfo: MarkupInfoViewModel = <MarkupInfoViewModel>{};
  branchMarkupById: GetMarkupByIdViewModel = <GetMarkupByIdViewModel>{};
  branchMarkupInfo: MarkupInfoViewModel[] = [];
  chargeTypeArr: ChargeTypeViewModel[] = [];
  updateMarkupInfo: MarkupInfoResponseViewModel = <MarkupInfoResponseViewModel>{};

  constructor(
    private router: Router,
    private dialogsService: DialogsService,
    private activatedRoute: ActivatedRoute,
    private agencyMarkupService: AgencyMarkupService,
    private currencyApiService: CurrencyApiService,
    private userProfileService: UserProfileService,
    private snackBar: MatSnackBar,
    public cancelPopupService: CancelpopupService,
    public decimalPipe: DecimalPipe,
    public userInputTransformService: UserInputTransformService,
    private navigatorService: NavigatorService,
    public dialog: MatDialog,
    public markFormDirtyService: MarkFormDirtyService

  ) { }

  ngOnInit() {
    this.isChecked = true;

    this.activatedRoute.params.subscribe(params => {
      this.paramObj = { ...params.keys, ...params };
    });

    this.markupBranch = this.agencyMarkupService.selectedMarkupBranch;
    this.defaultCurrancy = this.currencyApiService.getDefaultCurrancy();
    this.isRead = (this.paramObj['operation'] === 'read');
    this.isEdit=(this.paramObj['operation'] === 'edit');

    // this.bookingId = this.activatedRoute.snapshot.params['id'];

    this.getChargeType();

    this.updateMarkupForm = new FormGroup({
      fromDate: new FormControl(),
      toDate: new FormControl(),
      chargeTypeId: new FormControl('', Validators.required),
      markup: new FormControl('', [Validators.required]),
      isActive: new FormControl(this.isChecked),
    });

    if (this.paramObj['operation'] === 'edit' || this.paramObj['operation'] === 'read') {
      this.getMarkupToEdit();
    }

  }

  getMarkupToEdit() {
    this.showSpinner();
    this.buildSearchByIdModel();
    const subscription = this.agencyMarkupService.getMarkupToEdit(this.branchMarkupById).subscribe((branchMarkupInfo) => {
      if (branchMarkupInfo.result) {
        this.updateMarkupInfo = branchMarkupInfo.result[0];
        this.updateExistingForm(this.updateMarkupInfo);
        this.addValidations();
      } else {
        this.updateMarkupInfo = new MarkupInfoResponseViewModel();
      }
    }, (error: JarvisError) => {
      this.hideSpinner(); console.error(`Error in Getting Branch Markup List. ${error.friendlyMessage}`);
    },
      () => this.hideSpinner()
    );
    this.subscriptions.push(subscription);
  }

  updateExistingForm(formData) {
    this.updateMarkupForm.get('fromDate').setValue(formData['fromDate']);
    this.updateMarkupForm.get('toDate').setValue(formData['toDate']);
    this.updateMarkupForm.get('chargeTypeId').setValue(formData['chargeTypeId']);
    this.updateMarkupForm.get('markup').setValue(formData['markup']
    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 6 }));
    this.updateMarkupForm.get('isActive').setValue(formData['isActive']);
  }

  buildSearchByIdModel() {
    this.branchMarkupById.agencyId = this.paramObj['agencyId'];
    this.branchMarkupById.branchId = this.paramObj['branchId'];
    this.branchMarkupById.tabId = this.paramObj['tabId'];
    this.branchMarkupById.groupId = this.paramObj['groupId'];
  }

  getChargeType() {
    this.chargeTypeList$ = this.agencyMarkupService.getChargeType(0);
    this.chargeTypeList$.subscribe((ele) => {
      ele.forEach(element => {
        this.chargeTypeArr.push(element);
      });
    });
  }

  buildModel() {
    this.saveMarkupInfo.fromDateKey = null;
    this.saveMarkupInfo.toDateKey = null;
    this.saveMarkupInfo.agencyId = this.userProfileService.getBasicUserInfo().agencyId;  // headers madhli
    this.saveMarkupInfo.branchId = (this.agencyMarkupService.selectedMarkupBranch.isAgency) ? 0 :
      this.agencyMarkupService.selectedMarkupBranch.id; // selected all 0
    this.saveMarkupInfo.currencyId = this.userProfileService.getBasicUserInfo().agencyDefaultCurrencyId; // 14;
    this.saveMarkupInfo.countryList = null;
    this.saveMarkupInfo.cityList = null;
    this.saveMarkupInfo.hotelList = null;
    this.saveMarkupInfo.countryId = null;
    this.saveMarkupInfo.cityId = null;
    this.saveMarkupInfo.tabId = CONSTANTS.markup.tab.branch.id;
    this.saveMarkupInfo.tabName = CONSTANTS.markup.tab.branch.label;
    this.saveMarkupInfo.isUpdate = false;  // create = ;
    const mValueFrom = this.updateMarkupForm.get('markup').value ?
          +MarkupUtilities.replaceComma(this.updateMarkupForm.get('markup').value) : null;
    this.saveMarkupInfo.markup = mValueFrom;

    if (this.paramObj['operation'] === 'edit') {
      this.saveMarkupInfo.isUpdate = true;
      this.saveMarkupInfo.groupId = this.paramObj['groupId'];
    }
  }

  saveBranchMarkup() 
  {
    this.markFormDirtyService.markGroupDirty(this.updateMarkupForm);
    if (this.updateMarkupForm.valid && this.updateMarkupForm.dirty)
  
  {
    this.saveMarkupInfo = this.updateMarkupForm.value;
    this.buildModel();
    this.saveMarkupInfo.isSaveValidRecordsOnly = false; // discard : 1 ? 0
    this.updateMarkup();
  }}

  discardBranchMarkup() {
    this.saveMarkupInfo.isSaveValidRecordsOnly = true; // discard : 1 ? 0
  }

  updateMarkup() {
    this.showSpinner();
    const subscription = this.agencyMarkupService.saveMarkup(this.saveMarkupInfo).subscribe((branchMarkupInfo) => {
      this.branchMarkupInfo = branchMarkupInfo.result;
      if (this.branchMarkupInfo.length > 0) {
        this.conflicts();
      } else {
        const message = (this.paramObj['operation'] === 'edit') ?
        'Markup has been updated successfully' :
        'Markup has been added successfully';
        this.snackBar.open(message, '', {
          duration: CONSTANTS.toasterTimeOut.duration,
          verticalPosition: 'top', panelClass: 'showSnackBar'
        });
        this.navigate();
      }
    }, (error: JarvisError) => {
      this.hideSpinner();
      let dialogRef: MatDialogRef<MessageDialogComponent>;
      dialogRef = this.dialog.open(MessageDialogComponent);
      dialogRef.componentInstance.title = 'Error';
      dialogRef.componentInstance.message = `Error Code ${ErrorCodes.ValidateMarkupRequest}. ${error.friendlyMessage}`;
      dialogRef.componentInstance.buttonText = 'OK';
    },
    () => this.hideSpinner());
    this.subscriptions.push(subscription);
  }

  conflicts() {
    this.isConflicts = true;
    // TODO: handle error highlighting
    this.updateMarkupForm.get('fromDate').setErrors({ dateError: true });
    this.updateMarkupForm.get('toDate').setErrors({ dateError: true });
  }

  closeCustomTooltip() {
    this.isConflicts = false;
  }

  navigate() {
    this.router.navigate(['/authenticated/markup/markupmgmt/branchmarkup'], { relativeTo: this.activatedRoute });
  }

  getMarkupTypeByID(id) {
    let chargetype1;
    this.chargeTypeArr.forEach(element => {
      if (element['id'] === id) {
        return chargetype1 = element['chargeType'];
      }
    });

    if (chargetype1) {
      return chargetype1;
    }
  }

  removeAmount() {
    this.updateMarkupForm.get('markup').setValue('');
  }

  addValidations() {
    if (this.getMarkupTypeByID(this.updateMarkupForm.get('chargeTypeId').value) === 'Amount') {
      this.updateMarkupForm.get('markup')
      .setValidators([
        Validators.required,
        Validators.max(CONSTANTS.markup.markupMaxAmount),
        MarkupUtilities.markupAmountValidatior
        ]);
    } else {
      this.updateMarkupForm.get('markup')
      .setValidators([
        Validators.required,
        Validators.max(CONSTANTS.markup.markupMaxPercent),
        MarkupUtilities.markupPercentValidatior
        ]);
    }

    this.updateMarkupForm.updateValueAndValidity();
  }

  hasErrors(controlName: string) {
    if (this.updateMarkupForm.get(controlName)) {
      return (this.updateMarkupForm.get(controlName).dirty || this.updateMarkupForm.get(controlName).touched)
      && (this.updateMarkupForm.get(controlName).errors !== null);
    }
  }

  getValidationMessage(controlName: string) {

    if (this.updateMarkupForm.get(controlName).hasError('required')) {
        return ErrorMessages.requiredFieldMessage;
    } else if (this.updateMarkupForm.get(controlName).hasError('max')) {
      const markupLimitMessage =
      (this.getMarkupTypeByID(this.updateMarkupForm.get('chargeTypeId').value) === 'Amount')
        ? ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxAmount)
        : ErrorMessages.maxAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMaxPercent);
      return markupLimitMessage;
    } else if (this.updateMarkupForm.get(controlName).hasError('min')) {
      const markupLimitMessage =
      (this.getMarkupTypeByID(this.updateMarkupForm.get('chargeTypeId').value) === 'Amount')
        ? ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinAmount)
        : ErrorMessages.minAllowedLimit + this.decimalPipe.transform(CONSTANTS.markup.markupMinPercent);

      return markupLimitMessage;
    } else {
      return '';
    }
  }

  transformAmount(amount,control) {
    if ( amount && amount !== '') {
    amount = Number(amount.replace(/[,]/g, ''));
    let isNumber = false;
   isNumber = isNaN(amount);
   if (isNumber === false) {
     amount = Number(amount);
    this.updateMarkupForm.get(control).setValue(formatNumber(amount, this.navigatorService.getCurrentLocale(), CONSTANTS.digitsInfo));
   }
 }
}

numberFormatInCurrentLocale(value) { // If index not present, then remove the parameter
  if (value !== '') {
     //this.roomTypeList.controls[index].get('minimumSellingPrice').setValue(this.userInputTransformService.userInputNumberTransform(value));
// or
 this.updateMarkupForm.get('markup').setValue(this.userInputTransformService.userInputNumberTransform(value));
    }
  }




removeCurrencyPipeFormat($event) {
  $event.target.value = MarkupUtilities.replaceComma($event.target.value);
}



cancelCRUDMarkup() {
    let confirmCancelSubscription = null;
    confirmCancelSubscription = Utilities.confirmCancel(this.updateMarkupForm.dirty, this.cancelPopupService, this.router,
      // tslint:disable-next-line:max-line-length
      ['/authenticated/markup/markupmgmt/branchmarkup'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' }, () => window.scrollTo(0, 0));
    if (!Utilities.isNullOrEmpty(confirmCancelSubscription)) {
      this.subscriptions.push(confirmCancelSubscription);
      this.confirmCancel = true;
    }
  }

  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.updateMarkupForm.dirty && this.confirmCancel) {
      return this.dialogsService.confirm('Confirm', 'If you cancel, the current data will be lost');
    } else {
      return of(true);
    }
    // return true;
  }

  showSpinner() {
    this.spinnerDialogTimeout = setTimeout(() => {
      this.spinnerDialogId = this.dialogsService.showSpinner();
    });
  }

  hideSpinner() {
    if (this.spinnerDialogId !== null && this.spinnerDialogId !== undefined && this.spinnerDialogId !== '') {
      this.dialogsService.hideSpinner(this.spinnerDialogId);
      this.spinnerDialogId = undefined;
    }
    clearTimeout(this.spinnerDialogTimeout);
    this.spinnerDialogTimeout = undefined;
  }

}

