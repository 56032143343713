import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { ScreenService } from '../../services/screen.service';
import { MenuService } from '../../services/menu.service';
import { AuthService } from '../../../../common/shared/services/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fw-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit, AfterViewInit {

  @ViewChild('appDrawer') appDrawer: ElementRef;
  constructor(public menuService: MenuService, public screenService: ScreenService, private authService: AuthService) {
    if (menuService.items.findIndex(val => val.text === 'Terms & Conditions') == -1) {
      menuService.items.push({
        icon: "fa fa-users userManagementIcon",
        route: null,
        submenu: null,
        text: "Terms & Conditions"
      });
    }
    if (authService.isLoggedIn() && menuService.items.findIndex(val => val.text === 'FAQ/Help Center') == -1) {
      menuService.items.push({
        icon: "fa fa-users userManagementIcon",
        route: "authenticated/faq",
        submenu: null,
        text: "FAQ/Help Center"
      })
    }

    if (!authService.isLoggedIn() && menuService.items.findIndex(val => val.text === 'Contact') == -1) {
      menuService.items.push({
        icon: "fa fa-users userManagementIcon",
        route: "siteinfomgmt/contact",
        submenu: null,
        text: "Contact"
      })
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.menuService.appDrawer = this.appDrawer;
  }

}
